const initialState = {
  dashboardStats: {},
  lineItems: [],
  stripeCharges: [],
  location: { _id: 1, city: "Charlotte", latitude: 35.22, longitude: -80.84 },
  markets: []
};

function general(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case "ADMIN_GET_DASHBOARD_STATS":
      return { ...state, dashboardStats: payload };
    case "ADMIN_GET_LINE_ITEMS_SUCCESS":
      return { ...state, lineItems: payload.lineItems };
    case "ADMIN_GET_STRIPE_CHARGES_SUCCESS":
      return { ...state, stripeCharges: payload.stripeCharges };
    case "CHANGE_CITY":
      return { ...state, location: action.payload };
    case "GET_MARKETS":
      return { ...state, markets: action.payload };
    default:
      return state;
  }
}

export default general;
