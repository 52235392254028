import React, { Component } from "react";

class Appointments extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div>Appointments</div>;
  }
}

export default Appointments;
