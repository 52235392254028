import React, { Component } from "react";
import axios from "../../../../services/network.js";
import { DropdownList, Multiselect } from "react-widgets";
import { DefaultModal } from "../../../index.js";

class MedicineItem extends Component {
  initialState = {
    _id: "",
    name: "",
    purpose: "",
    frequency: "",
    location: "",
    dosage: "",
    other: "",
    times_of_day: [],
    editing: false
  };
  state = { ...this.initialState };

  componentWillMount() {
    if (this.props && this.props.medicine) {
      this.setState({ ...this.props.medicine });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.medicine && nextProps.medicine._id) {
      this.setState({ ...nextProps.medicine });
    }
  }

  removeMedicine = async () => {
    try {
      if (!this.state._id) return;
      const { data } = await axios.put(
        `/admin/medication/${this.state._id}/delete`
      );
      this.props.loadMedication();
    } catch (e) {
      if (e && e.data && e.data.error) {
        alert(e.data.error);
      } else {
        alert(e);
      }
      console.log("Massive e:::", e);
    }
  };

  getFrequencies = () => {
    return ["Once A Day", "Multiple Times/Day", "As Needed", "Other"];
  };

  getTimes = () => {
    return ["morning", "midday", "evening"];
  };

  saveMedication = async () => {
    this.props.save(this.state);
    // if(!this.props.medicine || !this.props.medicine._id){
    //   this.setState({ ...this.initialState })
    // }
    this.setState({ editing: false });
  };

  updateVal = (key, val) => {
    this.setState({ [key]: val });
  };

  renderAction = () => {
    const { editing } = this.state;
    if (editing) {
      return (
        <div>
          <a
            className="remove-link"
            onClick={() => {
              this.removeMedicine();
            }}
          >
            <span className="fa fa-close" />
            Remove Medication
          </a>
        </div>
      );
    } else if (!editing && this.state._id) {
      return (
        <div>
          <a
            className="remove-link"
            onClick={() => {
              this.setState({ editing: true });
            }}
          >
            <span className="fa fa-edit" />
            Edit Medication
          </a>
        </div>
      );
    }
    return null;
  };

  disable = () => {
    return this.state.editing || this.props.new;
  };
  render() {
    const i = this.props.index;
    const m = { ...this.state };
    return (
      <div key={m._id}>
        <div className="col-sm-12">
          {this.props.index > -1 ? (
            <h4 className="med-name">Medication {this.props.index + 1}</h4>
          ) : (
            <h4 className="med-name">New Medication</h4>
          )}
          {this.renderAction()}
        </div>
        <div className="col-md-4">
          <div className="form-component-wrapper">
            <input
              type="text"
              placeholder="Medication Name"
              onChange={e => {
                this.updateVal("name", e.target.value, i);
              }}
              value={m.name}
              disabled={!this.disable()}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-component-wrapper">
            <input
              type="text"
              value={m.purpose}
              disabled={!this.disable()}
              placeholder="Purpose"
              onChange={e => {
                this.updateVal("purpose", e.target.value, i);
              }}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-component-wrapper">
            <DropdownList
              data={this.getFrequencies()}
              placeholder="Frequency"
              value={m.frequency}
              onChange={value => {
                this.updateVal("frequency", value, i);
              }}
              disabled={!this.disable()}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-component-wrapper">
            <input
              type="text"
              disabled={!this.disable()}
              value={m.location}
              placeholder="Location"
              onChange={e => {
                this.updateVal("location", e.target.value, i);
              }}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-component-wrapper">
            <input
              type="text"
              disabled={!this.disable()}
              value={m.dosage}
              placeholder="Dosage"
              onChange={e => {
                this.updateVal("dosage", e.target.value, i);
              }}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-component-wrapper">
            <input
              type="text"
              disabled={!this.disable()}
              value={m.other}
              placeholder="Other Info"
              onChange={e => {
                this.updateVal("other", e.target.value, i);
              }}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-component-wrapper">
            <Multiselect
              data={this.getTimes()}
              placeholder="Times of Day"
              onChange={value => {
                this.updateVal("times_of_day", value, i);
              }}
              value={m.times_of_day}
              disabled={!this.disable()}
            />
          </div>
        </div>
        {this.disable() ? (
          <div className="col-sm-12">
            <div className="col-sm-3">
              <button
                className="btn btn-tertiary btn-block"
                onClick={() =>
                  this.props.cancel
                    ? this.props.cancel()
                    : this.setState({ editing: false })
                }
              >
                Cancel
              </button>
            </div>
            <div className="col-sm-3 pull-right">
              <button
                className="btn btn-primary btn-block"
                onClick={this.saveMedication}
              >
                Save
              </button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default MedicineItem;
