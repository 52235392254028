const fetching = (state = {}, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, [action.context]: true };

    case "FETCH_SUCCESS":
      return { ...state, [action.context]: false };

    case "FETCH_FAILURE":
      return { ...state, [action.context]: false };

    default:
      return state;
  }
};

export default fetching;

// Selector for determining if there is any context (key) in state that is fetching.
// Contexts can be a string (for one context) or an array of strings (for checking multiple).
export const isFetching = (state, contexts = "any") => {
  const fetchingState = state.general.fetching;
  const fetchingContexts = Object.keys(fetchingState).filter(
    context => fetchingState[context] === true
  );

  // Returns true if anything isFetching
  if (contexts === "any" && fetchingContexts.length) {
    return true;

    // Returns true if the given context string isFetching and false otherwise.
  } else if (typeof contexts === "string") {
    return fetchingContexts.includes(contexts);

    // Returns true if any one context in the provided contexts array isFetching
  } else {
    for (const context of contexts) {
      if (fetchingContexts.includes(context)) {
        return true;
      }
    }
  }

  // Otherwise, return false.
  return false;
};
