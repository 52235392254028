import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import UserDropdownMenu from "../shared/UserDropdownMenu";
import { AdminMobileNav } from "../";
import logo from "../../images/skipper-logo-nav2.svg";
import { pushModal, popModal } from "../../actions/general.actions";
import { changeCity } from "../../actions/admin/appointments.actions";
import { locations } from "../../services/constants";
import { connect } from "react-redux";
import { DropdownList } from "react-widgets";

class AdminNavComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      manage_dropdown_open: false
    };
  }


  static defaultProps = {
    blur: false
  };

  getStyle = () => (this.props.blur ? "nav-blur" : "nav");

  changeCity = city => {
    const { selectedDate, changeCity, location } = this.props;
    if (city && city._id !== this.props.city._id) {
      localStorage.setItem("market_id", city._id);
      changeCity(city, selectedDate, location.pathname);
    }
  };

  returnLinks = () => {
    const { user } = this.props;
    const { manage_dropdown_open } = this.state;
    if (
      !user ||
      !user.user ||
      !user.user.roles ||
      user.user.roles.includes("offshore")
    )
      return null;

      const roles = user.user.roles

      let mainRole
  
      if (roles.includes('Support User') || roles.includes('PCA Supervisor User')) mainRole = 'support'
      if (roles.includes('Back Office User')) {
        mainRole = 'admin'
      }
      // legacy permissions...
      if (roles.includes('oa') || roles.includes('admin')) mainRole = 'admin'
  
      const isAdmin = mainRole === 'admin'

    return (
      <ul className="nav navbar-nav">
        <li>
          <Link to="/" className="active-link">
            Dashboard
          </Link>
        </li>
        <li>
          <Link to="/clientlist" className="active-link">
            Clients
          </Link>
        </li>
        <li>
          <Link to="/stafflist" className="active-link">
            Staff
          </Link>
        </li>
        <li>
          <Link to="/map" className="active-link">
            Map
          </Link>
        </li>
        {
          isAdmin && (

          <li className={`dropdown${manage_dropdown_open ? " open" : ""}`}>
            <a
              className="dropdown-toggle"
              data-toggle="dropdown"
              href="#"
              onClick={this.showDropdown}
            >
              Management <span className="caret"></span>
            </a>
            <div className="dropdown-menu nav-client">
              <ul>
                <Link to="/payments">
                  <li>Payments</li>
                </Link>
                <Link to="/staffData">
                  <li>Data</li>
                </Link>
                <Link to="/markets">
                  <li>Markets</li>
                </Link>
                <Link to="/apartments">
                  <li>Apartments</li>
                </Link>
                <Link to="/editprices">
                  <li>Prices</li>
                </Link>
                <Link to="/vacation">
                  <li>Vacation</li>
                </Link>
                <Link to="/promos">
                  <li>Promos</li>
                </Link>
                <Link to="/partners">
                  <li>Partners</li>
                </Link>
                <Link to="/facilities">
                  <li>Facilities</li>
                </Link>
                <Link to="/vets">
                  <li>Vets</li>
                </Link>
                <Link to="/apps">
                  <li>App Versions</li>
                </Link>
              </ul>
            </div>
          </li>
          )
        }
      </ul>
    );
  };

  showDropdown = () => {
    this.setState({ manage_dropdown_open: true });
    document.addEventListener("click", this.hideDropdown);
  };

  hideDropdown = () => {
    this.setState({ manage_dropdown_open: false });
    document.removeEventListener("click", this.hideDropdown);
  };

  componentWillUnmount = () => {
    document.removeEventListener("click", this.hideDropdown);
  };

  render() {
    const { modalActions, user } = this.props;

    return (
      <nav
        className={"navbar navbar-fixed-top navbar-default " + this.getStyle()}
      >
        <div className="container-fluid">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
              aria-expanded="false"
              onClick={() =>
                modalActions.push(
                  <AdminMobileNav fullScreen modalActions={modalActions} />
                )
              }
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>

            <Link to="/">
              <span className="navbar-brand">
                <img className="logo" src={logo} alt="Waggle Logo" />
              </span>
            </Link>
            <div className="select-city">
              {this.props.location.pathname.indexOf("market") > -1 ? null : (
                <DropdownList
                  data={this.props.markets}
                  textField="city"
                  onChange={val => this.changeCity(val)}
                  defaultValue={this.props.city}
                  value={this.props.city}
                />
              )}
            </div>
          </div>

          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            {this.returnLinks()}
            <ul className="nav navbar-nav navbar-right">
              <UserDropdownMenu />
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = state => ({
  user: state.general.user,
  city: state.admin.general.location,
  markets: state.admin.general.markets,
  selectedDate: state.admin.scheduler.selectedDate
});

const mapDispatchToProps = dispatch => ({
  changeCity: (city, date, location) =>
    dispatch(changeCity(city, date, location)),
  modalActions: {
    push: component => dispatch(pushModal(component)),
    pop: () => dispatch(popModal())
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AdminNavComponent)
);
