import React, { Component } from "react";
import { connect } from "react-redux";
import { loadStaffData } from "../../actions/admin/staff.actions";
import { Link } from "react-router-dom";
import { StaffTableRow } from "../../components";
import queryString from "query-string";
import DropdownList from "react-widgets/DropdownList";
import {
  StaffDataRow,
  DateSwitcher,
  AppointmentTable,
  PlayCareVisitRow
} from "../../components";

import moment from "moment";
import axios from "../../services/network.js";

class PlayCare extends Component {
  state = {
    apartments: [],
    apartment: null,
    appointments: [],
    selectedDate: moment().toDate()
  };

  componentWillMount() {
    axios
      .get(
        `${process.env.REACT_APP_API_URI}/admin/apartments?market=${this.props.market._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      )
      .then(res => {
        this.setState({ apartments: res.data.filter(a => a.playcare) });
      });
  }

  loadVisits = (apartment, date) => {
    let apt;
    if (apartment) {
      apt = apartment;
    } else {
      apt = this.state.apartment;
    }

    axios
      .get(
        `${process.env.REACT_APP_API_URI}/admin/playCareVisits?market=${this.props.market._id}&apt=${apt.apartment_id}&date=${date}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      )
      .then(res => {
        this.setState({ appointments: res.data });
      });
  };

  onDateBack = () => {
    let date = moment(this.state.selectedDate)
      .subtract(1, "day")
      .toDate();
    this.setState({ selectedDate: date }, () =>
      this.loadVisits(null, this.state.selectedDate)
    );
  };

  onDateForward = () => {
    let date = moment(this.state.selectedDate)
      .add(1, "day")
      .toDate();
    this.setState({ selectedDate: date }, () =>
      this.loadVisits(null, this.state.selectedDate)
    );
  };

  render() {
    const { appointments } = this.state;

    return (
      <div className="card p-t-none">
        <div className="row">
          <div className="col-sm-6">
            <DateSwitcher
              dateFormat="dddd, MMMM D, YYYY"
              max={moment().add(2, "weeks")}
              min={moment()}
              onDateBack={this.onDateBack}
              onDateForward={this.onDateForward}
              selectedDate={moment(this.state.selectedDate)}
            />
          </div>
          <div className="col-sm-6">
            <h3>Apartment Complex</h3>
            <DropdownList
              data={this.state.apartments}
              textField="name"
              placeholder="Choose Apartment Complex"
              onChange={v =>
                this.setState({ apartment: v }, () =>
                  this.loadVisits(v, this.state.selectedDate)
                )
              }
            />
          </div>
        </div>

        <div
          className={`user-list-container ${
            this.state.collapseAppts ? "collapseAppts" : ""
          }`}
        >
          {appointments && appointments.length
            ? appointments
                // Currently sorting by requested_start_time, ascending
                .filter(a => !a.clear_from_dashboard)
                // .filter(a => this.state.viewPast && tab !== 'late' ? a : !moment().isAfter(a.window_end_time))
                .sort((a, b) => moment(b.updatedAt) - moment(a.updatedAt))
                .map(appointment => {
                  return (
                    <PlayCareVisitRow
                      key={`appointment-${appointment._id}`}
                      appointment={appointment}
                      // removeFromDashboard={this.props.removeFromDashboard}
                      // approveAppointment={this.props.approveAppointment}
                      // tab={tab}
                    />
                  );
                })
            : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  staffList: state.admin.staff.staffData.staff,
  appointments: state.admin.staff.staffData.appointments,
  user: state.general.user,
  market: state.admin.general.location
});

const mapDispatchToProps = dispatch => ({
  loadStaff: qs => dispatch(loadStaffData(qs))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayCare);
