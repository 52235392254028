import React from "react";

const FieldColumnWrapper = ({ children, column, offset }) => (
  <div
    className={`col col-sm-${column} col--form-column-component ${
      offset ? `col-sm-offset-${offset}` : ""
    }`}
  >
    <div className="input-wrapper--form-column-component">{children}</div>
  </div>
);


export default FieldColumnWrapper;
