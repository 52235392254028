import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import { pushModal, popModal } from "../../../actions/general.actions";
// import { sendPasswordResetEmail as sendPasswordResetEmailAction } from '../../../actions/general.actions';
import {
  resetPassword,
  resetPasswordClient
} from "../../../services/firebaseHelpers";

// Components
import { DefaultModal } from "../../";

class PasswordResetConfirmationModal extends Component {
  constructor() {
    super();

    this.confirmSendEmail = this.confirmSendEmail.bind(this);
  }

  async confirmSendEmail(e) {
    e.preventDefault();
    const { modalActions, email, userType } = this.props;
    try {
      if (userType === "client") {
        await resetPasswordClient(email);
      } else {
        await resetPassword(email);
      }
      modalActions.pop();
    } catch (e) {
      alert(e);
    }
  }

  render() {
    const { modalActions, userType } = this.props;

    return (
      <DefaultModal
        title="Send Password Reset Email"
        onDismiss={modalActions.pop}
        options={
          <div>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={modalActions.pop}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.confirmSendEmail}
            >
              Confirm
            </button>
          </div>
        }
      >
        <p>
          This will send the {userType} an email with a link to a secure page
          allowing them to set/change their password. Would you like to proceed?
        </p>
      </DefaultModal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  modalActions: {
    push: component => dispatch(pushModal(component)),
    pop: () => dispatch(popModal())
  }
});

export default connect(
  null,
  mapDispatchToProps
)(PasswordResetConfirmationModal);
