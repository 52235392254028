import React, { Component } from "react";
import queryString from "query-string";
import moment from "moment";
import axios from "../../../services/network.js";
import { connect } from "react-redux";
import { ForecastRowItem } from "../../../components";

class ForecastTable extends Component {
  state = {
    forecast: null,
    rmse: null
  };

  getForecast = async () => {
    const {
      showSpinner,
      stopSpinner,
      showMessage,
      removeMessage,
      marketId
    } = this.props;
    showSpinner();
    try {
      const coolDate = moment(this.props.forecastDate).startOf("isoWeek");
      const { data } = await axios.get(
        `${
          process.env.REACT_APP_API_URI
        }/admin/market/${marketId}/forecast?date=${coolDate.format(
          "YYYY-MM-DD"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );
      if (data && data.forecast) {
        this.setState({ forecast: data.forecast, rmse: data.rmse });
      } else {
        showMessage({
          message: "Looks like we dont have predictions for that day yet",
          messageType: "error"
        });
      }
    } catch (e) {
      console.log(e);
      if (e.data && e.data.message) {
        showMessage({
          message: e.data.message,
          messageType: "error"
        });
      } else {
        showMessage({
          message: "Error!!!!!!!!",
          messageType: "error"
        });
      }
    }
    setTimeout(removeMessage, 3000);
    stopSpinner();
  };

  renderPredictions = () => {
    if (!this.state.forecast) return null;
    return Object.keys(this.state.forecast).map(k => {
      return (
        <ForecastRowItem
          date={moment(k).format("dddd. MMMM, Do")}
          values={this.state.forecast[k]["hours"]}
          key={k}
          day_rmse={this.state.forecast[k].day_rmse}
          total_predicted={this.state.forecast[k].total_predicted}
          actual_visits={this.state.forecast[k].actual_visits}
        />
      );
    });
  };

  render() {
    return (
      <div>
        <div className="card" style={{ padding: "10px" }}>
          <div className="gray-table">
            <div className="parent-header">
              <div>
                <button
                  className="btn btn-primary normal-size-btn"
                  onClick={this.getForecast}
                >
                  Get Predictions
                </button>
              </div>
              <div className="headings">
                <div>Day</div>
                <div>Total Predicted</div>
                <div>Actual Visits</div>
                <div>Daily Average Error</div>
              </div>
              <div>
                {this.state.rmse ? (
                  <div className="actions">
                    <div>{`Average Error: ${this.state.rmse.toFixed(1)}`}</div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="table-body">{this.renderPredictions()}</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showSpinner: () => dispatch({ type: "FETCH_REQUEST" }),
  stopSpinner: () => dispatch({ type: "FETCH_SUCCESS" }),
  showMessage: msg => dispatch({ type: "ADD_MESSAGE", payload: msg }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" })
});

export default connect(
  null,
  mapDispatchToProps
)(ForecastTable);
