import React, { Component } from "react";
import { Link } from "react-router-dom";

import { OverlayModal } from "../../";

export default class AdminMobileNav extends Component {

  render() {
    const { modalActions } = this.props;
    return (
      <OverlayModal onDismiss={modalActions.pop}>
        <div className="row mobile-navigation">
          <ul className="nav">
            <li>
              <Link to="/" className="new-appointment-link-button">
                <button
                  className="btn btn-secondary navbar-btn blurable"
                  onClick={modalActions.pop}
                >
                  Dashboard
                </button>
              </Link>
            </li>
            <li>
              <Link to="/stafflist" className="new-appointment-link-button">
                <button
                  className="btn btn-secondary navbar-btn blurable"
                  onClick={modalActions.pop}
                >
                  Staff
                </button>
              </Link>
            </li>
            <li>
              <Link to="/clientlist" className="new-appointment-link-button">
                <button
                  className="btn btn-secondary navbar-btn blurable"
                  onClick={modalActions.pop}
                >
                  Clients
                </button>
              </Link>
            </li>
            <li>
              <Link to="/map" className="new-appointment-link-button">
                <button
                  className="btn btn-secondary navbar-btn blurable"
                  onClick={modalActions.pop}
                >
                  Map
                </button>
              </Link>
            </li>
            <li>
              <Link to="/payments" className="new-appointment-link-button">
                <button
                  className="btn btn-secondary navbar-btn blurable"
                  onClick={modalActions.pop}
                >
                  Payments
                </button>
              </Link>
            </li>
          </ul>
        </div>
      </OverlayModal>
    );
  }
}
