import React, { Component } from "react";

export default class FormSubHeader extends Component {

  render() {
    return (
      <div className="form-sub-section">
        <h4>{this.props.children}</h4>
        <hr className="hr" />
      </div>
    );
  }
}
