import React from "react";
import ReactDOM from "react-dom";

export default class Map extends React.Component {

  static defaultProps = {
    initialCenter: {
      lat: 35.2271,
      lng: -80.8431
    },
    zoom: 18,
    maxZoom: 18
    // kmlUrl: 'https://s3.amazonaws.com/waggle-static-dev/1d037ef2-1139-4c68-b32b-273450ea8a9c.kml',
  };

  componentDidMount() {
    this.loadMap();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.google !== this.props.google) {
      this.loadMap();
    }
  }

  loadMap = () => {
    const { google, initialCenter, kmlUrl, marker, zoom, maxZoom } = this.props;
    if (this.props && google) {
      // google is available
      const { google } = this.props;
      const maps = google.maps;
      const node = ReactDOM.findDOMNode(this.mapRef);

      const { lat, lng } = initialCenter;
      const center = new maps.LatLng(lat, lng);
      // const mapConfig = Object.assign({}, { center, zoom });
      const mapConfig = { center, zoom, maxZoom, scrollwheel: false };

      //d1f0a82a-4532-4eb6-ab36-709349cfd346.csv

      this.map = new maps.Map(node, mapConfig);
      if (kmlUrl) {
        this.kmlLayer = new maps.KmlLayer({
          url: kmlUrl,
          map: this.map
        });
      }

      if (marker) {
        this.marker = new maps.Marker({
          position: marker,
          map: this.map
        });
      }
    }
  };

  render() {
    return (
      <div
        ref={map => (this.mapRef = map)}
        className="google-walk-map"
        style={{ height: "400px" }}
      >
        Loading map...
      </div>
    );
  }
}
