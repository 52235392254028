import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "../../services/network.js";
import moment from "moment";
import { Link } from "react-router-dom";
import { DropdownList } from "react-widgets";
import { CardComponent, PromoCodeRowItem } from "../../components";

class Promos extends Component {
  state = {
    promo_codes: [],
    sort: {
      key: "code_string",
      direction: "ASC"
    },
    show_filters: false,
    code_filter: "",
    type_filter: "",
    active_filter: null,
    tab: "general"
  };

  componentWillMount = async () => {
    this.fetchPromos("general");
  };

  handleSort = key => {
    this.setState(
      {
        sort: {
          key: key,
          direction: this.state.sort.direction === "ASC" ? "DESC" : "ASC"
        }
      },
      () => {
        //determine type of sort key and use a switch statment to sort by type
        const { sort, promo_codes } = this.state;
        let promos = [...promo_codes];
        promos = promos.sort((a, b) => {
          let aVal = a[sort.key];
          let bVal = b[sort.key];
          switch (typeof a[sort.key]) {
            case "string":
              aVal = a[sort.key];
              bVal = b[sort.key];
              if (sort.direction === "ASC") {
                if (!aVal) return 1;
                if (!bVal) return -1;
                if (aVal === bVal) return 0;

                return aVal.toUpperCase() > bVal.toUpperCase() ? 1 : -1;
              } else {
                if (!bVal) return 1;
                if (!aVal) return -1;
                if (aVal === bVal) return 0;

                return bVal.toUpperCase() > aVal.toUpperCase() ? 1 : -1;
              }
              break;
            case "number":
              if (sort.direction === "ASC") {
                return a[sort.key] - b[sort.key];
              } else {
                return b[sort.key] - a[sort.key];
              }
              break;
            case "date":
              aVal = moment(a[sort.key], "MM-DD-YYYY").toDate();
              bVal = moment(b[sort.key], "MM-DD-YYYY").toDate();
              if (sort.direction === "ASC") {
                return aVal - bVal;
              } else {
                return bVal - aVal;
              }
              break;
            case "boolean":
              aVal = a[sort.key].toString();
              bVal = b[sort.key].toString();
              if (sort.direction === "ASC") {
                return aVal > bVal ? 1 : -1;
              } else {
                return bVal > aVal ? 1 : -1;
              }
              break;
          }
        });
        this.setState({ promo_codes: promos });
      }
    );
  };

  renderPromoCodes = () => {
    const { sort, promo_codes } = this.state;
    let filtered = [...promo_codes];
    //Filter by code name
    filtered = filtered.filter(p =>
      p.code_string.toLowerCase().includes(this.state.code_filter.toLowerCase())
    );
    if (this.state.type_filter) {
      filtered = filtered.filter(p => p.type === this.state.type_filter);
    }
    if (this.state.active_filter !== null) {
      filtered = filtered.filter(p => p.active === this.state.active_filter);
    }
    return filtered.map(p => {
      return (
        <PromoCodeRowItem
          id={p._id}
          active={p.active}
          code={p.code}
          code_string={p.code_string}
          owner={p.owner}
          created_at={p.created_at}
          expiration_date={p.expiration_date}
          type={p.type}
          num_used={p.num_used}
          partner_id={p.partner_id}
          staff_id={p.staff_id}
          client_id={p.client_id}
          market_id={p.market_id}
          key={p._id}
          updatePromo={this.updatePromo}
        />
      );
    });
  };

  updatePromo = async (id, active) => {
    const { showMessage, removeMessage, showSpinner, stopSpinner } = this.props;
    if (!id) return;
    showSpinner();
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URI}/admin/promo/${id}/status?active=${active}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken")
          }
        }
      );
      if (data.success) {
        const promos = [...this.state.promo_codes];
        const index = promos.findIndex(p => {
          return p._id === data.promo._id;
        });
        promos.splice(index, 1, data.promo);

        this.setState({ promo_codes: promos });
      }
    } catch (e) {
      if (e.data && e.data.message) {
        showMessage({
          message: e.data.message,
          messageType: "error"
        });
      } else {
        showMessage({
          message: "Something went wrong editing the promo",
          messageType: "error"
        });
      }
      setTimeout(removeMessage, 3000);
    }
    stopSpinner();
  };

  fetchPromos = async type => {
    const { showSpinner, stopSpinner } = this.props;
    showSpinner();
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URI}/admin/promos?type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      }
    );
    this.setState({ promo_codes: data, tab: type });
    stopSpinner();
  };

  render() {
    const { sort, tab } = this.state;
    let sortDirectionIconAlpha =
      sort.direction === "DESC" ? (
        <span className="fa fa-sort-alpha-desc" />
      ) : (
        <span className="fa fa-sort-alpha-asc" />
      );
    let sortDirectionIconNum =
      sort.direction === "DESC" ? (
        <span className="fa fa-sort-numeric-desc" />
      ) : (
        <span className="fa fa-sort-numeric-asc" />
      );
    return (
      <CardComponent title="Promos">
        <div className="promo-container">
          {this.state.show_filters ? (
            <div className="promo-filters">
              <div className="row">
                <div className="col-lg-2">
                  <div className="form-component-wrapper">
                    <input
                      type="text"
                      placeholder="Filter by Code"
                      onChange={e => {
                        this.setState({ code_filter: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="form-component-wrapper">
                    <DropdownList
                      placeholder="Filter by Active"
                      data={[
                        { text: "All", value: null },
                        { text: "Active", value: true },
                        { text: "Inactive", value: false }
                      ]}
                      textField="text"
                      valueField="value"
                      onChange={item => {
                        this.setState({ active_filter: item.value });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="promo-actions">
            <div className="row">
              <Link
                className="btn-primary btn"
                to={{ pathname: "/addpromo", state: {} }}
              >
                Add Promo Code
              </Link>
              <button
                type="button"
                className="pull-right btn btn-primary"
                onClick={() => {
                  this.setState({ show_filters: !this.state.show_filters });
                }}
              >
                {this.state.show_filters ? "Hide Filters" : "Filter"}
              </button>
            </div>
          </div>
          <div className="promo-tabs">
            <div
              className={`promoTab${tab === "general" ? " selectedTab" : ""}`}
              onClick={() => {
                this.fetchPromos("general");
              }}
            >
              General
            </div>
            <div
              className={`promoTab${tab === "partner" ? " selectedTab" : ""}`}
              onClick={() => {
                this.fetchPromos("partner");
              }}
            >
              Partner
            </div>
            <div
              className={`promoTab${tab === "staff" ? " selectedTab" : ""}`}
              onClick={() => {
                this.fetchPromos("staff");
              }}
            >
              Staff
            </div>
            <div
              className={`promoTab${tab === "client" ? " selectedTab" : ""}`}
              onClick={() => {
                this.fetchPromos("client");
              }}
            >
              Client
            </div>
          </div>
          <div className="promo-table">
            <div className="row table-headers">
              <div
                className="col-xs-2 header"
                onClick={() => {
                  this.handleSort("code_string");
                }}
              >
                Code
                {this.state.sort.key === "code_string"
                  ? sortDirectionIconAlpha
                  : null}
              </div>
              <div
                className="col-xs-1 header"
                onClick={() => {
                  this.handleSort("type");
                }}
              >
                Type
                {this.state.sort.key === "type" ? sortDirectionIconAlpha : null}
              </div>
              <div
                className="col-xs-3 header"
                onClick={() => {
                  this.handleSort("owner");
                }}
              >
                Owner
                {this.state.sort.key === "owner"
                  ? sortDirectionIconAlpha
                  : null}
              </div>
              <div
                className="col-xs-1 header"
                onClick={() => {
                  this.handleSort("num_used");
                }}
              >
                # Used
                {this.state.sort.key === "num_used"
                  ? sortDirectionIconNum
                  : null}
              </div>
              <div
                className="col-xs-2 header"
                onClick={() => {
                  this.handleSort("created_at");
                }}
              >
                Created At
                {this.state.sort.key === "created_at"
                  ? sortDirectionIconNum
                  : null}
              </div>
              <div
                className="col-xs-2 header"
                onClick={() => {
                  this.handleSort("expiration_date");
                }}
              >
                Expires At
                {this.state.sort.key === "expiration_date"
                  ? sortDirectionIconNum
                  : null}
              </div>
              <div
                className="col-xs-1 header"
                onClick={() => {
                  this.handleSort("active");
                }}
              >
                Active
                {this.state.sort.key === "active"
                  ? sortDirectionIconAlpha
                  : null}
              </div>
            </div>
            {this.renderPromoCodes()}
          </div>
        </div>
      </CardComponent>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showSpinner: () => dispatch({ type: "FETCH_REQUEST" }),
  stopSpinner: () => dispatch({ type: "FETCH_SUCCESS" }),
  showMessage: msg => dispatch({ type: "ADD_MESSAGE", payload: msg }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" })
});

export default connect(
  null,
  mapDispatchToProps
)(Promos);
