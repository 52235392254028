import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import {
  DropdownListField,
  FieldColumnWrapper,
  FormSection,
  InputField
} from "../../../shared/formComponents";
import { formatNumsToDollars, onlyNumbers } from "../../../../helpers";

class AddLineItemForm extends Component {

  render() {
    const { chargeOrCredit, dirty, handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <FormSection title="Add New Charge/Credit">
          <div className="row">
            <FieldColumnWrapper column="6">
              <Field
                name="description"
                component={InputField}
                placeholder="Description"
                type="text"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="2">
              <Field
                component={DropdownListField}
                name="charge_or_credit"
                data={["Charge", "Credit"]}
                placeholder="Charge/Credit"
              />
            </FieldColumnWrapper>

            {chargeOrCredit === "Credit" ? (
              <FieldColumnWrapper column="2">
                <Field
                  component={DropdownListField}
                  name="type"
                  data={["Promo", "Error", "Transfer"]}
                  placeholder="Credit Type"
                />
              </FieldColumnWrapper>
            ) : null}

            <FieldColumnWrapper column="2">
              <Field
                name="amount"
                component={InputField}
                placeholder="Amount"
                type="text"
                format={formatNumsToDollars}
                normalize={onlyNumbers}
              />
            </FieldColumnWrapper>
          </div>

          <div className="row">
            {dirty ? (
              <div className="col-sm-3 text-right button-column pull-right">
                <input
                  type="submit"
                  className="btn btn-block btn-primary"
                  value={`Add ${chargeOrCredit}`}
                />
              </div>
            ) : null}
          </div>
        </FormSection>
      </form>
    );
  }
}

const validate = values => {
  const errors = {};
  if (!values.description) errors.description = true;
  if (!values.charge_or_credit) errors.charge_or_credit = true;
  if (!values.amount) errors.amount = true;
  return errors;
};

AddLineItemForm = reduxForm({ form: "addLineItem", validate })(AddLineItemForm);

// connect using react-redux and reduxForm's formValueSelector to get form values
const selector = formValueSelector("addLineItem");
const mapStateToProps = state => ({
  chargeOrCredit: selector(state, "charge_or_credit")
});

export default connect(mapStateToProps)(AddLineItemForm);
