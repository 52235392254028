import React, { Component } from "react";
import { connect } from "react-redux";
import { getAllStaff } from "../../actions/admin/staff.actions";
import { Link } from "react-router-dom";
import { StaffTableRow } from "../../components";

class StaffListPage extends Component {
  state = {
    staffList: [],
    showInactive: false
  };

  componentWillMount = () => {
    let { market } = this.props;

    this.props.onLoadStaff({ city: market._id });
  };

  componentWillReceiveProps(props) {
    if (this.props.market._id !== props.market._id) {
      this.props.onLoadStaff({ city: props.market._id });
    }
    if (props.staffList) this.setState({ staffList: props.staffList });
  }

  filterStaff = e => {
    if (!e.target.value) {
      this.setState({
        staffList: this.props.staffList
      });
    }
    const staff = this.props.staffList.filter(
      c =>
        !c.contact ||
        !c.contact.full_name ||
        c.contact.full_name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ staffList: staff });
  };

  render() {
    const { user } = this.props;

    const { staffList } = this.state;
    // if (user)
    return (
      <div className="card p-t-none">
        <div className="card-header">
          <div className="row">
            <div className="col-sm-12">
              <div className="col-xs-4">
                <h1>All Staff</h1>
                <div>
                  <label>Show Deactivated</label>
                  <input
                    type="checkbox"
                    checked={this.state.showInactive ? true : false}
                    onChange={() =>
                      this.setState({ showInactive: !this.state.showInactive })
                    }
                  />
                </div>
              </div>
              <div className="col-xs-8">
                <span className="inline-link-right">
                  <Link to="/addstaff">
                    <span className="fa fa-plus" /> Add New Staff Member
                  </Link>
                </span>
              </div>
            </div>
            <div className="col-sm-12 p-t-sm">
              <div className="form-inline">
                <div className="col-xs-12">
                  <div className="form-group input-field">
                    <span className="fa fa-search" />
                    <input
                      placeholder="Filter by Name..."
                      onChange={e => this.filterStaff(e)}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="user-list-container">
            <div className="user-list-item user-list-header">
              <div className="user-list-item-content title col-lg-3 col-md-3 col-sm-3 col-xs-6">
                Name
              </div>
              <div className="user-list-item-content title col-lg-3 col-md-3 col-sm-3 col-xs-6">
                Zones
              </div>
              <div className="user-list-item-content title col-lg-3 col-md-3 col-sm-3 col-xs-6">
                Zip Code
              </div>
              <div className="user-list-item-content title col-lg-4 col-md-3 col-sm-3 hidden-xs">
                Notes
              </div>
            </div>

            {staffList && staffList.length
              ? staffList
                  .filter(staff =>
                    this.state.showInactive ? true : staff.active
                  )
                  .map((staff, index) => (
                    <StaffTableRow
                      key={`staff-list-item-id-${staff._id}`}
                      staff={staff}
                    />
                  ))
              : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  staffList: state.admin.staff.staffList,
  user: state.general.user,
  market: state.admin.general.location
});

const mapDispatchToProps = dispatch => ({
  onLoadStaff: market => dispatch(getAllStaff(market))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StaffListPage);
