import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ZoneBadge } from "../../";
import moment from "moment";
import axios from "../../../services/network.js";
import queryString from "query-string";
import oval from "../../../images/oval.svg";
export default class StaffDataRow extends Component {
  state = {
    open: false,
    miles: null,
    request: false,
    getMiles: true
  };

  componentDidMount() {
    if (this.props.staff && this.props.staff.appointments) {
      this.props.staff.appointments.forEach(a => {
        if (
          (!a.client ||
            !a.client.contact.address.loc ||
            !a.client.contact.address.loc.coordinates ||
            !a.client.contact.address.loc.coordinates.length) &&
          a.appointment_type.id !== 8
        )
          this.setState({ getMiles: false });
      });
    }
  }

  getMoney = () => {
    const { staff } = this.props;
    if (!staff || !staff.appointments || !staff.appointments.length) return 0;
    let appts = staff.appointments.filter(a => a.appointment_type.id !== 8);
    if (!appts.length) return 0;
    let cents = appts.map(a => {
      return Number(a.price);
    });
    cents = cents.reduce((a, b) => {
      return a + b;
    }, 0);
    let dollars = (cents / 100).toFixed(2);
    return dollars;
  };

  getMileage = async e => {
    e.stopPropagation();
    this.setState({ request: true });
    const { staff, startDate, endDate } = this.props;
    const qs = queryString.stringify({
      startDate: startDate,
      endDate: endDate
    });
    let miles = await axios.get(
      `${process.env.REACT_APP_API_URI}/admin/staffMileage/${staff._id}?${qs}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      }
    );
    if (!miles.data || !miles.data.length) {
      this.setState({ miles: 0, request: false });
      return;
    }
    let mileage = miles.data.map(m => m.mileage).reduce((a, b) => a + b);
    this.setState({ miles: mileage, request: false });
  };

  render() {
    const { staff } = this.props;
    console.log(staff);
    return (
      <div>
        <div
          className="user-list-item"
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <div className="user-list-item-content username-container col-lg-3 col-md-3 col-sm-3 col-xs-6">
            <div className="user-list-name">
              {staff.contact.full_name ? staff.contact.full_name : "No Name"}
            </div>
            <div className="user-list-staff-type">{staff.staff_type}</div>
          </div>
          <div className="col-sm-3">
            {staff.appointments ? staff.appointments.length : 0}
          </div>
          <div className="col-sm-3">${this.getMoney()}</div>
          <div className="col-sm-3">
            {this.state.request ? (
              <div>
                <div className="loading-spinner-bg"></div>
                <img
                  className="loading-spinner-img"
                  src={oval}
                  alt="Spinner"
                  height={72}
                  width={72}
                />
              </div>
            ) : (
              <div>
                {this.state.getMiles ? (
                  <button
                    disabled={this.props.disabled}
                    onClick={e => this.getMileage(e)}
                  >
                    Get Mileage
                  </button>
                ) : (
                  <span>Client Missing Lat/Lng</span>
                )}
                {!this.state.miles && this.state.miles !== 0
                  ? null
                  : this.state.miles}
              </div>
            )}
          </div>
        </div>
        {this.state.open && staff.appointments ? (
          <div>
            {staff.appointments
              .filter(a => a.appointment_type.id !== 8)
              .map(a => {
                const hasLat =
                  a.client &&
                  a.client.contact.address.latitude &&
                  a.client.contact.address.longitude
                    ? true
                    : false;

                return (
                  <div
                    className={`row ${hasLat ? "" : "missingLat"}`}
                    key={`staffDataAppts${a._id}`}
                  >
                    <div className="col-sm-2">
                      <Link to={`/client/${a.client._id}`}>
                        {a.client.full_name}
                      </Link>
                    </div>
                    <div className="col-sm-2">
                      <Link to={`/appointments/${a._id}`}>
                        {moment(a.actual_end_time).format("MM/DD/YY")}
                      </Link>
                    </div>
                    <div className="col-sm-2">
                      {a.credit ? "Credit Used" : null}{" "}
                      {a.item ? "Line Item" : null}
                    </div>
                    <div className="col-sm-2">
                      ${Number(a.price / 100).toFixed(2)}
                    </div>
                    <div className="col-sm-2">{a.appointment_type.name}</div>
                    <div className="col-sm-2"></div>
                  </div>
                );
              })}
          </div>
        ) : null}
      </div>
    );
  }
}
