import React, { Component } from "react";
import { DropdownList, Multiselect } from "react-widgets";
import { formatNumsToDollars, onlyNumbers } from "../../../../helpers";
let yesno = [{ text: "Yes", value: true }, { text: "No", value: false }];

class Addons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adding: false,
      displayPrice: "",
      allowed_times: ["morning", "midday", "evening"],
      default_times: ["morning", "midday", "evening"],
      addOn: {
        allowed_times: [],
        default_times: []
      }
    };
  }

  addAddOn = category => {
    let addons = [...this.props.facility.addOns, this.state.addOn];
    this.props.updateBasicInfo("add_on_services", addons, true);
  };

  renderAddOns = () => {
    return this.props.facility.add_on_services.map(a => {
      return (
        <div className="col-sm-12 addOnRow">
          <div className="col-sm-2">{a.name}</div>
          <div className="col-sm-2">{a.description}</div>
          <div className="col-sm-2">{a.category}</div>
          <div className="col-sm-2">{a.area}</div>
          <div className="col-sm-1">{a.type}</div>
          <div className="col-sm-2">{a.times}</div>
          <div className="col-sm-1">{formatNumsToDollars(a.price)}</div>
        </div>
      );
    });
  };

  toggleAddOn = cat => {
    this.setState({
      adding: true,
      addOn: {
        ...this.state.addOn,
        category: cat
      }
    });
  };

  updateAddOn = (key, val) => {
    this.setState({
      ...this.state,
      addOn: {
        ...this.state.addOn,
        [key]: val
      }
    });
  };

  reset = () => {
    this.setState({
      adding: false,
      addOn: {
        ...this.state.addOn,
        category: "",
        default_times: [],
        allowed_times: []
      }
    });
  };

  getAddOnForm = () => {
    let { category } = this.state.addOn;
    let capitalized = category
      ? category.charAt(0).toUpperCase() + category.slice(1)
      : "";
    return (
      <div className="col-sm-8 boarding">
        <div className="col-sm-12 withCancel">
          <h4>Add {capitalized} AddOn</h4>
          <button
            className="btn btn-cancel pull-right"
            onClick={() => this.reset()}
          >
            X
          </button>
        </div>
        <div className="col-sm-4">
          <h4>Name</h4>
          <input
            type="text"
            value={this.state.addOn.name}
            placeholder="NAME"
            onChange={e => this.updateAddOn("name", e.target.value)}
          />
        </div>
        <div className="col-sm-4">
          <h4>Description</h4>
          <input
            type="text"
            value={this.state.addOn.description}
            placeholder="DESCRIPTION"
            onChange={e => this.updateAddOn("description", e.target.value)}
          />
        </div>
        <div className="col-sm-4">
          <h4>Area</h4>
          <DropdownList
            data={this.props.facility.areas}
            textField="name"
            valueField="_id"
            onChange={val => {
              this.updateAddOn("area_id", val._id);
            }}
          />
        </div>
        <div className="col-sm-4">
          <h4>Price</h4>
          <input
            type="text"
            value={this.state.displayPrice}
            placeholder="Price"
            onChange={e => this.updatePrice(e.target.value)}
          />
        </div>
        <div className="col-sm-8">
          <h4>Variable Price</h4>
          <DropdownList
            data={yesno}
            textField="text"
            valueField="value"
            onChange={val => this.updateAddOn("variable_price", val.value)}
          />
        </div>

        {category === "appointment" ? this.getAppointmentAddOnForm() : null}
        {category === "pet" ? this.getPetAddOnForm() : null}
        {category === "schedule" ? this.getScheduleAddOnForm() : null}
      </div>
    );
  };

  updatePrice = price => {
    let newPrice = formatNumsToDollars(onlyNumbers(price));
    this.setState({
      displayPrice: newPrice,
      addOn: {
        ...this.state.addOn,
        price: onlyNumbers(price)
      }
    });
  };

  getAppointmentAddOnForm = () => {
    let days = [
      {
        text: "First Day",
        value: "first_day"
      },
      {
        text: "Last Day",
        value: "last_day"
      }
    ];

    return (
      <div className="">
        <div className="col-sm-4">
          <h4>Which Day In Visit?</h4>
          <DropdownList
            data={days}
            textField="text"
            valueField="value"
            onChange={val => this.updateAddOn("day_in_appointment", val.value)}
          />
        </div>
        <div className="col-sm-8">
          <h4>Default Time</h4>
          <div
            className={`toggle-box-container isAdmin editBoarding editDefaultTimesBox`}
          >
            {this.state.default_times.map((option, index) => {
              const checked = this.state.addOn.default_time === option;
              return (
                <div
                  className={`toggle-box weekdays-field editBoardingWeekday editDefaultTimes btn btn-tertiary${
                    checked ? " checked" : ""
                  }`}
                  key={`${option}-${index}`}
                  onClick={() => {
                    this.updateAddOn("default_time", option);
                  }}
                >
                  <label>{option}</label>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-sm-4">
          <h4>Time Bound?</h4>
          <DropdownList
            data={yesno}
            textField="text"
            valueField="value"
            onChange={val => {
              this.updateAddOn("time_bound", val.value);
            }}
          />
        </div>
        {this.state.addOn.time_bound ? (
          <div className="col-sm-4">
            <h4>Start Time</h4>
            <DropdownList
              data={this.props.times}
              placeholder="START TIME"
              onChange={val => this.updateAddOn("startTime", val)}
            />
          </div>
        ) : null}
        {this.state.addOn.time_bound ? (
          <div className="col-sm-4">
            <h4>End Time</h4>
            <DropdownList
              data={this.props.times}
              placeholder="END TIME"
              onChange={val => this.updateAddOn("endTime", val)}
            />
          </div>
        ) : null}
      </div>
    );
  };

  getScheduleAddOnForm = () => {
    return (
      <div className="">
        <div className="col-sm-3">
          <h4>Type</h4>
          <DropdownList
            data={["treat", "walk", "other"]}
            onChange={val => this.updateAddOn("type", val)}
          />
        </div>

        <div className="col-sm-3">
          <h4>Max Once Daily?</h4>
          <DropdownList
            data={yesno}
            textField="text"
            valueField="value"
            onChange={val => this.updateAddOn("max_once_daily", val.value)}
          />
        </div>
        <div className="col-sm-6">
          <h4>Allowed Times</h4>
          <div
            className={`toggle-box-container isAdmin editBoarding editDefaultTimesBox`}
          >
            {this.state.allowed_times.map((option, index) => {
              const checked = this.state.addOn.allowed_times.includes(option);
              return (
                <div
                  className={`toggle-box weekdays-field editBoardingWeekday editDefaultTimes btn btn-tertiary${
                    checked ? " checked" : ""
                  }`}
                  key={`${option}-${index}`}
                  onClick={() => {
                    const times = [...this.state.addOn.allowed_times];
                    if (times.includes(option)) {
                      times.splice(times.indexOf(option), 1);
                    } else {
                      times.push(option);
                    }
                    this.updateAddOn("allowed_times", times);
                  }}
                >
                  <label>{option}</label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  getPetAddOnForm = () => {
    let days = [
      {
        text: "First Day",
        value: "first_day"
      },
      {
        text: "Last Day",
        value: "last_day"
      }
    ];
    return (
      <div>
        <div className="col-sm-4">
          <h4>Which Day In Visit?</h4>
          <DropdownList
            data={days}
            textField="text"
            valueField="value"
            onChange={val => this.updateAddOn("day_in_appointment", val.value)}
          />
        </div>
        <div className="col-sm-6">
          <h4>Default Time</h4>
          <div
            className={`toggle-box-container isAdmin editBoarding editDefaultTimesBox`}
          >
            {this.state.default_times.map((option, index) => {
              const checked = this.state.addOn.default_time === option;
              return (
                <div
                  className={`toggle-box weekdays-field editBoardingWeekday editDefaultTimes btn btn-tertiary${
                    checked ? " checked" : ""
                  }`}
                  key={`${option}-${index}`}
                  onClick={() => {
                    this.updateAddOn("default_time", option);
                  }}
                >
                  <label>{option}</label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  saveAddOn = async () => {
    try {
      this.props.saveAddOn(this.state.addOn);
      this.setState({
        adding: false,
        displayPrice: "",
        allowed_times: ["morning", "midday", "evening"],
        default_times: ["morning", "midday", "evening"],
        addOn: {
          allowed_times: [],
          default_times: []
        }
      });
    } catch (e) {}
  };

  render() {
    return (
      <div className="row">
        {this.state.adding ? null : (
          <div className="col-sm-12">
            <div className="col-sm-2 areaTitle">Name</div>
            <div className="col-sm-2 areaTitle">Description</div>
            <div className="col-sm-2 areaTitle">Category*</div>
            <div className="col-sm-2 areaTitle">Area</div>
            <div className="col-sm-1 areaTitle">Type</div>
            <div className="col-sm-2 areaTitle">Times</div>
            <div className="col-sm-1 areaTitle">Price</div>
          </div>
        )}
        <div>{this.state.adding ? null : this.renderAddOns()}</div>
        {this.state.adding ? null : (
          <div className="row addOnButtons">
            <div className="col-sm-4">
              <button
                className="btn btn-primary"
                onClick={() => this.toggleAddOn("appointment")}
              >
                + Appointment Add-On
              </button>
            </div>
            <div className="col-sm-4">
              <button
                className="btn btn-primary"
                onClick={() => this.toggleAddOn("schedule")}
              >
                + Schedule Add-On
              </button>
            </div>
            <div className="col-sm-4">
              <button
                className="btn btn-primary"
                onClick={() => this.toggleAddOn("pet")}
              >
                + Pet Add-On
              </button>
            </div>
          </div>
        )}
        {this.state.adding ? this.getAddOnForm() : null}
        {this.state.adding ? (
          <div style={{ clear: "both", width: "65%", paddingTop: "15px" }}>
            <button
              className="btn btn-primary pull-right"
              onClick={() => this.saveAddOn()}
            >
              Save
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Addons;
