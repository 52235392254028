import {
  clientFirebaseAuth,
  firebaseAuth
} from "./firebaseAuth";

export function auth(email, pw, roles) {
  return firebaseAuth()
    .createUserWithEmailAndPassword(email, pw)
    .then(user => saveUser(user, roles));
}

export function logout() {
  return firebaseAuth().signOut();
}

export function login(email, pw) {
  return firebaseAuth().signInWithEmailAndPassword(email, pw);
}

export function resetPassword(email) {
  return firebaseAuth().sendPasswordResetEmail(email);
}

export function getUser() {
  return firebaseAuth().currentUser;
}

export function authClient(email, pw) {
  return clientFirebaseAuth
    .auth()
    .createUserWithEmailAndPassword(email, pw)
    .then(user => saveClientUser(user));
}

export function resetPasswordClient(email) {
  return clientFirebaseAuth.auth().sendPasswordResetEmail(email);
}

export function saveClientUser(userCredential) {
  
}
