import React, { Component } from "react";
import moment from "moment";
import { DateSwitcher, SchedulerRow, SitterDropdown } from "../../../";
import { schedulerRows } from "../../../../config/scheduler";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import {
  AdminCommentIcon,
  ClientCommentIcon,
  ScheduleAppointmentForm,
  ZoneBadge
} from "../../../";

export default class AssignmentPanel extends Component {

  componentWillReceiveProps(props) {
    if (props.staffList && props.staffList.length) {
      this.setState({
        staffListFull: props.staffList
      });
    }
  }

  state = {
    staffList: [],
    selectedSitters: []
  };

  setSelectedSitter = sitter => {
    this.props.addSitter(sitter);
  };

  onDateBack = () => {
    const {
      handleSelectAppointment,
      setSelectedDate,
      selectedDate
    } = this.props;
    setSelectedDate(
      moment(selectedDate)
        .subtract(1, "day")
        .toDate()
    );
    // this.props.setDate(moment(selectedDate).subtract(1, 'day').toDate());
  };

  onDateForward = () => {
    const {
      handleSelectAppointment,
      setSelectedDate,
      selectedDate
    } = this.props;
    setSelectedDate(
      moment(selectedDate)
        .add(1, "day")
        .toDate()
    );
    // this.props.setDate(moment(selectedDate).add(1, 'day').toDate());
  };

  render() {
    const {
      appointments,
      handleScheduleAppointment,
      handleSelectAppointment,
      handleTransitionAppointment,
      onClickAppointmentDetail,
      onScheduleFail,
      onScheduleSuccess,
      getAppointmentsForSitter,
      selectedAppointment,
      selectedDate,
      selectedSitters,
      staffList,
      transitionalAppointment,
      transitionalHourSelected,
      transitionalSitterId
    } = this.props;

    return (
      <div className="scheduler">
        <DateSwitcher
          dateFormat="dddd, MMMM D, YYYY"
          max={moment().add(2, "weeks")}
          min={moment()}
          onDateBack={this.onDateBack}
          onDateForward={this.onDateForward}
          selectedDate={moment(selectedDate)}
        />
        <div className="scheduler-row">
          {staffList.map(staff => {
            return (
              <div key={staff._id}>
                <div className="sitter-list-item">
                  <span>{staff.contact.full_name}</span>
                  <div className="zone-container">
                    {staff.zones && staff.zones.length
                      ? staff.zones.map(zone => (
                          <ZoneBadge
                            key={`sitter-${staff.id}-dropdown-zone-${zone.id}`}
                            size="tiny"
                            zone={zone}
                          />
                        ))
                      : null}
                  </div>
                </div>
                <SitterDropdown
                  sitter={staff}
                  appointments={appointments}
                  selectedSitters={this.state.selectedSitters}
                  setSelectedSitter={this.setSelectedSitter}
                  getAppointmentsForSitter={getAppointmentsForSitter}
                  assignedAppointments={this.props.assignedAppointments}
                  onClickAppointmentDetail={onClickAppointmentDetail}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
