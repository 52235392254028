import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";

// Actions
import {
  approveAppointment,
  updateMultipleAppointments,
  approveAllAppointments,
  getAppointments as getAppointmentsAction,
  scheduleAppointment as scheduleAppointmentAction
} from "../../actions/admin/appointments.actions";
import {
  setSchedulerCalendarDay,
  setSchedulerCalendarMonth,
  setSchedulerCalendarWeek,
  setSchedulerFilters,
  setSchedulerSelectedDate,
  setSchedulerSelectedSitter,
  addSitter,
  getAppointmentsForSitter
} from "../../actions/admin/scheduler.actions";
import { getAllStaffWithAppointments } from "../../actions/admin/staff.actions";
import {
  pushModal,
  popModal,
  showFormErrorMessage,
  showSuccessMessage
} from "../../actions/general.actions";

// Components
import { AdminAppointmentDetailPage } from "../";
import {
  CalendarView,
  LegendBar,
  SchedulingTool,
  OverlayModal
} from "../../components";

class AdminScheduler extends Component {

  state = {
    view: "scheduling", // or 'calendar'
    calendarType: "day" // or 'week' or 'month'
  };

  componentWillMount = () => {
    this.props.loadStaff({
      endDate: moment(this.props.selectedDate)
        .endOf("day")
        .toDate(),
      startDate: moment(this.props.selectedDate)
        .startOf("day")
        .toDate()
    });
  };

  changeSchedulerView = view => this.setState({ view });
  changeCalendarView = calendarType => this.setState({ calendarType });

  onClickAppointmentCard = appointmentId => {
    const { modalActions } = this.props;
    modalActions.push(
      <OverlayModal
        fullScreen
        onDismiss={() => modalActions.pop()}
        textAlign="left"
      >
        <AdminAppointmentDetailPage appointmentId={appointmentId} />
      </OverlayModal>
    );
  };

  render() {
    const { calendarType, view } = this.state;
    const {
      adminApproveAppointment,
      updateMultipleAppointments,
      approveAllAppointments,
      assignedAppointments,
      appointments,
      calendarDay,
      calendarMonth,
      calendarWeek,
      calendarFilters,
      displayFormError,
      displayFormSuccess,
      getAppointments,
      getAppointmentsForSitter,
      scheduleAppointment,
      selectedDate,
      selectedSitters,
      setCalendarDay,
      setCalendarMonth,
      setCalendarWeek,
      setFilters,
      setSelectedDate,
      setSelectedSitter,
      staffList,
      unassignedFilters
    } = this.props;

    // Filter out Canceled and Rejected appointments
    let liveAppointments = [];
    if (appointments) {
      liveAppointments = appointments.filter(appt => {
        return (
          appt.appointment_status.name !== "Canceled" &&
          appt.appointment_status.name !== "Rejected" &&
          !appt.sitter_id
        );
      });
    }

    return (
      <div className="container-fluid">
        <LegendBar />
        {staffList && view === "calendar" ? (
          <CalendarView
            appointments={liveAppointments}
            assignedAppointments={assignedAppointments}
            calendarDay={calendarDay}
            calendarMonth={calendarMonth}
            calendarWeek={calendarWeek}
            calendarFilters={calendarFilters}
            calendarView={calendarType}
            changeCalendarView={this.changeCalendarView}
            changeSchedulerView={this.changeSchedulerView}
            getAppointments={getAppointments}
            onClickAppointmentCard={this.onClickAppointmentCard}
            schedulerView={view}
            selectedDate={selectedDate}
            setCalendarDay={setCalendarDay}
            setCalendarMonth={setCalendarMonth}
            setCalendarWeek={setCalendarWeek}
            setCalendarFilters={filters => setFilters(filters, "calendar")}
          />
        ) : null}

        {staffList && view === "scheduling" ? (
          <SchedulingTool
            addSitter={this.props.addSitter}
            calendarFilters={calendarFilters}
            appointments={liveAppointments}
            approveAppointment={adminApproveAppointment}
            updateMultipleAppointments={updateMultipleAppointments}
            approveAllAppointments={approveAllAppointments}
            assignedAppointments={assignedAppointments}
            changeSchedulerView={this.changeSchedulerView}
            dispatch={this.props.dispatch}
            getAppointments={getAppointments}
            getAppointmentsForSitter={getAppointmentsForSitter}
            scheduleAppointment={scheduleAppointment}
            onClickAppointmentDetail={this.onClickAppointmentCard}
            onScheduleFail={errors => displayFormError(errors)}
            onScheduleSuccess={() =>
              displayFormSuccess("Appointment successfully scheduled.")
            }
            schedulerView={view}
            selectedDate={selectedDate}
            selectedSitters={selectedSitters}
            setSelectedDate={setSelectedDate}
            setSelectedSitter={setSelectedSitter}
            setCalendarFilters={filters => setFilters(filters, "calendar")}
            setUnassignedFilters={filters => setFilters(filters, "unassigned")}
            staffList={staffList}
            unassignedFilters={unassignedFilters}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  appointments: state.admin.appointments.appointmentList,
  assignedAppointments: state.admin.scheduler.assignedAppointments,
  calendarDay: state.admin.scheduler.calendarDay,
  calendarMonth: state.admin.scheduler.calendarMonth,
  calendarWeek: state.admin.scheduler.calendarWeek,
  selectedDate: state.admin.scheduler.selectedDate,
  calendarFilters: state.admin.scheduler.calendarFilters,
  selectedSitters: state.admin.scheduler.sitterColumns,
  staffList: state.admin.staff.staffList,
  unassignedFilters: state.admin.scheduler.unassignedFilters,
  selectedDate: state.admin.scheduler.selectedDate
});

const mapDispatchToProps = dispatch => ({
  addSitter: sitter => dispatch(addSitter(sitter)),
  adminApproveAppointment: (appointmentId, model) =>
    dispatch(approveAppointment(appointmentId, model)),
  approveAllAppointments: apps => dispatch(approveAllAppointments(apps)),
  updateMultipleAppointments: apps =>
    dispatch(updateMultipleAppointments(apps)),
  displayFormError: errors =>
    dispatch(showFormErrorMessage(errors, "adminAppointmentDetails")),
  displayFormSuccess: message =>
    dispatch(showSuccessMessage(message, "adminAppointmentDetails")),
  getAppointments: filters => dispatch(getAppointmentsAction(filters)),
  getAppointmentsForSitter: sitter =>
    dispatch(getAppointmentsForSitter(sitter)),
  scheduleAppointment: (appointmentId, model) =>
    dispatch(scheduleAppointmentAction(appointmentId, model)),
  loadStaff: qs => dispatch(getAllStaffWithAppointments(qs)),
  saveAllAppointments: apps => dispatch(updateMultipleAppointments(apps)),
  setCalendarDay: day => dispatch(setSchedulerCalendarDay(day)),
  setCalendarMonth: month => dispatch(setSchedulerCalendarMonth(month)),
  setCalendarWeek: week => dispatch(setSchedulerCalendarWeek(week)),
  setFilters: (filters, context) =>
    dispatch(setSchedulerFilters(filters, context)),
  setSelectedDate: date => dispatch(setSchedulerSelectedDate(date)),
  setSelectedSitter: sitterId => dispatch(setSchedulerSelectedSitter(sitterId)),
  modalActions: {
    push: component => dispatch(pushModal(component)),
    pop: () => dispatch(popModal())
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminScheduler);
