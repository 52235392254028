import React, { Component } from "react";
import moment from "moment-timezone";
import Tooltip from "rc-tooltip";
import PetCardCompact from "./scheduler/PetCardCompact";
import ZoneBadge from "./ZoneBadge";
import Timeblock from "./Timeblock";
import { DropdownList } from "react-widgets";
import _ from "lodash";
import history from "../../actions/history";
import TimePicker from "rc-time-picker";
import { connect } from "react-redux";
import axios from "../../services/network.js";
import SitterBreakRow from "./SitterBreakRow";

class RouteModal extends Component {
  state = {
    availableSitters: [],
    selectedSitters: [],
    selectedAppts: [],
    showAll: false,
    time: moment().hour(9).minute(0).toDate(),
    minVisits: "3",
    maxVisits: "8",
    lateness: "7",
    noteTime: 7,
    select: false,
    zones: false,
    traffic: "normal",
    shortest_distance: false,
    balance: false,
    selectedZones: [],
    appointmentZones: [],
    selectAll: false,
    showOnlyWorkingSitters: true,
    useZones: true,
    strict_start: false,
    max_vehicle_overtime: 0,
    min_vehicles: false,
    min_speed: null,
    priority_type: "new",
    shifts: [],
    ghosts: null,
    max_shifts: 0,
    mapanything: false,
    advanced: true,
    adjustbreaks: false,
    last_tms: false,
    timeout: null,
    renderSpecialAdvanced: false,
    renderGhostShifts: false,
    timeFactors: false,
    consultation_tms: {
      penalty_per_violation: 1000,
    },
    time_windows: {
      penalty_per_violation: 1000,
      violation_increment: 3600,
    },
    visit_range: {
      penalty_per_violation: 10000,
      violation_increment: 1,
    },
    match_attributes: {
      penalty_per_violation: 1000000,
    },
    order_priority: {
      penalty_per_violation: 100,
    },
    travel_time: {
      penalty_per_violation: 1,
      violation_increment: 1,
    },
    prefer_last_tms: {
      penalty_per_violation: 100,
    },
    speed: {
      slow: 1.25,
      normal: 1,
      fast: 0.9,
      faster: 0.8,
    },
  };

  componentDidMount() {
    if (this.props.time) {
      this.setState({
        time: moment(this.props.time).toDate(),
      });
    }
  }

  renderPending = () => {
    let statuses = [1, 2, 3];
    if (!this.props.appointments) return null;
    let filtered = this.props.appointments.filter(
      (a) => a.appointment_status.id === 1
    );
    if (!filtered.length) return <div>N/A</div>;
    return filtered.map((a) => {
      return (
        <div key={`routeModalAppt${a._id}`} className="apptPending row">
          <div className="col-sm-1">
            <input
              type="checkbox"
              checked={this.state.selectedAppts.includes(a._id)}
              onChange={() => this.toggleAppts(a)}
            />
          </div>
          <div className="col-sm-6">
            {a.contact.full_name}
            {a.home.zones.map((z) => (
              <ZoneBadge
                size="tiny"
                zone={z}
                key={`aZone${a._id}${z.id ? z.id : Math.random() * 10}`}
              />
            ))}
          </div>
          <div className="col-sm-3">
            {moment(a.window_start_time).format("hh:mm")} -{" "}
            {moment(a.window_end_time).format("hh:mm")}
          </div>
          <div className="col-sm-2">
            {a.appointment_type.duration_in_minutes} Min.
          </div>
        </div>
      );
    });
  };

  renderApproved = () => {
    let statuses = [1, 2, 3];
    if (!this.props.appointments) return null;
    let filtered = this.props.appointments.filter(
      (a) => a.appointment_status.id === 2 && (!a.sitter || !a.sitter._id)
    );
    if (!filtered.length) return <div>N/A</div>;
    return filtered.map((a) => {
      return (
        <div key={`routeModalAppt${a._id}`} className="apptUnassigned row">
          <div className="col-sm-1">
            <input
              type="checkbox"
              checked={this.state.selectedAppts.includes(a._id)}
              onChange={() => this.toggleAppts(a)}
            />
          </div>
          <div className="col-sm-6">
            {a.contact.full_name}
            {a.home.zones.map((z) => (
              <ZoneBadge
                size="tiny"
                zone={z}
                key={`aZone${a._id}${z.id ? z.id : Math.random() * 10}`}
              />
            ))}
          </div>
          <div className="col-sm-3">
            {moment(a.window_start_time).format("hh:mm")} -{" "}
            {moment(a.window_end_time).format("hh:mm")}
          </div>
          <div className="col-sm-2">
            {a.appointment_type.duration_in_minutes} Min.
          </div>
        </div>
      );
    });
  };

  toggleAppts = (appt) => {
    let appts = this.state.selectedAppts.slice();
    let findAppt = appts.findIndex((a) => a._id === appt._id);
    if (findAppt > -1) {
      appts.splice(findAppt, 1);
    } else {
      appts.push(appt._id);
    }

    this.setState({ selectedAppts: appts });
  };

  toggleSitter = (_id) => {
    let sitters = this.state.selectedSitters.slice();
    let findSit = sitters.findIndex((id) => id === _id);
    if (findSit > -1) {
      sitters.splice(findSit, 1);
    } else {
      sitters.push(_id);
    }
    this.setState({ selectedSitters: sitters });
  };

  renderTeam = () => {
    if (!this.props.sitters) return null;
    return this.props.sitters
      .filter((s) => {
        if (!this.state.useZones) return true;
        let zones = this.state.selectedZones;
        let hasZone;
        s.zones.forEach((z) => {
          let findIt = zones.find((az) => az === z.id);
          if (findIt) hasZone = true;
        });
        return hasZone;
      })
      .sort((a, b) => {
        if (a.contact.full_name > b.contact.full_name) return 1;
        if (a.contact.full_name < b.contact.full_name) return -1;
        return 0;
      })
      .filter((s) => {
        if (this.state.showOnlyWorkingSitters) return s.shift || s._id === null;
        return s;
      })
      .map((s) => {
        return (
          <div key={`routeModalSit${s._id}`} className="row sitterStuff">
            <div className="col-sm-1">
              <input
                type="checkbox"
                checked={this.state.selectedSitters.includes(s._id)}
                onChange={() => this.toggleSitter(s._id)}
              />
            </div>
            <div className="col-sm-6">{s.contact.full_name}</div>
            <div className="col-sm-5">
              {s.shift ? (
                <span>
                  {moment(s.shift.StartTimeLocalized).format("hh:mm")} -{" "}
                  {moment(s.shift.EndTimeLocalized).format("hh:mm")}
                </span>
              ) : null}
            </div>
            <div className="col-sm-12 nameZones">
              {s.zones
                ? s.zones.map((z) => <ZoneBadge size="tiny" zone={z} />)
                : null}
            </div>
          </div>
        );
      });
  };

  runAll = () => {
    if (this.state.mapanything) {
      this.runMapAnything();
    } else {
      let wat = {
        time: this.state.time,
        minVisits: this.state.minVisits,
        lateness: this.state.lateness,
        traffic: this.state.traffic,
        noteTime: this.state.noteTime ? this.state.noteTime : 0,
        min_speed: this.state.min_speed,
        max_vehicle_overtime: this.state.max_vehicle_overtime,
        priority_type: this.state.priority_type,
      };

      if (this.state.shortest_distance) wat.shortest_distance = true;
      if (this.state.balance) wat.balance = true;
      if (this.state.zones) wat.zones = true;
      if (this.state.min_vehicles) wat.min_vehicles = true;
      if (this.state.strict_start) wat.strict_start = true;

      this.props.runAll(wat, this.state.shifts);
    }
  };

  runMapAnything = () => {
    let wat = {
      minVisits: this.state.minVisits,
      maxVisits: this.state.maxVisits,
      noteTime: this.state.noteTime ? this.state.noteTime : 0,
      priority_type: this.state.priority_type,
      advanced: this.state.advanced ? {} : false,
      last_tms: this.state.last_tms,
    };

    if (this.state.advanced) {
      wat.advanced.time_windows = this.state.time_windows;
      wat.advanced.travel_time = this.state.travel_time;
      wat.advanced.match_attributes = this.state.match_attributes;
      wat.advanced.order_priority = this.state.order_priority;
      wat.advanced.visit_range = this.state.visit_range;
      wat.advanced.prefer_last_tms = this.state.prefer_last_tms;
      wat.advanced.consultation_tms = this.state.consultation_tms;
      wat.advanced.speed = this.state.speed;
    }

    this.props.runMapAnything(wat, this.state.shifts);
  };

  runPartialMapAnything = () => {
    let qs = {
      minVisits: this.state.minVisits,
      maxVisits: this.state.maxVisits,
      noteTime: this.state.noteTime ? this.state.noteTime : 0,
      priority_type: this.state.priority_type,
      advanced: this.state.advanced ? {} : false,
      last_tms: this.state.last_tms,
    };
    if (this.state.advanced) {
      qs.advanced.time_windows = this.state.time_windows;
      qs.advanced.travel_time = this.state.travel_time;
      qs.advanced.match_attributes = this.state.match_attributes;
      qs.advanced.order_priority = this.state.order_priority;
      qs.advanced.visit_range = this.state.visit_range;
      qs.advanced.prefer_last_tms = this.state.prefer_last_tms;
      qs.advanced.consultation_tms = this.state.consultation_tms;
      qs.advanced.speed = this.state.speed;
    }

    if (!this.state.selectedSitters.length || !this.state.selectedAppts.length)
      return;

    this.props.runSomeMapAnything(
      this.state.selectedSitters,
      this.state.selectedAppts,
      qs
    );
  };

  runSchedule = () => {
    if (this.state.mapanything) {
      this.runPartialMapAnything();
    } else {
      let qs = {
        time: this.state.time,
        minVisits: this.state.minVisits,
        lateness: this.state.lateness,
        traffic: this.state.traffic,
        noteTime: this.state.noteTime ? this.state.noteTime : 0,
        min_speed: this.state.min_speed,
        max_vehicle_overtime: this.state.max_vehicle_overtime,
        priority_type: this.state.priority_type,
      };
      if (this.state.shortest_distance) qs.shortest_distance = true;
      if (this.state.balance) qs.balance = true;
      if (this.state.zones) qs.zones = true;
      if (this.state.min_vehicles) qs.min_vehicles = true;
      if (this.state.strict_start) qs.strict_start = true;
      if (
        !this.state.selectedSitters.length ||
        !this.state.selectedAppts.length
      )
        return;

      this.props.runSome(
        this.state.selectedSitters,
        this.state.selectedAppts,
        qs
      );
    }
  };

  toggleZone = (zone) => {
    let selectedZones = this.state.selectedZones.slice();
    let findIt = selectedZones.findIndex((z) => z == zone.id);
    if (findIt > -1) {
      selectedZones.splice(findIt, 1);
    } else {
      selectedZones.push(zone.id);
    }

    let availableSitters = this.props.sitters
      .filter((s) => {
        if (!this.state.useZones) return true;
        let zones = selectedZones;
        let hasZone;
        s.zones.forEach((z) => {
          let findIt = zones.find((az) => az === z.id);
          if (findIt) hasZone = true;
        });
        return hasZone;
      })
      .filter((s) => {
        if (this.state.showOnlyWorkingSitters) return s.shift || s._id === null;
        return s;
      })
      .map((s) => s._id);

    this.setState({
      selectedZones: selectedZones,
      availableSitters: availableSitters,
    });

    if (this.state.selectAll) {
      this.setState({ selectedSitters: availableSitters });
    }
  };

  selectAllTeamMembers = () => {
    if (this.state.selectAll) {
      this.setState({ selectedSitters: [], selectAll: !this.state.selectAll });
    } else {
      let sitters = this.props.sitters
        .filter((s) => {
          if (!this.state.useZones) return true;
          let zones = this.state.selectedZones;
          let hasZone;
          s.zones.forEach((z) => {
            let findIt = zones.find((az) => az === z.id);
            if (findIt) hasZone = true;
          });
          return hasZone;
        })
        .filter((s) => {
          if (this.state.showOnlyWorkingSitters)
            return s.shift || s._id === null;
          return s;
        })
        .map((s) => s._id);
      this.setState({
        selectedSitters: sitters,
        selectAll: !this.state.selectAll,
      });
    }
  };

  toggleUseZones = () => {
    let availableSitters = this.props.sitters
      .filter((s) => {
        if (this.state.showOnlyWorkingSitters) return s.shift || s._id === null;
        return s;
      })
      .map((s) => s._id);
    this.setState({ useZones: !this.state.useZones, availableSitters });
  };

  toggleStrictStart = () => {
    this.setState({ strict_start: !this.state.strict_start });
  };

  toggleMinVehicles = () => {
    this.setState({ min_vehicles: !this.state.min_vehicles });
  };

  toggleWorkingSitters = () => {
    this.setState({
      showOnlyWorkingSitters: !this.state.showOnlyWorkingSitters,
    });
  };

  lockFirstVisits = () => {
    this.props.lockFirstVisits();
  };

  toggleSelect = () => {
    this.setState({ select: !this.state.select });
  };

  changeStart = (e, i) => {
    let time = e.target.value;
    let shifts = [...this.state.shifts];
    if (shifts[i]) {
      shifts[i].shift_start = time;
      this.setState({ shifts });
    }
  };

  changeEnd = (e, i) => {
    let time = e.target.value;
    let shifts = [...this.state.shifts];
    if (shifts[i]) {
      shifts[i].shift_end = time;
      this.setState({ shifts });
    }
  };

  advancedOptions = () => {
    return (
      <div className="col-sm-3">
        <label>Advanced Options</label>
        <input
          type="checkbox"
          checked={this.state.advanced}
          onChange={(e) => this.setState({ advanced: !this.state.advanced })}
        />
        <label>Prefer Last 5 TMs</label>
        <input
          type="checkbox"
          checked={this.state.last_tms}
          onChange={(e) => this.setState({ last_tms: !this.state.last_tms })}
        />
      </div>
    );
  };

  changeAdvanced = (key, key2, value) => {
    this.setState({
      ...this.state,
      [key]: {
        ...this.state[key],
        [key2]: value,
      },
    });
  };

  changeSpeed = (key, value) => {
    this.setState({
      ...this.state,
      speed: {
        ...this.state.speed,
        [key]: value,
      },
    });
  };

  renderSpecialAdvanced = () => {

    return null

    return (
      <div className="row renderSpecial">
        <div className="row dropdownThing">
          <h5
            onClick={() =>
              this.setState({
                renderSpecialAdvanced: !this.state.renderSpecialAdvanced,
              })
            }
          >
            MapAnything Advanced Constraints
            <i
              className={`fa fa-chevron-down ${
                this.state.renderSpecialAdvanced ? "twistigooDown" : "twistigoo"
              }`}
            />
          </h5>
        </div>
        <div
          className={`row ${
            this.state.renderSpecialAdvanced ? "helloThere" : "goodbye"
          }`}
        >
          <div className="col-sm-2 maNamesAgain">
            <div>Penalty Per Violation</div>
            <div>Violation Increment</div>
          </div>
          <div className="col-sm-10">
            <div className="col-sm-2 maTypes">
              <h5>Honor Time Windows</h5>
              <div>
                <input
                  type="text"
                  value={this.state.time_windows.penalty_per_violation}
                  onChange={(e) =>
                    this.changeAdvanced(
                      "time_windows",
                      "penalty_per_violation",
                      e.target.value
                    )
                  }
                />
                <input
                  type="text"
                  value={this.state.time_windows.violation_increment}
                  onChange={(e) =>
                    this.changeAdvanced(
                      "time_windows",
                      "violation_increment",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
            <div className="col-sm-2 maTypes">
              <h5>Order Priority</h5>
              <div>
                <input
                  type="text"
                  value={this.state.order_priority.penalty_per_violation}
                  onChange={(e) =>
                    this.changeAdvanced(
                      "order_priority",
                      "penalty_per_violation",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
            <div className="col-sm-2 maTypes">
              <h5>Match Attributes</h5>
              <div>
                <input
                  type="text"
                  value={this.state.match_attributes.penalty_per_violation}
                  onChange={(e) =>
                    this.changeAdvanced(
                      "match_attributes",
                      "penalty_per_violation",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
            <div className="col-sm-2 maTypes">
              <h5>Travel Time</h5>
              <div>
                <input
                  type="text"
                  value={this.state.travel_time.penalty_per_violation}
                  onChange={(e) =>
                    this.changeAdvanced(
                      "travel_time",
                      "penalty_per_violation",
                      e.target.value
                    )
                  }
                />
                <input
                  type="text"
                  value={this.state.travel_time.violation_increment}
                  onChange={(e) =>
                    this.changeAdvanced(
                      "travel_time",
                      "violation_increment",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
            <div className="col-sm-2 maTypes">
              <h5>Visit Range</h5>
              <div>
                <input
                  type="text"
                  value={this.state.visit_range.penalty_per_violation}
                  onChange={(e) =>
                    this.changeAdvanced(
                      "visit_range",
                      "penalty_per_violation",
                      e.target.value
                    )
                  }
                />
                <input
                  type="text"
                  value={this.state.visit_range.violation_increment}
                  onChange={(e) =>
                    this.changeAdvanced(
                      "visit_range",
                      "violation_increment",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
            <div className="col-sm-2 maTypes">
              <h5>Prefer Consultation TMs for 1st Visits</h5>
              <div>
                <input
                  type="text"
                  value={this.state.consultation_tms.penalty_per_violation}
                  onChange={(e) =>
                    this.changeAdvanced(
                      "consultation_tms",
                      "penalty_per_violation",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
            {this.state.last_tms ? (
              <div className="col-sm-2 maTypes">
                <h5>Prefer Last 5 TMs</h5>
                <div>
                  <input
                    type="text"
                    value={this.state.prefer_last_tms.penalty_per_violation}
                    onChange={(e) =>
                      this.changeAdvanced(
                        "prefer_last_tms",
                        "penalty_per_violation",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  renderTimeFactors = () => {
    return (
      <div className="row renderSpecial">
        <div className="row dropdownThing">
          <h5
            onClick={() =>
              this.setState({ timeFactors: !this.state.timeFactors })
            }
          >
            Team Member Speed Factors
            <i
              className={`fa fa-chevron-down ${
                this.state.timeFactors ? "twistigooDown" : "twistigoo"
              }`}
            />
          </h5>
        </div>
        <div
          className={`row ${this.state.timeFactors ? "helloThere" : "goodbye"}`}
        >
          <div className="col-sm-12 maTypes">
            <h5>Team Member Speed Factors</h5>
            <div className="noBord">
              Each time factor corresponds to one of the team member speeds
              (adjustable in the team member profile). The entire duration of a
              visit (visit time + note time + entry/exit) will be multiplied by
              this factor. This does not affect driving speed or time between
              visits.
            </div>
            <div className="col-sm-3">
              <label>Slow</label>
              <br />
              <input
                type="text"
                value={this.state.speed.slow}
                onChange={(e) => this.changeSpeed("slow", e.target.value)}
              />
            </div>
            <div className="col-sm-3">
              <label>Normal</label>
              <br />
              <input
                type="text"
                value={this.state.speed.normal}
                onChange={(e) => this.changeSpeed("normal", e.target.value)}
              />
            </div>
            <div className="col-sm-3">
              <label>Fast</label>
              <br />
              <input
                type="text"
                value={this.state.speed.fast}
                onChange={(e) => this.changeSpeed("fast", e.target.value)}
              />
            </div>
            <div className="col-sm-3">
              <label>Faster</label>
              <br />
              <input
                type="text"
                value={this.state.speed.faster}
                onChange={(e) => this.changeSpeed("faster", e.target.value)}
              />
            </div>
            <div className="noBord">
              <h5>Actual Visit Durations (with 2 minute entry & exit times)</h5>
            </div>
            <div className="col-sm-3 extraBord">
              <label>20 Min</label>
              <br />
              <span className="speedLabel">Slow</span>{" "}
              <span className="speedVal">
                {((24 + this.state.noteTime) * this.state.speed.slow).toFixed(
                  1
                )}
              </span>
              <br />
              <span className="speedLabel">Normal</span>{" "}
              <span className="speedVal">
                {((24 + this.state.noteTime) * this.state.speed.normal).toFixed(
                  1
                )}
              </span>
              <br />
              <span className="speedLabel">Fast</span>{" "}
              <span className="speedVal">
                {((24 + this.state.noteTime) * this.state.speed.fast).toFixed(
                  1
                )}
              </span>
              <br />
              <span className="speedLabel">Faster</span>{" "}
              <span className="speedVal">
                {((24 + this.state.noteTime) * this.state.speed.faster).toFixed(
                  1
                )}
              </span>
              <br />
            </div>
            <div className="col-sm-3 extraBord">
              <label>40 Min</label>
              <br />
              <span className="speedLabel">Slow</span>{" "}
              <span className="speedVal">
                {((44 + this.state.noteTime) * this.state.speed.slow).toFixed(
                  1
                )}
              </span>
              <br />
              <span className="speedLabel">Normal</span>{" "}
              <span className="speedVal">
                {((44 + this.state.noteTime) * this.state.speed.normal).toFixed(
                  1
                )}
              </span>
              <br />
              <span className="speedLabel">Fast</span>{" "}
              <span className="speedVal">
                {((44 + this.state.noteTime) * this.state.speed.fast).toFixed(
                  1
                )}
              </span>
              <br />
              <span className="speedLabel">Faster</span>{" "}
              <span className="speedVal">
                {((44 + this.state.noteTime) * this.state.speed.faster).toFixed(
                  1
                )}
              </span>
              <br />
            </div>
            <div className="col-sm-3 extraBord">
              <label>60 Min</label>
              <br />
              <span className="speedLabel">Slow</span>{" "}
              <span className="speedVal">
                {((64 + this.state.noteTime) * this.state.speed.slow).toFixed(
                  1
                )}
              </span>
              <br />
              <span className="speedLabel">Normal</span>{" "}
              <span className="speedVal">
                {((64 + this.state.noteTime) * this.state.speed.normal).toFixed(
                  1
                )}
              </span>
              <br />
              <span className="speedLabel">Fast</span>{" "}
              <span className="speedVal">
                {((64 + this.state.noteTime) * this.state.speed.fast).toFixed(
                  1
                )}
              </span>
              <br />
              <span className="speedLabel">Faster</span>{" "}
              <span className="speedVal">
                {((64 + this.state.noteTime) * this.state.speed.faster).toFixed(
                  1
                )}
              </span>
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderGhostShifts = () => {
    return (
      <div className="row renderSpecial">
        <div className="row dropdownThing">
          <h5
            onClick={() =>
              this.setState({
                renderGhostShifts: !this.state.renderGhostShifts,
              })
            }
          >
            Ghost Shifts
            <i
              className={`fa fa-chevron-down ${
                this.state.renderGhostShifts ? "twistigooDown" : "twistigoo"
              }`}
            />
            <i className="fa fa-snapchat-ghost" />
          </h5>
        </div>
        <div
          className={`row ${
            this.state.renderGhostShifts ? "helloThere" : "goodbye"
          }`}
        >
          <div className="col-sm-12">
            <button
              onClick={() => this.addGhost()}
              className="btn btn-secondary"
            >
              Add Ghost
            </button>
            {this.renderGhosts()}
          </div>
        </div>
      </div>
    );
  };

  showAdvancedOptions = () => {
    return (
      <div className="row maStuff">
        {this.renderSpecialAdvanced()}
        {this.renderTimeFactors()}
        {this.renderGhostShifts()}
        {this.showBreaks()}
      </div>
    );
  };

  getTimes = () => {
    let times = [];
    for (
      let i = moment("09:00", "HH:mm");
      i.isBefore(moment("20:00", "HH:mm"));
      i.add(15, "minutes")
    ) {
      times.push(i.format("hh:mm a"));
    }
    return times;
  };

  addGhost = async (obj) => {
    try {
      this.props.showSpinner();
      let market_id = this.props.market._id;
      let ghost = {
        ghost_shift_start: "11:00",
        ghost_shift_end: "14:00",
        market_id,
      };

      if (obj) {
        ghost._id = obj._id;
        ghost.ghost_shift_start = obj.ghost_shift_start
          ? obj.ghost_shift_start
          : "11:00";
        ghost.ghost_shift_end = obj.ghost_shift_end
          ? obj.ghost_shift_end
          : "14:00";
      }

      let { data } = await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/createGhost`,
        { ghost },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      this.props.addStaff(data);
      this.props.stopSpinner();
    } catch (e) {
      console.log("error.....", e);
      this.props.stopSpinner();
      let message = e.data && e.data.message ? e.data.message : "Error";
      this.props.showMessage({ messageType: "error", message });
      setTimeout(this.props.removeMessage, 3000);
      // alert('Bad Error')
    }
  };

  renderGhosts = () => {
    let ghosts = this.props.sitters.filter((s) => s.ghost);
    return ghosts.map((s) => {
      return (
        <div className="col-sm-12 ghosty">
          <div className="col-sm-3">{s.contact.full_name}</div>
          <div className="col-sm-3">
            <DropdownList
              data={this.getTimes()}
              value={moment(s.shift.StartTimeLocalized).format("hh:mm a")}
              onChange={(val) => this.changeGhost(s, val, "ghost_shift_start")}
            />
          </div>
          <div className="col-sm-3">
            <DropdownList
              data={this.getTimes()}
              value={moment(s.shift.EndTimeLocalized).format("hh:mm a")}
              onChange={(val) => this.changeGhost(s, val, "ghost_shift_end")}
            />
          </div>
          <div className="col-sm-3 removeGhost">
            <span
              onClick={() => this.removeGhost(s)}
              style={{ cursor: "pointer" }}
            >
              X
            </span>
          </div>
        </div>
      );
    });
  };

  changeGhost = (ghost, time, type) => {
    let newTime = moment(time, "hh:mm a").format("HH:mm");
    let newGhost = {
      _id: ghost._id,
      ghost_shift_start: moment(ghost.shift.StartTimeLocalized).format("HH:mm"),
      ghost_shift_end: moment(ghost.shift.EndTimeLocalized).format("HH:mm"),
    };
    if (time && type) {
      newGhost[type] = newTime;
    }
    this.addGhost(newGhost);
  };

  removeGhost = async (ghost) => {
    try {
      let { market } = this.props;
      this.props.showSpinner();
      ghost.market_id = market._id;
      let { data } = await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/removeGhost`,
        ghost,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      this.props.removeStaff(ghost);
      this.props.stopSpinner();
    } catch (e) {
      console.log("error.....", e);
      this.props.stopSpinner();
      let message = e.data && e.data.message ? e.data.message : "Error";
      this.props.showMessage({ messageType: "error", message });
      setTimeout(this.props.removeMessage, 3000);
      // alert('Bad Error')
    }
  };

  floatingBreakDuration = (id, duration) => {
    this.props.adjustBreakDuration(id, duration);
    //janky debounce
    let { timeout } = this.state;
    clearTimeout(timeout);
    let newTimeout = setTimeout(async () => {
      await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/staffBreak/${id}?break=true&floating_break_duration=${duration}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
    }, 1000);
    this.setState({ timeout: newTimeout });
  };

  showBreaks = () => {
    return (
      <div className="row renderSpecial">
        <div className="row dropdownThing">
          <h5
            onClick={() =>
              this.setState({ renderBreaks: !this.state.renderBreaks })
            }
          >
            Adjust Breaks
            <i
              className={`fa fa-chevron-down ${
                this.state.renderBreaks ? "twistigooDown" : "twistigoo"
              }`}
            />
          </h5>
        </div>
        <div
          className={`row ${
            this.state.renderBreaks ? "helloThere" : "goodbye"
          }`}
        >
          <div className="col-sm-12">
            <h4 style={{ fontSize: "16px", fontWeight: "bold" }}>
              Team Members
            </h4>
            <p>
              Click the 'floating break' checkbox to add/remove a break. To
              manually adjust the time window for the break, use the dropdowns,
              then click 'save'.
            </p>
          </div>

          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-2">Name</div>
              <div className="col-sm-3">Shift</div>
              <div className="col-sm-7">Floating Break</div>
            </div>
          </div>
          {this.props.sitters
            .filter((s) => s.shift)
            .map((s) => {
              return (
                <SitterBreakRow
                  sitter={s}
                  key={`sitterBreak${s._id}`}
                  showSpinner={this.props.showSpinner}
                  stopSpinner={this.props.stopSpinner}
                  adjustBreak={this.props.adjustBreak}
                  setBreakInRedux={this.props.setBreakInRedux}
                />
              );
            })}
        </div>
      </div>
    );
  };

  floatingBreak = async (id, useBreak) => {
    try {
      this.props.setBreakInRedux(id, useBreak ? false : true);
      await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/staffBreak/${id}?break=${
          useBreak ? "false" : "true"
        }`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
    } catch (e) {
      console.log("bad error here sir:", e);
    }
  };

  render() {
    let style = {
      maxHeight: this.state.select ? "100%" : "0",
      transition: "1s all ease",
      padding: this.state.select ? "5px" : "0",
    };

    let inputStyle = {
      padding: "4px 7px",
      height: "28px",
      cursor: "text",
      fontSize: "12px",
      lineHeight: 1.5,
      color: "#666",
      backgroundColor: "#fff",
      backgroundImage: "none",
      border: "1px solid #d9d9d9",
      borderRadius: "4px",
    };
    return (
      <div className="scheduleCard">
        <div className="row topSection">
          <div className="col-sm-12">
            <h3>Options</h3>
          </div>
          <div className="col-sm-6">
            <div className="row">
              <div className="col-sm-4">
                <label style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Routific
                </label>
                <input
                  type="checkbox"
                  checked={!this.state.mapanything}
                  onChange={(e) =>
                    this.setState({ mapanything: !this.state.mapanything })
                  }
                />
              </div>
              <div className="col-sm-6">
                <label style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Solvice
                </label>
                <input
                  type="checkbox"
                  checked={this.state.mapanything}
                  onChange={(e) =>
                    this.setState({ mapanything: !this.state.mapanything })
                  }
                />
              </div>
            </div>
          </div>
                
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-2">
                <label>Note Time</label>
                <Tooltip
                  placement="top"
                  overlay={
                    <div>
                      Time allotted for notes. (Defaults to 5 if set to 0 or
                      empty)
                    </div>
                  }
                  mouseEnterDelay={0.5}
                  mouseLeaveDelay={0.1}
                  overlayClassName="annotation-tooltip"
                >
                  <i className="fa fa-info" />
                </Tooltip>
                <input
                  style={inputStyle}
                  type="text"
                  value={this.state.noteTime}
                  onChange={(e) =>
                    this.setState({ noteTime: Number(e.target.value) })
                  }
                />
              </div>
                  {
                    !this.state.mapanything && (

                  <div className="col-sm-2">
                    <label>Min Visits/TM</label>
                    <Tooltip
                      placement="top"
                      overlay={
                        <div>
                          Minimum visits per team member. Ignored if set to 0. If
                          there are not enough visits to go around, this parameter
                          will be ignored. If a team member has minimum visits on
                          their profile, that will take precedence.
                        </div>
                      }
                      mouseEnterDelay={0.5}
                      mouseLeaveDelay={0.1}
                      overlayClassName="annotation-tooltip"
                    >
                      <i className="fa fa-info" />
                    </Tooltip>
                    <br />
                    <input
                      style={inputStyle}
                      value={this.state.minVisits}
                      onChange={(e) => this.setState({ minVisits: e.target.value })}
                    />
                  </div>
                    )
                  }
              {
                !this.state.mapanything && (

                <div className="col-sm-2">
                  <label>Max Visits/TM</label>
                  <Tooltip
                    placement="top"
                    overlay={
                      <div>
                        Maximum visits per team member. Ignored if set to 0.
                      </div>
                    }
                    mouseEnterDelay={0.5}
                    mouseLeaveDelay={0.1}
                    overlayClassName="annotation-tooltip"
                  >
                    <i className="fa fa-info" />
                  </Tooltip>
                  <br />
                  <input
                    style={inputStyle}
                    value={this.state.maxVisits}
                    onChange={(e) => this.setState({ maxVisits: e.target.value })}
                  />
                </div>
                )
              }
             {
                !this.state.mapanything && (

              <div className="col-sm-3">
                <label>Priority Calculation</label>
                <Tooltip
                  placement="top"
                  overlay={
                    <div>
                      New is Consultations > Approved > Other, Old is
                      Consultations > Series > Earliest Scheduled
                    </div>
                  }
                  mouseEnterDelay={0.5}
                  mouseLeaveDelay={0.1}
                  overlayClassName="annotation-tooltip"
                >
                  <i className="fa fa-info" />
                </Tooltip>
                <br />
                <DropdownList
                  data={["old", "new"]}
                  onChange={(val) => this.setState({ priority_type: val })}
                  defaultValue="new"
                  containerClassName="dropdownthings"
                />
              </div>
              )
                    }
              {/*this.state.mapanything ? this.advancedOptions() : null*/}
            </div>
          </div>

          {/*this.state.mapanything ? this.showAdvancedOptions() : null*/}
        </div>

        {!this.state.mapanything ? (
          <div className="col-sm-12 topSection">
            <div className="row">
              <div className="col-sm-2">
                <label>Start Time</label>
                <Tooltip
                  placement="top"
                  overlay={
                    <div>
                      Run visits after this time. Normally you will not need to
                      adjust this, as the routes can be better calculated with a
                      more complete schedule.
                    </div>
                  }
                  mouseEnterDelay={0.5}
                  mouseLeaveDelay={0.1}
                  overlayClassName="annotation-tooltip"
                >
                  <i className="fa fa-info" />
                </Tooltip>
                <br />
                <TimePicker
                  defaultValue={moment(this.state.time).hour(9).minute(0)}
                  format={"hh:mm a"}
                  showSecond={false}
                  onChange={(e) => this.setState({ time: e.toDate() })}
                />
              </div>
              <div className="col-sm-2">
                <label>Lateness Allowed</label>
                <Tooltip
                  placement="top"
                  overlay={
                    <div>
                      Will allow a visit to be scheduled late by this many
                      minutes in order to fit it in schedule. Otherwise, it will
                      be unserved.
                    </div>
                  }
                  mouseEnterDelay={0.5}
                  mouseLeaveDelay={0.1}
                  overlayClassName="annotation-tooltip"
                >
                  <i className="fa fa-info" />
                </Tooltip>
                <br />
                <input
                  style={inputStyle}
                  value={this.state.lateness}
                  onChange={(e) => this.setState({ lateness: e.target.value })}
                />
              </div>

              <div className="col-sm-2">
                <label>Min Staff Speed</label>
                <Tooltip
                  placement="top"
                  overlay={
                    <div>
                      This will override scheduled staffs speed to a minimum
                      speed selected here
                    </div>
                  }
                  mouseEnterDelay={0.5}
                  mouseLeaveDelay={0.1}
                  overlayClassName="annotation-tooltip"
                >
                  <i className="fa fa-info" />
                </Tooltip>
                <br />
                <DropdownList
                  data={[
                    null,
                    "faster",
                    "fast",
                    "normal",
                    "slow",
                    "very slow",
                    "bike",
                  ]}
                  onChange={(val) => this.setState({ min_speed: val })}
                />
              </div>

              <div className="col-sm-2">
                <label>Traffic</label>
                <Tooltip
                  placement="top"
                  overlay={<div>Traffic speed</div>}
                  mouseEnterDelay={0.5}
                  mouseLeaveDelay={0.1}
                  overlayClassName="annotation-tooltip"
                >
                  <i className="fa fa-info" />
                </Tooltip>
                <br />
                <DropdownList
                  data={[
                    "predicted",
                    "faster",
                    "fast",
                    "normal",
                    "slow",
                    "very slow",
                  ]}
                  onChange={(val) => this.setState({ traffic: val })}
                  defaultValue="normal"
                />
              </div>

              <div className="col-sm-2">
                <label>Walker Overtime</label>
                <Tooltip
                  placement="top"
                  overlay={<div>Time allotted for notes.</div>}
                  mouseEnterDelay={0.5}
                  mouseLeaveDelay={0.1}
                  overlayClassName="annotation-tooltip"
                >
                  <i className="fa fa-info" />
                </Tooltip>
                <br />
                <input
                  type="text"
                  value={this.state.max_vehicle_overtime}
                  onChange={(e) =>
                    this.setState({
                      max_vehicle_overtime: Number(e.target.value),
                    })
                  }
                />
              </div>

              <div className="col-sm-2">
                <label>Shortest Distance</label>
                <Tooltip
                  placement="top"
                  overlay={
                    <div>
                      This will optimize for shortest distances driven. If
                      toggled on, team members will have shorter routes and
                      potentially less visits, as well as potentially more
                      unserved visits.
                    </div>
                  }
                  mouseEnterDelay={0.5}
                  mouseLeaveDelay={0.1}
                  overlayClassName="annotation-tooltip"
                >
                  <i className="fa fa-info" />
                </Tooltip>
                <br />
                <input
                  type="checkbox"
                  checked={this.state.shortest_distance}
                  onChange={() =>
                    this.setState({
                      shortest_distance: !this.state.shortest_distance,
                    })
                  }
                />
              </div>

              <div className="col-sm-2">
                <label>Minimize Walkers</label>
                <Tooltip
                  placement="top"
                  overlay={
                    <div>
                      minimize the number of vehicles you'd like to use to get
                      all the jobs done.
                    </div>
                  }
                  mouseEnterDelay={0.5}
                  mouseLeaveDelay={0.1}
                  overlayClassName="annotation-tooltip"
                >
                  <i className="fa fa-info" />
                </Tooltip>
                <br />
                <input
                  type="checkbox"
                  checked={this.state.min_vehicles}
                  onChange={() => this.toggleMinVehicles()}
                />
              </div>

              <div className="col-sm-2">
                <label>Strict Start</label>
                <Tooltip
                  placement="top"
                  overlay={
                    <div>
                      Checking this option tells routific to start visits at the
                      sitters scheduled start time if possible, this may
                      increase idle time
                    </div>
                  }
                  mouseEnterDelay={0.5}
                  mouseLeaveDelay={0.1}
                  overlayClassName="annotation-tooltip"
                >
                  <i className="fa fa-info" />
                </Tooltip>
                <br />
                <input
                  type="checkbox"
                  checked={this.state.strict_start}
                  onChange={() => this.toggleStrictStart()}
                />
              </div>

              <div className="col-sm-2">
                <label>Use Zones</label>
                <Tooltip
                  placement="top"
                  overlay={
                    <div>
                      Use team member zones to restrict the locations that they
                      can service.
                    </div>
                  }
                  mouseEnterDelay={0.5}
                  mouseLeaveDelay={0.1}
                  overlayClassName="annotation-tooltip"
                >
                  <i className="fa fa-info" />
                </Tooltip>
                <br />
                <input
                  type="checkbox"
                  checked={this.state.zones}
                  onChange={() => this.setState({ zones: !this.state.zones })}
                />
              </div>

              <div className="col-sm-8">
                <label>Balance</label>
                <Tooltip
                  placement="top"
                  overlay={
                    <div>
                      This will attempt to balance driving shifts for team
                      members in additon to minimum visits/vehicle. Should only
                      be used in conjunction with minimum visits per vehicle.
                    </div>
                  }
                  mouseEnterDelay={0.5}
                  mouseLeaveDelay={0.1}
                  overlayClassName="annotation-tooltip"
                >
                  <i className="fa fa-info" />
                </Tooltip>
                <br />
                <input
                  type="checkbox"
                  checked={this.state.balance}
                  onChange={() =>
                    this.setState({ balance: !this.state.balance })
                  }
                />
              </div>
            </div>
          </div>
        ) : null}

        <div className="row topSection">
          <div className="col-sm-12 runButtons">
            <button
              onClick={() => this.lockFirstVisits()}
              className="btn btn-primary lockBtn"
            >
              Lock First Visits
            </button>
            {this.state.select ? (
              <button
                onClick={() => this.runSchedule()}
                className="btn btn-danger"
              >
                Run
              </button>
            ) : (
              <button onClick={() => this.runAll()} className="btn btn-danger">
                Run All
              </button>
            )}
            {this.state.select ? (
              <button
                onClick={() => this.toggleSelect()}
                className="btn btn-primary"
              >
                Select All Visits
              </button>
            ) : (
              <button
                onClick={() => this.toggleSelect()}
                className="btn btn-primary"
              >
                Select Visits To Run
              </button>
            )}
          </div>
          {this.state.select ? (
            <div className="col-sm-3">
              <label>Sort TMs by Zone</label>
              <Tooltip
                placement="top"
                overlay={
                  <div>Show only sitters assigned to relevant zones.</div>
                }
                mouseEnterDelay={0.5}
                mouseLeaveDelay={0.1}
                overlayClassName="annotation-tooltip"
              >
                <i className="fa fa-info" />
              </Tooltip>
              <br />
              <input
                type="checkbox"
                checked={this.state.useZones}
                onChange={() => this.toggleUseZones()}
              />
            </div>
          ) : null}
        </div>

        <div className="row bottomSection" style={style}>
          <div className="col-sm-7">
            <h3>Pending Visits</h3>
            {this.renderPending()}
            <h3>Approved & Unassigned</h3>
            {this.renderApproved()}
          </div>
          <div className="col-sm-5">
            <h3>Team Members</h3>

            {this.state.useZones
              ? this.props.formConstants.zones.map((z) => {
                  return (
                    <div className="selectZone">
                      <ZoneBadge zone={z} size="tiny" />
                      <input
                        type="checkbox"
                        checked={this.state.selectedZones.includes(z.id)}
                        onChange={() => this.toggleZone(z)}
                      />
                    </div>
                  );
                })
              : null}
            <div className="teamMemberSettings">
              <label>Show Only Scheduled Team Members</label>
              <input
                type="checkbox"
                checked={this.state.showOnlyWorkingSitters}
                onChange={() => this.toggleWorkingSitters()}
              />
              <label>Select All</label>
              <input
                type="checkbox"
                checked={this.state.selectAll}
                onChange={() => this.selectAllTeamMembers()}
              />
            </div>
            {this.renderTeam()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sitters: state.admin.staff.staffList,
  market: state.admin.general.location,
});

const mapDispatchToProps = (dispatch) => ({
  setBreakInRedux: (id, useBreak) =>
    dispatch({ type: "SET_BREAK", payload: { id, useBreak } }),
  adjustBreak: (id, ourBreak) =>
    dispatch({ type: "SET_BREAK_DETAILS", payload: { id, ourBreak } }),
  addStaff: (staff) => dispatch({ type: "ADD_GHOST_STAFF", payload: staff }),
  removeStaff: (staff) => dispatch({ type: "REMOVE_GHOST", payload: staff }),
  showMessage: (msg) => dispatch({ type: "ADD_MESSAGE", payload: msg }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" }),
  showSpinner: () => dispatch({ type: "FETCH_REQUEST" }),
  stopSpinner: () => dispatch({ type: "FETCH_SUCCESS" }),
});

export default connect(mapStateToProps, mapDispatchToProps)(RouteModal);

// export default RouteModal;
