import React, { Component } from "react";

class Grooming extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div>Grooming</div>;
  }
}

export default Grooming;
