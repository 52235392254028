import React, { Component } from "react";
import { connect } from "react-redux";
import { StaffDataRow, DefaultModal } from "../../../components";
import { loadStaffData } from "../../../actions/admin/staff.actions";
import queryString from "query-string";
import axios from "../../../services/network.js";

import { pushModal, popModal } from "../../../actions/general.actions";
import moment from "moment";

class StaffTable extends Component {
  state = {
    staff: [],
    job_id: ""
  };

  componentWillReceiveProps = props => {
    if (props.market_id !== this.props.market_id) {
      this.getData();
    }
    this.setState({ staff: [] });
  };

  getData = () => {
    this.setState({ staff: [] }, async () => {
      const {
        showSpinner,
        stopSpinner,
        showMessage,
        removeMessage
      } = this.props;
      showSpinner();
      try {
        const { startDate, endDate, market_id } = this.props;
        const query = {
          startDate: startDate,
          endDate: endDate,
          market_id: market_id
        };
        const c = axios.get(
          `${
            process.env.REACT_APP_API_URI
          }/admin/market/${market_id}/clients?${queryString.stringify(query)}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            }
          }
        );
        const s = axios.get(
          `${
            process.env.REACT_APP_API_URI
          }/admin/market/${market_id}/staff?${queryString.stringify(query)}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            }
          }
        );
        const a = this.props.getAppointments(startDate, endDate, market_id);

        let [clients, staff, appointments] = await Promise.all([c, s, a]);

        const ids = appointments.map(a => a._id);
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URI}/admin/appointments/getPayments`,
          { ids: ids },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            }
          }
        );

        appointments = await this.formatAppointments(
          appointments,
          data.walk_credits,
          data.line_items,
          clients.data
        );
        staff = await this.formatStaff(staff, appointments, clients);

        this.setState({ staff: staff });
      } catch (e) {
        showMessage({
          message: e.message,
          messageType: "error"
        });
        setTimeout(removeMessage, 3000);
        console.log("BIG ERROR", e);
      }
      stopSpinner();
    });
  };

  formatStaff = (staff, appointments, clients) => {
    return new Promise((res, rej) => {
      const cool_staff = [];
      for (let s of staff.data) {
        let appts = appointments.filter(a => {
          return (
            a.sitter && a.sitter._id == s._id && a.appointment_status.id !== 7
          );
        });
        s.appointments = appts && appts.length ? appts : null;
        s.contact.full_name = `${s.first_name} ${s.last_name}`;
        cool_staff.push(s);
      }
      cool_staff.sort((a, b) => {
        if (!a || !a.last_name) return 1;
        if (!b || !b.last_name) return -1;
        if (a.last_name.toLowerCase() < b.last_name.toLowerCase()) return -1;
        if (a.last_name.toLowerCase() > b.last_name.toLowerCase()) return 1;
      });
      res(cool_staff);
    });
  };

  formatAppointments = (appointments, walkCredits, lineItems, clients) => {
    return new Promise((res, rej) => {
      const cool_appts = appointments.map(a => {
        let client = clients.find(c => c._id == a.client_id);
        a.client = client ? client : null;
        let credit = walkCredits.find(c => c.visit_used_on == a._id);
        let item = lineItems.find(i => i.appointment_id == a._id);

        if (credit) {
          a.credit = credit;
          a.price = credit.price;
        }
        if (item) {
          a.item = item;
          if (!a.price) a.price = item.amount;
        }
        if (!a.price && !credit && !item) {
          a.price = 0;
        }
        a.price = a.price || 0;
        return a;
      });
      res(cool_appts);
    });
  };

  showExportModal = () => {
    const { modalActions } = this.props;
    const modal = (
      <DefaultModal
        title="Please Read"
        onDismiss={modalActions.pop}
        options={
          <div>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={modalActions.pop}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-slack"
              onClick={this.exportMileage}
            >
              <i className="fa fa-slack"></i>
              Export
            </button>
          </div>
        }
      >
        <div className="add-export-modal">
          <div className="row">
            <div className="col-md-12">
              <p>
                By clicking submit, the export will generate. Please do not
                submit if you have done so in the past few minutes. The export
                takes a few minutes to generate and will be sent to #operations
                when it is complete. Sitters from all markets will be exported
                between the dates
                {`${moment()
                  .subtract(1, "week")
                  .startOf("week")
                  .add(1, "day")
                  .format("MM/DD/YYYY")}-${moment()
                  .subtract(1, "week")
                  .endOf("week")
                  .add(1, "day")
                  .format("MM/DD/YYYY")}`}
                .
              </p>
            </div>
          </div>
        </div>
      </DefaultModal>
    );
    modalActions.push(modal);
  };

  exportMileage = async () => {
    const {
      showSpinner,
      stopSpinner,
      showMessage,
      removeMessage,
      modalActions
    } = this.props;
    try {
      const { data } = await axios.get(
        `${
          process.env.REACT_APP_API_URI
        }/admin/exportMileage?${queryString.stringify({
          id: this.state.job_id
        })}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );
      showMessage({ messageType: "success", message: data.message });
    } catch (e) {
      showMessage({ messageType: "error", message: e.response.data.message });
    }
    setTimeout(removeMessage, 3000);
    modalActions.pop();
  };

  render() {
    const staffList = [...this.state.staff];

    return (
      <div className="card p-t-none">
        <div className="row">
          <div className="col-lg-2">
            <button
              className="btn btn-primary"
              onClick={this.getData}
              disabled={this.props.disabled}
            >
              Get Metrics
            </button>
            <button
              className="btn btn-slack normal-size-btn"
              onClick={this.showExportModal}
            >
              <i className="fa fa-slack"></i>
              Export
            </button>
          </div>
        </div>
        <div>
          <div className="user-list-container">
            <div className="user-list-item user-list-header">
              <div className="user-list-item-content title col-lg-3 col-md-3 col-sm-3 col-xs-6">
                Name
              </div>
              <div className="user-list-item-content title col-lg-3 col-md-3 col-sm-3 col-xs-6">
                Visits Completed
              </div>
              <div className="user-list-item-content title col-lg-3 col-md-3 col-sm-3 col-xs-6">
                Revenue Generated
              </div>
              <div className="user-list-item-content title col-lg-3 col-md-3 col-sm-3 hidden-xs">
                Mileage
              </div>
            </div>
            {staffList && staffList.length
              ? staffList
                  .filter(s => s && s.contact && s.contact.full_name)
                  .sort((a, b) => {
                    if (a.contact.last_name < b.contact.last_name) return -1;
                    if (a.contact.last_name > b.contact.last_name) return 1;
                    return 0;
                  })
                  .map((staff, index) => (
                    <StaffDataRow
                      staff={staff}
                      key={staff._id}
                      startDate={this.props.startDate}
                      endDate={this.props.endDate}
                      disabled={this.props.disabled}
                    />
                  ))
              : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showMessage: message => dispatch({ type: "ADD_MESSAGE", payload: message }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" }),
  showSpinner: () => dispatch({ type: "FETCH_REQUEST" }),
  stopSpinner: () => dispatch({ type: "FETCH_SUCCESS" }),
  modalActions: {
    push: component => dispatch(pushModal(component)),
    pop: () => dispatch(popModal())
  }
});

export default connect(
  null,
  mapDispatchToProps
)(StaffTable);
