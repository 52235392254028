import React, { Component } from "react";
import { CSSTransitionGroup } from 'react-transition-group'

export default class FlashMessage extends Component {

  render() {
    const { messages } = this.props;
    return (
      <div className={`text-center message-container`}>
        <CSSTransitionGroup
          transitionName="message"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}
        >
          {messages.map((msg, index) => (
            <div
              className={`${msg.messageType}-message`}
              key={`${msg.messageContext}-${msg.timestamp}`}
            >
              <p>{msg.message}</p>
            </div>
          ))}
        </CSSTransitionGroup>
      </div>
    );
  }
}
