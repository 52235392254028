import React, { Component } from "react";
import "rc-time-picker/assets/index.css";
import moment from "moment-timezone";
import axios from "../../services/network.js";

class BreakRow extends Component {
  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.breakStart &&
        nextProps.breakStart !== this.state.breakStart) ||
      (nextProps.breakEnd && nextProps.breakEnd !== this.state.breakEnd)
    ) {
      console.log("receiving next props...", nextProps);
      this.setState({
        breakStart: nextProps.breakStart,
        breakEnd: nextProps.breakEnd,
        tempBreakStart: nextProps.breakStart,
        tempBreakEnd: nextProps.breakEnd
      });
    }
  }

  state = {
    breakStart: "02:00 pm",
    breakEnd: "02:20 pm",
    tempBreakStart: "02:00 pm",
    tempBreakEnd: "02:20 pm"
  };

  updateBreakStart = val => {
    if (val.includes("P")) val = val.replace("P", "p");
    if (val.includes("M")) val = val.replace("M", "m");
    this.setState({ tempBreakStart: val, dirty: true });
  };

  updateBreakEnd = val => {
    if (val.includes("P")) val = val.replace("P", "p");
    if (val.includes("M")) val = val.replace("M", "m");
    this.setState({ tempBreakEnd: val, dirty: true });
  };

  cancel = () => {
    this.setState({
      tempBreakStart: this.state.breakStart,
      tempBreakEnd: this.state.breakEnd,
      editing: false
    });
  };

  save = async () => {
    try {
      this.props.showSpinner();
      let formats = ["hh:mm a", "h:mm a", "hh:mma", "h:mma"];
      if (
        moment(this.state.tempBreakStart, formats, true).isValid() &&
        moment(this.state.tempBreakEnd, "hh:mm a", true).isValid()
      ) {
        let ourBreak = {
          _id: this.props.breakId,
          start_time: moment(this.state.tempBreakStart, formats).format(
            "hh:mm a"
          ),
          end_time: moment(this.state.tempBreakEnd, formats).format("hh:mm a")
        };
        let { data } = await axios.post(
          `${process.env.REACT_APP_API_URI}/admin/updateStaffBreak`,
          { break: ourBreak },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            }
          }
        );
        this.props.updateBreak(this.props.sitter, data);
        this.setState({
          // breakStart: data.start_time,
          // breakEnd: data.end_time,
          // tempBreakStart: data.start_time,
          // tempBreakEnd: data.end_time,
          editing: false
        });
      }
      this.props.stopSpinner();
    } catch (e) {
      this.props.stopSpinner();
      console.log(e);
      alert("Error saving breaks");
    }
  };

  delete = async () => {
    let ourBreak = { _id: this.props.breakId };
    let { data } = await axios.post(
      `${process.env.REACT_APP_API_URI}/admin/removeStaffBreak`,
      { break: ourBreak },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      }
    );
    this.props.removeBreak(this.props.sitter);
  };

  getDiffFromStart = (breakStart, day) => {
    let formattedDay = moment(day).format('YYYY-MM-DD');

    let hour = moment(breakStart, "hh:mm a").hour();
    let minute = moment(breakStart, "hh:mm a").minute();

    return moment(formattedDay).hour(hour).minute(minute).diff(
      moment(day)
        .hour(9)
        .minute(0),
      "minutes"
    );
  }

  render() {
    let { breakStart, breakEnd, tempBreakEnd, tempBreakStart } = this.state;
    let { day } = this.props;
    let breakLength = moment(breakStart, "hh:mm a").diff(
      moment(breakEnd, "hh:mm a"),
      "minutes"
    );

    let diffFromStart = this.getDiffFromStart(breakStart, day);

    let windowStyle = {
      height: `${Math.abs(breakLength * 1.5)}px`,
      position: "absolute",
      top: `${Math.abs(diffFromStart * 1.5)}px`,
      width: "550px",
      left: 0,
      overflow: "visible"
    };

    if (!this.props.stacked)
      windowStyle = {
        height: `${Math.abs(breakLength * 1.5)}px`,
        width: "550px",
        overflow: "visible",
        margin: "10px"
      };

    if (this.state.editing)
      return (
        <div style={windowStyle} className="breakWindow">
          <div className="row mapBreakRow">
            <div className="col-sm-3">
              <input
                type="text"
                value={this.state.tempBreakStart}
                onChange={e => this.updateBreakStart(e.target.value)}
              />
            </div>
            <div className="col-sm-3">
              <input
                type="text"
                value={this.state.tempBreakEnd}
                onChange={e => this.updateBreakEnd(e.target.value)}
              />
            </div>
            <div className="col-sm-6">
              <button
                className="btn btn-primary cancelBreakBtn"
                onClick={this.save}
              >
                Save
              </button>
              <button
                onClick={this.cancel}
                className="btn btn-primary cancelBreakBtn cancelll"
              >
                Cancel
              </button>
              <button
                className="btn btn-danger cancelBreakBtn"
                onClick={this.delete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      );

    return (
      <div style={windowStyle} className="breakWindow">
        <div className="row mapBreakRow">
          Break: {breakStart} - {breakEnd}{" "}
          <button
            onClick={() => this.setState({ editing: true })}
            className="btn btn-primary editBreakBtn"
          >
            Edit
          </button>
        </div>
      </div>
    );
  }
}

export default BreakRow;
