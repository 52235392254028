import * as ActionTypes from "../../actions";

const initialState = {
  clientList: null,
  client: null,
  pet: null // TODO: If unused, remove.
};

function clients(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ActionTypes.CREATE_CLIENT_SUCCESS:
      return state;

    case ActionTypes.GET_ALL_CLIENTS_SUCCESS:
      return { ...state, clientList: payload.clients };

    case ActionTypes.GET_CLIENT_SUCCESS:
      return { ...state, client: action.payload.client };

    case ActionTypes.GET_PET_SUCCESS:
      return { ...state, pet: action.payload.pet };

    case ActionTypes.ADD_PET_TO_CLIENT_SUCCESS:
      return state;
    case "RESET_USER":
      return { ...state, client: null };
    case "PLAYCARE_PETS":
      return {
        ...state,
        client: {
          ...state.client,
          pets: state.client.pets.filter(
            p => p.playcare_approved && p.animal_type === "Dog"
          )
        }
      };
    case "RESET_PETS":
      return {
        ...state,
        client: {
          ...state.client,
          pets: state.client.all_pets
        }
      };
    default:
      return state;
  }
}

export default clients;
