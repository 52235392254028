export const locations = [
  {
    id: 1,
    name: "Charlotte",
    center: { lat: 35.22, lng: -80.84 }
  },
  {
    id: 2,
    name: "Austin",
    center: { lat: 30.2672, lng: -97.7431 }
  }
];

export const entryTimes = [
  {
    name: "Short (1-2 min)",
    value: 2
  },
  {
    name: "Average (3-4 min)",
    value: 4
  },
  {
    name: "Long (6-7 min)",
    value: 7
  },
  {
    name: "Really Long (9 + min)",
    value: 10
  }
];

export const dogBreeds = [
  "Affenpinscher",
  "Afghan Hound",
  "Airedale Terrier",
  "Akita",
  "Alaskan Malamute",
  "American English Coonhound",
  "American Eskimo Dog",
  "American Foxhound",
  "American Hairless Terrier",
  "American Staffordshire Terrier",
  "American Water Spaniel",
  "Anatolian Shepherd Dog",
  "Australian Cattle Dog",
  "Australian Shepherd",
  "Australian Terrier",
  "Azawakh",
  "Basenji",
  "Basset Hound",
  "Beagle",
  "Bearded Collie",
  "Beauceron",
  "Bedlington Terrier",
  "Belgian Laekenois",
  "Belgian Malinois",
  "Belgian Sheepdog",
  "Belgian Tervuren",
  "Bergamasco",
  "Berger Picard",
  "Bernese Mountain Dog",
  "Bichon Frise",
  "Black and Tan Coonhound",
  "Black Russian Terrier",
  "Bloodhound",
  "Bluetick Coonhound",
  "Boerboel",
  "Border Collie",
  "Border Terrier",
  "Borzoi",
  "Boston Terrier",
  "Bouvier des Flandres",
  "Boxer",
  "Boykin Spaniel",
  "Briard",
  "Brittany",
  "Brussels Griffon",
  "Bull Terrier",
  "Bulldog",
  "Bullmastiff",
  "Cairn Terrier",
  "Canaan Dog",
  "Cane Corso",
  "Catahoula Leopard Dog",
  "Cavalier King Charles Spaniel",
  "Cesky Terrier",
  "Chesapeake Bay Retriever",
  "Chihuahua",
  "Chinese Crested",
  "Chinese Shar-Pei",
  "Chinook",
  "Chow Chow",
  "Cirneco Dell’Etna",
  "Clumber Spaniel",
  "Cocker Spaniel",
  "Collie",
  "Corgi",
  "Coton De Tulear",
  "Curly-Coated Retriever",
  "Dachshund",
  "Dalmatian",
  "Dandie Dinmont Terrier",
  "Doberman Pinscher",
  "Dogo Argentino",
  "Dogue de Bordeaux",
  "English Cocker Spaniel",
  "English Foxhound",
  "English Setter",
  "English Springer Spaniel",
  "English Toy Spaniel",
  "Entlebucher Moutain Dog",
  "Field Spaniel",
  "Finish Spitz",
  "Finnish Lapphund",
  "Flat-Coated Retriever",
  "French Bulldog",
  "German Pinscher",
  "German Shepherd",
  "German Shorthaired Pointer",
  "German Wirehaired Pointer",
  "Giant Schnauzer",
  "Glen of Imaal Terrier",
  "Golden Retriever",
  "Goldendoodle",
  "Gordon Setter",
  "Grand Basset Griffon Vendeen",
  "Great Dane",
  "Great Pyrenees",
  "Greater Swiss Mountain Dog",
  "Greyhound",
  "Harrier",
  "Havanese",
  "Ibizan Hound",
  "Icelandic Sheepdog",
  "Irish Red and White Setter",
  "Irish Setter",
  "Irish Terrier",
  "Irish Water Spaniel",
  "Irish Wolfhound",
  "Italian Greyhound",
  "Jack Russell Terrier",
  "Japanese Chin",
  "Keeshond",
  "Kerry Blue Terrier",
  "Komondor",
  "Kuvasz",
  "Lab Mix",
  "Labradoodle",
  "Labrador Retriever",
  "Lagotto Romagnolo",
  "Lakeland Terrier",
  "Leonberger",
  "Lhasa Apso",
  "Lowchen",
  "Maltese",
  "Manchester Terrier",
  "Mastiff",
  "Miniature American Shepherd",
  "Miniature Bull Terrier",
  "Miniature Pinscher",
  "Miniature Poodle",
  "Miniature Schnauzer",
  "Mixed",
  "Munsterlander",
  "Neapolitan Mastiff",
  "Nederlandse Kooikerhondje",
  "Newfoundland",
  "Norfolk Terrier",
  "Norrbottenspets",
  "Norwegian Buhund",
  "Norwegian Elkhound",
  "Norwegian Lundhund",
  "Norwich Terrier",
  "Nova Scotia Duck Tolling Retriever",
  "Old English Sheepdog",
  "Other",
  "Otterhound",
  "Papillon",
  "Pekingese",
  "Peruvian Inca Orchid",
  "Petit Basset Griffon Vendeen",
  "Pharaoh Hound",
  "Pitbull Mix",
  "Pitbull Terrier",
  "Plott",
  "Pointer",
  "Polish Lowland Sheepdog",
  "Pomeranian",
  "Poodle Mix",
  "Portuguese Podengo",
  "Portuguese Podengo Pequeno",
  "Portuguese Water Dog",
  "Pug",
  "Puli",
  "Pumi",
  "Pyrenean Sheepdog",
  "Rat Terrier",
  "Redbone Coonhound",
  "Rhodesian Ridgeback",
  "Rottweiler",
  "Saluki",
  "Samoyed",
  "Schipperke",
  "Scottish Deerhound",
  "Scottish Terrier",
  "Sealyham Terrier",
  "Shetland Sheepdog",
  "Shiba Inu",
  "Shih Tzu",
  "Siberian Husky",
  "Silky Terrier",
  "Skye Terrier",
  "Sloughi",
  "Smooth Fox Terrier",
  "Spanish Water Dog",
  "Spinone Italiano",
  "St. Bernard",
  "Staffordshire Bull Terrier",
  "Standard Poodle",
  "Standard Schnauzer",
  "Sussex Spaniel",
  "Swedish Vallhund",
  "Tibetan Mastiff",
  "Tibetan Spaniel",
  "Tibetan Terrier",
  "Toy Fox Terrier",
  "Toy Poodle",
  "Treeing Walker Coonhound",
  "Vizsla",
  "Weimaraner",
  "Welsh Springer Spaniel",
  "Welsh Terrier",
  "West Highland White Terrier",
  "Wheaten Terrier",
  "Whippet",
  "Wire Fox Terrier",
  "Wirehaired Pointing Griffon",
  "Wirehaired Vizsla",
  "Xoloitzcuintli",
  "Yorkshire Terrier"
];

export const catBreeds = [
  "American Bobtail",
  "American Curl",
  "American Shorthair",
  "American Wirehair",
  "Balinese",
  "Bengal Cats",
  "Birman",
  "Bombay",
  "British Shorthair",
  "Burmese",
  "Burmilla",
  "Chartreux",
  "Chinese Li Hua",
  "Colorpoint Shorthair",
  "Cornish Rex",
  "Cymric",
  "Devon Rex",
  "Domestic Longhair",
  "Domestic Mediumhair",
  "Domestic Shorthair",
  "Egyptian Mau",
  "European Burmese",
  "Exotic",
  "Havana Brown",
  "Himalayan",
  "Japanese Bobtail",
  "Javanese",
  "Korat",
  "LaPerm",
  "Maine Coon",
  "Manx",
  "Mix",
  "Nebelung",
  "Norwegian Forest",
  "Ocicat",
  "Oriental",
  "Other",
  "Persian",
  "Pixie-Bob",
  "Ragamuffin",
  "Ragdoll Cats",
  "Russian Blue",
  "Savannah",
  "Scottish Fold",
  "Selkirk Rex",
  "Siamese Cat",
  "Siberian",
  "Singapura",
  "Snowshoe",
  "Somali",
  "Sphynx",
  "Tonkinese",
  "Turkish Angora",
  "Turkish Van"
];

export const otherBreeds = [
  "Amphibian",
  "Bird",
  "Chicken",
  "Ferret",
  "Fish",
  "Gerbil",
  "Guinea Pig",
  "Hamster",
  "Horse",
  "Other",
  "Rabbit",
  "Rat",
  "Reptile"
];

export const dogColors = [
  "Apricot",
  "Black",
  "Blue",
  "Brindle",
  "Brown",
  "Chestnut",
  "Chocolate",
  "Cream",
  "Dark Brown",
  "Fawn",
  "Golden",
  "Gray",
  "Light Brown",
  "Liver",
  "Merle",
  "Orange",
  "Parti-Color",
  "Phantom",
  "Red",
  "Rust",
  "Sable",
  "Silver",
  "Spotted",
  "Tan",
  "Tri-Colored",
  "Wheaten",
  "White",
  "Yellow"
];

export const catColors = [
  "Calico",
  "Grey Tabby",
  "Orange Tabby",
  "Tortoise",
  "Tuxedo"
];

export const apartments = [
  "None",
  "Other",
  "1100 South",
  "1225 South Church",
  "1420 Magnolia",
  "3400 Selwyn Apartments",
  "5115 Park Place",
  "642 Raphael Place",
  "8812 Park Rd Unit D",
  "Alexander Place",
  "Allure",
  "Alpha Mill",
  "Andover Woods Apartments",
  "Anson at the Lakes",
  "Ascent Pineville",
  "Ascent Uptown",
  "Ashley Square at SouthPark",
  "Ashton South End",
  "Atkins Circle",
  "Aurea Station",
  "Aurora Apartment Homes",
  "Aztec Apartments",
  "Beacon Hill",
  "Bell Ballantyne",
  "Berkshire Dilworth",
  "Berkshire Place Apartments",
  "Bexley at Matthews",
  "Bexley at Springs Farm",
  "Bexley Crossing at Providence",
  "Bridges at Quail Hollow",
  "Brighton Place Apartments",
  "Camden Ballantyne",
  "Camden Dilworth",
  "Camden Fairview",
  "Camden Foxcroft",
  "Camden Gallery",
  "Camden South End",
  "Camden Southline",
  "Camden Stonecrest",
  "Camden Touchstone",
  "Carmel on Providence",
  "Catalyst",
  "Catherine 36",
  "Cedar Flats",
  "Central Pointe Apartment Homes",
  "Charlotte Woods",
  "Cheswyck at Ballantyne",
  "Cielo Apartments",
  "Circa Uptown",
  "Colonial Grand at Beverly Crest",
  "Colonial Grand at Matthews Commons",
  "Colonial Reserve at South End",
  "Courtney Ridge",
  "Courtyard Townhomes",
  "Crescent Southpark",
  "Crest Gateway",
  "Crest on Providence",
  "DEAL STAGE",
  "District Flats Summit and Church",
  "Eastover Ridge Apartments",
  "Element South",
  "Elements on Park",
  "Elizabeth Court Condos",
  "Elizabeth Square",
  "Elmhurst Apartment Homes",
  "Enclave",
  "Fountains Matthews",
  "Fountains Southend",
  "Galleria Village",
  "Gateway West Uptown Flats",
  "Glen Cove",
  "Grand Oaks Apartment Homes",
  "Hawthorne at Commonweath",
  "Johnston Creek Crossing",
  "Junction 1504",
  "Legacy 521",
  "Legacy Arboretum",
  "Legacy Ballantyne",
  "Loft 135",
  "Madison Southpark",
  "Maple Way",
  "Marq Midtown 205",
  "Martha Washington Apartment",
  "Matthews Crossing",
  "Matthews Pointe",
  "McMullen Wood",
  "Mercury Noda",
  "Metro 808",
  "MEZZO1",
  "Mission Matthews Place",
  "Misty Woods Apartments",
  "Mosaic South End",
  "Museum Tower",
  "Old Towne - Demolished",
  "One305 Central",
  "Paces Pointe",
  "Park and Kingston",
  "Park Lane Apartments",
  "Park Place Apartments",
  "Parkhill Condominiums",
  "Peppertree",
  "Pinehurts on Providence",
  "Pinetree Apartments",
  "Plantation Park",
  "Post Ballantyne",
  "Post Gateway Place",
  "Post Park at Phillips Place",
  "Post South End",
  "Post Uptown Place",
  "Presley Uptown",
  "Promenade Park",
  "Providence Court",
  "Providence Park Apartments",
  "Quail Valley on Carmel",
  "Quarterside",
  "Randolph Park",
  "Reafield Village Apartments",
  "Reserve at Providence I",
  "Retreat at McAlpine",
  "Ridgeway 32",
  "River Bich Apartment Homes",
  "Rock Creek at Ballantyne Commons",
  "Salem Village Apartments",
  "Sardis Place at Matthews",
  "Sharon Crossing",
  "Sharon West Apartments",
  "Sharonridge Apartments",
  "Silos South End",
  "Skyhouse Uptown",
  "Soho on Central",
  "Solis Ballantyne",
  "Solis Dilworth",
  "Solis Sharon Square",
  "Solis Waverly",
  "Southgate Apartments",
  "Southpark Commons",
  "SouthPark Morrison",
  "Spectrum South End",
  "SpringCroft at Ashley Park",
  "Sterling Magnolia Apartments",
  "Stonehaven East",
  "Strawberry Hill",
  "Swan Run Apartments",
  "Teal Point Apartments",
  "The Abbey",
  "The Apartments at Blakeney",
  "The Brook",
  "The Crest at Galleria",
  "The Crossing at Quail Hollow",
  "The Encore Southpark",
  "The Fairways at Piper Glen Apartments",
  "The Gibson",
  "The Julien",
  "The Lexington Dilworth",
  "The Lofts at Noda Mills",
  "The Lowrie",
  "The Mint",
  "The Pines on Wendover",
  "The Regency",
  "The Reserve Apartments",
  "The Residence at SouthPark",
  "The Residences at West Mint",
  "The Timbers",
  "The Village at Commonwealth",
  "The Villages",
  "The Vue Charlotte on 5th",
  "The Vyne on Central",
  "Timber Creek",
  "Uptown Court",
  "Uptown Gardens",
  "Venue",
  "Vista Commons",
  "Waterford Square Apartments",
  "Willow Ridge Apartments",
  "Windsor Landing Apartments",
  "Woodland Park Apartments",
  "Yards at Noda"
];
