import React, { Component } from "react";

class Calendar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div>Calendar</div>;
  }
}

export default Calendar;
