import React from "react";
import { DefaultModal, ZoneSettingsRow } from "../";
import { catchFormServerErrors } from "../../helpers";

class ZoneSettings extends React.Component {

  static defaultProps = {
    zones: []
  };

  state = {
    sortDir: "ASC", // or DESC
    sortKey: "id", // or zone_name
    addZone: false,
    openZoneId: null // null or zoneId int; 0 for new zone
  };

  toggleAddZone = () => {
    const { addZone } = this.state;
    this.setState({ addZone: !addZone, openZoneId: 0 });
  };

  toggleEditZoneDrawer = zoneId => {
    const { openZoneId } = this.state;
    if (openZoneId === zoneId || !zoneId) {
      this.setState({ openZoneId: null });
    } else {
      this.setState({ openZoneId: zoneId });
    }
  };

  handleAddZone = values => {
    const { addZone } = this.props;
    this.setState({ addZone: false });
    return addZone(values).then(res => catchFormServerErrors(res));
  };

  handleUpdateZone = values => {
    const { updateZone } = this.props;
    const { id, zone_name, background_color, text_color } = values;
    const model = { zone_name, background_color, text_color };
    return updateZone(id, model).then(res => catchFormServerErrors(res));
  };

  handleDeleteZone = (e, zoneId) => {
    e.preventDefault();
    const { deleteZone, modalActions } = this.props;

    const confirmDelete = () => {
      deleteZone(zoneId); // Success message gets triggered in action
      modalActions.pop();
    };

    const deleteZoneModal = (
      <DefaultModal
        title="Zone Deletion"
        onDismiss={modalActions.pop}
        options={
          <div>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={modalActions.pop}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={confirmDelete}
            >
              I Confirm
            </button>
          </div>
        }
      >
        <p>
          Are you sure you want to delete this zone? This zone will be removed
          from any clients and team members, and this action cannot be undone.
        </p>
      </DefaultModal>
    );

    modalActions.push(deleteZoneModal);
  };

  // TODO: Add sorting back in
  setSort = sortKey => {
    const { sortDir } = this.state;
    this.setState({ sortKey, sortDir: sortDir === "ASC" ? "DESC" : "ASC" });
  };

  sortZones = (a, b) => {
    const { sortDir, sortKey } = this.state;
    const nameA = a.zone_name.toLowerCase();
    const nameB = b.zone_name.toLowerCase();

    let directionFactor = 1;
    if (sortDir === "ASC") {
      directionFactor = -1;
    }

    if (sortKey === "id") {
      return a.id + b.id * directionFactor;
    } else if (sortKey === "zone_name") {
      if (nameA < nameB) {
        return 1 * directionFactor;
      } else if (nameA > nameB) {
        return -1 * directionFactor;
      }
      return 0;
    }
  };

  render() {
    const { addZone, openZoneId } = this.state;
    const { displayFormError, displayFormSuccess, zones } = this.props;
    return (
      <div className="row">
        {zones.sort(this.sortZones).map(zone => (
          <ZoneSettingsRow
            key={`zone-${zone.id}`}
            displayFormError={displayFormError}
            displayFormSuccess={displayFormSuccess}
            handleDeleteZone={this.handleDeleteZone}
            handleUpdateZone={this.handleUpdateZone}
            openZoneId={openZoneId}
            toggleEditZoneDrawer={this.toggleEditZoneDrawer}
            zone={zone}
          />
        ))}
        {addZone ? (
          <ZoneSettingsRow
            key="add-new-zone-row"
            displayFormError={displayFormError}
            displayFormSuccess={displayFormSuccess}
            handleAddZone={this.handleAddZone}
            handleCancel={this.toggleAddZone}
          />
        ) : null}
        <div className="col-sm-12">
          <span className="inline-link">
            {addZone ? null : (
              <a onClick={this.toggleAddZone}>
                {" "}
                <span className="fa fa-plus" />
                Add Zone
              </a>
            )}
          </span>
        </div>
      </div>
    );
  }
}

export default ZoneSettings;
