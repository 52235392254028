import React, { Component } from "react";
import moment from "moment-timezone";

class SitterAppointmentListItem extends Component {
  state = {
    open: false
  };

  highlight = (e, _id) => {
    e.stopPropagation();
    this.props.highlightAppointmentOnMap(_id);
  };

  getTrain = () => {
    if (!this.props.sitter || !this.props.sitter.trainings_completed)
      return null;
    if (this.props.sitter.trainings_completed.includes("Consultation Training"))
      return "CT";
    return null;
  };

  getBreak = () => {
    let { sitter } = this.props;
    if (sitter.break_start) {
      return " Break";
    }
    return null;
  };

  isLate = () => {
    let { sitter, appointments } = this.props;
    if (!appointments || !appointments.length) return 0;
    let isToday = moment(this.props.selectedDate).isSame(moment(), "day");
    if (!isToday) return 0;
    if (sitter.lateness) return sitter.lateness;
    if (!sitter.shift) return 0;
    let start = moment(appointments[0].scheduled_start_time);
    let startedFirstVisit = [4, 5].includes(
      appointments[0].appointment_status.id
    );
    if (
      moment().isAfter(start) &&
      !startedFirstVisit &&
      moment().isAfter(moment(appointments[0].scheduled_start_time))
    ) {
      return moment().diff(start, "minutes");
    }
    return 0;
  };

  render() {
    const s = this.props.sitter;
    const color = s.className ? s.className : "#EEE";
    const { appointments } = this.props;
    let shift = this.props.sitter.shift;
    let isToday = moment(this.props.selectedDate).isSame(moment(), "day");
    let gonna_be_late;
    let hasIdleTime;
    const completedAppts = [];

    appointments.forEach((a, i) => {
      let projStart = a.projected_start_time
        ? a.projected_start_time
        : a.scheduled_start_time;
      let lateness =
        this.props.sitter.lateness && isToday ? this.props.sitter.lateness : 0;
      let idle = a.idle_time ? a.idle_time : 0;
      if (idle > 0) hasIdleTime = true;
      let newTime = moment(projStart)
        .add(lateness, "minutes")
        .subtract(idle, "minutes");
      if (
        a.appointment_status.id === 2 &&
        newTime.isAfter(moment(a.window_end_time))
      )
        gonna_be_late = true;
      if (a.actual_end_time) completedAppts.push(a);
    });

    let late = {
      marginLeft: "20px",
      color: s.lateness > 15 ? "red" : "black"
    };

    return (
      <div
        key={`sitterMap${s._id}`}
        onClick={() => this.props.sitterPreview(s)}
      >
        <div
          className={`sitter-item row ${gonna_be_late ? "gonna_be_late" : ""}`}
        >
          <div className="col-sm-1">
            <i className={`fa fa-circle fa-stack-1x`} style={{ color }}></i>
          </div>
          <div className="col-sm-11">
            <span
              className="sitter-appts-name"
              onMouseEnter={() => this.props.highlightSitter(s._id)}
              onMouseLeave={() => this.props.highlightSitter(null)}
            >
              {s.contact.full_name} (
              {`${completedAppts.length}/${appointments.length}`}){" "}
              {/*`${sa && helpfulGaps && helpfulGaps.length && inZone ? 'OPEN' : ''}`*/}
              {s.full_time ? <i className="fa fa-clock-o" /> : null}
              {hasIdleTime ? <i className="fa fa-hourglass-half"></i> : null}
            </span>
            <span>
              {this.getTrain()}
              {this.getBreak()}
            </span>
            <br />
            {shift ? (
              <span>
                {moment(shift.StartTimeLocalized).format("hh:mm")} -{" "}
                {moment(shift.EndTimeLocalized).format("hh:mm")}
              </span>
            ) : null}
            {this.isLate() ? (
              <span style={late}>{this.isLate()} min. behind</span>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default SitterAppointmentListItem;
