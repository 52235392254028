import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

class ApartmentRowItem extends Component {
  playcareText() {
    if (this.props.playcare && this.props.playcare.enabled)
      return "Playcare Enabled";
    return "Playcare Unavailable";
  }

  playcareClass() {
    if (this.props.playcare && this.props.playcare.enabled)
      return "text-success";
    return "text-danger";
  }

  conciergeText() {
    const { concierge } = this.props;
    if (!this.props.concierge_enabled) return "Concierge Unavailable";
    const days = concierge.days.sort().map(d => {
      const day = weekdays.find(w => w.id === d);
      return day.name;
    });
    return `Available ${days.join(", ")} from ${moment(
      concierge.window_start_time,
      "HH:mm"
    ).format("hh:mma")}-${moment(concierge.window_end_time, "HH:mm").format(
      "hh:mma"
    )}`;
  }

  conciergeClass() {
    if (this.props.concierge_enabled) return "text-success";
    return "text-danger";
  }

  render() {
    return (
      <Link
        to={{
          pathname: `/editapartment/${this.props.id}`,
          state: {
            name: this.props.name,
            playcare: this.props.playcare,
            market_id: this.props.market_id,
            concierge: this.props.concierge
          }
        }}
      >
        <div className="row">
          <div className="col-sm-4">{this.props.name}</div>
          <div className="col-sm-4">
            <span className={this.conciergeClass()}>
              {this.conciergeText()}
            </span>
          </div>
          <div className="col-sm-4">
            <span className={this.playcareClass()}>{this.playcareText()}</span>
          </div>
        </div>
      </Link>
    );
  }
}

const weekdays = [
  { id: 0, name: "Sun" },
  { id: 1, name: "Mon" },
  { id: 2, name: "Tue" },
  { id: 3, name: "Wed" },
  { id: 4, name: "Thu" },
  { id: 5, name: "Fri" },
  { id: 6, name: "Sat" }
];

export default ApartmentRowItem;
