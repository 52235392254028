import React from "react";

const options = [
  {
    name: "Yes",
    value: true
  },
  {
    name: "No",
    value: false
  }
];

// Each {option} needs to have an `value` and `name` property.
export default class RecurringBoolField extends React.Component {

  static defaultProps = {
    disabled: false
  };

  render = () => {
    const { input } = this.props;
    // const { touched, error, warning } = this.props.meta;

    // TODO: Set up error class for checkbox inputs
    // const errorClass = touched && error ? ' error-text error-border' : '';
    return (
      <div className="toggle-box-container small-buttons">
        {options.map((option, index) => {
          const checked = option.value === !!input.value;
          return (
            <div
              className={`toggle-box btn btn-tertiary${
                checked ? " checked" : ""
              }`}
              key={`recurring-${input.name}-${index}`}
              onClick={() => input.onChange(option.value)}
            >
              <label>{option.name}</label>
            </div>
          );
        })}
      </div>
    );
  };
}
