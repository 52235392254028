// appointments
export const CLIENT_GET_APPOINTMENT_LIST_SUCCESS =
  "CLIENT_GET_APPOINTMENT_LIST_SUCCESS";
export const CLIENT_GET_APPOINTMENT_DETAIL_SUCCESS =
  "CLIENT_GET_APPOINTMENT_DETAIL_SUCCESS";
export const CLIENT_REQUEST_APPOINTMENT_SUCCESS =
  "CLIENT_REQUEST_APPOINTMENT_SUCCESS";
export const CLIENT_UPDATE_APPOINTMENT_SUCCESS =
  "CLIENT_UPDATE_APPOINTMENT_SUCCESS";
export const CLIENT_CANCEL_APPOINTMENT_SUCCESS =
  "CLIENT_CANCEL_APPOINTMENT_SUCCESS";
export const GET_CLIENT_USER_INFO_SUCCESS = "GET_CLIENT_USER_INFO_SUCCESS";
