import React, { Component } from "react";
import { DefaultModal } from "../../";

class CancelConciergeModal extends Component {

  render() {
    const { modalActions, cancelConcierge, count } = this.props;
    return (
      <DefaultModal
        title="Cancel Future Concierge"
        onDismiss={modalActions.pop}
        options={
          <div>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={modalActions.pop}
            >
              Close
            </button>
            {count ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={cancelConcierge}
              >
                Cancel Future Concierge Visits
              </button>
            ) : null}
          </div>
        }
      >
        {count ? (
          <div>
            Are you sure you wish to cancel all future Concierge visits at{" "}
            {this.props.name}? This will effect {this.props.count} visits and
            cannot be undone...
          </div>
        ) : (
          <div>
            No worries, there are no future concierge visits at{" "}
            {this.props.name}
          </div>
        )}
      </DefaultModal>
    );
  }
}

export default CancelConciergeModal;
