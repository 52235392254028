import React, { Component } from "react";
import { ChromePicker as ColorPicker } from "react-color";

export default class ColorField extends Component {

  static defaultProps = {
    required: false
  };

  state = {
    active: false,
    displayColorPicker: false
  };

  handleOpen = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChangeComplete = (color, event) => {
    const { input } = this.props;
    input.onChange(color.hex);
  };

  render() {
    const { displayColorPicker } = this.state;
    const { input, name, placeholder } = this.props;

    return (
      <div className="color-picker">
        <div className="picker-wrapper">
          <label htmlFor={name}>{placeholder}</label>
          <div className="swatch" onClick={this.handleOpen}>
            <div
              className="swatch-color"
              style={{ backgroundColor: input.value }}
            />
          </div>
        </div>

        {displayColorPicker ? (
          <div className="swatch-popover">
            <div className="swatch-cover" onClick={this.handleClose} />
            <ColorPicker
              color={input.value}
              disableAlpha
              onChangeComplete={this.handleChangeComplete}
            />
          </div>
        ) : null}
      </div>
    );
  }
}
