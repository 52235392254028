import React, { Component } from "react";
import Tooltip from "rc-tooltip";

class ZipCodeRowItem extends Component {
  render() {
    const { zip, createDeleteModal } = this.props;
    return (
      <div className="row zip-row">
        <div className="col-sm-10 zip-code">{zip.zip_code}</div>
        <div className="col-sm-2">
          <Tooltip
            placement="top"
            overlay={
              <div>
                {this.props.active ? "Move to Wait List" : "Activate Zip Code"}
              </div>
            }
            mouseEnterDelay={0.2}
            mouseLeaveDelay={0.1}
            overlayClassName="annotation-tooltip"
          >
            {this.props.active ? (
              <i
                className="fa fa-toggle-off fa-2x"
                onClick={() => {
                  this.props.updateZip(zip);
                }}
              ></i>
            ) : (
              <i
                className="fa fa-toggle-on fa-2x"
                onClick={() => {
                  this.props.updateZip(zip);
                }}
              ></i>
            )}
          </Tooltip>
          <Tooltip
            placement="top"
            overlay={<div>Delete Zip Code</div>}
            mouseEnterDelay={0.2}
            mouseLeaveDelay={0.1}
            overlayClassName="annotation-tooltip"
          >
            <i
              className="fa fa-times fa-2x"
              aria-hidden="true"
              onClick={() => {
                createDeleteModal(zip._id);
              }}
            ></i>
          </Tooltip>
        </div>
      </div>
    );
  }
}

export default ZipCodeRowItem;
