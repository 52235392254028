import React, { Component } from "react";
import { connect } from "react-redux";
import { submit } from "redux-form";
import { catchFormServerErrors } from "../../../helpers";

// Actions
import {
  pushModal,
  popModal,
  showFormErrorMessage,
  showSuccessMessage
} from "../../../actions/general.actions";
import {
  getAppointmentDetail as getAppointmentDetailAction,
  rejectAppointment
} from "../../../actions/admin/appointments.actions";

// Components
import { DefaultModal, RejectAppointmentForm } from "../../";

class RejectAppointmentModal extends Component {

  handleReject = values => {
    const { appointment, onSubmitReject } = this.props;
    const model = { rejection_reason: values.rejectionReason };
    return onSubmitReject(appointment._id, model).then(res =>
      catchFormServerErrors(res)
    );
  };

  render() {
    const {
      appointment,
      displayFormError,
      displayFormSuccess,
      getAppointmentDetail,
      modalActions,
      submitRejectForm
    } = this.props;

    return (
      <DefaultModal
        title="Reject Appointment"
        onDismiss={modalActions.pop}
        options={
          <div>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={modalActions.pop}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={submitRejectForm}
            >
              Reject
            </button>
          </div>
        }
      >
        <span>
          <RejectAppointmentForm
            onSubmit={this.handleReject}
            onSubmitFail={errors => displayFormError(errors)}
            onSubmitSuccess={() => {
              displayFormSuccess("Appointment successfully rejected.");
              getAppointmentDetail(appointment._id);
              modalActions.pop();
            }}
          />
          <p>
            The client <strong>will receive a notification</strong> that the
            appointment has been rejected.
          </p>
        </span>
      </DefaultModal>
    );
  }
}

const mapStateToProps = state => ({
  appointment: state.admin.appointments.appointmentDetail
});

const mapDispatchToProps = dispatch => ({
  displayFormError: errors =>
    dispatch(showFormErrorMessage(errors, "adminAppointmentDetailsReject")),
  displayFormSuccess: message =>
    dispatch(showSuccessMessage(message, "adminAppointmentDetailsReject")),
  getAppointmentDetail: appointmentId =>
    dispatch(getAppointmentDetailAction(appointmentId)),
  modalActions: {
    push: component => dispatch(pushModal(component)),
    pop: () => dispatch(popModal())
  },
  submitRejectForm: () => dispatch(submit("rejectAppointment")),
  onSubmitReject: (appointmentId, model) =>
    dispatch(rejectAppointment(appointmentId, model))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RejectAppointmentModal);
