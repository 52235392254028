import React, { Component } from "react";
import { connect } from "react-redux";
import { createUserService } from "../../services";
import { PageTitleComponent, StaffForm } from "../../components";
import { catchFormServerErrors } from "../../helpers";
// Actions
import { createStaff } from "../../actions/admin/staff.actions";
import {
  showFormErrorMessage,
  showSuccessMessage
} from "../../actions/general.actions";
import { auth, resetPassword } from "../../services/firebaseHelpers";

class AddStaffPage extends Component {
  constructor() {
    super();

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(values) {
    try {
      console.log('values..', values)
      this.props.showFetch();
      const email = values.email;
      const model = createUserService.getAddStaffModel(values);
      await this.props.onSubmit(model);
      if (!values._id) resetPassword(email)
      this.props.removeFetch();
    } catch (e) {
      this.props.removeFetch();
      if (e) catchFormServerErrors(e);
    }
  }

  render() {
    const {
      displayFormError,
      displayFormSuccess,
      formConstants,
      user,
      markets
    } = this.props;

    return (
      <div className="card">
        <PageTitleComponent title="Add New Staff Member" />
        <StaffForm
          onSubmit={this.handleSubmit}
          onSubmitFail={errors => displayFormError(errors)}
          markets={markets}
          onSubmitSuccess={() =>
            displayFormSuccess("New staff member has been created.")
          }
          formConstants={formConstants}
          initialValues={{ state: 33 }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  formConstants: state.general.formConstants,
  user: state.general.user,
  market: state.admin.general.location,
  markets: state.admin.general.markets
});

const mapDispatchToProps = dispatch => ({
  showFetch: () => dispatch({ type: "FETCH_REQUEST", context: "addUser" }),
  removeFetch: () => dispatch({ type: "FETCH_SUCCESS", context: "addUser" }),
  displayFormError: errors =>
    dispatch(showFormErrorMessage(errors, "addStaffForm")),
  displayFormSuccess: message =>
    dispatch(showSuccessMessage(message, "addStaffForm")),
  onSubmit: model => dispatch(createStaff(model))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddStaffPage);
