import React, { Component } from "react";
import moment from "moment";

const statusColors = {
  completed: "#9DD7CC",
  "in-progress": "#F8787A",
  approved: "#AFD47B",
  pending: "#FED866",
  "change-requested": "#AB8BE8",
  selected: "#91CDC2"
};

class Timeblock extends Component {
  renderTime = () => {
    return this.props.time.appointment ? (
      <div className={`col-sm-12`}>
        {this.props.time.appointment.appointment_type.name}
      </div>
    ) : null;
  };

  setAppointment = () => {
    if (this.props.time.appointment) {
      return;
    }
    this.props.setAppointment(
      this.props.appointment,
      this.props.time,
      this.props.sitter
    );
  };

  render() {
    let status;
    if (this.props.time.appointment) {
      status = "map-closed";
    } else {
      status = "map-open";
    }

    let sameAppt = "diffAppt";
    if (
      this.props.time.appointment &&
      this.props.appointment &&
      this.props.time.appointment._id === this.props.appointment._id
    ) {
      status = "sameAppt";
    }

    return (
      <div
        className={`row petMarkerTimeBlock ${status} ${sameAppt} ${
          this.props.time.inRange ? "inRange" : "notInRange"
        }`}
        onClick={() => this.setAppointment()}
      >
        <div className="col-sm-12">
          {moment(this.props.time.time).format("hh:mm")}
        </div>
        {this.renderTime()}
      </div>
    );
  }
}

export default Timeblock;
