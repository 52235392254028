export default function validate(values) {
  var errors = {};

  // Personal Information
  if (!values.firstName) errors.firstName = true;
  if (!values.lastName) errors.lastName = true;
  if (!values.market_id) errors.market_id = true;

  // if (!values.hireDate) errors.hireDate = true;
  if (!values.gender) errors.gender = true;
  if (!values.email) {
    errors.email = true;
  }
  // if (!values.dob) errors.dob = true;

  // Home Address
  // if (!values.address) errors.address = true;
  // if (!values.city) errors.city = true;
  // if (!values.state || values.state === "-1") errors.state = true;
  // if (!values.zip) errors.zip = true;
  // if (!values.zone_ids) errors.zone_ids = true;
  // Contact
  if (!values.mobile) errors.mobile = true;
  // if (!values.emergencycontactname) errors.emergencycontactname = true;
  // if (!values.emergencycontactphone) errors.emergencycontactphone = true;

  // Payroll
  // if (!values.stafftype || values.stafftype === "-1") errors.stafftype = true;
  // if (!values.paytype || values.paytype === "-1") errors.paytype = true;
  // if (!values.roles || !values.roles.length) errors.roles = true;
  // Transportation
  // if (!values.driverslicense) errors.driverslicense = true;
  // if (!values.licenseplate) errors.licenseplate = true;

  return errors;
}
