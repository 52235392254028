import React from "react";
import { formatNumsToDollars } from "../../../helpers";

// Each {option} needs to have an `id` and `name` property.
export default class AddonsField extends React.Component {

  static defaultProps = {
    disabled: false
  };

  render = () => {
    const { input, options } = this.props;
    // const { touched, error, warning } = this.props.meta;

    // TODO: Set up error class for checkbox inputs
    // const errorClass = touched && error ? ' error-text error-border' : '';
    return (
      <div className="toggle-box-container">
        {options.map((option, index) => {
          const checked = input.value.includes(option.id);
          return (
            <div
              className={`toggle-box btn btn-tertiary${
                checked ? " checked" : ""
              }`}
              key={`${input.name}-${index}`}
              onClick={() => {
                const newValue = [...input.value];
                if (input.value.includes(option.id)) {
                  newValue.splice(newValue.indexOf(option.id), 1);
                } else {
                  newValue.push(option.id);
                }
                return input.onChange(newValue);
              }}
            >
              <label>
                {option.name}
                {option.price ? (
                  <span>
                    <br />
                    (Adds {formatNumsToDollars(option.price)})
                  </span>
                ) : null}
              </label>
            </div>
          );
        })}
      </div>
    );
  };
}
