import React, { Component } from "react";
import moment from "moment-timezone";
import {
  AdminCommentIcon,
  ClientCommentIcon,
  ScheduleAppointmentForm,
  ZoneBadge
} from "../../";
import PetCardCompact from "./PetCardCompact";

export default class PetCardCondensed extends Component {

  static defaultProps = {
    handleSelectAppointment: () => {}
  };

  state = {
    mouseOverCloseBtn: false,
    mouseIsOver: false,
    preview: false
  };

  closeButton = () => {
    if (this.props.appointment.appointment_status.id !== 1) {
      return null;
    } else {
      return (
        <div
          className="closeButton"
          onClick={() => this.props.resetAppointment(this.props.appointment)}
          onMouseOver={() => this.setState({ mouseOverCloseBtn: true })}
          onMouseLeave={() => this.setState({ mouseOverCloseBtn: false })}
        >
          X
        </div>
      );
    }
  };

  showHover = () => {
    return (
      <div className="petcard-hover">
        <PetCardCompact
          onClickAppointmentDetail={this.props.onClickAppointmentDetail}
          appointment={this.props.appointment}
        />
      </div>
    );
  };

  showDetail = () => {
    if (this.state.mouseOverCloseBtn) return;
    this.setState({ mouseIsOver: true });
    setTimeout(() => {
      if (this.state.mouseIsOver) {
        this.setState({ preview: true });
      }
    }, 600);
  };

  hideDetail = () => {
    this.setState({ mouseIsOver: false, preview: false });
  };

  render() {
    const { appointment } = this.props;
    const {
      appointment_status,
      contact,
      duration_in_minutes,
      _id,
      client,
      scheduled_start_time,
      window_start_time,
      window_end_time,
      home
    } = appointment;
    const pets = appointment.pets || appointment.appointment_pets;
    const status = appointment_status.id === 1 ? "pending" : "approved";
    status.replace(" ", "-");

    let startTime = moment(scheduled_start_time);
    let windowStart = moment(window_start_time);
    let windowEnd = moment(window_end_time);

    const petNames = pets
      ? pets.map(pet => pet.name).join(", ")
      : "Placeholder Pet Names";

    return (
      <div className="petCardCondensedWrapper">
        <div
          onMouseEnter={() => (appointment ? this.showDetail() : null)}
          onMouseDown={() => (appointment ? this.hideDetail() : null)}
          onMouseLeave={() => (appointment ? this.hideDetail() : null)}
        >
          {this.state.preview ? this.showHover() : null}
          <div className={`pet-card-condensed ${status}`}>
            <div
              onClick={() => {
                this.setState({ mouseIsOver: false, preview: false });
                this.props.onClickAppointmentDetail(_id);
              }}
            >
              <div className="condensed-client-info-wrapper row">
                <div className="col-sm-12">
                  <p className="pet-name">{petNames}</p>
                </div>
              </div>
              <div className="appointment-detail-wrapper">
                <p>
                  {windowStart.format("h:mmA")} - {windowEnd.format("h:mmA")},{" "}
                  {duration_in_minutes}Min
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>{this.closeButton()}</div>
      </div>
    );
  }
}
