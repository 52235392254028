import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "../../services/network.js";

import { CardComponent, PartnerForm } from "../../components";

import history from "../../actions/history";

class AddPartner extends Component {
  savePartner = async (partner, valid) => {
    const { showSpinner, stopSpinner, showMessage, removeMessage } = this.props;
    showSpinner();
    if (valid) {
      let body = { ...partner };
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/partner`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );
      if (data._id) {
        showMessage({
          message: "Partner successfully created",
          messageType: "success"
        });
        history.push(`/editpartner/${data._id}`);
      } else {
        showMessage({
          message: data,
          messageType: "error"
        });
      }
    } else {
      showMessage({
        message: "Partner name must be defined, and a market must be selected",
        messageType: "error"
      });
    }
    setTimeout(this.removeMessage, 3000);
    stopSpinner();
  };

  render() {
    return (
      <CardComponent title="Add Partner">
        <PartnerForm savePartner={this.savePartner} />
      </CardComponent>
    );
  }
}

const mapStateToProps = state => ({
  market: state.admin.general.location
});

const mapDispatchToProps = dispatch => ({
  showSpinner: () => dispatch({ type: "FETCH_REQUEST" }),
  stopSpinner: () => dispatch({ type: "FETCH_SUCCESS" }),
  showMessage: msg => dispatch({ type: "ADD_MESSAGE", payload: msg }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPartner);
