import {
  deleteJSON,
  getJSON,
  postJSON,
  putJSON,
  postFormData,
  putFormData,
  oktaPostJSON
} from "./api.config";

const endpoints = {
  admin: {
    reviewLineItems: () => getJSON(`/admin/reviewPayments`),
    addPet: (clientId, formData) =>
      postFormData(`/admin/clients/${clientId}/pets`, formData),
    addZone: model => postJSON("/admin/settings/zones", model),
    cancelAppointment: (appointmentId, model) =>
      putJSON(`/admin/appointments/${appointmentId}/cancel`, model),
    clientActivation: (clientId, model) =>
      putJSON(`/admin/clients/${clientId}/activation`, model),
    createStaffMember: model => postJSON("/admin/createStaff", model),
    createClient: model => postJSON("/admin/createClient", model),
    getAllStaff: qs => getJSON(`/admin/staff-list?${qs}`),
    getAllClients: qs => getJSON(`/admin/client-list?${qs}`),
    getAllFacilities: () => getJSON(`/admin/facilities`),
    getAppointmentDetail: appointmentId =>
      getJSON(`/admin/appointments/${appointmentId}`),
    getAppointmentList: qs => getJSON(`/admin/appointments?${qs}`),
    getAppointmentsAllSitters: qs =>
      getJSON(`/admin/appointmentsBySitter?${qs}`),
    getDashboardStats: qs => getJSON(`/admin/dashboard-stats?${qs}`),
    getClient: clientId => getJSON(`/admin/clients/${clientId}`),
    getPet: petId => getJSON(`/admin/clients/pets/${petId}`),
    deactivatePet: petId => putJSON(`/admin/clients/pets/${petId}/deactivate`),
    getStaffMember: staffId => getJSON(`/admin/staff/${staffId}`),
    getAllSchedules: () => getJSON(`/admin/getAllSchedules`),
    approveAppointment: (appointmentId, model) =>
      putJSON(`/admin/appointments/${appointmentId}/approve`, model),
    rejectAppointment: (appointmentId, model) =>
      putJSON(`/admin/appointments/${appointmentId}/reject`, model),
    requestFormConstants: () => getJSON("/admin/constants"),
    scheduleAppointment: (appointmentId, model) =>
      putJSON(`/admin/appointments/${appointmentId}/schedule`, model),
    createAppointment: model => postJSON("/admin/appointments/", model),
    updateAppointment: (appointmentId, model) =>
      putJSON(`/admin/appointments/${appointmentId}`, model),
    updateMultipleAppointments: appointments =>
      putJSON(`/admin/updateMultipleAppointments`, appointments),
    approveMultipleAppointments: appointments =>
      putJSON(`/admin/approveAppointments/multiple`, appointments),
    updateClient: (clientId, model) =>
      putJSON(`/admin/clients/${clientId}`, model),
    updatePet: (petId, formData) =>
      putFormData(`/admin/clients/pets/${petId}`, formData),
    updateStaffMember: (staffId, model) =>
      putJSON(`/admin/staff/${staffId}`, model),
    staffActivation: (staffId, model) =>
      putJSON(`/admin/staff/${staffId}/activation`, model),
    saveMapAppointment: (model, draft) =>
      putJSON(`/admin/saveMapAppointment${draft ? "?draft=true" : ""}`, model),
    updateZone: (zoneId, model) =>
      putJSON(`/admin/settings/zones/${zoneId}`, model),
    deleteZone: zoneId => deleteJSON(`/admin/settings/zones/${zoneId}`),
    verifyUser: token => postJSON(`/users/verify?sptoken=${token}`),
    setPaymentMethod: (clientId, model) =>
      postJSON(`/admin/clients/${clientId}/payment-method/set`, model),
    createLineItem: model => postJSON("/admin/line-items/add", model),
    getLineItems: () => getJSON("/admin/line-items"),
    getStripeCharges: () => getJSON("/admin/stripe-charges"),
    retryClientCharge: model =>
      postJSON("/admin/process-delinquent-client-balance", model),
    getActivities: qs => getJSON(`/admin/activities?${qs}`),
    loadStaffData: qs => getJSON(`/admin/staffData?${qs}`)
  },
  client: {
    clientAddPet: (clientId, pet) => postJSON(`/client/${clientId}/pets`, pet),
    clientUpdatePet: (petId, formData) =>
      putFormData(`/client/pets/${petId}`, formData),
    cancelAppointment: (appointmentId, model) =>
      putJSON(`/client/appointments/${appointmentId}/cancel`, model),
    getAppointmentList: qs => getJSON(`/client/appointments?${qs}`),
    getAppointmentDetail: appointmentId =>
      getJSON(`/client/appointments/${appointmentId}`),
    requestClientUserInfo: () => getJSON("/client/user-info"),
    requestAppointment: model => postJSON("/client/appointments", model),
    setPaymentMethod: model => postJSON(`/client/payment-method/set`, model),
    getStripeCharges: () => getJSON("/client/stripe-charges"),
    getLineItems: () => getJSON("/client/line-items"),
    updateAppointment: (appointmentId, model) =>
      putJSON(`/client/appointments/${appointmentId}`, model),
    updateClientProfile: model => putJSON(`/client/update-profile`, model)
  }
};

export default endpoints;
