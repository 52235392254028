import moment from "moment";

const initState = {
  calendarDay: moment(),
  calendarMonth: moment(),
  calendarWeek: moment(),
  calendarFilters: [],
  unassignedFilters: [],
  selectedDate: moment()
    .hour(12)
    .toDate(),
  assignedAppointments: [],
  sitters: {}
};

const scheduler = (state = initState, action) => {
  switch (action.type) {
    case "SET_SCHEDULER_CALENDAR_MONTH":
      return { ...state, calendarMonth: action.payload };

    case "SET_SCHEDULER_CALENDAR_WEEK":
      return { ...state, calendarWeek: action.payload };

    case "SET_SCHEDULER_CALENDAR_DAY":
      return { ...state, calendarDay: action.payload };

    case "SET_SCHEDULER_FILTERS":
      if (action.payload.context === "calendar") {
        return { ...state, calendarFilters: action.payload.filters };
      } else if (action.payload.context === "unassigned") {
        return { ...state, unassignedFilters: action.payload.filters };
      }
      return state;

    case "SET_SCHEDULER_SELECTED_DATE":
      return { ...state, selectedDate: action.payload };

    case "SET_SCHEDULER_SELECTED_SITTER":
      return {
        ...state,
        sitterColumns: {
          ...state.sitterColumns,
          ...action.payload
        }
      };
    case "ADD_SITTER":
      const newObj = {
        ...state,
        sitters: {
          ...state.sitters,
          ...action.payload
        }
      };
      return newObj;
    case "SET_APPOINTMENT":
      // const ourArr = state.assignedAppointments.slice();
      // const findApp = ourArr.findIndex(a => a.id === action.payload.appointment.id);
      // if (findApp > -1) ourArr.splice(findApp, 1);
      // const appt = action.payload.appointment;
      // appt.sitter_id = action.payload.sitter;
      // appt.scheduled_start_time = action.payload.time;
      // ourArr.push(appt);
      return {
        ...state
        // assignedAppointments: ourArr
      };
    default:
      return state;
  }
};

export default scheduler;
