const messages = (state = [], action) => {
  switch (action.type) {
    case "ADD_MESSAGE":
      return [
        ...state,
        {
          message: action.payload.message,
          messageContext: action.payload.messageContext,
          messageType: action.payload.messageType,
          timestamp: new Date().getTime()
        }
      ];

    case "REMOVE_MESSAGE":
      return state.slice(1, state.length);

    default:
      return state;
  }
};

export default messages;
