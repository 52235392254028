import moment from "moment";
import { CONTACT_TYPE, PHONE_NUMBER_TYPE } from "../enums";

export default {
  /**
   * Takes form values and creates a model to send
   * to the server to create Staff user.
   *
   * @param {any} values An object of form field values.
   */
  getAddStaffModel(values) {
    let model = {
      uid: values.uid,
      deputy_id: values.deputy_id,
      first_name: values.firstName,
      last_name: values.lastName,
      full_name: `${values.firstName} ${values.lastName}`,
      email: values.email,
      roles: values.roles,
      dob: moment(values.dob).format("YYYY-MM-DD"),
      staff_type: values.stafftype,
      pay_type: values.paytype,
      driver_license: values.driverslicense,
      instagram: values.instagram,
      license_plate: values.licenseplate,
      market_id: values.market_id,
      hire_date: moment(values.hireDate).toDate(),
      hire_source: values.hire_source,
      gender: values.gender,
      notes: values.notes,
      zones: values.zones,
      trainings_completed: values.trainings_completed || [],
      use_secondary_for_routing: values.use_secondary_for_routing,
      min_visits: values.min_visits,
      speed: values.speed,
      break_start: values.break_start,
      break_end: values.break_end,
      use_break: values.use_break,
      full_time: values.full_time,
      contact: {
        phone_number: values.mobile,
        address: {
          address_line_1: values.address,
          address_line_2: values.address2,
          city: values.city,
          zip_code: values.zip,
          state: values.state,
          latitude: values.latitude,
          longitude: values.longitude
        },
        secondary_address: {
          secondary_address: values.secondary_address,
          secondary_address2: values.secondary_address2,
          secondary_city: values.secondary_city,
          secondary_state: values.secondary_state,
          secondary_zip: values.secondary_zip,
          latitude: values.secondary_latitude,
          longitude: values.secondary_longitude
        }
      },
      emergency_contact: {
        first_name: values.emergencycontactfirstname,
        last_name: values.emergencycontactlastname,
        phone_number: values.emergencycontactphone
      },
      authorized_pickups: values.authorized_pickups
    };
    return model;
  },

  getInitialFormValuesForStaffMember(staff) {
    return {
      _id: staff._id,
      deputy_id: staff.deputy_id,
      dob: moment(staff.dob).format("YYYY/MM/DD"),
      roles: staff.roles,
      stafftype: staff.staff_type,
      paytype: staff.pay_type,
      gender: staff.gender,
      driverslicense: staff.drivers_license,
      licenseplate: staff.license_plate,
      hireDate: moment(staff.hire_date).toDate(),
      hire_source: staff.hire_source,
      instagram: staff.instagram,
      notes: staff.notes,
      zones: staff.zones,
      market_id: staff.market_id,
      firstName: staff.contact.first_name,
      lastName: staff.contact.last_name,
      email: staff.contact.email,
      address: staff.home.address.address_line_1,
      address2: staff.home.address.address_line_2,
      city: staff.home.address.city,
      state: staff.home.address.state.id,
      zip: staff.home.address.zip_code,
      latitude: staff.home.address.latitude,
      longitude: staff.home.address.longitude,
      min_visits: staff.min_visits,
      speed: staff.speed,
      mobile: staff.contact.phone_number,
      secondary_address: staff.home.secondary_address.secondary_address,
      secondary_address2: staff.home.secondary_address.secondary_address2,
      secondary_city: staff.home.secondary_address.secondary_city,
      secondary_state: staff.home.secondary_address.secondary_state,
      secondary_zip: staff.home.secondary_address.secondary_zip,
      secondary_latitude: staff.home.secondary_address.latitude,
      secondary_longitude: staff.home.secondary_address.longitude,
      emergencycontactfirstname: staff.emergency_contact.first_name,
      use_secondary_for_routing: staff.use_secondary_for_routing ? true : false,
      emergencycontactlastname: staff.emergency_contact.last_name,
      emergencycontactphone: staff.emergency_contact.phone_number,
      trainings_completed: staff.trainings_completed || [],
      break_start: staff.break_start,
      break_end: staff.break_end,
      use_break: staff.use_break,
      full_time: staff.full_time,
      authorized_pickups: staff.authorized_pickups || []
    };
  },

  /**
   * Takes form values and creates a model to send
   * to the server to create Client user.
   *
   * @param {any} values An object of form field values.
   */
  getAddClientModel(values) {
    const model = {
      first_name: values.firstName,
      last_name: values.lastName,
      full_name: values.firstName + " " + values.lastName,
      market_id: values.market_id,
      email: values.email,
      override_geocode: values.override_geocode,
      restrict_personnel: values.team_members
        ? !!values.team_members.length
        : false,
      team_members: values.team_members,
      alarm_code: values.securityalarmcode,
      building_type: values.buildingType,
      apartment_id: values.apartment_id,
      parking: values.parking,
      home_class: values.homeclass,
      home_class_notes: values.housenotes,
      lock_box: values.lockbox,
      home_access_notes: values.homeaccessnotes,
      gender_preference: values.gender_preference,
      wifi_info: values.wificode,
      signup_date: moment(values.signUpDate).format("YYYY-MM-DD"),
      notes: values.notes,
      referral_source: values.referralsource,
      referral_specifics: values.referral_specifics,
      promo_code: values.promo_code,
      instagram: values.instagram,
      visit_routine: values.visit_routine,
      private_admin_notes: values.private_admin_notes,
      waste_disposal: values.waste_disposal,
      secured_yard: values.secured_yard ? true : false,
      zones: values.zones,
      entry_time: values.entry_time,
      exit_time: values.exit_time,
      access_method: values.access_method,
      avoid_tms: values.avoid_tms,
      contact: {
        phone_number: values.mobile,
        address: {
          address_line_1: values.address,
          address_line_2: values.address2,
          city: values.city,
          state: values.state,
          zip_code: values.zip,
          latitude: values.latitude,
          longitude: values.longitude,
          enforce_device_within_region: values.enforce_device_within_region
            ? true
            : false,
          override_geocode: values.override_geocode
        }
      },
      secondary_contact: {
        first_name: values.secondaryfirstname,
        last_name: values.secondarylastname,
        phone_number: values.secondaryphone,
        email: values.secondaryemail
      },
      emergency_contact: {
        first_name: values.emergencycontactfirstname,
        last_name: values.emergencycontactlastname,
        phone_number: values.emergencycontactphone
      },
      consultation_status: values.consultation_status,
      dog_walking: values.dog_walking,
      authorized_pickups: values.authorized_pickups || []
    };
    return model;
  },

  getInitialFormValuesForClient(client) {
    const initialValues = {
      _id: client._id,
      market_id: client.market_id,
      state: client.home.address.state && client.home.address.state.id,
      firstName: client.contact.first_name,
      lastName: client.contact.last_name,
      signUpDate: moment(client.signup_date).toDate(),
      address: client.home.address.address_line_1,
      address2: client.home.address.address_line_2,
      apartment_id: client.home.apartment_id,
      city: client.home.address.city,
      zip: client.home.address.zip_code,
      buildingType: client.home.building_type,
      lockbox: client.home.lock_box,
      securityalarmcode: client.home.alarm_code,
      secured_yard: client.secured_yard,
      instagram: client.instagram,
      waste_disposal: client.waste_disposal,
      visit_routine: client.visit_routine,
      gender_preference: client.gender_preference,
      override_geocode: client.override_geocode,
      wificode: client.home.wifi_info,
      restrict_personnel: client.restrict_personnel ? true : false,
      team_members: client.team_members,
      parking: client.home.parking,
      homeaccessnotes: client.home.home_access_notes,
      homeclass: client.home.home_class,
      housenotes: client.home.home_class_notes,
      enforce_device_within_region:
        client.home.address.enforce_device_within_region,
      zones: client.zones,
      email: client.contact.email,
      mobile: client.contact.phone_number,
      referralsource: client.referral_source,
      referral_specifics: client.referralsource,
      promo_code: client.promo_code,
      private_admin_notes: client.private_admin_notes,
      notes: client.notes,
      entry_time: client.home.entry_time,
      exit_time: client.home.exit_time,
      avoid_tms: client.avoid_tms,
      access_method: client.home.access_method,
      latitude: client.home.address.latitude,
      longitude: client.home.address.longitude,
      consultation_status: client.consultation_status,
      dog_walking: client.dog_walking,
      authorized_pickups: client.authorized_pickups || []
    };

    // Check for secondary contact and merge into initial values
    if (client.secondary_contact) {
      Object.assign(initialValues, {
        secondaryfirstname: client.secondary_contact.first_name,
        secondarylastname: client.secondary_contact.last_name,
        secondaryphone: client.secondary_contact.phone_number,
        secondaryemail: client.secondary_contact.email
      });
    }

    // Check for emergency contact and merge into initial values
    if (client.emergency_contact) {
      Object.assign(initialValues, {
        emergencycontactfirstname: client.emergency_contact.first_name,
        emergencycontactlastname: client.emergency_contact.last_name,
        emergencycontactphone: client.emergency_contact.phone_number
      });
    }

    return initialValues;
  }
};
