import React, { Component } from "react";
import { Multiselect, DropdownList } from "react-widgets";
import axios from "../../../../services/network";

//move these to form constant file at some point
const sizes = ["XS", "S", "M", "L", "XL"];
const ages = ["Puppy", "Adult", "Senior"];
const temperaments = ["Calm", "Not Calm"];
const statuses = ["in use", "overflow", "disabled"];

class Playgroup extends Component {
  state = {
    adding: false,
    playgroup: {
      name: ""
    }
  };

  renderGroups = () => {
    return this.props.playgroups.map(p => {
      return (
        <div
          className="row groupRow"
          onClick={() => this.setState({ adding: true, playgroup: { ...p } })}
        >
          <div className="col-md-12">
            <div className="col-md-2 areaTitle">{p.name}</div>
            <div className="col-md-2 areaTitle">{this.renderList(p.sizes)}</div>
            <div className="col-md-2 areaTitle">{this.renderList(p.ages)}</div>
            <div className="col-md-2 areaTitle">
              {this.renderList(p.temperaments)}
            </div>
            <div className="col-md-1 areaTitle">{p.capacity}</div>
            <div className="col-md-1 areaTitle">{p.status}</div>
            <div className="col-md-1 areaTitle">{this.renderArea(p)}</div>
            <div className="col-md-1 areaTitle">
              <a onClick={() => this.delete(p)}>
                <i className="fa fa-times" />
              </a>
            </div>
          </div>
        </div>
      );
    });
  };

  renderList = l => {
    if (!l || !l.length) return null;
    return l
      .map((s, i) => {
        if (i === l.length - 1) return s;
        return `${s}, `;
      })
      .reduce((a, b) => (a += b));
  };

  renderArea = p => {
    const { areas } = this.props;
    if (!areas || !areas.length) return null;
    const ourArea = areas.find(a => a._id === p.area_id);
    if (!ourArea) return null;
    return ourArea.name;
  };

  getStaff = () => {
    let { staff } = this.props;
    return staff
      .map(s => {
        return {
          ...s,
          sort_name: `${s.last_name}, ${s.first_name}`
        };
      })
      .sort((a, b) => {
        if (a.sort_name < b.sort_name) return -1;
        if (a.sort_name > b.sort_name) return 1;
        return 0;
      });
  };

  renderForm = () => {
    if (!this.state.adding) {
      return (
        <div className="row addOnButtons">
          <div className="col-sm-4">
            <button
              className="btn btn-primary"
              onClick={() => this.setState({ adding: true })}
            >
              + Add Playgroup
            </button>
          </div>
        </div>
      );
    }
    // console.log(sizes, ages, temperaments, statuses, this.getAreas());
    return (
      <div className="row">
        <div className="col-sm-12 boarding">
          <div className="col-sm-2">
            <h4>Name</h4>
            <input
              type="text"
              value={this.state.playgroup.name}
              placeholder="NAME"
              onChange={e => this.updatePlaygroup("name", e.target.value)}
            />
          </div>
          <div className="col-sm-2">
            <h4>Sizes</h4>
            <Multiselect
              data={sizes}
              value={this.state.playgroup.sizes}
              onChange={e => this.updatePlaygroup("sizes", e)}
            />
          </div>
          <div className="col-sm-2">
            <h4>Ages</h4>
            <Multiselect
              data={ages}
              value={this.state.playgroup.ages}
              onChange={e => this.updatePlaygroup("ages", e)}
            />
          </div>
          <div className="col-sm-2">
            <h4>Temperaments</h4>
            <Multiselect
              data={temperaments}
              value={this.state.playgroup.temperaments}
              onChange={val => this.updatePlaygroup("temperaments", val)}
            />
          </div>
          <div className="col-sm-2">
            <h4>Capacity</h4>
            <input
              type="text"
              value={this.state.playgroup.capacity}
              onChange={e =>
                this.validateNumber("capacity", Number(e.target.value))
              }
            />
          </div>
          <div className="col-sm-2">
            <h4>Status</h4>
            <DropdownList
              data={statuses}
              value={this.state.playgroup.status}
              onChange={val => this.updatePlaygroup("status", val)}
            />
          </div>
          <div className="col-sm-2">
            <h4>Kennel Block</h4>
            <DropdownList
              data={this.getAreas()}
              value={this.state.playgroup.area_id}
              textField="text"
              valueField="value"
              onChange={val => this.updatePlaygroup("area_id", val.value)}
            />
          </div>
          <div className="col-sm-2">
            <h4>Assigned Staff</h4>
            <Multiselect
              data={this.getStaff()}
              value={this.state.playgroup.staff_ids}
              textField="sort_name"
              valueField="_id"
              onChange={val =>
                this.updatePlaygroup("staff_ids", val.map(s => s._id))
              }
            />
          </div>
          <div className="col-sm-12">
            <button className="btn btn-primary" onClick={() => this.save()}>
              Save
            </button>
          </div>
        </div>
      </div>
    );
  };

  getAreas = () => {
    return this.props.areas.map(a => ({ text: a.name, value: a._id }));
  };

  updatePlaygroup = (key, val) => {
    this.setState({
      ...this.state,
      playgroup: {
        ...this.state.playgroup,
        [key]: val
      }
    });
  };

  validateNumber = (key, val) => {
    if (!isNaN(val)) this.updatePlaygroup(key, val);
  };

  save = () => {
    if (!this.state.playgroup.name) return null;

    this.props.savePlaygroup(this.state.playgroup);
  };

  delete = p => {
    // console.log("delete this boi", p);
    this.props.deletePlaygroup(p);
  };

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="col-md-2 areaTitle">Name</div>
            <div className="col-md-2 areaTitle">Sizes</div>
            <div className="col-md-2 areaTitle">Ages</div>
            <div className="col-md-2 areaTitle">Temperaments</div>
            <div className="col-md-1 areaTitle">Capacity</div>
            <div className="col-md-1 areaTitle">Status</div>
            <div className="col-md-1 areaTitle">Area</div>
            <div className="col-md-1 areaTitle">Action</div>
          </div>
        </div>
        {this.renderGroups()}
        {this.renderForm()}
      </div>
    );
  }
}

export default Playgroup;
