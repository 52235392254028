import React, { Component } from "react";

export default class FormSection extends Component {

  getTitleclassName = () => {
    const title = this.props.title;
    if (title && title.length > 0) {
      return "title";
    }
    return "no-title";
  };

  render() {
    return (
      <div className="container form-container">
        <div className="row">
          <h3 className={`col-sm-12 ${this.getTitleclassName()}`}>
            {this.props.title}
          </h3>
        </div>
        {this.props.children}
      </div>
    );
  }
}
