import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "../../services/network.js";

import { CardComponent, PartnerRowItem } from "../../components";

class Partners extends Component {
  state = {
    partners: []
  };

  componentWillMount = async () => {
    const { market, showSpinner, stopSpinner } = this.props;
    showSpinner();
    if (market) {
      this.getPartners(market._id);
    }
    stopSpinner();
  };

  componentWillReceiveProps = props => {
    if (this.props.market._id !== props.market._id) {
    }
  };

  getPartners = async market_id => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URI}/admin/partners?market_id=${market_id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      }
    );
    if (data) {
      this.setState({ partners: data });
    }
  };

  renderPartners = () => {
    return this.state.partners.map(p => {
      return (
        <PartnerRowItem
          key={p._id}
          id={p._id}
          name={p.name}
          type={p.apartment_id ? "Apartment" : "Other"}
          apartment_id={p.apartment_id}
          promos={p.promos}
          referrals={p.referrals}
        />
      );
    });
  };

  render() {
    return (
      <CardComponent title="Partners">
        <div className="partner-container">
          <div className="partner-actions">
            <div className="row">
              <Link
                className="btn-primary btn"
                to={{ pathname: "/addpartner" }}
              >
                <span className="fa fa-plus" />
                Add Partner
              </Link>
              <Link
                to={{ pathname: "/addapartment" }}
                className="btn btn-primary"
              >
                <span className="fa fa-plus" />
                Add Apartment
              </Link>
            </div>
          </div>
          <div className="partner-table">
            <div className="row table-headers">
              <div className="col-xs-3 header">Name</div>
              <div className="col-xs-3 header">Type</div>
              <div className="col-xs-3 header"># Promos</div>
              <div className="col-xs-3 header"># Referrals</div>
            </div>
            {this.renderPartners()}
          </div>
        </div>
      </CardComponent>
    );
  }
}

const mapStateToProps = state => ({
  market: state.admin.general.location
});

const mapDispatchToProps = dispatch => ({
  showSpinner: () => dispatch({ type: "FETCH_REQUEST" }),
  stopSpinner: () => dispatch({ type: "FETCH_SUCCESS" }),
  showMessage: msg => dispatch({ type: "ADD_MESSAGE", payload: msg }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" })
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Partners);
