import React, { Component } from "react";
import { formatNumsToDollars } from "../../../../helpers";
class PriceForm extends Component {
  state = {
    1: 0,
    5: 0,
    15: 0
  };

  componentWillReceiveProps = nextProps => {
    this.setState({
      1: formatNumsToDollars(nextProps.data[1]),
      5: formatNumsToDollars(nextProps.data[5]),
      15: formatNumsToDollars(nextProps.data[15])
    });
  };

  componentWillMount = () => {
    this.setState({
      1: formatNumsToDollars(this.props.data[1]),
      5: formatNumsToDollars(this.props.data[5]),
      15: formatNumsToDollars(this.props.data[15])
    });
  };

  updatePrice = (e, quantity) => {
    const { duration } = this.props;
    const value = e.target.value;
    if (quantity && value) {
      const price = parseFloat(value.replace(/\D/g, ""));
      this.props.updatePrice(duration, quantity, price);
    }
  };

  render() {
    const bundles = [1, 5, 15];
    return (
      <div className="price-form">
        <h3 className="title">
          {this.props.duration === 11
            ? "Playcare"
            : `${this.props.duration} Minute Visits`}
        </h3>
        {bundles.map(b => {
          return (
            <div className="row">
              <div className="form-component-wrapper">
                <label>{b === 1 ? "Single Visit" : `${b} Visit Bundle`}</label>
                {this.props.editing ? (
                  <input
                    type="text"
                    value={this.state[b.toString()]}
                    onChange={e => {
                      this.updatePrice(e, b);
                    }}
                  />
                ) : (
                  <input
                    type="text"
                    value={this.state[b.toString()]}
                    disabled
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default PriceForm;
