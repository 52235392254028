import React from "react";
import moment from "moment";
import Tooltip from "rc-tooltip";
import { PaymentStripeSubListItem } from "../../components";
import { formatNumsToDollars } from "../../helpers";
import { Link } from "react-router-dom";

export default class PaymentStripeListItem extends React.Component {

  state = {
    listItemExpanded: false
  };

  toggleListItems = () => {
    this.setState({ listItemExpanded: !this.state.listItemExpanded });
  };

  render() {
    const { stripeCharge, userType } = this.props;
    const { listItemExpanded } = this.state;

    let PSLI__NameClass, PSLI__NotesClass;

    if (userType === "client") {
      PSLI__NameClass =
        "user-list-item-content username-container col-lg-3 col-md-2 col-sm-5 col-xs-8";
      PSLI__NotesClass =
        "user-list-item-content col-lg-3 col-md-5 col-sm-3 hidden-xs";
    } else {
      PSLI__NameClass =
        "user-list-item-content username-container col-lg-3 col-md-2 col-sm-2 col-xs-8";
      PSLI__NotesClass =
        "user-list-item-content col-lg-6 col-md-5 col-sm-5 hidden-xs";
    }

    return (
      <div>
        <div
          className={
            listItemExpanded
              ? "user-list-item stripecharge-list-item expanded"
              : "user-list-item stripecharge-list-item"
          }
          onClick={this.toggleListItems}
        >
          <div className="user-list-item-content col-lg-1 col-md-1 col-sm-2 col-xs-3">
            {moment(stripeCharge.date).format("MM/DD/YY")}
          </div>

          <div className={PSLI__NameClass}>
            <div className="user-list-name">
              {stripeCharge.client ? (
                <Link to={`/client/${stripeCharge.client._id}`}>
                  {stripeCharge.client.full_name}
                </Link>
              ) : (
                "No Client Found"
              )}
            </div>
            {/* {stripeCharge.stripe_charge_status} */}
          </div>

          {stripeCharge.stripe_charge_id ? (
            <div className="user-list-item-content col-lg-1 col-md-2 col-sm-3 hidden-xs">
              <a
                target="_blank"
                href={`https://dashboard.stripe.com/payments/${stripeCharge.stripe_charge_id}`}
              >
                {/* {listItem.appointment_id} */}
                Payment Details
              </a>
            </div>
          ) : null}

          <div className="user-list-item-content row-amount col-lg-1 col-md-2 col-sm-2 hidden-xs">
            {stripeCharge.amount >= 0 ? (
              <span className="color-black">
                {" "}
                {formatNumsToDollars(stripeCharge.amount * -1)}
              </span>
            ) : (
              <span> {formatNumsToDollars(stripeCharge.amount * -1)}</span>
            )}
          </div>

          <div className={PSLI__NotesClass}>
            {stripeCharge.stripe_receipt_code}
            {stripeCharge.stripe_error_code}
            {stripeCharge.stripe_charge_status}
            {stripeCharge.stripe_error_message}
          </div>
        </div>

        {/*listItemExpanded
          ? stripeCharge.line_items.map((item) => (
              <PaymentStripeSubListItem
                userType={userType}
                key={`item-${item.id}`}
                listItem={item}
              />
            ))
          : null
        */}
      </div>
    );
  }
}
