import React, { Component } from "react";
import {useDropzone} from 'react-dropzone';

const ProfileImageField = (props) => {
  
    const { label, input, ...rest } = props;

    console.log('input...', input)

    const onDrop = (img) => {
      console.log('drop?', img)
      input.onChange(img[0])
    }

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({ onDrop });


    return (
                <div 
                style={
                  input.value
                    ? { backgroundImage: `url(${input.value.preview})` }
                    : null
                }
                >

                
                <div
                  {...getRootProps()}
                  className="dropzone-contents"
                  style={!input.value ? { opacity: 1 } : null}
                >
                  <div>
                    <span className="fa fa-picture-o" />
                  </div>
                  <div>{label}</div>
                </div>
                </div>

    );

}

export default ProfileImageField