import React, { Component } from "react";

export default class InputField extends Component {
  
  static defaultProps = {
    required: false
  };

  state = {
    active: false
  };

  handleFocus = () => {
    this.setState({ active: true });
  };

  handleBlur = () => {
    this.setState({ active: false });
  };

  render() {
    const { active } = this.state;
    const { input, meta, placeholder, required } = this.props;
    const { touched, error } = meta;

    const wrapperClass = touched && error ? " error-text error-border" : "";
    const focusClass = active ? " field-focus" : "";
    const inputClass = touched && error ? "input-error" : "input";
    const hasValue = input.value && input.value.length > 0;

    // Build class name string for placholder
    let placeholderClass = "noselect form-label";
    
    placeholderClass += " form-label-not-empty";
    
    if (touched && error) {
      placeholderClass += " error-text";
    }

    return (
      <div
        className={`input-wrapper--form-control-wrapper-component${wrapperClass}${focusClass}`}
        onClick={() => (this.field ? this.field.focus() : null)}
        tabIndex="-1"
      >
        <label htmlFor={input.name} className={placeholderClass}>
          {placeholder}
        </label>

        <input
          {...input}
          className={`form-control form-height ${inputClass}`}
          placeholder={`${required ? "*" : ""}`}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          ref={field => (this.field = field)}
        />
      </div>
    );
  }
}
