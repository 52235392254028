import React, { Component } from "react";
import ReactDOM from "react-dom";
import moment from "moment";

export default class TimePickerListItem extends Component {
  componentWillUpdate() {
    const hideMe = ReactDOM.findDOMNode(this.timeSlotToHide);
    if (hideMe) {
      hideMe.parentNode.remove();
    }
  }

  render() {
    const { item } = this.props;
    const hourOfValue = moment(item.date).hour();
    // hide hours before 9 am and after 10 pm.
    if (hourOfValue < 9 || hourOfValue > 21) {
      return <div ref={el => (this.timeSlotToHide = el)}>NAH</div>;
    }
    return (
      <div>
        Between {moment(item.date).format("h:mm A")} and{" "}
        {moment(item.date)
          .add(1.5, "hour")
          .format("h:mm A")}
      </div>
    );
  }
}
