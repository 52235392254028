import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";

// Actions
import { pushModal, popModal } from "../../../actions/general.actions";
import { cancelAppointment as cancelAppointmentAction } from "../../../actions/admin/appointments.actions";

// Components
import { DefaultModal } from "../../";

class AdminCancelAppointmentModal extends Component {
  
  state = {
    cancelSeries: false,
    waiveLatePenalty: false
  };

  confirmCancel = e => {
    e.preventDefault();
    const { cancelSeries, waiveLatePenalty } = this.state;
    const { appointment, modalActions, submitCancel } = this.props;

    // Here, success message gets triggered in action
    submitCancel(appointment._id, {
      cancel_series: cancelSeries,
      waive_late_penalty: waiveLatePenalty
    });
    modalActions.pop();
  };

  renderLateCancellationWarning = () => {
    const { appointment } = this.props;
    let ourTime = moment.tz(appointment.time_zone).format("HH:mm");
    const requestedTime = moment(appointment.window_start_time);
    const fullPriceCancellationDeadline = requestedTime
      .clone()
      .subtract(1, "hour");
    const halfPriceCancellationDeadline = requestedTime
      .clone()
      .subtract(1, 'd')
      .hour(18)
      .minute(0);

    if (
      [8, 10, 12].includes(appointment.appointment_type.id) ||
      appointment.appointment_status.id === 1
    )
      return null;

    if (moment(ourTime, "HH:mm").isAfter(fullPriceCancellationDeadline)) {
      return (
        <p>
          Heads up! Canceling within 1 hour of the scheduled visit start-time
          will use one visit from your visit bank.
        </p>
      );
    } else if (
      moment.tz(appointment.time_zone).isAfter(halfPriceCancellationDeadline)
    ) {
      return (
        <p>
          Heads up! Canceling after 6pm on the day before the visit will incur a $6
          cancellation charge.
        </p>
      );
    }

    return null;
  };

  renderLateWaiverField = plural => {
    const { waiveLatePenalty } = this.state;
    const { appointment } = this.props;

    if ([8, 10, 12].includes(appointment.appointment_type.id)) return null;

    return (
      <div className="simple-checkbox-field">
        <div className="simple-checkbox-input">
          <input
            type="checkbox"
            id="waiveLatePenalty"
            checked={waiveLatePenalty}
            onChange={() =>
              this.setState({ waiveLatePenalty: !waiveLatePenalty })
            }
          />
          <label htmlFor="waiveLatePenalty" />
        </div>
        <span
          className="simple-checkbox-label"
          onClick={() => this.setState({ waiveLatePenalty: !waiveLatePenalty })}
        >
          Waive late fee{plural ? "s" : ""}
        </span>
      </div>
    );
  };

  renderOneOffWaiver = () => {
    const { appointment } = this.props;
    if (appointment.appointment_type.id === 12) return null;

    const requestedTime = moment(appointment.window_start_time).add(30, "m");
    const halfPriceCancellationDeadline = requestedTime
      .clone()
      .hour(8)
      .minute(0);
    const isLate = moment().isAfter(halfPriceCancellationDeadline);

    if (isLate) {
      return this.renderLateWaiverField(false);
    }
  };

  render() {
    const { cancelSeries } = this.state;
    const { appointment, modalActions } = this.props;
    const isRecurring = !!appointment.recurrence;

    return (
      <DefaultModal
        title="Appointment Cancellation"
        onDismiss={modalActions.pop}
        options={
          <div>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={modalActions.pop}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.confirmCancel}
            >
              {cancelSeries ? "Cancel Appointments" : "Cancel Appointment"}
            </button>
          </div>
        }
      >
        <p>Are you sure you want to cancel this appointment?</p>
        {this.renderLateCancellationWarning()}
        {isRecurring ? (
          <div>
            <div className="simple-checkbox-field">
              <div className="simple-checkbox-input">
                <input
                  type="checkbox"
                  id="cancelRecurring"
                  checked={cancelSeries}
                  onChange={() =>
                    this.setState({ cancelSeries: !cancelSeries })
                  }
                />
                <label htmlFor="cancelRecurring" />
              </div>
              <span
                className="simple-checkbox-label"
                onClick={() => this.setState({ cancelSeries: !cancelSeries })}
              >
                Cancel all appointments in this recurring series
              </span>
            </div>
            {this.renderLateWaiverField(true)}
          </div>
        ) : (
          this.renderOneOffWaiver()
        )}
      </DefaultModal>
    );
  }
}

const mapStateToProps = state => ({
  appointment: state.admin.appointments.appointmentDetail
});

const mapDispatchToProps = dispatch => ({
  submitCancel: (appointmentId, model) =>
    dispatch(cancelAppointmentAction(appointmentId, model)),
  modalActions: {
    push: component => dispatch(pushModal(component)),
    pop: () => dispatch(popModal())
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCancelAppointmentModal);
