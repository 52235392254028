// The sites main stylesheet
require("./styles/all.scss");

// Redux
import thunkMiddleware from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import rootReducer from "./reducers";
// import createLogger from 'redux-logger'

// const loggerMiddleware = createLogger();

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);

import renderer from "./renderer";
renderer.render(store);
