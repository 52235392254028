import { displayMessage } from "./general.actions";

export const fetchFailure = (message, messageContext) => ({
  type: "FETCH_FAILURE",
  context: messageContext,
  payload: { message, messageContext }
});

export const dispatchFetchFailure = (res, context, dispatch, error) => {
  if (res === "network") {
    console.error("Error caught in action catch:");
    console.error(error ? error : "Undefined Error");

    let message =
      "Ruh roh! Something went wrong. Please check your internet connection and try again.";
    //Bye Ruh Roh lol
    if (error && error.data && error.data.message) {
      message = error.data.message;
    }
    dispatch(fetchFailure(message, context));
    dispatch(displayMessage(message, context, "error"));
    setTimeout(() => dispatch({ type: "REMOVE_MESSAGE" }), 5000);
  } else if (res) {
    dispatch(fetchFailure(res.message, context));
    dispatch(displayMessage(res.message, context, "error"));
    setTimeout(() => dispatch({ type: "REMOVE_MESSAGE" }), 5000);
  }

  // We return the response so that redux-form forms can pick up the response as a sign of an error
  // and in turn fail form submission (if a form caused this dispatch in the first place).
  return res;
};
