import React, { Component } from "react";

export default class AuthWrapperComponent extends Component {
  render() {
    const logo = require("../../images/waggle-logo.svg");

    return (
      <div className="login-bg">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 text-center p-md">
              <img
                src={logo}
                alt="The Waggle Company Logo"
                className="login-logo"
              />
            </div>
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }
}
