import React, { Component } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import { TimeInput } from "react-widgets";

export default class DateTimeField extends Component {

  static defaultProps = {
    dateFormat: "h:mm A on dddd MMM. D, Y",
    parseInput: input => {
      if (input.toLowerCase() === "today") {
        return new Date();
      } else if (moment(input).isValid()) {
        return moment(input).toDate();
      }
    }
  };

  state = {
    active: false
  };

  componentDidMount() {
    // if (this.props.disableManualInput) {
    //   const input = ReactDOM.findDOMNode(
    //     this.picker.refs.inner.refs.valueInput
    //   );
    //   input.readOnly = true;
    // }
  }

  handleFocus = () => {
    this.setState({ active: true });
  };

  handleBlur = () => {
    this.setState({ active: false });
  };

  render = () => {
    const { active } = this.state;
    // eslint-disable-next-line
    const {
      input,
      meta,
      dateFormat,
      disableManualInput,
      parseInput,
      placeholder,
      required,
      ...rest
    } = this.props;
    const { dirty, error, touched, warning } = meta;

    // const { warning } = meta;

    //Looking at the docs, passing step as a prop to Field won't network
    //gonna add this workaround for now
    const step =
      input.name === "window_start_time" || "window_end_time" ? 10 : null;

    // TODO: Set up error styles.
    const wrapperClass = touched && error ? " error-text error-border" : "";
    const errorClass = touched && error ? "error-border" : null;
    const focusClass = active ? " field-focus" : "";

    // redux-form initializes with a blank string. For no date, the date picker wants a null value.
    // The date/time picker will not accept a blank string. This adjusts value for us as necessary.
    let value = null;
    if (input.value !== "") {
      value = input.value;
    }
    const hasValue = !!value;

    let calendar;
    calendar =
      this.props.placeholder.indexOf("Time") > -1 ? "time" : "calendar";
    return (
      <div>
        <div
          className={`input-wrapper--form-control-wrapper-component focus-fix${wrapperClass}`}
          tabIndex="-1"
          onClick={() => (this.picker ? this.picker.focus() : null)}
        >
          <div className={`field-focus-wrapper${focusClass}`} />

          <label
            htmlFor={input.name}
            className={
              "form-label-not-empty" +
              (touched && error ? " error-text" : "")
            }
          >
            {placeholder} {(dirty || touched) && error}
          </label>

          <TimeInput
            {...input}
            includeTime={true}
            format={dateFormat}
            value={value}
            name={input.name}
            use12HourClock={true}
            onToggle={() => {}}
            open={active ? calendar : false}
            step={step}
            // BUG: The default onBlur breaks compatibility with redux-form. We tried
            // `onBlur={() => input.onBlur(value)}` but then it wouldn't parse manual input.
            // `onBlur={null}` seems to do the trick.
            // https://github.com/jquense/react-widgets/issues/254#issuecomment-158667963
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            ref={picker => (this.picker = picker)}
            className={errorClass}
            parse={parseInput}
            placeholder={`${placeholder} ${required ? "*" : ""}`}
            {...rest}
          />
        </div>
        <div>
          <span className="text-danger">{warning ? warning : null}</span>
        </div>
      </div>
    );
  };
}
