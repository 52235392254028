import React from "react";
import { OverlayModal } from "../../";

const ImageOverlay = ({ fullScreen, image, modalActions }) => {
  return (
    <OverlayModal onDismiss={modalActions.pop}>
      <img
        alt="from the appointment"
        className="overlay-pet-image"
        src={image.image_url}
      />
    </OverlayModal>
  );
};

export default ImageOverlay;
