import React, { Component } from "react";
import DropdownList from "react-widgets/DropdownList";

export default class EditAutoRenewForm extends Component {
  setAutoRenew = val => {
    this.props.setAutoRenew(this.props.type, val.value);
  };

  saveAutoRenew = e => {
    e.stopPropagation();
    this.props.saveAutoRenew(this.props.auto_renew);
    this.props.setEditing();
  };

  disableEditing = e => {
    e.stopPropagation();
    this.props.setEditing(null);
  };
  render() {
    return (
      <div className="col-sm-12">
        <div className="margin-container">
          <div className="form-component-wrapper">
            <label>Number of Visits to Auto Renew</label>
            <DropdownList
              placeholder="Visits"
              data={[
                { text: "Disable", value: 0 },
                { text: "5 Visits", value: 5 },
                { text: "15 Visits", value: 15 }
              ]}
              valueField={"value"}
              textField={"text"}
              onChange={val => {
                this.setAutoRenew(val);
              }}
              defaultValue={this.props.auto_renew}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <button
              className="btn btn-success btn-primary"
              onClick={e => {
                this.saveAutoRenew(e);
              }}
            >
              Submit
            </button>
          </div>
          <div className="col-lg-6">
            <button
              className="btn btn-danger"
              onClick={e => {
                this.disableEditing(e);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}
