import React, { Component } from "react";

// Each {option} needs to have an `id` and `name` property.
export default class RadioGroupField extends Component {

  static defaultProps = {
    disabled: false
  };

  render = () => {
    const { disabled, input, name, options } = this.props;
    // const { touched, error, warning } = this.props.meta;

    // TODO: Set up error class for checkbox inputs
    // const errorClass = touched && error ? ' error-text error-border' : '';

    return (
      <div>
        {options.map((option, index) => (
          <div className="checkbox" key={index}>
            <label>
              <input
                {...input}
                name={name}
                type="radio"
                value={option.value}
                disabled={disabled}
                checked={input.value === option.value}
                id={`${name}-${option.value}`}
              />
              {option.name}
            </label>
          </div>
        ))}
      </div>
    );
  };
}
