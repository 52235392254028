export default function validate(values) {
  const errors = {};
  //
  // // Personal Information
  if (!values.firstName) errors.firstName = true;
  if (!values.market_id) errors.market_id = true;
  if (!values.lastName) errors.lastName = true;
  // if (!values.signUpDate) errors.signUpDate = true;
  // if (!values.address) errors.address = true;
  // if (!values.city) errors.city = true;
  // if (!values.state) errors.state = true;
  // if (!values.zip) errors.zip = true;
  //
  // // House Information
  // if (!values.homeclass || values.homeclass === -1) errors.homeclass = true;
  // if ((values.homeclass === 2 || values.homeclass === 3) && !values.housenotes) errors.housenotes = true;
  //
  // // Contact
  if (!values.email) {
    errors.email = true;
  }
  //
  // if (!values.password) errors.password = true;
  //
  // if (!values.mobile) errors.mobile = true;
  //
  // // TODO: For the below, only show errors if fields are visited
  // // Secondary Contact
  // if (values.secondaryfirstname || values.secondarylastname || values.secondaryphone || values.secondaryemail) {
  //   if (!values.secondaryfirstname) errors.secondaryfirstname = true;
  //   if (!values.secondarylastname) errors.secondarylastname = true;
  //   if (!values.secondaryphone) errors.secondaryphone = true;
  //   if (!values.secondaryemail) {
  //     errors.secondaryemail = true;
  //   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.secondaryemail)) {
  //     errors.secondaryemail = true;
  //   }
  // }
  //
  // // Emergency Contact
  // if (values.emergencycontactfirstname || values.emergencycontactlastname || values.emergencycontactphone) {
  //   if (!values.emergencycontactfirstname) errors.emergencycontactfirstname = true;
  //   if (!values.emergencycontactlastname) errors.emergencycontactlastname = true;
  //   if (!values.emergencycontactphone) errors.emergencycontactphone = true;
  // }

  return errors;
}
