import React, { Component } from "react";
import axios from "../../services/network.js";
import { connect } from "react-redux";
import history from "../../actions/history";
import moment from "moment";
import { ApartmentForm, CardComponent } from "../../components";

class AppVersions extends Component {
  state = {
    team_member: {
      ios: "",
      android: ""
    },
    client: {
      ios: "",
      android: ""
    }
  };
  async componentDidMount() {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URI}/admin/appVersions`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
        }
      }
    );

    this.setState({
      team_member: {
        ios: data.team_member.ios,
        android: data.team_member.android
      },
      client: {
        ios: data.client.ios,
        android: data.client.android
      }
    });
  }

  render() {
    return (
      <CardComponent title="Current App Versions">
        <div className="row">
          <h3>Team Member (Petsby)</h3>
          <h5>iOS: {this.state.team_member.ios}</h5>
          <h5>Android: {this.state.team_member.android}</h5>
        </div>
        <div className="row">
          <h3>Client</h3>
          <h5>iOS: {this.state.client.ios}</h5>
          <h5>Android: {this.state.client.android}</h5>
        </div>
      </CardComponent>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showSpinner: () => dispatch({ type: "FETCH_REQUEST" }),
  stopSpinner: () => dispatch({ type: "FETCH_SUCCESS" }),
  showMessage: msg => dispatch({ type: "ADD_MESSAGE", payload: msg }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" })
});

const mapStateToProps = state => ({
  market: state.admin.general.location
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppVersions);
