import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "../../services/network.js";

import {
  CardComponent,
  PartnerForm,
  PartnerPromoTable
} from "../../components";

import history from "../../actions/history";

class EditPartner extends Component {
  state = {
    partner: null
  };
  componentWillMount = () => {
    setTimeout(this.props.removeMessage, 3000);
    this.getPartner();
  };

  getPartner = async () => {
    const { partnerId } = this.props.match.params;
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URI}/admin/partner/${partnerId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      }
    );
    this.setState({ partner: data });
  };

  savePartner = async (partner, valid) => {
    const { showSpinner, stopSpinner, showMessage, removeMessage } = this.props;
    showSpinner();
    if (valid) {
      let body = { ...partner };
      body._id = this.state.partner._id;
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URI}/admin/partner/${this.state.partner._id}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );
      showMessage({
        message: "Successfully updated partner",
        messageType: "success"
      });
      this.getPartner();
    } else {
      showMessage({
        message: "Partner name must be defined, and a market must be selected",
        messageType: "error"
      });
    }
    setTimeout(removeMessage, 3000);
    stopSpinner();
  };

  render() {
    return (
      <div>
        <CardComponent title="Edit Partner">
          <PartnerForm
            partner={this.state.partner}
            savePartner={this.savePartner}
          />
        </CardComponent>
        <CardComponent title="Promos">
          <PartnerPromoTable
            promos={this.state.partner ? this.state.partner.promos : null}
            referrals={this.state.partner ? this.state.partner.referrals : null}
            partner={this.state.partner}
          />
        </CardComponent>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  market: state.admin.general.location
});

const mapDispatchToProps = dispatch => ({
  showSpinner: () => dispatch({ type: "FETCH_REQUEST" }),
  stopSpinner: () => dispatch({ type: "FETCH_SUCCESS" }),
  showMessage: msg => dispatch({ type: "ADD_MESSAGE", payload: msg }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPartner);
