import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import moment from "moment";
import axios from "../../../../services/network.js";
import queryString from "query-string";
import {
  calculateAppointmentPrice,
  formatNumsToDollars,
  parseTimeInput
} from "../../../../helpers";
import {
  AddonsField,
  AppointmentImageField,
  AppointmentPetField,
  AppointmentPriceOverrideField,
  CheckboxField,
  CheckboxGroupField,
  ComboboxField,
  DateField,
  TimeField,
  DropdownListField,
  FieldColumnWrapper,
  FormSection,
  FormSubHeader,
  MultiselectField,
  TextAreaField,
  PetService,
  WeekdaysField
} from "../../../shared/formComponents";
import { AppointmentPriceBanner } from "../../../";

const weekdays = [
  {
    id: "Sunday",
    name: "SUN"
  },
  {
    id: "Monday",
    name: "MON"
  },
  {
    id: "Tuesday",
    name: "TUE"
  },
  {
    id: "Wednesday",
    name: "WED"
  },
  {
    id: "Thursday",
    name: "THU"
  },
  {
    id: "Friday",
    name: "FRI"
  },
  {
    id: "Saturday",
    name: "SAT"
  }
];

class EditAppointmentForm extends Component {
  state = {
    staff_list: []
  };

  componentWillReceiveProps = props => {
    if (props.staffList) {
      const staff = [];
      staff.push({ full_name: "None", _id: null });
      staff.push(...props.staffList);

      this.setState({ staff_list: staff });
    }
  };

  getServices = () => {
    const { formConstants, clientInfo } = this.props;
    let types = [...formConstants.appointmentTypes];
    if (!this.props.appointmentType || !this.props.appointmentType.id)
      return [];
    if (this.props.appointmentType.id === 11) {
      return types.filter(t => t.id === 11);
    } else {
      types = types.filter(t => t.id !== 11);
    }

    if (
      !clientInfo ||
      !clientInfo.apartment ||
      !clientInfo.apartment.playcare ||
      !clientInfo.apartment.playcare.enabled
    ) {
      types = types
        .filter(t => t && t.id !== 11)
        .map(svc => ({
          id: svc.id,
          duration_in_minutes: svc.duration_in_minutes,
          name: svc.name
        }));
    }
    if (
      this.props.appointmentType.id !== 12 &&
      this.props.appointmentType.id !== 8
    ) {
      types = types
        .filter(t => t && t.id !== 12 && t.id !== 8)
        .map(svc => ({
          id: svc.id,
          duration_in_minutes: svc.duration_in_minutes,
          name: svc.name
        }));
    }
    types = types
      .filter(svc => svc && svc.id)
      .map(svc => ({
        id: svc.id,
        duration_in_minutes: svc.duration_in_minutes,
        name: svc.name
      }));

    return types;
  };

  getAddons = () => {
    const { formConstants } = this.props;
    return formConstants.addOnServicesGeneral
      .filter(svc => svc && svc.id)
      .map(svc => ({
        id: svc.id,
        name: `${svc.name}${
          svc.price ? ` (Adds ${formatNumsToDollars(svc.price)})` : ""
        }`
      }));
  };

  renderApproveButton = () => {
    const { appointment, dirty, pushActionModal } = this.props;
    const appointmentStatus = appointment.appointment_status.name;
    const statusesForRender = ["Pending", "Change Requested", "Rejected"];

    if (statusesForRender.includes(appointmentStatus) && !dirty) {
      return (
        <div className="col-sm-3 text-right button-column pull-right">
          <button
            className="btn btn-block btn-primary"
            onClick={e => pushActionModal(e, "approve")}
          >
            Approve Appointment
          </button>
        </div>
      );
    }
  };

  renderCancelButton = () => {
    const { appointment, pushActionModal } = this.props;
    const appointmentStatus = appointment.appointment_status.name;
    const statusesForRender = [
      "Pending",
      "Approved",
      "Change Requested",
      "In Progress"
    ];

    if (statusesForRender.includes(appointmentStatus)) {
      return (
        <div className="col-sm-3 text-right button-column pull-right">
          <button
            className="btn btn-block btn-primary"
            onClick={e => pushActionModal(e, "cancel")}
          >
            Cancel Appointment
          </button>
        </div>
      );
    }
  };

  renderDiscardButton = () => {
    const { discardChanges } = this.props;

    return (
      <div className="col-sm-3 text-right button-column pull-right">
        <button
          className="btn btn-block btn-primary btn-discard-changes"
          onClick={discardChanges}
        >
          Discard Changes
        </button>
      </div>
    );
  };

  renderRejectButton = () => {
    const { appointment, pushActionModal } = this.props;
    const appointmentStatus = appointment.appointment_status.name;
    const statusesForRender = ["Pending", "Approved", "Change Requested"];

    if (statusesForRender.includes(appointmentStatus)) {
      return (
        <div className="col-sm-3 text-right button-column pull-right">
          <button
            className="btn btn-block btn-primary"
            onClick={e => pushActionModal(e, "reject")}
          >
            Reject Appointment
          </button>
        </div>
      );
    }
  };

  render() {
    const {
      addonIds,
      appointment,
      appointmentType,
      dirty,
      editRecurringSeries,
      formConstants,
      freezePrice,
      handleSubmit,
      modalActions,
      priceOverridden,
      price,
      staffList
    } = this.props;

    const appointmentStatus = appointment.appointment_status.name;
    return (
      <form onSubmit={handleSubmit}>
        {appointmentStatus === "Rejected" ? (
          <FormSection title="Rejection Reason">
            <div className="row">
              <FieldColumnWrapper column="12">
                <Field
                  component={TextAreaField}
                  name="rejection_reason"
                  placeholder="Reason appointment was rejected."
                />
              </FieldColumnWrapper>
            </div>
          </FormSection>
        ) : null}

        <FormSection title="Appointment Details">
          <div className="row admin-apptdetails">
            {editRecurringSeries ? null : (
              <FieldColumnWrapper column="4">
                <Field
                  component={DropdownListField}
                  name="appointment_status"
                  busy={false} // DropdownList: Display spinner
                  data={formConstants.appointmentStatuses} // DropdownList: Array of strings or objects or other primitives to populate
                  textField="name" // DropdownList: If data is obj, determines which property gets displayed
                  // filter="contains" // DropdownList: If you want to filter, include this with string indicating filter type
                  parse={(val, name) => val} // reduxForm: parse decides what goes into the state in the form store
                  placeholder="Appointment Status"
                />
              </FieldColumnWrapper>
            )}
            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                name="appointment_type"
                busy={false} // DropdownList: Display spinner
                data={this.getServices()} // DropdownList: Array of strings or objects or other primitives to populate
                textField="name" // DropdownList: If data is obj, determines which property gets displayed
                // filter="contains" // DropdownList: If you want to filter, include this with string indicating filter type
                parse={(val, name) => val} // reduxForm: parse decides what goes into the state in the form store
                placeholder="Appointment Type"
                disabled={
                  !this.props.appointmentType ||
                  this.props.appointmentType.id === 12 ||
                  this.props.appointmentType.id === 8
                }
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="8">
              <Field
                component={MultiselectField}
                options={this.getAddons()}
                name="add_on_services"
                data={this.getAddons()} // Multiselect: Array of strings or objects or other primitives to populate
                textField="name" // Multiselect: If data is obj, determines which property gets displayed
                parse={val => val} // reduxForm: parse decides what goes into the state in the form store
                placeholder="Service Offerings"
              />
            </FieldColumnWrapper>
          </div>
        </FormSection>

        <FormSection title="Pets In This Appointment">
          <Field
            component={AppointmentPetField}
            name="pet_ids"
            isAdmin
            modalActions={modalActions}
            clientPets={appointment.all_client_pets}
          />
        </FormSection>
        <FormSection title="Pet Add-ons">
          <Field
            component={PetService}
            name="pet_addons"
            pets={this.props.appointment.appointment_pet_ids}
            clientPets={this.props.appointment.all_client_pets}
            pet_addons={this.props.pet_addons}
            modalActions={modalActions}
            options={formConstants.addOnServicesPet.filter(
              a => a.id !== 1 && a.id !== 2
            )}
          />
        </FormSection>

        <FormSection title="Appointment Schedule">
          <div className="row">
            <div className="col-sm-12">
              <FormSubHeader>Requested</FormSubHeader>
            </div>

            {editRecurringSeries ? (
              <div>
                <FieldColumnWrapper offset="1" column="10">
                  <Field
                    component={WeekdaysField}
                    required
                    name="recurrence_weekdays"
                    options={weekdays}
                    onChange={this.props.seriesIntervalChanged}
                    appointmentTypeId={
                      appointmentType ? appointmentType.id : null
                    }
                    clientInfo={this.props.clientInfo}
                  />
                </FieldColumnWrapper>
                <FieldColumnWrapper column="4">
                  <Field
                    component={DateField}
                    name="recurrence_end_date"
                    disableManualInput
                    time={false}
                    dateFormat="dddd, MMM. D, Y"
                    placeholder="Recurrence End Date"
                    onChange={this.props.seriesIntervalChanged}
                  />
                </FieldColumnWrapper>
              </div>
            ) : (
              <FieldColumnWrapper column="4">
                <Field
                  component={DateField}
                  name="requested_date"
                  disableManualInput
                  time={false}
                  dateFormat="dddd, MMM. D, Y"
                  placeholder="Requested Date"
                  onChange={this.props.seriesIntervalChanged}
                />
              </FieldColumnWrapper>
            )}
            {appointmentType && appointmentType.id === 11 ? null : (
              <FieldColumnWrapper column="4">
                <Field
                  component={TimeField}
                  name="window_start_time"
                  calendar={false}
                  placeholder="Window Start Time"
                  dateFormat="h:mm a"
                  parseInput={parseTimeInput}
                  step={1}
                />
              </FieldColumnWrapper>
            )}
            {appointmentType && appointmentType.id === 11 ? null : (
              <FieldColumnWrapper column="4">
                <Field
                  component={TimeField}
                  name="window_end_time"
                  calendar={false}
                  placeholder="Window End Time"
                  dateFormat="h:mm a"
                  parseInput={parseTimeInput}
                  step={1}
                />
              </FieldColumnWrapper>
            )}
            <FieldColumnWrapper column="4">
              <Field
                component={CheckboxField}
                name="exclude_from_scheduler"
                label="Exclude From Auto-Schedule"
                type="checkbox"
              />
            </FieldColumnWrapper>
          </div>

          {appointmentStatus !== "Rejected" &&
          appointmentStatus !== "Canceled" ? (
            <div className="row">
              <div className="col-sm-12">
                <FormSubHeader>Scheduled</FormSubHeader>
              </div>

              <FieldColumnWrapper column="4">
                <Field
                  component={TimeField}
                  name="scheduled_time"
                  calendar={false}
                  dateFormat="h:mm a"
                  parseInput={parseTimeInput}
                  placeholder="Scheduled Time"
                  step={1}
                  disabled={
                    !editRecurringSeries ||
                    (editRecurringSeries &&
                      this.props.update_all_scheduled_times)
                      ? false
                      : true
                  }
                />
              </FieldColumnWrapper>
              {editRecurringSeries ? (
                <FieldColumnWrapper column="8">
                  <Field
                    component={CheckboxField}
                    name="update_all_scheduled_times"
                    type="checkbox"
                    label={`Update Scheduled Times For Series`}
                  />
                </FieldColumnWrapper>
              ) : null}

              <FieldColumnWrapper column="4">
                <Field
                  component={ComboboxField}
                  name="sitter"
                  busy={staffList === null} // Combobox: Display spinner
                  data={this.state.staff_list || []} // Combobox: Array of strings or objects or other primitives to populate
                  valueField="_id" // Combobox: If data is obj, determines which property is the id
                  textField="full_name" // Combobox: If data is obj, determines which property gets displayed
                  filter="contains" // Combobox: If you want to filter, include this with string indicating filter type
                  suggest // Combobox: Should it fill in values for you?
                  parse={(val, name) => val} // reduxForm: parse decides what goes into the state in the form store
                  placeholder="Team Member"
                  disabled={
                    !editRecurringSeries ||
                    (editRecurringSeries && this.props.update_all_sitters)
                      ? false
                      : true
                  }
                />
              </FieldColumnWrapper>
              {editRecurringSeries &&
              appointmentType &&
              appointmentType.id !== 11 ? (
                <FieldColumnWrapper column="8">
                  <Field
                    component={CheckboxField}
                    name="update_all_sitters"
                    type="checkbox"
                    label={`Update Team Member For Series`}
                  />
                </FieldColumnWrapper>
              ) : null}
            </div>
          ) : null}
        </FormSection>

        {appointmentStatus !== "Rejected" &&
        appointmentStatus !== "Canceled" ? (
          <span>
            <FormSection title="Completion Information">
              <div className="row">
                {!editRecurringSeries ? (
                  <FieldColumnWrapper column="4">
                    <Field
                      component={TimeField}
                      name="actual_start_time"
                      calendar={false}
                      dateFormat="h:mm a"
                      parseInput={parseTimeInput}
                      placeholder="Time Started"
                      step={1}
                    />
                  </FieldColumnWrapper>
                ) : null}

                {!editRecurringSeries ? (
                  <FieldColumnWrapper column="4">
                    <Field
                      component={TimeField}
                      name="actual_end_time"
                      calendar={false}
                      dateFormat="h:mm a"
                      parseInput={parseTimeInput}
                      placeholder="Time Completed"
                      step={1}
                    />
                  </FieldColumnWrapper>
                ) : null}
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <FormSubHeader>Team Member Notes for Admin</FormSubHeader>
                  <Field
                    component={TextAreaField}
                    name="sitter_notes_to_admin"
                    placeholder="Team Member Notes for Admin"
                    disabled={false}
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <FormSubHeader>Team Member Notes for Client</FormSubHeader>
                  <Field
                    component={TextAreaField}
                    name="sitter_notes_to_client"
                    placeholder="Team Member Notes for Client"
                    disabled={false}
                  />
                </div>
                <div className="col-sm-12 col-md-12">
                  <FormSubHeader>Admin Notes for Team Member</FormSubHeader>
                  <Field
                    component={TextAreaField}
                    name="admin_notes_to_sitter"
                    placeholder="Admin Notes to Team Member"
                    disabled={this.props.freezePrice ? true : false}
                  />
                </div>
                <div className="col-sm-12 col-md-12">
                  <FormSubHeader>Client Notes to Team</FormSubHeader>
                  <Field
                    component={TextAreaField}
                    name="client_notes"
                    placeholder="Client Notes to Team"
                    disabled={this.props.freezePrice ? true : false}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <FormSubHeader>Appointment Images</FormSubHeader>
                  <Field
                    component={AppointmentImageField}
                    appointmentId={appointment._id}
                    images={appointment.appointment_images}
                    name="images_for_deletion"
                  />
                </div>
              </div>
            </FormSection>
          </span>
        ) : null}

        {appointmentStatus !== "Rejected" &&
        appointmentStatus !== "Canceled" ? (
          <span>
            {freezePrice ? null : (
              <FormSection title="Price Adjustments">
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <div className="price-override">
                      <div className="override-checkbox">
                        <Field
                          component={CheckboxField}
                          name="price_overridden"
                          type="checkbox"
                          label={`Manually override price?`}
                        />
                        {priceOverridden
                          ? `(Original Price: $${calculateAppointmentPrice(
                              formConstants,
                              appointmentType.id,
                              addonIds
                            )})`
                          : null}
                      </div>
                      <div className="override-price">
                        {priceOverridden ? (
                          <AppointmentPriceOverrideField />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </FormSection>
            )}
          </span>
        ) : null}

        <FormSection>
          <div className="row">
            <div className="col-sm-3 text-right button-column pull-right">
              <input
                type="submit"
                className="btn btn-block btn-primary"
                value="Save"
              />
            </div>

            {this.renderCancelButton()}
            {this.renderRejectButton()}
            {this.renderDiscardButton()}
          </div>
        </FormSection>
      </form>
    );
  }
}

const warn = (values, props) => {
  const warnings = {};
  if (props) {
    const check = props.vacation_days.find(d =>
      moment(d.date, "MM/DD/YYYY").isSame(moment(values.requested_date), "day")
    );
    if (!!check) {
      warnings.requested_date = "The date selected is a vacation date";
    }
  }
  return warnings;
};

const validate = (values, props) => {
  const errors = {};
  // if (!values.appointment_type_id) errors.appointment_type_id = true;
  // if (!values.pet_ids || !values.pet_ids.length) errors.pet_ids = true;
  if (!values.requested_date) errors.requested_date = true;
  if (values.appointmentType && values.appointmentType.id !== 11) {
    if (!values.window_start_time) errors.window_start_time = true;
    if (!values.window_end_time) errors.window_end_time = true;
  }

  if (values.scheduled_time) {
    if (!values.scheduled_time) errors.scheduled_time = true;
  }

  if (values.completed_date || values.actual_start_time) {
    if (!values.completed_date) errors.completed_date = true;
    if (!values.actual_start_time) errors.actual_start_time = true;
  }

  if (values.actual_end_time) {
    if (!values.completed_date) errors.completed_date = true;
    if (!values.actual_start_time) errors.actual_start_time = true;
  }

  if (values.appointment_type && values.appointment_type.id === 11) {
    if (values.pet_ids) {
      const { all_client_pets } = props.appointment;
      const valid = values.pet_ids.map(id => {
        if (all_client_pets) {
          const pet = all_client_pets.find(x => x._id === id);
          if (pet && pet.animal_type === "Dog") {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
      if (valid.includes(false)) {
        errors.pet_ids = true;
        errors.customMessageText = "You may only add dogs to playcare visits";
      }
    }
  }
  if (values.appointment_status && values.appointment_status.id === 5) {
    if (!values.actual_end_time) errors.actual_end_time = true;
  }
  return errors;
};

EditAppointmentForm = reduxForm({
  form: "adminEditAppointment",
  validate,
  warn
})(EditAppointmentForm);

// connect using react-redux and reduxForm's formValueSelector to get form values
const selector = formValueSelector("adminEditAppointment");
const mapStateToProps = state => ({
  addonIds: selector(state, "addon_ids"),
  appointmentType: selector(state, "appointment_type"),
  priceOverridden: selector(state, "price_overridden"),
  price: selector(state, "price"),
  pet_addons: selector(state, "pet_addons"),
  update_all_scheduled_times: selector(state, "update_all_scheduled_times"),
  update_all_sitters: selector(state, "update_all_sitters")
  //initialValues: state.form.adminEditAppointment.initial
});

export default connect(mapStateToProps)(EditAppointmentForm);
