import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class VetRowItem extends Component {
  renderAddress = () => {
    const { vet } = this.props;
    let address = vet.address_1;
    if (vet.address_2) {
      address += " " + vet.address_2;
    }
    address += ", " + vet.city + " " + vet.state + ", " + vet.zip;
    return address.replace(/undefined/g, "");
  };

  render() {
    const { vet, market } = this.props;
    return (
      <Link to={`/vet/${vet._id}`} className="vetLink">
        <div>{vet.name}</div>
        <div>{this.renderAddress()}</div>
        <div>{market.city}</div>
      </Link>
    );
  }
}
