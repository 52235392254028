import React, { Component } from "react";
import axios from "../../services/network.js";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { CardComponent, ApartmentRowItem } from "../../components";

class Apartments extends Component {
  state = {
    apartments: [],
    playcare: null,
    search: null,
    concierge: null
  };

  componentWillMount = () => {
    this.fetchData(this.props.market_id);
  };

  componentWillReceiveProps = nextProps => {
    if (this.props.market_id && nextProps.market_id !== this.props.market_id) {
      this.fetchData(nextProps.market_id);
    }
  };

  fetchData = async market_id => {
    if (market_id) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URI}/admin/apartments?market=${market_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );
      this.setState({ apartments: data });
    }
  };

  setPlaycare = status => {
    this.setState({ playcare: status });
  };

  setConcierge = status => {
    this.setState({ concierge: status });
  };

  setSearch = e => {
    const val = e.target.value;
    if (val.length > 0) {
      this.setState({ search: val });
    } else {
      this.setState({ search: null });
    }
  };

  render() {
    return (
      <CardComponent title="Apartments">
        <div className="actions-bar">
          <div className="row">
            <div className="col-sm-12">
              <Link
                to={{
                  pathname: "/addapartment",
                  state: {
                    market_id: this.props.market_id
                  }
                }}
                className="btn btn-primary"
              >
                <span className="fa fa-plus" /> Add New Apartment
              </Link>
            </div>
          </div>
        </div>
        <div className="apartment-wrapper">
          <div className="apartment-filters">
            <div className="row">
              <div className=" col-md-8 col-md-offset-2">
                <div className="btn-group btn-group-justified" role="group">
                  <a
                    className={
                      this.state.playcare ? "btn btn-primary" : "btn btn-white"
                    }
                    onClick={() => this.setPlaycare(true)}
                  >
                    Playcare Apartments
                  </a>
                  <a
                    className={
                      this.state.playcare === null
                        ? "btn btn-primary"
                        : "btn btn-white"
                    }
                    onClick={() => this.setPlaycare(null)}
                  >
                    All
                  </a>
                  <a
                    className={
                      this.state.playcare !== null && !this.state.playcare
                        ? "btn btn-primary"
                        : "btn btn-white"
                    }
                    onClick={() => this.setPlaycare(false)}
                  >
                    No Playcare
                  </a>
                </div>
                <div className="btn-group btn-group-justified" role="group">
                  <a
                    className={
                      this.state.concierge ? "btn btn-primary" : "btn btn-white"
                    }
                    onClick={() => this.setConcierge(true)}
                  >
                    Concierge Apartments
                  </a>
                  <a
                    className={
                      this.state.concierge === null
                        ? "btn btn-primary"
                        : "btn btn-white"
                    }
                    onClick={() => this.setConcierge(null)}
                  >
                    All
                  </a>
                  <a
                    className={
                      this.state.concierge !== null && !this.state.concierge
                        ? "btn btn-primary"
                        : "btn btn-white"
                    }
                    onClick={() => this.setConcierge(false)}
                  >
                    No Concierge
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-sm-offset-3 search-filter">
                <input
                  type="text"
                  onChange={e => {
                    this.setSearch(e);
                  }}
                  placeholder="Search Apartments by Name"
                />
              </div>
            </div>
          </div>
          <div className="apartment-grid">
            {this.state.apartments
              .filter(apt => {
                if (
                  this.state.playcare !== null ||
                  this.state.concierge !== null
                ) {
                  if (this.state.concierge === null) {
                    if (apt.playcare) {
                      return this.state.playcare === apt.playcare.enabled;
                    }
                  } else if (this.state.playcare === null) {
                    return apt.concierge_enabled === this.state.concierge;
                  } else {
                    if (apt.playcare) {
                      return (
                        apt.concierge_enabled === this.state.concierge &&
                        this.state.playcare === apt.playcare.enabled
                      );
                    }
                  }
                }
                return apt;
              })
              .filter(apt => {
                if (this.state.search) {
                  return apt.name
                    .toLowerCase()
                    .includes(this.state.search.toLowerCase());
                }
                return apt;
              })
              .map(apt => {
                return (
                  <ApartmentRowItem
                    key={apt.apartment_id}
                    id={apt.apartment_id}
                    name={apt.name}
                    playcare={apt.playcare}
                    concierge={apt.concierge}
                    market_id={this.props.market_id}
                    concierge_enabled={apt.concierge_enabled}
                  />
                );
              })}
          </div>
        </div>
      </CardComponent>
    );
  }
}

const mapStateToProps = state => ({
  market_id: state.admin.general.location._id
});

export default connect(
  mapStateToProps,
  null
)(Apartments);
