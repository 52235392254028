import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { DateSwitcher, SchedulerRow, SitterDropdown } from "../../components";
import PetMarker from "../../components/admin/PetMarker";
import SitterAppointmentListItem from "../../components/admin/SitterAppointmentListItem";
import AppointmentListItem from "../../components/admin/AppointmentListItem";
import RouteModal from "../../components/admin/RouteModal";
import ResultsModal from "../../components/admin/ResultsModal";
import ErrorModal from "../../components/admin/ErrorModal";
import SitterPreview from "../../components/admin/SitterPreview";
import GoogleMapReact from "google-map-react";
import { adminAppointmentService } from "../../services";
import { Multiselect } from "react-widgets";
import { catchFormServerErrors } from "../../helpers";
import queryString from "query-string";
import axios from "../../services/network.js";
import {
  saveMapAppointment,
  approveAppointment,
  getAppointments as getAppointmentsAction,
  scheduleAppointment as scheduleAppointmentAction,
  showAppointmentPreview,
  updateAppointment as updateAppointmentAction,
  highlightSitter,
  highlightAppointmentOnMap
} from "../../actions/admin/appointments.actions";

import { getAllStaffWithAppointments } from "../../actions/admin/staff.actions";
import {
  pushModal,
  popModal,
  showFormErrorMessage,
  showSuccessMessage
} from "../../actions/general.actions";
import {
  setMapAppointment as setAppointmentTime,
  setSchedulerFilters,
  setSchedulerSelectedDate
} from "../../actions/admin/scheduler.actions";
import {
  applyMultiFilters,
  getFilterOptions
} from "../../helpers/schedulerFilter";

import MapAppointmentDetail from "../../components/admin/MapAppointmentDetail";
import { CardComponent, ScheduleOverlay } from "../../components";

import moment from "moment";
import { setPusherClient } from "react-pusher";
import PusherConfig from "pusher-js";
import Pusher from "react-pusher";

const pusherClient = new PusherConfig(process.env.REACT_APP_PUSHER_KEY, {
  encrypted: true,
  cluster: "us2"
});

setPusherClient(pusherClient);

class MapComponent extends Component {
  constructor() {
    super();
    this.approveAppt = this.approveAppt.bind(this);
  }

  static defaultProps = {
    zoom: 11
  };

  state = {
    appointments: [],
    apartments: [],
    sitters: [],
    ghost_shifts: [],
    showOnlyWorkingSitters: true,
    showErrors: false,
    errors: null,
    results: null,
    scheduling: false,
    results: false,
    resultsData: null,
    previewAppt: null,
    traffic: true,
    filters: {
      pending: true,
      approved: false,
      assigned: true
    },
    showSitterPreview: false,
    sitterPreview: null,
    sendNotifications: true,
    draft: false,
    colors: [
      "#d92121",
      "#b26750",
      "#ff7700",
      "#8c6200",
      "#d9c8a3",
      "#ffbe26",
      "#fff899",
      "#5d662e",
      "#dbff26",
      "#52b31b",
      "#99ffbb",
      "#006652",
      "#62d5d9",
      "#0083b3",
      "#0059b3",
      "#bfdfff",
      "#8687b3",
      "#ffccff",
      "#0008ff",
      "#801bb3",
      "#bc82d9",
      "#664d5d",
      "#ff00a2",
      "#ff9999",
      "#d9a3ac"
    ]
  };

  componentDidMount() {
    if (this.props.city._id === 1) return;
    this.fetchAppointments();
    this.getStaff();
  }

  async componentWillReceiveProps(props) {
    if (
      this.props.city._id !== 1 &&
      this.props.city._id !== props.city._id &&
      props.city._id
    ) {
      const { getAppointments, selectedDate, city, getStaff } = props;
      let payload = {
        startDate: moment(selectedDate)
          .startOf("day")
          .toDate(),
        endDate: moment(selectedDate)
          .endOf("day")
          .toDate(),
        city: city._id
      };

      getAppointments(payload);
      getStaff({
        startDate: moment(selectedDate)
          .startOf("day")
          .toDate(),
        endDate: moment(selectedDate)
          .endOf("day")
          .toDate(),
        city: city._id
      });
    }
  }

  showPreview = app => {
    this.setState({
      scheduling: false,
      results: false,
      previewAppt: app ? app : null
    });
  };

  turnItAllOff = () => {
    this.setState({
      scheduling: false,
      results: false,
      showSitterPreview: false,
      sitterPreview: false,
      previewAppt: null
    });
  };

  getStaff = () => {
    const { getStaff, selectedDate, city } = this.props;
    return getStaff({
      startDate: moment(selectedDate)
        .startOf("day")
        .toDate(),
      endDate: moment(selectedDate)
        .endOf("day")
        .toDate(),
      city: city._id
    });
  };

  onDateBack = () => {
    const { setSelectedDate, selectedDate, city } = this.props;
    this.setState({ draft: false }, () =>
      setSelectedDate(
        moment(selectedDate)
          .subtract(1, "day")
          .toDate(),
        city._id
      )
    );
  };

  onDateForward = () => {
    const { setSelectedDate, selectedDate, city } = this.props;
    this.setState({ draft: false }, () =>
      setSelectedDate(
        moment(selectedDate)
          .add(1, "day")
          .toDate(),
        city._id
      )
    );
  };

  selectDate = date => {
    const { setSelectedDate, selectedDate, city } = this.props;
    this.setState({ draft: false }, () =>
      setSelectedDate(moment(date).toDate(), city._id)
    );
  };

  setAppointmentTime = (app, time, sitter) => {
    app.scheduled_start_time = time.time.toString();
    app.sitter = {
      _id: sitter._id,
      full_name: sitter.name || sitter.contact.full_name
    };
    app.sitter_id = sitter._id;
    return this.props.setAppointment(app);
  };

  fetchAppointments = draft => {
    const { getAppointments, selectedDate, city } = this.props;
    let data = {
      startDate: moment(selectedDate)
        .startOf("day")
        .toDate(),
      endDate: moment(selectedDate)
        .endOf("day")
        .toDate(),
      city: city._id
    };
    if (this.state.draft || (draft && draft.draft)) data.draft = true;
    return getAppointments(data);
  };

  saveAppointments = async appts => {
    let draft = this.state.draft;
    let market_id = this.props.city._id;
    let model = {
      appointments: appts,
      market_id: market_id
    };
    await this.props.saveMapAppointment(model, draft);
    await this.fetchAppointments();
  };

  async approveAppt(app) {
    await this.props.approveAppointment(app._id, app);
    await this.fetchAppointments();
    await this.getStaff();
  }

  highlightSitter = _id => {
    this.props.highlightSitter(_id);
  };

  highlightAppointmentOnMap = _id => {
    this.props.highlightAppointmentOnMap(_id);
  };

  showResults = data => {
    let { modalActions } = this.props;
    modalActions.push(
      <ScheduleOverlay
        fullScreen={false}
        onDismiss={modalActions.pop}
        textAlign="left"
        schedule={true}
      >
        <ResultsModal
          appointments={this.props.appointments}
          results={data}
          timeZone={this.props.location.time_zone}
        />
      </ScheduleOverlay>
    );
  };

  getMapAnythingResults = async jobId => {
    const {
      getAppointments,
      selectedDate,
      city,
      showSpinner,
      stopSpinner,
      modalActions
    } = this.props;
    const sendNotifications = this.state.sendNotifications;

    // console.log('job id?', jobId)

    try {
      let body = {
        market: city._id,
        jobId: jobId || '94de65c9-12ce-4140-881a-8b3210cdeb0a',
        day: selectedDate,
        sendNotifications: sendNotifications ? true : false
      };
      let results = await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/getMapAnythingResults`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );

      if (results.status === 202) {
        setTimeout(() => {
          this.getMapAnythingResults(jobId);
        }, 5000);
      } else {

    

        if (results.data.unservedError) {
          modalActions.pop();
          modalActions.push(
            <ErrorModal error="Too many unserved visits. No changes have been saved." />
          );
        } else if (results.data.JobMessage) {
          let msg = `${results.data.JobMessage.comment}`;
          modalActions.pop();
          modalActions.push(<ErrorModal error={msg} />);
        } else {
          if (!this.state.draft) {
            this.setState({ draft: true });
          }
          this.fetchAppointments("draft");
          this.getStaff();
          stopSpinner();
          this.props.modalActions.pop();
          // this.showResults(results.data);
        }
      }
    } catch (e) {
      stopSpinner();
      modalActions.pop();
      let msg;
      if (e.data && e.data.JobMessage) {
        msg = `${e.data.JobMessage.comment}`;
      } else {
        msg = JSON.stringify(e);
      }
      modalActions.push(<ErrorModal error={msg} />);
    }
  };

  //this function both gets the routific results, and actually assigns the appointments
  getRouteResults = async jobId => {
    const {
      getAppointments,
      selectedDate,
      city,
      showSpinner,
      stopSpinner,
      modalActions
    } = this.props;
    const sendNotifications = this.state.sendNotifications;
    try {
      let body = {
        market: city._id,
        jobId: jobId,
        day: selectedDate,
        sendNotifications: sendNotifications ? true : false
      };
      let results = await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/getRouteResults`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );

      if (results.status === 202) {
        setTimeout(() => {
          this.getRouteResults(jobId);
        }, 5000);
      } else {
        if (results.data.unservedError) {
          modalActions.push(
            <ErrorModal error="Too many unserved visits. No changes have been saved." />
          );
        } else {
          if (!this.state.draft) {
            this.setState({ draft: true });
          }
          this.fetchAppointments("draft");
          stopSpinner();
          this.props.modalActions.pop();
          this.showResults(results.data);
        }
      }
    } catch (e) {
      stopSpinner();
      modalActions.pop();
      modalActions.push(<ErrorModal error={e} />);
    }
  };

  runMapAnything = async (qs, shifts) => {
    const {
      getAppointments,
      selectedDate,
      city,
      showSpinner,
      stopSpinner,
      modalActions
    } = this.props;
    try {
      showSpinner();
      let queries = {
        startDate: moment(selectedDate)
          .startOf("day")
          .toDate(),
        endDate: moment(selectedDate)
          .endOf("day")
          .toDate(),
        market: city._id,
        minVisits: qs.minVisits,
        maxVisits: qs.maxVisits,
        noteTime: qs.noteTime,
        priority_type: qs.priority_type
      };

      let advanced = false;
      if (qs.advanced) {
        advanced = { ...qs.advanced };
      }

      let qst = queryString.stringify(queries);

      let { data } = await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/runMapAnything?${qst}`,
        { shifts: this.props.ghost_shifts, advanced, last_tms: qs.last_tms },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );

      console.log('data....', data)

      if (data && data.error) {
        console.log("routific error: ", data.error);
        stopSpinner();
        modalActions.pop();
        if (data.error) {
          modalActions.push(
            <ErrorModal
              error={
                data?.error || "Error running solvice routing"
              }
            />
          );
        } 
      } else if (data && data.unserved) {
        stopSpinner();
        modalActions.pop();
        modalActions.push(<ErrorModal unserved={data.unserved} />);
      } else {
        localStorage.setItem("jobId", data.job_id);

        if (data && data.draft_created) {
          this.setState({ draft: true });
        }

        setTimeout(() => {
          this.getMapAnythingResults(data.job.job_id);
        }, 5000);
      }
    } catch (e) {
      console.log("big eeeeeeee", e);
      stopSpinner();
      alert("Error " + e);
    }
  };

  calculateAllRoutes = async (qs, shifts) => {
    const {
      getAppointments,
      selectedDate,
      city,
      showSpinner,
      stopSpinner,
      modalActions
    } = this.props;
    try {
      showSpinner();
      let queries = {
        startDate: moment(selectedDate)
          .startOf("day")
          .toDate(),
        endDate: moment(selectedDate)
          .endOf("day")
          .toDate(),
        market: city._id,
        time: qs.time,
        minVisits: qs.minVisits,
        lateness: qs.lateness,
        traffic: qs.traffic,
        noteTime: qs.noteTime,
        min_speed: qs.min_speed,
        max_vehicle_overtime: qs.max_vehicle_overtime,
        priority_type: qs.priority_type
      };
      if (qs.balance) queries.balance = true;
      if (qs.shortest_distance) queries.shortest_distance = true;
      if (qs.zones) queries.zones = true;
      if (qs.min_vehicles) queries.min_vehicles = true;
      if (qs.strict_start) queries.strict_start = true;

      let qst = queryString.stringify(queries);

      let { data } = await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/calculateRoutes?${qst}`,
        { shifts: this.props.ghost_shifts },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );

      if (data && data.error && data.error.error) {
        console.log("routific error: ", data.error);
        stopSpinner();
        modalActions.pop();
        modalActions.push(<ErrorModal error={data.error.error} />);
      } else if (data && data.unserved) {
        stopSpinner();
        modalActions.pop();
        modalActions.push(<ErrorModal unserved={data.unserved} />);
      } else {
        localStorage.setItem("jobId", data.job_id);

        if (data && data.draft_created) {
          this.setState({ draft: true });
        }

        setTimeout(() => {
          this.getRouteResults(data.job.job_id);
        }, 5000);
      }
    } catch (e) {
      console.log("big eeeeeeee", e);
    }
  };

  runPartialMapAnything = async (sitters, appointments, qs) => {
    const {
      getAppointments,
      selectedDate,
      city,
      showSpinner,
      stopSpinner,
      modalActions
    } = this.props;
    try {
      showSpinner();
      let queries = {
        startDate: moment(selectedDate)
          .startOf("day")
          .toDate(),
        endDate: moment(selectedDate)
          .endOf("day")
          .toDate(),
        minVisits: qs.minVisits,
        maxVisits: qs.maxVisits,
        market: city._id,
        noteTime: qs.noteTime,
        priority_type: qs.priority_type
      };

      let advanced = false;
      if (qs.advanced) {
        advanced = { ...qs.advanced };
      }

      let qst = queryString.stringify(queries);

      let market = city._id;
      let { data } = await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/runMapAnything?${qst}`,
        { sitters, appointments, advanced, last_tms: qs.last_tms },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );

      if (data.job.error) {
        console.log("routific error: ", data.job.error.error);
        stopSpinner();
        modalActions.pop();
        modalActions.push(<ErrorModal error={data.job.error.error} />);
      } else if (data.job.unserved) {
        stopSpinner();
        modalActions.pop();
        modalActions.push(<ErrorModal unserved={data.job.unserved} />);
      } else {
        localStorage.setItem("jobId", data.job.job_id);
        if (data.draft_created) {
          this.setState({ draft: true });
        }
        setTimeout(() => {
          this.getMapAnythingResults(data.job.job_id);
        }, 5000);
      }
    } catch (e) {
      stopSpinner();
      modalActions.pop();
      modalActions.push(<ErrorModal error={e} />);
    }
  };

  runPartialSchedule = async (sitters, appointments, qs) => {
    const {
      getAppointments,
      selectedDate,
      city,
      showSpinner,
      stopSpinner,
      modalActions
    } = this.props;
    try {
      showSpinner();
      let queries = {
        startDate: moment(selectedDate)
          .startOf("day")
          .toDate(),
        endDate: moment(selectedDate)
          .endOf("day")
          .toDate(),
        time: qs.time,
        minVisits: qs.minVisits,
        lateness: qs.lateness,
        traffic: qs.traffic,
        market: city._id,
        noteTime: qs.noteTime,
        min_speed: qs.min_speed,
        max_vehicle_overtime: qs.max_vehicle_overtime,
        priority_type: qs.priority_type
      };
      if (qs.balance) queries.balance = true;
      if (qs.shortest_distance) queries.shortest_distance = true;
      if (qs.zones) queries.zones = true;
      if (qs.min_vehicles) queries.min_vehicles = true;
      if (qs.strict_start) queries.strict_start = true;

      let qst = queryString.stringify(queries);

      let market = city._id;
      let { data } = await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/calculateRoutes?${qst}`,
        { sitters, appointments },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );

      if (data.job.error) {
        console.log("routific error: ", data.job.error.error);
        stopSpinner();
        modalActions.pop();
        modalActions.push(<ErrorModal error={data.job.error.error} />);
      } else if (data.job.unserved) {
        stopSpinner();
        modalActions.pop();
        modalActions.push(<ErrorModal unserved={data.job.unserved} />);
      } else {
        localStorage.setItem("jobId", data.job.job_id);
        if (data.draft_created) {
          this.setState({ draft: true });
        }
        setTimeout(() => {
          this.getRouteResults(data.job.job_id);
        }, 5000);
      }
    } catch (e) {
      stopSpinner();
      modalActions.pop();
      modalActions.push(<ErrorModal error={e} />);
    }
  };

  runSchedule = () => {
    // this.setState({ scheduling: true })
    let { modalActions } = this.props;
    modalActions.push(
      <ScheduleOverlay
        fullScreen={false}
        onDismiss={modalActions.pop}
        textAlign="left"
        schedule={true}
      >
        <RouteModal
          appointments={this.props.appointments}
          addShifts={this.addShifts}
          ghost_shifts={this.props.ghost_shifts}
          // sitters={this.props.sitters}
          runAll={this.calculateAllRoutes}
          runSome={this.runPartialSchedule}
          errors={this.state.errors}
          results={this.state.results}
          showErrors={this.state.showErrors}
          time={this.props.selectedDate}
          formConstants={this.props.formConstants}
          lockFirstVisits={this.lockFirstVisits}
          modalActions={this.props.modalActions}
          sendNotifications={this.state.sendNotifications}
          toggleSendNotifications={this.toggleSendNotifications}
          runMapAnything={this.runMapAnything}
          runSomeMapAnything={this.runPartialMapAnything}
          showSpinner={this.props.showSpinner}
          stopSpinner={this.props.stopSpinner}
        />
      </ScheduleOverlay>
    );
  };

  unassignAppt = async appt => {
    let draft = this.state.draft;
    await axios.post(
      `${process.env.REACT_APP_API_URI}/admin/appointments/${appt._id}/unassign`,
      {
        draft: draft ? true : false,
        market_id: this.props.city._id
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      }
    );
    this.fetchAppointments();
  };

  sitterPreview = sitter => {
    this.setState({
      showSitterPreview: true,
      sitterPreview: sitter._id
    });
  };

  assignSitter = (sitter, appointment) => {
    let obj = {
      sitter: sitter,
      appointment: appointment
    };

    this.props.assignSitter(obj);
    this.setState({
      showSitterPreview: true,
      sitterPreview: sitter._id
    });
  };

  gotMessage = data => {
    this.props.updateVisit(data.data);
  };

  gotStaffMessage = data => {
    this.props.updateStaff(data.data);
  };

  lockFirstVisits = async () => {
    const {
      getAppointments,
      selectedDate,
      city,
      showSpinner,
      stopSpinner,
      modalActions,
      showSuccessMessage,
      removeMessage
    } = this.props;
    try {
      showSpinner();
      let lock = await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/lockFirstVisits`,
        { market_id: city._id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );

      stopSpinner();
      showSuccessMessage({
        message: "Successfully Locked",
        messageType: "success"
      });

      setTimeout(removeMessage, 2000);
    } catch (e) {
      stopSpinner();
      modalActions.pop();
      modalActions.push(
        <ErrorModal error={{ error: "Uh oh. Something went wrong." }} />
      );
    }
  };

  toggleSendNotifications = () => {
    this.setState({ sendNotifications: !this.state.sendNotifications });
  };

  toggleDraft = () => {
    this.setState({ draft: !this.state.draft }, this.fetchAppointments);
  };

  publishDraft = async () => {
    const {
      city,
      showSpinner,
      stopSpinner,
      modalActions,
      showSuccessMessage,
      removeMessage
    } = this.props;
    try {
      showSpinner();
      await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/publishDraft`,
        { market_id: city._id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );
      stopSpinner();
      this.setState({ draft: false });
      modalActions.pop();
      this.fetchAppointments(false);
    } catch (e) {
      modalActions.pop();
      stopSpinner();
      showSuccessMessage({
        message:
          e.data && e.data.message
            ? e.data.message
            : "Error publishing, please try again",
        messageType: "error"
      });
      setTimeout(removeMessage, 3000);
    }
  };

  discardDraft = async () => {
    const { city, showSpinner, stopSpinner, modalActions } = this.props;
    showSpinner();
    await axios.post(
      `${process.env.REACT_APP_API_URI}/admin/publishDraft?discard=true`,
      { market_id: city._id },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      }
    );
    stopSpinner();
    this.setState({ draft: false });
    modalActions.pop();
    this.fetchAppointments(false);
  };

  promptPublishDraft = () => {
    this.props.modalActions.push(
      <div className="prompter row">
        <p>
          Publish Schedule? This will send notifications to clients and team
          members.
        </p>
        <button className="btn btn-warning" onClick={() => this.publishDraft()}>
          Why Yes, Thank You.
        </button>
        <button
          className="btn btn-primary"
          onClick={() => this.props.modalActions.pop()}
        >
          I Could Never
        </button>
      </div>
    );
  };

  addShifts = shifts => {
    this.props.addShifts(shifts);
  };

  getSitterList = () => {
    return this.props.sitters
      .filter(s => {
        let hasVisit = this.props.appointments.find(
          a => a.sitter && a.sitter._id === s._id
        );
        return this.state.showOnlyWorkingSitters
          ? s.shift || hasVisit || s.ghost
          : s;
      })
      .sort((a, b) => {
        if (a.contact.full_name < b.contact.full_name) return -1;
        if (a.contact.full_name > b.contact.full_name) return 1;
        return 0;
      });
  };

  getPreviewSitterList = () => {
    return this.props.sitters.sort((a, b) => {
      if (a.contact.full_name < b.contact.full_name) return -1;
      if (a.contact.full_name > b.contact.full_name) return 1;
      return 0;
    });
  };

  moveVisits = async obj => {
    const {
      city,
      showSpinner,
      stopSpinner,
      modalActions,
      showSuccessMessage,
      removeMessage,
      selectedDate,
      sitters
    } = this.props;
    try {
      showSpinner();
      obj.market_id = city._id;
      obj.day = selectedDate;
      await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/moveShift`,
        obj,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );
      showSuccessMessage({
        message: "Visits Moved Into New Draft",
        messageType: "success"
      });
      setTimeout(removeMessage, 3000);
      let sitter = sitters.find(s => s._id === obj.to);
      this.setState({ draft: true }, this.fetchAppointments);
      if (sitter) {
        this.setState({
          showSitterPreview: true,
          sitterPreview: sitter._id
        });
      }
      stopSpinner();
    } catch (e) {
      stopSpinner();
      console.log("error here.....", e);
    }
  };

  filterSitterAppointments = s => {
    return this.props.appointments
      .filter(a => {
        return (
          [2, 3, 4, 5].includes(a.appointment_status.id) &&
          a.sitter &&
          a.sitter._id === s._id
        );
      })
      .sort(
        (t, t2) =>
          moment(t.scheduled_start_time) - moment(t2.scheduled_start_time)
      );
  };

  addAppointment = data => {
    this.props.addAppointment(data.appt, data.prev_id);
  };

  getStacked = appt => {
    let filterOutCanceled = this.props.appointments.filter(
      a => !(a.appointment_status.id === 7 && (!a.sitter || !a.sitter._id))
    );
    const filteredAppointments = applyMultiFilters(
      filterOutCanceled,
      this.props.calendarFilters
    );
    let stacked = filteredAppointments.filter(
      a =>
        (appt.contact.apartment_id &&
          a.contact.apartment_id === appt.contact.apartment_id) ||
        appt.client_id === a.client_id
    );
    if (stacked.length === 1) return [];
    return stacked;
  };

  render() {

    const filterDimensions = [
      "Not Canceled",
      "Appointment Status",
      "Time Period",
      "Client",
      "Zone",
      "Pet",
      "Pet Class",
      "Home Class",
      "Team Member",
      "Recurrence Type"
    ];

    let filterOutCanceled = this.props.appointments.filter(
      a => !(a.appointment_status.id === 7 && (!a.sitter || !a.sitter._id))
    );
    const filteredAppointments = applyMultiFilters(
      filterOutCanceled,
      this.props.calendarFilters
    );

    const badAssignments = filteredAppointments
      .filter(a => a.sitter && a.sitter._id)
      .filter(ap => {
        let findIt = this.props.sitters.find(s => s._id === ap.sitter._id);
        return (
          findIt &&
          !findIt.shift &&
          ap.appointment_status.id !== 5 &&
          ap.appointment_status.id !== 7 &&
          ap.appointment_status.id !== 6 &&
          ap.appointment_type.id !== 8
        );
      });

    const pendingAppointments = filteredAppointments.filter(a => {
      return (
        (a.appointment_status &&
          a.appointment_status.id === 3 &&
          (!a.sitter || !a.sitter._id)) ||
        (a.appointment_status && a.appointment_status.id === 1) ||
        (a.appointment_status.id === 2 &&
          (!a.sitter || !a.sitter._id || !a.scheduled_start_time))
      );
    });

    const cancellations = filteredAppointments.filter(a => {
      return (
        a.appointment_status &&
        a.appointment_status.id === 7 &&
        a.sitter &&
        a.sitter._id
      );
    });

    const edits = filteredAppointments.filter(a => {
      //sitter is still removed on server so removing the sitter check here
      return (
        a.appointment_status &&
        a.appointment_status.id === 3 &&
        a.sitter &&
        a.sitter._id
      );
    });

    const scheduled = filteredAppointments.filter(a => {
      return (
        a.appointment_status &&
        a.appointment_status.id === 2 &&
        (a.sitter && a.sitter._id) &&
        a.scheduled_start_time
      );
    });

    const completed = filteredAppointments.filter(a => {
      return a.appointment_status && a.appointment_status.id === 5;
    });

    const consultations = filteredAppointments.filter(a => {
      return (
        (a.appointment_type && a.appointment_type.id === 8) ||
        (a.appointment_type && a.appointment_type.id === 10)
      );
    });

    const concierge = filteredAppointments.filter(a => {
      return a.appointment_type.id === 12;
    });

    let sitterListStyle = {
      width: this.state.showSitterPreview ? "0px" : "18%",
      transition: ".25s all ease",
      paddingLeft: this.state.showSitterPreview ? "0px" : "15px",
      paddingTop: this.state.showSitterPreview ? "0px" : "15px",
      paddingBottom: this.state.showSitterPreview ? "0px" : "15px",
      overflow: "scroll"
    };

    if (this.props.city && this.props.city._id === "ALL") {
      return (
        <CardComponent title="Error">
          <div className="row">
            <div className="col-md-12 text-center">
              <i
                className="fa fa-exclamation-triangle fa-5x"
                aria-hidden="true"
              ></i>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              You must select a specific market to view the map and schedule
              appointments
            </div>
          </div>
        </CardComponent>
      );
    } else {
      return (
        <div className="card map-card">
          <div className="row">
            <div className="col-xs-6">
              <DateSwitcher
                dateFormat="dddd, MMMM D, YYYY"
                max={moment().add(2, "weeks")}
                min={moment()}
                onDateBack={this.onDateBack}
                onDateForward={this.onDateForward}
                selectDate={this.selectDate}
                selectedDate={moment(this.props.selectedDate)}
              />
              <Pusher
                channel="update-visit"
                event="update-visit"
                onUpdate={data => this.gotMessage(data)}
              />
              <Pusher
                channel="update-staff"
                event="update-staff"
                onUpdate={data => this.gotStaffMessage(data)}
              />
              <Pusher
                channel="appointment"
                event="new-appointment"
                onUpdate={data => {
                  this.addAppointment(data);
                }}
              />
              <Pusher
                channel="scheduling"
                event="is_concierge"
                onUpdate={data => {
                  let message = `TM ${data.sitter.full_name}'s concierge schedule conflicts with their floating break. The break will be ignored.`;
                  this.props.showSpecialMessage({
                    message,
                    messageType: "error"
                  });
                }}
              />
            </div>
            <div className="col-xs-6">
              <div className="form-group input-field mapStatus">
                <Multiselect
                  data={getFilterOptions(filterOutCanceled, filterDimensions)}
                  defaultValue={this.props.calendarFilters}
                  valueField="value"
                  textField="text"
                  groupBy={item => item.data.type}
                  filter="contains"
                  onChange={filters =>
                    this.props.setFilters(filters, "calendar")
                  }
                  placeholder="Filter by anything..."
                />
              </div>
            </div>
          </div>

          <div
            className={`map-container ${this.state.draft ? "draftMode" : ""}`}
          >
            {/*// list of sitters on the left of map*/}

            <div className="sitter-list" style={sitterListStyle}>
              <label>Scheduled TMs Only</label>
              <input
                type="checkbox"
                checked={this.state.showOnlyWorkingSitters}
                onChange={() =>
                  this.setState({
                    showOnlyWorkingSitters: !this.state.showOnlyWorkingSitters
                  })
                }
              />
              <p># of Team Members: {this.getSitterList().length}</p>

              {this.getSitterList().map(s => {
                return (
                  <SitterAppointmentListItem
                    key={`sitterList${s._id}`}
                    sitter={s}
                    appointments={this.filterSitterAppointments(s)}
                    showPreview={this.showPreview}
                    highlightSitter={this.highlightSitter}
                    highlightAppointmentOnMap={this.highlightAppointmentOnMap}
                    sitterPreview={this.sitterPreview}
                    selectedDate={this.props.selectedDate}
                    timeZone={this.props.location.time_zone}
                    appointment={this.state.previewAppt}
                  />
                );
              })}
            </div>
            {this.state.showSitterPreview ? (
              <SitterPreview
                sitter={this.props.sitters.find(
                  s => s._id === this.state.sitterPreview
                )}
                sitters={this.getPreviewSitterList()}
                showSpinner={this.props.showSpinner}
                stopSpinner={this.props.stopSpinner}
                changeStart={this.props.changeStart}
                moveVisits={this.moveVisits}
                changeEnd={this.props.changeEnd}
                removeShift={this.removeShift}
                saveAppointments={this.saveAppointments}
                assignSitter={this.assignSitter}
                highlightAppointmentOnMap={this.highlightAppointmentOnMap}
                showPreview={this.showPreview}
                selectedDate={this.props.selectedDate}
                timeZone={this.props.city.time_zone}
                removeBreak={this.props.removeBreak}
                addBreak={this.props.addBreak}
                updateBreak={this.props.updateBreak}
                appointments={this.props.appointments}
                previewedAppointment={this.state.previewAppt}
              />
            ) : null}

            <div className="maplist">
              <label>Draft</label>
              <input
                type="checkbox"
                checked={this.state.draft}
                onChange={() => this.toggleDraft()}
              />
              <button
                className="btn btn-default"
                onClick={() => this.promptPublishDraft()}
                disabled={!this.state.draft}
              >
                Publish Draft
              </button>
              {!this.state.draft ? (
                <button
                  className="btn btn-primary"
                  onClick={() => this.runSchedule()}
                >
                  Run Schedule
                </button>
              ) : (
                <button
                  className="btn btn-warning"
                  onClick={() => this.discardDraft()}
                >
                  Discard Draft
                </button>
              )}
              <AppointmentListItem
                showPreview={this.showPreview}
                highlightAppointmentOnMap={this.highlightAppointmentOnMap}
                type="Needs Attention"
                appointments={pendingAppointments}
              />
              <AppointmentListItem
                showPreview={this.showPreview}
                highlightAppointmentOnMap={this.highlightAppointmentOnMap}
                type="Cancellations"
                appointments={cancellations}
              />
              <AppointmentListItem
                showPreview={this.showPreview}
                highlightAppointmentOnMap={this.highlightAppointmentOnMap}
                type="Change Requests"
                appointments={edits}
              />
              <AppointmentListItem
                showPreview={this.showPreview}
                highlightAppointmentOnMap={this.highlightAppointmentOnMap}
                type="Scheduled"
                appointments={scheduled}
              />
              <AppointmentListItem
                showPreview={this.showPreview}
                highlightAppointmentOnMap={this.highlightAppointmentOnMap}
                type="Completed"
                appointments={completed}
              />
              <AppointmentListItem
                showPreview={this.showPreview}
                highlightAppointmentOnMap={this.highlightAppointmentOnMap}
                type="Consultations"
                appointments={consultations}
              />
              <AppointmentListItem
                showPreview={this.showPreview}
                highlightAppointmentOnMap={this.highlightAppointmentOnMap}
                type="Concierge"
                appointments={concierge}
              />
            </div>

            <GoogleMapReact
              onClick={e => {
                // this is a hack to get around how the google map react library handles events
                if (e.event.origin && e.event.origin === "petmarker") {
                  delete e.event.origin;
                } else {
                  this.turnItAllOff();
                }
              }}
              center={{
                lat: parseFloat(this.props.city.latitude),
                lng: parseFloat(this.props.city.longitude)
              }}
              defaultZoom={this.props.zoom}
              layerTypes={this.state.traffic ? ["TrafficLayer"] : []}
              hoverDistance={0}
              options={{
                panControl: false,
                mapTypeControl: false,
                scrollwheel: false
              }}
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
              }}
            >
              {applyMultiFilters(
                filterOutCanceled,
                this.props.calendarFilters
              ).map(appointment => {
                return (
                  <PetMarker
                    showPreview={this.showPreview}
                    key={appointment._id}
                    lat={
                      appointment.home.address.loc
                        ? appointment.home.address.loc.coordinates[1]
                        : 80
                    }
                    lng={
                      appointment.home.address.loc
                        ? appointment.home.address.loc.coordinates[0]
                        : 35
                    }
                    appointment={appointment}
                    sitters={this.props.sitters}
                    completeAppointment={this.completeAppointment}
                    stackedVisits={this.getStacked(appointment)}
                    completeAppointmentWithLateCharge={
                      this.completeAppointmentWithLateCharge
                    }
                  />
                );
              })}
            </GoogleMapReact>

            <MapAppointmentDetail
              appointment={this.state.previewAppt}
              //apartments={this.state.apartments}
              appointments={this.props.appointments}
              sitters={this.props.sitters}
              saveAppt={this.saveAppt}
              unassignAppt={this.unassignAppt}
              showPreview={this.showPreview}
              approveAppt={this.approveAppt}
              assignSitter={this.assignSitter}
              setAppointment={this.setAppointmentTime}
              completeAppointment={this.completeAppointment}
              completeAppointmentWithLateCharge={
                this.completeAppointmentWithLateCharge
              }
            />

            <div className="traffic">
              <label>Show Traffic</label>
              <input
                type="checkbox"
                checked={this.state.traffic}
                onClick={() => this.setState({ traffic: !this.state.traffic })}
              /> 
              <button onClick={() => this.getMapAnythingResults()}>Results Again</button>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  calendarFilters: state.admin.scheduler.calendarFilters,
  appointments: state.admin.appointments.appointmentList,
  selectedDate: state.admin.scheduler.selectedDate,
  sitters: state.admin.staff.staffList,
  city: state.admin.general.location,
  formConstants: state.general.formConstants,
  ghost_shifts: state.admin.staff.ghost_shifts
});

const mapDispatchToProps = dispatch => ({
  showSpinner: () => dispatch({ type: "FETCH_REQUEST" }),
  stopSpinner: () => dispatch({ type: "FETCH_SUCCESS" }),
  showSuccessMessage: msg => dispatch({ type: "ADD_MESSAGE", payload: msg }),
  showSpecialMessage: msg => {
    dispatch({ type: "ADD_SPECIAL_MESSAGE", payload: msg });
    setTimeout(() => {
      dispatch({ type: "REMOVE_SPECIAL_MESSAGE" });
    }, 5000);
  },
  addBreak: (sitter, ourBreak) =>
    dispatch({ type: "ADD_BREAK", payload: { sitter, ourBreak } }),
  updateBreak: (sitter, ourBreak) =>
    dispatch({ type: "UPDATE_BREAK", payload: { sitter, ourBreak } }),
  removeBreak: sitter => dispatch({ type: "REMOVE_BREAK", payload: sitter }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" }),
  assignSitter: payload =>
    dispatch({ type: "ASSIGN_SITTER", payload: payload }),
  setSelectedDate: (date, city) =>
    dispatch(setSchedulerSelectedDate(date, city)),
  setFilters: (filters, context) =>
    dispatch(setSchedulerFilters(filters, context)),
  showPreview: app => dispatch(showAppointmentPreview(app)),
  highlightSitter: id => dispatch(highlightSitter(id)),
  highlightAppointmentOnMap: id => dispatch(highlightAppointmentOnMap(id)),
  getAppointments: filters => dispatch(getAppointmentsAction(filters)),
  getStaff: qs => dispatch(getAllStaffWithAppointments(qs)),
  setAppointment: app => dispatch(setAppointmentTime(app)),
  approveAppointment: (_id, app) => dispatch(approveAppointment(_id, app)),
  saveMapAppointment: (appts, draft) =>
    dispatch(saveMapAppointment(appts, draft)),
  clearVisits: () => dispatch({ type: "CLEAR_VISITS" }),
  addShifts: shifts => dispatch({ type: "ADD_SHIFTS", payload: shifts }),
  modalActions: {
    push: component => dispatch(pushModal(component)),
    pop: () => dispatch(popModal())
  },
  removeShift: id => dispatch({ type: "REMOVE_GHOST", payload: id }),
  changeStart: obj => dispatch({ type: "CHANGE_GHOST_START", payload: obj }),
  changeEnd: obj => dispatch({ type: "CHANGE_GHOST_END", payload: obj }),
  updateVisit: app => dispatch({ type: "UPDATE_VISIT_PUSH", payload: app }),
  updateStaff: staff => dispatch({ type: "UPDATE_STAFF_PUSH", payload: staff }),
  addAppointment: (appt, prev_id) =>
    dispatch({ type: "ADD_APPOINTMENT", payload: appt, prev_id: prev_id })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapComponent);
