import { APIService } from "../../services";
import * as ActionTypes from "./actionTypes";
import { dispatchFetchFailure } from "../helpers";
import { showSuccessMessage } from "../general.actions";

export function getClientUserInfo() {
  const context = "getClientUserInfo";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.client
      .requestClientUserInfo()
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch({
            type: ActionTypes.GET_CLIENT_USER_INFO_SUCCESS,
            payload: {
              info: response
            }
          });
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error => console.error(error));
  };
}

export function updateClientProfile(model) {
  const context = "updateClientProfile";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.client
      .updateClientProfile(model)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch(getClientUserInfo());
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function setPaymentMethod(model) {
  const context = "setPaymentMethod";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.client
      .setPaymentMethod(model)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch(getClientUserInfo()); // update the client information
          dispatch(
            showSuccessMessage("Payment method successfully updated.", context)
          );
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}
