import React, { Component } from "react";
import { Link } from "react-router-dom";
import { CardComponent } from "../../../../components";

export default class PetSelector extends Component {
  render() {
    const { selectPet } = this.props;
    return (
      <CardComponent title="Add New Pet">
        <div className="row">
          <div className="col-sm-12">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-2 col-lg-offset-3 text-center">
              <button
                className="btn btn-block btn-secondary btn-lg overlay-btn"
                onClick={selectPet.bind(this, "Dog")}
              >
                Dog
              </button>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-2 text-center">
              <button
                className="btn btn-block btn-secondary btn-lg overlay-btn"
                onClick={selectPet.bind(this, "Cat")}
              >
                Cat
              </button>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-2 text-center">
              <button
                className="btn  btn-block btn-secondary btn-lg overlay-btn"
                onClick={selectPet.bind(this, "Other")}
              >
                Other
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 text-center">
            <h5 className="staff-overlay-cancel">
              <Link to="/admin/clientlist" style={{ color: "#B6B3AD" }}>
                Skip this step
              </Link>
            </h5>
          </div>
        </div>
      </CardComponent>
    );
  }
}
