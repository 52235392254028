import React, { Component } from "react";
import Combobox from "react-widgets/Combobox";

export default class ComboboxField extends Component {

  state = {
    active: false
  };

  handleFocus = () => {
    this.setState({ active: true });
  };

  handleBlur = () => {
    this.setState({ active: false });
  };

  render = () => {
    // eslint-disable-next-line
    const { active } = this.state;
    const { input, meta, placeholder, required, ...rest } = this.props;
    const { touched, error } = meta;
    // const { warning } = meta;

    // TODO: Set up error styles.
    const wrapperClass = touched && error ? " error-text error-border" : "";
    const focusClass = active ? " field-focus" : "";
    const errorClass = touched && error ? "error-border" : null;
    const hasValue = input.value;

    return (
      <div
        className={`input-wrapper--form-control-wrapper-component focus-fix${wrapperClass}`}
        tabIndex="-1"
      >
        <div className={`field-focus-wrapper${focusClass}`} />

        <label
          htmlFor={input.name}
          className={
            (hasValue ? "form-label-not-empty" : "form-label") +
            (touched && error ? " error-text" : "")
          }
        >
          {placeholder}
        </label>

        <Combobox
          {...input}
          value={input.value}
          name={input.name}
          className={errorClass}
          onBlur={this.handleBlur} // Fix to prevent redux-form parse from trying to parse null value
          onFocus={this.handleFocus}
          placeholder={`${placeholder} ${required ? "*" : ""}`}
          {...rest}
        />
      </div>
    );
  };
}
