import React, { useState, useEffect } from "react";
import { ImageComponent } from "../../";
import {useDropzone} from 'react-dropzone';
import axios from "../../../services/network.js";

const AppointmentImageField = (props) => {

  const [images, setImages] = useState([])

  
  const onDrop = image => {
    let formData = new FormData();
    formData.append("appointment_image", image[0]);
    const config = {
      headers: {
        "content-type": "mulipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("accessToken")
      }
    };
    
    return axios
    .post(
      `${process.env.REACT_APP_API_URI}/admin/appointments/${props.appointmentId}/images`,
      formData,
      config
      )
      .then(res => {
        setImages([...images, res.data]);
      })
      .catch(e => console.log("error: ", e));
    };
    

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({ onDrop });

  const toggleDeletion = imageId => {
    const { input } = props;
    const newValue = [...input.value];

    // If the imageId is already marked for deletion, unmark it
    if (input.value.includes(imageId)) {
      newValue.splice(newValue.indexOf(imageId), 1);

      // If the imageId is not marked for deletion, mark it
    } else {
      newValue.push(imageId);
    }

    input.onChange(newValue);
  };

  const { input } = props;

  const propsImages = props.images || []

  return (

      <div>
      
        <div
          {...getRootProps()}
          className="dropzone-contents"
          style={!input.value ? { opacity: 1 } : null}
        >
          <div>
            <span className="fa fa-picture-o" />
          </div>
          <div>Add Image</div>
        </div>

      

        <ImageComponent
          images={[...propsImages, ...images]}
          toggleDeletion={toggleDeletion}
          imagesMarkedForDeletion={input.value}
        />
      </div>
    );
  
}

export default AppointmentImageField