import React from "react";
import { connect } from "react-redux";
import {
  setPaymentMethod as adminSetPaymentMethodAction,
  getClient
} from "../../actions/admin/clients.actions";
import { setPaymentMethod as clientSetPaymentMethodAction } from "../../actions/client/user.actions";
import axios from "../../services/network.js";

// Stripe
let stripeKey;
if (process.env.REACT_APP_STRIPE_MODE === "test") {
  stripeKey = process.env.REACT_APP_STRIPE_TEST_KEY;
} else {
  stripeKey = process.env.REACT_APP_STRIPE_PROD_KEY;
}

const stripe = Stripe(stripeKey);

class StripeCard extends React.Component {

  componentDidMount = () => {
    const {
      clientId,
      adminSetPaymentMethod,
      clientSetPaymentMethod
    } = this.props;
    const style = {
      base: {
        color: "#32325d",
        lineHeight: "24px",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    };

    const elements = stripe.elements();
    this.card = elements.create("card", { style });
    this.card.mount(this.stripeCardElement);

    // Handle real-time validation errors from the card Element.
    this.card.addEventListener("change", this.handleChange);

    // Get token on submit
    this.paymentFormElement.addEventListener("submit", async e => {
      const {
        showMessage,
        removeMessage,
        getClient,
        showSpinner,
        stopSpinner
      } = this.props;
      showSpinner();
      e.preventDefault();
      const { token, error } = await stripe.createToken(this.card);

      if (error) {
        this.stripeCardErrors.textContent = error.message;
      } else if (this.props.clientId) {
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_API_URI}/admin/clients/${this.props.clientId}/payment-method/set`,
            { token: token.id },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`
              }
            }
          );
          console.log(data);
          showMessage({
            message: "Successfully saved card",
            messageType: "success"
          });
          setTimeout(removeMessage, 3000);
          this.props.onDismiss();
        } catch (e) {
          showMessage({
            message: e.response.data.message,
            messageType: "error"
          });
          setTimeout(removeMessage, 3000);
        }
        getClient(this.props.clientId);
        //adminSetPaymentMethod(this.props.clientId, { token: token.id });
      } else {
        clientSetPaymentMethod({ token: token.id });
      }
      stopSpinner();
    });
  };

  componentWillUnmount = () => {
    this.card.removeEventListener("change", this.handleChange);
    this.card.unmount();
  };

  handleChange = ({ error }) => {
    const displayError = this.stripeCardErrors;
    if (error) {
      displayError.textContent = error.message;
    } else {
      displayError.textContent = "";
    }
  };

  render() {
    const { cards, onDismiss } = this.props;

    return (
      <form
        action="/charge"
        method="post"
        id="payment-form"
        ref={node => (this.paymentFormElement = node)}
      >
        <div className="form-row">
          <label htmlFor="card-element">Credit or debit card</label>
          <div id="card-element" ref={node => (this.stripeCardElement = node)}>
            {/* <!-- a Stripe Element will be inserted here. --> */}
          </div>

          {/* <!-- Used to display Element errors --> */}
          <div
            id="card-errors"
            ref={node => (this.stripeCardErrors = node)}
          ></div>
        </div>

        <button>{cards.length ? "Save Card" : "Add Card"}</button>

        <a className="btn cc-cancel-btn" onClick={onDismiss}>
          Cancel
        </a>
      </form>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  adminSetPaymentMethod: (clientId, model) =>
    dispatch(adminSetPaymentMethodAction(clientId, model)),
  clientSetPaymentMethod: model =>
    dispatch(clientSetPaymentMethodAction(model)),
  showSpinner: () => dispatch({ type: "FETCH_REQUEST" }),
  stopSpinner: () => dispatch({ type: "FETCH_SUCCESS" }),
  showMessage: msg => dispatch({ type: "ADD_MESSAGE", payload: msg }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" }),
  getClient: clientId => dispatch(getClient(clientId))
});

export default connect(
  null,
  mapDispatchToProps
)(StripeCard);
