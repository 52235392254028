import React from "react";
import moment from "moment";
import Tooltip from "rc-tooltip";
import { formatNumsToDollars } from "../../helpers";

export default class PaymentTableItem extends React.Component {
  componentWillReceiveProps = nextProps => {
    this.setState({ selected: nextProps.selected });
  };

  selectPayment = e => {
    e.stopPropagation();
    this.props.selectPayment(this.props.item, !this.props.selected);
  };

  render() {
    const { item } = this.props;
    return (
      <div className="lineItemsRow clearfix">
        <div className="col-sm-1">
          {moment(item.createdAt).format("MMM-D-YY")}
        </div>
        <div className="col-sm-5 lineItemDescription">{item.description}</div>
        <div className="col-sm-1">{formatNumsToDollars(item.amount)}</div>
        <div className="col-sm-1">{item.stripe_charge_status}</div>
        <div className="col-sm-3 lineItemDescription">
          <Tooltip
            placement="top"
            overlay={<div>{item.stripe_error_message}</div>}
            mouseEnterDelay={0.2}
            mouseLeaveDelay={0.1}
            overlayClassName="annotation-tooltip"
          >
            <span>
              {item.stripe_error_code ? `${item.stripe_error_code}:` : null}
            </span>
          </Tooltip>
        </div>
        <div className="col-sm-1">
          {item.deleted ? (
            <Tooltip
              placement="top"
              overlay={
                <div>Deleted items will not be ran with other payments</div>
              }
              mouseEnterDelay={0.2}
              mouseLeaveDelay={0.1}
              overlayClassName="annotation-tooltip"
            >
              <span
                style={{ color: "red" }}
              >{`Deleted by ${item.deleted_by}`}</span>
            </Tooltip>
          ) : (
            <input
              type="checkbox"
              checked={this.props.selected}
              onChange={e => this.selectPayment(e)}
            />
          )}
        </div>
      </div>
    );
  }
}
