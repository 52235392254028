import React, { Component } from "react";
import axios from "../../../../services/network.js";
import { DefaultModal } from "../../../index.js";

class ClientPromoCard extends Component {
  state = {
    referrals: [],
    redemptions: [],
    promo_code: ""
  };

  componentWillMount = async () => {
    const { client } = this.props;
    if (client) {
      // This would be a call to get all referrals in the case of multiple Promos
      // build a tables or something based on requirements...
      // const { data } = await axios.get(`${process.env.REACT_APP_API_URI}/admin/client/${this.props.client._id}/promos`, {
      //   headers: {
      //     'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      //   }
      // })
      this.setState({ promo_code: client.promo_code || "" });
    }
  };

  savePromo = async remove => {
    const {
      fetchRequest,
      fetchSuccess,
      showMessage,
      removeMessage
    } = this.props;
    fetchRequest();
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/client/${this.props.client._id}/promos`,
        { promo_code: this.state.promo_code },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );
      if (data && data.added) {
        showMessage({ message: data.message, messageType: "success" });
      } else {
        showMessage({ message: data.message, messageType: "error" });
      }
      if (remove) this.props.modalActions.pop();
    } catch (e) {
      showMessage({ message: e.data.message, messageType: "error" });
    }
    setTimeout(removeMessage, 3000);
    fetchSuccess();
  };

  validatePromo = () => {
    const saveButton = (
      <button
        className="btn btn-primary"
        onClick={() => {
          this.savePromo(true);
        }}
      >
        Save
      </button>
    );
    const cancelButton = (
      <button
        className="btn btn-danger"
        onClick={() => {
          this.props.modalActions.pop();
        }}
      >
        Cancel
      </button>
    );
    if (!this.state.promo_code) {
      this.props.modalActions.push(
        <DefaultModal
          options={[saveButton, cancelButton]}
          onDismiss={this.props.modalActions.pop}
        >
          You are about to remove a promo from this Client, do you wish to
          continue?
        </DefaultModal>
      );
      return;
    }
    this.savePromo();
  };

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-3">
            <div className="form-component-wrapper">
              <input
                type="text"
                placeholder="Promo Code"
                onChange={e => this.setState({ promo_code: e.target.value })}
                value={this.state.promo_code}
              />
            </div>
          </div>
          <div className="col-md-1">
            <button
              type="button"
              className="btn btn-primary normal-size-btn"
              onClick={this.validatePromo}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ClientPromoCard;
