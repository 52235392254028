import * as ActionTypes from "../../actions";

const constants = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.GET_CONSTANTS_SUCCESS:
      return action.payload.formConstants;

    default:
      return state;
  }
};

export default constants;
