import React, { Component } from "react";
import { Multiselect, DropdownList } from "react-widgets";
import Tooltip from "rc-tooltip";
import uuid from "uuid/v4";
const dog_sizes = ["XS", "S", "M", "L", "XL"];

class Kennels extends Component {
  state = {
    add: false,
    addKennel: false,
    name: "",
    size: "",
    square_feet: "",
    dog_sizes_allowed: [],
    newKennel: {
      number_to_create: 1,
      reference_number: "",
      type: "",
      default_area: "",
      assigned_area: ""
    }
  };

  renderKennelTypes = () => {
    let { facility } = this.props;
    return facility.kennel_types.map(t => {
      return (
        <div
          style={{ overflow: "hidden" }}
          onClick={() =>
            this.setState({
              add: true,
              ...t
            })
          }
        >
          <div className="col-sm-3 specialOne">{t.name}</div>
          <div className="col-sm-3 specialOne">{t.size}</div>
          <div className="col-sm-2 specialOne">{t.square_feet}</div>
          <div className="col-sm-4 specialOne">
            <Multiselect
              disabled
              data={dog_sizes}
              value={t.dog_sizes_allowed}
            />
          </div>
        </div>
      );
    });
  };

  renderKennels = () => {
    let { facility, playgroups } = this.props;
    return facility.kennels.map(t => {
      let ourArea = facility.areas.find(a => a._id && a._id === t.area_id);
      let ourGroup = playgroups.find(g => g._id && g._id === t.playgroup_id);
      return (
        <div
          style={{ overflow: "hidden", cursor: "pointer" }}
          onClick={() =>
            this.setState({
              addKennel: true,
              newKennel: {
                ...t
              }
            })
          }
        >
          <div className="col-sm-3 specialOne">{t.reference_number}</div>
          <div className="col-sm-3 specialOne">{t.type}</div>
          <div className="col-sm-3 specialOne">
            {ourArea ? ourArea.name : ""}
          </div>
          <div className="col-sm-3 specialOne">
            {ourGroup ? ourGroup.name : ""}
          </div>
        </div>
      );
    });
  };

  addType = val => {
    this.setState({ add: val });
    if (!val) {
      this.setState({
        id: "",
        name: "",
        size: "",
        square_feet: "",
        dog_sizes_allowed: []
      });
    }
  };

  addKennel = () => {
    let new_kennel = {
      ...this.state.newKennel,
      facility_id: this.props.facility._id
    };
    this.props.addKennel(new_kennel);
    this.setState({
      addKennel: false,
      newKennel: {
        reference_number: "",
        type: "",
        default_area: "",
        assigned_area: ""
      }
    });
  };

  addKennelType = () => {
    let { kennel_types } = this.props.facility;
    let newOnes = [...kennel_types];
    let type = { ...this.state };
    if (type.id) {
      let findIndex = newOnes.findIndex(k => k.id === type.id);
      if (findIndex > -1) newOnes.splice(findIndex, 1, type);
    } else {
      type.id = uuid();
      newOnes.push(type);
    }
    this.props.updateBasicInfo("kennel_types", newOnes, true);
    this.setState({
      add: false,
      name: "",
      size: "",
      square_feet: "",
      dog_sizes_allowed: []
    });
  };

  getNumbers = () => {
    let nums = [];
    for (let i = 1; i < 51; i++) {
      nums.push(i);
    }
    return nums;
  };

  render() {
    let { name, square_feet, dog_sizes_allowed, size } = this.state;
    return (
      <div className="row boarding kennels">
        <div className="col-sm-12">
          <div className="col-sm-12">
            <h4>Kennels</h4>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="col-sm-12 rowSection">
            <div>
              <h5>Kennel Types</h5>
            </div>
            <div style={{ overflow: "hidden" }}>
              <div className="col-sm-3 specialOne">Name</div>
              <div className="col-sm-3 specialOne">Size</div>
              <div className="col-sm-2 specialOne">Square Feet</div>
              <div className="col-sm-4 specialOne">Dog Sizes Allowed</div>
            </div>
            {this.renderKennelTypes()}
            <div
              clearfix
              style={{ padding: "25px", minHeight: "100px", margin: "30px 0" }}
            >
              {!this.state.add ? (
                <button
                  className="btn btn-primary"
                  onClick={() => this.addType(true)}
                  style={{ display: "block", margin: " auto" }}
                >
                  Add New Type
                </button>
              ) : null}
            </div>
            {this.state.add ? (
              <div style={{ position: "relative" }}>
                <button
                  className="btn btn-danger"
                  onClick={() => this.addType(false)}
                  style={{ position: "absolute", right: 0, top: "-50px" }}
                >
                  Cancel
                </button>
                <div>
                  <div className="col-sm-3 specialOne">
                    Name
                    <input
                      type="text"
                      value={name}
                      onChange={e => this.setState({ name: e.target.value })}
                    />
                  </div>
                  <div className="col-sm-3 specialOne">
                    Size
                    <input
                      type="text"
                      value={size}
                      onChange={e => this.setState({ size: e.target.value })}
                    />
                  </div>
                  <div className="col-sm-2 specialOne">
                    Square Feet
                    <input
                      type="text"
                      value={square_feet}
                      onChange={e =>
                        this.setState({ square_feet: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-sm-4 specialOne">
                    Dog Sizes Allowed
                    <Multiselect
                      data={dog_sizes}
                      value={dog_sizes_allowed}
                      onChange={e => this.setState({ dog_sizes_allowed: e })}
                    />
                  </div>
                </div>
                <div>
                  <button
                    className="btn btn-secondary pull-right"
                    onClick={this.addKennelType}
                    style={{ marginTop: "20px" }}
                  >
                    {this.state.id ? "Save" : "Add"}
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-sm-6">
          <div className="col-sm-12">
            <div className="col-sm-12">
              <h5>Facility Kennels</h5>
            </div>
            <div className="col-sm-12">
              {this.renderKennels()}
              <div
                clearfix
                style={{
                  padding: "25px",
                  minHeight: "100px",
                  margin: "30px 0"
                }}
              >
                {!this.state.add ? (
                  <button
                    className="btn btn-primary"
                    onClick={() => this.setState({ addKennel: true })}
                    style={{ display: "block", margin: " auto" }}
                  >
                    Add Kennel
                  </button>
                ) : null}
              </div>
              {this.state.addKennel ? (
                <div style={{ position: "relative" }}>
                  <button
                    className="btn btn-danger"
                    onClick={() =>
                      this.setState({ addKennel: false, newKennel: {} })
                    }
                    style={{ position: "absolute", right: 0, top: "-50px" }}
                  >
                    Cancel
                  </button>
                  <div>
                    {this.state.newKennel && this.state.newKennel._id ? null : (
                      <div className="col-sm-4 specialOne">
                        How Many?
                        <DropdownList
                          data={this.getNumbers()}
                          value={this.state.newKennel.number_to_create}
                          onChange={val => {
                            this.setState({
                              ...this.state,
                              newKennel: {
                                ...this.state.newKennel,
                                number_to_create: val
                              }
                            });
                          }}
                        />
                      </div>
                    )}
                    <div className="col-sm-4 specialOne">
                      Reference
                      <Tooltip
                        placement="top"
                        overlay={
                          <div>
                            These are the letters that will be used to reference
                            the kennels. A unique number will be automatically
                            added to each kennel. Example - 'RR' will become
                            'RR1, RR2, RR3...'. You can edit the reference code
                            later.
                          </div>
                        }
                        mouseEnterDelay={0.2}
                        mouseLeaveDelay={0.1}
                        overlayClassName="annotation-tooltip"
                      >
                        <i
                          className="fa fa-info"
                          style={{
                            marginLeft: "10px",
                            fontSize: "18px",
                            cursor: "pointer"
                          }}
                        />
                      </Tooltip>
                      <input
                        type="text"
                        value={this.state.newKennel.reference_number}
                        onChange={e =>
                          this.setState({
                            ...this.state,
                            newKennel: {
                              ...this.state.newKennel,
                              reference_number: e.target.value
                            }
                          })
                        }
                      />
                    </div>
                    <div className="col-sm-4 specialOne">
                      Type
                      <DropdownList
                        data={this.props.facility.kennel_types}
                        textField="name"
                        valueField="name"
                        value={this.state.newKennel.type}
                        onChange={val =>
                          this.setState({
                            ...this.state,
                            newKennel: {
                              ...this.state.newKennel,
                              type: val.name
                            }
                          })
                        }
                      />
                    </div>
                    <div className="col-sm-4 specialOne">
                      Area
                      <DropdownList
                        data={this.props.facility.areas}
                        textField="name"
                        valueField="_id"
                        value={this.state.newKennel.area_id}
                        onChange={val =>
                          this.setState({
                            ...this.state,
                            newKennel: {
                              ...this.state.newKennel,
                              area_id: val._id
                            }
                          })
                        }
                      />
                    </div>
                    <div className="col-sm-4 specialOne">
                      Play Group
                      <DropdownList
                        data={this.props.playgroups}
                        textField="name"
                        valueField="_id"
                        value={this.state.newKennel.playgroup_id}
                        onChange={val =>
                          this.setState({
                            ...this.state,
                            newKennel: {
                              ...this.state.newKennel,
                              playgroup_id: val._id
                            }
                          })
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      className="btn btn-secondary pull-right"
                      onClick={this.addKennel}
                      style={{ marginTop: "20px" }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Kennels;
