import React from "react";
import moment from "moment";
import { AppointmentListTableRow } from "../../";
import { pageItemLimit } from "../../../config/general";

export default class AppointmentTable extends React.Component {

  state = {
    collapseAppts: false,
    viewPast: false
  };

  render() {
    const {
      appointmentCount,
      appointments,
      onChangePage,
      onSort,
      page,
      sort,
      tab
    } = this.props;
    const totalPages = Math.ceil(appointmentCount / pageItemLimit);

    let sortDirectionIconAlpha =
      sort.direction === "DESC" ? (
        <span className="fa fa-sort-alpha-desc" />
      ) : (
        <span className="fa fa-sort-alpha-asc" />
      );
    let sortDirectionIconNum =
      sort.direction === "DESC" ? (
        <span className="fa fa-sort-numeric-desc" />
      ) : (
        <span className="fa fa-sort-numeric-asc" />
      );
    return (
      <div>
        <div className="user-list-container user-list-header">
          <div className="user-list-item">
            <div
              className="user-list-item-content title col-lg-2 col-md-2 col-sm-2 col-xs-8"
              onClick={() => onSort("client")}
            >
              Client {sort.key === "client" ? sortDirectionIconAlpha : null}
            </div>
            <div
              className="user-list-item-content title col-lg-2 col-md-2 col-sm-2"
              onClick={() => onSort("timeRequested")}
            >
              Time <span className="hidden-sm">&nbsp;Requested</span>&nbsp;
              {sort.key === "timeRequested" ? sortDirectionIconNum : null}
            </div>

            <div
              className="user-list-item-content title col-lg-2 col-md-2 col-sm-2 hidden-xs"
              onClick={() => onSort("teamMember")}
            >
              Team Member{" "}
              {sort.key === "teamMember" ? sortDirectionIconAlpha : null}
            </div>

            <div
              className="user-list-item-content title col-lg-1 col-md-1 col-sm-1 hidden-xs"
              onClick={() => onSort("zone")}
            >
              Pets {sort.key === "zone" ? sortDirectionIconNum : null}
            </div>
            <div className="user-list-item-content title col-lg-1 col-md-1 col-sm-1 hidden-xs">
              Notes
            </div>
            <div className="user-list-item-content title col-lg-2 col-md-2 col-sm-2 hidden-xs">
              {tab === "late" ? "Late By" : "Updated At"}
            </div>
            <div className="user-list-item-content title col-lg-2 col-md-2 col-sm-2 hidden-xs">
              {tab === "concierge" ? (
                <span className="text-center">Apartment</span>
              ) : (
                <div className="showCleared">
                  <span
                    onClick={() =>
                      this.setState({ viewPast: !this.state.viewPast })
                    }
                  >
                    <i className="fa fa-clock-o" />
                  </span>
                  <i
                    className={`fa fa-caret-${
                      this.state.collapseAppts ? "down" : "up"
                    }`}
                    onClick={() =>
                      this.setState({
                        collapseAppts: !this.state.collapseAppts
                      })
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className={`user-list-container ${
            this.state.collapseAppts ? "collapseAppts" : ""
          }`}
        >
          {appointments
            ? appointments
                // Currently sorting by requested_start_time, ascending
                .filter(a => !a.clear_from_dashboard)
                // .filter(a => this.state.viewPast && tab !== 'late' ? a : !moment().isAfter(a.window_end_time))
                .sort((a, b) => moment(b.updatedAt) - moment(a.updatedAt))
                .map(appointment => {
                  return (
                    <AppointmentListTableRow
                      key={`appointment-${appointment._id}`}
                      appointment={appointment}
                      removeFromDashboard={this.props.removeFromDashboard}
                      approveAppointment={this.props.approveAppointment}
                      tab={tab}
                    />
                  );
                })
            : null}
        </div>

        {/*<div className="pagination-container">
          {page > 1 && <div className="pagination-btn-nav prev" onClick={() => onChangePage(page - 1, tab)}><span className="fa fa-chevron-left"/></div>}
          <ul>
            {renderPageNumbers()}
          </ul>
          {page < totalPages && <div className="pagination-btn-nav" onClick={() => onChangePage(page + 1, tab)}><span className="fa fa-chevron-right"/></div>}
        </div>*/}
      </div>
    );
  }
}
