import * as ActionTypes from "../../actions";
import _ from "lodash";
import moment from "moment";

const initialState = {
  appointmentList: [],
  filterList: [],
  sitters: [],
  approvalList: [],
  activities: [],
  appointmentDetail: null,
  pagination: {
    needsAttention: 1,
    scheduled: 1,
    inProgress: 1,
    completed: 1,
    late: 1
  },
  sorting: {
    needsAttention: { key: "timeRequested", direction: "DESC" },
    scheduled: { key: "timeRequested", direction: "DESC" },
    inProgress: { key: "timeRequested", direction: "DESC" },
    completed: { key: "timeRequested", direction: "DESC" },
    late: { key: "timeRequested", direction: "DESC" },
    failedMessages: { key: "timeRequested", direction: "DESC" },
    concierge: { key: "timeRequested", direction: "DESC" }
  },
  dashboardTab: "needsAttention"
};

function appointments(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case "UPDATE_SERIES_SUCCESS":
      return {
        ...state,
        appointmentDetail: {
          ...state.appointmentDetail,
          _id: payload
        }
      };
    case "CLEAR_VISITS":
      return { ...state, appointmentList: [] };
    case "ADD_ACTIVITY":
      let activities = state.activities.slice();
      let getAppt = activities.find(a => a._id === action.payload.data.id);
      if (getAppt) {
        getAppt.sitter_notes_to_admin =
          action.payload.data.sitter_notes_to_admin;
        getAppt.sitter_notes_to_client =
          action.payload.data.sitter_notes_to_client;
        return { ...state, activities: activities };
      } else {
        return {
          ...state,
          activities: [...state.activities, action.payload.data]
        };
      }
    case "ADD_APPOINTMENT":
      const appts = [...state.appointmentList];
      const appt = action.payload;
      let index;
      //New Appointment Added
      if (action.prev_id) {
        index = state.appointmentList.findIndex(a => a._id == action.prev_id);
        //Appointment was updated w/ same ID
      } else {
        index = state.appointmentList.findIndex(a => a._id === appt._id);
      }
      if (index < 0) {
        appts.push(action.payload);
        return { ...state, appointmentList: appts };
        //New series was generated assigning new ID
      } else {
        appts.splice(index, 1, appt);
      }
      return { ...state, appointmentList: appts };

    case "UPDATE_VISIT_PUSH":
      let slicer = state.appointmentList.slice();
      let findIt = slicer.find(a => a._id === payload.id);
      if (!findIt) return state;
      findIt.appointment_status = payload.appointment_status;
      findIt.appointment_status_id = payload.appointment_status.id;
      return { ...state, appointmentList: slicer };

    case "ADMIN_GET_APPOINTMENT_LIST_SUCCESS":
      return {
        ...state,
        appointmentList: payload.appointments,
        filterList: payload.appointments
      };

    case "ADMIN_GET_APPOINTMENT_DETAIL_SUCCESS":
      return {
        ...state,
        appointmentDetail: payload.appointment,
        appointmentList: state.appointmentList
      };
    case "CLEAR_APPOINTMENT_DETAIL":
      return { ...state, appointmentDetail: null };

    case "ADMIN_SET_APPOINTMENT_DASHBOARD_PAGE": {
      const { page, tab } = payload;
      return {
        ...state,
        pagination: {
          ...state.pagination,
          [tab]: page
        }
      };
    }

    case "ADMIN_SET_APPOINTMENT_DASHBOARD_TAB":
      return { ...state, dashboardTab: payload };

    case "ADMIN_SET_APPOINTMENT_DASHBOARD_SORT": {
      return {
        ...state,
        sorting: {
          ...state.sorting,
          [state.dashboardTab]: payload
        }
      };
    }
    case "ADD_APPROVAL":
      return {
        ...state,
        approvalList: state.approvalList.concat(action.payload)
      };
    case "SET_APPOINTMENT":
      const newArr = state.appointmentList.slice();
      const findAppt = newArr.findIndex(
        a => a._id === action.payload.appointment._id
      );
      if (findAppt > -1) {
        newArr.splice(findAppt, 1);
      }
      return {
        ...state,
        appointmentList: newArr
      };
    case "SET_MAP_APPOINTMENT":
      const newMapArr = state.appointmentList.slice();
      const newAppointmentCloned = _.cloneDeep(action.payload.appointment);
      const findMapAppt = newMapArr.findIndex(
        a => a._id === action.payload.appointment._id
      );
      if (findMapAppt > -1)
        newMapArr.splice(findMapAppt, 1, newAppointmentCloned);
      return {
        ...state,
        appointmentList: newMapArr
      };
    case "RESET_APPOINTMENT":
      let newArray = state.appointmentList.slice();
      let findApp = newArray.findIndex(a => a._id === action.payload._id);
      let newOne = action.payload;
      newOne.sitter = null;
      newOne.sitter_id = null;
      newOne.scheduled_start_time = null;
      if (findApp > -1) {
        newArray.splice(findApp, 1, newOne);
      } else {
        newArray.push(newOne);
      }
      return {
        ...state,
        appointmentList: newArray
      };
    case "ASSIGN_MULTIPLE":
      console.log("HERE WE GOOOOOOOOOOOOO", action.payload);
      let assignArr2 = state.appointmentList.map(findAssign => {
        let findOne = action.payload.appointments.find(
          a => a._id === findAssign._id
        );
        if (findOne) {
          findAssign.sitter = {
            _id: action.payload.sitter._id,
            full_name: action.payload.sitter.contact.full_name
          };
          if (!findAssign.scheduled_start_time)
            findAssign.scheduled_start_time = moment(
              findAssign.window_start_time
            ).format("ddd MMM D YYYY H:mm");
          findAssign.special = true;
          findAssign.projected_start_time = moment(
            findAssign.scheduled_start_time
          )
            .add(15, "minutes")
            .format("ddd MMM D YYYY H:mm");
          findAssign.idle_time = 0;
        }

        if (
          findAssign.sitter &&
          findAssign.sitter._id === action.payload.sitter._id
        ) {
          findAssign.idle_time = 0;
        }
        findAssign.ghost = false;
        return findAssign;
      });

      return {
        ...state,
        appointmentList: assignArr2
      };

    case "ASSIGN_SITTER":
      let assignArr = state.appointmentList.map(findAssign => {
        if (findAssign._id === action.payload.appointment._id) {
          findAssign.sitter = {
            _id: action.payload.sitter._id,
            full_name: action.payload.sitter.contact.full_name
          };
          if (!findAssign.scheduled_start_time)
            findAssign.scheduled_start_time = moment(
              findAssign.window_start_time
            ).format("ddd MMM D YYYY H:mm");
          findAssign.special = true;
          findAssign.projected_start_time = moment(
            findAssign.scheduled_start_time
          )
            .add(15, "minutes")
            .format("ddd MMM D YYYY H:mm");
          findAssign.idle_time = 0;
        }

        if (
          findAssign.sitter &&
          findAssign.sitter._id === action.payload.sitter._id
        ) {
          findAssign.idle_time = 0;
        }

        return findAssign;
      });

      return {
        ...state,
        appointmentList: assignArr
      };
    case "UPDATE_SINGLE_APPOINTMENT":
      let newArrayz = state.appointmentList.slice();
      const ourApp = Object.assign({}, action.payload);
      let findAppz = newArrayz.findIndex(a => a._id === ourApp._id);
      // if appt is assigned, remove from list
      if (findAppz > -1 && ourApp.sitter_id) {
        newArrayz.splice(findAppz, 1);
      } else if (findAppz > -1 && !ourApp.sitter_id) {
        // if unassigned and in the list, replace in list
        newArrayz.splice(findAppz, 1, ourApp);
      } else {
        // if not in list at all and unassigned, add to list
        newArrayz.push(ourApp);
      }
      return {
        ...state,
        appointmentList: newArrayz
      };
    case "SHOW_APPOINTMENT_PREVIEW":
      let previewArr = state.appointmentList.slice().map(a => {
        if (!action.payload) {
          a.preview = false;
          return a;
        }
        if (a._id === action.payload._id) {
          a.preview = action.payload.preview ? false : true;
        } else {
          a.preview = false;
        }
        return a;
      });
      return {
        ...state,
        appointmentList: previewArr
      };

    case "SET_SITTER_APPOINTMENTS":
      let approvalArray = state.approvalList.slice();
      let filterArray = state.filterList.slice();

      action.payload.appointments.forEach(a => {
        if (a.appointment_status._id === 1) approvalArray.push(a);
        let findIt = filterArray.findIndex(ap => ap._id === a._id);
        if (findIt > -1) filterArray.splice(findIt, 1);
      });
      return {
        ...state,
        approvalList: approvalArray,
        filterList: filterArray
      };
    case "HIGHLIGHT_SITTER":
      const apps = state.appointmentList.map(a => {
        if (a.sitter && a.sitter._id == action.payload) {
          if (a.highlight) {
            a.highlight = false;
          } else {
            a.highlight = true;
          }
        }
        return a;
      });
      return {
        ...state,
        appointmentList: apps
      };
    case "HIGHLIGHT_APPOINTMENT":
      const highlight = state.appointmentList.map(a => {
        if (a._id == action.payload) {
          if (a.highlightAppt) {
            a.highlightAppt = false;
          } else {
            a.highlightAppt = true;
          }
        }
        return a;
      });
      return {
        ...state,
        appointmentList: highlight
      };
    case "UNHIGHLIGHT":
      const unhighlight = state.appointmentList.map(a => {
        a.highlightAppt = false;
        a.highlight = false;
        return a;
      });
      return {
        ...state,
        appointmentList: unhighlight
      };
    case "GET_ACTIVITIES":
      return {
        ...state,
        activities: action.payload.activities
      };
    case "HIDE_APPT":
      let newAppts = state.appointmentList.slice();
      let findApptToHide = newAppts.find(a => a._id === action.payload);
      if (findApptToHide) findApptToHide.clear_from_dashboard = true;
      return {
        ...state,
        appointmentList: newAppts
      };
    case "HIDE_NOTES":
      let newApptsNotes = state.activities.slice();
      let findNotesToHide = newApptsNotes.find(a => a._id === action.payload);
      if (findNotesToHide) findNotesToHide.clear_notes_from_dashboard = true;
      return {
        ...state,
        activities: newApptsNotes
      };
    default:
      return state;
  }
}

export default appointments;
