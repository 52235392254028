import React, { Component } from "react";
import moment from "moment-timezone";

class ShiftMarker extends Component {
  state = {};

  render() {
    let { time, sitter, timeZone, day } = this.props;

    if (
      !this.props.sitter ||
      !this.props.sitter.shift ||
      !this.props.sitter.shift.StartTimeLocalized
    )
      return null;

    let ourTime;
    if (time === "start") {
      time = this.props.sitter.shift.StartTimeLocalized;
    } else {
      time = this.props.sitter.shift.EndTimeLocalized;
    }

    let diffFromStart = moment(time).diff(
      moment(day)
        .hour(9)
        .minute(0),
      "minutes"
    );

    let windowStyle = {
      height: `3px`,
      position: "absolute",
      top: `${Math.abs(diffFromStart * 1.5)}px`,
      width: "100%",
      left: 0,
      overflow: "visible",
      backgroundColor: "gray"
    };

    return <div style={windowStyle} className="shiftWindow"></div>;
  }
}

export default ShiftMarker;
