import React, { Component } from "react";
import axios from "../../services/network.js";
import { connect } from "react-redux";
import moment from "moment";
import {
  reviewLineItems as reviewLineItemsAction,
  getStripeCharges as getStripeChargesAction
} from "../../actions/admin/general.actions";

import { pushModal, popModal } from "../../actions/general.actions";

import {
  PaymentListItem,
  PaymentStripeListItem,
  PageTitleComponent,
  PaymentTableItem,
  ProgressView,
  OverlayModal,
  DefaultModal
} from "../../components";

class AdminPaymentPage extends Component {
  state = {
    tab: "client",
    items: [],
    showFailedOnly: false,
    selectAll: false,
    payments: [],
    toPay: [],
    runningPayments: false,
    paymentsComplete: false,
    showDeleted: false
  };

  componentWillMount = () => {
    this.loadPayments();
  };

  loadPayments = async () => {
    const { showSpinner, stopSpinner } = this.props;
    let payments = [];
    try {
      showSpinner();
      let { data } = await axios.get(
        `${process.env.REACT_APP_API_URI}/admin/reviewPayments`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );
      for (const item of data) {
        if (item.line_items) {
          payments = payments.concat(item.line_items);
        }
      }
      payments = payments.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      this.setState({
        items: data,
        payments: payments
      });
    } catch (e) {
      console.log("e: ", e);
      stopSpinner();
    }
    stopSpinner();
  };

  selectClient = listItem => {
    let selected;
    let items = this.state.items.map(item => {
      if (item?.client?._id === listItem.client._id) {
        item.selected = item.selected ? false : true;
        selected = item.selected;
      }
      return item;
    });

    let payments = [];
    let toPay = [...this.state.toPay];

    listItem.line_items.forEach(payment => {
      const temp = toPay.find(x => x._id === payment?._id);
      if (selected && !payment.deleted) {
        if (!temp) {
          payments.push(payment);
        }
      } else {
        const index = toPay.indexOf(temp);
        toPay.splice(index, 1);
      }
    });
    toPay = toPay.concat(payments);
    this.setState({ items: items, toPay: toPay });
  };

  selectPayment = (item, selected) => {
    let payments = [...this.state.toPay];
    const payment = payments.find(x => x._id === item._id);
    if (!payment && !payment.deleted && selected) {
      payments.push(item);
    } else if (!selected) {
      const index = payments.indexOf(payment);
      if (index >= 0) {
        payments.splice(index, 1);
      }
    }
    this.setState({ toPay: payments });
  };

  renderItems = () => {
    return (
      <div>
        <div>
          {this.state.items
            .filter(item => {
              return item.line_items.find(this.filterLineItem);
            })
            .map(charge => (
              <PaymentListItem
                selectClient={this.selectClient}
                key={`charge-${charge?.client?._id}`}
                listItem={charge}
                selectPayment={this.selectPayment}
                payments={this.state.toPay}
                filterLineItem={this.filterLineItem}
              />
            ))}
        </div>
      </div>
    );
  };

  renderPaymentView = () => {
    return (
      <div>
        <div>
          {this.state.payments.filter(this.filterLineItem).map(payment => {
            return (
              <PaymentTableItem
                item={payment}
                selectPayment={this.selectPayment}
                selected={this.state.toPay.includes(payment)}
              />
            );
          })}
        </div>
      </div>
    );
  };

  selectAll = () => {
    let items = [...this.state.items];
    let toPay = [...this.state.toPay];
    let itemsToSelect = [];
    if (!this.state.selectAll) {
      itemsToSelect = items
        .filter(item => {
          return item.line_items.find(this.filterLineItem);
        })
        .map(item => {
          item.selected = true;
          for (let payment of item.line_items) {
            if (!payment.deleted) {
              const temp = toPay.find(x => x._id === payment._id);
              if (!temp) {
                toPay.push(payment);
              }
            }
          }
          return item;
        });
      items = items.map(item => {
        if (item.client) {
          const temp = itemsToSelect.find(
            x => x.client._id === item.client._id
          );
          if (temp) {
            const index = items.indexOf(temp);
            temp.selected = true;
            items[index] = temp;
          }
        }
        return item;
      });
    } else {
      items = items.map(item => {
        item.selected = false;
        return item;
      });
      toPay = [];
    }
    this.setState({
      items: items,
      toPay: toPay,
      selectAll: !this.state.selectAll
    });
  };

  runPayments = async () => {
    const { toPay } = this.state;
    const { modalActions } = this.props;
    try {
      let paymentsToRun = [...this.state.items];
      paymentsToRun = paymentsToRun.filter(item => {
        if (item.line_items) {
          let items = [...item.line_items];
          items = items.filter(payment => {
            let temp = toPay.find(t => {
              return t._id === payment._id;
            });
            if (temp) {
              return payment;
            }
          });
          if (items.length > 0) {
            item.line_items = items;
            return item;
          }
        }
      });
      let data = await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/process-payments`,
        paymentsToRun,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );
      this.setState({
        runningPayments: true
      });
      const processingView = (
        <DefaultModal title="Running Payments" onDismiss={this.onDismiss}>
          <ProgressView />
        </DefaultModal>
      );

      modalActions.push(processingView);
    } catch (e) {
      console.log("the e....", e);
    }
  };

  onDismiss = () => {
    const { modalActions } = this.props;
    modalActions.pop();
    this.loadPayments();
    this.setState({
      showFailedOnly: false,
      selectAll: false,
      runningPayments: false,
      toPay: []
    });
  };

  filterLineItem = payment => {
    if (this.state.showFailedOnly) {
      if (this.state.showDeleted) {
        return (
          payment.stripe_charge_status === "failed" ||
          this.state.toPay.includes(payment)
        );
      } else {
        return (
          !payment.deleted &&
          (payment.stripe_charge_status === "failed" ||
            this.state.toPay.includes(payment))
        );
      }
    } else if (!this.state.showDeleted) {
      return !payment.deleted;
    }
    return payment;
  };

  render() {
    const { tab } = this.state;
    const { lineItems, stripeCharges, modalActions } = this.props;

    return (
      <div className="card">
        <PageTitleComponent title="Payment Feed" />

        <div className="payment-wrapper">
          <ul className="nav nav-tabs payment-tabs">
            <li>
              <a
                onClick={() => {
                  this.setState({ tab: "client" });
                }}
              >
                Clients
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  this.setState({ tab: "payment" });
                }}
              >
                Payments
              </a>
            </li>
            <button
              type="button"
              className="btn filter-btn"
              onClick={this.selectAll}
            >
              {this.state.selectAll ? "Deselect All" : "Select All"}
            </button>
            {this.state.showFailedOnly ? (
              <button
                type="button"
                className="btn filter-btn"
                onClick={() => {
                  this.setState({ showFailedOnly: false });
                }}
              >
                Show All Payments
              </button>
            ) : (
              <button
                type="button"
                className="btn filter-btn"
                onClick={() => {
                  this.setState({ showFailedOnly: true });
                }}
              >
                Show Failed Only
              </button>
            )}
            {this.state.showDeleted ? (
              <button
                className="btn filter-btn"
                type="button"
                onClick={() => {
                  this.setState({ showDeleted: false });
                }}
              >
                Hide Deleted
              </button>
            ) : (
              <button
                className="btn filter-btn"
                type="button"
                onClick={() => {
                  this.setState({ showDeleted: true });
                }}
              >
                Show Deleted
              </button>
            )}
            {this.state.runningPayments || !this.state.toPay.length ? null : (
              <button
                className="btn btn-success"
                onClick={() => this.runPayments()}
              >
                Run Selected Payments
              </button>
            )}
          </ul>
          <div>
            <div className="tableRecords">
              {this.state.tab == "client"
                ? this.renderItems()
                : this.renderPaymentView()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lineItems: state.admin.general.lineItems,
  stripeCharges: state.admin.general.stripeCharges,
  user: state.general.user
});

const mapDispatchToProps = dispatch => ({
  reviewLineItems: () => dispatch(reviewLineItemsAction()),
  getStripeCharges: () => dispatch(getStripeChargesAction()),
  showSpinner: () => dispatch({ type: "FETCH_REQUEST" }),
  stopSpinner: () => dispatch({ type: "FETCH_SUCCESS" }),
  modalActions: {
    push: component => dispatch(pushModal(component)),
    pop: () => dispatch(popModal())
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPaymentPage);
