import history from "../history";
import queryString from "query-string";
import { APIService } from "../../services";
import * as ActionTypes from "./actionTypes";
import { dispatchFetchFailure } from "../helpers";
import { showSuccessMessage } from "../general.actions";
import { getDashboardStats } from "./general.actions";
import moment from "moment";
import {
  getAllStaffWithAppointments,
  getAllStaff,
  loadStaffData
} from "./staff.actions";
import { getAllClients } from "./clients.actions";
import axios from "../../services/network.js";

const statusFilters = {
  needsAttention: ["Needs Attention"],
  scheduled: ["Scheduled"],
  inProgress: ["In Progress"],
  completed: ["Completed"],
  late: ["Late"]
};

export function removeFromDashboard(_id, state_only) {
  return function(dispatch) {
    dispatch({ type: "HIDE_APPT", payload: _id });
    if (!state_only) {
      axios.post(
        `${process.env.REACT_APP_API_URI}/admin/appointments/${_id}/clear`,
        { clear_dash: true },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken")
          }
        }
      );
    }
  };
}

export function removeNotesFromList(_id, state_only) {
  return function(dispatch) {
    dispatch({ type: "HIDE_NOTES", payload: _id });
    if (!state_only) {
      axios.post(
        `${process.env.REACT_APP_API_URI}/admin/appointments/${_id}/clear`,
        { clear_notes: true },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken")
          }
        }
      );
    }
  };
}

export function getActivities(filters) {
  const context = "adminGetActivities";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    const qs = queryString.stringify(filters);
    return APIService.admin
      .getActivities(qs)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch({
            type: "GET_ACTIVITIES",
            payload: {
              activities: response
            }
          });
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function changeCity(city, date, location) {
  return function(dispatch) {
    dispatch({
      type: "CHANGE_CITY",
      payload: city
    });
  };
}

export function getAppointments(filters = {}, sort = {}) {
  const context = "adminGetAppointmentList";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    const qs = queryString.stringify(filters);
    return APIService.admin
      .getAppointmentList(qs)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch({
            type: "ADMIN_GET_APPOINTMENT_LIST_SUCCESS",
            payload: {
              appointments: response
            }
          });

          let filtered = response.filter(
            a => a.ghost && a.sitter && a.sitter.full_name.includes("ghost")
          );
          dispatch({
            type: "ADD_GHOST_SHIFTS",
            payload: filtered
          });
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function setAppointmentDashboardPage(page, tab) {
  return {
    type: "ADMIN_SET_APPOINTMENT_DASHBOARD_PAGE",
    payload: { page, tab }
  };
}

export function getDashboardAppointments(date, city) {
  return function(dispatch) {
    const filters = {
      startDate: moment(date)
        .startOf("day")
        .toDate(),
      endDate: moment(date)
        .endOf("day")
        .toDate(),
      city: city
    };
    dispatch(getAppointments(filters));
  };
}

export function setAppointmentDashboardSort(key, direction) {
  return function(dispatch, getState) {
    dispatch({
      type: "ADMIN_SET_APPOINTMENT_DASHBOARD_SORT",
      payload: { key, direction }
    });
  };
}

export function getAppointmentDetail(appointmentId) {
  const context = "adminGetAppointmentDetail";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .getAppointmentDetail(appointmentId)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch({
            type: "ADMIN_GET_APPOINTMENT_DETAIL_SUCCESS",
            payload: {
              appointment: response
            }
          });
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function scheduleAppointment(appointmentId, model) {
  const context = "adminScheduleAppointment";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .scheduleAppointment(appointmentId, model)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
        }, // do not return
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function approveAppointment(appointmentId, model) {
  const context = "adminApproveAppointment";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return (
      APIService.admin
        .approveAppointment(appointmentId, model)
        .then(
          response => {
            dispatch({ type: "FETCH_SUCCESS", context });
          }, // do not return
          error => error.response.json() // sends errorRes to next then()
        )
        // this only gets a response if prior then() errors out
        .then(errorRes => dispatchFetchFailure(errorRes, context, dispatch)) // dispatches only if there is a response
        .catch(error =>
          dispatchFetchFailure("network", context, dispatch, error)
        )
    );
  };
}

export function rejectAppointment(appointmentId, model) {
  const context = "adminRejectAppointment";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .rejectAppointment(appointmentId, model)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
        } // do not return
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function cancelAppointment(appointmentId, model) {
  const context = "adminCancelAppointment";
  let successMessage = "Appointment canceled successfully.";
  if (model.cancel_series) {
    successMessage = "Recurring series of appointments canceled successfully.";
  }

  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .cancelAppointment(appointmentId, model)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch(showSuccessMessage(successMessage, context));
          dispatch(getAppointmentDetail(appointmentId));
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function createAppointment(model) {
  const context = "adminCreateAppointment";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .createAppointment(model)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          history.push(`/appointments/${response.appointments[0]._id}`);
        }, // do not return
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function approveAllAppointments(appointments) {
  const date =
    appointments[0].scheduled_start_time || appointments[0].window_start_time;
  const context = "updateMultipleAppointments";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .approveMultipleAppointments(appointments)
      .then(res => {
        dispatch(
          getAllStaffWithAppointments({
            startDate: moment(date)
              .startOf("day")
              .toDate(),
            endDate: moment(date)
              .endOf("day")
              .toDate()
          })
        );
        dispatch({ type: "FETCH_SUCCESS", context });
      })
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function updateMultipleAppointments(appointments) {
  const date =
    appointments[0].scheduled_start_time || appointments[0].window_start_time;
  const context = "updateMultipleAppointments";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .updateMultipleAppointments(appointments)
      .then(res => {
        dispatch(
          getAllStaffWithAppointments({
            startDate: moment(date)
              .startOf("day")
              .toDate(),
            endDate: moment(date)
              .endOf("day")
              .toDate()
          })
        );
        dispatch({ type: "FETCH_SUCCESS", context });
      })
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function saveMapAppointment(model, draft) {
  const context = "adminUpdateAppointment";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .saveMapAppointment(model, draft)
      .then(res => {
        dispatch({ type: "FETCH_SUCCESS", context });
      })
      .then(resultz => dispatchFetchFailure(resultz, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function updateAppointment(appointmentId, model) {
  const context = "adminUpdateAppointment";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return (
      APIService.admin
        .updateAppointment(appointmentId, model)
        .then(res => {
          dispatch({ type: "FETCH_SUCCESS", context });
          if (res && res.new_appt) {
            //UPDATE REDUX STATE
            dispatch({ type: "UPDATE_SERIES_SUCCESS", payload: res.new_appt });
          } else if (res && res.id) {
            //FETCH NEW APPT AFTER UPDATE WHEN NEW ID IS RETURNED
            APIService.admin
              .getAppointmentDetail(res.id)
              .then(
                response => {
                  dispatch({ type: "FETCH_SUCCESS", context });
                  dispatch({
                    type: "ADMIN_GET_APPOINTMENT_DETAIL_SUCCESS",
                    payload: {
                      appointment: response
                    }
                  });
                },
                error => error.response.json()
              )
              .then(res => dispatchFetchFailure(res, context, dispatch))
              .catch(error =>
                dispatchFetchFailure("network", context, dispatch, error)
              );
          }
        })
        //.then(resultz => dispatchFetchFailure(resultz, context, dispatch))
        .catch(error =>
          dispatchFetchFailure("network", context, dispatch, error)
        )
    );
  };
}

export function showAppointmentPreview(app) {
  return {
    type: "SHOW_APPOINTMENT_PREVIEW",
    payload: app
  };
}

export function highlightSitter(id) {
  if (id) {
    return {
      type: "HIGHLIGHT_SITTER",
      payload: id
    };
  } else {
    return {
      type: "UNHIGHLIGHT"
    };
  }
}

export function highlightAppointmentOnMap(id) {
  if (id) {
    return {
      type: "HIGHLIGHT_APPOINTMENT",
      payload: id
    };
  } else {
    return {
      type: "UNHIGHLIGHT"
    };
  }
}
