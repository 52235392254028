import { APIService } from "../../services";
import history from "../history";
import * as ActionTypes from "./actionTypes";
import { dispatchFetchFailure } from "../helpers";
import queryString from "query-string";
import { showFormErrorMessage, showSuccessMessage } from "../general.actions";

export function getAllClients(filters) {
  const context = "getAllClients";
  return function(dispatch) {
    const qs = queryString.stringify(filters);
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .getAllClients(qs)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch({
            type: ActionTypes.GET_ALL_CLIENTS_SUCCESS,
            payload: {
              clients: response
            }
          });
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function getClient(clientId) {
  const context = "getClient";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .getClient(clientId)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch({
            type: ActionTypes.GET_CLIENT_SUCCESS,
            payload: {
              client: response
            }
          });
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function createClient(model) {
  const context = "createClient";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .createClient(model)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch({
            type: ActionTypes.CREATE_CLIENT_SUCCESS,
            payload: model
          });
          history.push(
            response.client ? `/client/${response.client._id}` : "/clientlist"
          );
        },
        error => error.data
      )
      .then(res => {
        if (
          res &&
          res.error &&
          res.error.status === 409 &&
          res.error.code === 2001
        ) {
          res.message = "Oops! A user with that email address already exists.";
          res.email = true;
        }
        console.log(res);
        return dispatchFetchFailure(res, context, dispatch);
      })
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function updateClient(clientId, model) {
  const context = "updateClient";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .updateClient(clientId, model)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch({ type: ActionTypes.UPDATE_CLIENT_SUCCESS });
          dispatch(
            showSuccessMessage("Successfully updated client", "success")
          );
        },
        error => error.data
      )
      .then(res => {
        dispatchFetchFailure(res, context, dispatch);
      });
  };
}

export function clientActivation(clientId, active) {
  const context = "clientActivation";
  let successMessage =
    "Client successfully deactivated and any outstanding appointments canceled.";
  if (active) {
    successMessage = "Client successfully activated.";
  }
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .clientActivation(clientId, { active })
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch(getClient(clientId)); // update the client information
          dispatch(showSuccessMessage(successMessage, context));
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function getPetsForAppointment(clientId, appointmentTypeId) {
  const context = "getPets";
  return function(dispatch) {
    if (appointmentTypeId === 11) {
      return dispatch({ type: "PLAYCARE_PETS", context });
    } else {
      return dispatch({ type: "RESET_PETS", context });
    }
  };
}

// TODO: If not in use, remove this, the corresponding reducer state, and tie up on the backend too.
export function getPet(petId) {
  const context = "getPet";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .getPet(petId)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch({
            type: ActionTypes.GET_PET_SUCCESS,
            payload: {
              pet: response
            }
          });
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function addPet(clientId, model) {
  const context = "addPetToClient";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .addPet(clientId, model)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch({ type: ActionTypes.ADD_PET_TO_CLIENT_SUCCESS });
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function updatePet(petId, model) {
  const context = "updatePet";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .updatePet(petId, model)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch({ type: ActionTypes.UPDATE_PET_SUCCESS });
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function clientAddPet(clientId, model) {
  const context = "addPetToClient";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.client
      .clientAddPet(clientId, model)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch({ type: ActionTypes.ADD_PET_TO_CLIENT_SUCCESS });
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function clientUpdatePet(petId, model) {
  const context = "updatePet";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.client
      .clientUpdatePet(petId, model)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch({ type: ActionTypes.UPDATE_PET_SUCCESS });
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function deactivatePet(petId, clientId) {
  const context = "deactivatePet";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .deactivatePet(petId)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch(getClient(clientId)); // update the client information
          dispatch(
            showSuccessMessage("Pet successfully deactivated.", context)
          );
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function setPaymentMethod(clientId, model) {
  const context = "setPaymentMethod";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .setPaymentMethod(clientId, model)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch(getClient(clientId)); // update the client information
          dispatch(
            showSuccessMessage("Payment method successfully updated.", context)
          );
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function processDelinquentClientBalance(model) {
  const context = "processDelinquentClientBalance";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .retryClientCharge(model)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch(getClient(model.client_id)); // update the client information

          const failedCharge = response.find(
            charge => charge.stripe_charge_status !== "succeeded"
          );
          if (!response || !response.length) {
            dispatch(
              showFormErrorMessage(
                {
                  customMessageText: `No Charges Processed. Please try again.`
                },
                context
              )
            );
          } else if (failedCharge) {
            dispatch(
              showFormErrorMessage(
                {
                  customMessageText: `Charge Failed: ${failedCharge.stripe_error_message}`
                },
                context
              )
            );
          } else {
            dispatch(
              showSuccessMessage("Payment successfully processed.", context)
            );
          }
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}
