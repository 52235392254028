import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import validate from "./formValidator";
import history from "../../../../actions/history";
import {
  DateTimeField,
  DropdownListField,
  FieldColumnWrapper,
  FormSection,
  FormSubHeader,
  InputField,
  MultiselectField,
  TextAreaField,
  ProfileImageField,
  CheckboxField
} from "../../../shared/formComponents";
import moment from "moment";

let breakTimes = [];

for (
  var m = moment()
    .hour(10)
    .minute(0);
  m.isBefore(
    moment()
      .hour(18)
      .minute(0)
  );
  m.add(15, "minutes")
) {
  breakTimes.push(m.format("HH:mm"));
}

class StaffForm extends Component {

  cancelForm = e => {
    e.preventDefault();
    history.push("/");
  };

  render() {
    const markets = this.props.markets.filter(m => m._id !== "ALL");
    const {
      editExisting,
      formConstants,
      handleActivation,
      handlePasswordReset,
      staffMember
    } = this.props;
    let cancelButtonClass = "col-md-2 col-sm-4 col-xs-4 button-column";
    if (!editExisting || (editExisting && !staffMember.active)) {
      cancelButtonClass += " col-md-offset-8";
    }

    return (
      <form onSubmit={this.props.handleSubmit}>
        {editExisting && !staffMember.active ? (
          <FormSection title="Team Member Activation">
            <div className="row">
              <div className="col-sm-4">
                <p className="red-text">Team Member Account Deactivated</p>
                <button
                  className="btn btn-block btn-primary"
                  onClick={e => handleActivation(e, true)}
                >
                  Reactivate Team Member
                </button>
              </div>
            </div>
          </FormSection>
        ) : null}

        <FormSection title="Personal Information">
          <div className="row">
            <FieldColumnWrapper column="4">
              <Field
                name="firstName"
                component={InputField}
                placeholder="First Name"
                type="text"
                required
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                name="lastName"
                component={InputField}
                type="text"
                placeholder="Last Name"
                required
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                name="gender"
                component={DropdownListField}
                data={["Female", "Male"]}
                type="text"
                placeholder="Gender"
                required
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                name="market_id"
                data={markets}
                valueField="_id" // DropdownList: If data is obj, determines which property is the id
                textField="city" // DropdownList: If data is obj, determines which property gets displayed
                parse={val => val._id} // reduxForm: parse decides what goes into the state in the form store
                placeholder="Market"
                required
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                name="deputy_id"
                component={InputField}
                placeholder="Deputy ID"
                type="text"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                name="dob"
                component={InputField}
                type="text"
                placeholder="Date of Birth (YYYY/MM/DD)"
                required
              />
            </FieldColumnWrapper>
          </div>

          <FormSubHeader>Contact</FormSubHeader>
          <div className="row">
            <FieldColumnWrapper column="4">
              <Field
                name="email"
                component={InputField}
                type="email"
                placeholder="Email Address"
                required
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                name="mobile"
                component={InputField}
                type="phone"
                placeholder="Mobile Phone"
                required
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                name="instagram"
                component={InputField}
                type="text"
                placeholder="Instagram"
              />
            </FieldColumnWrapper>
          </div>

          <FormSubHeader>Home Address</FormSubHeader>
          <div className="row">
            <FieldColumnWrapper column="4">
              <Field
                name="address"
                component={InputField}
                type="text"
                placeholder="Address"
                required
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                name="address2"
                component={InputField}
                type="text"
                placeholder="Address 2"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                name="city"
                component={InputField}
                type="text"
                placeholder="City"
                required
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                name="state"
                busy={false} // DropdownList: Display spinner
                data={formConstants.states} // DropdownList: Array of strings or objects or other primitives to populate
                valueField="id" // DropdownList: If data is obj, determines which property is the id
                textField="name" // DropdownList: If data is obj, determines which property gets displayed
                filter="contains" // DropdownList: If you want to filter, include this with string indicating filter type
                parse={(val, name) => val.id} // reduxForm: parse decides what goes into the state in the form store
                placeholder="State"
                required
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                name="zip"
                component={InputField}
                type="text"
                placeholder="Zip Code"
                required
              />
            </FieldColumnWrapper>
            <FieldColumnWrapper column="4">
              <Field
                component={InputField}
                name="latitude"
                type="text"
                placeholder="Latitude"
              />
            </FieldColumnWrapper>
            <FieldColumnWrapper column="4">
              <Field
                component={InputField}
                name="longitude"
                type="text"
                placeholder="Longitude"
              />
            </FieldColumnWrapper>
          </div>

          <FormSubHeader>Secondary Address</FormSubHeader>
          <div className="row">
            <FieldColumnWrapper column="4">
              <Field
                name="secondary_address"
                component={InputField}
                type="text"
                placeholder="Secondary Address"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                name="secondary_address2"
                component={InputField}
                type="text"
                placeholder="Secondary Address 2"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                name="secondary_city"
                component={InputField}
                type="text"
                placeholder="City"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                name="secondary_state"
                busy={false} // DropdownList: Display spinner
                data={formConstants.states} // DropdownList: Array of strings or objects or other primitives to populate
                valueField="id" // DropdownList: If data is obj, determines which property is the id
                textField="name" // DropdownList: If data is obj, determines which property gets displayed
                filter="contains" // DropdownList: If you want to filter, include this with string indicating filter type
                parse={(val, name) => val.id} // reduxForm: parse decides what goes into the state in the form store
                placeholder="State"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                name="secondary_zip"
                component={InputField}
                type="text"
                placeholder="Zip Code"
              />
            </FieldColumnWrapper>
          </div>

          <FormSubHeader>Secondary Starting Point</FormSubHeader>
          <div className="row">
            <FieldColumnWrapper column="4">
              <Field
                component={InputField}
                name="secondary_latitude"
                type="text"
                placeholder="Secondary Latitude"
              />
            </FieldColumnWrapper>
            <FieldColumnWrapper column="4">
              <Field
                component={InputField}
                name="secondary_longitude"
                type="text"
                placeholder="Secondary Longitude"
              />
            </FieldColumnWrapper>
            <FieldColumnWrapper column="4">
              <Field
                name="use_secondary_for_routing"
                component={DropdownListField}
                data={[
                  { text: "Yes", value: true },
                  { text: "No", value: false }
                ]}
                textField="text"
                valueField="value"
                placeholder="Use For Routing?"
                parse={val => val.value}
              />
            </FieldColumnWrapper>
          </div>

          <FormSubHeader>Emergency Contact</FormSubHeader>
          <div className="row">
            <FieldColumnWrapper column="4">
              <Field
                name="emergencycontactfirstname"
                component={InputField}
                type="text"
                placeholder="First Name"
                required
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                name="emergencycontactlastname"
                component={InputField}
                type="text"
                placeholder="Last Name"
                required
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                name="emergencycontactphone"
                component={InputField}
                type="phone"
                placeholder="Phone"
                required
              />
            </FieldColumnWrapper>
          </div>
        </FormSection>

        <FormSection title="Staffing Information">
          <div className="row">
            <FieldColumnWrapper column="4">
              <Field
                component={DateTimeField}
                name="hireDate"
                time={false}
                dateFormat="MMM. D, Y"
                placeholder="Hire Date"
                required
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={MultiselectField}
                name="zones"
                data={formConstants.zones} // Multiselect: Array of strings or objects or other primitives to populate
                textField="zone_name" // Multiselect: If data is obj, determines which property gets displayed
                parse={(val, name) => val.map(v => v)} // reduxForm: parse decides what goes into the state in the form store
                placeholder="Zones"
                required
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              {/*<Field
                component={MultiselectField}
                name="roles"
                data={["admin", "oa", "sitter", "offshore", "facility_admin"]} // Multiselect: Array of strings or objects or other primitives to populate
                // Multiselect: If data is obj, determines which property is the id
                // Multiselect: If data is obj, determines which property gets displayed
                parse={(val, name) => val.map(v => v)} // reduxForm: parse decides what goes into the state in the form store
                placeholder="Roles"
                required
              />*/}
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={MultiselectField}
                name="trainings_completed"
                data={[
                  "Basic Training",
                  "Shadow Training",
                  "Consultation Training",
                  "OA Training",
                  "Event Training"
                ]} // Multiselect: Array of strings or objects or other primitives to populate
                // Multiselect: If data is obj, determines which property is the id
                // Multiselect: If data is obj, determines which property gets displayed
                placeholder="Trainings Completed"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                name="hire_source"
                data={[
                  "Charlotte Agenda",
                  "Indeed",
                  "Glassdoor",
                  "Craigslist",
                  "Snagajob",
                  "Student Job Post",
                  "Client Referral",
                  "Employee Referral"
                ]} // Multiselect: Array of strings or objects or other primitives to populate
                // Multiselect: If data is obj, determines which property is the id
                // Multiselect: If data is obj, determines which property gets displayed
                placeholder="Hire Source"
              />
            </FieldColumnWrapper>
            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                name="min_visits"
                placeholder="Minimum Visits (per shift)"
                data={["default", 0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
              />
            </FieldColumnWrapper>
            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                name="speed"
                placeholder="Speed"
                data={["slow", "normal", "fast", "faster"]}
              />
            </FieldColumnWrapper>
            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                name="break_start"
                placeholder="Break Start Time"
                data={breakTimes}
              />
            </FieldColumnWrapper>
            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                name="break_end"
                placeholder="Break End Time"
                data={breakTimes}
              />
            </FieldColumnWrapper>
            <FieldColumnWrapper column="4">
              <Field
                component={CheckboxField}
                name="use_break"
                label="Use Break"
                type="checkbox"
              />
            </FieldColumnWrapper>
          </div>

          <FormSubHeader>Payroll</FormSubHeader>
          <div className="row">
            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                name="stafftype"
                busy={false} // DropdownList: Display spinner
                data={formConstants.staffTypes} // DropdownList: Array of strings or objects or other primitives to populate
                valueField="name" // DropdownList: If data is obj, determines which property is the id
                textField="name" // DropdownList: If data is obj, determines which property gets displayed
                // filter="contains" // DropdownList: If you want to filter, include this with string indicating filter type
                parse={(val, name) => val.name} // reduxForm: parse decides what goes into the state in the form store
                placeholder="Staff Type"
                required
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                name="paytype"
                busy={false} // DropdownList: Display spinner
                data={formConstants.payTypes} // DropdownList: Array of strings or objects or other primitives to populate
                valueField="name" // DropdownList: If data is obj, determines which property is the id
                textField="name" // DropdownList: If data is obj, determines which property gets displayed
                // filter="contains" // DropdownList: If you want to filter, include this with string indicating filter type
                parse={(val, name) => val.name} // reduxForm: parse decides what goes into the state in the form store
                placeholder="Pay Type"
                required
              />
            </FieldColumnWrapper>
            <FieldColumnWrapper column="4">
              <Field
                component={CheckboxField}
                name="full_time"
                label="Full Time"
                type="checkbox"
              />
            </FieldColumnWrapper>
          </div>

          <FormSubHeader>Transportation</FormSubHeader>
          <div className="row">
            <FieldColumnWrapper column="4">
              <Field
                name="driverslicense"
                component={InputField}
                type="text"
                placeholder="Drivers License"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                name="licenseplate"
                component={InputField}
                type="text"
                placeholder="License Plate"
              />
            </FieldColumnWrapper>
          </div>
        </FormSection>

        <FormSection title="Notes">
          <div className="row">
            <div className="col col-sm-12">
              <Field component={TextAreaField} name="notes" />
            </div>
          </div>
        </FormSection>

        <br />

        <FormSection>
          <div className="row">
            {editExisting && staffMember.active ? (
              <div className="col-md-2 col-sm-4 col-xs-4 button-column">
                <button
                  className="btn btn-block btn-secondary"
                  onClick={e => handleActivation(e, false)}
                >
                  Deactivate
                </button>
              </div>
            ) : null}

            {editExisting && staffMember.active ? (
              <div className="col-md-2 col-sm-4 col-xs-4 button-column">
                <button
                  className="btn btn-block btn-secondary"
                  onClick={handlePasswordReset}
                >
                  Reset Password
                </button>
              </div>
            ) : null}

            <div className={cancelButtonClass}>
              <button
                className="btn btn-block btn-secondary"
                onClick={this.cancelForm}
              >
                Cancel
              </button>
            </div>
            <div className="col-md-2 col-sm-4 col-xs-4 button-column">
              <input
                type="submit"
                className="btn btn-block btn-primary"
                value="Continue"
              />
            </div>
          </div>
        </FormSection>
      </form>
    );
  }
}

export default reduxForm({ form: "addStaff", validate })(StaffForm);
