import React, { Component } from "react";
import {
  auth,
  resetPassword,
  getUser,
  login
} from "../../services/firebaseHelpers";
import {
  getClientUserInfo as getUserInfo,
} from "../../actions/client/user.actions";
import history from "../../actions/history";
import { connect } from "react-redux";
import jwt from "jwt-decode";
import logo from "../../images/skipper-logo-nav2.svg";
import { withRouter } from "react-router-dom";
import axios from "../../services/network.js";

function setErrorMsg(error) {
  return {
    loginMessage: error
  };
}

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

class SetPassword extends Component {
  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  state = {
    loginMessage: null,
    prevPath: "/",
    user: {},
    pw: null,
    success: false
  };

  async componentWillMount() {
    try {
      const activation_id = getParameterByName("id");
      const user = await axios.get(
        `${process.env.REACT_APP_API_URI}/admin/adminActivation/${activation_id}`
      );
      this.setState({ user: user.data });
    } catch (e) {
      // history.push("/login");
      this.setState(
        setErrorMsg("Error retrieving your account. Please contact your administrator.")
      );

    }
  }

  getUserInfo = () => {
    return getUser()
      .getIdToken(true)
      .then(token => {
        localStorage.setItem("accessToken", token);
        this.props.getUserInfo().then(res => {
          history.push("/");
        });
      });
  };

  async handleSubmit(e) {
    e.preventDefault();
    try {
      const activation_id = getParameterByName("id");
      const body = {
        activation_id: activation_id,
        password: this.state.pw
      };
      this.props.fetchRequest();
      const res = await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/setPassword`,
        body
      );
      this.props.fetchSuccess();
      this.setState({ success: true });
    } catch (e) {
      this.props.fetchSuccess();
      this.setState(
        setErrorMsg("Error setting your password. Please try again.")
      );
    }
  }

  render() {
    return (
      <div className="login-bg">
        <div className="col-sm-6 col-sm-offset-3">
          <div className="login-img">
            <img src={logo} className="login-logo" />
          </div>
          <h3 className="set-pw-banner">
            Welcome to your new Skipper account! Please create a password.
          </h3>
          {!this.state.success ? (
            <form onSubmit={this.handleSubmit}>
              <div className="form-group">
                <label>Email</label>
                <input
                  className="form-control"
                  ref={email => (this.email = email)}
                  readOnly
                  value={
                    this.state.user && this.state.user.email
                      ? this.state.user.email
                      : "Email"
                  }
                  placeholder="Email"
                />
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  onChange={e => this.setState({ pw: e.target.value })}
                />
              </div>
              {this.state.loginMessage && (
                <div className="alert alert-danger" role="alert">
                  <span
                    className="glyphicon glyphicon-exclamation-sign"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Error:</span>
                  &nbsp;{this.state.loginMessage}
                </div>
              )}
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          ) : (
            <h3>Success! You may now use your password to log in.</h3>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.general.user
});

const mapDispatchToProps = dispatch => ({
  fetchRequest: () => dispatch({ type: "FETCH_REQUEST", context: null }),
  fetchSuccess: () => dispatch({ type: "FETCH_SUCCESS", context: null }),
  getUserInfo: () => dispatch(getUserInfo()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SetPassword)
);
