import React from "react";
import moment from "moment-timezone";
import { AppointmentListTableRow } from "../../";
import { pageItemLimit } from "../../config/general";
import { Link } from "react-router-dom";

export default class ClientAppointments extends React.Component {

  getPetNames = appointment => {
    if (!appointment.pets || !appointment.pets.length) return null;
    if (appointment.pets.length === 1) return appointment.pets[0];
    return appointment.pets.join(", ");
  };

  render() {
    const {
      appointmentCount,
      appointments,
      onChangePage,
      onSort,
      page,
      sort,
      tab
    } = this.props;
    const totalPages = Math.ceil(appointmentCount / pageItemLimit);

    return (
      <div className="client-appointment-list">
        <div className="user-list-container">
          {appointments && appointments.length ? (
            appointments
              // Currently sorting by requested_start_time, ascending
              .sort(
                (a, b) =>
                  moment(a.window_start_time) - moment(b.window_start_time)
              )
              .map(appointment => {
                return (
                  <Link
                    to={`/appointments/${appointment._id}`}
                    key={`apptKey${appointment._id}`}
                  >
                    <div className="appointment-row row">
                      <div className="col-xs-2">
                        {moment(appointment.window_start_time).format(
                          "MM/DD/YY"
                        )}
                      </div>
                      <div className="col-xs-2">
                        {appointment.appointment_status.name}
                      </div>
                      <div className="col-xs-2">{`${moment(
                        appointment.window_start_time
                      ).format("h:mm a")} - ${moment(
                        appointment.window_end_time
                      ).format("h:mm a")}`}</div>
                      <div className="col-xs-2">
                        {appointment.appointment_type.name}
                      </div>
                      <div className="col-xs-2">
                        {appointment.sitter && appointment.sitter._id
                          ? appointment.sitter.full_name
                          : "Unassigned"}
                      </div>
                      <div className="col-xs-2">
                        {this.getPetNames(appointment)}
                      </div>
                    </div>
                  </Link>
                );
              })
          ) : (
            <div>Nothing to see here.</div>
          )}
        </div>
      </div>
    );
  }
}
