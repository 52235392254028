import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import moment from "moment-timezone";
import { parseTimeInput } from "../../../../helpers";
import {
  CheckboxField,
  ComboboxField,
  DateTimeField,
  FieldColumnWrapper
} from "../../../shared/formComponents";

class ApproveAppointmentForm extends Component {

  render() {
    const {
      apply_to_recurring,
      appointment,
      handleSubmit,
      requestedStartDate,
      scheduled_time,
      staffList
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="row">
          <FieldColumnWrapper column="6">
            <Field
              component={ComboboxField}
              name="sitter_id"
              busy={staffList === null} // Combobox: Display spinner
              data={staffList || []} // Combobox: Array of strings or objects or other primitives to populate
              valueField="id" // Combobox: If data is obj, determines which property is the id
              textField="fullName" // Combobox: If data is obj, determines which property gets displayed
              filter="contains" // Combobox: If you want to filter, include this with string indicating filter type
              suggest // Combobox: Should it fill in values for you?
              parse={(val, name) => val.id} // reduxForm: parse decides what goes into the state in the form store
              placeholder="Team Member"
            />
          </FieldColumnWrapper>

          <FieldColumnWrapper column="6">
            <Field
              component={DateTimeField}
              name="scheduled_time"
              pickerType="time"
              calendar={false}
              dateFormat="h:mm a"
              parseInput={time => parseTimeInput(time, requestedStartDate)}
              placeholder="Scheduled Time"
            />
          </FieldColumnWrapper>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <Field
              component={CheckboxField}
              name="apply_to_recurring"
              type="checkbox"
              label="Apply to all appointments in this recurring series?"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <br />
            <p>
              {apply_to_recurring ? (
                <span>
                  This appointment will be scheduled for{" "}
                  <strong>{moment(scheduled_time).format("h:mm a")}</strong> on
                  the date of each appointment in the series.{" "}
                </span>
              ) : (
                <span>
                  This appointment will be scheduled on the requested date of{" "}
                  <strong>
                    {moment(appointment.window_start_time).format(
                      "dddd, MMMM D, YYYY"
                    )}
                  </strong>
                  .{" "}
                </span>
              )}
              {appointment.appointment_status.name === "Pending" ||
              appointment.appointment_status.name === "Change Requested" ? (
                <span>
                  Because this appointment is currently in a <em>Pending</em> or{" "}
                  <em>Change Requested</em> status,{" "}
                  <strong>the client will receive a notification</strong> that
                  the appointment has been approved.
                </span>
              ) : (
                <span>
                  Because this appointment is not <em>Pending</em> or{" "}
                  <em>Change Requested</em>, the client{" "}
                  <strong>will not</strong> receive a notification. Notify them
                  manually if they need to know about this change.
                </span>
              )}
            </p>
          </div>
        </div>
      </form>
    );
  }
}

const validate = values => {
  const errors = {};
  if (!values.scheduledTime) errors.scheduledTime = true;
  if (!values.assignedSitter) errors.assignedSitter = true;
  return errors;
};

ApproveAppointmentForm = reduxForm({ form: "approveAppointment", validate })(
  ApproveAppointmentForm
);

// connect using react-redux and reduxForm's formValueSelector to get form values
const selector = formValueSelector("approveAppointment");
const mapStateToProps = state => ({
  apply_to_recurring: selector(state, "apply_to_recurring"),
  scheduled_time: selector(state, "scheduled_time")
});

export default connect(mapStateToProps)(ApproveAppointmentForm);
