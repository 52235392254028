import React, { Component } from "react";
import { ReferralRowItem } from "../../../components";

class ReferralTable extends Component {
  renderReferrals = () => {
    return this.props.referrals.map(r => {
      return (
        <ReferralRowItem
          client={r.referral_client}
          created_at={r.created_at}
          num_visits={r.appointments.length}
          referral_completed={r.referral_completed}
          referrer_completed={r.referrer_completed}
          key={r._id}
        />
      );
    });
  };

  render() {
    return (
      <div className="referral-container">
        <div className="referral-table">
          <div className="row table-headers">
            <div className="col-xs-2 header">Client</div>
            <div className="col-xs-2 header">Referred On</div>
            <div className="col-xs-2 header">Consultation Status</div>
            <div className="col-xs-2 header"># Visits</div>
            <div className="col-xs-2 header">Referral Complete</div>
            <div className="col-xs-2 header">Referrer Complete</div>
          </div>
          {this.renderReferrals()}
        </div>
      </div>
    );
  }
}

export default ReferralTable;
