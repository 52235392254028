import moment from "moment";

const getUniqueOptions = options => {
  const uniqueOptions = new Set(options.map(a => a.data.id));
  return Array.from(uniqueOptions).map(id => {
    return options.find(item => item.data.id === id);
  });
};

export const getFilterOptions = (appointments, dimensions) => {
  // APPT STATUS OPTIONS
  let apptStatusOptions = appointments.map(a => ({
    text: `Appointment Status: ${a.appointment_status.name}`,
    value: `Appointment Status ${a.appointment_status.id}`,
    data: {
      id: a.appointment_status.id,
      type: "Appointment Status"
    }
  }));

  // CLIENT OPTIONS
  let clientOptions = appointments.map(a => ({
    text: `Client: ${a.contact.full_name}`,
    value: `Client ${a.contact.household_id}`,
    data: {
      id: a.contact.household_id,
      type: "Client"
    }
  }));

  let timeMorning = [
    {
      text: `Morning (9-11)`,
      value: "Morning",
      data: {
        id: 443,
        type: "Time"
      }
    },
    {
      text: `Midday (11-2)`,
      value: "Midday",
      data: {
        id: 23234,
        type: "Time"
      }
    },
    {
      text: `Afternoon/Evening (2-6)`,
      value: "Afternoon/Evening",
      data: {
        id: 9889,
        type: "Time"
      }
    }
  ];

  let notCanceled = [
    {
      text: "Not Canceled",
      value: "Not Canceled",
      data: {
        id: 229,
        type: "Not Canceled"
      }
    }
  ];

  // ZONE OPTIONS
  // let zoneOptions = appointments
  //   .map(a => ({
  //     text: `Zone ${a.home.zone.id}: ${a.home.zone.name}`,
  //     value: `Zone ${a.home.zone.id}`,
  //     data: {
  //       id: a.home.zone.id,
  //       type: 'Zone',
  //     },
  //   }));

  // PET OPTIONS
  // let petOptions = appointments
  //   .map(a => a.appointment_pets) // get pets from appointments
  //   .reduce((a, b) => a.concat(b), []) // flatten the pets array
  //   .map(pet => ({
  //     text: `Pet: ${pet.name}`,
  //     value: `Pet ${pet.id}`,
  //     data: {
  //       id: pet.id,
  //       type: 'Pet',
  //     },
  //   }));
  //
  // // PET CLASSES
  // let petClassOptions = appointments
  //   .map(a => a.appointment_pets)
  //   .reduce((a, b) => a.concat(b), [])
  //   .map(pet => ({
  //     text: `Pet Class: ${pet.pet_class}`,
  //     value: `Pet Class ${pet.pet_class}`,
  //     data: {
  //       id: pet.pet_class,
  //       type: 'Pet Class',
  //     },
  //   }));

  // HOME CLASSES
  let homeClassOptions = appointments
    .map(a => a.home.home_class)
    .map(homeClass => ({
      text: `Home Class: ${homeClass}`,
      value: `Home Class ${homeClass}`,
      data: {
        id: homeClass,
        type: "Home Class"
      }
    }));

  // SITTER OPTIONS
  let sitterOptions = appointments
    .filter(a => a.sitter && a.sitter._id)
    .map(a => ({
      text: `Team Member: ${a.sitter.full_name}`,
      value: `Team Member ${a.sitter._id}`,
      data: {
        id: a.sitter._id,
        type: "Team Member"
      }
    }));

  // add option for unassigned sitter
  sitterOptions.push({
    text: "Team Member: Unassigned",
    value: "Team Member: Unassigned",
    data: {
      id: null,
      type: "Team Member"
    }
  });

  // Recurrence Options
  const recurrenceOptions = ["Not Recurring", "Weekly", "Monthly"].map(
    type => ({
      text: `Recurrence: ${type}`,
      value: `Recurrence Type ${type}`,
      data: {
        id: type,
        type: "Recurrence Type"
      }
    })
  );

  const dimensionToOptionsMapping = {
    "Appointment Status": getUniqueOptions(apptStatusOptions),
    "Time Period": getUniqueOptions(timeMorning),
    Client: getUniqueOptions(clientOptions),
    "Not Canceled": getUniqueOptions(notCanceled),
    // 'Zone': getUniqueOptions(zoneOptions),
    // 'Pet': getUniqueOptions(petOptions),
    // 'Pet Class': getUniqueOptions(petClassOptions),
    "Home Class": getUniqueOptions(homeClassOptions),
    "Team Member": getUniqueOptions(sitterOptions),
    "Recurrence Type": recurrenceOptions
  };

  const options = [];
  dimensions.forEach(dimension => {
    if (dimensionToOptionsMapping[dimension])
      options.push(...dimensionToOptionsMapping[dimension]);
  });

  return options;
};

export const applyMultiFilters = (appointments, calendarFilters) => {
  let filteredAppointments = appointments;
  if (!calendarFilters || !calendarFilters.length) return appointments;
  // Filter for Appointment Status
  const statusFilters = calendarFilters
    .filter(item => item.data.type === "Appointment Status")
    .map(item => item.data.id);

  if (statusFilters.length) {
    filteredAppointments = filteredAppointments.filter(a =>
      statusFilters.includes(a.appointment_status.id)
    );
  }

  // Filter for Client
  const clientFilters = calendarFilters
    .filter(item => item.data.type === "Client")
    .map(item => item.data.id);

  if (clientFilters.length) {
    filteredAppointments = filteredAppointments.filter(a =>
      clientFilters.includes(a.contact.household_id)
    );
  }

  // Filter for Zones
  const zoneFilters = calendarFilters
    .filter(item => item.data.type === "Zone")
    .map(item => item.data.id);

  if (zoneFilters.length) {
    filteredAppointments = filteredAppointments.filter(a =>
      zoneFilters.includes(a.home.zone.id)
    );
  }

  // Filter for Pets
  const petFilters = calendarFilters
    .filter(item => item.data.type === "Pet")
    .map(item => item.data.id);

  if (petFilters.length) {
    filteredAppointments = filteredAppointments.filter(a => {
      const petIds = a.appointment_pets.map(pet => pet.id);
      let hasPet = false;
      petIds.forEach(id => {
        if (petFilters.includes(id)) {
          hasPet = true;
        }
      });
      return hasPet;
    });
  }

  // Filter for Pet Class
  const petClassFilters = calendarFilters
    .filter(item => item.data.type === "Pet Class")
    .map(item => item.data.id);

  if (petClassFilters.length) {
    filteredAppointments = filteredAppointments.filter(a => {
      const petClasses = a.appointment_pets.map(pet => pet.pet_class);
      let hasPetClass = false;
      petClasses.forEach(petClass => {
        if (petClassFilters.includes(petClass)) {
          hasPetClass = true;
        }
      });
      return hasPetClass;
    });
  }

  // Filter for Home Class
  const homeClassFilters = calendarFilters
    .filter(item => item.data.type === "Home Class")
    .map(item => item.data.id);

  if (homeClassFilters.length) {
    filteredAppointments = filteredAppointments.filter(a =>
      homeClassFilters.includes(a.home.home_class)
    );
  }

  const notCanceledFilters = calendarFilters
    .filter(item => item.data.type === "Not Canceled")
    .map(item => item.data.id);

  // Filter for Sitter
  const sitterFilters = calendarFilters
    .filter(item => item.data.type === "Team Member")
    .map(item => item.data.id);

  if (sitterFilters.length) {
    filteredAppointments = filteredAppointments.filter(a => {
      if (!a.sitter && sitterFilters.includes(null)) {
        return true;
      }
      return a.sitter && sitterFilters.includes(a.sitter._id);
    });
  }

  // Filter for Recurrence
  const recurrenceFilters = calendarFilters
    .filter(item => item.data.type === "Recurrence Type")
    .map(item => item.data.id);

  if (recurrenceFilters.length) {
    filteredAppointments = filteredAppointments.filter(a => {
      if (
        recurrenceFilters.includes("Not Recurring") &&
        !a.recurrence.is_recurring
      ) {
        return true;
      } else if (
        recurrenceFilters.includes("Weekly") &&
        a.recurrence.recurrence_type === "weekly"
      ) {
        return true;
      } else if (
        recurrenceFilters.includes("Monthly") &&
        a.recurrence.recurrence_type === "monthly"
      ) {
        return true;
      }
      return false;
    });
  }

  const timeMorningFilter = calendarFilters.find(f => f.value === "Morning");
  if (timeMorningFilter) {
    filteredAppointments = filteredAppointments.filter(a => {
      return moment(a.window_start_time).isBefore(
        moment(a.window_start_time).hour(11)
      );
    });
  }

  const timeMiddayFilter = calendarFilters.find(f => f.value === "Midday");
  if (timeMiddayFilter) {
    filteredAppointments = filteredAppointments.filter(a => {
      return (
        moment(a.window_start_time).isBefore(
          moment(a.window_start_time).hour(14)
        ) &&
        moment(a.window_start_time).isAfter(
          moment(a.window_start_time).hour(11)
        )
      );
    });
  }

  const timeEveningFilter = calendarFilters.find(
    f => f.value === "Afternoon/Evening"
  );
  if (timeEveningFilter) {
    filteredAppointments = filteredAppointments.filter(a => {
      return (
        moment(a.window_start_time).isBefore(
          moment(a.window_start_time).hour(18)
        ) &&
        moment(a.window_start_time).isAfter(
          moment(a.window_start_time).hour(14)
        )
      );
    });
  }

  if (notCanceledFilters.length) {
    filteredAppointments = filteredAppointments.filter(
      a => a.appointment_status.id !== 7
    );
  }

  // Return filtered appointments
  return filteredAppointments;
};
