import * as ActionTypes from "../../actions";
import moment from "moment";
import _ from "lodash";

let colors = [
  "#d92121",
  "#b26750",
  "#ff7700",
  "#8c6200",
  "#d9c8a3",
  "#ffbe26",
  "#fff899",
  "#5d662e",
  "#dbff26",
  "#52b31b",
  "#99ffbb",
  "#006652",
  "#62d5d9",
  "#0083b3",
  "#0059b3",
  "#bfdfff",
  "#8687b3",
  "#ffccff",
  "#0008ff",
  "#801bb3",
  "#bc82d9",
  "#664d5d",
  "#ff00a2",
  "#ff9999",
  "#d9a3ac",
  "#d92121",
  "#b26750",
  "#ff7700",
  "#8c6200",
  "#d9c8a3",
  "#ffbe26",
  "#fff899",
  "#5d662e",
  "#dbff26",
  "#52b31b",
  "#99ffbb",
  "#006652",
  "#62d5d9",
  "#0083b3",
  "#0059b3",
  "#bfdfff",
  "#8687b3",
  "#ffccff",
  "#0008ff",
  "#801bb3",
  "#bc82d9",
  "#664d5d",
  "#ff00a2",
  "#ff9999",
  "#d9a3ac",
  "#d92121",
  "#b26750",
  "#ff7700",
  "#8c6200",
  "#d9c8a3",
  "#ffbe26",
  "#fff899",
  "#5d662e",
  "#dbff26",
  "#52b31b",
  "#99ffbb",
  "#006652",
  "#62d5d9",
  "#0083b3",
  "#0059b3",
  "#bfdfff",
  "#8687b3",
  "#ffccff",
  "#0008ff",
  "#801bb3",
  "#bc82d9",
  "#664d5d",
  "#ff00a2",
  "#ff9999",
  "#d9a3ac",
  "#d92121",
  "#b26750",
  "#ff7700",
  "#8c6200",
  "#d9c8a3",
  "#ffbe26",
  "#fff899",
  "#5d662e",
  "#dbff26",
  "#52b31b",
  "#99ffbb",
  "#006652",
  "#62d5d9",
  "#0083b3",
  "#0059b3",
  "#bfdfff",
  "#8687b3",
  "#ffccff",
  "#0008ff",
  "#801bb3",
  "#bc82d9",
  "#664d5d",
  "#ff00a2",
  "#ff9999",
  "#d9a3ac"
];

function getDiff(a, b) {
  let timeA = moment(a);
  let timeB = moment(b);
  let diff = timeA.diff(timeB, "minutes");
  return diff;
}

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

const initState = {
  staffList: [],
  staffWithoutAppointments: null,
  staffMember: null,
  ghost_shifts: [],
  staffData: {
    staff: [],
    appointments: []
  }
};

const staff = (state = initState, action) => {
  switch (action.type) {
    case "ADD_SHIFTS":
      return {
        ...state,
        ghost_shifts: [...action.payload]
      };
    case "ADD_GHOST_STAFF":
      let newGhostList = [...state.staffList];
      console.log("payload...", action.payload);
      let findIndex = newGhostList.findIndex(s => s._id === action.payload._id);
      console.log("finding...", findIndex);
      if (findIndex > -1) {
        newGhostList.splice(findIndex, 1, action.payload);
      } else {
        newGhostList.push(action.payload);
      }
      return {
        ...state,
        staffList: newGhostList
      };
    case "SET_BREAK":
      let newStaffs = state.staffList.map(s => {
        let newOne = {
          ...s,
          floating_break:
            s._id == action.payload.id
              ? action.payload.useBreak
              : s.floating_break,
          floating_break_start_time: "02:00 pm",
          floating_break_end_time: "04:00 pm"
        };
        return newOne;
      });
      return {
        ...state,
        staffList: newStaffs
      };
    case "REMOVE_BREAK":
      let staffers = state.staffList.map(s => {
        let woob = { ...s };
        if (s._id === action.payload._id) {
          woob.break_id = null;
          woob.break_start = null;
          woob.break_end = null;
          woob.break_duration = null;
        }
        return woob;
      });
      return {
        ...state,
        staffList: staffers
      };
    case "ADD_BREAK":
      let stafferz = state.staffList.map(s => {
        let woob = { ...s };
        if (s._id === action.payload.sitter._id) {
          woob.break_id = action.payload.ourBreak._id;
          woob.break_start = action.payload.ourBreak.start_time;
          woob.break_end = action.payload.ourBreak.end_time;
          woob.break_duration = action.payload.ourBreak.duration;
        }
        return woob;
      });
      return {
        ...state,
        staffList: stafferz
      };
    case "UPDATE_BREAK":
      let staffernz = state.staffList.map(s => {
        let woob = { ...s };
        if (s._id === action.payload.sitter._id) {
          woob.break_id = action.payload.ourBreak._id;
          woob.break_start = action.payload.ourBreak.start_time;
          woob.break_end = action.payload.ourBreak.end_time;
          woob.break_duration = action.payload.ourBreak.duration;
        }
        return woob;
      });
      return {
        ...state,
        staffList: staffernz
      };
    case "SET_BREAK_DETAILS":
      let duration = Number(action.payload.ourBreak.duration);
      if (!duration) duration = 20;
      let ourStaffs = state.staffList.map(s => {
        let copy = {
          ...s
        };
        if (s._id == action.payload.id) {
          copy.floating_break_duration = duration;
          copy.floating_break_start_time =
            action.payload.ourBreak.floating_break_start_time;
          copy.floating_break_end_time =
            action.payload.ourBreak.floating_break_end_time;
        }
        return copy;
      });
      return {
        ...state,
        staffList: ourStaffs
      };
    case "CHANGE_GHOST_END":
      let ghosts = [...state.ghost_shifts];
      let find = ghosts.find(g => g._id === action.payload.id);
      if (find) find.shift_end = action.payload.value;
      return {
        ...state,
        ghost_shifts: ghosts
      };
    case "CHANGE_GHOST_START":
      let ghosts2 = [...state.ghost_shifts];
      let find2 = ghosts2.find(g => g._id === action.payload.id);
      if (find2) find2.shift_start = action.payload.value;
      return {
        ...state,
        ghost_shifts: ghosts2
      };
    case "REMOVE_GHOST":
      console.log("removing.......");
      let newGhosts = state.staffList.filter(s => s._id !== action.payload._id);
      return {
        ...state,
        staffList: newGhosts
      };
    case "ADD_GHOST_SHIFTS":
      // do stuff here....
      let ourGhosts = [...state.ghost_shifts];
      action.payload.forEach(appt => {
        let findGhost = ourGhosts.find(g => g._id === appt.sitter._id);
        if (!findGhost)
          ourGhosts.push({
            shift_start: "11:00",
            shift_end: "15:00",
            _id: appt.sitter._id,
            contact: {
              full_name: `Ghost Sitter ${ourGhosts.length + 1}`
            },
            zones: [],
            ghost: true
          });
      });
      return {
        ...state,
        ghost_shifts: ourGhosts
      };
    case "UPDATE_STAFF_PUSH":
      let newStaffList = state.staffList.slice();
      let findStaff = newStaffList.find(s => s._id === action.payload._id);
      if (findStaff) findStaff.lateness = action.payload.lateness;
      return {
        ...state,
        staffList: newStaffList
      };

    case "STAFF_DATA":
      return {
        ...state,
        staffData: {
          staff: action.payload.staff,
          appointments: action.payload.appointments
        }
      };
    case ActionTypes.CREATE_STAFF_SUCCESS:
      return state;

    case "GET_ALL_STAFF_SUCCESS":
      return { ...state, staffList: action.payload.staffList };
    case "SET_STAFF_APPOINTMENTS":
      const sitters = action.payload.map((s, i) => {
        let className;
        if (i < colors.length) {
          className = colors[i];
        } else {
          let num = getRandomInt(colors.length - 1);
          className = colors[num];
        }

        s.className = className;
        return s;
      });
      return {
        ...state,
        staffList: action.payload
      };

    case "GET_STAFF_WITHOUT_APPOINTMENTS":
      return { ...state, staffWithoutAppointments: action.payload.staffList };
    case ActionTypes.GET_STAFF_MEMBER_SUCCESS:
      return { ...state, staffMember: action.payload.staffMember };
    case "RESET_APPOINTMENT":
      const newStaffz = state.staffList.slice();
      newStaffz.forEach(staff => {
        if (staff.appointments) {
          staff.appointments.forEach(a => {
            if (a.appointment && a.appointment._id === action.payload._id) {
              a.appointment = null;
            }
          });
        }
      });
      return {
        ...state,
        staffList: newStaffz
      };
    case "SET_APPOINTMENT":
      const newStaff = state.staffList.slice();
      //remove appointment from anyone else who has it
      newStaff.forEach(staff => {
        if (staff.appointments) {
          staff.appointments.forEach(a => {
            if (
              a.appointment &&
              a.appointment._id === action.payload.appointment._id
            ) {
              a.appointment = null;
            }
          });
        }
      });
      const ourStaffMember = newStaff.find(
        s => s._id == action.payload.appointment.sitter._id
      );
      if (!ourStaffMember) return state;
      const findAppt = ourStaffMember.appointments.find(
        a => a.time === action.payload.appointment.scheduled_start_time
      );
      if (!findAppt) {
        return state;
      }
      findAppt.appointment = _.cloneDeep(action.payload.appointment);
      return {
        ...state,
        staffList: newStaff
      };

    case "UPDATE_SINGLE_APPOINTMENT":
      return {
        ...state
      };
    case "SET_MAP_APPOINTMENT":
      const newMapStaff = state.staffList.slice();
      //remove appointment from anyone else who has it
      const newAppointmentCloned = _.cloneDeep(action.payload.appointment);
      newMapStaff.forEach(staff => {
        if (staff.appointments) {
          staff.appointments.forEach(a => {
            if (
              a.appointment &&
              a.appointment._id == newAppointmentCloned._id
            ) {
              a.appointment = null;
            }
          });
        }
      });
      const ourMapStaffMember = newMapStaff.find(
        s => s._id == newAppointmentCloned.sitter._id
      );
      if (!ourMapStaffMember) return state;
      const findMapAppt = ourMapStaffMember.appointments.find(
        a => a.time === newAppointmentCloned.scheduled_start_time
      );
      if (!findMapAppt) {
        return state;
      }
      findMapAppt.appointment = newAppointmentCloned;
      return {
        ...state,
        staffList: newMapStaff
      };
    case "HIGHLIGHT_SITTER":
      const highlightStaff = state.staffList.map(s => {
        if (s._id == action.payload) {
          if (s.highlight) {
            s.highlight = false;
          } else {
            s.highlight = true;
          }
        }
        return s;
      });
      return {
        ...state,
        staffList: highlightStaff
      };

    default:
      return state;
  }
};

export default staff;
