import React, { Component } from "react";
import moment from "moment";
import Multiselect from "react-widgets/Multiselect";
import {
  applyMultiFilters,
  getFilterOptions
} from "../../../helpers/schedulerFilter";
import {
  CalendarDayView,
  CalendarWeekView,
  CalendarMonthView,
  PetCardXtraCondensed,
  SegmentedControl
} from "../../";

const filterDimensions = [
  "Appointment Status",
  "Client",
  "Zone",
  "Pet",
  "Pet Class",
  "Home Class",
  "Team Member",
  "Recurrence Type"
];

export default class CalendarView extends Component {

  getAppointmentsByMonth = month => {
    const { getAppointments } = this.props;
    const start = month
      .clone()
      .startOf("month")
      .day("Sunday")
      .toDate();
    const end = month
      .clone()
      .endOf("month")
      .day("Saturday")
      .toDate();
    getAppointments({ start, end });
  };

  renderDayAppointments = day => {
    const {
      appointments,
      calendarFilters,
      onClickAppointmentCard
    } = this.props;
    const filteredAppointments = applyMultiFilters(
      appointments,
      calendarFilters
    );

    return filteredAppointments
      .filter(appt => {
        let startTime = moment(appt.window_start_time).add(30, "minutes");
        if (appt.scheduled_start_time) {
          startTime = moment(appt.scheduled_start_time);
        }
        return startTime.isSame(day.date, "day");
      })
      .map(appt => (
        <PetCardXtraCondensed
          key={`pet-card-xtra-condensed-appt-id-${appt._id}`}
          appointment={appt}
          onClickAppointmentCard={onClickAppointmentCard}
        />
      ));
  };

  renderCalendar = () => {
    const {
      appointments,
      calendarDay,
      calendarMonth,
      calendarView,
      calendarWeek,
      calendarFilters,
      getAppointments,
      onClickAppointmentCard,
      setCalendarDay,
      setCalendarMonth,
      setCalendarWeek
    } = this.props;

    const filteredAppointments = applyMultiFilters(
      appointments,
      calendarFilters
    );

    if (calendarView === "week") {
      return (
        <CalendarWeekView
          appointments={filteredAppointments}
          calendarWeek={calendarWeek}
          getAppointments={getAppointments}
          onClickAppointmentCard={onClickAppointmentCard}
          setCalendarWeek={setCalendarWeek}
        />
      );
    } else if (calendarView === "month") {
      return (
        <CalendarMonthView
          calendarMonth={calendarMonth}
          getAppointmentsByMonth={this.getAppointmentsByMonth}
          setCalendarMonth={setCalendarMonth}
        >
          {day => this.renderDayAppointments(day)}
        </CalendarMonthView>
      );
    }
    return (
      <CalendarDayView
        appointments={filteredAppointments}
        calendarDay={calendarDay}
        getAppointments={getAppointments}
        onClickAppointmentDetail={onClickAppointmentCard}
        setCalendarDay={setCalendarDay}
      />
    );
  };

  render() {
    const {
      appointments,
      changeCalendarView,
      changeSchedulerView,
      calendarFilters,
      calendarView,
      schedulerView,
      setCalendarFilters
    } = this.props;

    return (
      <div className="row">
        <div className="flex-row calendar-header">
          <div className="segmented-control">
            <SegmentedControl
              onChange={changeSchedulerView}
              options={[
                { name: "Scheduling Tool", key: "scheduling" },
                { name: "Calendar View", key: "calendar" }
              ]}
              selectedKey={schedulerView}
            />
          </div>

          <div className="form-group input-field">
            <Multiselect
              data={getFilterOptions(appointments, filterDimensions)}
              defaultValue={calendarFilters}
              valueField="value"
              textField="text"
              groupBy={item => item.data.type}
              filter="contains"
              onChange={setCalendarFilters}
              placeholder="Filter by anything..."
            />
            <div className="search-icon">
              <span className="fa fa-search" />
            </div>
          </div>

          <div className="segmented-control">
            <SegmentedControl
              onChange={changeCalendarView}
              options={[
                { name: "Day", key: "day" },
                { name: "Week", key: "week" },
                { name: "Month", key: "month" }
              ]}
              selectedKey={calendarView}
            />
          </div>
        </div>
        {this.renderCalendar()}
      </div>
    );
  }
}
