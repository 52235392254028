import React from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { Link, withRouter } from "react-router-dom";
import moment from "moment-timezone";
import { getIsFetching } from "../../reducers";
import { adminAppointmentService } from "../../services";
import { catchFormServerErrors } from "../../helpers";
import history from "../../actions/history";
import _ from "lodash";
import axios from "../../services/network.js";

// Actions
import {
  getAppointmentDetail as getAppointmentDetailAction,
  getAppointments as getAppointmentsAction,
  updateAppointment as updateAppointmentAction
} from "../../actions/admin/appointments.actions";
import { getClient } from "../../actions/admin/clients.actions";
import { createLineItem as createLineItemAction } from "../../actions/admin/general.actions";
import {
  getAllStaff,
  getStaffWithoutAppointments,
  getAllStaffWithAppointments
} from "../../actions/admin/staff.actions";
import {
  pushModal,
  popModal,
  showFormErrorMessage,
  showSuccessMessage
} from "../../actions/general.actions";

// Child components
import { FormSubHeader } from "../../components/shared/formComponents";
import {
  AddLineItemForm,
  LineItems,
  WalkCredits,
  AppointmentPriceBanner,
  AppointmentStatusBanner,
  ApproveAppointmentModal,
  AdminEditAppointmentForm,
  AdminCancelAppointmentModal,
  DeleteVisitModal,
  RejectAppointmentModal,
  PageTitleComponent,
  WalkMapCard,
  ZoneBadge
} from "../../components";

class AdminAppointmentDetailPage extends React.Component {

  state = {
    addOnServices: [],
    editRecurringSeries: false,
    editCurrentAppointment: false,
    completed_services: [],
    interval_changed: false
  };

  componentWillMount = async () => {
    const {
      appointmentId,
      getAppointmentDetail,
      getStaffList,
      match,
      staffList,
      market
    } = this.props;

    getAppointmentDetail(appointmentId || match.params.appointmentId);

    getStaffList({ city: market._id });

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URI}/admin/vacation`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
        }
      }
    );
    this.setState({ vacation_days: data });
  };

  componentWillUnmount = () => {
    this.props.clearAppointmentDetail();
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  componentWillReceiveProps = props => {
    if (props.appointmentDetail) {
      if (
        !this.props.appointmentDetail ||
        props.appointmentDetail.household_id !==
          this.props.appointmentDetail.household_id
      ) {
        props.getClientInfo(props.appointmentDetail.household_id);
      }
      let appt = _.cloneDeep(props.appointmentDetail);
      let completed = appt.add_on_services.filter(a => a.completed);
      if (
        appt &&
        this.props.appointmentDetail &&
        this.props.appointmentDetail._id !== appt._id
      ) {
        const { getAppointmentDetail, clearAppointmentDetail } = props;
        clearAppointmentDetail();
        history.push(`/appointments/${appt._id}`);
        getAppointmentDetail(appt._id);
        if (
          !this.state.completed_services ||
          !this.state.completed_services.length
        )
          this.setState({ completed_services: completed });
      } else {
        this.setState({ completed_services: completed });
      }
    }
  };

  shouldWarnOnUpdateRecurringInstance = () => {
    const { editCurrentAppointment } = this.state;
    const { appointmentDetail, formValues } = this.props;
    const isRecurring =
      appointmentDetail.recurrence && appointmentDetail.recurrence.is_recurring;
    const initialValues = adminAppointmentService.getInitialFormValuesForUpdate(
      appointmentDetail
    );

    // check if appt type changed
    const typeChanged =
      formValues.appointment_type_id !== initialValues.appointment_type_id;

    //check if date changed
    const initialDate = formValues.requested_date;
    const currentDate = initialValues.requested_date;
    const dateChanged = !moment(initialDate).isSame(currentDate, "day");

    // check if time changed
    const initialStartTime = formValues.window_start_time;
    const currentStartTime = initialValues.window_start_time;
    const startTimeChanged =
      moment(initialStartTime).hour() !== moment(currentStartTime).hour() ||
      moment(initialStartTime).minute() !== moment(currentStartTime).minute();

    // check if time changed
    const initialEndTime = formValues.window_start_time;
    const currentEndTime = initialValues.window_start_time;
    const endTimeChanged =
      moment(initialEndTime).hour() !== moment(currentEndTime).hour() ||
      moment(initialEndTime).minute() !== moment(currentEndTime).minute();

    // check if pets changed
    let petsChanged = false;
    const initialPetIds = initialValues.pet_ids || [];
    const currentPetIds = formValues.pet_ids || [];
    for (const initialPet of initialPetIds) {
      if (!currentPetIds.includes(initialPet)) petsChanged = true;
    }
    for (const currentPet of currentPetIds) {
      if (!initialPetIds.includes(currentPet)) petsChanged = true;
    }

    // check if services changed
    let svcsChanged = false;
    const initialSvcIds = initialValues.addon_ids || [];
    const currentSvcIds = formValues.addon_ids || [];
    for (const initialPet of initialSvcIds) {
      if (!currentSvcIds.includes(initialPet)) svcsChanged = true;
    }
    for (const currentPet of currentSvcIds) {
      if (!initialSvcIds.includes(currentPet)) svcsChanged = true;
    }

    const targetFieldsChanged =
      dateChanged ||
      startTimeChanged ||
      endTimeChanged ||
      typeChanged ||
      svcsChanged ||
      petsChanged;

    // if we're editing a single appointment intance on a recurring appointment and certain fields have changed
    // return true (so we can warn the user that the appointment will be removed from the series)
    return isRecurring && editCurrentAppointment && targetFieldsChanged;
  };

  showEditCurrentAppointment = editCurrentAppointment => {
    this.setState({ editCurrentAppointment });
  };

  showEditRecurringSeries = editRecurring => {
    this.setState({ editRecurringSeries: editRecurring });
  };

  handleUpdate = async values => {
    const { editRecurringSeries, interval_changed } = this.state;
    const {
      appointmentId,
      match,
      selectedDate,
      appointmentDetail,
      staffList,
      getAppointmentDetail,
      clientInfo
    } = this.props;
    const newMod = _.cloneDeep(values);
    newMod.appointment_images = appointmentDetail.appointment_images;
    const model = adminAppointmentService.getAdminUpdateAppointmentModel(
      newMod,
      clientInfo
    );
    model.add_on_services = model.add_on_services.concat(
      this.state.addOnServices
    );
    model.interval_changed = interval_changed;
    model.add_on_services.forEach(a => {
      let findIt;
      if (a.pet_id) {
        findIt = this.state.completed_services.find(
          s => s.id === a.id && s.pet_id === a.pet_id
        );
        a.completed = findIt ? true : false;
      } else {
        findIt = this.state.completed_services.find(s => s.id === a.id);
        a.completed = findIt ? true : false;
      }
    });
    if (editRecurringSeries) {
      model.update_series = true;
    }

    model.recurrence_id = appointmentDetail.recurrence
      ? appointmentDetail.recurrence.recurrence_id
      : null;
    let ourSitter = staffList.find(s => s._id == values.assigned_sitter);
    if (ourSitter) {
      model.sitter = {
        _id: ourSitter._id,
        full_name: ourSitter.fullName
      };
    }

    await this.props
      .updateAppointment(appointmentId || match.params.appointmentId, model)
      // Return server errors to form to display field-level errors and prevent success message
      // .then(result => this.props.getAppointmentList({ status: 'pending', startDate: moment(selectedDate).startOf('day').toDate(), endDate: moment(selectedDate).endOf('day').toDate() }))
      // .then(result => this.props.getAllStaffWithAppointments({ startDate: moment(selectedDate).startOf('day').toDate(), endDate: moment(selectedDate).endOf('day').toDate() }))
      .then(res => catchFormServerErrors(res));
  };

  handleAddLineItem = values => {
    const model = {
      ...values,
      amount:
        values.charge_or_credit === "Credit"
          ? values.amount * -1
          : values.amount,
      market_id: this.props.market._id
    };
    return (
      this.props
        .createLineItem(model)
        // Return server errors to form to display field-level errors and prevent success message
        .then(res => catchFormServerErrors(res))
    );
  };

  pushActionModal = (e, action) => {
    // action is one of ['approve', 'reject', 'cancel']
    e.preventDefault();
    const { modalActions } = this.props;

    const modals = {
      approve: <ApproveAppointmentModal />,
      reject: <RejectAppointmentModal />,
      cancel: <AdminCancelAppointmentModal />
    };

    modalActions.push(modals[action]);
  };

  onSelectAssociatedRecurringAppointment = appointmentId => {
    const { getAppointmentDetail, match } = this.props;
    const isModal = !(match.match && match.params.appointmentId);
    if (!isModal)
      this.props.history.push(`/admin/appointments/${appointmentId}`);
    getAppointmentDetail(appointmentId);
  };

  renderDangerButtons = () => {
    const { editCurrentAppointment, editRecurringSeries } = this.state;
    const { appointmentDetail } = this.props;

    return (
      <div>
        <div>
          <div className="col-sm-3 text-right button-column pull-right">
            <button
              className="btn btn-block btn-danger"
              onClick={() => this.showDeleteModal()}
            >
              Delete Appointment
            </button>
          </div>
        </div>
      </div>
    );
  };

  showDeleteModal = () => {
    const { appointmentDetail } = this.props;

    this.props.modalActions.push(
      <DeleteVisitModal appointment={appointmentDetail} />
    );
  };

  renderEditActionButtons = () => {
    const { editCurrentAppointment, editRecurringSeries } = this.state;
    const { appointmentDetail } = this.props;

    return (
      <div>
        {editCurrentAppointment || editRecurringSeries ? null : (
          <div>
            {appointmentDetail.recurrence &&
            appointmentDetail.recurrence.is_recurring ? (
              <div className="col-sm-3 text-right button-column pull-right">
                <button
                  className="btn btn-block btn-secondary"
                  onClick={() => this.showEditRecurringSeries(true)}
                >
                  Edit Recurring Series
                </button>
              </div>
            ) : null}
            <div className="col-sm-3 text-right button-column pull-right">
              <button
                className="btn btn-block btn-secondary"
                onClick={() => this.showEditCurrentAppointment(true)}
              >
                Edit Appointment
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  handleAddService = (service, pet) => {
    service.forEach(s => {
      const findIt = this.state.addOnServices.find(
        a => a.id === service.id && a.pet_id === pet._id
      );
      if (!findIt) {
        this.setState({
          addOnServices: this.state.addOnServices.concat([
            {
              id: s.id,
              name: s.name,
              description: s.description,
              pet_id: pet._id
            }
          ])
        });
      }
    });
  };

  completeService = (service, petId) => {
    let findIt = petId
      ? this.state.completed_services.findIndex(
          a => a.id === service.id && a.pet_id === petId
        )
      : this.state.completed_services.findIndex(a => a.id === service.id);
    let newArr = this.state.completed_services.slice();
    let findInAddons = this.state.addOnServices.find(
      a => a.id === service.id && a.pet_id === petId
    );
    if (!findInAddons) {
      let newAddons = this.state.addOnServices.slice();
      newAddons.push(service);
      this.setState({ addOnServices: newAddons });
    }

    if (findIt > -1) {
      newArr.splice(findIt, 1);
      this.setState({ completed_services: newArr });
    } else {
      newArr.push(service);
      this.setState({
        completed_services: newArr
      });
    }
  };

  visitReport = (e, appt) => {
    e.stopPropagation();
    if (process.env.NODE_ENV === "production") {
      window.open(`https://app.walkskipper.com/visit/report/${appt._id}`);
    } else {
      window.open(
        `https://skipper-client-stage.herokuapp.com/visit/report/${appt._id}`
      );
    }
  };

  seriesIntervalChanged = () => {
    if (!this.state.interval_changed) {
      this.setState({ interval_changed: true });
    }
  };

  render() {
    const {
      appointmentDetail: appt,
      displayFormError,
      displayFormSuccess,
      formConstants,
      getAppointmentDetail,
      getAppointmentList,
      isFetching,
      modalActions,
      relatedAppointments,
      staffList
    } = this.props;
    const { editCurrentAppointment, editRecurringSeries } = this.state;

    // TODO: make a better spinner
    // render spinner if we don't have any appointment data
    if (isFetching || !appt) {
      return <div />;
    }

    const requestedDate = moment(appt.visit_date).format("dddd, MMM D, YYYY");
    const requestedTime = moment(appt.window_start_time)
      .add(30, "m")
      .format("h:mm a");

    const recurringDays =
      appt.recurrence &&
      appt.recurrence.weekdays &&
      appt.recurrence.weekdays.toString();
    return (
      <div>
        <div className="card appointment-detail-page">
          <PageTitleComponent title="Appointment Details" />
          <AppointmentStatusBanner
            dateCreated={appt.created_at}
            dateUpdated={appt.updated_at}
            status={appt.appointment_status.name}
            userType="admin"
          />

          {appt.line_items && appt.line_items.length ? (
            <div className="container consideration-box">
              <p>
                A charge has been recorded for this appointment. Any further
                modifications to this appointment resulting in a price change
                will require manual adjustments to Charges/Credits below.
              </p>
            </div>
          ) : null}

          <div className="container form-container">
            <div className="row">
              <h3 className="col-sm-12 title">Appointment Summary</h3>
              <div className="col-sm-6 col-md-6">
                <FormSubHeader>Client </FormSubHeader>
                <ul className="no-decoration">
                  <li className="appointment-detail-contactname">
                    <Link to={`/client/${appt.contact.household_id}`}>
                      {appt.contact.full_name}
                    </Link>
                  </li>
                  <li>
                    {appt.home.address.address_line_1}
                    <div className="inline-zone-badge">
                      <ZoneBadge size="tiny" zone={appt.home.zone} />
                    </div>
                  </li>
                  {appt.home.address.address_line_2 ? (
                    <li>{appt.home.address.address_line_2}</li>
                  ) : null}
                  <li>{`${appt.home.address.city}, ${appt.home.address.state} ${appt.home.address.zip_code}`}</li>
                  {!appt.home.address.enforce_device_within_region ? (
                    <li>
                      <span className="fa fa-match-arrow" /> Geomatch not
                      enforced
                    </li>
                  ) : null}
                </ul>

                <FormSubHeader>
                  {`Pet${
                    appt.appointment_pet_ids.length > 1 ? "s" : ""
                  } & Services in this Appointment`}
                </FormSubHeader>
                <ul className="no-decoration">
                  {appt.appointment_pet_ids.map(petId => {
                    const pet = appt.all_client_pets.find(p => p._id == petId);
                    if (!pet) return null;
                    const services = appt.add_on_services.filter(
                      a => a.pet_id == petId
                    );

                    return (
                      <div
                        className="pet-name-service"
                        key={`petNameService${petId}`}
                      >
                        {pet.name}
                        {services.map(s => {
                          let checked = this.state.completed_services.find(
                            a => a.id === s.id && a.pet_id === pet._id
                          );
                          return (
                            <span className="petAddOn" key={s.id + petId}>
                              {s.name}
                              {editCurrentAppointment ? (
                                <input
                                  type="checkbox"
                                  checked={checked ? true : false}
                                  onChange={() =>
                                    this.completeService(s, petId)
                                  }
                                />
                              ) : (
                                <i
                                  className={`${checked ? "fa fa-check" : ""}`}
                                />
                              )}
                            </span>
                          );
                        })}
                      </div>
                    );
                  })}
                </ul>

                <FormSubHeader>Additional Notes</FormSubHeader>
                <ul className="no-decoration">
                  <li>
                    Home Class:{" "}
                    {appt.home.home_class && appt.home.home_class.name ? (
                      <div>
                        {" "}
                        <span
                          className={`fa fa-home ${
                            appt.home.home_class && appt.home.home_class.name
                              ? appt.home.home_class.name.toLowerCase()
                              : ""
                          }`}
                        >
                          {appt.home.home_class.name}
                        </span>{" "}
                        {appt.home.home_class_notes}{" "}
                      </div>
                    ) : null}
                  </li>
                  <li>Notes on Client: {appt.home.notes}</li>
                  <li>Client''s Notes: {appt.client_notes}</li>
                  <li>
                    {appt.price_overridden
                      ? `Overridden Price: $${(appt.price / 100).toFixed(2)}`
                      : null}
                  </li>
                </ul>
              </div>

              {appt.recurrence ? (
                <div className="col-sm-6 col-md-6">
                  <FormSubHeader>Recurring Appointments</FormSubHeader>
                  <ul className="no-decoration">
                    <li>{requestedDate}</li>
                    <li>{appt.appointment_type.name}</li>
                    <li>
                      <span className="fa fa-clock-o" /> Requested{" "}
                      {appt.recurrence.type} at {requestedTime} (ET)
                    </li>
                    <li>
                      <i>recurring</i>{" "}
                      <b>{recurringDays.split(",").join(", ")}</b>
                    </li>
                    <li>
                      {moment(appt.recurrence.start_date).format("MMM D, YYYY")}{" "}
                      to{" "}
                      {moment(appt.recurrence.end_date).format("MMM D, YYYY")}
                    </li>
                    {appt.appointment_status.id === 5 ? (
                      <li
                        className="linkTo"
                        onClick={e => this.visitReport(e, appt)}
                      >
                        Visit Report
                      </li>
                    ) : null}
                    {appt.cancel_date ? (
                      <li>
                        Canceled:{" "}
                        {moment(appt.cancel_date).format(
                          "MMM D, YYYY [at] h:mm a"
                        )}
                      </li>
                    ) : null}
                  </ul>
                </div>
              ) : (
                <div className="col-sm-6 col-md-6">
                  <FormSubHeader>Appointment</FormSubHeader>
                  <ul className="no-decoration">
                    <li>
                      {appt.appointment_type.name} - ({appt.duration_in_minutes}{" "}
                      min.)
                    </li>
                    <li>{requestedDate}</li>
                    <li>
                      {moment(appt.window_start_time).format("h:mm a")} -{" "}
                      {moment(appt.window_end_time).format("h:mm a")} (ET)
                    </li>
                    <li>
                      Created:{" "}
                      {moment(appt.created_at).format(
                        "MMM D, YYYY [at] h:mm a"
                      )}
                    </li>
                    {appt.appointment_status.id === 5 ? (
                      <li
                        className="linkTo"
                        onClick={e => this.visitReport(e, appt)}
                      >
                        Visit Report
                      </li>
                    ) : null}
                    {appt.cancel_date || appt.canceled_by ? (
                      <li>
                        Canceled by{" "}
                        {`${appt.canceled_by} ${moment(appt.cancel_date).format(
                          "MMM D, YYYY [at] h:mm a"
                        )}`}
                      </li>
                    ) : null}
                  </ul>
                </div>
              )}

              <div className="col-sm-12 col-md-6">
                <FormSubHeader>Status</FormSubHeader>
                <ul className="no-decoration">
                  <li>{appt.appointment_status.name}</li>
                  <li>
                    Scheduled Time:{" "}
                    {appt.scheduled_start_time
                      ? moment(appt.scheduled_start_time).format("h:mm a")
                      : null}
                  </li>
                  <li>
                    Team Member:{" "}
                    {appt.sitter && appt.sitter.full_name
                      ? appt.sitter.full_name
                      : "Not Assigned"}
                  </li>
                </ul>

                <FormSubHeader>Other Requested Services</FormSubHeader>
                <ul className="no-decoration">
                  {appt.add_on_services
                    .filter(a => !a.pet_id)
                    .map((s, i) => {
                      let checked = this.state.completed_services.find(
                        a => a.id === s.id
                      );
                      return (
                        <span className="petAddOn" key={`${s.id}addOn`}>
                          {s.name}

                          {editCurrentAppointment ? (
                            <input
                              type="checkbox"
                              checked={checked ? true : false}
                              onChange={() => this.completeService(s, null)}
                            />
                          ) : (
                            <i className={`${checked ? "fa fa-check" : ""}`} />
                          )}
                        </span>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>

          {(appt.appointment_type &&
            appt.appointment_type.id !== 8 &&
            appt.appointment_type.id !== 10) ||
          editCurrentAppointment ||
          editRecurringSeries ? null : (
            <div className="appointment-price-wrapper">
              <AppointmentPriceBanner
                condition={false} // condition upon which the price should be calculated based on form values
                // price to display when not being calculated from form values
                defaultPrice={(appt.price / 100).toFixed(2)}
                formConstants={formConstants}
              />
            </div>
          )}

          <div className="container form-container">
            <div className="row edit-actionbtn-container">
              {this.renderEditActionButtons()}
            </div>
          </div>

          {editCurrentAppointment || editRecurringSeries ? (
            <AdminEditAppointmentForm
              appointment={appt}
              enableReinitialize
              formConstants={formConstants}
              handleAddService={this.handleAddService}
              freezePrice={appt.line_items ? !!appt.line_items.length : false}
              initialValues={adminAppointmentService.getInitialFormValuesForUpdate(
                appt
              )}
              modalActions={modalActions}
              onSubmit={this.handleUpdate}
              onSubmitFail={errors => displayFormError(errors)}
              onSubmitSuccess={() => {
                displayFormSuccess("The appointment has been updated.");
                this.setState({
                  editCurrentAppointment: false,
                  editRecurringSeries: false
                });
              }}
              pushActionModal={this.pushActionModal}
              staffList={staffList}
              discardChanges={() => {
                this.showEditRecurringSeries(false);
                this.showEditCurrentAppointment(false);
              }}
              editRecurringSeries={editRecurringSeries}
              seriesIntervalChanged={this.seriesIntervalChanged}
              vacation_days={this.state.vacation_days}
              clientInfo={this.props.clientInfo}
            />
          ) : null}

          {this.shouldWarnOnUpdateRecurringInstance() ? (
            <p className="pull-right">
              These changes will remove this appointment from the recurring
              schedule.
            </p>
          ) : null}
        </div>

        <WalkMapCard appointment={appt} />

        <div className="card appointment-detail-page">
          <PageTitleComponent title="Charges/Credits" />
          {(appt.line_items && appt.line_items.length) ||
          (appt.walk_credits && appt.walk_credits.length) ? null : (
            <div className="message">
              There are no charges/credits associated with this appointment.
              <br />A charge will be created automatically when the appointment
              is completed or in the event of a late cancellation.
            </div>
          )}

          {appt.line_items ? (
            <LineItems
              lineItems={appt.line_items}
              showMessage={this.props.showMessage}
              removeMessage={this.props.removeMessage}
              modalActions={this.props.modalActions}
              showSpinner={this.props.fetchRequest}
              removeSpinner={this.props.fetchSuccess}
              callback={this.props.getAppointmentDetail}
              id={this.props.appointmentDetail._id}
            />
          ) : null}

          {appt.walk_credits ? (
            <WalkCredits credits={appt.walk_credits} />
          ) : null}

          {appt.line_items && appt.line_items.length ? (
            <div>
              <AddLineItemForm
                initialValues={{
                  appointment_id: appt._id,
                  client_id: appt.contact.household_id,
                  charge_or_credit: "Charge"
                }}
                hideClientField
                onSubmit={this.handleAddLineItem}
                onSubmitFail={errors => displayFormError(errors)}
                onSubmitSuccess={() => {
                  displayFormSuccess("Line item successfully added.");
                  getAppointmentDetail(appt._id);
                }}
              />
            </div>
          ) : null}
        </div>

        <div style={{ height: "2000px", width: "100%" }}></div>

        <div className="card appointment-detail-page">
          <PageTitleComponent title="DANGER ZONE" />
          <div className="container form-container">
            <div className="row edit-actionbtn-container">
              {this.renderDangerButtons()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const selector = formValueSelector("adminEditAppointment");

const mapStateToProps = state => ({
  appointmentDetail: state.admin.appointments.appointmentDetail,
  formConstants: state.general.formConstants,
  market: state.admin.general.location,
  formValues: {
    addon_ids: selector(state, "addon_ids"),
    appointment_type_id: selector(state, "appointment_type_id"),
    pet_ids: selector(state, "pet_ids"),
    requested_date: selector(state, "requested_date"),
    window_end_time: selector(state, "window_end_time"),
    window_start_time: selector(state, "window_start_time"),
    scheduled_date: selector(state, "scheduled_date")
  },
  isFetching: getIsFetching(state, "adminGetAppointmentDetail"),
  relatedAppointments: state.admin.appointments.appointmentList,
  selectedDate: state.admin.scheduler.selectedDate,
  // If staffList is not null, return array with only fullName and sitter id.
  staffList:
    state.admin.staff.staffList &&
    state.admin.staff.staffList.map(sitter => ({
      full_name: `${sitter.contact.full_name}`,
      _id: sitter._id
    })),
  clientInfo: state.admin.clients.client
});

const mapDispatchToProps = dispatch => ({
  clearAppointmentDetail: () => dispatch({ type: "CLEAR_APPOINTMENT_DETAIL" }),
  createLineItem: model => dispatch(createLineItemAction(model)),
  displayFormError: errors =>
    dispatch(showFormErrorMessage(errors, "adminAppointmentDetails")),
  displayFormSuccess: message =>
    dispatch(showSuccessMessage(message, "adminAppointmentDetails")),
  showMessage: message => dispatch({ type: "ADD_MESSAGE", payload: message }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" }),
  getAppointmentDetail: appointmentId =>
    dispatch(getAppointmentDetailAction(appointmentId)),
  getAllStaffWithAppointments: filters =>
    dispatch(getAllStaffWithAppointments(filters)),
  getAppointmentList: (filters, sort) =>
    dispatch(getAppointmentsAction(filters, sort)),
  getStaffList: market => dispatch(getAllStaff(market)),
  /*getStaffWithoutAppointments: () => dispatch(getStaffWithoutAppointments()),*/
  modalActions: {
    push: component => dispatch(pushModal(component)),
    pop: () => dispatch(popModal())
  },
  updateAppointment: (appointmentId, model) =>
    dispatch(updateAppointmentAction(appointmentId, model)),
  getClientInfo: clientId => dispatch(getClient(clientId)),
  fetchRequest: () => dispatch({ type: "FETCH_REQUEST" }),
  fetchSuccess: () => dispatch({ type: "FETCH_SUCCESS" })
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AdminAppointmentDetailPage)
);
