import React, { PureComponent } from "react";
import { DefaultModal } from "../../";
import axios from "../../../services/network";
import history from "../../../actions/history";

class DeleteVetModal extends PureComponent {
  deleteVet = async () => {
    const {
      showSpinner,
      stopSpinner,
      showMessage,
      removeMessage,
      vet,
      popModal
    } = this.props;
    //this shouldn't ever happen but 🤷🏻‍♂️
    if (!vet || !vet._id) return;
    showSpinner();
    try {
      const { data } = axios.delete(`/admin/vet/${vet._id}`);
      showMessage({
        message: `Successfully deleted ${vet.name}`,
        messageType: "success"
      });
      setTimeout(removeMessage, 3000);
      popModal();
      history.push("/vets");
    } catch (e) {
      if (e.data && e.data.error) {
      } else {
        showMessage({
          message: `Unable to delete ${vet.name} at this time`,
          messageType: "error"
        });
      }
      setTimeout(removeMessage, 3000);
    }
    stopSpinner();
  };

  render() {
    const { popModal, vet } = this.props;

    return (
      <DefaultModal
        title="Delete Vet"
        onDismiss={popModal}
        options={[
          <button
            type="button"
            className="btn btn-danger"
            onClick={this.deleteVet}
          >
            Delete Permanently
          </button>,
          <button type="button" className="btn btn-warning" onClick={popModal}>
            Jk, bad idea
          </button>
        ]}
      >
        {`Are you sure you wish to delete ${vet.name}? This action cannot be undone...`}
      </DefaultModal>
    );
  }
}

export default DeleteVetModal;
