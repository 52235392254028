import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "../../services/network";
import { popModal, pushModal } from "../../actions/general.actions";
import { DropdownList } from "react-widgets";
import history from "../../actions/history";
import { DeleteVetModal } from "../../components";

class Vet extends Component {
  state = {
    vet: {
      _id: "",
      market_id: "",
      name: "",
      approved: false,
      address_1: "",
      address_2: "",
      city: "",
      state: "",
      zip: "",
      phone_number: "",
      latitude: "",
      longitude: "",
      override_geocode: false
    }
  };
  componentWillMount() {
    const { vetId } = this.props.match.params;
    if (vetId !== "create") {
      this.loadVet(vetId);
      this.setState({ vet: { ...this.state.vet, _id: vetId } });
    }
  }

  loadVet = async id => {
    const { showSpinner, stopSpinner, showMessage, removeMessage } = this.props;
    showSpinner();
    try {
      const { data } = await axios.get(`/admin/vet/${id}`);
      this.setState({ vet: data.vet });
    } catch (e) {
      if (e.data && e.data.error) {
        showMessage({
          message: e.data.error,
          messageType: "error"
        });
      } else {
        showMessage({
          message: "Unable to get Vet at this time",
          messageType: "error"
        });
      }
      setTimeout(removeMessage, 3000);
    }
    stopSpinner();
  };

  changeVal = (key, val) => {
    this.setState({
      vet: {
        ...this.state.vet,
        [key]: val
      }
    });
  };

  getMarkets = () => {
    return this.props.markets.map(m => ({ text: m.city, value: m._id }));
  };

  onSubmit = async () => {
    const { vet } = this.state;
    const { showSpinner, stopSpinner, showMessage, removeMessage } = this.props;
    showSpinner();
    try {
      let response;
      if (vet._id) {
        response = await axios.put(`/admin/vet/${vet._id}`, vet);
      } else {
        response = await axios.post(`/admin/vet`, vet);
      }
      showMessage({
        message: "Successfully saved Vet",
        messageType: "success"
      });
      setTimeout(removeMessage, 3000);
      history.push(`/vet/${response.data.vet._id}`);
      this.loadVet(response.data.vet._id);
    } catch (e) {
      if (e.data && e.data.error) {
        showMessage({
          message: e.data.error,
          messageType: "error"
        });
      } else {
        showMessage({
          message: "Error saving vet",
          messageType: "error"
        });
      }
      setTimeout(removeMessage, 3000);
    }
    stopSpinner();
  };

  renderConfirmModal = () => {
    const {
      showMessage,
      removeMessage,
      showSpinner,
      stopSpinner,
      modalActions
    } = this.props;
    const confirmModal = (
      <DeleteVetModal
        showMessage={showMessage}
        removeMessage={removeMessage}
        showSpinner={showSpinner}
        stopSpinner={stopSpinner}
        popModal={modalActions.pop}
        vet={this.state.vet}
      />
    );

    modalActions.push(confirmModal);
  };

  getStates = () => {
    return this.props.formConstants.states.map(s => ({
      name: s.name,
      id: s.id.toString()
    }));
  };

  render() {
    const { vet } = this.state;
    return (
      <div className="card vet-form-container">
        <div className="actions">
          <div className="left">
            <button
              onClick={() => {
                history.push("/vets");
              }}
              className="btn btn-danger"
            >
              Back
            </button>
            {this.state.vet._id ? (
              <button
                onClick={this.renderConfirmModal}
                className="btn btn-danger"
              >
                Delete
              </button>
            ) : null}
          </div>
          <div className="right">
            <button onClick={this.onSubmit} className="btn btn-primary">
              Save
            </button>
          </div>
        </div>
        <div className="vet-form">
          <div className="basicInfo">
            <div className="headerContainer">
              <h3>Basic Info</h3>
            </div>
            <div className="inputContainer">
              <h4>Name</h4>
              <input
                type="text"
                value={vet.name}
                onChange={e => {
                  this.changeVal("name", e.target.value);
                }}
              />
            </div>
            <div className="inputContainer">
              <h4>Approved</h4>
              <input
                type="checkbox"
                checked={vet.approved}
                onChange={e => {
                  this.changeVal("approved", e.target.checked);
                }}
              />
            </div>
            <div className="inputContainer">
              <h4>Market</h4>
              <DropdownList
                data={this.getMarkets()}
                textField="text"
                valueField="value"
                onChange={item => {
                  this.changeVal("market_id", item.value);
                }}
                value={vet.market_id}
              />
            </div>
            <div className="inputContainer">
              <h4>Phone Number</h4>
              <input
                type="text"
                value={vet.phone_number}
                onChange={e => {
                  this.changeVal("phone_number", e.target.value);
                }}
              />
            </div>
          </div>
          <div className="address">
            <div className="headerContainer">
              <h3>Address</h3>
            </div>
            <div className="inputContainer">
              <h4>Address 1</h4>
              <input
                type="text"
                value={vet.address_1}
                onChange={e => {
                  this.changeVal("address_1", e.target.value);
                }}
              />
            </div>
            <div className="inputContainer">
              <h4>Address 2</h4>
              <input
                type="text"
                value={vet.address_2}
                onChange={e => {
                  this.changeVal("address_2", e.target.value);
                }}
              />
            </div>
            <div className="inputContainer">
              <h4>City</h4>
              <input
                type="text"
                value={vet.city}
                onChange={e => {
                  this.changeVal("city", e.target.value);
                }}
              />
            </div>
            <div className="inputContainer">
              <h4>State</h4>
              <DropdownList
                data={this.getStates()}
                textField="name"
                valueField="id"
                onChange={item => {
                  this.changeVal("state", item.value);
                }}
                value={vet.state}
              />
            </div>
            <div className="inputContainer">
              <h4>Zip Code</h4>
              <input
                type="text"
                value={vet.zip}
                onChange={e => {
                  this.changeVal("zip", e.target.value);
                }}
              />
            </div>
            <div className="inputContainer">
              <h4>Override Geocode?</h4>
              <input
                type="checkbox"
                value={vet.override_geocode}
                onChange={e => {
                  this.changeVal("override_geocode", e.target.checked);
                }}
              />
            </div>
            <div className="inputContainer">
              <h4>Latitude</h4>
              <input
                type="text"
                value={vet.latitude}
                disabled={!vet.override_geocode}
                onChange={e => {
                  this.changeVal("latitude", e.target.value);
                }}
              />
            </div>
            <div className="inputContainer">
              <h4>Longitude</h4>
              <input
                type="text"
                value={vet.longitude}
                disabled={!vet.override_geocode}
                onChange={e => {
                  this.changeVal("longitude", e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  formConstants: state.general.formConstants,
  markets: state.admin.general.markets,
  market: state.admin.general.location
});

const mapDispatchToProps = dispatch => ({
  showSpinner: () => dispatch({ type: "FETCH_REQUEST" }),
  stopSpinner: () => dispatch({ type: "FETCH_SUCCESS" }),
  showMessage: msg => dispatch({ type: "ADD_MESSAGE", payload: msg }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" }),
  modalActions: {
    push: component => dispatch(pushModal(component)),
    pop: () => dispatch(popModal())
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Vet);
