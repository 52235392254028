import React, { Component } from "react";
import PetCardCompact from "../PetCardCompact";
// import PropTypes from 'prop-types';
import { DragSource } from "react-dnd";

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
}

const knightSource = {
  beginDrag(props, monitor, component) {
    return {
      appointment: props.appointment
    };
  },
  endDrag(props, monitor) {
    const item = monitor.getItem();
    const dropResult = monitor.getDropResult();
    if (!monitor.didDrop()) {
      return;
    }
  }
};

class Slot extends Component {
  componentDidMount() {
    this.props.connectDragPreview(<div>Hello</div>);
  }

  render() {
    const { connectDragSource, connectDragPreview, isDragging } = this.props;
    const opacity = isDragging ? 0.4 : 1;
    const width = isDragging ? "80px" : "100%";
    const overflow = isDragging ? "hidden" : "normal";
    return connectDragSource(
      <div style={{ opacity }}>
        <PetCardCompact
          appointment={this.props.appointment}
          onClickAppointmentDetail={this.props.onClickAppointmentDetail}
        />
      </div>
    );
  }
}

export default DragSource("card", knightSource, collect)(Slot);
