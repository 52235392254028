import React from "react";
import Tooltip from "rc-tooltip";

const ZoneBadge = ({ zone, size }) => {
  return zone ? (
    <Tooltip
      placement="top"
      overlay={<div>{zone.name || zone.zone_name}</div>}
      mouseEnterDelay={0.2}
      mouseLeaveDelay={0.1}
      overlayClassName="annotation-tooltip"
    >
      <div
        className={`zone-badge ${size}`}
        style={{
          backgroundColor: zone.background_color,
          color: zone.text_color
        }}
      >
        {zone.id}
      </div>
    </Tooltip>
  ) : null;
};


export default ZoneBadge;
