import React from "react";

const AdminCommentIcon = ({ color, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Combined Shape</title>
    <path
      d="M14 5c0 .906-.313 1.743-.938 2.512-.624.768-1.473 1.375-2.546 1.82C9.443 9.777 8.27 10 7 10c-.365 0-.742-.02-1.133-.063-1.03.912-2.23 1.542-3.594 1.89-.255.074-.552.13-.89.173-.09.01-.168-.013-.238-.07-.07-.058-.116-.133-.137-.227v-.008c-.016-.02-.017-.052-.004-.093.013-.042.018-.068.016-.08-.003-.01.01-.034.035-.073l.047-.07c.005-.01.023-.03.054-.068.032-.036.052-.06.063-.07l.24-.27.27-.296c.055-.06.136-.163.243-.31.106-.145.19-.277.254-.397.062-.12.132-.275.21-.462.08-.188.146-.386.204-.594C1.824 8.45 1.18 7.878.708 7.194.237 6.515 0 5.782 0 5c0-.677.185-1.324.555-1.94.37-.62.867-1.15 1.492-1.6C2.672 1.014 3.417.66 4.28.396 5.147.132 6.053 0 7 0c1.27 0 2.443.223 3.516.668 1.073.445 1.921 1.052 2.546 1.82.626.77.938 1.606.938 2.512zM6.464 2.336L4 8h1.128l.512-1.296h2.456L8.624 8h1.152l-2.44-5.664h-.872zm1.28 3.504h-1.76l.888-2.304.872 2.304z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

AdminCommentIcon.defaultProps = {
  color: "#959595",
  height: "12",
  width: "14"
};

export default AdminCommentIcon;
