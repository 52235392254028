import React, { Component } from "react";
import history from "../../actions/history";
import { connect } from "react-redux";
import { createPetService } from "../../services";
import { PetSelector, PetForm, PageTitleComponent } from "../../components";
import { catchFormServerErrors } from "../../helpers";

// Actions
import { addPet } from "../../actions/admin/clients.actions";
import {
  showFormErrorMessage,
  showSuccessMessage
} from "../../actions/general.actions";

class AddPetPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFormType: props.petType || ""
    };
  }


  static defaultProps = {
    allowAddMultiple: true,
    onCancel: () => history.push("/")
  };

  // TODO: Does success message fire even if it fails?
  handleSubmit = (values, addAnother) => {
    const { currentFormType } = this.state;
    const { clientId, onAddPetSuccess, params } = this.props;
    const model = createPetService.getAddPetModel(values);

    // add animal type to model
    model.animal_type = currentFormType;

    // construct the FormData object
    const formData = new FormData();
    Object.keys(model).forEach(key => {
      if (model[key]) {
        formData.append(key, model[key]);
      }
    });

    return this.props
      .onSubmit(clientId || params.clientId, formData)
      .then(res => catchFormServerErrors(res))
      .then(() => {
        if (onAddPetSuccess) {
          onAddPetSuccess(); // execute the funtion passed hereto as a prop
        } else if (addAnother) {
          this.goBackToPetTypeSelection(); // Display the pet type selection overlay modal
        } else {
          history.push("/"); // Go back to the admin dashboard
        }
      });
  };

  goBackToPetTypeSelection = () => this.setState({ currentFormType: "" });

  render() {
    let { currentFormType } = this.state;
    const {
      allowAddMultiple,
      displayFormError,
      displayFormSuccess,
      formConstants,
      onCancel
    } = this.props;

    return (
      <div className="card">
        {this.state.currentFormType === "" ? (
          <PetSelector
            selectPet={type => this.setState({ currentFormType: type })}
          />
        ) : (
          <div>
            <PageTitleComponent
              title={
                currentFormType !== "Other"
                  ? `Add New ${currentFormType}`
                  : `Add New Pet`
              }
            />
            <PetForm
              allowAddMultiple={allowAddMultiple}
              displayFormError={displayFormError}
              formConstants={formConstants}
              onCancel={onCancel}
              onSubmit={this.handleSubmit}
              onSubmitFail={errors => displayFormError(errors)}
              onSubmitSuccess={() =>
                displayFormSuccess("New pet successfully added to client.")
              }
              petType={currentFormType}
              initialValues={{
                playcare_approved: currentFormType === "Dog",
                temperament_test_status: "Unscheduled"
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  formConstants: state.general.formConstants
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  displayFormError: errors =>
    dispatch(showFormErrorMessage(errors, "addPetForm")),
  displayFormSuccess: message =>
    dispatch(showSuccessMessage(message, "addPetForm")),
  onSubmit: (clientId, formData, addAnother) =>
    dispatch(addPet(clientId, formData, addAnother))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPetPage);
