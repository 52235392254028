import React, { Component } from "react";
import { pushModal, popModal } from "../../actions/general.actions";
import { PageTitleComponent } from "../../components";
import { connect } from "react-redux";

//Pusher for running payments
import { setPusherClient } from "react-pusher";
import PusherConfig from "pusher-js";
import Pusher from "react-pusher";

const pusherClient = new PusherConfig(process.env.REACT_APP_PUSHER_KEY, {
  encrypted: true,
  cluster: "us2"
});

setPusherClient(pusherClient);

export default class ProgressView extends Component {
  state = {
    totalRecords: this.props.totalRecords,
    numSuccess: 0,
    numFailed: 0
  };

  gotMessage = data => {
    document.body.classList.add("locked");
    if (data.status === 1) {
      this.setState({
        totalRecords: data.num_payments,
        numSuccess: 0,
        numFailed: 0
      });
    }
    if (data.status === 2) {
      if (!this.state.totalRecords) {
        this.setState({ totalRecords: data.num_payments });
      }
      if (data.message === "success") {
        this.setState({ numSuccess: ++this.state.numSuccess });
      } else if (data.message === "failed") {
        this.setState({ numFailed: ++this.state.numFailed });
      } else {
        console.log("Payment Error..............", data.message);
      }
    }
    if (data.status === 3) {
      document.body.classList.remove("locked");
      this.setState({ complete: true });
    }
  };
  render() {
    const { totalRecords, numSuccess, numFailed } = this.state;
    const successStyle = { width: (numSuccess / totalRecords) * 100 + "%" };
    const failedStyle = { width: (numFailed / totalRecords) * 100 + "%" };
    return (
      <div className="card">
        <div>
          <Pusher
            channel="update-payment"
            event="update-payment"
            onUpdate={data => this.gotMessage(data)}
          />
          <div className="progress-container">
            <div className="row">
              <div className="col-md-12">
                <div className="progress">
                  <div
                    className={
                      this.state.complete
                        ? "progress-bar progress-bar-success progress-bar-striped"
                        : "progress-bar progress-bar-success progress-bar-striped active"
                    }
                    role="progressbar"
                    aria-valuenow={numSuccess}
                    aria-valuemin="0"
                    aria-valuemax={totalRecords}
                    style={successStyle}
                  />
                  <div
                    className={
                      this.state.complete
                        ? "progress-bar progress-bar-danger progress-bar-striped"
                        : "progress-bar progress-bar-danger progress-bar-striped active"
                    }
                    role="progressbar"
                    aria-valuenow={numFailed}
                    aria-valuemin="0"
                    aria-valuemax={totalRecords}
                    style={failedStyle}
                  />
                </div>
              </div>
            </div>
            {this.state.complete ? (
              <div className="row">
                <div className="col-md-12">
                  <h1>Finished Processing Payments!</h1>
                </div>
                <div className="col-md-6">
                  {this.state.numSuccess} Payments Processed
                </div>
                <div className="col-md-6">
                  {this.state.numFailed} Failed to Process
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
