import React from "react";
import Tooltip from "rc-tooltip";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment-timezone";
import {
  setAppointmentDashboardPage as setAppointmentDashboardPageAction,
  setAppointmentDashboardSort as setAppointmentDashboardSortAction,
  getDashboardAppointments,
  getActivities,
  removeFromDashboard,
  removeNotesFromList,
  approveAppointment,
  changeCity
} from "../../actions/admin/appointments.actions";
import { pushModal, popModal } from "../../actions/general.actions";
import { setSchedulerSelectedDate } from "../../actions/admin/scheduler.actions";
import history from "../../actions/history";
import {
  AppointmentTable,
  PageTitleComponent,
  DateSwitcher,
  CardComponent,
  DefaultModal
} from "../../components";
import { pageItemLimit } from "../../config/general";
import { setPusherClient } from "react-pusher";
import PusherConfig from "pusher-js";
import Pusher from "react-pusher";
import ImageGallery from "react-image-gallery";
import queryString from "query-string";
import axios from "../../services/network.js";

const pusherClient = new PusherConfig(process.env.REACT_APP_PUSHER_KEY, {
  encrypted: true,
  cluster: "us2"
});

setPusherClient(pusherClient);

class AdminDashboardPage extends React.Component {
  state = {
    tab: "needsAttention",
    dashboardStats: {
      needsAttention: [],
      inProgress: [],
      late: [],
      scheduled: [],
      completed: [],
      concierge: []
    },
    images: []
  };


  componentWillMount = () => {
    const {
      getDashboardAppointments,
      tab,
      selectedDate,
      getActivities,
      city
    } = this.props;
    getDashboardAppointments(selectedDate, city ? city._id : null);
    getActivities({ city: city ? city._id : null });
    this.getFavoriteImages(this.props.selectedDate);
  };

  componentWillReceiveProps(nextProps) {
    const { changeCity, getDashboardAppointments, getActivities } = this.props;
    if (nextProps.city._id !== this.props.city._id) {
      changeCity(nextProps.city, this.props.selectedDate);
      getDashboardAppointments(
        nextProps.selectedDate,
        nextProps.city ? nextProps.city._id : null
      );
      getActivities({ city: nextProps.city ? nextProps.city._id : null });
      this.getFavoriteImages(this.props.selectedDate);
    }
    if (nextProps.appointments) {
      const needsAttention = nextProps.appointments.filter(
        a =>
          a.appointment_status.id === 1 ||
          a.appointment_status.id === 3 ||
          this.isCustomVisit(a) ||
          (a.appointment_status.id === 7 &&
            a.sitter &&
            a.sitter._id &&
            !a.clear_from_dashboard) ||
          (a.appointment_status.id === 2 && (!a.sitter || !a.sitter._id))
      );
      const scheduled = nextProps.appointments.filter(
        a =>
          a.appointment_status.id === 2 &&
          a.sitter &&
          a.sitter._id &&
          a.scheduled_start_time
      );
      const inProgress = nextProps.appointments.filter(
        a => a.appointment_status.id === 4
      );
      const completed = nextProps.appointments.filter(
        a => a.appointment_status.id === 5
      );

      const late = nextProps.appointments.filter(a => {
        const offset = moment.tz(a.time_zone).utcOffset();
        const now = moment.utc();
        // get now in utc & window end - UTC offset for client time zone
        // also late has now changed from 15 to 20? 2/14/19
        const window_end = moment
          .utc(a.window_end_time)
          .subtract(offset, "minutes")
          .subtract(20, "minutes");
        return (
          a.appointment_type.id !== 8 &&
          a.appointment_status.id === 2 &&
          a.sitter &&
          a.sitter._id &&
          a.scheduled_start_time &&
          !a.actual_start_time &&
          now.isAfter(window_end)
        );
      });
      const concierge = nextProps.appointments.filter(
        a =>
          a.appointment_type.id === 12 &&
          !(a.appointment_status.id === 7 && (!a.sitter || !a.sitter._id)) &&
          !a.clear_from_dashboard
      );
      this.setState({
        ...this.state,
        dashboardStats: {
          needsAttention: needsAttention,
          scheduled: scheduled,
          inProgress: inProgress,
          completed: completed,
          late: late,
          concierge: concierge
        }
      });
    }
  }

  isCustomVisit = a => {
    return (
      a.appointment_type.id === 11 &&
      !a.clear_from_dashboard &&
      a.appointment_status.id !== 7 &&
      a.appointment_status.id !== 5
    );
  };

  onDateBack = () => {
    const { setSelectedDate, selectedDate, city } = this.props;
    setSelectedDate(
      moment(selectedDate)
        .subtract(1, "day")
        .toDate(),
      city ? city._id : null
    );
    this.getFavoriteImages(
      moment(selectedDate)
        .subtract(1, "day")
        .toDate()
    );
  };

  onDateForward = () => {
    const { setSelectedDate, selectedDate, city } = this.props;
    setSelectedDate(
      moment(selectedDate)
        .add(1, "day")
        .toDate(),
      city ? city._id : null
    );
    this.getFavoriteImages(
      moment(selectedDate)
        .add(1, "day")
        .toDate()
    );
  };

  selectDate = date => {
    const { setSelectedDate, selectedDate, city } = this.props;
    setSelectedDate(moment(date).toDate(), city ? city._id : null);
    this.getFavoriteImages(moment(date).toDate());
  };

  removeFromDashboard = (e, _id) => {
    e.stopPropagation();
    this.props.removeFromDashboard(_id);
  };

  removeNotes = (e, _id) => {
    e.stopPropagation();
    this.props.removeNotesFromList(_id);
  };

  approveAppointment = (e, appointment) => {
    e.stopPropagation();
    const {
      getDashboardAppointments,
      tab,
      selectedDate,
      getActivities,
      city
    } = this.props;
    this.props.approveAppointment(appointment._id, appointment).then(res => {
      this.props.getDashboardAppointments(selectedDate, city._id);
    });
  };

  handleSetSort = key => {
    const { setAppointmentDashboardSort, sorting, tab } = this.props;
    let direction = "DESC";

    if (sorting[tab].key === key && sorting[tab].direction === "DESC")
      direction = "ASC";
    setAppointmentDashboardSort(key, direction);
  };

  renderTab = () => {
    const { appointments, pagination, sorting } = this.props;
    const tab = this.state.tab;

    // handle sort
    const sortDir = sorting[tab].direction;
    const sortKey = sorting[tab].key;

    const sortMethods = {
      client: (a, b) => {
        const nameA = a.contact.first_name.toUpperCase();
        const nameB = b.contact.first_name.toUpperCase();
        if (
          (nameA < nameB && sortDir === "ASC") ||
          (nameA > nameB && sortDir === "DESC")
        ) {
          return -1;
        } else if (
          (nameA > nameB && sortDir === "ASC") ||
          (nameA < nameB && sortDir === "DESC")
        ) {
          return 1;
        }
        return 0;
      },
      timeRequested: (a, b) => {
        const timeA = a.window_start_time;
        const timeB = b.window_start_time;
        if (
          (timeA < timeB && sortDir === "ASC") ||
          (timeA > timeB && sortDir === "DESC")
        ) {
          return -1;
        } else if (
          (timeA > timeB && sortDir === "ASC") ||
          (timeA < timeB && sortDir === "DESC")
        ) {
          return 1;
        }
        return 0;
      },
      zone: (a, b) => {
        const zoneA = a.home.zone.id;
        const zoneB = b.home.zone.id;
        if (sortDir === "ASC") {
          return zoneA - zoneB;
        } else if (sortDir === "DESC") {
          return zoneB - zoneA;
        }
        return 0;
      }
    };

    const sortedAppointments = this.state.dashboardStats[tab];

    return (
      <AppointmentTable
        appointmentCount={appointments.length}
        appointments={sortedAppointments}
        onSort={this.handleSetSort}
        sort={sorting[tab]}
        tab={this.state.tab}
        removeFromDashboard={this.removeFromDashboard}
        approveAppointment={this.approveAppointment}
      />
    );
  };

  setTab = tab => {
    this.setState({ tab: tab });
  };

  goToClient = (e, client) => {
    e.stopPropagation();
    history.push(`/client/${client.contact.client_id}`);
  };

  getPetNames = appointment => {
    if (!appointment.pets || !appointment.pets.length) return null;
    if (appointment.pets.length === 1) return appointment.pets[0].name;
    return appointment.pets.map(p => p.name).join(", ");
  };

  viewReport = (e, appointment) => {
    e.stopPropagation();
    if (process.env.NODE_ENV === "production") {
      window.open(
        `https://app.walkskipper.com/visit/report/${appointment._id}`
      );
    } else {
      window.open(
        `https://skipper-client-stage.herokuapp.com/visit/report/${appointment._id}`
      );
    }
  };

  showAddons = appt => {
    let addOn = appt.addons.find(
      a => (a.id === 1 || a.id === 2) && a.completed
    );
    if (!addOn) return <i className="fa fa-tint specialIconPee" />;
    return null;
  };

  showImages = appt => {
    if (!appt.images || !appt.images.length)
      return <i className="fa fa-picture-o specialIconPic" />;
    return null;
  };

  gotActivity = appt => {
    this.props.addActivity(appt);
  };

  deleteImage = async (img_id, appt_id) => {
    const { modalActions, showMessage, removeMessage, city } = this.props;
    if (img_id && appt_id) {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URI}/admin/image/${img_id}?appointmentId=${appt_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );
      if (res.status === 200) {
        this.getFavoriteImages(this.props.selectedDate);
        showMessage({
          message: "Successfully deleted photo",
          messageType: "success"
        });
        setTimeout(removeMessage, 3000);
      } else {
        showMessage({
          message: "Unable to delete photo at this time",
          messageType: "error"
        });
        setTimeout(removeMessage, 3000);
      }
    }
    modalActions.pop();
  };

  hideFromDashboard = async img_id => {
    const imageToUpdate = {
      _id: img_id,
      hide_from_dashboard: true
    };

    const res = await axios.put(
      `${process.env.REACT_APP_API_URI}/admin/image/${img_id}`,
      imageToUpdate,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      }
    );
    if (res.status === 200) {
      this.getFavoriteImages(this.props.selectedDate);
    }
  };

  getFavoriteImages = async (date) => {
    const qs = queryString.stringify({ date: date });
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URI}/admin/images/favorite?${qs}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      }
    );
    if (data && this.props.appointments) {
      data.map(img => {
        const appt = this.props.appointments.find(
          appt => appt._id === img.appt_id
        );
        if (appt) {
          img.pets = appt.pets;
        }
        return img;
      });
    }
    this.setState({ images: data });
  };

  showDeleteModal = (img_id, appt_id) => {
    const { modalActions } = this.props;
    modalActions.push(
      <DefaultModal
        title="Confirm Delete"
        onDismiss={modalActions.pop}
        options={
          <div>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={modalActions.pop}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                this.deleteImage(img_id, appt_id);
              }}
            >
              Confirm
            </button>
          </div>
        }
      >
        Are you sure you wish to delete this image?
        <br />
        This action cannot be undone.
      </DefaultModal>
    );
  };

  gotAppointment = data => {
    const {
      appt,
      prev_id,
      clear_from_dashboard,
      clear_notes_from_dashboard
    } = data;
    if (
      appt &&
      (this.props.city._id === "ALL" || appt.market_id === this.props.city._id)
    ) {
      this.props.addAppointment(appt, prev_id);
    } else if (clear_from_dashboard) {
      this.props.removeFromDashboard(clear_from_dashboard, true);
    } else if (clear_notes_from_dashboard) {
      this.props.removeNotesFromList(clear_notes_from_dashboard, true);
    }
  };

  gotMessage = data => {
    this.props.updateVisit(data.data);
  };

  render() {
    const { dashboardStats, tab } = this.state;

    return (
      <div>
        <div className="card">
          <DateSwitcher
            onDateBack={this.onDateBack}
            onDateForward={this.onDateForward}
            selectDate={this.selectDate}
            selectedDate={moment(this.props.selectedDate)}
          />
          <Pusher
            channel="activities"
            event="activities"
            onUpdate={data => this.gotActivity(data)}
          />
          <Pusher
            channel="appointment"
            event="new-appointment"
            onUpdate={data => {
              this.gotAppointment(data);
            }}
          />
          <Pusher
            channel="update-visit"
            event="update-visit"
            onUpdate={data => this.gotMessage(data)}
          />
          <div className="dashboard-wrapper">
            <div className="dashboard-tabs">
              <div
                className={`tab-header ${
                  tab === "needsAttention" ? " active" : ""
                }`}
                onClick={() => this.setTab("needsAttention")}
              >
                Needs Attention
                <div className="tab-header-counter">
                  {dashboardStats.needsAttention.length}
                </div>
              </div>
              <div
                className={`tab-header ${tab === "scheduled" ? " active" : ""}`}
                onClick={() => this.setTab("scheduled")}
              >
                Scheduled
                <div className="tab-header-counter">
                  {dashboardStats.scheduled.length}
                </div>
              </div>
              <div
                className={`tab-header ${
                  tab === "inProgress" ? " active" : ""
                }`}
                onClick={() => this.setTab("inProgress")}
              >
                In progress
                <div className="tab-header-counter">
                  {dashboardStats.inProgress.length}
                </div>
              </div>
              <div
                className={`tab-header ${tab === "completed" ? " active" : ""}`}
                onClick={() => this.setTab("completed")}
              >
                Completed
                <div className="tab-header-counter">
                  {dashboardStats.completed.length}
                </div>
              </div>
              <div
                className={`tab-header ${tab === "late" ? " active" : ""}`}
                onClick={() => this.setTab("late")}
              >
                Late
                <div className="tab-header-counter">
                  {dashboardStats.late.length}
                </div>
              </div>
              <div
                className={`tab-header ${tab === "concierge" ? " active" : ""}`}
                onClick={() => this.setTab("concierge")}
              >
                Concierge
                <div className="tab-header-counter">
                  {dashboardStats.concierge.length}
                </div>
              </div>
            </div>

            <div className="dashboard-tab-item">
              <div className="tab-content">{this.renderTab()}</div>
            </div>
          </div>
        </div>
        <div className="card">
          <PageTitleComponent title="Activities Feed" />
          <div className="dashboard-wrapper content row">
            <div className="comments-wrapper col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="dashboard-item comments-client col-lg-8 col-md-8 col-sm-12 col-xs-12">
                <div className="header">
                  Visit Report <span className="fa fa-caret-down" />
                </div>

                <div className="container-scroll">
                  {this.props.activities
                    .filter(
                      a =>
                        a.sitter_notes_to_client ||
                        moment().isAfter(
                          moment(a.actual_end_time).add(15, "minutes")
                        )
                    )
                    .map(appt => {
                      return (
                        <div
                          className="comments-single-wrap"
                          key={`activitiesFeed${appt._id}`}
                          onClick={() =>
                            history.push(`/appointments/${appt._id}`)
                          }
                        >
                          <div className="comments-single-content">
                            <div className="comments-meta col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div className="comments-appointment">
                                <a href="#">
                                  <span className="fa fa-user" />
                                  {appt.sitter
                                    ? appt.sitter.full_name
                                    : "No Name"}
                                </a>
                                <span className="fa fa-long-arrow-right" />
                                <a
                                  href="#"
                                  className="client"
                                  onClick={e => this.goToClient(e, appt)}
                                >
                                  {appt.contact
                                    ? appt.contact.full_name
                                    : "No Name"}
                                </a>
                              </div>
                              <div className="extra-notes row">
                                <div className="col-sm-4">
                                  {appt.client_notes ? (
                                    <Tooltip
                                      placement="top"
                                      overlay={<div>{appt.client_notes}</div>}
                                      mouseEnterDelay={0.2}
                                      mouseLeaveDelay={0.1}
                                      overlayClassName="annotation-tooltip"
                                    >
                                      <div className="extra-notes-item client">
                                        C
                                      </div>
                                    </Tooltip>
                                  ) : null}

                                  {appt.admin_notes_to_sitter ? (
                                    <Tooltip
                                      placement="top"
                                      overlay={
                                        <div>{appt.admin_notes_to_sitter}</div>
                                      }
                                      mouseEnterDelay={0.2}
                                      mouseLeaveDelay={0.1}
                                      overlayClassName="annotation-tooltip"
                                    >
                                      <div className="extra-notes-item client">
                                        A
                                      </div>
                                    </Tooltip>
                                  ) : null}
                                </div>
                                <div className="col-sm-6">
                                  <span className="activities-visit-time">
                                    {`${moment(appt.actual_start_time).format(
                                      "h:mm a"
                                    )} - ${moment(appt.actual_end_time).format(
                                      "h:mm a"
                                    )}`}
                                  </span>
                                </div>
                                <div className="col-sm-1">
                                  <span onClick={e => this.viewReport(e, appt)}>
                                    <i className="fa fa-file-text" />
                                  </span>
                                </div>
                                <div className="col-sm-1">
                                  {appt.pets && appt.pets.length ? (
                                    <Tooltip
                                      placement="top"
                                      overlay={
                                        <div>{this.getPetNames(appt)}</div>
                                      }
                                      mouseEnterDelay={0.2}
                                      mouseLeaveDelay={0.1}
                                      overlayClassName="annotation-tooltip"
                                    >
                                      <div className="extra-notes-item client pawprint">
                                        <i className="fa fa-paw" />
                                      </div>
                                    </Tooltip>
                                  ) : null}
                                </div>
                              </div>
                              {this.showAddons(appt)}
                              {this.showImages(appt)}
                            </div>
                            {appt.sitter_notes_to_client}
                            <div className="comments-single-author">
                              <a href="#">
                                <span className="fa fa-caret-right" />{" "}
                                {moment(appt.actual_end_time).format("h mm A")}
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="dashboard-item comments-team-member col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div className="header">
                  TM Notes to Admin <span className="fa fa-caret-down" />
                </div>

                <div className="container-scroll">
                  {this.props.activities
                    .filter(
                      a =>
                        a.sitter_notes_to_admin && !a.clear_notes_from_dashboard
                    )
                    .map(appt => {
                      return (
                        <div
                          className="comments-single-wrap"
                          key={`activities${appt._id}`}
                          onClick={() =>
                            history.push(`/appointments/${appt._id}`)
                          }
                        >
                          <span
                            className="btn btn-admin-details removeNotes"
                            onClick={e => this.removeNotes(e, appt._id)}
                          >
                            X
                          </span>
                          <div className="comments-single-content">
                            <div className="comments-meta">
                              <div className="tm-info col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <Link to={`/staff/${appt.sitter._id}`}>
                                  {appt.sitter
                                    ? appt.sitter.full_name
                                    : "No Name"}
                                </Link>
                                <span className="fa fa-long-arrow-right" />
                                <Link
                                  to={`/client/${appt.contact.client_id}`}
                                  className="client"
                                >
                                  {appt.contact
                                    ? appt.contact.full_name
                                    : "No Name"}
                                  : {appt.pets.map(p => p.name)}
                                </Link>
                              </div>
                            </div>

                            {appt.sitter_notes_to_admin}
                            <div className="comments-single-author">
                              <a href="#">
                                <span className="fa fa-caret-right" />{" "}
                                {moment(appt.actual_end_time).format("hh:mm A")}
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.images.length > 0 ? (
          <CardComponent title="Gallery">
            <ul className="favorite-image-list">
              <div className="hide-scroll-container">
                <div className="images-container">
                  {
                    //<i
                    //   className="fa fa-chevron-left fa-4 prev-button"
                    //   onClick={this.nextImage}
                    // />
                    // <i
                    //   className="fa fa-chevron-right fa-4 next-button"
                    //   onClick={this.prevImage}/>
                  }
                  {this.state.images.map((img, i) => {
                    return (
                      <li className="favorite-image" key={img._id}>
                        <div className="image-container">
                          <div className="actions">
                            <div className="image-action delete-image">
                              <i
                                className="fa fa-times-circle-o fa-2x"
                                onClick={() => {
                                  this.showDeleteModal(img._id, img.appt_id);
                                }}
                              />
                            </div>
                            <div className="image-action hide-image">
                              <i
                                className="fa fa-eye-slash fa-2x"
                                onClick={() => {
                                  this.hideFromDashboard(img._id);
                                }}
                              />
                            </div>
                          </div>
                          <Link to={`/appointments/${img.appt_id}`}>
                            <Tooltip
                              placement="top"
                              overlay={
                                <div>
                                  {img.pets
                                    ? img.pets.map((p, i) => {
                                        if (i === 0) {
                                          return p.name;
                                        } else {
                                          return ", " + p.name;
                                        }
                                      })
                                    : null}
                                </div>
                              }
                              mouseEnterDelay={0.2}
                              mouseLeaveDelay={0.1}
                              overlayClassName="annotation-tooltip"
                            >
                              <img
                                src={img.original}
                                alt="favorite image not found"
                              />
                            </Tooltip>
                          </Link>
                        </div>
                      </li>
                    );
                  })}
                </div>
              </div>
            </ul>
          </CardComponent>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  appointments: state.admin.appointments.appointmentList,
  dashboardStats: state.admin.general.dashboardStats,
  pagination: state.admin.appointments.pagination,
  tab: state.admin.appointments.dashboardTab,
  sorting: state.admin.appointments.sorting,
  selectedDate: state.admin.scheduler.selectedDate,
  activities: state.admin.appointments.activities,
  city: state.admin.general.location
});

const mapDispatchToProps = dispatch => ({
  addActivity: appt => dispatch({ type: "ADD_ACTIVITY", payload: appt }),
  addAppointment: (appt, prev_id) =>
    dispatch({ type: "ADD_APPOINTMENT", payload: appt, prev_id: prev_id }),
  setAppointmentDashboardPage: (page, tab) =>
    dispatch(setAppointmentDashboardPageAction(page, tab)),
  setAppointmentDashboardSort: (key, direction) =>
    dispatch(setAppointmentDashboardSortAction(key, direction)),
  getDashboardAppointments: (date, city) =>
    dispatch(getDashboardAppointments(date, city)),
  getActivities: city => dispatch(getActivities(city)),
  setSelectedDate: (date, city) =>
    dispatch(setSchedulerSelectedDate(date, city)),
  removeFromDashboard: (_id, state_only) =>
    dispatch(removeFromDashboard(_id, state_only)),
  removeNotesFromList: (_id, state_only) =>
    dispatch(removeNotesFromList(_id, state_only)),
  approveAppointment: (id, app) => dispatch(approveAppointment(id, app)),
  showMessage: message => dispatch({ type: "ADD_MESSAGE", payload: message }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" }),
  modalActions: {
    push: component => dispatch(pushModal(component)),
    pop: () => dispatch(popModal())
  },
  updateVisit: app => dispatch({ type: "UPDATE_VISIT_PUSH", payload: app }),
  changeCity: (city, date, location) =>
    dispatch(changeCity(city, date, location))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDashboardPage);
