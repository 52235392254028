import React from "react";

const PunchCard = ({ client }) => {
  let number = client && client.punch_card ? client.punch_card.number : 0;
  return client && client.punch_card ? (
    <div className="punch2">
      <h5>Multi-Visit-Day Tracker</h5>
      <div className="punchRow2">
        <span className="pawprint2">
          1{number > 0 ? <i className="fa fa-paw fa-2x" /> : null}
        </span>
        <span className="pawprint2">
          2{number > 1 ? <i className="fa fa-paw fa-2x" /> : null}
        </span>
        <span className="pawprint2">
          3{number > 2 ? <i className="fa fa-paw fa-2x" /> : null}
        </span>
        <span className="pawprint2">
          4{number > 3 ? <i className="fa fa-paw fa-2x" /> : null}
        </span>
        <span className="pawprint2">
          5{number > 4 ? <i className="fa fa-paw fa-2x" /> : null}
        </span>
      </div>
    </div>
  ) : null;
};

export default PunchCard;
