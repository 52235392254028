import React, { Component } from "react";
import { DropdownList, Multiselect } from "react-widgets";
import moment from "moment";
import Tooltip from "rc-tooltip";

const yesno = [{ text: "Yes", bool: true }, { text: "No", bool: false }];
const vaccines = ["Rabies", "Bordatella", "DHPP"];
class BasicInfo extends Component {
  constructor(props) {
    super(props);
  }

  getStates = () => {
    return this.props.states.map(s => ({ id: s.id.toString(), name: s.name }));
  };

  render() {
    let { facility } = this.props;
    return (
      <div className="row facilityInfo">
        <div className="col-sm-12">
          <div className="col-sm-12">
            <h3>Basic Info</h3>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="col-sm-6">
            <h4>Facility Name</h4>
            <input
              type="text"
              value={facility.name}
              onChange={e => this.props.updateBasicInfo("name", e.target.value)}
            />
          </div>
          <div className="col-sm-6">
            <h4>Market</h4>
            <DropdownList
              data={this.props.markets.filter(m => m.city !== "All")}
              textField="city"
              valueField="_id"
              onChange={val => this.props.updateBasicInfo("market_id", val)}
              value={facility.market_id}
            />
          </div>
          <div className="col-sm-6">
            <h4>Phone Number</h4>
            <input
              type="text"
              value={facility.contact.phone_number}
              onChange={e =>
                this.props.updateContact("phone_number", e.target.value)
              }
            />
          </div>
          <div className="col-sm-6">
            <h4>Email Address</h4>
            <input
              type="text"
              value={facility.email}
              onChange={e =>
                this.props.updateBasicInfo("email", e.target.value)
              }
            />
          </div>
          {/*<div className="col-sm-6">
            <h4>Time Zone</h4>
            <input type="text" value={facility.name}/>
          </div>*/}
          <div className="col-sm-6">
            <h4>Address 1</h4>
            <input
              type="text"
              value={facility.contact.address.address_line_1}
              onChange={e =>
                this.props.updateAddress("address_line_1", e.target.value)
              }
            />
          </div>
          <div className="col-sm-6">
            <h4>Address 2</h4>
            <input
              type="text"
              value={facility.contact.address.address_line_2}
              onChange={e =>
                this.props.updateAddress("address_line_2", e.target.value)
              }
            />
          </div>
          <div className="col-sm-6">
            <h4>City</h4>
            <input
              type="text"
              value={facility.contact.address.city}
              onChange={e => this.props.updateAddress("city", e.target.value)}
            />
          </div>
          <div className="col-sm-6">
            <h4>State</h4>
            <DropdownList
              value={facility.contact.address.state}
              onChange={val => this.props.updateAddress("state", val.id)}
              data={this.getStates()} // DropdownList: Array of strings or objects or other primitives to populate
              valueField="id" // DropdownList: If data is obj, determines which property is the id
              textField="name" // DropdownList: If data is obj, determines which property gets displayed
              filter="contains" // DropdownList: If you want to filter, include this with string indicating filter type
              parse={(val, name) => val.name}
            />
          </div>
          <div className="col-sm-6">
            <h4>Zip</h4>
            <input
              type="text"
              value={facility.contact.address.zip_code}
              onChange={e =>
                this.props.updateAddress("zip_code", e.target.value)
              }
            />
          </div>
          <div className="col-sm-6">
            <h4>Override Geocode?</h4>
            <input
              type="checkbox"
              checked={facility.contact.address.override_geocode}
              onChange={e =>
                this.props.updateAddress("override_geocode", e.target.checked)
              }
            />
          </div>
          <div className="col-sm-6">
            <h4>Latitude</h4>
            <input
              type="text"
              value={facility.contact.address.latitude}
              onChange={e =>
                this.props.updateAddress("latitude", e.target.value)
              }
            />
          </div>
          <div className="col-sm-6">
            <h4>Longitude</h4>
            <input
              type="text"
              value={facility.contact.address.longitude}
              onChange={e =>
                this.props.updateAddress("longitude", e.target.value)
              }
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="col-sm-12">
            <h4>Public Open Hours</h4>
            <div className="col-sm-6">
              <div>
                <h5 className="centeredH5">Weekdays</h5>
              </div>
              <div className="col-sm-6">
                <DropdownList
                  data={this.props.times}
                  value={facility.public_hours.weekday.open}
                  onChange={val =>
                    this.props.updatePublicHours("open", "weekday", val)
                  }
                />
              </div>
              <div className="col-sm-6">
                <DropdownList
                  data={this.props.times}
                  value={facility.public_hours.weekday.close}
                  onChange={val =>
                    this.props.updatePublicHours("close", "weekday", val)
                  }
                />
              </div>
            </div>
            <div className="col-sm-6">
              <h5 className="centeredH5">Weekends</h5>
              <div className="col-sm-6">
                <DropdownList
                  data={this.props.times}
                  value={facility.public_hours.weekend.open}
                  onChange={val =>
                    this.props.updatePublicHours("open", "weekend", val)
                  }
                />
              </div>
              <div className="col-sm-6">
                <DropdownList
                  data={this.props.times}
                  value={facility.public_hours.weekend.close}
                  onChange={val =>
                    this.props.updatePublicHours("close", "weekend", val)
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <h4>Staffed Hours</h4>
            <div className="col-sm-6">
              <div>
                <h5 className="centeredH5">Weekdays</h5>
              </div>
              <div className="col-sm-6">
                <DropdownList
                  data={this.props.times}
                  value={facility.staffed_hours.weekday.open}
                  onChange={val =>
                    this.props.updateStaffedHours("open", "weekday", val)
                  }
                />
              </div>
              <div className="col-sm-6">
                <DropdownList
                  data={this.props.times}
                  value={facility.staffed_hours.weekday.close}
                  onChange={val =>
                    this.props.updateStaffedHours("close", "weekday", val)
                  }
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div>
                <h5 className="centeredH5">Weekends</h5>
              </div>
              <div className="col-sm-6">
                <DropdownList
                  data={this.props.times}
                  value={facility.staffed_hours.weekend.open}
                  onChange={val =>
                    this.props.updateStaffedHours("open", "weekend", val)
                  }
                />
              </div>
              <div className="col-sm-6">
                <DropdownList
                  data={this.props.times}
                  value={facility.staffed_hours.weekend.close}
                  onChange={val =>
                    this.props.updateStaffedHours("close", "weekend", val)
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="facility-form-subheader">
              <h4>Visit Report Times</h4>
              <Tooltip
                placement="top"
                overlay={
                  <div>
                    These are the times which visit reports will be sent
                  </div>
                }
                mouseEnterDelay={0.2}
                mouseLeaveDelay={0.1}
                overlayClassName="annotation-tooltip"
              >
                <i className="fa fa-info-circle" />
              </Tooltip>
            </div>
            <div className="col-sm-3">
              <div>
                <h5>Morinng</h5>
              </div>
              {/*<DateTimePicker
                value={moment(facility.report_times.morning, "HH:mm").toDate()}
                calendar={false}
                onChange={val => {
                  const report_times = { ...facility.report_times };
                  report_times.morning = moment(val).format("HH:mm");
                  this.props.updateBasicInfo("report_times", report_times);
                }}
              />*/}
            </div>
            <div className="col-sm-3">
              <div>
                <h5>Midday</h5>
              </div>
              {/*<DateTimePicker
                value={moment(facility.report_times.midday, "HH:mm").toDate()}
                calendar={false}
                onChange={val => {
                  const report_times = { ...facility.report_times };
                  report_times.midday = moment(val).format("HH:mm");
                  this.props.updateBasicInfo("report_times", report_times);
                }}
              />*/}
            </div>
            <div className="col-sm-3">
              <div>
                <h5>Evening</h5>
              </div>
              {/*<DateTimePicker
                value={moment(facility.report_times.evening, "HH:mm").toDate()}
                calendar={false}
                onChange={val => {
                  const report_times = { ...facility.report_times };
                  report_times.evening = moment(val).format("HH:mm");
                  this.props.updateBasicInfo("report_times", report_times);
                }}
              />*/}
            </div>
            <div className="col-sm-3">
              <div>
                <h5>Night</h5>
              </div>
              {/*<DateTimePicker
                value={moment(facility.report_times.night, "HH:mm").toDate()}
                calendar={false}
                onChange={val => {
                  const report_times = { ...facility.report_times };
                  report_times.night = moment(val).format("HH:mm");
                  this.props.updateBasicInfo("report_times", report_times);
                }}
              />*/}
            </div>
          </div>
          <div className="col-sm-6">
            <h4>Maximum Pets (Daytime)</h4>
            <input
              type="text"
              value={facility.capacity.day}
              onChange={e => this.props.updateCapacity("day", e.target.value)}
            />
          </div>
          <div className="col-sm-6">
            <h4>Maximum Pets (Nighttime)</h4>
            <input
              type="text"
              value={facility.capacity.night}
              onChange={e => this.props.updateCapacity("night", e.target.value)}
            />
          </div>

          <div className="col-sm-4">
            <h4>Apartment Facility</h4>
            <DropdownList
              data={yesno}
              textField="text"
              valueField="bool"
              value={facility.apartment ? true : false}
              onChange={e => this.props.updateBasicInfo("apartment", e.bool)}
            />
          </div>
          {facility.apartment ? (
            <div className="col-sm-4">
              <h4>Apartment</h4>
              <DropdownList
                parse={(v, n) => v.apartment_id}
                data={this.props.apartments.sort((a, b) => {
                  if (a.name < b.name) return -1;
                  if (a.name > b.name) return 1;
                  return 0;
                })}
                placeholder="Apartment Complex"
                textField="name"
                valueField="_id"
                onChange={e => this.props.updateBasicInfo("apartment_id", e)}
                value={facility.apartment_id}
              />
            </div>
          ) : null}
          {facility.apartment ? (
            <div className="col-sm-4">
              <h4>Apartment Restricted?</h4>
              <DropdownList
                data={yesno}
                textField="text"
                valueField="bool"
                value={facility.apartment_restricted ? true : false}
                onChange={e =>
                  this.props.updateBasicInfo("apartment_restricted", e.bool)
                }
              />
            </div>
          ) : null}

          <div className="col-sm-12">
            <h4>Vaccines Required</h4>
            <Multiselect
              data={vaccines}
              allowCreate
              value={facility.vaccines_required}
              onChange={vax =>
                this.props.updateBasicInfo("vaccines_required", vax)
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default BasicInfo;
