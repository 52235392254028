import React from "react";
import { PageTitleComponent } from "../../";
import KMLMap from "./KMLMap";

export default class WalkMapCard extends React.Component {

  render() {
    const { appointment } = this.props;
    const coordinates = appointment.home.address.coordinates;

    if (appointment && appointment.appointment_status.name === "Completed") {
      return (
        <div className="card appointment-detail-page">
          <PageTitleComponent title="Walk Map" />
          {appointment.kml ? (
            <KMLMap
              kmlUrl={appointment.kml}
              marker={
                coordinates && {
                  lat: coordinates.latitude,
                  lng: coordinates.longitude
                }
              }
            />
          ) : (
            <div className="message">
              We're sorry, there's no walk map for this appointment.
            </div>
          )}
        </div>
      );
    }

    return null;
  }
}
