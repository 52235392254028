import { APIService } from "../../services";
// import { history } from 'react-router-dom'
import * as ActionTypes from "./actionTypes";
import { dispatchFetchFailure } from "../helpers";
import { showSuccessMessage, popModal } from "../general.actions";
import history from "../history";
import queryString from "query-string";
import { getUserData } from "../../services/firebaseHelpers";

export function getAllStaff(filters) {
  const context = "getAllStaff";
  return function(dispatch) {
    const qs = queryString.stringify(filters);
    console.log("this one oging....", qs);
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .getAllStaff(qs)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch({
            type: "GET_ALL_STAFF_SUCCESS",
            payload: {
              staffList: response
            }
          });
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function getAllStaffWithAppointments(filters = {}, sort = {}) {
  const context = "getAllStaff";
  return function(dispatch) {
    const qs = queryString.stringify(filters);
    dispatch({ type: "FETCH_REQUEST", context });

    return APIService.admin
      .getAppointmentsAllSitters(qs)
      .then(maybe => {
        dispatch({
          type: "SET_STAFF_APPOINTMENTS",
          payload: maybe
        });
        dispatch({ type: "FETCH_SUCCESS", context });
      })
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function getStaffWithoutAppointments() {
  return {
    type: "GET_STAFF_WITHOUT_APPOINTMENTS"
  };
}

export function getStaffMember(staffId) {
  const context = "getStaffMember";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .getStaffMember(staffId)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch({
            type: ActionTypes.GET_STAFF_MEMBER_SUCCESS,
            payload: {
              staffMember: response
            }
          });
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function createStaff(model) {
  const context = "createStaff";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .createStaffMember(model)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch({ type: ActionTypes.CREATE_STAFF_SUCCESS });
          dispatch(popModal());
          history.push("/stafflist");
        },
        error => error.response.json()
      )
      .then(res => {
        if (
          res &&
          res.error &&
          res.error.status === 409 &&
          res.error.code === 2001
        ) {
          res.message = "Oops! A user with that email address already exists.";
          res.email = true;
        }
        return dispatchFetchFailure(res, context, dispatch);
      })
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function updateStaffMember(staffId, model) {
  const context = "updateStaffMember";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .updateStaffMember(staffId, model)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch({ type: ActionTypes.UPDATE_STAFF_MEMBER_SUCCESS });
          history.push("/stafflist");
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function staffActivation(staffId, active) {
  const context = "staffActivation";
  let successMessage =
    "Team Member successfully deactivated and removed from any outstanding appointments.";
  if (active) {
    successMessage = "Team Member successfully activated.";
  }
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .staffActivation(staffId, { active })
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch(getStaffMember(staffId)); // update the client information
          dispatch(showSuccessMessage(successMessage, context));
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function loadStaffData(filters) {
  const context = "loadStaffData";
  let qs = queryString.stringify(filters);
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .loadStaffData(qs)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch({ type: "STAFF_DATA", payload: response });
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}
