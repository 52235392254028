import firebase from "firebase";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

const clientConfig = {
  apiKey: process.env.REACT_APP_CLIENT_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_CLIENT_FIREBASE_URL,
  databaseURL: process.env.REACT_APP_CLIENT_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_CLIENT_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_CLIENT_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_CLIENT_MESSAGE_ID
};

var clientFirebase = firebase.initializeApp(clientConfig, "secondary");

// console.log('config??????', config)

const fire = firebase.initializeApp(config);


export const firebaseAuth = firebase.auth;
export const clientFirebaseAuth = clientFirebase;
