import React, { Component } from "react";
import { PageTitleComponent } from "../";

export default class CardComponent extends Component {
  render() {
    return (
      <div className="card">
        <PageTitleComponent title={this.props.title} />
        <div className="card-content">{this.props.children}</div>
      </div>
    );
  }
}
