import React, { Component } from "react";
import axios from "../../services/network.js";
import { connect } from "react-redux";
import history from "../../actions/history";
import moment from "moment";
import { ApartmentForm, CardComponent } from "../../components";

class AddApartment extends Component {
  saveApartment = async apt => {
    let { showMessage, removeMessage, showSpinner } = this.props;

    let res;
    if (apt && !apt.name) {
      showMessage({
        message: "Please add a name.",
        messageType: "error"
      });
      setTimeout(removeMessage, 3000);
      return;
    }

    if (apt && apt.playcare && apt.playcare.enabled) {
      if (!apt.playcare.staff || !apt.playcare.staff.length) {
        showMessage({
          message: "Can't enable playcare with no assigned staff!",
          messageType: "error"
        });
        setTimeout(removeMessage, 3000);
        return;
      }
    }

    if (apt.pet_perks) {
      if (!apt.percentage) {
        showMessage({
          message: "Please add a discount percentage.",
          messageType: "error"
        });
        setTimeout(removeMessage, 3000);
        return;
      }

      if (!apt.start_date) {
        showMessage({
          message: "Please add a start date for pet perks.",
          messageType: "error"
        });
        setTimeout(removeMessage, 3000);
        return;
      }
    }

    showSpinner();

    if (apt && apt.name) {
      const market_id = this.props.market._id;
      if (apt.playcare) {
        apt.playcare.staff = apt.playcare.staff.map(s => ({
          full_name: s.text,
          _id: s.value
        }));
      }
      if (market_id !== "ALL") {
        res = await axios.post(
          `${process.env.REACT_APP_API_URI}/admin/apartments`,
          {
            name: apt.name,
            playcare: apt.playcare,
            market_id: market_id,
            pet_perks: apt.pet_perks ? true : false,
            percentage: apt.percentage,
            start_date: apt.start_date,
            expiration_date: moment()
              .add(10, "years")
              .toDate(),
            concierge: apt.concierge,
            contact: apt.contact
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("accessToken")
            }
          }
        );
      }
    }
    if (res && res.status === 200 && res.data) {
      const { data } = res;
      this.props.showMessage({
        message: "Successfully added new apartment",
        messageType: "success"
      });
      setTimeout(this.props.removeMessage, 3000);
      this.props.stopSpinner();
      return history.push(`editapartment/${data.saved._id}`);
    } else {
      this.props.showMessage({
        message: "Failed to add new apartment, (check selected market)",
        messageType: "error"
      });
      setTimeout(this.props.removeMessage, 3000);
    }
    this.props.stopSpinner();
  };
  render() {
    return (
      <CardComponent title="Add Apartment">
        <ApartmentForm
          saveApartment={this.saveApartment}
          market_id={this.props.market._id}
        />
      </CardComponent>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showSpinner: () => dispatch({ type: "FETCH_REQUEST" }),
  stopSpinner: () => dispatch({ type: "FETCH_SUCCESS" }),
  showMessage: msg => dispatch({ type: "ADD_MESSAGE", payload: msg }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" })
});

const mapStateToProps = state => ({
  market: state.admin.general.location
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddApartment);
