import React from "react";
import { connect } from "react-redux";

// Actions
import {
  pushModal,
  popModal,
  showFormErrorMessage,
  showSuccessMessage
} from "../../actions/general.actions";
import {
  addZone as addZoneAction,
  deleteZone as deleteZoneAction,
  updateZone as updateZoneAction
} from "../../actions/admin/settings.actions";

// Child components
import { PageTitleComponent, ZoneSettings } from "../../components";

class SystemSettings extends React.Component {

  render() {
    const {
      addZone,
      deleteZone,
      displayFormError,
      displayFormSuccess,
      modalActions,
      updateZone,
      zones
    } = this.props;

    return (
      <div className="card admin-settings">
        <PageTitleComponent title="System Settings" />
        <div className="container">
          <div className="row">
            <h3 className="col-sm-12 title">Zones</h3>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <ZoneSettings
                displayFormError={displayFormError}
                displayFormSuccess={displayFormSuccess}
                modalActions={modalActions}
                addZone={addZone}
                deleteZone={deleteZone}
                updateZone={updateZone}
                zones={zones}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  zones: state.general.formConstants.zones
});

const mapDispatchToProps = dispatch => ({
  displayFormError: errors =>
    dispatch(showFormErrorMessage(errors, "adminAppSettings")),
  displayFormSuccess: message =>
    dispatch(showSuccessMessage(message, "adminAppSettings")),
  modalActions: {
    push: component => dispatch(pushModal(component)),
    pop: () => dispatch(popModal())
  },
  addZone: model => dispatch(addZoneAction(model)),
  deleteZone: zoneId => dispatch(deleteZoneAction(zoneId)),
  updateZone: (zoneId, model) => dispatch(updateZoneAction(zoneId, model))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemSettings);
