import { SubmissionError } from "redux-form";
import moment from "moment-timezone";

// This gets called by redux-form forms' onSubmit() prop so that it can recognize a failure and
// dispatch @@redux-form/SET_SUBMIT_FAILED instead of @@redux-form/SET_SUBMIT_SUCCEEDED. This
// allows us to recognize true successful form submissions and display green success notification
// reliably.
export const catchFormServerErrors = res => {
  if (res) {
    // This SubmissionError triggers the form's onSubmitFail(). onSubmitFail() also gets triggered
    // when client-side field validation rules fail, and it shows an error message to that effect.
    // Because this SubmissionError is triggered in response to a FETCH_FAILURE action, an error
    // message has already been displayed. serverError: true will prevent display of 2nd error.
    throw new SubmissionError({ ...res, serverError: true });
  }
};

/**************************
  TIME/DATE UTILITIES
*/

// Params: moment(), integer
export const roundMomentToMinute = (momentTime, minuteInterval) => {
  const minute =
    Math.round(momentTime.minute() / minuteInterval) * minuteInterval;
  return momentTime.minute(minute).second(0);
};

// This parses time keyed into time field for appointments by clients.
// It currently rounds to nearest 10-min mark, with a min of 10am and max of 5pm.
export const parseClientApptTimeInput = input => {
  // Turn the input string into a moment for easier comparisons
  const timeString = `${moment().format("YYYY-MM-DD")} ${input}`;
  let timeMoment = moment(timeString, "YYYY-MM-DD h:mm a");

  // if the hour is less than 10, set it to 10am
  // if (timeMoment.hour() < 10) {
  //   timeMoment = timeMoment.hour(10).minute(0).second(0);

  // if it's after 5pm (17), set it to 5pm
  // } else if ((timeMoment.hour() === 17 && timeMoment.minute() > 0) || timeMoment.hour() >= 18) {
  //   timeMoment = timeMoment.hour(17).minute(0).second(0);
  // }

  // round that to the nearest 10-minute interval
  return roundMomentToMinute(timeMoment, 15).toDate();
};

// Helper that sets date to today if provided moment is before today. Otherwise, returns provided moment.
const preventDateBeforeToday = momentTime => {
  const currentDate = moment().startOf("day");
  if (momentTime.isBefore(currentDate)) {
    return moment().toDate();
  }
  return momentTime.toDate();
};

// This parses date keyed into date field for appointments.
export const parseDateNotBeforeToday = input => {
  if (input.toLowerCase() === "today") {
    return new Date();
  } else if (moment(input).isValid()) {
    return preventDateBeforeToday(moment(input));
  }
};

// This parses time keyed into time field, combining it with the provided date.
// (The widget expects a full JS Date.)
export const parseTimeInput = (time, date = new Date()) => {
  const reqDate = moment(date).format("M/D/YYYY");
  const dateTime = moment(`${reqDate} ${time}`, "M/D/YYYY h:mm A").toDate();
  return dateTime;
};

// Takes date from one JS Date and time from one JS Date and combines them
// Params: js Date(), js Date()
// Returns: string
export const combineDateTime = (date, time) => {
  const requestedTimeString = moment(time).format("H:mm");
  return moment(`${date.toDateString()} ${requestedTimeString}`).format(
    "ddd MMM D YYYY H:mm"
  );
};

/**************************
  PRICE UTILITIES
*/

// Takes appointmentTypeId and addonIds from appointment and gets prices for those from formConstants.
// Returns: string (10.00)
export const calculateAppointmentPrice = (
  formConstants,
  appointmentTypeId,
  addonIds = []
) => {
  if (formConstants && appointmentTypeId) {
    const basePrice = formConstants.appointmentTypes.find(
      type => type.id === appointmentTypeId
    ).price;

    return (basePrice / 100).toFixed(2);
  }
};

// Strips all but numbers from a form input.
export const onlyNumbers = value => {
  if (!value || value === "0") return value;
  const onlyNums = value.replace(/[^\d]/g, "").replace(/^0+/, "");
  if (onlyNums.length) return onlyNums;
  return 0;
};

// Convert number to dollar amount. (E.g., 1495 > $14.95)
export const formatNumsToDollars = value => {
  if (value !== undefined && value !== null) {
    return `$${(value / 100).toFixed(2)}`;
  }
  return "";
};
