import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ZoneBadge } from "../../";
import Tooltip from "rc-tooltip";

export default class ClientTableRow extends Component {

  getPets = pets => {
    if (!pets || !pets.length) return "N/A";
    let filtered = pets
      .filter(p => p && p._id)
      .map(p => p.name)
      .join(", ");
    return filtered;
  };

  getMarketName = () => {
    const { client, markets } = this.props;
    if (!client || !client.market_id || !markets) return null;
    let market = markets.find(m => m._id === client.market_id);
    return market ? market.city : null;
  };

  render() {
    const { client } = this.props;
    return (
      <Link to={`/client/${client._id}`}>
        <div className="user-list-item">
          <div className="user-list-item-content username-container col-lg-3 col-md-3 col-sm-3 col-xs-8">
            <div className="user-list-name">
              <Tooltip
                placement="top"
                overlay={<div>Active</div>}
                mouseEnterDelay={0.2}
                mouseLeaveDelay={0.1}
                overlayClassName="annotation-tooltip"
              >
                <div className="flags-user-active-green"></div>
              </Tooltip>
              {client.full_name ? client.full_name : "No Name Here"}
            </div>
          </div>
          <div className="user-list-item-content col-lg-2 col-md-2 col-sm-2 col-xs-2">
            <div>{this.getMarketName()}</div>
          </div>
          <div className="user-list-item-content col-lg-2 col-md-2 col-sm-2 col-xs-2">
            {client.zones
              ? client.zones.map(zone => (
                  <ZoneBadge
                    size="medium"
                    zone={zone}
                    key={`zone${client._id}${zone.id}`}
                  />
                ))
              : null}
          </div>
          <div className="user-list-item-content col-lg-1 col-md-1 col-sm-1 hidden-xs">
            <div>{client.contact.address.zip_code}</div>
          </div>
          <div className="user-list-item-content col-lg-2 col-md-2 col-sm-2 hidden-xs">
            <div>{this.getPets(client.pets)}</div>
          </div>
          <div className="user-list-item-content col-lg-2 col-md-2 col-sm-2 hidden-xs">
            <div>
              {client.household && client.household.consultation_status
                ? client.household.consultation_status
                : "unscheduled"}
            </div>
          </div>
        </div>
      </Link>
    );
  }
}
