import React, { Component } from "react";
import { connect } from "react-redux";
import history from "../../actions/history";
import axios from "../../services/network.js";

import { CardComponent, PromoForm } from "../../components";

class AddPromo extends Component {
  state = {
    partners: [],
    promo: null,
    edit: false
  };

  componentWillMount = () => {
    const { showSpinner, stopSpinner, market } = this.props;
    showSpinner();
    this.getPartners(market._id);
    stopSpinner();
  };

  componentWillReceiveProps = props => {
    if (props.market._id !== this.props.market._id) {
      this.getPartners(props.market._id);
    }
  };

  getPartners = async id => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URI}/admin/partners?market_id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      }
    );
    this.setState({ partners: data });
  };

  createPromo = async (promo, valid) => {
    const { showSpinner, stopSpinner, showMessage, removeMessage } = this.props;
    showSpinner();
    if (valid) {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URI}/admin/promo`,
          promo,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            }
          }
        );
        showMessage({
          message: "Promo code successfully created",
          messageType: "success"
        });
        setTimeout(removeMessage, 3000);
        history.push(`/promos`);
      } catch (e) {
        showMessage({
          message: "Error creating promo code (possible duplicate)",
          messageType: "error"
        });
        setTimeout(removeMessage, 3000);
      }
    } else {
      showMessage({
        message: "Invalid data, check your form for errors",
        messageType: "error"
      });
      setTimeout(removeMessage, 3000);
    }
    stopSpinner();
  };

  render() {
    return (
      <CardComponent title="Add Promo">
        <PromoForm
          partners={this.state.partners}
          markets={this.props.markets}
          savePromo={this.createPromo}
        />
      </CardComponent>
    );
  }
}

const mapStateToProps = state => ({
  market: state.admin.general.location,
  markets: state.admin.general.markets
});

const mapDispatchToProps = dispatch => ({
  showSpinner: () => dispatch({ type: "FETCH_REQUEST" }),
  stopSpinner: () => dispatch({ type: "FETCH_SUCCESS" }),
  showMessage: msg => dispatch({ type: "ADD_MESSAGE", payload: msg }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPromo);
