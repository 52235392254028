import React, { Component } from "react";
import { connect } from "react-redux";
import { CardComponent, MarketForm } from "../../components";
import axios from "../../services/network.js";

class AddMarket extends Component {
  saveMarket = async market => {
    const { showSpinner, showMessage, removeMessage, stopSpinner } = this.props;
    showSpinner();
    if (market && market.city && market.state) {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/market`,
        market,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken")
          }
        }
      );
      if (res.status === 200) {
        showMessage({
          message: `Successfully updated market in ${market.city}, ${market.state}`,
          messageType: "success"
        });
      } else {
        showMessage({
          message: "Unable to update the market at this time",
          messageType: "error"
        });
      }
      setTimeout(removeMessage, 3000);
    }
    stopSpinner();
  };
  render() {
    return (
      <CardComponent title="Add Market">
        <MarketForm saveMarket={this.saveMarket} />
      </CardComponent>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showSpinner: () => dispatch({ type: "FETCH_REQUEST" }),
  stopSpinner: () => dispatch({ type: "FETCH_SUCCESS" }),
  showMessage: msg => dispatch({ type: "ADD_MESSAGE", payload: msg }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" })
});

export default connect(
  null,
  mapDispatchToProps
)(AddMarket);
