import React, { Component } from "react";
import moment from "moment-timezone";
import Tooltip from "rc-tooltip";
import PetCardCompact from "./scheduler/PetCardCompact";
import Timeblock from "./Timeblock";
import { DropdownList } from "react-widgets";
import _ from "lodash";
import history from "../../actions/history";

class ResultsModal extends Component {
  getLateVisits = () => {
    const { results, appointments } = this.props;
    let flattened = [];
    for (let tm in results.solution) {
      if (results.solution[tm] && results.solution[tm].length) {
        let findLate = results.solution[tm].filter(visit => visit.too_late);
        if (findLate.length) {
          findLate.forEach(f => flattened.push(f));
        }
      }
    }

    return (
      <div>
        <div className="row apptHeader">
          <div className="col-sm-3">Client</div>
          <div className="col-sm-3">Window</div>
          <div className="col-sm-2">Duration</div>
          <div className="col-sm-2">Scheduled Time</div>
          <div className="col-sm-2">Team Member</div>
        </div>
        {flattened.map(appt => {
          let findAppt = appointments.find(a => a._id == appt.location_id);
          return findAppt ? (
            <div className="row" key={`resultsRow${findAppt._id}`}>
              <div className="col-sm-3">{findAppt.contact.full_name}</div>
              <div className="col-sm-3">
                {moment(findAppt.window_start_time).format("hh:mm")} -{" "}
                {moment(findAppt.window_end_time).format("hh:mm")}
              </div>
              <div className="col-sm-2">
                {findAppt.appointment_type.duration_in_minutes} Min.
              </div>
              <div className="col-sm-2">
                {moment(findAppt.scheduled_start_time).format("hh:mm")}
              </div>
              <div className="col-sm-2">{findAppt.sitter.full_name}</div>
            </div>
          ) : null;
        })}
      </div>
    );
  };

  render() {
    const { results, appointments } = this.props;
    return (
      <div className="card row scheduleCard">
        <div className="col-sm-12 scheduleHeader">
          <h3>Results</h3>
        </div>
        <div className="col-sm-6">
          <span>Number Late: </span>
          {results.num_late_visits ? results.num_late_visits : 0}
        </div>
        <div className="col-sm-6">
          <span>Number Unscheduled: </span>
          {results.num_unserved ? results.num_unserved : 0}
        </div>
        <div className="col-sm-6">
          <span>Total Travel Time: </span>
          {results.total_travel_time} Min.
        </div>
        <div className="col-sm-6">
          <span>Total Idle Time: </span>
          {results.total_idle_time} Min.
        </div>
        <div className="col-sm-12">
          {results.unserved ? <h3>Unserved: </h3> : null}
          {results.unserved ? (
            <div className="row apptHeader">
              <div className="col-sm-3">Client</div>
              <div className="col-sm-3">Window</div>
              <div className="col-sm-3">Duration</div>
              <div className="col-sm-3">Reason</div>
            </div>
          ) : null}
          {results.unserved && appointments
            ? Object.keys(results.unserved).map(apptId => {
                let findAppt = appointments.find(a => a._id == apptId);
                return findAppt ? (
                  <div className="row" key={`resultsRow${findAppt._id}`}>
                    <div className="col-sm-3">{findAppt.contact.full_name}</div>
                    <div className="col-sm-3">
                      {moment(findAppt.window_start_time).format("hh:mm")} -{" "}
                      {moment(findAppt.window_end_time).format("hh:mm")}
                    </div>
                    <div className="col-sm-3">
                      {findAppt.appointment_type.duration_in_minutes} Min.
                    </div>
                    <div className="col-sm-3">{results.unserved[apptId]}</div>
                  </div>
                ) : null;
              })
            : null}
        </div>
        <div className="col-sm-12">
          <h3>Late Visits</h3>
          {this.getLateVisits()}
        </div>
      </div>
    );
  }
}

export default ResultsModal;
