import React, { Component } from "react";
import { connect } from "react-redux";
import { DropdownList } from "react-widgets";
import history from "../../../../actions/history";

class PartnerForm extends Component {
  state = {
    name: "",
    market_id: ""
  };

  componentWillMount = () => {
    if (this.props.partner) {
      this.setState({
        name: this.props.partner.name,
        market_id: this.props.partner.market_id
      });
    }
  };

  componentWillReceiveProps = props => {
    if (props.partner) {
      this.setState({
        name: props.partner.name,
        market_id: props.partner.market_id
      });
    }
  };

  validateForm = () => {
    return this.state.name && this.state.market_id;
  };

  render() {
    return (
      <div className="partner-form-container">
        <div className="row">
          <div className="col-md-3">
            <div className="form-component-wrapper">
              <input
                type="text"
                placeholder="Partner Name"
                value={this.state.name}
                onChange={e => {
                  this.setState({ name: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-component-wrapper">
              <label>Market</label>
              <DropdownList
                data={this.props.markets.filter(m => m._id !== "ALL")}
                textField={"city"}
                valueField={"_id"}
                value={this.state.market_id}
                onChange={item => {
                  this.setState({ market_id: item._id });
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <button
              className="btn btn-primary"
              onClick={() => {
                this.props.savePartner(this.state, this.validateForm());
              }}
            >
              Save
            </button>
            <button
              className="btn btn-danger pull-right normal-size-btn"
              onClick={e => {
                history.push("/partners");
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  markets: state.admin.general.markets
});

export default connect(mapStateToProps)(PartnerForm);
