import React, { Component } from "react";
import axios from "../../services/network";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { pushModal, popModal } from "../../actions/general.actions";
import { VetRowItem } from "../../components";

class VetList extends Component {
  state = {
    vets: []
  };

  componentWillMount() {
    this.loadVets();
  }

  componentWillReceiveProps = nextProps => {
    const { market } = this.props;
    if (nextProps.market && market && nextProps.market._id != market._id) {
      this.loadVets(nextProps.market._id);
    }
  };

  loadVets = async (id = null) => {
    const {
      market,
      showSpinner,
      stopSpinner,
      showMessage,
      removeMessage
    } = this.props;
    showSpinner();
    if (market || id) {
      try {
        const { data } = await axios.get(
          `/admin/vets?marketId=${id ? id : market._id}`
        );
        this.setState({ vets: data });
      } catch (e) {
        if (e.data && e.data.error) {
          showMessage({
            message: e.data.error,
            messageType: "error"
          });
          setTimeout(removeMessage, 3000);
        }
      }
    }
    stopSpinner();
  };

  renderVets = () => {
    const { vets } = this.state;
    const { markets } = this.props;
    if (!vets || !vets.length) return <div>No Vets Found....</div>;

    return vets.map(v => {
      const ourMarket = markets.find(m => m._id === v.market_id);
      return <VetRowItem key={v._id} vet={v} market={ourMarket} />;
    });
  };

  render() {
    return (
      <div className="card">
        <div className="actions">
          <Link to={"/vet/create"}>
            <button type="button" className="btn btn-primary">
              Add Vet
            </button>
          </Link>
        </div>
        <div className="vetTable">
          <div className="header">
            <div>Vet Name</div>
            <div>Address</div>
            <div>Market</div>
          </div>
          <div className="body">{this.renderVets()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  market: state.admin.general.location,
  markets: state.admin.general.markets
});

const mapDispatchToProps = dispatch => ({
  showSpinner: () => dispatch({ type: "FETCH_REQUEST" }),
  stopSpinner: () => dispatch({ type: "FETCH_SUCCESS" }),
  showMessage: msg => dispatch({ type: "ADD_MESSAGE", payload: msg }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" }),
  modalActions: {
    push: component => dispatch(pushModal(component)),
    pop: () => dispatch(popModal())
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VetList);
