import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import axios from "../../../services/network.js";
import history from "../../../actions/history";
// Actions
import { pushModal, popModal } from "../../../actions/general.actions";
import { cancelAppointment as cancelAppointmentAction } from "../../../actions/admin/appointments.actions";

// Components
import { DefaultModal } from "../../";

class AdminCancelAppointmentModal extends Component {
  state = {
    cancelSeries: false,
    waiveLatePenalty: false
  };

  confirmCancel = async e => {
    e.preventDefault();
    const { appointment, modalActions, submitCancel } = this.props;
    await axios.post(
      `${process.env.REACT_APP_API_URI}/admin/appointments/${appointment._id}/delete`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
        }
      }
    );
    // Here, success message gets triggered in action
    modalActions.pop();
    history.push("/");
  };

  render() {
    const { cancelSeries } = this.state;
    const { appointment, modalActions } = this.props;
    const isRecurring = !!appointment.recurrence;

    return (
      <DefaultModal
        title="Delete Appointment"
        onDismiss={modalActions.pop}
        options={
          this.state.checked ? (
            <div>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={modalActions.pop}
              >
                no no no
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={this.confirmCancel}
              >
                Delete Forever
              </button>
            </div>
          ) : null
        }
      >
        <p>
          Are you sure you want to delete this appointment? This will delete it
          forever, as well as any related line items. This cannot be undone.
        </p>
        <label>I Understand</label>
        <input
          type="checkbox"
          checked={this.state.checked}
          onClick={() => this.setState({ checked: !this.state.checked })}
        />
      </DefaultModal>
    );
  }
}

const mapStateToProps = state => ({
  appointment: state.admin.appointments.appointmentDetail
});

const mapDispatchToProps = dispatch => ({
  submitCancel: (appointmentId, model) =>
    dispatch(cancelAppointmentAction(appointmentId, model)),
  modalActions: {
    push: component => dispatch(pushModal(component)),
    pop: () => dispatch(popModal())
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCancelAppointmentModal);
