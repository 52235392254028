import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import { pushModal, popModal } from "../../../actions/general.actions";
import { clientActivation as clientActivationAction } from "../../../actions/admin/clients.actions";

// Components
import { DefaultModal } from "../../";

class ClientActivationConfirmationModal extends Component {

  onConfirm = e => {
    e.preventDefault();
    const {
      activateClient,
      clientId,
      clientActivation,
      modalActions
    } = this.props;
    clientActivation(clientId, activateClient); // Here, success message gets triggered in action
    modalActions.pop();
  };

  render() {
    const { activateClient, modalActions } = this.props;

    return (
      <DefaultModal
        title={activateClient ? "Activate Client" : "Deactivate Client"}
        onDismiss={modalActions.pop}
        options={
          <div>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={modalActions.pop}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.onConfirm}
            >
              {activateClient ? "Activate" : "Deactivate"}
            </button>
          </div>
        }
      >
        <p>
          Are you sure you want to {activateClient ? "activate" : "deactivate"}{" "}
          this client?
        </p>
        {activateClient ? (
          <p>The client will be able to log in again after activation.</p>
        ) : (
          <p>
            This action will cancel all of the client's pending, approved, or
            change-requested appointments and lock the client out of the system.
          </p>
        )}
      </DefaultModal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  clientActivation: (petId, clientId) =>
    dispatch(clientActivationAction(petId, clientId)),
  modalActions: {
    push: component => dispatch(pushModal(component)),
    pop: () => dispatch(popModal())
  }
});

export default connect(
  null,
  mapDispatchToProps
)(ClientActivationConfirmationModal);
