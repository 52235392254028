import React, { Component } from "react";

// Each {option} needs to have an `id` and `name` property.
export default class CheckboxGroupField extends Component {

  static defaultProps = {
    disabled: false
  };

  render = () => {
    const { input, options } = this.props;
    // const { touched, error, warning } = this.props.meta;

    // TODO: Set up error class for checkbox inputs
    // const errorClass = touched && error ? ' error-text error-border' : '';

    return (
      <div>
        {options.map((option, index) => (
          <div
            className="simple-checkbox-field"
            key={`checkbox-group-${input.name}-${index}`}
          >
            <div className="simple-checkbox-input">
              <input
                type="checkbox"
                id={`checkbox-group-${input.name}-${index}`}
                checked={input.value.includes(option.id)}
                {...input}
                onChange={event => {
                  const newValue = [...input.value];
                  if (event.target.checked) {
                    newValue.push(option.id);
                  } else {
                    newValue.splice(newValue.indexOf(option.id), 1);
                  }
                  return input.onChange(newValue);
                }}
              />
              <label htmlFor={`checkbox-group-${input.name}-${index}`} />
            </div>

            <span
              className="simple-checkbox-label"
              onClick={() => {
                const newValue = [...input.value];
                if (input.value.includes(option.id)) {
                  newValue.splice(newValue.indexOf(option.id), 1);
                } else {
                  newValue.push(option.id);
                }
                return input.onChange(newValue);
              }}
            >
              {option.name}
            </span>
          </div>
        ))}
      </div>
    );
  };
}
