import React, { Component } from "react";
import { connect } from "react-redux";
import history from "../../../../actions/history";
import { DropdownList } from "react-widgets";
import axios from "../../../../services/network.js";
import _ from "lodash";
import { CardComponent } from "../../../../components";

class PromoForm extends Component {
  state = {
    _id: null,
    code_string: "",
    active: false,
    expiration_date: null,
    type: null,
    partner_id: null,
    market_id: null,
    referral_kickback: {
      threshold: {
        type: null,
        amount: "",
        error: false,
        message: ""
      },
      free_consultation: false,
      type: null,
      amount: "",
      error: false,
      message: ""
    },
    referrer_kickback: {
      threshold: {
        type: null,
        amount: "",
        error: false,
        message: ""
      },
      type: null,
      amount: "",
      error: false,
      message: ""
    },
    code_error: false,
    valid: false
  };

  componentWillReceiveProps = props => {
    if (props.promo) {
      this.gotProps(props);
    }
  };

  gotProps = props => {
    const { promo } = props;
    const state = _.cloneDeep(this.state);
    state._id = promo._id;
    state.code_string = promo.code_string;
    state.active = promo.active;
    state.expiration_date = promo.expiration_date;
    state.type = promo.type;
    state.partner_id = promo.partner_id;
    state.market_id = promo.market_id;
    state.referral_kickback.type = promo.referral_kickback.type;
    state.referral_kickback.amount = promo.referral_kickback.amount;
    state.referral_kickback.threshold.type =
      promo.referral_kickback.threshold.type;
    state.referral_kickback.threshold.amount =
      promo.referral_kickback.threshold.amount;
    state.referral_kickback.free_consultation =
      promo.referral_kickback.free_consultation;
    state.referrer_kickback.type = promo.referrer_kickback.type;
    state.referrer_kickback.amount = promo.referrer_kickback.amount;
    state.referrer_kickback.threshold.type =
      promo.referrer_kickback.threshold.type;
    state.referrer_kickback.threshold.amount =
      promo.referrer_kickback.threshold.amount;
    this.setState(state);
  };

  validateCode = e => {
    const val = e.target.value;
    if (!val && !val.length) {
      this.setState({ code_string: "", code_error: true });
    } else {
      this.setState({ code_string: val, code_error: false });
    }
  };

  handleChange = async value => {
    if (value !== this.state.type) {
      await this.setState({ type: value });
      //Fetch new list of stuff
    }
  };

  updateAmount = (amount, key) => {
    let state = _.cloneDeep(this.state);
    let object = state[key];
    if (!object.type) {
      object.error = true;
      object.message = "Type must be selected";
      state[key] = object;
      state.valid = false;
      this.setState(state);
      return;
    }
    if (object.type === "credits" || object.type === "walks") {
      const a = parseInt(amount.replace(/\D/g, ""));
      if (a < 0 || isNaN(a)) {
        object.error = true;
        object.message = "Enter a valid whole number for amount";
        object.amount = 0;
      } else {
        object.error = false;
        object.message = "";
        object.amount = a;
      }
    } else {
      const a = parseFloat(amount.replace(/\D/g, ""));
      if (a < 0 || isNaN(a)) {
        object.error = true;
        object.message = "Enter a valid number for amount";
        object.amount = 0;
      } else {
        object.error = false;
        object.message = "";
        object.amount = a;
      }
    }
    state[key] = object;
    this.setState(state);
  };

  updateThreshold = (value, kickback_key, threshold_key) => {
    let state = _.cloneDeep(this.state);

    let kickback_object = state[kickback_key];
    if (!value || (threshold_key === "amount" && isNaN(value))) {
      kickback_object.threshold.error = true;
      kickback_object.threshold.message = `${threshold_key} must be defined`;
      kickback_object.threshold[threshold_key] = "";
    } else {
      kickback_object.threshold[threshold_key] = value;
      if (kickback_object.threshold.type && kickback_object.threshold.amount) {
        kickback_object.threshold.error = false;
        kickback_object.threshold.message = "";
      }
    }
    this.setState(state);
  };

  validateForm = () => {
    const {
      referral_kickback,
      referrer_kickback,
      code_string,
      code_error,
      type,
      partner_id
    } = this.state;
    if (referral_kickback.error) return false;
    if (referrer_kickback.error) return false;
    if (referral_kickback.threshold.error) return false;
    if (code_error) return false;
    if (referrer_kickback.threshold.error) return false;
    if (!referral_kickback.type) return false;
    if (!referrer_kickback.type) return false;
    if (!referrer_kickback.threshold.type) return false;
    if (!code_string) return false;
    if (referral_kickback.amount < 0) return false;
    if (referrer_kickback.amount < 0) return false;
    if (referral_kickback.threshold.amount < 0) return false;
    if (!referral_kickback.threshold.type) return false;
    if (referrer_kickback.threshold.amount < 0) return false;
    if (!referrer_kickback.threshold.type) return false;
    if (!type) return false;
    if (type === "partner" && !partner_id) return false;
    return true;
  };

  handleTypeChange = val => {
    if (val === "general") {
      this.setState({
        type: val,
        referrer_kickback: {
          type: "discount",
          amount: "0",
          error: false,
          threshold: {
            type: "walks",
            amount: 0,
            error: false
          }
        }
      });
    } else {
      this.setState({ type: val });
    }
  };

  valid_types = ["general", "partner"];
  valid_kickback_types = [
    { name: "Cash Benefit", id: "discount" },
    { name: "Walk Credits", id: "credits" }
  ];
  valid_threshold_types = ["walks"];

  render() {
    const editable =
      !this.props.promo ||
      !this.props.promo.referrals ||
      !this.props.promo.referrals.length;
    return (
      <div className="promo-form-container">
        <div className="row">
          <div className="col-md-3">
            <div
              className={`form-component-wrapper${
                this.state.code_error ? " has-error" : ""
              }`}
            >
              <input
                disabled={!!this.props.promo}
                type="text"
                placeholder="Promo Code *"
                onChange={e => this.validateCode(e)}
                value={this.state.code_string}
              />
            </div>
          </div>
          <div className="col-md-1">
            <div className="form-component-wrapper">
              <DropdownList
                data={[
                  { text: "Yes", value: true },
                  { text: "No", value: false }
                ]}
                valueField={"value"}
                textField={"text"}
                value={this.state.active}
                onChange={item => {
                  this.setState({ active: item.value });
                }}
                disabled={!editable}
              />
              <label>Active?</label>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-component-wrapper">
              <label>Expiration Date</label>
              {/*<DateTimePicker
                value={this.state.expiration_date}
                time={false}
                onChange={value => this.setState({ expiration_date: value })}
                disabled={!editable}
              />*/}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-component-wrapper">
              <label>Promo Type</label>
              <DropdownList
                data={this.valid_types}
                value={this.state.type}
                onChange={value => this.handleTypeChange(value)}
                placeholder="Promo Type *"
                disabled={!editable}
              />
            </div>
          </div>
          {this.state.type === "partner" ? (
            <div className="col-md-3">
              <div className="form-component-wrapper">
                <label>Partners</label>
                <DropdownList
                  data={this.props.partners}
                  textField={"name"}
                  valueField={"_id"}
                  value={this.state.partner_id}
                  onChange={item => {
                    this.setState({ partner_id: item._id });
                  }}
                  placeholder="Partner *"
                  disabled={!editable}
                />
              </div>
            </div>
          ) : null}
          {this.state.type === "general" ? (
            <div className="col-md-3">
              <div className="form-component-wrapper">
                <label>Market</label>
                <DropdownList
                  data={this.props.markets}
                  textField={"city"}
                  valueField={"_id"}
                  value={this.state.market_id}
                  onChange={item => {
                    this.setState({ market_id: item._id });
                  }}
                  disabled={!editable}
                />
              </div>
            </div>
          ) : null}
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="info">
              <h3>Referral Benefits</h3>
              <h4>
                The Referral is always the Client who has used a promo code
                supplied by the Referrer. Threshold values are checked upon
                completion of the Referral Client events.
              </h4>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div
                  className={`form-component-wrapper${
                    this.state.referral_kickback.error ? " has-error" : ""
                  }`}
                >
                  <label>type</label>
                  <DropdownList
                    data={this.valid_kickback_types}
                    textField="name"
                    valueField="id"
                    value={this.state.referral_kickback.type}
                    onChange={item => {
                      this.setState({
                        referral_kickback: {
                          ...this.state.referral_kickback,
                          type: item.id
                        }
                      });
                    }}
                    placeholder="Kickback Type *"
                    disabled={!editable}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className={`form-component-wrapper${
                    this.state.referral_kickback.error ? " has-error" : ""
                  }`}
                >
                  <input
                    type="text"
                    value={this.state.referral_kickback.amount}
                    onChange={e => {
                      this.updateAmount(e.target.value, "referral_kickback");
                    }}
                    placeholder="Kickback Amount *"
                    disabled={!editable}
                  />
                </div>
              </div>
            </div>
            {this.state.referral_kickback.error ? (
              <div className="row error">
                {this.state.referral_kickback.message}
              </div>
            ) : null}
            <h4>Threshold</h4>
            <div className="row">
              <div className="col-md-6">
                <div
                  className={`form-component-wrapper${
                    this.state.referral_kickback.threshold.error
                      ? " has-error"
                      : ""
                  }`}
                >
                  <label>type</label>
                  <DropdownList
                    data={this.valid_threshold_types}
                    value={this.state.referral_kickback.threshold.type}
                    onChange={value => {
                      this.updateThreshold(value, "referral_kickback", "type");
                    }}
                    placeholder="Threshold Type *"
                    disabled={!editable}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className={`form-component-wrapper${
                    this.state.referral_kickback.threshold.error
                      ? " has-error"
                      : ""
                  }`}
                >
                  <input
                    type="text"
                    value={this.state.referral_kickback.threshold.amount}
                    onChange={e => {
                      this.updateThreshold(
                        e.target.value,
                        "referral_kickback",
                        "amount"
                      );
                    }}
                    placeholder="Threshold Amount *"
                    disabled={!editable}
                  />
                </div>
              </div>
            </div>
            <h4>Consultation</h4>
            <div className="row">
              <div className="col-md-3">
                <div className="form-component-wrapper">
                  <DropdownList
                    data={[
                      { text: "Yes", value: true },
                      { text: "No", value: false }
                    ]}
                    valueField={"value"}
                    textField={"text"}
                    value={this.state.referral_kickback.free_consultation}
                    onChange={item => {
                      this.setState({
                        referral_kickback: {
                          ...this.state.referral_kickback,
                          free_consultation: item.value
                        }
                      });
                    }}
                    disabled={!editable}
                  />
                  <label>Free Conultation</label>
                </div>
              </div>
            </div>
            {this.state.referral_kickback.threshold.error ? (
              <div className="row error">
                {this.state.referral_kickback.threshold.message}
              </div>
            ) : null}
          </div>
          {this.state.type !== "general" ? (
            <div className="col-md-6">
              <div className="info">
                <h3>Referrer Benefits</h3>
                <h4>
                  The Referrer is the owner of a promo code, ex. Client, Staff
                  or Partner. General codes do not have a Referrer.
                </h4>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div
                    className={`form-component-wrapper${
                      this.state.referrer_kickback.error ? " has-error" : ""
                    }`}
                  >
                    <label>type</label>
                    <DropdownList
                      data={this.valid_kickback_types}
                      textField="name"
                      valueField="id"
                      value={this.state.referrer_kickback.type}
                      placeholder="Kickback Type *"
                      onChange={item => {
                        this.setState({
                          referrer_kickback: {
                            ...this.state.referrer_kickback,
                            type: item.id
                          }
                        });
                      }}
                      disabled={!editable}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className={`form-component-wrapper${
                      this.state.referrer_kickback.error ? " has-error" : ""
                    }`}
                  >
                    <input
                      type="text"
                      value={this.state.referrer_kickback.amount}
                      onChange={e => {
                        this.updateAmount(e.target.value, "referrer_kickback");
                      }}
                      placeholder="Kickback Amount *"
                      disabled={!editable}
                    />
                  </div>
                </div>
              </div>
              {this.state.referrer_kickback.error ? (
                <div className="row error">
                  {this.state.referrer_kickback.message}
                </div>
              ) : null}
              <h4>Threshold</h4>
              <div className="row">
                <div className="col-md-6">
                  <div
                    className={`form-component-wrapper${
                      this.state.referrer_kickback.threshold.error
                        ? " has-error"
                        : ""
                    }`}
                  >
                    <label>type</label>
                    <DropdownList
                      data={this.valid_threshold_types}
                      value={this.state.referrer_kickback.threshold.type}
                      onChange={value => {
                        this.updateThreshold(
                          value,
                          "referrer_kickback",
                          "type"
                        );
                      }}
                      placeholder="Threshold Type *"
                      disabled={!editable}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className={`form-component-wrapper${
                      this.state.referrer_kickback.threshold.error
                        ? " has-error"
                        : ""
                    }`}
                  >
                    <input
                      type="text"
                      value={this.state.referrer_kickback.threshold.amount}
                      onChange={e => {
                        this.updateThreshold(
                          e.target.value,
                          "referrer_kickback",
                          "amount"
                        );
                      }}
                      placeholder="Threshold Amount *"
                      disabled={!editable}
                    />
                  </div>
                </div>
              </div>
              {this.state.referrer_kickback.threshold.error ? (
                <div className="row error">
                  {this.state.referrer_kickback.threshold.message}
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        <div className="row">
          <div className="col-md-12">
            {editable ? (
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.props.savePromo(this.state, this.validateForm());
                }}
              >
                Save
              </button>
            ) : null}
            <button
              className="btn btn-danger pull-right normal-size-btn"
              onClick={e => {
                history.push("/promos");
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default PromoForm;
