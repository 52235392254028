import React, { Component } from "react";
import moment from "moment";
import { DefaultModal } from "../../";

export default class CancelStandaloneAppointment extends Component {

  renderLateCancellationWarning = () => {
    const { appointment } = this.props;
    const requestedTime = moment(appointment.window_start_time).add(30, "m");
    const fullPriceCancellationDeadline = requestedTime
      .clone()
      .subtract(1, "h");
    const halfPriceCancellationDeadline = requestedTime
      .clone()
      .hour(8)
      .minute(0);
    if ([8, 10, 12].includes(appointment.appointment_type.id)) return null;
    if (moment().isAfter(fullPriceCancellationDeadline)) {
      return (
        <p>
          Because you are canceling within one hour of the requested appointment
          time, this appointment will be subject to a{" "}
          <strong>100% late cancellation charge.</strong>
        </p>
      );
    } else if (moment().isAfter(halfPriceCancellationDeadline)) {
      return (
        <p>
          Because you are canceling after 8am on the day of the appointment,
          this appointment will be subject to a{" "}
          <strong>50% late cancellation charge.</strong>
        </p>
      );
    }

    return null;
  };

  render() {
    const { onConfirm, onDismiss } = this.props;

    return (
      <DefaultModal
        title="Appointment Cancellation"
        onDismiss={onDismiss}
        options={
          <div>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onDismiss}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => onConfirm(false)}
            >
              I Confirm
            </button>
          </div>
        }
      >
        <p>Are you sure you want to cancel this appointment?</p>
        {this.renderLateCancellationWarning()}
      </DefaultModal>
    );
  }
}
