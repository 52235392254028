import React, { Component } from "react";
import { connect } from "react-redux";
import { getAllClients } from "../../actions/admin/clients.actions";
import { changeCity } from "../../actions/admin/appointments.actions";
import { Link } from "react-router-dom";
import { ClientTableRow } from "../../components";
import Tooltip from "rc-tooltip";
import { Multiselect, DropdownList } from "react-widgets";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import axios from "../../services/network.js";
import oval from "../../images/oval.svg";
const search = [
  "Name",
  "Secondary Contact",
  "Zip Code",
  "Pet",
  "Email",
  "Consultation Status"
];
const statuses = ["scheduled", "unscheduled", "completed"];

const searchAPI = (text, searchBy, active) => {
  if (searchBy === "Secondary Contact") searchBy = "secondary_contact";
  if (searchBy === "Zip Code") searchBy = "zip_code";
  return axios.get(
    `${
      process.env.REACT_APP_API_URI
    }/admin/searchClient?text=${text}&searchBy=${searchBy.toLowerCase()}&active=${
      active ? "true" : "false"
    }`,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken")
      }
    }
  );
};

const searchAPIDebounced = AwesomeDebouncePromise(searchAPI, 750);

class ClientListPage extends Component {
  constructor() {
    super();

    this.filterClients = this.filterClients.bind(this);
    this.state = {
      clients: [],
      reviewed: false,
      search: "name",
      active: true,
      value: "",
      zip_codes: [],
      selected_zip_codes: [],
      selected_statuses: [],
      isFetching: false
    };
  }

  componentWillMount = () => {
    const { market } = this.props;
    // this.props.loadClients({ city: market._id });
  };

  componentWillReceiveProps(props) {
    // if(this.props.market._id !== props.market._id){
    //   this.props.loadClients({ city: props.market._id })
    // }
    if (props.clientList) {
      const zipCodes = [];
      props.clientList.forEach(c => {
        if (!zipCodes.includes(c.contact.address.zip_code)) {
          zipCodes.push(c.contact.address.zip_code);
        }
      });
      this.setState({
        clients: props.clientList,
        zip_codes: zipCodes
      });
    }
  }

  toggleActive = () => {
    this.setState({ active: !this.state.active }, () => {
      this.handleTextChange(this.state.text);
    });
  };

  filterClients = val => {
    if (!val) {
      this.setState({
        clients: this.props.clientList,
        value: ""
      });
    } else {
      this.setState({ value: val }, () => {
        let clients_filtered;
        switch (this.state.search) {
          case "Name":
            //This returns falsey client names? is this desired?
            clients_filtered = this.props.clientList.filter(c => {
              return (
                c.full_name &&
                c.full_name
                  .toLowerCase()
                  .includes(this.state.value.toLowerCase())
              );
            });
            break;
          case "Secondary Contact":
            clients_filtered = this.props.clientList.filter(c => {
              if (c.secondary_contact) {
                const full_name = `${c.secondary_contact.first_name} ${c.secondary_contact.last_name}`.toLowerCase();
                return full_name.includes(this.state.value.toLowerCase());
              }
            });
            break;
          case "Pet":
            clients_filtered = this.filterPets(this.state.value);
            break;
          case "Apartment":
            clients_filtered = this.props.clientList.filter(c => {
              if (c.apartment) {
                return c.apartment.name
                  .toLowerCase()
                  .includes(this.state.value.toLowerCase());
              }
            });
            break;
          case "Email":
            clients_filtered = this.props.clientList.filter(c => {
              return c.email
                .toLowerCase()
                .includes(this.state.value.toLowerCase());
            });
            break;
        }
        if (this.state.active) {
          clients_filtered = clients_filtered.filter(c => c.active);
        }
        this.setState({ clients: clients_filtered });
      });
    }
  };

  filterPets = val => {
    let findClients = this.props.clientList.filter(client => {
      let findPets = client.pets.find(
        pet =>
          pet && pet.name && pet.name.toLowerCase().includes(val.toLowerCase())
      );
      if (findPets) return true;
      return false;
    });
    return findClients;
  };

  setFilters = filters => {
    if (filters.length) {
      const clients = this.props.clientList.filter(c => {
        return filters.includes(c.contact.address.zip_code);
      });
      this.setState({ clients: clients, selected_zip_codes: filters });
    } else {
      this.setState({ clients: this.props.clientList, selected_zip_codes: [] });
    }
  };

  filterConsultation = filters => {
    if (filters.length) {
      const clients = this.props.clientList.filter(c => {
        return filters.includes(c.consultation_status);
      });
      this.setState({ clients: clients, selected_statuses: filters });
    } else {
      this.setState({ clients: this.props.clientList, selected_statuses: [] });
    }
  };

  handleSearch = val => {
    this.setState({ search: val }, () => {
      if (this.state.text && this.state.text.length > 1) {
        this.handleTextChange(this.state.text);
      }
    });
  };

  handleTextChange = async text => {
    try {
      this.setState({ text, clients: [] });

      if (!text || !text.length || text.length < 2) {
        console.log("returning...");
        return;
      }
      if (text && text.length && text.length > 1) {
        this.setState({ isFetching: true });
        const { data } = await searchAPIDebounced(
          text,
          this.state.search,
          this.state.active
        );
        this.setState({ clients: data.clients, isFetching: false });
      }
    } catch (e) {
      this.setState({ isFetching: false });
    }
  };

  render() {
    const { clientList } = this.props;
    const { isFetching } = this.state;

    return (
      <div className="card p-t-none">
        <div className="card-header">
          <div className="row">
            <div className="col-sm-12">
              <div className="col-xs-6">
                <h1>All Clients</h1>
              </div>
              <div className="col-xs-6">
                <span className="inline-link-right">
                  <Link to="/addclient">
                    <span className="fa fa-plus" /> Add New Client
                  </Link>
                </span>
              </div>
            </div>
            <div className="col-xs-12 p-t-sm">
              <div className="form-inline">
                <div className="col-xs-12">
                  <div className="form-group input-field row">
                    <div className="col-sm-4">
                      {this.state.search !== "Consultation Status" ? (
                        <div>
                          <span className="fa fa-search" />
                          <input
                            value={this.state.text}
                            placeholder={`Search by ${this.state.search}...`}
                            onChange={e => {
                              this.handleTextChange(e.target.value);
                            }}
                            style={{ marginBottom: "20px" }}
                          ></input>
                        </div>
                      ) : (
                        <Multiselect
                          data={
                            this.state.search === "Zip Code"
                              ? this.state.zip_codes
                              : statuses
                          }
                          value={
                            this.state.search === "Zip Code"
                              ? this.state.selected_zip_codes
                              : this.state.selected_statuses
                          }
                          groupBy={item => item.type}
                          filter="contains"
                          onChange={
                            this.state.search === "Zip Code"
                              ? filters => this.setFilters(filters)
                              : filters => this.filterConsultation(filters)
                          }
                          placeholder={
                            this.state.search === "Zip Code"
                              ? "Filter by zip..."
                              : "Filter by Consultation Status..."
                          }
                        />
                      )}
                    </div>
                    <div className="col-sm-4 search-container">
                      <label>Search By:</label>
                      <DropdownList
                        className="search-select"
                        data={search}
                        defaultValue="Name"
                        onChange={v => {
                          this.handleSearch(v);
                        }}
                      />
                    </div>
                    <div className="col-sm-2 search-container">
                      <label>Active Only: </label>
                      <input
                        type="checkbox"
                        checked={this.state.active}
                        onChange={this.toggleActive}
                      />
                    </div>
                    <div className="col-sm-2 search-container">
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          this.props.loadClients({
                            city: this.props.market._id
                          })
                        }
                      >
                        Load All Clients
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="user-list-container">
            <div className="user-list-item user-list-header">
              <div className="user-list-item-content title col-lg-3 col-md-3 col-sm-3 col-xs-8">
                Name
              </div>
              <div className="user-list-item-content title col-lg-2 col-md-2 col-sm-2 col-xs-3">
                Market
              </div>
              <div className="user-list-item-content title col-lg-2 col-md-2 col-sm-2 col-xs-3">
                Zone
              </div>
              <div className="user-list-item-content title col-lg-1 col-md-1 col-sm-1 col-xs-2">
                Zip Code
              </div>
              <div className="user-list-item-content title col-lg-2 col-md-2 col-sm-1 hidden-xs">
                Pets
              </div>
              <div
                className="user-list-item-content title col-lg-2 col-md-2 col-sm-1 hidden-xs"
                onClick={() => this.filterReviewed()}
              >
                Completed Consultation?
              </div>
            </div>

            {this.state.clients.length && !isFetching
              ? this.state.clients.map((client, index) => (
                  <ClientTableRow
                    key={`client-list-item-id-${client._id}`}
                    client={client}
                    markets={this.props.marketList}
                  />
                ))
              : null}

            {isFetching ? (
              <div>
                <img
                  className="loading-spinner-img"
                  src={oval}
                  alt="Spinner"
                  height={72}
                  width={72}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  clientList: state.admin.clients.clientList,
  market: state.admin.general.location,
  marketList: state.admin.general.markets
});

const mapDispatchToProps = dispatch => ({
  loadClients: market => dispatch(getAllClients(market)),
  changeCity: (city, date, location) =>
    dispatch(changeCity(city, date, location)),
  showSpinner: () => dispatch({ type: "FETCH_REQUEST" }),
  stopSpinner: () => dispatch({ type: "FETCH_SUCCESS" })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientListPage);
