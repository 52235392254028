import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { parseTimeInput } from "../../../../helpers";
import { CheckboxField, DateTimeField } from "../../../shared/formComponents";

class ScheduleAppointmentForm extends Component {

  render() {
    const {
      appointmentId,
      handleSubmit,
      isRecurring,
      onCancel,
      selectedDate
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Field
          component={DateTimeField}
          name="scheduled_time"
          calendar={false}
          dateFormat="h:mm A"
          parseInput={time => parseTimeInput(time, selectedDate)}
          placeholder="Scheduled Time"
          step={5}
        />
        {isRecurring ? (
          <Field
            component={CheckboxField}
            name="apply_to_recurring"
            uniqueId={`applyToRecurring-${appointmentId}`}
            type="checkbox"
            label="Apply to all"
          />
        ) : null}
        <div className="pet-card-drawer-buttons">
          <button className="btn-small btn-green">
            <span className="fa fa-check" />
            Assign
          </button>
          <button
            className="btn-small btn-red AssignmentPanel-PC-cancel"
            onClick={onCancel}
          >
            <span className="fa fa-times" />
          </button>
        </div>
      </form>
    );
  }
}

// const validate = (values) => {
//   const errors = {};
//   if (!values.scheduledTime) errors.scheduledTime = true;
//   if (!values.assignedSitter) errors.assignedSitter = true;
//   return errors;
// }

export default reduxForm({ enableReinitialize: true })(ScheduleAppointmentForm);
