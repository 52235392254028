import React, { Component } from "react";
import { Multiselect, DropdownList } from "react-widgets";
const yesno = [{ text: "Yes", bool: true }, { text: "No", bool: false }];
const bundles = [
  { text: "Bundles", bool: true },
  { text: "One-Off", bool: false }
];

class Daycare extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    options: [
      {
        id: 0,
        name: "Sunday",
        abb: "Sun"
      },
      {
        id: 1,
        name: "Monday",
        abb: "Mon"
      },
      {
        id: 2,
        name: "Tuesday",
        abb: "Tue"
      },
      {
        id: 3,
        name: "Wednesday",
        abb: "Wed"
      },
      {
        id: 4,
        name: "Thursday",
        abb: "Thu"
      },
      {
        id: 5,
        name: "Friday",
        abb: "Fri"
      },
      {
        id: 6,
        name: "Saturday",
        abb: "Sat"
      }
    ]
  };

  render() {
    console.log(this.props.facility.services);
    return (
      <div className="row boarding">
        <div className="col-sm-12">
          <div className="col-sm-12">
            <h4>Daycare Settings</h4>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="col-sm-3">
            <h5>Available Service?</h5>
            <DropdownList
              data={yesno}
              value={this.props.facility.services.daycare.available}
              textField="text"
              valueField="bool"
              onChange={val => this.props.updateDaycare("available", val.bool)}
            />
          </div>
          {this.props.facility.services.daycare.available ? (
            <div className="col-sm-9 boardingDays">
              <h5>Days Of The Week</h5>
              <div className={`toggle-box-container isAdmin editBoarding`}>
                {this.state.options.map((option, index) => {
                  const checked = this.props.facility.services.daycare.days_of_week.includes(
                    option.name
                  );
                  return (
                    <div
                      className={`toggle-box weekdays-field editBoardingWeekday btn btn-tertiary${
                        checked ? " checked" : ""
                      }`}
                      key={`${option.name}-${index}`}
                      onClick={() => {
                        const days = [
                          ...this.props.facility.services.daycare.days_of_week
                        ];
                        if (days.includes(option.name)) {
                          days.splice(days.indexOf(option.name), 1);
                        } else {
                          days.push(option.name);
                        }
                        this.props.updateDaycare("days_of_week", days);
                      }}
                    >
                      <label>{option.abb}</label>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="col-sm-9 boardingDays"></div>
          )}
          <div className="col-sm-6">
            <h5>Drop Off Begins...</h5>
            <DropdownList
              value={this.props.facility.services.daycare.dropoff_begins}
              data={this.props.times}
              onChange={val => this.props.updateDaycare("dropoff_begins", val)}
            />
          </div>
          <div className="col-sm-6">
            <h5>Pickup Ends...</h5>
            <DropdownList
              value={this.props.facility.services.daycare.pickup_deadline}
              data={this.props.times}
              onChange={val => this.props.updateDaycare("pickup_deadline", val)}
            />
          </div>
          {/*<div className="col-sm-6">
            <h5>Cancellation Deadline (hours)</h5>
            <input type="text" value={this.props.facility.services.daycare.cancellation_deadline} onChange={(e) => this.props.updateDaycare('cancellation_deadline', e.target.value)}/>
          </div>*/}
        </div>
        <div className="col-sm-6">
          <div className="col-sm-12">
            <h5>Pricing Category</h5>
            <DropdownList
              textField="text"
              valueField="bool"
              value={this.props.facility.services.daycare.bundles_enabled}
              data={bundles}
              onChange={val =>
                this.props.updateDaycare("bundles_enabled", val.bool)
              }
            />
          </div>
          <div className="col-sm-6">
            <h5>Single Session</h5>
            <input
              type="text"
              value={(
                this.props.facility.services.daycare.prices[1] / 100
              ).toFixed(2)}
              onChange={e => this.props.updateDaycarePrices(1, e.target.value)}
            />
          </div>
          {this.props.facility.services.daycare.bundles_enabled ? (
            <div className="col-sm-6">
              <h5>5-Session Bundle</h5>
              <input
                type="text"
                value={(
                  this.props.facility.services.daycare.prices[5] / 100
                ).toFixed(2)}
                onChange={e =>
                  this.props.updateDaycarePrices(5, e.target.value)
                }
              />
            </div>
          ) : null}
          {this.props.facility.services.daycare.bundles_enabled ? (
            <div className="col-sm-6">
              <h5>20-Session Bundle</h5>
              <input
                type="text"
                value={(
                  this.props.facility.services.daycare.prices[20] / 100
                ).toFixed(2)}
                onChange={e =>
                  this.props.updateDaycarePrices(20, e.target.value)
                }
              />
            </div>
          ) : null}
          {this.props.facility.services.daycare.bundles_enabled ? (
            <div className="col-sm-6">
              <h5>50-Session Bundle</h5>
              <input
                type="text"
                value={(
                  this.props.facility.services.daycare.prices[50] / 100
                ).toFixed(2)}
                onChange={e =>
                  this.props.updateDaycarePrices(50, e.target.value)
                }
              />
            </div>
          ) : null}
          <div className="col-sm-6">
            <h5>Late Pickup Fee</h5>
            <input
              type="text"
              value={(
                this.props.facility.services.daycare.late_pickup_fee / 100
              ).toFixed(2)}
              onChange={e =>
                this.props.updateDaycareCharges(
                  "late_pickup_fee",
                  e.target.value
                )
              }
            />
          </div>
          <div className="col-sm-6">
            <h5>Holiday Surcharge</h5>
            <input
              type="text"
              value={(
                this.props.facility.services.daycare.holiday_surcharge / 100
              ).toFixed(2)}
              onChange={e =>
                this.props.updateDaycareCharges(
                  "holiday_surcharge",
                  e.target.value
                )
              }
            />
          </div>
          {/*<div className="col-sm-6">
            <h5>Late Cancellation Fee</h5>
            <input type="text" value={(this.props.facility.services.daycare.late_cancellation_fee/100).toFixed(2)} onChange={(e) => this.props.updateDaycareCharges('late_cancellation_fee', e.target.value)}/>
          </div>*/}
        </div>
      </div>
    );
  }
}

export default Daycare;
