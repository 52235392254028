import React, { Component } from "react";
import axios from "../../../../services/network.js";
import { DropdownList, Multiselect } from "react-widgets";
import MedicineItem from "./MedicineItem";
import { connect } from "react-redux";
import { pushModal, popModal } from "../../../../actions/general.actions";

class MedicineForm extends Component {
  state = {
    medication: [],
    new: false
  };

  componentDidMount() {
    this.loadMedication();
  }

  loadMedication = async () => {
    const { data } = await axios.get(
      `admin/pet/${this.props.petId}/medication`
    );
    this.setState({ medication: data });
  };

  saveMedication = async med => {
    const {
      showSpinner,
      removeSpinner,
      showMessage,
      removeMessage
    } = this.props;
    showSpinner();
    try {
      if (!med._id) {
        delete med._id;
      }
      const { data } = await axios.post(
        `/admin/pet/${this.props.petId}/medication`,
        med
      );
      const oldMeds = [...this.state.medication];
      let found = false;
      const newMeds = oldMeds.map(m => {
        if (!found && m._id === data._id) {
          m = data;
          found = true;
        }
        return m;
      });
      if (!found) {
        newMeds.push(data);
      }
      showMessage({
        message: "Successfully saved medication",
        messageType: "success"
      });
      this.setState({ medication: newMeds, new: false });
    } catch (e) {
      if (e.data && e.data.error) {
        showMessage({
          message: e.data.error,
          messageType: "error"
        });
      } else {
        showMessage({
          message: "Error saving medication",
          messageType: "error"
        });
      }
    }
    setTimeout(removeMessage, 3000);
    removeSpinner();
  };

  renderAddNew = () => {
    if (this.state.new)
      return (
        <MedicineItem
          new={true}
          save={this.saveMedication}
          modalActions={this.props.modalActions}
          cancel={this.cancelAddNew}
        />
      );
    else
      return (
        <div className="col-sm-12">
          <div className="col-sm-3">
            <button
              className="btn btn-primary btn-block"
              onClick={() => {
                this.setState({ new: true });
              }}
            >
              <span className="fa fa-plus" />
              Add New
            </button>
          </div>
        </div>
      );
  };

  cancelAddNew = () => {
    this.setState({ new: false });
  };

  render() {
    const { editing, medication } = this.state;
    return (
      <div className="container form-container">
        {this.state.medication.map((m, i) => {
          return (
            <MedicineItem
              key={i}
              save={this.saveMedication}
              modalActions={this.props.modalActions}
              loadMedication={this.loadMedication}
              medicine={m}
              index={i}
            />
          );
        })}
        {this.renderAddNew()}
      </div>
    );
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
  showMessage: message => dispatch({ type: "ADD_MESSAGE", payload: message }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" }),
  showSpinner: () => dispatch({ type: "FETCH_REQUEST" }),
  removeSpinner: () => dispatch({ type: "FETCH_SUCCESS" }),
  modalActions: {
    push: component => dispatch(pushModal(component)),
    pop: () => dispatch(popModal())
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MedicineForm);
