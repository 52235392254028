import React, { Component } from "react";
import { ZoneBadge, ZoneSettingsEditRow } from "../";

export default class ZoneSettingsRow extends Component {

  renderEditZone = () => {
    const { openZoneId } = this.props;
    const {
      displayFormError,
      displayFormSuccess,
      handleAddZone,
      handleDeleteZone,
      handleUpdateZone,
      zone
    } = this.props;

    if (!zone || openZoneId === zone.id) {
      return (
        <ZoneSettingsEditRow
          form={zone ? `editZone${zone.id}` : "add-new-zone-form"}
          initialValues={
            zone
              ? zone
              : {
                  background_color: "#FFFFFF",
                  text_color: "#FFFFFF"
                }
          }
          handleDelete={handleDeleteZone}
          onSubmit={zone ? handleUpdateZone : handleAddZone}
          onSubmitFail={errors => displayFormError(errors)}
          onSubmitSuccess={() =>
            displayFormSuccess(
              zone ? "Zone successfully updated." : "Zone successfully added"
            )
          }
          zone={zone}
        />
      );
    }
  };

  renderExistingZone = () => {
    const { toggleEditZoneDrawer, openZoneId, zone } = this.props;
    return (
      <div className="col-sm-12">
        <div className="zone-row">
          <div className="zone-row-top">
            <div className="zone-info">
              <div>
                <ZoneBadge size="large" zone={zone} />
              </div>
              <p>{zone.zone_name}</p>
            </div>
            <div className="zone-actions">
              <button
                className="btn btn-table"
                onClick={() => toggleEditZoneDrawer(zone.id)}
              >
                {openZoneId === zone.id ? "Cancel" : "Edit"}
              </button>
            </div>
          </div>
          {this.renderEditZone()}
        </div>
      </div>
    );
  };

  renderNewZone = () => {
    const { handleCancel } = this.props;
    return (
      <div className="col-sm-12">
        <div className="zone-row">
          <div className="zone-row-top">
            <div className="zone-info">
              <div className="blank-zone-badge " />
              <div className="blank-zone-name" />
            </div>
            <div className="zone-actions">
              <button className="btn btn-table" onClick={handleCancel}>
                Cancel
              </button>
            </div>
          </div>
          {this.renderEditZone()}
        </div>
      </div>
    );
  };

  render() {
    const { zone } = this.props;
    if (zone) {
      return this.renderExistingZone();
    } else {
      return this.renderNewZone();
    }
  }
}
