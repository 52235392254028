import React from "react";

import moment from "moment";

// Each {option} needs to have an `id` and `name` property.
export default class WeekdaysField extends React.Component {

  state = {
    options: []
  };

  static defaultProps = {
    disabled: false
  };

  getOptions = () => {
    return this.props.options.map(d => {
      if (this.props.appointmentTypeId === 11 && this.props.clientInfo) {
        d.disabled = !this.props.clientInfo.apartment.playcare.days.includes(
          moment(d.id, "dddd").day()
        );
      }
      return d;
    });
  };

  render = () => {
    const { input, options, isAdmin } = this.props;
    const { touched, error, warning } = this.props.meta;
    const wrapperClass =
      touched && error ? " error-text error-border error-doubleBorder" : "";

    // TODO: Set up error class for checkbox inputs
    // const errorClass = touched && error ? ' error-text error-border' : '';
    return (
      <div
        className={
          isAdmin
            ? `toggle-box-container isAdmin${wrapperClass}`
            : `toggle-box-container${wrapperClass}`
        }
      >
        {isAdmin ? null : (
          <div className="toggle-box-container-title">Select Weekdays</div>
        )}
        {this.getOptions().map((option, index) => {
          const checked = input.value.includes(option.id);
          return (
            <div
              className={`toggle-box weekdays-field btn btn-tertiary${
                checked ? " checked" : ""
              }${option.disabled ? " disabled" : ""}`}
              key={`${input.name}-${index}`}
              onClick={() => {
                if (option.disabled) return;
                const newValue = [...input.value];
                if (input.value.includes(option.id)) {
                  newValue.splice(newValue.indexOf(option.id), 1);
                } else {
                  newValue.push(option.id);
                }
                return input.onChange(newValue);
              }}
            >
              <label>{option.name}</label>
            </div>
          );
        })}
      </div>
    );
  };
}
