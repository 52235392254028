import React, { Component } from "react";
import moment from "moment";
import { Week, DateSwitcher } from "../../../components";
import history from "../../../actions/history";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { pushModal, popModal } from "../../../actions/general.actions";

class ClientAppointmentCalendar extends Component {
  state = {
    month: moment(),
    week: moment(),
    startDay: moment()
      .startOf("week")
      .toDate(),
    endDay: moment()
      .endOf("week")
      .toDate(),
    show_canceled: false
  };

  renderWeekDayHeaders = () => (
    <div className="calendar-row-month">
      <div className="day-wrapper">
        <h4>S</h4>
      </div>
      <div className="day-wrapper">
        <h4>M</h4>
      </div>
      <div className="day-wrapper">
        <h4>T</h4>
      </div>
      <div className="day-wrapper">
        <h4>W</h4>
      </div>
      <div className="day-wrapper">
        <h4>T</h4>
      </div>
      <div className="day-wrapper">
        <h4>F</h4>
      </div>
      <div className="day-wrapper">
        <h4>S</h4>
      </div>
    </div>
  );

  renderWeeks = () => {
    const { appointments, visits } = this.props;
    const weeks = [];
    let done = false;
    let date = this.state.month
      .clone()
      .startOf("month")
      .day("Sunday");
    let monthIndex = date.month();
    let count = 0;

    while (!done) {
      weeks.push(
        <Week
          appointments={appointments}
          visits={visits}
          key={date.toString()}
          firstDayOfWeek={date.clone()}
          showMonth={true}
          month={this.state.month}
          show_canceled={this.state.show_canceled}
        />
      );
      date.add(1, "week");
      done = count++ > 2 && monthIndex !== date.month();
      monthIndex = date.month();
    }
    return weeks;
  };

  next = () => {
    const { getAppointments, setCalendarMonth } = this.props;
    const month = this.state.month.clone().add(1, "month");
    this.setCalendarMonth(month);
  };

  previous = () => {
    const { getAppointments, setCalendarMonth } = this.props;
    const month = this.state.month.clone().subtract(1, "month");
    this.setCalendarMonth(month);
  };

  setCalendarMonth = month => {
    this.setState({ month: month });
  };

  render() {
    return (
      <div className="card-calendar watever">
        <div className="calendar-filters">
          <div className="col-md-4 col-md-offset-4">
            <div className="row">
              <div className="btn-group btn-group" role="group">
                <a
                  className={
                    !this.state.show_canceled
                      ? "btn btn-primary"
                      : "btn btn-white"
                  }
                  onClick={() => this.setState({ show_canceled: false })}
                >
                  Active Appointments
                </a>
                <a
                  className={
                    this.state.show_canceled
                      ? "btn btn-primary"
                      : "btn btn-white"
                  }
                  onClick={() => this.setState({ show_canceled: true })}
                >
                  All Appointments
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="calendar-header-month">
          <DateSwitcher
            dateFormat="MMMM YYYY"
            onDateBack={this.previous}
            onDateForward={this.next}
            selectedDate={this.state.month}
          />
        </div>
        <div className="calendar-month-wrapper">
          {this.renderWeekDayHeaders()}
          {this.renderWeeks()}
        </div>
      </div>
    );
  }
}

export default ClientAppointmentCalendar;
