import React from "react";
import { connect } from "react-redux";
import Tooltip from "rc-tooltip";
import { pushModal, popModal } from "../../actions/general.actions";
import { ImageOverlay } from "../";

const ImageComponent = ({
  images,
  imagesMarkedForDeletion,
  modalActions,
  toggleDeletion
}) => {

  return (
    <div className="appointment-image-wrapper">
      {images.map(img => {
        return (
          <div key={`image-${img.id}-2`} className="appointment-image-box">
            <img
              alt="Your pet."
              className={
                imagesMarkedForDeletion.includes(img.id)
                  ? "marked-for-deletion"
                  : ""
              }
              src={img.image_url}
              onClick={() =>
                modalActions.push(
                  <ImageOverlay
                    fullScreen
                    image={img}
                    modalActions={modalActions}
                  />
                )
              }
            />

            {toggleDeletion && !imagesMarkedForDeletion.includes(img.id) ? (
              <div
                className="delete-image"
                onClick={() => toggleDeletion(img.id)}
              >
                <Tooltip placement="top" overlay="Tag for deletion">
                  <span className="fa fa-times-circle" />
                </Tooltip>
              </div>
            ) : null}

            {toggleDeletion && imagesMarkedForDeletion.includes(img.id) ? (
              <div
                className="delete-image"
                onClick={() => toggleDeletion(img.id)}
              >
                <Tooltip placement="top" overlay="Keep">
                  <span className="fa fa-undo" />
                </Tooltip>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

ImageComponent.defaultProps = {
  imagesMarkedForDeletion: []
};

const mapDispatchToProps = dispatch => ({
  modalActions: {
    push: component => dispatch(pushModal(component)),
    pop: () => dispatch(popModal())
  }
});

export default connect(
  null,
  mapDispatchToProps
)(ImageComponent);
