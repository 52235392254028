import React from "react";
import { calculateAppointmentPrice } from "../../helpers";

const AppointmentPriceBanner = ({
  addonIds,
  appointmentTypeId,
  condition,
  defaultPrice,
  formConstants,
  overridden
}) => {
  const calculatePrice = () => {
    if (overridden) return defaultPrice;
    // If the condition is met (usually form is dirty), then calculate price based on current form
    // values instead of blindly displaying the price reported from the server.
    if (condition)
      return calculateAppointmentPrice(
        formConstants,
        appointmentTypeId,
        addonIds
      );

    // Otherwise, just display the price as reported from the server.
    return defaultPrice;
  };

  return (
    <div className="appointment-price-banner">
      <div className="container form-container">
        <div className="row">
          <div className="col-sm-12">
            <div className="price-banner-content text-right">
              Appointment Total:{" "}
              <span className="money">${calculatePrice()}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentPriceBanner;
