import React, { Component } from "react";
import moment from "moment-timezone";
import Tooltip from "rc-tooltip";
import {
  AdminCommentIcon,
  ClientCommentIcon,
  ScheduleAppointmentForm,
  ZoneBadge
} from "../../";

const statusColors = {
  completed: "#FFB575",
  "in-progress": "#F8787A",
  approved: "#AFD47B",
  pending: "#FED866",
  "change-requested": "#AB8BE8",
  selected: "#91CDC2"
};

export default class PetCard extends Component {

  static defaultProps = {
    handleSelectAppointment: () => {},
    showDate: false
  };

  render() {
    const {
      appointment,
      drawerOpen,
      handleScheduleAppointment,
      handleSelectAppointment,
      isTransitional,
      onClickAppointmentDetail,
      onScheduleFail,
      onScheduleSuccess,
      scheduledTimeFieldValue,
      selected,
      selectedDate,
      showDate,
      sitterId
    } = this.props;

    const {
      appointment_status,
      client_notes,
      contact,
      duration_in_minutes,
      home,
      id,
      recurrence,
      scheduled_start_time,
      window_start_time,
      window_end_time
    } = appointment;

    const appointment_pets = appointment.pets;

    const status = appointment_status.name.toLowerCase().replace(" ", "-");
    let startTime = moment(window_start_time).add(30, "minutes");
    if (scheduled_start_time) {
      startTime = moment(scheduled_start_time);
    }

    const petNames = appointment_pets.map(pet => pet.name).join(", ");

    let circleClass = "";
    const homeClass = home.home_class;
    const petClasses = appointment_pets.map(pet => pet.pet_class);
    if (homeClass === "Red" || petClasses.includes("Red")) {
      circleClass = " red-border";
    } else if (homeClass === "Yellow" || petClasses.includes("Yellow")) {
      circleClass = " yellow-border";
    }

    return (
      <div
        // className="pet-card"
        className={`pet-card ${selected ? " selected" : ""}`}
        onClick={() => {
          if (!drawerOpen) {
            handleSelectAppointment(appointment);
          }
        }}
      >
        {selected ? (
          <div className="PetCard-selected-text open">
            <div className="default-text">
              Select an available slot.{" "}
              <span className="fa fa-long-arrow-right" />
            </div>
            <div className="cancel-text">Cancel</div>
          </div>
        ) : (
          <div className="PetCard-selected-text close" />
        )}
        <div
          className={`pet-card-detail ${status}${drawerOpen ? " open" : ""}`}
        >
          {/* <div
          className={`pet-card-detail ${status}${selected ? ' selected' : ''}${drawerOpen ? ' open' : ''}`}
          onClick={() => {
            if (!drawerOpen) {
              handleSelectAppointment(appointment);
            }
          }}
        > */}

          {/* ANNOTATIONS */}
          <div className="pet-card-annotations">
            <div className="left-annotations">
              {recurrence.is_recurring ? (
                <Tooltip
                  placement="top"
                  overlay={
                    <div>
                      {recurrence.recurrence_type === "weekly"
                        ? "Weekly"
                        : "Monthly"}
                    </div>
                  }
                  mouseEnterDelay={0.2}
                  mouseLeaveDelay={0.1}
                  overlayClassName="annotation-tooltip"
                >
                  <div>
                    <span className="fa fa-clock-o recurring-icon" />
                  </div>
                </Tooltip>
              ) : null}
              {/* TODO: Are we pulling the right notes field (Client.notes)? */}
              {home.admin_client_notes ? (
                <Tooltip
                  placement="top"
                  overlay={<div>{home.admin_client_notes}</div>}
                  mouseEnterDelay={0.2}
                  mouseLeaveDelay={0.1}
                  overlayClassName="annotation-tooltip"
                >
                  <div>
                    <AdminCommentIcon
                      color={
                        selected
                          ? statusColors["selected"]
                          : statusColors[status]
                      }
                      height="20"
                      width="18"
                    />
                  </div>
                </Tooltip>
              ) : null}
              {client_notes ? (
                <Tooltip
                  placement="top"
                  overlay={<div>{client_notes}</div>}
                  mouseEnterDelay={0.2}
                  mouseLeaveDelay={0.1}
                  overlayClassName="annotation-tooltip"
                >
                  <div>
                    <ClientCommentIcon
                      color={
                        selected
                          ? statusColors["selected"]
                          : statusColors[status]
                      }
                      height="20"
                      width="18"
                    />
                  </div>
                </Tooltip>
              ) : null}
            </div>
            <div className="right-annotations">
              {showDate ? (
                <p className="appointment-detail">
                  {startTime.format("M/D/YY")}
                </p>
              ) : null}
              <ZoneBadge size="tiny" zone={home.zone} />
            </div>
          </div>

          {/* MAIN */}
          <div className="pet-card-main">
            <div className="pet-image-wrapper">
              <div
                className={`pet-img${circleClass}`}
                style={{
                  backgroundImage: `url(${appointment_pets[0].image_url})`
                }}
              ></div>
            </div>
            <div className="client-info-wrapper">
              <p className="client-name">{contact.full_name}</p>
              <p className="pet-name">{petNames}</p>
              {appointment.sitter ? (
                <p className="pet-name">{appointment.sitter.full_name}</p>
              ) : null}
            </div>
            <div className="client-address-wrapper">
              <p>{home.address.address_line_1}</p>
              {home.address.address_line_2 ? (
                <p>{home.address.address_line_2}</p>
              ) : null}
              <p>
                {home.address.city}, {home.address.state}{" "}
                {home.address.zip_code}
              </p>
            </div>
          </div>

          {/* TIMES */}
          <div className="pet-card-times">
            <div className="pet-card-time-cell left">
              <p className="pet-card-time-label">TIME WINDOW</p>
              <p className="appointment-detail">
                {moment(window_start_time).format("h:mmA")}-
                {moment(window_end_time).format("h:mmA")}
              </p>
            </div>
            <div className="pet-card-time-cell middle">
              <p className="pet-card-time-label">SCHED TIME</p>
              <p className="appointment-detail">
                {scheduled_start_time
                  ? moment(scheduled_start_time).format("h:mmA")
                  : "TBD"}
              </p>
            </div>
            <div className="pet-card-time-cell right">
              <p className="pet-card-time-label">DURATION</p>
              <p className="appointment-detail">{duration_in_minutes}Min</p>
            </div>
          </div>

          {/* APPOINTMENT DETAIL LINK */}
          <div
            className={`appointment-detail-link ${status}${
              selected ? " selected" : ""
            }`}
            onClick={e => {
              e.stopPropagation();
              onClickAppointmentDetail(id);
            }}
          >
            <p>+ APPOINTMENT DETAIL</p>
          </div>
        </div>

        {/* DRAWER */}
        {/*<div className={`pet-card-drawer${drawerOpen ? ' open' : ''}`}>
          <ScheduleAppointmentForm
            key={`schedule-appt-form-${id}${isTransitional ? '-transitional' : ''}`}
            appointmentId={id}
            // This form value guarantees that the form name is unique for appointment cards whether
            // in Unassigned, Assigned, or CalendarDayView, avoiding issues with initialValues
            form={`petCard-${id}${isTransitional ? '-transitional' : ''}${sitterId ? `-sitter-${sitterId}` : ''}`}
            initialValues={{
              appointment_id: _id,
              scheduled_time: scheduledTimeFieldValue,
              sitter_id: sitterId,
            }}
            isRecurring={recurrence.is_recurring}
            onCancel={(e) => {
              e.preventDefault();
              handleSelectAppointment();
            }}
            onSubmit={handleScheduleAppointment}
            onSubmitFail={onScheduleFail}
            onSubmitSuccess={onScheduleSuccess}
            selectedDate={selectedDate}
          />
        </div>*/}
      </div>
    );
  }
}
