import React, { Component } from "react";
import { DropdownList } from "react-widgets";
import { connect } from "react-redux";
import { ZoneBadge } from "../../../";
import moment from "moment";
import DropZone from "./DropTarget";

class SitterDropDown extends Component {

  state = {
    appointments: []
  };

  ListItemComponent = ({ item }) => (
    <div className="sitter-list-item">
      <span>{item.name}</span>
      <div className="zone-container">
        {item.zones.map(zone => (
          <ZoneBadge
            key={`sitter-${item.id}-dropdown-zone-${zone.id}`}
            size="tiny"
            zone={zone}
          />
        ))}
      </div>
    </div>
  );

  DateValueComponent = ({ item }) => (
    <div>
      <h1>{item.name}</h1>
      <p>{item.type}</p>
    </div>
  );

  sitterMap = staff => ({
    id: staff.id,
    name: staff.contact.full_name,
    type: staff.staff_type,
    zones: staff.zones
  });

  setSitter = sitter => {
    this.props.setSelectedSitter(sitter);
    const ourSitter = this.props.staffList.find(s => s._id === sitter._id);
    this.setState({
      sitter: ourSitter ? ourSitter : null
    });
  };

  render() {
    const sitterMap = staff => ({
      id: staff.id,
      name: staff.contact.full_name,
      type: staff.staff_type,
      zones: staff.zones
    });

    return (
      <div className="staff-block staff-block-name">
        {this.props.sitter && this.props.sitter.appointments
          ? this.props.sitter.appointments.map(app => {
              return (
                <DropZone
                  resetAppointment={this.props.resetAppointment}
                  appointment={app.appointment}
                  sitter={this.props.sitter}
                  key={this.props.sitter._id + app.time}
                  onClickAppointmentDetail={this.props.onClickAppointmentDetail}
                  time={app.time.toString()}
                />
              );
            })
          : null}
      </div>
    );
  }
}

export default SitterDropDown;
