import React, { Component } from "react";
import DropdownList from "react-widgets/DropdownList";
import axios from "../../../../services/network.js";
import { InputField } from "../../../shared/formComponents";

export default class AddBundlesForm extends Component {
  state = {
    number: "",
    length: "",
    selectedFacility: null,
    auto_renew: false,
    discount: "",
    promo_type: null,
    isDaycare: false,
    duration_error: false,
    facilities_error: false,
    quantity_error: false,
    discount_error: false,
    visits: [
      { text: "1 Visit", value: 1 },
      { text: "5 Visits", value: 5 },
      { text: "15 Visits", value: 15 }
    ],
    facilityVisits: [
      { text: "1 Visit", value: 1 },
      { text: "5 Visits", value: 5 },
      { text: "20 Visits", value: 20 },
      { text: "50 Visits", value: 50 }
    ],
    total_credits: []
  };

  addBundles = async () => {
    this.props.fetchRequest();

    if (this.state.isDaycare) {
      this.addDaycareBundles();
    } else {
      this.addVisitBundles();
    }

    setTimeout(this.props.removeMessage, 3000);
    this.props.fetchSuccess();
  };

  addDaycareBundles = async () => {
    const { duration_error, quantity_error, discount_error } = this.state;
    let hasNoError = !duration_error && !quantity_error && !discount_error;

    try {
      if (hasNoError) {
        const payload = {
          number: this.state.number,
          length: this.state.length,
          auto_renew: this.state.auto_renew,
          discount: this.state.discount,
          promo_type: this.state.promo_type
        };

        const res = await axios.post(
          `${process.env.REACT_APP_API_URI}/admin/clients/${this.props.client._id}/facilities/${this.state.selectedFacility._id}/credits`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            }
          }
        );

        this.props.fetchData();
        this.props.showMessage({
          message: "Successfully added new daycare bundles",
          messageType: "success"
        });

        this.setState({
          number: "",
          length: "",
          discount: "",
          auto_renew: false
        });
      }
    } catch (e) {
      if (e.data && e.data.message) {
        this.props.showMessage({
          message: e.data.message,
          messageType: "error"
        });
      } else {
        this.props.showMessage({
          message: "Failed to add daycare bundles at this time",
          messageType: "error"
        });
      }
    }
  };

  addVisitBundles = async () => {
    const { duration_error, quantity_error, discount_error } = this.state;
    let hasNoError = !duration_error && !quantity_error && !discount_error;

    try {
      if (hasNoError) {
        const payload = {
          number: this.state.number,
          length: this.state.length,
          auto_renew: this.state.auto_renew,
          discount: this.state.discount,
          promo_type: this.state.promo_type
        };
        const res = await axios.post(
          `${process.env.REACT_APP_API_URI}/admin/walkCredit/${this.props.client._id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            }
          }
        );
        this.props.fetchData();
        this.props.showMessage({
          message: "Successfully added new bundles",
          messageType: "success"
        });
        this.setState({
          number: "",
          length: "",
          discount: "",
          auto_renew: false
        });
      }
    } catch (e) {
      if (e.data && e.data.message) {
        this.props.showMessage({
          message: e.data.message,
          messageType: "error"
        });
      } else {
        this.props.showMessage({
          message: "Failed to add bundles at this time",
          messageType: "error"
        });
      }
    }
  };

  handleDurationUpdate = val => {
    const valid_times = [20, 40, 60, 11, "daycare"];

    if (!valid_times.includes(val)) {
      this.setState({ duration_error: true });
    } else {
      this.setState(
        {
          duration_error: false,
          length: val,
          isDaycare: val === "daycare",
          selectedFacility: null,
          number: null
        },
        () => {
          if (this.props.remove && !this.state.isDaycare) {
            this.validateQuantity(this.state.number, this.state.length);
          }
        }
      );
    }
  };

  handleFacilityUpdate = val => {
    const valid_facilities = this.props.facilities;
    if (!valid_facilities.includes(val)) {
      this.setState({ facilities_error: true });
    } else {
      this.setState({ facilities_error: false, selectedFacility: val });
    }
  };

  handleQuantityUpdate = e => {
    const target = e.target;
    const valid_amounts = [5, 15];
    if (target && !valid_amounts.includes(target.value)) {
      this.setState({ quantity_error: true });
    } else if (this.state.quantity_error) {
      this.setState({ quantity_error: false });
    }
  };

  handleDiscountUpdate = e => {
    let value = e.target.value;
    if (value && value.charAt(value.length - 1) === ".") {
      value = Number(value + "0");
    }
    let error = false;
    if (value) {
      if (isNaN(value)) {
        error = true;
      } else if (value < 0 || value > 100) {
        error = true;
      }
      if (error) {
        this.setState({ discount_error: true, discount: this.state.discount });
      } else {
        this.setState({
          discount_error: false,
          discount: Math.round(value * 10) / 10
        });
      }
    } else {
      this.setState({ discount: 0 });
    }
  };

  handleRemoveQuantity = e => {
    const val = e.target.value;
    let { length } = this.state;
    if (!val || isNaN(val)) {
      this.setState({ quantity_error: true, number: val });
    } else {
      if (!this.state.duration_error) {
        this.validateQuantity(val, length);
      } else {
        this.setState({ number: val, quantity_error: false });
      }
    }
  };

  validateQuantity = (quantity, duration) => {
    let current;
    switch (duration) {
      case 20:
        current = this.props.total_credits.find(c => {
          return c.name === 20;
        });
        break;
      case 40:
        current = this.props.total_credits.find(c => {
          return c.name === 40;
        });
        break;
      case 60:
        current = this.props.total_credits.find(c => {
          return c.name === 60;
        });
        break;
      case 11:
        current = this.props.total_credits.find(c => {
          return c.name === 11;
        });
        break;
      case "daycare":
        current = this.props.total_credits.find(c => {
          return c.name === "daycare";
        });
        break;
      default:
        this.setState({ duration_error: true });
        break;
    }
    if ((current && current.number < quantity) || !quantity) {
      this.setState({ quantity_error: true });
    } else {
      this.setState({ quantity_error: false, number: quantity });
    }
  };

  removeCredits = async () => {
    this.props.fetchRequest();
    let quantityQuery = `?quantity=${this.state.number}`;
    let durationQuery = `&duration=${this.state.length}`;
    let facilityQuery = this.state.isDaycare
      ? `&facility=${this.state.selectedFacility._id}`
      : "";

    try {
      if (!this.state.quantity_error && !this.state.duration_error) {
        const res = await axios.delete(
          `${process.env.REACT_APP_API_URI}/admin/walkCredit/${this.props.client._id}${quantityQuery}${durationQuery}${facilityQuery}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            }
          }
        );
        this.props.fetchData();
        this.props.showMessage({
          message: "Successfully removed credits",
          messageType: "success"
        });
        this.setState({ number: "", length: "" });
      }
    } catch (e) {
      if (e.data && e.data.message) {
        this.props.showMessage({
          message: e.data.message,
          messageType: "error"
        });
      } else {
        this.props.showMessage({
          message: "Failed to remove credits at this time",
          messageType: "error"
        });
      }
    }
    setTimeout(this.props.removeMessage, 3000);
    this.props.fetchSuccess();
  };

  toggleRemove = e => {
    e.stopPropagation();

    this.props.toggleRemove();
  };

  getBundleOptions = () => {
    const { client } = this.props;
    const data = [
      { text: "20 Minutes", value: 20 },
      { text: "40 Minutes", value: 40 },
      { text: "60 Minutes", value: 60 }
    ];
    if (
      client.apartment &&
      client.apartment.playcare &&
      client.apartment.playcare.enabled
    ) {
      data.push({ text: "Playcare", value: 11 });
    }
    if (client.facilities && client.facilities.length > 0) {
      data.push({ text: "Daycare", value: "daycare" });
    }
    return data;
  };

  render() {
    return (
      <div className="add-bundles-container">
        <div className="row">
          <div className="col-sm-2">
            <div
              className={`form-component-wrapper ${
                this.state.duration_error ? "has-error" : ""
              }`}
            >
              <DropdownList
                data={this.getBundleOptions()}
                placeholder="Duration"
                value={this.state.length}
                valueField={"value"}
                textField={"text"}
                onChange={item => {
                  this.handleDurationUpdate(item.value);
                }}
              />
            </div>
          </div>
          {this.state.isDaycare ? (
            <div className="col-sm-2">
              <div
                className={`form-component-wrapper ${
                  this.state.facilities_error ? "has-error" : ""
                }`}
              >
                <DropdownList
                  data={this.props.facilities}
                  placeholder="Facilities"
                  value={this.state.selectedFacility}
                  textField={"name"}
                  onChange={item => {
                    this.handleFacilityUpdate(item);
                  }}
                />
              </div>
            </div>
          ) : null}
          <div className="col-sm-2">
            <div
              className={`form-component-wrapper ${
                this.state.quantity_error ? "has-error" : ""
              }`}
            >
              {this.props.remove ? (
                <input
                  type="text"
                  onChange={e => {
                    this.handleRemoveQuantity(e);
                  }}
                  placeholder="Quantity"
                  value={this.state.number}
                />
              ) : (
                <DropdownList
                  data={
                    this.state.isDaycare
                      ? this.state.facilityVisits
                      : this.state.visits
                  }
                  placeholder="Quantity"
                  value={this.state.number}
                  valueField={"value"}
                  textField={"text"}
                  onChange={item => {
                    this.setState({ number: item.value });
                  }}
                />
              )}
            </div>
          </div>
          {this.props.remove ? null : (
            <div className="col-sm-2">
              <div className="form-component-wrapper">
                <DropdownList
                  data={["Promo", "Error", "Transfer"]}
                  placeholder="Promo Type"
                  onChange={item => this.setState({ promo_type: item })}
                />
              </div>
            </div>
          )}
          {this.props.remove ? null : (
            <div>
              <div className="col-sm-3">
                <div
                  className={`form-component-wrapper ${
                    this.state.discount_error ? "has-error" : ""
                  }`}
                >
                  <label>Discount (%)</label>
                  <input
                    type="text"
                    value={this.state.discount}
                    onChange={e => {
                      this.handleDiscountUpdate(e);
                    }}
                    placeholder="Discount %"
                  />
                </div>
              </div>
              {this.state.isDaycare ? null : (
                <div className="col-sm-3">
                  <div className="form-component-wrapper">
                    <label>Enable Auto Renew</label>
                    <input
                      type="checkbox"
                      checked={this.state.auto_renew}
                      onChange={() => {
                        this.setState({ auto_renew: !this.state.auto_renew });
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {this.state.duration_error || this.state.quantity_error ? (
          <div className="row">
            <div className="col-sm-3">
              {this.state.duration_error ? (
                <span className="help-block">
                  Select a valid duration amount.
                </span>
              ) : null}
            </div>
            <div className="col-sm-3">
              {this.state.quantity_error ? (
                <span className="help-block">
                  Enter a valid quantity that is less than or equal to than the
                  number of available credits.
                </span>
              ) : null}
            </div>
          </div>
        ) : null}
        <div className="row">
          <div className="col-sm-12">
            {this.props.remove ? (
              <div>
                <button
                  className="btn btn-danger"
                  onClick={e => {
                    this.removeCredits(e);
                  }}
                >
                  Remove
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={e => {
                    this.toggleRemove(e);
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div>
                <button
                  className="btn btn-primary"
                  onClick={e => {
                    this.addBundles(e);
                  }}
                >
                  Submit
                </button>
                <button
                  className="btn btn-danger"
                  onClick={e => {
                    this.toggleRemove(e);
                  }}
                >
                  Remove Credits
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
