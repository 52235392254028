import React, { Component } from "react";
import axios from "../../services/network.js";
import { CreditsDisplayCard, AddBundlesForm } from "../../components";

export default class ClientAddVisitsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      credits: [],
      editing: null,
      show_remove: false
    };
  }

  componentWillMount = () => {
    this.fetchData();
  };

  fetchData = async () => {
    try {
      this.props.fetchRequest();
      const client = this.props.client;
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URI}/admin/client/${client._id}/credits`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );

      this.setState({
        credits: data,
        editing: null
      });

      this.props.fetchSuccess();
    } catch (e) {
      this.props.fetchSuccess();
    }
  };

  setEditing = duration => {
    this.setState({ editing: duration });
  };

  setAutoRenew = (type, quantity) => {
    let newCredits = this.state.credits.map(c => {
      if (c.type === type) c.auto_renew = quantity;
      return c;
    });
    this.setState({ credits: newCredits });
  };

  saveAutoRenew = async () => {
    this.props.fetchRequest();
    let auto_renew = {};
    for (let credit of this.state.credits) {
      auto_renew[credit.name] = credit.auto_renew;
    }
    await axios.post(
      `${process.env.REACT_APP_API_URI}/admin/autoRenew/${this.props.client._id}`,
      auto_renew,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      }
    );
    this.fetchData();
    this.props.fetchSuccess();
  };

  renderCreditCards = () => {
    return this.state.credits.map((credit, i) => {
      const { client } = this.props;
      let isPetcare = credit.name === "play_care" && !(client.apartment && client.apartment.playcare && client.apartment.playcare.enabled);

      if (isPetcare) {
        //return nothing if playcare isn't enabled...
        return null;
      }

      return (
        <CreditsDisplayCard
          key={`credit${i}`}
          type={credit.type}
          count={credit.number}
          credits={this.state.credits}
          auto_renew={credit.auto_renew ? credit.auto_renew : 0}
          client={this.props.client}
          setEditing={this.setEditing}
          editing={this.state.editing === 20}
          setAutoRenew={this.setAutoRenew}
          saveAutoRenew={this.saveAutoRenew}
        />
      );
    });
  };

  toggleRemove = () => {
    this.setState({ show_remove: !this.state.show_remove });
  };

  render() {
    return (
      <div className="container form-container clearfix visit-credits">
        <div className="row creditRow">{this.renderCreditCards()}</div>
        <div className="row">
          <h3 className="col-sm-12">Add/Remove Visit Credits</h3>
        </div>
        <div className="row">
          <AddBundlesForm
            client={this.props.client}
            facilities={this.props.facilities}
            fetchData={this.fetchData}
            remove={this.state.show_remove}
            total_credits={this.state.show_remove ? this.state.credits : null}
            fetchSuccess={this.props.fetchSuccess}
            fetchRequest={this.props.fetchRequest}
            toggleRemove={this.toggleRemove}
            showMessage={this.props.showMessage}
            removeMessage={this.props.removeMessage}
          />
        </div>
      </div>
    );
  }
}
