import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import moment from "moment";
import axios from "../../../../services/network.js";
import queryString from "query-string";
import {
  calculateAppointmentPrice,
  formatNumsToDollars,
  parseTimeInput,
  parseDateNotBeforeToday,
  parseClientApptTimeInput
} from "../../../../helpers";
import {
  AppointmentPetField,
  AppointmentPriceOverrideField,
  CheckboxField,
  DateField,
  TimeField,
  DropdownListField,
  FieldColumnWrapper,
  FormSection,
  MultiselectField,
  RecurringBoolField,
  TextAreaField,
  WeekdaysField,
  PetService
} from "../../../shared/formComponents";
import { AppointmentPriceBanner } from "../../../";
import { getPetsForAppointment } from "../../../../actions/admin/clients.actions";

const weekdays = [
  {
    id: "Sunday",
    name: "SUN"
  },
  {
    id: "Monday",
    name: "MON"
  },
  {
    id: "Tuesday",
    name: "TUE"
  },
  {
    id: "Wednesday",
    name: "WED"
  },
  {
    id: "Thursday",
    name: "THU"
  },
  {
    id: "Friday",
    name: "FRI"
  },
  {
    id: "Saturday",
    name: "SAT"
  }
];

class ScheduleAppointmentForm extends Component {
  state = {
    concierge_week_visits: 0,
    concierge_day_visits: 0,
    selected_date: null,
    appointment_type: null,
    vacation_days: []
  };


  getServices = () => {
    const { formConstants, clientInfo, recurring } = this.props;

    let conciergeAvailable = false
    let playcareAvailable = false

    return formConstants.appointmentTypes
      .map(svc => ({ id: svc.id, name: svc.name }))
      .filter(svc => {
        return conciergeAvailable ? svc : svc.id !== 12;
      })
      .filter(svc => {
        return playcareAvailable ? svc : svc.id !== 11;
      });
  };

  getAddons = () => {
    const { formConstants } = this.props;

    if (formConstants && formConstants.addOnServicesGeneral) {

      return formConstants.addOnServicesGeneral.map(svc => ({
        id: svc.id,
        name: `${svc.name}${
          svc.price ? ` (Adds ${formatNumsToDollars(svc.price)})` : ""
        }`,
        description: svc.description
      }));
    }

    return []
  };

  getWindowMenus = () => {
    let { appointmentTypeId, clientInfo } = this.props;
   

    return (
      <div>
        <FieldColumnWrapper column="2">
          <Field
            component={TimeField}
            name="window_start_time"
            calendar={false}
            placeholder="Window Start Time"
            dateFormat="h:mm a"
            parseInput={parseTimeInput}
            required
          />
        </FieldColumnWrapper>

        <FieldColumnWrapper column="2">
          <Field
            component={TimeField}
            name="window_end_time"
            calendar={false}
            placeholder="Window End Time"
            dateFormat="h:mm a"
            parseInput={parseTimeInput}
            required
          />
        </FieldColumnWrapper>
      </div>
    );
  };

  getApartmentData = async (date, type) => {
    if (date || type) {
      console.log(type, this.props.appointmentTypeId);
      if (type === 11 || this.props.appointmentTypeId === 11) {
        this.props.getPetsForAppointment(this.props.clientInfo._id, type);
      }
      const { clientInfo } = this.props;
      const visit_date = date ? moment(date) : moment();
      let week_visits = [];
      let day_visits = [];
      let todaysPets = 0;
      if (type === 12) {
        const week_start = moment(visit_date).startOf("week");
        const week_end = moment(visit_date).endOf("week");
        const day_start = moment(visit_date).startOf("day");
        const day_end = moment(visit_date).endOf("day");

        week_visits = clientInfo.appointments.filter(a => {
          return (
            a.appointment_type.id === 12 &&
            moment(a.window_start_time, "YYYY-MM-DD HH:mm").isAfter(
              week_start
            ) &&
            moment(a.window_start_time, "YYYY-MM-DD HH:mm").isBefore(
              week_end
            ) &&
            a.appointment_status.id !== 7
          );
        });

        day_visits = week_visits.filter(a =>
          moment(a.window_start_time, "YYYY-MM-DD HH:mm").isSame(
            visit_date,
            "day"
          )
        );
      }
      if (type === 11) {
        const query = {
          visitDate: visit_date
        };
        let apartment_id;
        if (clientInfo && clientInfo.apartment)
          apartment_id = clientInfo.apartment._id;
        if (apartment_id) {
          const { data } = await axios.get(
            `${
              process.env.REACT_APP_API_URI
            }/admin/apartments/${apartment_id}/schedule?${queryString.stringify(
              query
            )}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`
              }
            }
          );
          todaysPets = data.pet_count;
        }
      }
      this.setState({
        selected_date: visit_date.toDate(),
        appointment_type: type,
        concierge_week_visits: week_visits,
        concierge_day_visits: day_visits,
        playcare_pet_count: todaysPets
      });
    }
  };

  render() {

    // console.log('props.', this.props)

    const {
      add_on_services,
      appointmentTypeId,
      clientInfo,
      dirty,
      formConstants,
      handleSubmit,
      modalActions,
      priceOverridden,
      price,
      recurring
    } = this.props;

    const addonIds = add_on_services ? add_on_services.map(a => a.id) : [];

    return (
      <form onSubmit={handleSubmit}>
        <FormSection title="Appointment Details">
          <div className="row">
            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                name="appointment_type_id"
                busy={false} // DropdownList: Display spinner
                data={this.getServices()} // DropdownList: Array of strings or objects or other primitives to populate
                valueField="id" // DropdownList: If data is obj, determines which property is the id
                textField={(item) => {
                  if (formConstants && formConstants.appointmentTypes) {
                    let type = formConstants.appointmentTypes.find(t => t.id === item)
                    if (type) return type.name
                  }
                  return item.name
                }} // DropdownList: If data is obj, determines which property gets displayed
                // filter="contains" // DropdownList: If you want to filter, include this with string indicating filter type
                parse={(val, name) => val.id} // reduxForm: parse decides what goes into the state in the form store
                placeholder="Appointment Type"
                onChange={(e, v) => {
                  this.getApartmentData(this.state.selected_date, v);
                }}
                required
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="8">
              <Field
                component={MultiselectField}
                name="add_on_services"
                data={this.getAddons()} // Multiselect: Array of strings or objects or other primitives to populate
                textField="name" // Multiselect: If data is obj, determines which property gets displayed
                parse={(val, name) => val} // reduxForm: parse decides what goes into the state in the form store
                placeholder="General Service Offerings"
              />
            </FieldColumnWrapper>
          </div>
        </FormSection>

        <FormSection title="Pet-Specific Services">
          <Field
            component={PetService}
            name="pet_addons"
            pets={clientInfo.pets.map(p => p._id)}
            clientPets={clientInfo.pets}
            modalActions={modalActions}
            options={formConstants.addOnServicesPet.filter(
              s => s.id !== 1 && s.id !== 2
            )}
          />
        </FormSection>

        <FormSection title="Appointment Schedule">
          {appointmentTypeId !== 12 ? (
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4">
                <h3>Would you like this appointment to recur?</h3>
              </div>
              <div className="col-lg-6 col-md-5 col-sm-6 appt-recurring-options">
                <Field component={RecurringBoolField} name="recurring" />
              </div>
            </div>
          ) : null}

          <div className="row">
            {recurring ? (
              <div>
                <FieldColumnWrapper column="4">
                  <Field
                    component={DateField}
                    name="recurrence_start_date"
                    dateFormat="dddd, MMM. D, Y"
                    min={new Date()}
                    parseInput={parseDateNotBeforeToday}
                    placeholder="Start Date"
                    required
                    time={false}
                  />
                </FieldColumnWrapper>

                <FieldColumnWrapper column="8">
                  <Field
                    component={WeekdaysField}
                    required
                    name="recurrence_weekdays"
                    options={weekdays}
                    appointmentTypeId={this.props.appointmentTypeId}
                    clientInfo={this.props.clientInfo}
                  />
                </FieldColumnWrapper>
                {appointmentTypeId !== 11 ? (
                  <FieldColumnWrapper column="4">
                    <Field
                      component={TimeField}
                      name="window_start_time"
                      calendar={false}
                      placeholder="Window Start Time"
                      dateFormat="h:mm a"
                      parseInput={parseTimeInput}
                      required
                    />
                  </FieldColumnWrapper>
                ) : null}

                {appointmentTypeId !== 11 ? (
                  <FieldColumnWrapper column="4">
                    <Field
                      component={TimeField}
                      name="window_end_time"
                      calendar={false}
                      placeholder="Window End Time"
                      dateFormat="h:mm a"
                      parseInput={parseTimeInput}
                      required
                    />
                  </FieldColumnWrapper>
                ) : null}

                <FieldColumnWrapper column="4">
                  <Field
                    component={DropdownListField}
                    name="appointment_type_id"
                    busy={false} // DropdownList: Display spinner
                    data={this.getServices()} // DropdownList: Array of strings or objects or other primitives to populate
                    valueField="id" // DropdownList: If data is obj, determines which property is the id
                    textField="name" // DropdownList: If data is obj, determines which property gets displayed
                    // filter="contains" // DropdownList: If you want to filter, include this with string indicating filter type
                    parse={(val, name) => val.id} // reduxFFenorm: parse decides what goes into the state in the form store
                    placeholder="Appointment Duration"
                    required
                  />
                </FieldColumnWrapper>

                <FieldColumnWrapper column="4">
                  <Field
                    component={DateField}
                    name="recurrence_end_date"
                    time={false}
                    dateFormat="MMM. D, Y"
                    min={new Date(moment().add(1, "weeks"))}
                    placeholder="End Date (optional)"
                  />
                </FieldColumnWrapper>
              </div>
            ) : (
              <div>
                <FieldColumnWrapper column="3">
                  <Field
                    component={DateField}
                    name="requested_date"
                    time={false}
                    dateFormat="dddd, MMM. D, Y"
                    placeholder="Requested Date"
                    onChange={(e, v) => {
                      this.getApartmentData(v, this.state.appointment_type);
                    }}
                    required
                  />
                </FieldColumnWrapper>

                {this.getWindowMenus()}
              </div>
            )}
          </div>
        </FormSection>

        <FormSection title="Pets In This Appointment">
          <Field
            component={AppointmentPetField}
            name="pet_ids"
            formConstants={formConstants}
            modalActions={modalActions}
            clientPets={clientInfo.pets}
          />
        </FormSection>

        <FormSection title="Client Notes for Assigned Waggle Team Member">
          <div className="row">
            <div className="col col-sm-12">
              <Field
                component={TextAreaField}
                name="client_notes"
                placeholder="Are there any notes the client would like to add for the assigned Team Member? (Client can see/edit these.)"
              />
            </div>
          </div>
        </FormSection>

        <FormSection title="Admin Notes for Assigned Waggle Team Member">
          <div className="row">
            <div className="col col-sm-12">
              <Field
                component={TextAreaField}
                name="admin_notes_to_sitter"
                placeholder="Are there any notes you would like to add for the assigned Team Member? (Client can NOT see/edit these.)"
              />
            </div>
          </div>
        </FormSection>

        <FormSection title="Price Adjustments">
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="price-override">
                <div className="override-checkbox">
                  <Field
                    component={CheckboxField}
                    name="price_overridden"
                    type="checkbox"
                    label="Manually override price?"
                  />
                  {appointmentTypeId && priceOverridden
                    ? `(Original Price: $${calculateAppointmentPrice(
                        formConstants,
                        appointmentTypeId,
                        []
                      )})`
                    : null}
                </div>
                <div className="override-price">
                  {priceOverridden ? <AppointmentPriceOverrideField /> : null}
                </div>
              </div>
            </div>
          </div>
        </FormSection>

        {appointmentTypeId === 8 || appointmentTypeId === 10 ? (
          <div className="appointment-price-wrapper">
            <AppointmentPriceBanner
              addonIds={addonIds || []}
              appointmentTypeId={appointmentTypeId}
              // condition upon which the price should be calculated based on form values
              condition={appointmentTypeId && !(priceOverridden && price)}
              // price to display when not being calculated from form values
              defaultPrice={
                priceOverridden && price ? (price / 100).toFixed(2) : "0.00"
              }
              formConstants={formConstants}
            />
          </div>
        ) : null}

        <FormSection>
          <div className="row">
            {dirty ? (
              <div className="col-sm-3 text-right button-column pull-right">
                <input
                  type="submit"
                  className="btn btn-block btn-primary"
                  value="Schedule & View"
                />
              </div>
            ) : null}
          </div>
        </FormSection>
      </form>
    );
  }
}

const warn = (values, props) => {
  const warnings = {};
  if (props) {
    const check = props.vacation_days.find(d =>
      moment(d.date, "MM/DD/YYYY").isSame(moment(values.requested_date), "day")
    );
    if (!!check) {
      warnings.requested_date = "The date selected is a vacation date";
    }
  }
  return warnings;
};

const validate = (values, props) => {
  const errors = {};
  if (!values.appointment_type_id) errors.appointment_type_id = true;
  if (values.appointment_type_id !== 10 && values.appointment_type_id !== 8) {
    if (values.appointment_type_id === 11) {
      values.pet_ids = values.pet_ids.filter(id => {
        const find = props.clientInfo.pets.find(p => p._id === id);
        if (find) return id;
      });
    }
    if (!values.pet_ids || !values.pet_ids.length) errors.pet_ids = true;
  }

  if (!values.requested_date) errors.requested_date = true;
  if (
    !values.window_start_time &&
    values.appointment_type_id !== 12 &&
    values.appointment_type_id !== 11
  )
    errors.window_start_time = true;
  if (
    !values.window_end_time &&
    values.appointment_type_id !== 12 &&
    values.appointment_type_id !== 11
  )
    errors.window_end_time = true;

  if (values.scheduled_date || values.scheduled_time) {
    if (!values.scheduled_date) errors.scheduled_date = true;
    if (!values.scheduled_time) errors.scheduled_time = true;
  }

  if (values.completed_date || values.actual_start_time) {
    if (!values.completed_date) errors.completed_date = true;
    if (!values.actual_start_time) errors.actual_start_time = true;
  }

  if (values.actual_end_time) {
    if (!values.completed_date) errors.completed_date = true;
    if (!values.actual_start_time) errors.actual_start_time = true;
  }

  if (values.price_overridden && !values.price) errors.price = true;

  if (values.recurring) {
    if (!values.recurrence_start_date) errors.recurrence_start_date = true;
    if (!values.recurrence_weekdays || !values.recurrence_weekdays.length)
      errors.recurrence_weekdays = true;
  }

  if (values.appointment_type_id && values.appointment_type_id === 12) {
    let days = props.clientInfo.apartment.concierge.days;
    if (!days.includes(moment(values.requested_date).day())) {
      errors.requested_date = true;
      errors.customMessageText = "Invalid day selected for concierge visit.";
    }
  }
  //this gets handled in the service now...

  // console.log('errors...', errors)

  return errors;
};

ScheduleAppointmentForm = reduxForm({
  form: "adminEditAppointment",
  validate,
  warn
})(ScheduleAppointmentForm);

// connect using react-redux and reduxForm's formValueSelector to get form values
const selector = formValueSelector("adminEditAppointment");
const mapStateToProps = state => ({
  add_on_services: selector(state, "add_on_services"),
  appointmentStatusId: selector(state, "appointment_status_id"),
  appointmentTypeId: selector(state, "appointment_type_id"),
  priceOverridden: selector(state, "price_overridden"),
  price: selector(state, "price"),
  recurrence_weekdays: selector(state, "recurrence_weekdays"),
  recurrence_start_date: selector(state, "recurrence_start_date"),
  recurring: selector(state, "recurring")
});

const mapDispatchToProps = dispatch => ({
  getPetsForAppointment: (clientId, appointmentTypeId) =>
    dispatch(getPetsForAppointment(clientId, appointmentTypeId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleAppointmentForm);
