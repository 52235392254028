export default function validate(values) {
  var errors = {};

  // General Information
  if (!values.name) errors.name = true;

  if (!values.petclass || values.petclass === "-1") errors.petclass = true;
  if ((values.petclass === 2 || values.petclass === 3) && !values.petnotes)
    errors.petnotes = true;

  return errors;
}
