import React, { Component } from "react";
import { AddPetOverlay, PetDetailsRow } from "../../../components";

export default class AppointmentPetField extends Component {

  handleRemoval = petId => {
    const { input } = this.props;
    const newPetIds = input.value.filter(val => val !== petId);
    input.onChange(newPetIds);
  };

  renderAddPetButton = () => {
    const { clientPets, disabled, input, modalActions } = this.props;
    const { onChange, value } = input;

    // Keep these in order so the form doesn't think the value changed if we remove and add the same pets.
    const addPets = ids => {
      const newPetIds = [...value, ...ids].sort();
      return onChange(newPetIds);
    };

    // Don't render the link if all of the client's pets are already added to the appointment!
    const availablePets = clientPets
      .filter(pet => pet.active)
      .filter(pet => !value.includes(pet.id));

    if (disabled || !availablePets || !availablePets.length) {
      return null;
    }

    const modalComponent = (
      <AddPetOverlay
        fullScreen
        onAddPets={addPets}
        onDismiss={modalActions.pop}
        clientPets={clientPets.filter(pet => pet.active)}
        selectedPets={value}
      />
    );

    return (
      <div className="col-sm-12">
        <span className="inline-link">
          <a onClick={() => modalActions.push(modalComponent)}>
            {" "}
            <span className="fa fa-plus" />
            Add Pets
          </a>
        </span>
      </div>
    );
  };

  renderPets = () => {
    const {
      input,
      clientPets,
      showAddOns,
      isAppointmentForm,
      handleAddService,
      formConstants,
      ...props
    } = this.props;
    const allowRemoval = input.value.length > 1;
    return clientPets
      .filter(pet => input.value.includes(pet._id))
      .map(pet => {
        return (
          <PetDetailsRow
            isAppointmentForm={isAppointmentForm}
            formConstants={formConstants}
            handleAddService={handleAddService}
            key={`pet2-${pet._id}`}
            pet={pet}
            removePet={() => this.handleRemoval(pet._id)}
            allowRemoval={allowRemoval}
            showAddOns={showAddOns}
            {...props}
          />
        );
      });
  };

  render = () => (
    <div className="row">
      {this.renderPets()}
      {this.renderAddPetButton()}
    </div>
  );
}
