import React, { Component } from "react";
import moment from "moment-timezone";

class AppointmentListItem extends Component {
  state = {
    open: false
  };

  renderArrow = () => {
    const { appointments } = this.props;
    if (appointments.length) {
      if (this.state.open) {
        return (
          <i
            className="fa fa-chevron-up"
            onClick={() => this.setState({ open: !this.state.open })}
          />
        );
      } else {
        return (
          <i
            className="fa fa-chevron-down"
            onClick={() => this.setState({ open: !this.state.open })}
          />
        );
      }
    } else {
      return null;
    }
  };

  highlight = (e, _id) => {
    e.stopPropagation();
    this.props.highlightAppointmentOnMap(_id);
  };

  render() {
    const { appointments, type } = this.props;
    let ourClass;
    switch (type) {
      case "Pending":
        ourClass = "pending";
        break;
      case "Cancellations":
        ourClass = "canceled";
        break;
      case "Change Requests":
        ourClass = "edit";
        break;
      case "Approved + Unassigned":
        ourClass = "approvedUnassigned";
        break;
      case "Scheduled":
        ourClass = "approved";
        break;
      case "Completed":
        ourClass = "completed";
        break;
      case "Consultations":
        ourClass = "consultation";
        break;
      default:
        ourClass = "approvedUnassigned";
    }
    return (
      <div className="appt-item">
        <span
          className="appt-title"
          onClick={() => this.setState({ open: !this.state.open })}
        >
          {this.props.type} ({appointments.length})
        </span>
        {this.renderArrow()}
        <div className="appt-list-container">
          {this.state.open
            ? appointments
                .sort(
                  (a, b) =>
                    moment(a.window_start_time) - moment(b.window_start_time)
                )
                .map(app => {
                  return (
                    <div
                      key={`apptListKey${app._id}`}
                      className={`appt-list-item ${ourClass}`}
                      onMouseEnter={e => this.highlight(e, app._id)}
                      onMouseLeave={e => this.highlight(e, null)}
                      onClick={() => this.props.showPreview(app)}
                    >
                      <span>
                        {app.scheduled_start_time
                          ? moment(app.scheduled_start_time).format("h:mm a")
                          : moment(app.window_start_time).format("h:mm a")}
                      </span>
                      <span>
                        {app.appointment_type.duration_in_minutes} Min
                      </span>
                    </div>
                  );
                })
            : null}
        </div>
      </div>
    );
  }
}

export default AppointmentListItem;
