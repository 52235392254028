import React, { Component } from "react";
import { connect } from "react-redux";
import {
  CardComponent,
  MarketForm,
  ZipCodeRowItem,
  AddZipCodeModal,
  DefaultModal
} from "../../components";
import axios from "../../services/network.js";
import { pushModal, popModal } from "../../actions/general.actions";
import { DatePicker, DropdownList } from "react-widgets";
import moment from "moment";

class EditMarket extends Component {
  state = {
    zip_codes: [],
    date: moment().toDate(),
    consultationDate: moment().toDate(),
    disabled_times: [],
    disabled_consultation_times: [],
    availableTimes: [],
    availableConsultationTimes: [],
    market: {}
  };

  componentWillMount = () => {
    this.loadMarket();
    this.fetchZipCodes();
    this.getTimes();
    this.getConsultationTimes();
  };

  componentDidMount = () => {
    let times = [];
    for (
      let i = moment("10:00", "HH:mm");
      i.isBefore(moment("18:31", "HH:mm"));
      i.add(30, "minutes")
    ) {
      times.push(i.format("HH:mm"));
    }

    this.setState({ availableTimes: times, availableConsultationTimes: times });
  };

  loadMarket = async () => {
    const { showSpinner, showMessage, removeMessage, stopSpinner } = this.props;
    showSpinner();
    let _id = this.props.match.params.marketId;
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URI}/admin/market/${_id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
        }
      }
    );
    let market = { ...data };
    market.start_date = moment(market.start_date).toDate();
    this.setState({ market });
  };

  saveMarket = async market => {
    const { showSpinner, showMessage, removeMessage, stopSpinner } = this.props;
    let _id = this.props.match.params.marketId;
    showSpinner();
    if (market && market.city && market.state && _id) {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URI}/admin/market/${_id}`,
        market,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken")
          }
        }
      );
      if (res.status === 200) {
        showMessage({
          message: `Successfully updated market in ${market.city}, ${market.state}`,
          messageType: "success"
        });
      } else {
        showMessage({
          message: "Unable to update the market at this time",
          messageType: "error"
        });
      }
      setTimeout(removeMessage, 3000);
    }
    stopSpinner();
  };

  fetchZipCodes = async () => {
    const { showSpinner, stopSpinner } = this.props;
    let _id = this.props.match.params.marketId;
    showSpinner();
    if (_id) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URI}/admin/market/${_id}/zipCodes`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken")
          }
        }
      );
      this.setState({ zip_codes: data });
    }
    stopSpinner();
  };

  renderZipCodes = active => {
    const { zip_codes } = this.state;
    //I'm using an active flag but the server uses inactive
    const filtered = zip_codes.filter(z => z.wait_list === !active);
    if (zip_codes && zip_codes.length) {
      return filtered.map(zip => {
        return (
          <ZipCodeRowItem
            zip={zip}
            key={zip._id}
            active={active}
            updateZip={this.updateZip}
            createDeleteModal={this.createDeleteModal}
          />
        );
      });
    }
  };

  updateZip = async zip => {
    const { showSpinner, stopSpinner, showMessage, removeMessage } = this.props;
    let _id = this.props.match.params.marketId;
    showSpinner();
    if (zip) {
      zip.wait_list = !zip.wait_list;
      const res = await axios.put(
        `${process.env.REACT_APP_API_URI}/admin/zip/${zip._id}`,
        zip,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken")
          }
        }
      );
      if (res.status === 200) {
        showMessage({
          message: "Successfully updated Zip Code " + zip.zip_code,
          messageType: "success"
        });
      } else {
        showMessage({
          message:
            "Unable to update Zip Code " + zip.zip_code + " at this time",
          messageType: "error"
        });
      }
      setTimeout(removeMessage, 3000);
    }
    stopSpinner();
    this.fetchZipCodes();
  };

  addZipCode = async zip_code => {
    const {
      showSpinner,
      stopSpinner,
      showMessage,
      removeMessage,
      modalActions
    } = this.props;
    const { city, state } = this.state.market;
    let _id = this.props.match.params.marketId;
    showSpinner();
    if (zip_code) {
      const zip = {
        market_id: _id,
        wait_list: true,
        state_abbreviation: state,
        zip_code: zip_code
      };
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URI}/admin/zip`,
          zip,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("accessToken")
            }
          }
        );
        showMessage({
          message: "Successfully created Zip Code " + zip.zip_code,
          messageType: "success"
        });
      } catch (e) {
        showMessage({
          message:
            "Unable to create Zip Code " + zip.zip_code + " at this time",
          messageType: "error"
        });
      }
      setTimeout(removeMessage, 3000);
    }
    stopSpinner();
    this.fetchZipCodes();
    modalActions.pop();
  };

  deleteZipCode = async id => {
    const {
      showSpinner,
      stopSpinner,
      showMessage,
      removeMessage,
      modalActions
    } = this.props;
    let _id = this.props.match.params.marketId;
    showSpinner();
    if (id) {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URI}/admin/zip/${id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken")
          }
        }
      );
      if (res.status === 200) {
        showMessage({
          message: "Successfully deleted Zip Code",
          messageType: "success"
        });
      } else {
        showMessage({
          message: "Unable to delete Zip Code at this time",
          messageType: "error"
        });
      }
      setTimeout(removeMessage, 3000);
    }
    stopSpinner();
    this.fetchZipCodes();
    modalActions.pop();
  };

  createAddModal = () => {
    const { modalActions } = this.props;
    const modal = (
      <AddZipCodeModal
        addZipCode={this.addZipCode}
        onDismiss={modalActions.pop}
      />
    );
    modalActions.push(modal);
  };

  createDeleteModal = id => {
    const { modalActions } = this.props;
    const modal = (
      <DefaultModal
        title="Delete Zip Code?"
        onDismiss={modalActions.pop}
        options={
          <div>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={modalActions.pop}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger normal-size-btn"
              onClick={() => {
                this.deleteZipCode(id);
              }}
            >
              Delete
            </button>
          </div>
        }
      >
        Are you sure you would like to delete this Zip Code?
        <br />
        This action cannot be undone.
      </DefaultModal>
    );
    modalActions.push(modal);
  };

  getTimes = async () => {
    const { showSpinner, stopSpinner } = this.props;
    const { date } = this.state;
    let _id = this.props.match.params.marketId;
    showSpinner();
    if (_id) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URI}/admin/getTimes?date=${moment(
          date
        ).format("YYYY-MM-DD")}&market=${_id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken")
          }
        }
      );

      this.setState({
        disabled_times: Object.keys(data.blocked_times)
      });
    }
    stopSpinner();
  };

  getConsultationTimes = async () => {
    const { showSpinner, stopSpinner } = this.props;
    const date = this.state.consultationDate;
    let _id = this.props.match.params.marketId;
    showSpinner();
    if (_id) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URI}/admin/getTimes?date=${moment(
          date
        ).format("YYYY-MM-DD")}&market=${_id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken")
          }
        }
      );

      this.setState({
        disabled_consultation_times: Object.keys(
          data.blocked_consultation_times
        )
      });
    }
    stopSpinner();
  };

  renderTimes = () => {
    let times = this.state.disabled_times;
    if (!times || !times.length) return <div>No times disabled.</div>;
    return times
      .sort((a, b) => {
        if (moment(a, "HH:mm").toDate() > moment(b, "HH:mm").toDate()) return 1;
        if (moment(a, "HH:mm").toDate() < moment(b, "HH:mm").toDate())
          return -1;
        return 0;
      })
      .map(t => {
        return (
          <div key={t} className="row">
            <div className="col-sm-4">
              {moment(t, "HH:mm").format("hh:mm a")}
            </div>
            <div
              className="col-sm-4"
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.disableTime(moment(t, "hh:mm a").format("HH:mm"), false)
              }
            >
              X
            </div>
          </div>
        );
      });
  };

  renderConsultationTimes = () => {
    let times = this.state.disabled_consultation_times;
    if (!times || !times.length) return <div>No times disabled.</div>;
    return times
      .sort((a, b) => {
        if (moment(a, "HH:mm").toDate() > moment(b, "HH:mm").toDate()) return 1;
        if (moment(a, "HH:mm").toDate() < moment(b, "HH:mm").toDate())
          return -1;
        return 0;
      })
      .map(t => {
        return (
          <div key={t} className="row">
            <div className="col-sm-4">
              {moment(t, "HH:mm").format("hh:mm a")}
            </div>
            <div
              className="col-sm-4"
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.disableConsultationTime(
                  moment(t, "hh:mm a").format("HH:mm"),
                  false
                )
              }
            >
              X
            </div>
          </div>
        );
      });
  };

  disableTime = async (time, blocked) => {
    const { showSpinner, stopSpinner, showMessage, removeMessage } = this.props;
    try {
      const { date } = this.state;
      let _id = this.props.match.params.marketId;
      showSpinner();
      let body = {
        time,
        blocked
      };

      await axios.put(
        `${process.env.REACT_APP_API_URI}/admin/updateTimes?date=${moment(
          date
        ).format("YYYY-MM-DD")}&market=${_id}`,
        body,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken")
          }
        }
      );

      await this.getTimes();

      stopSpinner();
    } catch (e) {
      stopSpinner();
      showMessage({
        message: "Error editing time",
        messageType: "error"
      });
      setTimeout(removeMessage, 3000);
    }
  };

  disableConsultationTime = async (time, blocked) => {
    const { showSpinner, stopSpinner, showMessage, removeMessage } = this.props;
    try {
      const date = this.state.consultationDate;
      let _id = this.props.match.params.marketId;
      showSpinner();
      let body = {
        consultation: true,
        time,
        blocked
      };

      await axios.put(
        `${
          process.env.REACT_APP_API_URI
        }/admin/updateConsultationTimes?date=${moment(date).format(
          "YYYY-MM-DD"
        )}&market=${_id}`,
        body,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken")
          }
        }
      );

      await this.getConsultationTimes();

      stopSpinner();
    } catch (e) {
      stopSpinner();
      showMessage({
        message: "Error editing time",
        messageType: "error"
      });
      setTimeout(removeMessage, 3000);
    }
  };

  render() {
    const {
      city,
      latitude,
      longitude,
      state,
      time_zone,
      start_date,
      phone_number,
      times
    } = this.state.market;

    return (
      <div>
        <CardComponent title="Edit Market">
          {this.state.market._id ? (
            <MarketForm
              saveMarket={this.saveMarket}
              city={city}
              state={state}
              latitude={latitude}
              longitude={longitude}
              start_date={start_date}
              time_zone={time_zone}
              phone_number={phone_number}
            />
          ) : null}
        </CardComponent>
        <CardComponent title="Zip Codes">
          <div className="row zip-actions">
            <div className="col-sm-1">
              <button
                className="btn btn-primary addButton"
                onClick={this.createAddModal}
              >
                Add Zip Code
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="zip-card">
                <h2 className="text-center">Active Zip Codes</h2>
                <div className="zip-data">{this.renderZipCodes(true)}</div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="zip-card">
                <h2 className="text-center">Wait List</h2>
                <div className="zip-data">{this.renderZipCodes(false)}</div>
              </div>
            </div>
          </div>
        </CardComponent>

        <CardComponent title="Disable/Enable Time Slots">
          <div className="row" style={{ minHeight: "400px" }}>
            <div className="col-lg-6">
              <div className="col-xs-12">
                <h2>Regular Visits</h2>
              </div>
              <div className="col-sm-4">
                <h3>Select Date</h3>
                <DatePicker
                  defaultValue={this.state.date}
                  time={false}
                  onChange={e =>
                    this.setState({ date: moment(e).toDate() }, () =>
                      this.getTimes()
                    )
                  }
                />
              </div>
              <div className="col-sm-4">
                <h3>Select Time Window:</h3>
                <DropdownList
                  className="search-select"
                  data={this.state.availableTimes
                    .filter(t => {
                      let find = this.state.disabled_times.find(ti => ti == t);
                      return !find;
                    })
                    .map(t => {
                      return moment(t, "HH:mm").format("hh:mm a");
                    })}
                  onChange={v =>
                    this.disableTime(moment(v, "hh:mm a").format("HH:mm"), true)
                  }
                />
              </div>
              <div className="col-sm-4">
                <h3>Disabled Times</h3>
                {this.renderTimes()}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="col-xs-12">
                <h2>Consultations</h2>
              </div>
              <div className="col-sm-4">
                <h3>Select Date</h3>
                <DatePicker
                  defaultValue={this.state.date}
                  time={false}
                  onChange={e =>
                    this.setState(
                      { consultationDate: moment(e).toDate() },
                      () => this.getConsultationTimes()
                    )
                  }
                />
              </div>
              <div className="col-sm-4">
                <h3>Select Time Window:</h3>
                <DropdownList
                  className="search-select"
                  data={this.state.availableConsultationTimes
                    .filter(t => {
                      let find = this.state.disabled_consultation_times.find(
                        ti => ti == t
                      );
                      return !find;
                    })
                    .map(t => {
                      return moment(t, "HH:mm").format("hh:mm a");
                    })}
                  onChange={v =>
                    this.disableConsultationTime(
                      moment(v, "hh:mm a").format("HH:mm"),
                      true
                    )
                  }
                />
              </div>
              <div className="col-sm-4">
                <h3>Disabled Times</h3>
                {this.renderConsultationTimes()}
              </div>
            </div>
          </div>
        </CardComponent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showSpinner: () => dispatch({ type: "FETCH_REQUEST" }),
  stopSpinner: () => dispatch({ type: "FETCH_SUCCESS" }),
  showMessage: msg => dispatch({ type: "ADD_MESSAGE", payload: msg }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" }),
  modalActions: {
    push: component => dispatch(pushModal(component)),
    pop: () => dispatch(popModal())
  }
});

export default connect(
  null,
  mapDispatchToProps
)(EditMarket);
