// appointments
export const ADMIN_GET_APPOINTMENT_LIST_SUCCESS =
  "ADMIN_GET_APPOINTMENT_LIST_SUCCESS";
export const ADMIN_GET_APPOINTMENT_DETAIL_SUCCESS =
  "ADMIN_GET_APPOINTMENT_DETAIL_SUCCESS";
export const GET_CLIENT_SUCCESS = "GET_CLIENT_SUCCESS";
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const GET_ALL_CLIENTS_SUCCESS = "GET_ALL_CLIENTS_SUCCESS";
export const GET_ALL_FACILITIES_SUCCESS = "GET_ALL_FACILITIES_SUCCESS";
export const ADD_PET_TO_CLIENT_SUCCESS = "ADD_PET_TO_CLIENT_SUCCESS";
export const CREATE_STAFF_SUCCESS = "CREATE_STAFF_SUCCESS";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_STAFF_MEMBER_SUCCESS = "UPDATE_STAFF_SUCCESS";
export const GET_ALL_STAFF_SUCCESS = "GET_ALL_STAFF_SUCCESS";
export const GET_STAFF_MEMBER_SUCCESS = "GET_STAFF_MEMBER_SUCCESS";
export const GET_PET_SUCCESS = "GET_PET_SUCCESS";
export const UPDATE_PET_SUCCESS = "UPDATE_PET_SUCCESS";
