import React, { Component } from "react";
import axios from "../../services/network.js";
import { connect } from "react-redux";
import moment from "moment";
import {
  ApartmentForm,
  CardComponent,
  ClientApartmentRowItem,
  PartnerPromoTable,
  CancelConciergeModal
} from "../../components";
import { pushModal, popModal } from "../../actions/general.actions";
import _ from "lodash";
import "query-string";

class EditApartment extends Component {
  state = {
    name: "",
    playcare: false,
    market_id: "",
    pet_perks: false,
    percentage: 0,
    start_date: moment().toDate(),
    concierge: {
      days: [],
      enabled: false,
      daily_visits: 0,
      window_start_time: "10:00",
      window_end_time: "14:00"
    },
    clients: [],
    contact: {}
  };

  async componentWillMount() {
    this.getApartment();
  }

  getApartment = async () => {
    const { showSpinner, stopSpinner, showMessage, removeMessage } = this.props;
    showSpinner();
    try {
      let { data } = await axios.get(
        `${process.env.REACT_APP_API_URI}/admin/apartments/${this.props.match.params.apartmentId}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken")
          }
        }
      );
      if (data.playcare && data.playcare.enabled && data.playcare.staff)
        data.playcare.staff = data.playcare.staff.map(s => ({
          text: s.full_name,
          value: s._id
        }));
      this.setState({
        name: data.name,
        playcare: data.playcare,
        market_id: data.market_id,
        concierge: data.concierge,
        clients: data.clients,
        partner: data.partner,
        promos: data.promos,
        referrals: data.referrals,
        pet_perks: data.pet_perks ? data.pet_perks : false,
        percentage: data.percentage ? data.percentage : 0,
        start_date: data.start_date ? data.start_date : moment().toDate(),
        original_playcare: _.cloneDeep(data.playcare),
        contact: data.contact
      });
    } catch (e) {
      showMessage({
        message: "Error fetching apartment",
        messageType: "error"
      });
      setTimeout(removeMessage, 3000);
    }
    stopSpinner();
  };

  saveApartment = async apt => {
    const { showSpinner, stopSpinner, showMessage, removeMessage } = this.props;
    try {
      // if (apt && apt.playcare && apt.days_changed) {
      //   console.log('sdfasdfsafasfasfdasdf8888888')
      //   let needsAlert = false;
      //   this.state.playcare.days.forEach(d => {
      //     let find = apt.playcare.days.find(w => w === d)
      //     if (!find) needsAlert = true;
      //   })
      //   if (needsAlert) {
      //
      //   }
      // }

      if (apt && apt.concierge) {
        if (
          apt.concierge.enabled &&
          (!apt.concierge.days || !apt.concierge.days.length)
        ) {
          showMessage({
            message: "Can't enable concierge without any weekdays",
            messageType: "error"
          });
          setTimeout(removeMessage, 3000);
          return;
        }
      }

      if (apt && apt.playcare && apt.playcare.enabled) {
        if (!apt.playcare.staff || !apt.playcare.staff.length) {
          showMessage({
            message: "Can't enable playcare with no assigned staff!",
            messageType: "error"
          });
          setTimeout(removeMessage, 3000);
          return;
        }
        apt.playcare.staff = apt.playcare.staff.map(s => ({
          full_name: s.text,
          _id: s.value
        }));
      }
      if (apt.pet_perks) {
        if (!apt.percentage) {
          showMessage({
            message: "Please add a discount percentage.",
            messageType: "error"
          });
          setTimeout(removeMessage, 3000);
          return;
        }

        if (!apt.start_date) {
          showMessage({
            message: "Please add a start date for pet perks.",
            messageType: "error"
          });
          setTimeout(removeMessage, 3000);
          return;
        }
      }
      showSpinner();
      let res;
      if (apt && apt.name) {
        const { market_id } = this.state;
        res = await axios.put(
          `${process.env.REACT_APP_API_URI}/admin/apartments/${this.props.match.params.apartmentId}`,
          {
            name: apt.name,
            playcare: apt.playcare,
            market_id: market_id,
            concierge: apt.concierge,
            pet_perks: apt.pet_perks ? true : false,
            percentage: apt.percentage,
            start_date: apt.start_date,
            expiration_date: moment()
              .add(10, "years")
              .toDate(),
            contact: apt.contact
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("accessToken")
            }
          }
        );
      }
      if (res && res.status === 200) {
        showMessage({
          message: "Successfully updated apartment",
          messageType: "success"
        });
        this.getApartment();
        setTimeout(removeMessage, 3000);
      } else {
        showMessage({
          message: "Failed to update the apartment",
          messageType: "error"
        });
        setTimeout(removeMessage, 3000);
      }
      stopSpinner();
    } catch (e) {
      showMessage({
        message: "Failed to update the apartment",
        messageType: "error"
      });
      setTimeout(removeMessage, 3000);
    }
  };

  renderClients = () => {
    return (
      <div className="user-list-container">
        <div className="user-list-item user-list-header">
          <div className="user-list-item-content title col-lg-2 col-md-2 col-sm-2 col-xs-8">
            Name
          </div>
          <div className="user-list-item-content title col-lg-2 col-md-2 col-sm-2 col-xs-2">
            Pets
          </div>
          <div className="user-list-item-content title col-lg-1 col-md-1 col-sm-1 col-xs-1">
            Zip Code
          </div>
          <div className="user-list-item-content title col-lg-1 col-md-1 col-sm-1 col-xs-1">
            Signup
          </div>
          <div className="user-list-item-content title col-lg-2 col-md-2 col-sm-2 col-xs-2">
            Promo
          </div>
          <div className="user-list-item-content title col-lg-2 col-md-2 col-sm-2 hidden-xs">
            Consultation
          </div>
          <div className="user-list-item-content title col-lg-1 col-md-1 col-sm-1 hidden-xs">
            Spend
          </div>
          <div className="user-list-item-content title col-lg-1 col-md-1 col-sm-1 hidden-xs">
            Appointments
          </div>
        </div>

        {this.state.clients.length
          ? this.state.clients.map((client, index) => (
              <ClientApartmentRowItem
                key={`client-list-item-id-${client._id}`}
                client={client}
              />
            ))
          : null}
      </div>
    );
  };

  removeFutureConcierge = async () => {
    const {
      showSpinner,
      stopSpinner,
      showMessage,
      removeMessage,
      modalActions
    } = this.props;
    const { apartmentId } = this.props.match.params;
    showSpinner();
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URI}/admin/apartments/${apartmentId}/cancelConcierge`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      }
    );
    if (data.error) {
      showMessage({
        messageType: "error",
        message: "Error canceling future concierge visits"
      });
    } else {
      showMessage({
        messageType: "success",
        message: "Successfully canceled future concierge visits"
      });
    }
    setTimeout(removeMessage, 3000);
    modalActions.pop();
    stopSpinner();
  };

  getFutureConciergeCount = async () => {
    const { apartmentId } = this.props.match.params;
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URI}/admin/apartments/${apartmentId}/concierge`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      }
    );

    return new Promise((yea, no) => {
      yea(data.length);
    });
  };

  showConciergeModal = async () => {
    const count = await this.getFutureConciergeCount();
    const jsx = (
      <CancelConciergeModal
        name={this.state.name}
        count={count}
        cancelConcierge={this.removeFutureConcierge}
        modalActions={this.props.modalActions}
      />
    );
    this.props.modalActions.push(jsx);
  };

  render() {
    const {
      name,
      playcare,
      concierge,
      market_id,
      pet_perks,
      start_date,
      percentage,
      contact
    } = this.state;
    const { apartmentId } = this.props.match.params;
    return (
      <div>
        <CardComponent title="Edit Apartment">
          <ApartmentForm
            saveApartment={this.saveApartment}
            id={apartmentId}
            name={name}
            playcare={playcare}
            concierge={concierge}
            market_id={market_id}
            pet_perks={pet_perks}
            start_date={start_date}
            percentage={percentage}
            showConciergeModal={this.showConciergeModal}
            contact={contact}
          />
        </CardComponent>
        <CardComponent title="Clients at Apartment">
          {this.renderClients()}
        </CardComponent>
        <CardComponent title="Promos">
          <PartnerPromoTable
            promos={this.state.promos}
            referrals={this.state.referrals}
            partner={this.state.partner}
          />
        </CardComponent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showSpinner: () => dispatch({ type: "FETCH_REQUEST" }),
  stopSpinner: () => dispatch({ type: "FETCH_SUCCESS" }),
  showMessage: msg => dispatch({ type: "ADD_MESSAGE", payload: msg }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" }),
  modalActions: {
    push: component => dispatch(pushModal(component)),
    pop: () => dispatch(popModal())
  }
});

export default connect(
  null,
  mapDispatchToProps
)(EditApartment);
