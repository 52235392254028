import React, { Component } from "react";
import Multiselect from "react-widgets/Multiselect";
import {
  applyMultiFilters,
  getFilterOptions
} from "../../../../helpers/schedulerFilter";
import { PetCard } from "../../../../components";

const filterDimensions = [
  "Appointment Status",
  "Client",
  "Zone",
  "Pet",
  "Pet Class",
  "Home Class",
  "Recurrence Type"
];
const breakpoint = 1030;

export default class UnassignedAppointmentsCol extends Component {

  state = {
    adjustSidebarPosition: false,
    petCardContainerHeight: null,
    top: 154
  };

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
    let adjustSidebarPosition = false;
    if (window.innerWidth > breakpoint) {
      adjustSidebarPosition = true;
    }
    this.setState({ adjustSidebarPosition });
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  };

  handleResize = () => {
    const windowWidth = window.innerWidth;
    this.setState(prevState => {
      let adjustSidebarPosition = prevState.adjustSidebarPosition;
      if (!adjustSidebarPosition && windowWidth > breakpoint) {
        adjustSidebarPosition = true;
      } else if (adjustSidebarPosition && windowWidth <= breakpoint) {
        adjustSidebarPosition = false;
      }
      return {
        adjustSidebarPosition,
        petCardContainerHeight: this.wrapper.clientHeight
      };
    });
  };

  handleScroll = () => {
    const value = document.body.scrollTop;
    if (value > 154) {
      this.setState({
        top: 10,
        petCardContainerHeight: this.wrapper.clientHeight
      });
    } else {
      this.setState({
        top: 154,
        petCardContainerHeight: this.wrapper.clientHeight
      });
    }
  };

  render = () => {
    const { adjustSidebarPosition, petCardContainerHeight, top } = this.state;
    const {
      appointments,
      handleSelectAppointment,
      onClickAppointmentDetail,
      selectedAppointment,
      setUnassignedFilters,
      transitionalAppointment,
      unassignedFilters
    } = this.props;

    // set up styles based on breakpoint above
    let unassignedAppointmentsStyle = {};
    let petCardContainerStyle = {};
    if (adjustSidebarPosition) {
      unassignedAppointmentsStyle = { top, bottom: 40, overflow: "visible" };
      petCardContainerStyle = {
        overflow: "scroll",
        height: petCardContainerHeight - 115
      };
    }

    // Filter out assigned and transitional appointments
    const unassignedAppointments = appointments
      .filter(appt => !(appt.sitter && appt.scheduled_start_time)) // filter out assigned
      .filter(appt => {
        // filter out transitional appts
        if (transitionalAppointment) {
          return appt.id !== transitionalAppointment.id;
        }
        return appt;
      });

    // Run unassignedAppointments through multifilter and use for rendering pet cards
    const filteredAppointments = applyMultiFilters(
      unassignedAppointments,
      unassignedFilters
    );

    return (
      <div
        className="unassigned-appointments"
        ref={el => (this.wrapper = el)}
        style={unassignedAppointmentsStyle}
      >
        <div className="unassigned-filter">
          <div className="form-group input-field">
            <Multiselect
              data={getFilterOptions(unassignedAppointments, filterDimensions)}
              defaultValue={unassignedFilters}
              valueField="value"
              textField="text"
              groupBy={item => item.data.type}
              filter="contains"
              onChange={setUnassignedFilters}
              placeholder="Filter by anything..."
            />
            <div className="search-icon">
              <span className="fa fa-search" />
            </div>
          </div>
        </div>

        {/* <h1>Unassigned Appointments</h1> */}

        <div className="pet-card-container" style={petCardContainerStyle}>
          {filteredAppointments
            .sort((a, b) => {
              const startTimeA = a.scheduled_start_time || a.window_start_time;
              const startTimeB = b.scheduled_start_time || b.window_start_time;
              return startTimeA > startTimeB;
            })
            .map(appt => (
              <PetCard
                key={`unassigned-appt-id-${appt.id}`}
                appointment={appt}
                handleSelectAppointment={handleSelectAppointment}
                onClickAppointmentDetail={onClickAppointmentDetail}
                selected={
                  selectedAppointment && appt.id === selectedAppointment.id
                }
                showDate
              />
            ))}
        </div>
      </div>
    );
  };
}
