import React, { Component } from "react";

class MarketRowItem extends Component {
  render() {
    return (
      <div className="row market-row">
        <div className="col-sm-4">
          {`${this.props.city}, ${this.props.state}`}
        </div>
        <div className="col-sm-4">{this.props.time_zone}</div>
        <div className="col-sm-4">
          {this.props.start_date &&
          this.props.start_date.getTime() > new Date().getTime() ? (
            <div className="launch-date">
              Launch Date:
              <span>
                {`${this.props.start_date.getMonth() +
                  1}/${this.props.start_date.getDate()}/${this.props.start_date.getFullYear()}`}
              </span>
            </div>
          ) : (
            <div>Live</div>
          )}
        </div>
      </div>
    );
  }
}

export default MarketRowItem;
