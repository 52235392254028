import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ZoneBadge } from "../../";
import Tooltip from "rc-tooltip";
import moment from "moment";

export default class ClientApartmentRowItem extends Component {

  getPets = pets => {
    if (!pets || !pets.length) return "N/A";
    let filtered = pets
      .filter(p => p && p._id)
      .map(p => p.name)
      .join(", ");
    return filtered;
  };

  render() {
    const { client } = this.props;
    return (
      <Link to={`/client/${client._id}`}>
        <div className="user-list-item">
          <div className="user-list-item-content username-container col-lg-2 col-md-2 col-sm-2 col-xs-8">
            <div className="user-list-name">
              <Tooltip
                placement="top"
                overlay={<div>Active</div>}
                mouseEnterDelay={0.2}
                mouseLeaveDelay={0.1}
                overlayClassName="annotation-tooltip"
              >
                <div className="flags-user-active-green"></div>
              </Tooltip>
              {client.full_name ? client.full_name : "No Name Here"}
            </div>
          </div>
          <div className="user-list-item-content col-lg-2 col-md-2 col-sm-2 col-xs-2">
            <div>{this.getPets(client.pets)}</div>
          </div>
          <div className="user-list-item-content col-lg-1 col-md-1 col-sm-1 hidden-xs">
            <div>{client.contact.address.zip_code}</div>
          </div>
          <div className="user-list-item-content col-lg-1 col-md-1 col-sm-1 hidden-xs">
            <div>{moment(client.signup_date).format("MM/DD/YYYY")}</div>
          </div>
          <div className="user-list-item-content col-lg-2 col-md-2 col-sm-2 hidden-xs">
            <div>{client.promo_code || "none"}</div>
          </div>
          <div className="user-list-item-content col-lg-2 col-md-2 col-sm-2 hidden-xs">
            <div>
              {client.consultation_status
                ? client.consultation_status
                : "unscheduled"}
            </div>
          </div>
          <div className="user-list-item-content col-lg-1 col-md-1 col-sm-1 hidden-xs">
            <div>{`$${client.history ? client.history.toFixed(2) : 0}`}</div>
          </div>
          <div className="user-list-item-content col-lg-1 col-md-1 col-sm-1 hidden-xs">
            <div>{`${client.appointment_count}`}</div>
          </div>
        </div>
      </Link>
    );
  }
}
