import React, { Component } from "react";
import moment from "moment-timezone";

const messages = (requested, updated) => ({
  client: {
    pending: `We are currently processing your appointment request.`,
    approved: `Your appointment request has been accepted!`,
    "change-requested": `We're currently reviewing your requested changes.`,
    completed: `This appointment has been completed.`,
    "in-progress": `This appointment is currently in progress.`,
    rejected: `We're sorry! We were unable to accommodate your appointment. Please contact us if we haven't already reached out to you to make other arrangements.`,
    canceled: `This appointment has been canceled.`
  },
  admin: {
    pending: `This appointment is Pending. It was requested on ${requested}.`,
    approved: `This appointment is Approved. Last modified on ${updated}.`,
    "change-requested": `The client has requested changes. Last modified on ${updated}.`,
    completed: `This appointment has been completed.`,
    "in-progress": `This appointment is currently In Progress.`,
    rejected: `This appointment has been Rejected. Last modified on ${updated}.`,
    canceled: `This appointment has been Canceled. Last modified on ${updated}.`
  }
});

export default class AppointmentStatusBanner extends Component {

  render() {
    const { dateCreated, dateUpdated, status } = this.props;
    const userType = "admin";
    const colorClass = status.replace(" ", "-").toLowerCase();
    const messageArgs = {
      requested: moment(dateCreated).format("dddd, MMM D, YYYY [at] H:mm A"),
      updated: moment(dateUpdated).format("dddd, MMM D, YYYY [at] H:mm A")
    };

    return (
      <div className={`text-center appt-status ${colorClass}`}>
        <p>
          {
            messages(messageArgs.requested, messageArgs.updated)[userType][
              colorClass
            ]
          }
        </p>
      </div>
    );
  }
}
