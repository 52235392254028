import React, { Component } from "react";
import history from "../../actions/history";
import { connect } from "react-redux";
import { createPetService } from "../../services";
import {
  PetSelector,
  PetForm,
  PageTitleComponent,
  Addons,
  Areas,
  Boarding,
  Daycare,
  Grooming,
  Holidays,
  BasicInfo,
  Appointments,
  Kennels,
  Calendar,
  Playgroups
} from "../../components";
import { catchFormServerErrors } from "../../helpers";
import axios from "../../services/network.js";
import moment from "moment";

// Actions
import { addPet } from "../../actions/admin/clients.actions";
import {
  showFormErrorMessage,
  showSuccessMessage
} from "../../actions/general.actions";

const onlyNumbers = value => {
  if (!value || value === "0") return value;
  const onlyNums = value.replace(/[^\d]/g, "").replace(/^0+/, "");
  if (onlyNums.length) return onlyNums;
  return 0;
};

class Facility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apartments: [],
      staff: [],
      facility: {
        areas: [],
        kennels: [],
        kennel_types: [],
        holidays: [],
        contact: {
          phone_number: "",
          address: {
            address_line_1: "",
            address_line_2: "",
            city: "",
            state: "",
            zip_code: "",
            override_geocode: false,
            latitude: "",
            longitude: ""
          }
        },
        add_on_services: [],
        capacity: {
          day: 0,
          night: 0
        },
        public_hours: {
          weekend: { open: "", close: "" },
          weekday: { open: "", close: "" }
        },
        staffed_hours: {
          weekend: { open: "", close: "" },
          weekday: { open: "", close: "" }
        },
        report_times: {
          morning: "10:30",
          midday: "15:00",
          evening: "21:00",
          night: "06:00"
        },
        services: {
          daycare: {
            days_of_week: [],
            prices: {
              1: 0,
              5: 0,
              20: 0,
              50: 0
            },
            holiday_surcharge: 1000,
            late_pickup_fee: 1000,
            bundles_enabled: false
          },
          boarding: {
            days_of_week: [],
            prices: {
              1: 0,
              5: 0,
              20: 0,
              50: 0
            },
            holiday_surcharge: 1000,
            late_pickup_fee: 1000,
            late_cancellation_fee: 1000,
            bundles_enabled: false
          },
          grooming: {},
          baths: {
            days_of_week: []
          }
        }
      },
      tab: 4,
      playgroups: []
    };
  }

  componentWillMount() {
    this.loadFacility();
  }

  loadFacility = async () => {
    try {
      this.props.showSpinner();
      let { data } = await axios.get(
        `${process.env.REACT_APP_API_URI}/admin/facilities/${this.props.match.params.facilityId}`
      );
      this.setState({
        facility: data.facility,
        apartments: data.apartments,
        playgroups: data.playgroups,
        staff: data.staff
      });
      this.props.stopSpinner();
    } catch (e) {
      this.props.showMessage({ messageType: "error", message: "Oh no" });
      setTimeout(this.props.removeMessage, 3000);
      this.props.stopSpinner();
    }
  };

  updateBasicInfo = (key, val, save) => {
    this.setState(
      {
        ...this.state,
        facility: {
          ...this.state.facility,
          [key]: val
        }
      },
      () => {
        if (save) {
          this.saveFacility();
        }
      }
    );
  };

  updateContact = (key, val) => {
    this.setState({
      ...this.state,
      facility: {
        ...this.state.facility,
        contact: {
          ...this.state.facility.contact,
          [key]: val
        }
      }
    });
  };

  updateAddress = (key, val) => {
    this.setState({
      ...this.state,
      facility: {
        ...this.state.facility,
        contact: {
          ...this.state.facility.contact,
          address: {
            ...this.state.facility.contact.address,
            [key]: val
          }
        }
      }
    });
  };

  updatePublicHours = (key, weekend, val) => {
    let hours = { ...this.state.facility.public_hours };
    hours[weekend] = { ...hours[weekend], [key]: val };
    this.setState({
      ...this.state,
      facility: {
        ...this.state.facility,
        public_hours: hours
      }
    });
  };

  updateStaffedHours = (key, weekend, val) => {
    let hours = { ...this.state.facility.staffed_hours };
    hours[weekend] = { ...hours[weekend], [key]: val };

    this.setState({
      ...this.state,
      facility: {
        ...this.state.facility,
        staffed_hours: hours
      }
    });
  };

  getTimes = () => {
    let breakTimes = [];

    for (
      var m = moment()
        .hour(7)
        .minute(0);
      m.isBefore(
        moment()
          .hour(22)
          .minute(0)
      );
      m.add(1, "hour")
    ) {
      breakTimes.push(m.format("h:mm a"));
    }
    return breakTimes;
  };

  getTimesHalf = () => {
    let breakTimes = [];

    for (
      var m = moment()
        .hour(7)
        .minute(0);
      m.isBefore(
        moment()
          .hour(22)
          .minute(0)
      );
      m.add(30, "minutes")
    ) {
      breakTimes.push(m.format("h:mm a"));
    }
    return breakTimes;
  };

  updateCapacity = (key, val) => {
    this.setState({
      ...this.state,
      facility: {
        ...this.state.facility,
        capacity: {
          ...this.state.facility.capacity,
          [key]: val
        }
      }
    });
  };

  saveFacility = async () => {
    try {
      this.props.showSpinner();
      let { data } = await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/facilities/create`,
        this.state.facility
      );

      this.props.stopSpinner();
      this.props.showMessage({
        messageType: "success",
        message: "Facility Updated"
      });
      setTimeout(this.props.removeMessage, 3000);

      this.setState({
        facility: {
          ...data,
          contact: {
            ...data.contact,
            address: {
              ...data.contact.address,
              longitude: data.contact.address.loc
                ? data.contact.address.loc.coordinates[0]
                : 0,
              latitude: data.contact.address.loc
                ? data.contact.address.loc.coordinates[1]
                : 0
            }
          }
        }
      });
    } catch (e) {
      this.props.showMessage({ messageType: "error", message: "Oh no" });
      setTimeout(this.props.removeMessage, 3000);
      this.props.stopSpinner();
    }
  };

  addKennel = async kennel => {
    try {
      this.props.showSpinner();
      let { data } = await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/facilities/kennels`,
        kennel
      );
      await this.loadFacility();
      this.props.stopSpinner();
      this.props.showMessage({
        messageType: "success",
        message: "Facility Kennels Updated"
      });
      setTimeout(this.props.removeMessage, 3000);
    } catch (e) {
      this.props.showMessage({ messageType: "error", message: "Oh no" });
      setTimeout(this.props.removeMessage, 3000);
      this.props.stopSpinner();
    }
  };

  addArea = async area => {
    this.props.showSpinner();
    area.facility_id = this.state.facility._id;
    if (!area._id || area._id === "") delete area._id;
    let { data } = await axios.post(
      `${process.env.REACT_APP_API_URI}/admin/facilities/areas`,
      area
    );
    let areas = [...this.state.facility.areas];
    let findIndex = areas.findIndex(f => f._id == area._id);
    if (findIndex > -1) {
      areas.splice(findIndex, 1, area);
    } else {
      areas.push(area);
    }
    this.setState({
      ...this.state,
      facility: {
        ...this.state.facility,
        areas
      }
    });
    this.props.stopSpinner();
    this.props.showMessage({
      messageType: "success",
      message: "Facility Updated"
    });
    setTimeout(this.props.removeMessage, 3000);
  };

  updateBoarding = (key, val) => {
    this.setState({
      ...this.state,
      facility: {
        ...this.state.facility,
        services: {
          ...this.state.facility.services,
          boarding: {
            ...this.state.facility.services.boarding,
            [key]: val
          }
        }
      }
    });
  };

  updateDaycare = (key, val) => {
    this.setState({
      ...this.state,
      facility: {
        ...this.state.facility,
        services: {
          ...this.state.facility.services,
          daycare: {
            ...this.state.facility.services.daycare,
            [key]: val
          }
        }
      }
    });
  };

  updateDaycareCharges = (key, val) => {
    let newVal = onlyNumbers(val);

    this.setState({
      ...this.state,
      facility: {
        ...this.state.facility,
        services: {
          ...this.state.facility.services,
          daycare: {
            ...this.state.facility.services.daycare,
            [key]: newVal
          }
        }
      }
    });
  };

  updateBoardingCharges = (key, val) => {
    let newVal = onlyNumbers(val);

    this.setState({
      ...this.state,
      facility: {
        ...this.state.facility,
        services: {
          ...this.state.facility.services,
          boarding: {
            ...this.state.facility.services.boarding,
            [key]: newVal
          }
        }
      }
    });
  };

  updateBoardingPrices = val => {
    let newVal = onlyNumbers(val);

    this.setState({
      ...this.state,
      facility: {
        ...this.state.facility,
        services: {
          ...this.state.facility.services,
          boarding: {
            ...this.state.facility.services.boarding,

            price_per_night: newVal
          }
        }
      }
    });
  };

  updateDaycarePrices = (key, val) => {
    let newVal = onlyNumbers(val);

    this.setState({
      ...this.state,
      facility: {
        ...this.state.facility,
        services: {
          ...this.state.facility.services,
          daycare: {
            ...this.state.facility.services.daycare,
            prices: {
              ...this.state.facility.services.daycare.prices,
              [key]: newVal
            }
          }
        }
      }
    });
  };

  saveAddOn = async addOn => {
    try {
      this.props.showSpinner();
      let ourAddOn = {
        facility_id: this.state.facility._id,
        ...addOn
      };
      let { data } = await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/facilities/addOns`,
        ourAddOn
      );
      this.loadFacility();
      this.props.showMessage({
        messageType: "success",
        message: "Facility Updated"
      });
      this.props.stopSpinner();
      setTimeout(this.props.removeMessage, 3000);
    } catch (e) {
      this.props.showMessage({ messageType: "error", message: "Oh no" });
      setTimeout(this.props.removeMessage, 3000);
      this.props.stopSpinner();
    }
  };

  saveHoliday = async holiday => {
    try {
      this.props.showSpinner();
      let ourHoliday = {
        facility_id: this.state.facility._id,
        ...holiday
      };
      let { data } = await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/facilities/holidays`,
        ourHoliday
      );
      this.loadFacility();
      this.props.showMessage({
        messageType: "success",
        message: "Facility Updated"
      });
      this.props.stopSpinner();
      setTimeout(this.props.removeMessage, 3000);
    } catch (e) {
      this.props.showMessage({ messageType: "error", message: "Oh no" });
      setTimeout(this.props.removeMessage, 3000);
      this.props.stopSpinner();
    }
  };

  savePlaygroup = async playgroup => {
    if (!playgroup) return null;
    const { showSpinner, stopSpinner, showMessage, removeMessage } = this.props;
    showSpinner();
    try {
      playgroup.facility_id = this.props.match.params.facilityId;
      const newGroups = [...this.state.playgroups];
      const { data } = await axios.post(
        `/admin/facilities/playgroups`,
        playgroup
      );

      if (!playgroup._id) {
        newGroups.push(data);
      }
      this.setState({ playgroups: newGroups });
      showMessage({
        message: "Successfully created playgroup",
        messageType: "success"
      });
    } catch (e) {
      console.log("bad e", e);
      if (e.data && e.data.error) {
        showMessage({
          message: e.data.error,
          messageType: "error"
        });
      } else {
        showMessage({
          message: "Unable to save playgroup",
          messageType: "error"
        });
      }
    }
    setTimeout(removeMessage, 3000);
    stopSpinner();
  };

  deletePlaygroup = async playgroup => {
    if (!playgroup) return null;
    const { showSpinner, stopSpinner, showMessage, removeMessage } = this.props;
    showSpinner();
    try {
      let groups = [...this.state.playgroups];
      groups = groups.filter(p => p._id !== playgroup._id);
      const { data } = await axios.put(
        "/admin/facilities/playgroups",
        playgroup
      );
      this.setState({ playgroups: groups });
      showMessage({
        message: "Successfully removed the playgroup",
        messageType: "success"
      });
    } catch (e) {
      if (e.data && e.data.error) {
        showMessage({
          message: e.data.error,
          messageType: "error"
        });
      } else {
        showMessage({
          message: "Unable to remove the playgroup at this time",
          messageType: "error"
        });
      }
    }
    setTimeout(removeMessage, 3000);
    stopSpinner();
  };

  render() {
    let { tab, facility, playgroups, staff } = this.state;

    return (
      <div className="card facility">
        <div>
          <h3>{facility.name}</h3>
          {tab !== 6 ? (
            <button
              style={{ float: "right" }}
              className="btn btn-secondary"
              onClick={this.saveFacility}
            >
              Save
            </button>
          ) : null}
        </div>
        <div className="apartmentTabs">
          <div
            onClick={() => this.setState({ tab: 1 })}
            className={`apartmentTab${tab === 1 ? " selectedTab" : ""}`}
          >
            Basic Info
          </div>
          <div
            onClick={() => this.setState({ tab: 2 })}
            className={`apartmentTab${tab === 2 ? " selectedTab" : ""}`}
          >
            Calendar
          </div>
          <div
            onClick={() => this.setState({ tab: 3 })}
            className={`apartmentTab${tab === 3 ? " selectedTab" : ""}`}
          >
            Appointments
          </div>
          <div
            onClick={() => this.setState({ tab: 4 })}
            className={`apartmentTab${tab === 4 ? " selectedTab" : ""}`}
          >
            Kennels
          </div>
          <div
            onClick={() => this.setState({ tab: 5 })}
            className={`apartmentTab${tab === 5 ? " selectedTab" : ""}`}
          >
            Areas
          </div>
          <div
            onClick={() => this.setState({ tab: 6 })}
            className={`apartmentTab${tab === 6 ? " selectedTab" : ""}`}
          >
            Add Ons
          </div>
          <div
            onClick={() => this.setState({ tab: 7 })}
            className={`apartmentTab${tab === 7 ? " selectedTab" : ""}`}
          >
            Boarding
          </div>
          <div
            onClick={() => this.setState({ tab: 8 })}
            className={`apartmentTab${tab === 8 ? " selectedTab" : ""}`}
          >
            Daycare
          </div>
          {/*<div onClick={() => this.setState({ tab: 9 })} className={`apartmentTab${tab === 9 ? ' selectedTab' : ''}`}>
            Grooming
          </div>*/}
          <div
            onClick={() => this.setState({ tab: 10 })}
            className={`apartmentTab${tab === 10 ? " selectedTab" : ""}`}
          >
            Holidays
          </div>
          <div
            onClick={() => this.setState({ tab: 11 })}
            className={`apartmentTab${tab === 11 ? " selectedTab" : ""}`}
          >
            Playgroups
          </div>
        </div>
        <div className="selectedRes">
          {tab === 1 ? (
            <BasicInfo
              facility={facility}
              updateBasicInfo={this.updateBasicInfo}
              updateContact={this.updateContact}
              updateAddress={this.updateAddress}
              updatePublicHours={this.updatePublicHours}
              updateStaffedHours={this.updateStaffedHours}
              states={this.props.formConstants.states}
              updateCapacity={this.updateCapacity}
              times={this.getTimes()}
              apartments={this.state.apartments}
              markets={this.props.markets}
            />
          ) : null}
          {tab === 2 ? (
            <Calendar
              updateBasicInfo={this.updateBasicInfo}
              facility={facility}
            />
          ) : null}
          {tab === 3 ? <Appointments facility={facility} /> : null}
          {tab === 4 ? (
            <Kennels
              playgroups={playgroups}
              updateBasicInfo={this.updateBasicInfo}
              addKennel={this.addKennel}
              facility={facility}
            />
          ) : null}
          {tab === 5 ? (
            <Areas
              updateBasicInfo={this.updateBasicInfo}
              facility={facility}
              addArea={this.addArea}
            />
          ) : null}
          {tab === 6 ? (
            <Addons
              times={this.getTimes()}
              saveAddOn={this.saveAddOn}
              updateBasicInfo={this.updateBasicInfo}
              facility={facility}
            />
          ) : null}
          {tab === 7 ? (
            <Boarding
              facility={facility}
              updateBoarding={this.updateBoarding}
              updateBoardingPrices={this.updateBoardingPrices}
              updateBoardingCharges={this.updateBoardingCharges}
              times={this.getTimesHalf()}
            />
          ) : null}
          {tab === 8 ? (
            <Daycare
              facility={facility}
              updateDaycare={this.updateDaycare}
              updateDaycarePrices={this.updateDaycarePrices}
              updateDaycareCharges={this.updateDaycareCharges}
              times={this.getTimesHalf()}
              facility={facility}
            />
          ) : null}
          {/*
            tab === 9 ? <Grooming facility={facility}/> : null
          */}
          {tab === 10 ? (
            <Holidays facility={facility} save={this.saveHoliday} />
          ) : null}
          {tab === 11 ? (
            <Playgroups
              facility={facility}
              playgroups={playgroups}
              staff={staff}
              areas={facility.areas}
              savePlaygroup={this.savePlaygroup}
              deletePlaygroup={this.deletePlaygroup}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  formConstants: state.general.formConstants,
  markets: state.admin.general.markets
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: (clientId, formData, addAnother) =>
    dispatch(addPet(clientId, formData, addAnother)),
  showSpinner: () => dispatch({ type: "FETCH_REQUEST" }),
  stopSpinner: () => dispatch({ type: "FETCH_SUCCESS" }),
  showMessage: msg => dispatch({ type: "ADD_MESSAGE", payload: msg }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Facility);
