import React, { Component } from "react";
import { connect } from "react-redux";
import { getAllStaff } from "../../actions/admin/staff.actions";
import { loadConstants } from "../../actions/general.actions";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { MarketRowItem } from "../../components";
import moment from "moment";
import axios from "../../services/network.js";

class Markets extends Component {
  state = {
    markets: []
  };

  async componentWillMount() {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URI}/admin/markets`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      }
    );
    const format = data.map(market => {
      return {
        ...market,
        start_date: market.start_date ? new Date(market.start_date) : null
      };
    });
    this.setState({ markets: format });
  }

  render() {
    return (
      <div className="card p-t-none">
        <div className="row">
          <div className="col-sm-12 marketForm">
            <Link to={`/addmarket`}>
              <h3>Add Market</h3>
            </Link>
          </div>
        </div>

        <div className="market-container">
          {this.state.markets.map((market, i) => {
            return (
              <Link
                to={{
                  pathname: `/market/${market._id}`,
                  state: { market: market }
                }}
                key={i}
              >
                <MarketRowItem
                  city={market.city}
                  state={market.state}
                  time_zone={market.time_zone}
                  start_date={market.start_date}
                  key={market._id}
                  phone_number={market.phone_number}
                />
              </Link>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  markets: state.admin.general.markets,
  market: state.admin.general.location
});

const mapDispatchToProps = dispatch => ({
  loadConstants: () => dispatch(loadConstants())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Markets);
