import React, { Component } from "react";
import { DropdownList, Multiselect } from "react-widgets";
const types = ["Playspace", "Grooming", "Other"];
const sizes = ["Extra-Small", "Small", "Medium", "Large", "Extra-Large"];
class Areas extends Component {
  state = {
    _id: "",
    add: false,
    name: "",
    abbreviation: "",
    type: "",
    default_dog_sizes: [],
    capacity: {
      day: "",
      night: ""
    }
  };

  setEdit = area => {
    this.setState({
      add: true,
      ...area
    });
  };

  renderAreas = () => {
    const { facility } = this.props;
    return (
      <div>
        {facility.areas.map(f => {
          return (
            <div className="col-sm-12 area" onClick={() => this.setEdit(f)}>
              <div className="col-sm-2">{f.name}</div>
              <div className="col-sm-2">{f.abbreviation}</div>
              <div className="col-sm-2">{f.type}</div>
              <div className="col-sm-2">
                <Multiselect
                  data={sizes}
                  value={f.default_dog_sizes}
                  disabled
                />
              </div>
              <div className="col-sm-2">{f.capacity.day}</div>
              <div className="col-sm-2">{f.capacity.night}</div>
            </div>
          );
        })}
      </div>
    );
  };

  addArea = () => {
    let newState = { ...this.state };
    this.props.addArea(newState);
    this.setState({
      add: false,
      name: "",
      abbreviation: "",
      type: "",
      default_dog_sizes: [],
      capacity: {
        day: "",
        night: ""
      }
    });
  };

  render() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="col-sm-2 areaTitle">Name</div>
          <div className="col-sm-2 areaTitle">Abbreviation</div>
          <div className="col-sm-2 areaTitle">Type</div>
          <div className="col-sm-2 areaTitle">Default Dog Sizes</div>
          <div className="col-sm-2 areaTitle">Max Pets (Day)</div>
          <div className="col-sm-2 areaTitle">Max Pets (Night)</div>
        </div>
        {this.renderAreas()}
        {!this.state.add ? (
          <div className="col-sm-12 addAreas">
            <button
              className="btn btn-primary addFacilityBtn"
              onClick={() => this.setState({ add: true })}
              style={{ margin: "0 auto", display: "block" }}
            >
              Add Area
            </button>
          </div>
        ) : null}
        {this.state.add ? (
          <div className="col-sm-12" className="addArea">
            <div className="col-sm-12">
              <div className="col-sm-12 addAreas">
                <h4>Add/Edit Area</h4>
                <button
                  className="btn btn-danger cancelBtn"
                  onClick={() => this.setState({ add: false })}
                  style={{ margin: "0 auto", display: "block" }}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="col-sm-2">
              Name
              <input
                type="text"
                value={this.state.name}
                onChange={e => this.setState({ name: e.target.value })}
              />
            </div>
            <div className="col-sm-1">
              Abbreviation
              <input
                type="text"
                value={this.state.abbreviation}
                onChange={e => this.setState({ abbreviation: e.target.value })}
              />
            </div>
            <div className="col-sm-2">
              Type
              <DropdownList
                placeholder="Choose Type..."
                data={types}
                value={this.state.type}
                onChange={val => this.setState({ type: val })}
              />
            </div>
            <div className="col-sm-3">
              Default Dog Sizes
              <Multiselect
                data={sizes}
                value={this.state.default_dog_sizes}
                onChange={val => this.setState({ default_dog_sizes: val })}
              />
            </div>
            <div className="col-sm-2">
              Max Pets (Day)
              <input
                type="text"
                value={this.state.capacity.day}
                onChange={e =>
                  this.setState({
                    ...this.state,
                    capacity: { ...this.state.capacity, day: e.target.value }
                  })
                }
              />
            </div>
            <div className="col-sm-2">
              Max Pets (Night)
              <input
                type="text"
                value={this.state.capacity.night}
                onChange={e =>
                  this.setState({
                    ...this.state,
                    capacity: { ...this.state.capacity, night: e.target.value }
                  })
                }
              />
            </div>
            <div className="col-sm-12 addAreas">
              <button
                className="btn btn-secondary"
                style={{ float: "right" }}
                onClick={this.addArea}
              >
                Save
              </button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Areas;
