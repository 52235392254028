import React from "react";
import { Field } from "redux-form";
import { InputField } from "./";
import { formatNumsToDollars, onlyNumbers } from "../../../helpers";

const AppointmentPriceOverrideField = () => (
  <Field
    name="price"
    component={InputField}
    placeholder="Price"
    type="text"
    format={formatNumsToDollars}
    normalize={onlyNumbers}
  />
);

export default AppointmentPriceOverrideField;
