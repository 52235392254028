import React, { Component } from "react";
import { login, resetPassword, getUser } from "../../services/firebaseHelpers";
import { setUser } from "../../actions/general.actions";
import history from "../../actions/history";
import { connect } from "react-redux";
import jwt from "jwt-decode";
import logo from "../../images/waggle-logo.svg";
import firebase from 'firebase'

function setErrorMsg(error) {
  return {
    loginMessage: error
  };
}

const isEmailValid = (email) => {
  if (typeof email === 'string') {
    return email.match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  }
}

class Login extends Component {

  componentDidMount() {
    window.recaptchaVerifier = null
  }

  state = {
    loginMessage: null,
    verificationId: null,
    showFactorQuestion: false
  };

  setMfaError = (err) => {
    this.setState(setErrorMsg(err));
  }

  isLoginValid = () => {
    let email = this.state.email
    let password = this.state.pw

    let emailValid = isEmailValid(email)

    if (!email || !emailValid) {
      this.setMfaError('Invalid Email')
    }

    if (!password) {
      this.setMfaError('Invalid PW')
    }

    return Boolean(email) && Boolean(emailValid) && Boolean(password)
  }

  submitFactor = (e) => {
    e.preventDefault();

    const setUserInfo = (u) => {
      this.props.setUser(u);
      setTimeout(() => {
        history.push("/");
      }, 200)
    }

    try {
      const cred = firebase.auth.PhoneAuthProvider.credential(this.state.verificationId, this.state.code);
      const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);

      window.mfaResolver.resolveSignIn(multiFactorAssertion)
        .then(function (userCredential) {
          // console.log('cred...', userCredential)
          // userCredential will also contain the user, additionalUserInfo, optional
          // credential (null for email/password) associated with the first factor sign-in.
          // For example, if the user signed in with Google as a first factor,
          // userCredential.additionalUserInfo will contain data related to Google provider that
          // the user signed in with.
          // user.credential contains the Google OAuth credential.
          // user.credential.accessToken contains the Google OAuth access token.
          // user.credential.idToken contains the Google OAuth ID token.

          const { user } = userCredential;
          user.getIdToken(true).then(token => {
            localStorage.setItem("accessToken", token);
            const decoded = jwt(token);
            let roles = decoded.roles ? decoded.roles.map(role => role) : [];
            let ourUser = {
              roles: roles,
              email: decoded.email
            };

            setUserInfo(ourUser)

          });


        }).catch(e => {
          console.log('err..', e)
          if (e?.code === 'auth/invalid-verification-code') {
            this.setMfaError('Incorrect MFA Code')
          } else {
            this.setMfaError('Error signing in')
          }
        })

    } catch (e) {
      console.log('err..', e)
      if (e?.code === 'auth/invalid-verification-code') {
        this.setMfaError('Incorrect MFA Code')
      } else {
        this.setMfaError('Error signing in')
      }
    }
  }

  handleSubmit = e => {
    e.preventDefault();

    const setTwoFactors = (ourId) => {
      this.setState({ verificationId: ourId, showFactorQuestion: true, loginMessage: '' })
    }

    login(this.state.email, this.state.pw)
      .then(userCredential => {
        // console.log('ughghghg?????', userCredential)
      })
      .catch(error => {
        console.log('error....', error)
        if (error.code == 'auth/multi-factor-auth-required') {
          // The user is a multi-factor user. Second factor challenge is required.
          const resolver = error.resolver;

          window.mfaResolver = resolver

          if (resolver.hints[0].factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID) {
            // User selected a phone second factor.
            // ...

            if (!window.recaptchaVerifier) window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('captcha', {
              'size': 'invisible',
              'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
              }
            });

            // console.log('whee', window.recaptchaVerifier)

            const phoneNumber = resolver.hints[0].phoneNumber

            const phoneInfoOptions = {
              multiFactorUid: resolver.hints[0].uid,
              session: resolver.session
            };

            var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
            // Send SMS verification code.
            return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
              .then(function (ourId) {
                // verificationId will be needed for sign-in completion.
                setTwoFactors(ourId)


              })

            return
          } else {
            // Unsupported second factor.
            // Note that only phone second factors are currently supported.
            this.setMfaError('Wrong MFA Type')
            return
          }
        }

        if (error.type) {
          this.setState(setErrorMsg(error.message));
        }
        this.setState(setErrorMsg("Invalid username/password."));
      });
  };

  resetPassword = () => {
    resetPassword(this.state.email)
      .then(() =>
        this.setState(
          setErrorMsg(`Password reset email sent to ${this.state.email}.`)
        )
      )
      .catch(error => this.setState(setErrorMsg(`Email address not found.`)));
  };


  render() {

    return (
      <div className="login-bg">
        <div className="col-sm-6 col-sm-offset-3">
          <div id="captcha"></div>
          <div className="login-img">
            <img src={logo} className="login-logo" />
          </div>
         
              <form onSubmit={this.submitFactor} style={{
                display: this.state.showFactorQuestion ? 'block' : 'none'
              }}>
                <div style={{ marginBottom: 36 }}>
                  <label>Two-Factor Code</label>
                  <input
                    id="code"
                    name="code"
                    className="form-control"
                    value={this.state.code}
                    onChange={(e) => this.setState({ code: e.target.value })}
                    placeholder="Enter MFA Code"
                  />
                </div>

                {this.state.loginMessage && (
                  <div className="alert alert-danger" role="alert">
                    <span
                      className="glyphicon glyphicon-exclamation-sign"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Error:</span>
                    &nbsp;{this.state.loginMessage}{" "}
                  </div>
                )}
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
           
              <form onSubmit={this.handleSubmit} style={{
                display: !this.state.showFactorQuestion ? 'block' : 'none'
              }}>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    className="form-control"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    placeholder="Email"
                  />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    value={this.state.pw}
                    onChange={(e) => this.setState({ pw: e.target.value })}

                  />
                </div>
                {this.state.loginMessage && (
                  <div className="alert alert-danger" role="alert">
                    <span
                      className="glyphicon glyphicon-exclamation-sign"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Error:</span>
                    &nbsp;{this.state.loginMessage}{" "}
                    <a href="#" onClick={this.resetPassword} className="alert-link">
                      Forgot Password?
                    </a>
                  </div>
                )}
                <button type="submit" className="btn btn-primary">
                  Login
                </button>
              </form>
      

        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.general.user
});

const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(setUser(user))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
