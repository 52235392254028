import React, { Component } from "react";
import moment from "moment";
import axios from "../../services/network.js";
import Tooltip from "rc-tooltip";
import PetCardCompact from "./scheduler/PetCardCompact";
import Timeblock from "./Timeblock";
import MapAppointmentRow from "./MapAppointmentRow";
import SitterPreview from "./SitterPreview";
import { DropdownList } from "react-widgets";
import _ from "lodash";
import history from "../../actions/history";
import TimePicker from "rc-time-picker";

const statusColors = {
  completed: "#9DD7CC",
  "in-progress": "#F8787A",
  approved: "#AFD47B",
  pending: "#FED866",
  "change-requested": "#AB8BE8",
  selected: "#91CDC2"
};

class MapAppointmentDetail extends Component {
  state = {
    sitter: null,
    appointments: [],
    showOnlyWorkingSitters: true,
    showUnavailableTimes: false,
    showTip: false,
    apartment: null
  };

  closeBtn = () => {
    this.setState({ sitter: null });
    this.props.showPreview();
  };

  async componentWillReceiveProps(props) {
    if (props) {
      const { appointment } = props;
      if (appointment) {
        if (appointment.sitter) {
          const sitter = this.props.sitters.find(
            s => s._id === appointment.sitter._id
          );
          this.setState({ sitter: sitter });
        }
        // if(appointment.appointment_type && appointment.appointment_type.id === 12){
        // const { contact } = appointment
        // console.log(contact.apartment_id);
        // console.log(this.props.appointment.contact);
        // if(contact.apartment_id){
        //   const apartment = props.apartments.find(a => a._id === contact.apartment)
        //   this.setState({ apartment: apartment })
        // }
        // } else {
        //   this.setState({ apartment: null })
        // }
      }
    }
  }

  changeSitter = sitter => {
    let findSit = this.props.sitters.find(s => s._id === sitter._id);
    this.props.assignSitter(findSit, this.props.appointment);
  };

  getSitter = data => {
    if (this.state.sitter) {
      let findIt = data.find(s => s._id === this.state.sitter._id);
      return findIt;
    }
    return "Select Sitter";
  };

  showShift = () => {
    if (!this.state.sitter) return null;
    if (!this.state.sitter.shift)
      return <span>Team Member Not Scheduled In Deputy</span>;
    return (
      <span>
        Shift:{" "}
        {moment(this.state.sitter.shift.StartTimeLocalized).format("hh:mm a")} -{" "}
        {moment(this.state.sitter.shift.EndTimeLocalized).format("hh:mm a")}
      </span>
    );
  };

  renderButtons = () => {
    const status = this.props.appointment.appointment_status.id;
    return (
      <div className="row whatever">
        <button
          className="btn btn-default"
          disabled={status === 4 ? true : false}
          onClick={() => this.props.unassignAppt(this.props.appointment)}
        >
          Unassign
        </button>
        <button
          className="btn btn-primary"
          disabled={status === 4 || status === 2 || status === 7}
          onClick={() => this.props.approveAppt(this.props.appointment)}
        >
          Approve
        </button>
      </div>
    );
  };

  renderTeamMember = () => {
    const { apartment } = this.state;
    const sitterMap = staff => ({
      _id: staff._id,
      name: staff.contact.full_name,
      zones: staff.zones,
      appointments: staff.appointments,
      className: staff.className,
      shift: staff.shift
    });

    // Which options should appear in the dropdown?
    const sitterData = this.props.sitters
      .filter(s => {
        if (apartment && apartment.concierge) {
          return apartment.concierge.staff.includes(s._id);
        } else {
          return true;
        }
      })
      .sort((a, b) => {
        if (a.contact.first_name < b.contact.first_name) return -1;
        if (a.contact.first_name > b.contact.first_name) return 1;
        return 0;
      })
      .map(sitterMap);

    return (
      <div>
        {this.props.sitters && this.props.sitters.length ? (
          <DropdownList
            data={
              this.state.showOnlyWorkingSitters
                ? sitterData.filter(s => s.shift || s._id === null)
                : sitterData
            }
            onChange={val => this.changeSitter(val)}
            value={this.getSitter(sitterData)}
            textField={"name"}
            dropUp
          />
        ) : null}

        <div className="mapAppointmentDetailSettings">
          <label>Show Only Scheduled Team Members</label>
          <input
            type="checkbox"
            checked={this.state.showOnlyWorkingSitters}
            onChange={() =>
              this.setState({
                showOnlyWorkingSitters: !this.state.showOnlyWorkingSitters
              })
            }
          />
        </div>

        {this.state.sitter ? this.showShift() : null}

        <div>{this.renderButtons()}</div>
      </div>
    );
  };

  renderBlock = () => {
    return (
      <div className="mapAppointmentDetail">
        <div className="row petMarkerDetail">
          <div className="closeButton" onClick={() => this.closeBtn()}>
            X
          </div>
          <div className="col-sm-6 petMarkerLeft">
            <PetCardCompact
              appointment={this.props.appointment}
              onClickAppointmentDetail={() =>
                history.push(`/appointments/${this.props.appointment._id}`)
              }
              showAddress
            />
          </div>
          <div className="col-sm-6 petMarkerRight">
            {this.renderTeamMember()}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return <div>{this.props.appointment ? this.renderBlock() : null}</div>;
  }
}

export default MapAppointmentDetail;
