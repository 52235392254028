import { combineReducers } from "redux";

import appointments from "./appointments.reducer";
import clients from "./clients.reducer";
import general from "./general.reducer";
import scheduler from "./scheduler.reducer";
import staff from "./staff.reducer";
import facilities from "./facilities.reducer";

const admin = combineReducers({
  appointments,
  clients,
  general,
  scheduler,
  staff,
  facilities
});

export default admin;
