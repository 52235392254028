import React, { Component } from "react";
import { connect } from "react-redux";
import { createUserService } from "../../services";
import { catchFormServerErrors } from "../../helpers";
//why
import {
  PageTitleComponent,
  PasswordResetConfirmationModal,
  StaffActivationConfirmationModal,
  StaffForm
} from "../../components";

// Actions
import {
  getStaffMember as getStaffMemberAction,
  updateStaffMember
} from "../../actions/admin/staff.actions";
import {
  pushModal,
  popModal,
  showFormErrorMessage,
  showSuccessMessage
} from "../../actions/general.actions";

class EditStaffPage extends Component {

  componentWillMount = () => {
    const { getStaffMember, match } = this.props;
    getStaffMember(match.params.staffId);
  };

  handleSubmit = values => {
    const { onSubmit, match } = this.props;
    const staffId = match.params.staffId;
    const model = createUserService.getAddStaffModel(values);
    model.uid = this.props.staffMember.uid;
    return onSubmit(staffId, model).then(res => catchFormServerErrors(res));
  };

  handleStaffActivation = (e, activate) => {
    e.preventDefault();
    const { staffMember, modalActions } = this.props;
    const modal = (
      <StaffActivationConfirmationModal
        activateStaff={activate}
        staffId={staffMember._id}
      />
    );
    modalActions.push(modal);
  };

  handlePasswordReset = e => {
    e.preventDefault();
    const { staffMember, modalActions } = this.props;
    const modal = (
      <PasswordResetConfirmationModal
        userType="Team Member"
        email={staffMember.contact.email}
      />
    );
    modalActions.push(modal);
  };

  render() {
    const {
      displayFormError,
      displayFormSuccess,
      formConstants,
      staffMember,
      user
    } = this.props;
    return (
      <div className="card">
        <PageTitleComponent title="Edit Staff Member" />
        {staffMember ? (
          <StaffForm
            editExisting
            enableReinitialize
            formConstants={formConstants}
            handleActivation={this.handleStaffActivation}
            handlePasswordReset={this.handlePasswordReset}
            initialValues={createUserService.getInitialFormValuesForStaffMember(
              staffMember
            )}
            onSubmit={this.handleSubmit}
            onSubmitFail={errors => displayFormError(errors)}
            onSubmitSuccess={() =>
              displayFormSuccess("Staff member successfully updated.")
            }
            staffMember={staffMember}
            markets={this.props.markets}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  formConstants: state.general.formConstants,
  staffMember: state.admin.staff.staffMember,
  user: state.general.user,
  markets: state.admin.general.markets
});

const mapDispatchToProps = dispatch => ({
  displayFormError: errors =>
    dispatch(showFormErrorMessage(errors, "addStaffForm")),
  displayFormSuccess: message =>
    dispatch(showSuccessMessage(message, "addStaffForm")),
  getStaffMember: staffId => dispatch(getStaffMemberAction(staffId)),
  modalActions: {
    push: component => dispatch(pushModal(component)),
    pop: () => dispatch(popModal())
  },
  onSubmit: (staffId, model) => dispatch(updateStaffMember(staffId, model))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditStaffPage);
