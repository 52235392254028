import React, { Component } from "react";

export default class Overlay extends Component {

  static defaultProps = {
    textAlign: "center"
  };

  _handleEscKey = e => {
    const { onDismiss } = this.props;
    if (e.keyCode === 27) {
      onDismiss();
    }
  };

  componentWillMount = () => {
    window.addEventListener("keydown", this._handleEscKey);
  };

  componentWillUnmount = () => {
    window.removeEventListener("keydown", this._handleEscKey);
  };

  componentDidMount = () => {
    window.onpopstate = this.onBackButton;
  };

  onBackButton = e => {
    this.props.onDismiss();
  };

  render() {
    const { onDismiss, textAlign } = this.props;

    return (
      <div id="overlay" className="overlay">
        <div className="overlay-content-wrapper">
          <span className="closebtn" onClick={onDismiss}>
            <span className="fa fa-times times"></span>
          </span>
          <div className="overlay-content" style={{ textAlign }}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
