import moment from "moment";

export default {
  /**pre
   * Takes form values and creates a model to send
   * to the server to create Client user.
   *
   * @param {any} values An object of form field values.
   */
  getAddPetModel(values) {
    const model = {
      name: values.name,
      breed: values.breed,
      age: values.age,
      color: values.color,
      size: values.size,
      microchip: values.microchip,
      indoor: values.indoor,
      litterbox: values.litterbox,
      petClass: values.petclass,
      pet_class_notes: values.petnotes,
      pet_location: values.pet_location,
      leash_collar: values.leash_collar,
      harness: values.harness,
      waste_disposal: values.waste_disposal,
      toy_info: values.toy_info,
      bowl_location: values.bowl_location,
      food_location: values.food_location,
      brand_of_food: values.brand_of_food,
      food_instructions: values.food_instructions,
      allergies: values.allergies,
      rabies_vaccination_date: values.rabies_vaccination_date
        ? moment(values.rabies_vaccination_date).format("YYYY-MM-DD")
        : null,
      personality: values.personality,
      vet_clinic: values.vet_clinic,
      vet_phone: values.vet_phone,
      vet_address: values.vet_address,
      vet_city: values.vet_city,
      vet_state: values.vet_state,
      vet_zip_code: values.vet_zip_code,
      other: values.other,
      treat_info: values.treat_info,
      sex: values.sex, // ??????
      gender_preference: values.gender_preference,
      birthday_month: values.birthday_month,
      origin: values.origin,
      PetMedicines: JSON.stringify(values.PetMedicines), // redux-form field array
      water_instructions: values.water_instructions,
      playcare_approved: values.playcare_approved,
      // group_preference: values.playcare_approved
      //   ? values.group_preference
      //   : null,
      temperament_test_status: values.temperament_test_status
        ? values.temperament_test_status
        : "Unscheduled"
    };

    // If image is prefilled with initialValue, it will be an object and not a file.
    // Only submit it if it's a file (which means the user has changed it.)
    if (values.profile_image instanceof File) {
      model.profile_image = values.profile_image;
    }

    return model;
  },

  getInitialFormValuesForUpdate(values) {
    return {
      // General Information
      profile_image: values.image_url ? { preview: values.image_url } : null,
      name: values.name,
      breed: values.breed,
      age: values.age,
      sex: values.sex,
      color: values.color,
      size: values.size,
      microchip: values.medical.microchip,
      indoor: values.indoor,
      personality: values.personality,
      petclass: values.pet_class_id,
      petnotes: values.pet_class_notes,
      birthday_month: values.birthday_month,
      origin: values.origin,
      gender_preference: values.gender_preference,
      harness: values.harness,
      market_id: values.market_id,
      playcare_approved:
        values.animal_type === "Dog" ? values.playcare_approved : false,
      group_preference:
        values.animal_type === "Dog" && values.playcare_approved
          ? values.group_preference
          : null,
      // Visit Details
      litterbox: values.litterbox,
      pet_location: values.pet_location,
      leash_collar: values.leash_collar,
      toy_info: values.toy_info,
      bowl_location: values.food.bowl_location,
      food_location: values.food.food_location,
      brand_of_food: values.food.brand_of_food,
      food_instructions: values.food.food_instructions,
      water_instructions: values.food.water_instructions,
      treat_info: values.food.treat_info,

      // Health
      allergies: values.medical.allergies,
      rabies_vaccination_date: values.medical.rabies_vaccination_date
        ? new Date(values.medical.rabies_vaccination_date)
        : null,
      vet_clinic: values.vet.vet_clinic,
      vet_phone: values.vet.phone,
      vet_address: values.vet.address_line_1,
      vet_city: values.vet.city,
      vet_state: values.vet.state ? values.vet.state : null,
      vet_zip_code: values.vet.zip_code,

      // Medicines
      PetMedicines: values.medical.medications,
      temperament_test_status: values.temperament_test_status
        ? values.temperament_test_status
        : "Unscheduled"
    };
  }
};
