import React, { Component } from "react";
import history from "../../actions/history";
import { connect } from "react-redux";
import { createPetService } from "../../services";
import { PetSelector, PetForm, PageTitleComponent } from "../../components";
import { catchFormServerErrors } from "../../helpers";
import axios from "../../services/network.js";
import { DropdownList } from "react-widgets";
import { Link } from "react-router-dom";

// Actions
import { addPet } from "../../actions/admin/clients.actions";
import {
  showFormErrorMessage,
  showSuccessMessage,
  pushModal,
  popModal
} from "../../actions/general.actions";

class Facility extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    facilities: [],
    name: "",
    add: false,
    market: null
  };

  componentWillMount() {
    this.loadFacilities();
  }

  addFacility = async () => {
    let { name, market } = this.state;
    if (!name || !market) {
      this.props.showMessage({
        messageType: "error",
        message: "Please add a name and select a market"
      });
      setTimeout(this.props.removeMessage, 3000);
      return;
    }
    this.props.showSpinner();
    let { data } = await axios.post(
      `${process.env.REACT_APP_API_URI}/admin/facilities/create`,
      { name, market_id: market._id }
    );

    this.props.showMessage({
      messageType: "success",
      message: "Facility Created"
    });
    setTimeout(this.props.removeMessage, 3000);
    history.push(`/facilities/${data._id}`);
  };

  renderAdd = () => {
    let { name, market } = this.state;
    let { markets } = this.props;

    return (
      <div className="col-sm-12" className="addFacility">
        <div className="col-sm-3">
          <label style={{ display: "block" }}>Name</label>
          <input
            type="text"
            value={name}
            onChange={e => this.setState({ name: e.target.value })}
          />
        </div>
        <div className="col-sm-3">
          <label style={{ display: "block" }}>Market</label>
          <DropdownList
            data={this.props.markets.filter(m => m.city !== "All")}
            textField="city"
            valueField="_id"
            onChange={val => this.setState({ market: val })}
            value={market}
          />
        </div>
        <div className="col-sm-3">
          <button
            className="btn btn-block btn-primary"
            onClick={this.addFacility}
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  loadFacilities = async () => {
    try {
      this.props.showSpinner();
      let { data } = await axios.get(
        `${process.env.REACT_APP_API_URI}/admin/facilities?market=${this.props.market._id}`
      );
      this.setState({ facilities: data });
      this.props.stopSpinner();
    } catch (e) {
      this.props.showMessage({ messageType: "error", message: "Oh no" });
      setTimeout(this.props.removeMessage, 3000);
      this.props.stopSpinner();
    }
  };

  renderFacilities = () => {
    return this.state.facilities.map(f => {
      return (
        <Link to={`/facilities/${f._id}`} className="facLink">
          <div className="col-sm-12 facLinkIn" key={f._id}>
            <div className="col-sm-2">{f.name}</div>
            <div className="col-sm-2">{f.market}</div>
            <div className="col-sm-1">
              {f.services.boarding.available ? "Yes" : "No"}
            </div>
            <div className="col-sm-1">
              {f.services.daycare.available ? "Yes" : "No"}
            </div>
            <div className="col-sm-1">
              {f.services.grooming.available ? "Yes" : "No"}
            </div>
            <div className="col-sm-1">
              {f.capacity.day || 0}/{f.capacity.night || 0}
            </div>
            <div className="col-sm-3">{this.renderStatus(f)}</div>
          </div>
        </Link>
      );
    });
  };

  // TEMP, DONT DELETE...
  renderStatus = facility => {};

  render() {
    let { name, add } = this.state;
    return (
      <div className="card">
        <div className="row">
          <div className="col-sm-12">
            <div className="col-sm-2">Name</div>
            <div className="col-sm-2">Market</div>
            <div className="col-sm-1">Boarding</div>
            <div className="col-sm-1">Daycare</div>
            <div className="col-sm-1">Grooming</div>
            <div className="col-sm-1">Capacity</div>
            <div className="col-sm-3">Status</div>
          </div>
          <div className="facCont">{this.renderFacilities()}</div>
        </div>
        <div className="row addFac">
          <div className="col-sm-12">
            <button
              className="btn btn-primary addFacilityBtn"
              onClick={() => this.setState({ add: true })}
              style={{ margin: "0 auto", display: "block" }}
            >
              Add Facility
            </button>
            {add ? this.renderAdd() : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  formConstants: state.general.formConstants,
  markets: state.admin.general.markets,
  market: state.admin.general.location
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: (clientId, formData, addAnother) =>
    dispatch(addPet(clientId, formData, addAnother)),
  showSpinner: () => dispatch({ type: "FETCH_REQUEST" }),
  stopSpinner: () => dispatch({ type: "FETCH_SUCCESS" }),
  showMessage: msg => dispatch({ type: "ADD_MESSAGE", payload: msg }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" }),
  modalActions: {
    push: component => dispatch(pushModal(component)),
    pop: () => dispatch(popModal())
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Facility);
