import React, { Component } from "react";
import moment from "moment";
import { SelectList, Multiselect } from "react-widgets";

export default class PetDetailsRow extends Component {

  static defaultProps = {
    allowEditPets: false
  };

  state = {
    open: false
  };

  renderViewButton = () => {
    const { open } = this.state;

    let buttonText = "View Pet Details";
    if (open) {
      buttonText = "View Less";
    }

    return (
      <div className="btn" onClick={() => this.setState({ open: !open })}>
        {buttonText}
      </div>
    );
  };

  renderEditIcons = () => {
    const { allowEdit, disabled, editPet, removePet } = this.props;
    if (allowEdit && !disabled) {
      return (
        <div>
          {editPet ? (
            <div className="close-button" onClick={editPet}>
              <div className="fa fa-pencil"></div>
            </div>
          ) : null}
        </div>
      );
    }
  };

  renderRemoveIcons = () => {
    const { allowRemoval, disabled, editPet, removePet } = this.props;
    if (allowRemoval && !disabled) {
      return (
        <div>
          <div className="close-button" onClick={removePet}>
            <div className="fa fa-times"></div>
          </div>
        </div>
      );
    }
  };

  renderSelectList = () => {
    const {
      isAppointmentForm,
      formConstants,
      handleAddService,
      pet
    } = this.props;

    return isAppointmentForm ? (
      <Multiselect
        data={formConstants.addOnServicesPet.filter(
          s => s.name !== "Pee" && s.name !== "Poop"
        )}
        textField="name"
        multiple
        parse={val => val}
        placeholder="Add Services For This Pet"
        onChange={val => handleAddService(val, pet)}
      />
    ) : null;
  };

  renderPetDetails = () => {
    const { open } = this.state;
    const { isAdmin, pet } = this.props;

    const header = title => (
      <div className="pet-row-details-header">{title}</div>
    );

    const item = (title, value) => {
      if (value) {
        return (
          <div className="pet-row-details-item">
            <div className="title-cell">
              <p>{title}</p>
            </div>
            <div className="value-cell">
              <p>{value}</p>
            </div>
          </div>
        );
      }
    };

    const listMedications = () =>
      pet.medical.medications.map((med, index) => {
        return (
          <div key={`med-${med.id}`}>
            {header(`Medication ${index + 1}: ${med.name}`)}
            {item("Purpose", med.purpose)}
            {item("Frequency", med.frequency)}
            {item("Location", med.location)}
            {item("Dosage", med.dosage)}
            {item("Other", med.other)}
          </div>
        );
      });

    if (open) {
      return (
        <div className="pet-row-details">
          {header("General Information")}
          {isAdmin && (pet.pet_class === "Yellow" || pet.pet_class === "Red")
            ? item("Pet Class Notes", pet.pet_class_notes)
            : null}
          {item(
            "Status",
            pet.active
              ? null
              : "This pet has been removed from the client's account."
          )}
          {item("Breed", pet.breed)}
          {item("Age", pet.age)}
          {item("Gender", pet.gender)}
          {item("Color", pet.color)}
          {item("Weight", pet.weight)}
          {item("Personality", pet.personality)}
          {item("Other Information", pet.other)}

          {header("Visit Details")}
          {item("Pet Location", pet.pet_location)}
          {item("Indoor/Outdoor?", pet.indoor)}
          {item("Leash/Collar", pet.leash_collar)}
          {item(
            "Secured Yard?",
            (() => {
              if (pet.secured_yard === true) {
                return "Yes";
              } else if (pet.secured_yard === false) {
                return "No";
              }
            })() // IIFE
          )}
          {item("Litter Box", pet.litterbox)}
          {item("Waste Disposal", pet.waste_disposal)}
          {item("Toy Info", pet.toy_info)}
          {item("Visit Routine", pet.visit_routine)}

          {header("Feeding")}
          {item("Allergies", pet.medical.allergies)}
          {item("Bowl Location", pet.food.bowl_location)}
          {item("Food/Treat Location", pet.food.food_treat_location)}
          {item("Brand of Food", pet.food.brand_of_food)}
          {item("Food Instructions", pet.food.food_instructions)}

          {header("Medical")}
          {item(
            "Rabies Expiration",
            pet.medical.rabies_vaccination_date
              ? moment(pet.medical.rabies_vaccination_date).format("MM/DD/YYYY")
              : null
          )}
          {item(
            "Spayed/Neutered?",
            (() => {
              if (pet.medical.fixed === true) {
                return "Yes";
              } else if (pet.medical.fixed === false) {
                return "No";
              }
            })() // IIFE
          )}
          {listMedications()}
        </div>
      );
    }
  };

  render() {
    const { open } = this.state;
    const { isAdmin, pet } = this.props;
    let petImageClass = "pet-img";
    if (isAdmin && (pet.pet_class === "Yellow" || pet.pet_class === "Red")) {
      petImageClass += ` ${pet.pet_class.toLowerCase()}-border`;
    }

    return (
      <div className="col-sm-6">
        <div className="pet-row" onClick={() => this.setState({ open: !open })}>
          <div className="pet-row-top">
            <div className="pet-info">
              <div
                className={petImageClass}
                style={{ backgroundImage: `url(${pet.image_url})` }}
              ></div>
              <p>{pet.name}</p>
              <div
                onClick={e => e.stopPropagation()}
                style={{ overflow: "visible" }}
              >
                {this.renderSelectList()}
              </div>
            </div>
            <div className="pet-actions">
              {this.renderViewButton()}
              {this.renderEditIcons()}
              {this.renderRemoveIcons()}
            </div>
          </div>
          {this.renderPetDetails()}
        </div>
      </div>
    );
  }
}
