import React, { Component } from "react";

class Faq extends Component {
  render() {
    let style = {
      display: "block",
      fontWeight: "bold",
      fontSize: "16px",
      marginTop: "10px",
      marginBottom: "5px"
    };

    let divStyle = {
      padding: "20px",
      fontSize: "14px"
    };

    let h3Style = {
      fontWeight: "bold",
      fontSize: "26px"
    };

    return (
      <div className="card p-t-none" style={divStyle}>
        <div>
          <h3 style={h3Style}>HOME LOGISTICS</h3>
          <div>
            <span style={style} className="question">
              Q: I can’t find the home. How do I get there?
            </span>
            A: Follow the link to Maps from Petsby. Double check address in Maps
            matches profile information in Petsby. If this is incorrect, use
            Google Maps for directions to home. Use Google Street View/house
            numbers to verify home is correct.
            <span style={style} className="question">
              Q: I can’t find the lockbox. What do I do?
            </span>
            A: Double check address to make sure you’re at the correct home.
            Look around entire property to see if lockbox location has changed.
            Check all notes for any indication of location or alternate form of
            entry. If you are at the correct location and the lockbox cannot be
            found, please contact Waggle line and send a photo of house number
            to prove correct address.
            <span style={style} className="question">
              Q: I can’t open the lockbox/the code is incorrect. What do I do?
            </span>
            A: Double check address to make sure you’re at the correct home. Try
            the numbers above or below each number of the code (for example; if
            code is 123, try 223, 023, 113,133, etc). If you can not open the
            box, call the Waggle line.
            <span style={style} className="question">
              Q: The key is missing from the lockbox. What do I do?
            </span>
            A: Check all around the lockbox area (grass, bushes, etc) and under
            doormats. Check previous client comments to see if key is in
            different location. Check visit history to see if other team members
            mentioned it. If you still can’t find it, call the Waggle line.
            <span style={style} className="question">
              Q: I walked into the home and the alarm is going off! What do I
              do?
            </span>
            A: Check Home Info notes carefully for disarm code. Try same code as
            electronic door (if applicable). If that doesn’t work, call the
            Waggle line.
            <span style={style} className="question">
              Q: The key does not work in the lock. What do I do?
            </span>
            A: Push the door away and/or pull the door towards you, then try
            again. Pull key out 1 cm and try to turn (don’t force). Jiggle the
            key in the lock to engage. If that doesn’t work, call the Waggle
            line.
            <span style={style} className="question">
              Q: The code won’t work on the electronic keypad. What do I do?
            </span>
            A: Double check Home Access notes for correct code. Pull door
            towards you, then try again. If that doesn’t work, call Waggle line.
            <span style={style} className="question">
              Q: The house door was unlocked/open when I arrived. What should I
              do?
            </span>
            A: Knock on the door and announce, “Waggle is here!” Proceed as
            normal. Lock the door when you leave and mention the logistics in
            your visit report.
            <span style={style} className="question">
              Q: The alarm was off when I arrived, but Home Info contains an
              alarm code. Should I arm it when I leave?
            </span>
            A: If they notes specifically say to make sure alarm is set, please
            arm before leaving. If not, please leave it as you found it (off).
            Mention the logistics in the visit report.
          </div>
        </div>
        <div>
          <h3 style={h3Style}>ANIMAL BEHAVIOR</h3>
          <div>
            <span style={style} className="question">
              Q: The dog is scared and won’t come with me. What do I do?
            </span>
            A: Move slowly and speak in a soothing voice. Avoid eye contact and
            sit on the floor to let the dog come to you. Use treats, if
            available, to help them warm up to you. If you can get close, clip
            their leash quickly or use your slip leash to lasso them. If needed,
            walk outside for a moment to “reset” the scene. If the visit is
            backyard play, open the door and give them space to walk outside. If
            you have spent more than 5 minutes “warming up”, call Waggle line.
            <span style={style} className="question">
              Q: The dog is being aggressive. What do I do?
            </span>
            A: Do not crowd the dog’s space. Move slowly and speak in a soothing
            voice. If the dog is barking and/or biting, take a video of the
            behavior and send it to lizzy@thewagglecompany.com . Do NOT mention
            the video to clients in your notes. If you can not successfully get
            the dog outside, call the Waggle line.
            <span style={style} className="question">
              Q: The dog got sick (vomited or had diarrhea). Should I tell the
              owner?
            </span>
            A: Always mention small health issues in your notes. If there is a
            lot of vomit or diarrhea, include a photo. Check the dog’s gums
            (pink color = healthy, pale/gray = emergency) and behavior. If gums
            are pink, you may finish the walk and include in your notes. Text
            Waggle line with info. If the dog is uncontrollably sick and/or
            unresponsive, call Waggle line immediately.
            <span style={style} className="question">
              Q: I can’t find the pet! They’re not in the listed location. What
              do I do?
            </span>
            A: Check notes for possible hiding locations. Search everywhere;
            under beds, in closets, in bathrooms, behind couches, etc. Knock and
            announce, “Hello! The Waggle Company is here!” before
            opening/entering any closed door in the home. Check backyard (if
            applicable). If you still can’t find the pet, call the Waggle line.
          </div>
        </div>
        <div>
          <h3 style={h3Style}>APP QUESTIONS</h3>
          <div>
            <span style={style} className="question">
              Q: Why am I getting error pop-ups in the middle of my walk?
            </span>
            A: Make sure you have the most recent version of Petsby on your
            phone. Open your Test Flight app and tap “Update” next to Petsby’s
            name (if available). If you are updated and still getting errors,
            please send a screenshot to the Waggle line.
            <span style={style} className="question">
              Q: Why won’t my app let me take/upload photos?
            </span>
            A: We’re working on that! Try quitting your app and restarting. Save
            backup photos to camera roll and try to upload throughout the visit.
            If that does not help, send photos to Waggle line to upload.
            <span style={style} className="question">
              Q: My comments were deleted. What do I do?
            </span>
            A: Send complete report to Waggle line to upload.
          </div>
        </div>
        <div>
          <h3 style={h3Style}>HELPFUL VIDEOS</h3>
          <span style={style} className="question">
            <a href="https://www.youtube.com/watch?v=PMBAxemcrUA">
              Easy Walk Harness
            </a>
          </span>
          <span style={style} className="question">
            <a href="https://www.youtube.com/watch?v=mjx_g8I6nmI">
              Step-In Harness
            </a>
          </span>
          <span style={style} className="question">
            <a href="https://www.youtube.com/watch?v=1SvRoesgvQ0">
              Gentle Leader
            </a>
          </span>
          <span style={style} className="question">
            <a href="https://www.youtube.com/watch?v=m1BgZlB-Sc0">
              Step-In Adjustable Harness
            </a>
          </span>
          <span style={style} className="question">
            <a href="https://www.youtube.com/watch?v=KF41AOP95BU">K9 Harness</a>
          </span>
          <span style={style} className="question">
            <a href="https://www.youtube.com/watch?v=vcKlI-LcQis">
              Turn a Slip Lead into a Harness
            </a>
          </span>
          <span style={style} className="question">
            <a href="https://www.youtube.com/watch?time_continue=53&amp=&v=mf1uzzsASVU">
              Ruffwear Harness
            </a>
          </span>
        </div>
      </div>
    );
  }
}

export default Faq;
