import { APIService } from "../../services";
import { loadConstants, showSuccessMessage } from "../general.actions";
import { dispatchFetchFailure } from "../helpers";

export function addZone(model) {
  const context = "addZone";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .addZone(model)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch(loadConstants());
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function deleteZone(zoneId) {
  const context = "deleteZone";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .deleteZone(zoneId)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch(loadConstants());
          dispatch(
            showSuccessMessage(
              "Zone successfully deleted",
              "clientAppointmentDetails"
            )
          );
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function updateZone(zoneId, model) {
  const context = "updateZone";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .updateZone(zoneId, model)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch(loadConstants());
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}
