import React, { Component } from "react";
import moment from "moment-timezone";
import Tooltip from "rc-tooltip";
import PetCardCompact from "./scheduler/PetCardCompact";
import ZoneBadge from "./ZoneBadge";
import Timeblock from "./Timeblock";
import { DropdownList } from "react-widgets";
import _ from "lodash";
import history from "../../actions/history";
import TimePicker from "rc-time-picker";
import { connect } from "react-redux";
import axios from "../../services/network.js";

export default class SitterBreakRow extends Component {
  state = {
    dirty: false,
    floating_break_duration: 20,
    floating_break_window_start_time: "02:00 pm",
    floating_break_window_end_time: "04:00 pm"
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.sitter) {
      let sitter = nextProps.sitter;
      if (
        sitter.floating_break_duration &&
        sitter.floating_break_duration !== this.state.floating_break_duration
      ) {
        this.setState({
          floating_break_duration: sitter.floating_break_duration
        });
      }
      if (
        sitter.floating_break_window_start_time &&
        sitter.floating_break_window_start_time !==
          this.state.floating_break_window_start_time
      ) {
        this.setState({
          floating_break_window_start_time:
            sitter.floating_break_window_start_time
        });
      }
      if (
        sitter.floating_break_window_end_time &&
        sitter.floating_break_window_end_time !==
          this.state.floating_break_window_end_time
      ) {
        this.setState({
          floating_break_window_end_time: sitter.floating_break_window_end_time
        });
      }
    }
  }

  getTimes = () => {
    let times = [];
    for (
      let i = moment("09:00", "HH:mm");
      i.isBefore(moment("20:00", "HH:mm"));
      i.add(15, "minutes")
    ) {
      times.push(i.format("hh:mm a"));
    }
    return times;
  };

  floatingBreak = async (id, useBreak) => {
    try {
      this.props.setBreakInRedux(id, useBreak ? false : true);
      await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/staffBreak/${id}?break=${
          useBreak ? "false" : "true"
        }`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );
      if (useBreak) this.setState({ dirty: false });
    } catch (e) {
      console.log("bad error here sir:", e);
    }
  };

  adjustTimes = (val, type) => {
    this.setState({
      [type]: val,
      dirty: true
    });
  };

  save = async () => {
    try {
      this.props.showSpinner();
      let id = this.props.sitter._id;
      let ourBreak = {
        ...this.state
      };

      await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/staffBreak/${id}?break=true`,
        ourBreak,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );
      this.props.adjustBreak(id, ourBreak);
      this.props.stopSpinner();
      this.setState({ dirty: false });
    } catch (e) {
      console.log("the error....", e);
      this.props.stopSpinner();
      alert("Error saving break");
    }
  };

  render() {
    let s = this.props.sitter;

    return (
      <div className={`col-sm-12 breakRow ${this.state.dirty ? "dirty" : ""}`}>
        <div className="row">
          <div className="col-sm-2">{s.contact.full_name}</div>
          <div className="col-sm-3">
            <span>
              {moment(s.shift.StartTimeLocalized).format("hh:mm a")} -{" "}
              {moment(s.shift.EndTimeLocalized).format("hh:mm a")}
            </span>
            <span>
              {" "}
              (
              {Math.abs(
                moment(s.shift.StartTimeLocalized).diff(
                  s.shift.EndTimeLocalized,
                  "hours"
                )
              )}{" "}
              hours)
            </span>
          </div>
          <div className="col-sm-7">
            {!s.break_id ? (
              <div className="col-sm-1">
                <input
                  type="checkbox"
                  checked={s.floating_break}
                  onChange={() => this.floatingBreak(s._id, s.floating_break)}
                />
              </div>
            ) : null}
            {s.floating_break && !s.break_id ? (
              <div className="col-sm-3">
                <input
                  type="text"
                  onChange={e =>
                    this.setState({
                      floating_break_duration: Number(e.target.value),
                      dirty: true
                    })
                  }
                  value={this.state.floating_break_duration}
                  className="breakInput"
                />
                <span>Min.</span>
              </div>
            ) : null}
            {s.floating_break && !s.break_id ? (
              <div className="col-sm-8">
                <div className="col-sm-5">
                  <DropdownList
                    data={this.getTimes()}
                    onChange={val =>
                      this.adjustTimes(val, "floating_break_window_start_time")
                    }
                    value={this.state.floating_break_window_start_time}
                  />
                </div>
                <div className="col-sm-5">
                  <DropdownList
                    data={this.getTimes()}
                    onChange={val =>
                      this.adjustTimes(val, "floating_break_window_end_time")
                    }
                    value={this.state.floating_break_window_end_time}
                  />
                </div>
                <div className="col-sm-2">
                  {this.state.dirty ? (
                    <button onClick={this.save}>Save</button>
                  ) : null}
                </div>
              </div>
            ) : null}
            {s.break_id ? (
              <div className="col-sm-12">
                This TM has a pre-scheduled break from {s.break_start} to{" "}
                {s.break_end}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
