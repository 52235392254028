import React, { Component } from "react";
import queryString from "query-string";
import moment from "moment";
import axios from "../../../services/network.js";

class MetricTable extends Component {
  state = {
    revenue: 0,
    promo_credit: (0).toFixed(2),
    error_credit: (0).toFixed(2),
    other_credit: (0).toFixed(2),
    walk_credits: [],
    line_items: [],
    appointments: []
  };

  componentWillReceiveProps = props => {
    this.setState({ walk_credits: [], line_items: [], appointments: [] });
  };

  getMetrics = async () => {
    this.props.showSpinner();
    const {
      start_date,
      end_date,
      market_id,
      getAppointments,
      getLineItems,
      getWalkCredits
    } = this.props;
    const appointments = await getAppointments(start_date, end_date, market_id);

    const ids = appointments.map(a => a._id);
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URI}/admin/appointments/getPayments`,
      { ids: ids },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      }
    );
    let line_items = data.line_items;
    let walk_credits = data.walk_credits;
    let playcare_credits = data.playcare_credits;

    playcare_credits.map(c => {
      const appts = appointments.filter(a =>
        c.visit_ids.includes(a._id.toString())
      );
      appts.forEach(a => {
        let amount = c.visits_used_on.find(
          v => v.visit_id === a._id.toString()
        );
        a.price = amount.amount_used * c.price;
      });
    });

    const l = getLineItems(start_date, end_date, market_id);
    const w = getWalkCredits(start_date, end_date, market_id);

    const [negative_line_items, negative_walk_credits] = await Promise.all([
      l,
      w
    ]);
    const other_credit = (
      this.getCatchAllCredits(negative_line_items, negative_walk_credits) / 100
    ).toFixed(2);
    const error_credit = (
      (await this.getDiscountValue(
        "Error",
        negative_line_items,
        negative_walk_credits
      )) / 100
    ).toFixed(2);
    const promo_credit = (
      (await this.getDiscountValue(
        "Promo",
        negative_line_items,
        negative_walk_credits
      )) / 100
    ).toFixed(2);

    this.setState({
      promo_credit: promo_credit,
      error_credit: error_credit,
      other_credit: other_credit,
      walk_credits: walk_credits,
      line_items: line_items,
      appointments: appointments
    });

    this.props.stopSpinner();
  };

  getTotalHours = () => {
    let hours = this.state.appointments
      .filter(a => a.appointment_status.id === 5)
      .map(a => a.appointment_type.duration_in_minutes);
    if (!hours || !hours.length) return 0;
    let total = hours.reduce((a, b) => (!isNaN(b) ? a + b : a + 0));
    return total / 60;
  };

  getSeriesVisits = () => {
    let series = this.state.appointments.filter(
      a => a.appointment_status.id === 5 && a.recurrence_id
    );
    return series.length;
  };

  getNonSeriesVisits = () => {
    let nonSeries = this.state.appointments.filter(
      a => !a.recurrence_id && a.appointment_status.id === 5
    );
    return nonSeries.length;
  };

  getTotalVisits = () => {
    return this.state.appointments.filter(a => a.appointment_status.id === 5)
      .length;
  };

  getTotalCancels = () => {
    let cancels = this.state.appointments.filter(
      a => a.appointment_status.id === 7
    );
    return cancels.length;
  };

  getSameDayCancels = () => {
    let cancels = this.state.appointments
      .filter(a => a.appointment_status.id === 7)
      .filter(a => {
        return (
          a.cancel_date &&
          moment(a.cancel_date).isSame(moment(a.window_start_time), "day")
        );
      });
    return cancels.length;
  };

  getLateVisits = () => {
    let late = this.state.appointments.filter(
      a =>
        a.appointment_status.id === 5 &&
        a.appointment_type.id !== 8 &&
        moment(a.actual_start_time).isAfter(moment(a.window_end_time))
    );
    return late.length;
  };

  getConsultations = () => {
    return this.state.appointments.filter(
      a => a.appointment_type.id === 8 && a.actual_end_time
    ).length;
  };

  getCompletedAppointments = () => {
    return this.state.appointments.filter(x => x.actual_end_time);
  };

  getRealizedRevenue = () => {
    const appointments = this.state.appointments.map(a => {
      let credit = this.state.walk_credits.find(c => c.visit_used_on == a._id);
      let item = this.state.line_items.find(i => i.appointment_id == a._id);

      if (credit) {
        a.credit = credit;
        a.price = credit.price;
      }
      if (item) {
        a.item = item;
        if (!a.price) a.price = item.amount;
      }

      if (!a.price && !credit && !item) a.price = 0;
      return a;
    });

    const returnVal = appointments.reduce((accumulator, a) => {
      return !isNaN(a.price) ? accumulator + Number(a.price) : accumulator;
    }, 0);
    return returnVal;
  };

  getDiscountValue = (type, line_items, walk_credits) => {
    return new Promise(async (res, rej) => {
      let line_discount = line_items
        .filter(l => l.type == type && l.amount < 0)
        .reduce((accumulator, l) => {
          return !isNaN(l.amount) ? accumulator - l.amount : accumulator;
        }, 0);
      line_discount = line_discount ? line_discount : 0;

      let credit_discount = walk_credits
        .filter(w => w.promo_type === type)
        .reduce((accumulator, w) => {
          const discount = w.price * w.discount;
          if (!isNaN(discount)) {
            return accumulator + discount;
          }
        }, 0);
      credit_discount = credit_discount ? credit_discount : 0;

      let total = line_discount + credit_discount;
      total = total ? total : 0;
      res(total);
    });
  };

  getCatchAllCredits = (line_items, walk_credits) => {
    let line_discount = line_items
      .filter(l => {
        return l.amount < 0 && l.type !== "Promo" && l.type !== "Error";
      })
      .reduce((accumulator, l) => {
        return !isNaN(l.amount) ? accumulator - l.amount : accumulator;
      }, 0);
    line_discount = line_discount ? line_discount : 0;

    let credit_discount = walk_credits
      .filter(w => {
        return w.promo_type !== "Promo" && w.promo_type !== "Error";
      })
      .reduce((accumulator, w) => {
        const discount = w.price * w.discount;
        if (!isNaN(discount)) {
          return accumulator + discount;
        }
      }, 0);
    credit_discount = credit_discount ? credit_discount : 0;

    const total = line_discount + credit_discount;
    return total ? total : 0;
  };

  getActiveClients = () => {
    const filter_list = this.state.appointments.filter(a => {
      return a.appointment_type.id !== 8 && a.appointment_type.id !== 10;
    });
    return [...new Set(filter_list.map(a => a.client_id))].length;
  };

  getVisitCount = duration => {
    return this.state.appointments.filter(
      a => a.appointment_type.duration_in_minutes === duration
    ).length;
  };

  render() {
    return (
      <div>
        <div className="card p-t-none">
          <div className="data-container">
            <div className="row staffDataContainer">
              <div className="col-sm-12">
                <h3>
                  General Stats <small>(Completed visits only)</small>
                </h3>
              </div>
              <div className="button-container">
                <button
                  className="btn btn-primary pull-right"
                  onClick={this.getMetrics}
                  disabled={this.props.disabled}
                >
                  Get Metrics
                </button>
              </div>
              <div className="col-sm-6 dataCol">
                <span className="dataHeader">Total Hours:</span>
                {this.getTotalHours()}
              </div>
              <div className="col-sm-6 dataCol">
                <span className="dataHeader">Series Visits:</span>
                {this.getSeriesVisits()}
              </div>
              <div className="col-sm-6 dataCol">
                <span className="dataHeader">Non-Series Visits:</span>
                {this.getNonSeriesVisits()}
              </div>
              <div className="col-sm-6 dataCol">
                <span className="dataHeader">Total Visits:</span>
                {this.getTotalVisits()}
              </div>
              <div className="col-sm-6 dataCol">
                <span className="dataHeader">Late Visits:</span>
                {this.getLateVisits()}
              </div>
              <div className="col-sm-6 dataCol">
                <span className="dataHeader">Consultations:</span>
                {this.getConsultations()}
              </div>
            </div>
          </div>
        </div>
        <div className="card p-t-none">
          <div className="row staffDataContainer">
            <div className="col-sm-12">
              <h3>
                Market Stats <small>(Current Market)</small>
              </h3>
            </div>
          </div>
          <div className="row staffDataContainer">
            <div className="col-md-4 dataCol">
              <span className="dataHeader">Realized Revenue:</span>$
              {(this.getRealizedRevenue() / 100).toFixed(2)}
            </div>
            <div className="col-md-4 dataCol">
              <span className="dataHeader">20 Minute Visits:</span>
              {this.getVisitCount(20)}
            </div>
            <div className="col-md-4 dataCol">
              <span className="dataHeader">40 Minute Visits:</span>
              {this.getVisitCount(40)}
            </div>
          </div>
          <div className="row staffDataContainer">
            <div className="col-md-4 dataCol">
              <span className="dataHeader">Promo Discounts:</span>$
              {this.state.promo_credit}
            </div>
            <div className="col-md-4 dataCol">
              <span className="dataHeader">60 Minute Visits:</span>
              {this.getVisitCount(60)}
            </div>
            <div className="col-md-4 dataCol">
              <span className="dataHeader">Playcare Visits:</span>
              {this.getVisitCount(11)}
            </div>
          </div>
          <div className="row staffDataContainer">
            <div className="col-md-4 dataCol">
              <span className="dataHeader">Error Discounts:</span>$
              {this.state.error_credit}
            </div>
            <div className="col-md-4 dataCol">
              <span className="dataHeader">10 Minute Visits:</span>
              {this.getVisitCount(10)}
            </div>
            <div className="col-md-4 dataCol">
              <span className="dataHeader">30 Minute Visits:</span>
              {this.getVisitCount(30) - this.getConsultations()}
            </div>
          </div>
          <div className="row staffDataContainer">
            <div className="col-md-4 dataCol">
              <span className="dataHeader">Other Discounts:</span>$
              {this.state.other_credit}
            </div>
            <div className="col-md-4 dataCol">
              <span className="dataHeader">45 Minute Visits:</span>
              {this.getVisitCount(45)}
            </div>
            <div className="col-md-4 dataCol">
              <span className="dataHeader">90 Minute Visits:</span>
              {this.getVisitCount(90)}
            </div>
          </div>
          <div className="row staffDataContainer">
            <div className="col-md-4 dataCol">
              <span className="dataHeader">Active Clients:</span>
              {this.getActiveClients()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MetricTable;
