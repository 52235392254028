import React, { Component } from "react";
import moment from "moment-timezone";
import Tooltip from "rc-tooltip";
import PetCardCompact from "./scheduler/PetCardCompact";
import Timeblock from "./Timeblock";
import { DropdownList } from "react-widgets";
import _ from "lodash";
import history from "../../actions/history";

const statusColors = {
  completed: "#9DD7CC",
  "in-progress": "#F8787A",
  approved: "#AFD47B",
  pending: "#FED866",
  "change-requested": "#AB8BE8",
  selected: "#91CDC2"
};

class PetMarker extends Component {
  state = {
    sitter: null,
    showStack: false,
    showTip: false
  };

  closeBtn = () => {
    this.setState({ sitter: null });
    const app = this.props.appointment;
    this.props.showPreview(app);
  };

  componentWillReceiveProps(props) {
    if (props.appointment) {
      if (props.appointment.sitter) {
        const sitter = this.props.sitters.find(
          s => s._id === props.appointment.sitter._id
        );
        if (
          !this.state.sitter ||
          this.state.sitter._id === props.appointment.sitter._id
        ) {
          this.setState({ sitter: sitter });
        }
      }
    }
  }

  showPreview = e => {
    // this is a hack to get around how the google map react library handles events
    e.origin = "petmarker";
    this.props.showPreview(this.props.appointment);
  };

  renderMarkerColors = () => {
    // console.log(this.props, this.state)
    let { appointment } = this.props;
    let { sitter } = this.state;
    const color = sitter ? sitter.className : "#FFFFFF";

    if (appointment.appointment_status.id === 1) {
      // unassigned
      if (!appointment.sitter || !appointment.sitter._id) {
        return (
          <span
            className="fa-stack fa-lg pendingMarker"
            onClick={this.showPreview}
          >
            <i className="fa fa-square fa-stack-2x i-base cpend pendingMarker"></i>
          </span>
        );
      } else {
        //assigned but still pending TODO add in the correct color class to this one
        return (
          <span
            className="fa-stack fa-lg pendingMarker"
            onClick={this.showPreview}
          >
            <i className="fa fa-square fa-stack-2x i-base cpend pendingMarker"></i>
            <i className={`fa fa-circle fa-stack-1x`} style={{ color }}></i>
          </span>
        );
      }
    }

    // appt is approved
    if (appointment.appointment_status.id === 2) {
      // approved, not assigned
      if (!appointment.sitter || !appointment.sitter._id) {
        return (
          <span className="fa-stack fa-lg" onClick={this.showPreview}>
            <i className="fa fa-square fa-stack-2x i-base cappr"></i>
          </span>
        );
      } else if (
        appointment.sitter &&
        appointment.sitter._id &&
        appointment.en_route
      ) {
        //approved and assigned TODO add in the correct color class to this one
        return (
          <span className="fa-stack fa-lg carCont" onClick={this.showPreview}>
            <i
              className={`fa fa-circle fa-stack-2x i-base`}
              style={{ color }}
            ></i>
            <i className="fa fa-car fa-stack-1x specialFancyCar" />
          </span>
        );
      } else {
        return (
          <span className="fa-stack fa-lg" onClick={this.showPreview}>
            <i
              className={`fa fa-circle fa-stack-2x i-base`}
              style={{ color }}
            ></i>
          </span>
        );
      }
    }

    // appt has change request TODO add in the correct color class to this one
    if (appointment.appointment_status.id === 3) {
      if (!this.state.sitter) {
        return (
          <span className="fa-stack fa-lg" onClick={this.showPreview}>
            <i className={`fa fa-square fa-stack-2x i-base cappr`}></i>
            <i
              className="fa fa-chevron-up fa-stack-1x"
              style={{ color: "#FFFFFF" }}
            ></i>
          </span>
        );
      }
      return (
        <span className="fa-stack fa-lg" onClick={this.showPreview}>
          <i
            className={`fa fa-circle fa-stack-2x i-base`}
            style={{ color }}
          ></i>
          <i className="fa fa-chevron-up fa-stack-1x"></i>
        </span>
      );
    }

    // appt is in progress TODO add in the correct color class to this one
    if (appointment.appointment_status.id === 4) {
      return (
        <span className="fa-stack fa-lg" onClick={this.showPreview}>
          <i
            className={`fa fa-circle fa-stack-2x i-base`}
            style={{ color }}
          ></i>
          <i className="fa fa-spinner fa-spin fa-stack-1x"></i>
        </span>
      );
    }

    // appt is completed TODO add in the correct color class to this one
    if (appointment.appointment_status.id === 5) {
      return (
        <span className="fa-stack fa-lg" onClick={this.showPreview}>
          <i
            className={`fa fa-circle fa-stack-2x i-base`}
            style={{ color }}
          ></i>
          <i className="fa fa-check fa-stack-1x"></i>
        </span>
      );
    }

    //appt status is canceled TODO add in the correct color class to this one
    if (appointment.appointment_status.id === 7) {
      return (
        <span className="fa-stack fa-lg" onClick={this.showPreview}>
          <i
            className={`fa fa-circle fa-stack-2x i-base`}
            style={{ color }}
          ></i>
          <i className="fa fa-times fa-stack-1x"></i>
        </span>
      );
    }

    if (appointment.appointment_status.id === 6) {
      return (
        <span className="canceledThing">
          <i
            className={`fa fa-circle fa-stack-2x i-base`}
            style={{ color }}
          ></i>
          <i className="fa fa-times fa-stack-1x"></i>
        </span>
      );
    }
  };

  tooltipInfo = () => {
    return (
      <div>
        {this.state.sitter && this.state.sitter.contact
          ? this.state.sitter.contact.full_name + ", "
          : "Unassigned, "}
        {this.props.appointment.scheduled_start_time
          ? moment(this.props.appointment.scheduled_start_time).format("hh:mm")
          : "Not Scheduled"}
      </div>
    );
  };

  renderMarker = () => {
    let { appointment } = this.props;
    let { sitter } = this.state;
    const color = this.state.sitter ? this.state.sitter.className : "#EEE";

    return (
      <Tooltip
        placement="top"
        overlay={<div>{this.tooltipInfo()}</div>}
        mouseEnterDelay={0.5}
        mouseLeaveDelay={0.1}
        overlayClassName="annotation-tooltip"
      >
        {this.renderMarkerColors()}
      </Tooltip>
    );
  };

  noStack = () => {
    this.setState({ showStack: false });
  };

  showStack = () => {
    return (
      <div className="showStack">
        <div className="showStackClose">
          <span
            onClick={e => {
              e.stopPropagation();
              this.noStack();
            }}
          >
            X
          </span>
        </div>
        {this.props.stackedVisits.map(a => {
          return (
            <div
              className="row sweetStack"
              key={`stackedAppts${a._id}`}
              onClick={e => this._onClick(a, e)}
            >
              <div className="col-sm-3 showStackCol">{a.contact.full_name}</div>
              <div className="col-sm-3 showStackCol">
                {a.appointment_status.name}
              </div>
              <div className="col-sm-4 showStackCol">
                {moment(a.window_start_time).format("hh:mm")}-
                {moment(a.window_end_time).format("hh:mm")}
              </div>
              <div className="col-sm-2 showStackCol">
                {a.appointment_type.duration_in_minutes
                  ? a.appointment_type.duration_in_minutes
                  : ""}{" "}
                min
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  _onClick = (a, e) => {
    // this is a hack to get around how the google map react library handles events
    e.origin = "petmarker";
    this.props.showPreview(a, e);
  };

  renderStack = () => {
    let { stackedVisits } = this.props;
    return (
      <div>
        <Tooltip
          placement="top"
          overlay={<div>{this.tooltipInfoStack()}</div>}
          mouseEnterDelay={0.5}
          mouseLeaveDelay={0.1}
          overlayClassName="annotation-tooltip"
        >
          <div
            className="stackerBoi"
            onClick={e => {
              e.stopPropagation();
              this.setState({ showStack: true });
            }}
          >
            <span>{stackedVisits.length}</span>
          </div>
        </Tooltip>
        {this.state.showStack ? this.showStack() : null}
      </div>
    );
  };

  tooltipInfoStack = () => {
    let { stackedVisits } = this.props;
    let clientIds = [...new Set(stackedVisits.map(v => v.client_id))];
    if (clientIds.length === 1) return stackedVisits[0].contact.full_name;

    let aptVisits = stackedVisits.filter(a => a.contact.apartment_id);
    let apartmentIds = [...new Set(aptVisits.map(v => v.contact.apartment_id))];
    if (apartmentIds.length === 1) return aptVisits[0].contact.apartment_name;
    return "";
  };

  render() {
    // if (this.props.stackedVisits && this.props.stackedVisits.length) return this.renderStack()
    return (
      <div
        className={
          this.props.appointment.highlight ||
          this.props.appointment.highlightAppt
            ? "highlight"
            : "no-highlight"
        }
      >
        {this.renderMarker()}
      </div>
    );
  }
}

export default PetMarker;
