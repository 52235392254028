import React, { Component } from "react";
import moment from "moment";
import { Multiselect } from "react-widgets";

class ExportAppointmentsForm extends Component {
  state = {
    fields: this.props.fields,
    headers: this.props.headers
  };

  componentWillMount = () => {
    this.setState({
      fields: this.props.fields,
      headers: this.props.headers
    });
  };

  handleUpdate = items => {
    const fields = [];
    const headers = [];
    for (let item of items) {
      fields.push(item.value);
      headers.push(item.text);
    }
    this.setState({ fields: fields, headers: headers });
  };

  processRow = data => {
    return new Promise((resolve, reject) => {
      let string = "";
      let i = 0;
      for (let f of this.state.fields) {
        try {
          const val = data[f];
          if (typeof val === "string") {
            string += `${val ? val.replace(/[,\n]/g, "") : ""},`;
          } else {
            string += `${val || val === 0 ? val : ""},`;
          }
        } catch (e) {
          string += ",";
        }
      }
      resolve(string ? string.substr(0, string.length - 1) : string);
    });
  };

  exportData = async e => {
    e.preventDefault();
    let data = await this.props.getData();

    if (this.props.flatten) {
      data = await this.props.flatten(data);
    }

    let string = "";
    //let string = 'data:text/csv;charset=utf-8,'
    for (let h of this.state.headers) {
      string += `${h},`;
    }
    string = string ? string.substr(0, string.length - 1) : string;
    string += "\n";

    for (let a of data) {
      try {
        const temp = await this.processRow(a);
        string += temp;
        string += "\n";
      } catch (e) {
        console.log("The E", e);
      }
    }
    try {
      const csvData = new Blob([string], { type: "text/csv" });
      const blobUrl = URL.createObjectURL(csvData);
      window.open(blobUrl);
    } catch (e) {
      console.log("Error Creating CSV", e);
    }
  };

  render() {
    return (
      <div className="card p-t-none">
        <div>
          <div className="row staffDataContainer">
            <h3>Export {this.props.title}</h3>
          </div>
          <div className="row fieldSelection">
            <Multiselect
              data={this.props.exportFields}
              value={this.state.fields}
              textField="text"
              valueField="value"
              onChange={fields => {
                this.handleUpdate(fields);
              }}
              placeholder="Select Fields for CSV Export"
              className="testing"
            />
          </div>
          <div className="row staffDataContainer">
            <div className="col-md-1">
              <button
                className="btn btn-primary"
                onClick={e => this.exportData(e)}
                disabled={this.props.disabled}
              >
                Export to CSV
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ExportAppointmentsForm;
