import React, { Component } from "react";
import moment from "moment";
import { PromoCodeRowItem } from "../../../components";
class PartnerPromoTable extends Component {
  render() {
    return (
      <div className="promo-table">
        <div className="row table-headers">
          <div className="col-xs-2 header">Code</div>
          <div className="col-xs-1 header">Type</div>
          <div className="col-xs-3 header">Owner</div>
          <div className="col-xs-1 header"># Used</div>
          <div className="col-xs-2 header">Created At</div>
          <div className="col-xs-2 header">Expires At</div>
        </div>
        {this.props.promos && this.props.promos.length > 0 ? (
          this.props.promos.map(p => {
            return (
              <PromoCodeRowItem
                id={p._id}
                active={p.active}
                code={p.code_string}
                owner={this.props.partner.name}
                created_at={moment(p.createdAt).format("MM-DD-YYYY")}
                expiration_date={moment(p.expiration_date).format("MM-DD-YYYY")}
                type={p.type}
                num_used={
                  this.props.referrals ? this.props.referrals.length : 0
                }
                partner_id={this.props.partner._id}
                key={p._id}
                toggleActive={this.toggleActive}
              />
            );
          })
        ) : (
          <span>No Promos associated with this Partner</span>
        )}
      </div>
    );
  }
}

export default PartnerPromoTable;
