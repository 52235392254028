import React, { Component } from "react";
import { EditAutoRenewForm } from "../../components";
import DropdownList from "react-widgets/DropdownList";
import { countBy } from "lodash";

export default class CreditsDisplayCard extends Component {
  state = {
    unsaved: false,
    editing: false,
    hasFacilities: false,
    selectedFacility: null
  };

  componentDidMount = () => {
    if (this.props.client.facilities && this.props.client.facilities.length > 0 && this.props.type === "Daycare") {
      this.setState({ hasFacilities: true, selectedFacility: this.props.client.facilities[0] });
    }
  }

  componentWillReceiveProps = nextProps => {
    if (!this.state.unsaved) {
      this.setState({ prev_auto_renew: nextProps.auto_renew });
    }
  };

  setEditing = () => {
    this.setState({ editing: !this.state.editing });
  };

  renderAutoRenewForm = () => {
    return (
      <EditAutoRenewForm
        client={this.props.client}
        type={this.props.type}
        auto_renew={this.props.auto_renew}
        setAutoRenew={this.setAutoRenew}
        setEditing={this.setEditing}
        saveAutoRenew={this.saveAutoRenew}
      />
    );
  };

  setAutoRenew = (type, number) => {
    this.setState({ unsaved: true }, () => {
      this.props.setAutoRenew(type, number);
    });
  };

  saveAutoRenew = auto_renew => {
    this.setState({ unsaved: false, prev_auto_renew: auto_renew }, () => {
      this.props.saveAutoRenew();
    });
  };

  renderEditButton = () => {
    if (
      this.state.unsaved
        ? this.state.prev_auto_renew
        : this.props.auto_renew > 0
    ) {
      return (
        <i
          className="fa fa-refresh fa-2 fa-fw margin-bottom auto-renew-icon"
          onClick={() => this.setState({ editing: !this.state.editing })}
        />
      );
    } else {
      return (
        <button
          className="btn btn-success btn-primary"
          onClick={() => this.setState({ editing: !this.state.editing })}
        >
          Enable Auto Renew
        </button>
      );
    }
  };

  styles = {
    height: "100px"
  };

  withoutDropdown = () => {
    return (
      <div className="stats-container">
        <div className="row">
          <div className="card-text">
            Visits: <span>{this.props.count}</span>
          </div>
          <div className="card-text">
            Auto-Refill:{" "}
            <span>
              {this.props.auto_renew ? this.props.auto_renew : "N/A"}
            </span>
          </div>
        </div>
        <div className="row auto-renew" style={this.styles}>
          {this.state.editing
            ? this.renderAutoRenewForm()
            : this.renderEditButton()}
        </div>
      </div>
    );
  };

  withDropdown = () => {
    return (
      <div className="stats-container">
        <div className="row">
          <DropdownList
            data={this.props.client.facilities}
            placeholder="Facilities"
            value={this.state.selectedFacility}
            textField={"name"}
            onChange={item => {
              this.setState({ selectedFacility: item });
            }}
          />
          <div className="card-text">
            Credits: <span>{ this.state.selectedFacility ? this.getCredits() : null }</span>
          </div>
        </div>
      </div>
    ); 
  };

  getCredits = () => {
    let credit = this.props.credits
      .find(credit => credit.name === "day_care");
    
    let facility;

    if (credit) {
      facility = credit.facilities
        .find(facility => facility._id === this.state.selectedFacility._id);

      if (facility) {
        return facility.number;
      } 
    }
  };

  render() {
    return (
      <div className="col-sm-3 client-credits">
        <h2>{this.props.type}</h2>
        { this.state.hasFacilities && this.props.type === "Daycare" ? this.withDropdown() : this.withoutDropdown() }
      </div>
    );
  }
}
