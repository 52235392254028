import React, { Component } from "react";
import TextareaAutosize from "react-autosize-textarea";

export default class TextAreaField extends Component {

  static defaultProps = {
    required: false
  };

  state = {
    active: false
  };

  handleFocus = () => {
    this.setState({ active: true });
  };

  handleBlur = () => {
    this.setState({ active: false });
  };

  render() {
    const { active } = this.state;
    const { input, meta, placeholder, required, ...rest } = this.props;
    const { touched, error } = meta;

    const errorClass = touched && error ? " error-text error-border" : "";
    const focusClass = active ? " field-focus" : "";
    const hasValue = input.value && input.value.length > 0;

    let placeholderClass = "noselect form-label ta-label";
  
    placeholderClass += " form-label-not-empty";
    
    if (touched && error) {
      placeholderClass += " error-text";
    }

    return (
      <div
        className={`input-wrapper--form-control-wrapper-component${errorClass}${focusClass}`}
        onClick={() => (this.field ? this.field.focus() : null)}
        tabIndex="-1"
      >
        <label htmlFor={input.name} className={placeholderClass}>
          {placeholder}
        </label>

        <TextareaAutosize
          {...input}
          placeholder={`${
            required ? "*" : ""
          }`}
          style={{
            height: '38px'
          }}
          className={`form-control textarea${errorClass}${focusClass}`}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          ref={field => (this.field = field)}
          {...rest}
        />
      </div>
    );
  }
}
