import React, { Component } from "react";
import moment from "moment";
import PetCardCompact from "./PetCardCompact";
import { adminAppointmentService } from "../../../services";
import { catchFormServerErrors } from "../../../helpers";
import Multiselect from "react-widgets/Multiselect";
import {
  AssignmentPanel,
  SegmentedControl,
  UnassignedAppointmentsCol
} from "../../../components";
import HTML5Backend from "react-dnd-html5-backend";
import { DragDropContextProvider } from "react-dnd";
import Slot from "./schedulingTool/Slot";
import {
  applyMultiFilters,
  getFilterOptions
} from "../../../helpers/schedulerFilter";
import _ from "lodash";

class SchedulingTool extends Component {

  state = {
    // this is an appointment that has been selected but not yet provisionally placed
    selectedAppointment: null,

    // these are for appointments that have been provisionnally placed in cell but not yet confirmed
    transitionalAppointment: null,
    transitionalSitterId: null,
    transitionalHourSelected: null,
    approvalList: []
  };

  componentWillMount = () => {
    this.fetchAppointments();
  };

  approveAll = () => {
    const apps = [];
    this.props.staffList.forEach(s => {
      s.appointments.forEach(a => {
        if (
          a.appointment &&
          a.appointment.scheduled_start_time &&
          a.appointment.appointment_status.id === 1
        ) {
          let newA = _.cloneDeep(a.appointment);
          if (newA.sitter) {
            delete newA.sitter.appointments;
          }
          newA.sitter = { _id: s._id, full_name: s.full_name };
          apps.push(newA);
        }
      });
    });
    this.props.approveAllAppointments(apps);
  };

  saveAppts = () => {
    const apps = [];
    this.props.staffList.forEach(s => {
      s.appointments.forEach(a => {
        if (a.appointment && a.appointment.scheduled_start_time) {
          let newA = _.cloneDeep(a.appointment);

          if (newA.sitter) {
            delete newA.sitter.appointments;
          }
          newA.sitter = { _id: s._id, full_name: s.contact.full_name };
          apps.push(newA);
        }
      });
    });
    this.props.appointments.forEach(a => {
      if (a.scheduled_start_time && a.appointment_status.id === 1) {
        if (a.sitter) a.sitter = null;
        apps.push(a);
      }
    });
    this.props.updateMultipleAppointments(apps);
  };

  fetchAppointments = () => {
    const { getAppointments, selectedDate } = this.props;

    return getAppointments({
      startDate: moment(selectedDate)
        .startOf("day")
        .toDate(),
      endDate: moment(selectedDate)
        .endOf("day")
        .toDate()
    });
  };

  handleScheduleAppointment = values => {
    const { appointment_id, ...rest } = values;
    const model = adminAppointmentService.getAdminApproveOrScheduleAppointmentModel(
      rest
    );
    return this.props
      .scheduleAppointment(appointment_id, model)
      .then(res => catchFormServerErrors(res))
      .then(resp => this.props.approveAppointment(appointment_id, model))
      .then(() => this.fetchAppointments())
      .then(res => this.handleSelectAppointment());
  };

  renderAppointments = app => {
    return (
      <Slot
        key={app._id}
        appointment={app}
        onClickAppointmentDetail={this.props.onClickAppointmentDetail}
      />
    );
  };

  renderMessage = () => {
    return <div>No more pending appointments. Good job! </div>;
  };

  handleTransitionAppointment = () => {};
  handleSelectAppointment = () => {};

  render() {
    const filterDimensions = [
      "Appointment Status",
      "Client",
      "Zone",
      "Pet",
      "Pet Class",
      "Home Class",
      "Team Member",
      "Recurrence Type"
    ];
    const filteredAppointments = applyMultiFilters(
      this.props.appointments,
      this.props.calendarFilters
    );

    const {
      selectedAppointment,
      transitionalAppointment,
      transitionalHourSelected,
      transitionalSitterId
    } = this.state;

    const {
      appointments,
      assignedAppointments,
      calendarFilters,
      changeSchedulerView,
      onClickAppointmentDetail,
      onScheduleFail,
      onScheduleSuccess,
      getAppointmentsForSitter,
      schedulerView,
      selectedDate,
      selectedSitters,
      setCalendarFilters,
      setSelectedDate,
      setSelectedSitter,
      setUnassignedFilters,
      staffList,
      unassignedFilters
    } = this.props;

    return (
      <DragDropContextProvider backend={HTML5Backend}>
        <div className="row">
          <div className="flex-row scheduler-subnavbar">
            <SegmentedControl
              onChange={changeSchedulerView}
              options={[
                { name: "Scheduling Tool", key: "scheduling" },
                { name: "Calendar View", key: "calendar" }
              ]}
              selectedKey={schedulerView}
            />
            <div className="row approveAll">
              <div className="col-sm-6">
                <button
                  className="btn btn-block btn-primary"
                  onClick={this.saveAppts}
                >
                  Save
                </button>
              </div>
              <div className="col-sm-6">
                <button
                  className="btn btn-block btn-primary"
                  onClick={this.approveAll}
                >
                  Approve All
                </button>
              </div>
            </div>
          </div>

          {appointments ? (
            <div className="SchedulingTool-container">
              <div className="UnassignedAppointments col-lg-2 col-md-2 col-sm-12">
                {appointments && appointments.length
                  ? appointments
                      .filter(r => !r.sitter || !r.sitter.id)
                      .map(appt => this.renderAppointments(appt))
                  : this.renderMessage()}
              </div>
              <div className="AssignmentPanel col-lg-10 col-md-10 col-sm-12">
                <AssignmentPanel
                  addSitter={this.props.addSitter}
                  appointments={appointments}
                  assignedAppointments={assignedAppointments}
                  handleScheduleAppointment={this.handleScheduleAppointment}
                  onScheduleFail={onScheduleFail}
                  onScheduleSuccess={onScheduleSuccess}
                  handleSelectAppointment={this.handleSelectAppointment}
                  handleTransitionAppointment={this.handleTransitionAppointment}
                  onClickAppointmentDetail={onClickAppointmentDetail}
                  selectedAppointment={selectedAppointment}
                  selectedDate={selectedDate}
                  selectedSitters={selectedSitters}
                  setSelectedDate={setSelectedDate}
                  setSelectedSitter={setSelectedSitter}
                  getAppointmentsForSitter={getAppointmentsForSitter}
                  staffList={staffList}
                  transitionalAppointment={transitionalAppointment}
                  transitionalHourSelected={transitionalHourSelected}
                  transitionalSitterId={transitionalSitterId}
                />
              </div>
            </div>
          ) : null}
        </div>
      </DragDropContextProvider>
    );
  }
}

export default SchedulingTool;
