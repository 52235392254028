import React, { Component } from "react";
import moment from "moment";
import { DatePicker } from "react-widgets";

class DateSwitcher extends Component {
  state = {
    picker: false
  };

  render() {
    let { picker } = this.state;
    let {
      dateFormat,
      max,
      min,
      onDateBack,
      onDateForward,
      selectedDate,
      showPicker,
      selectDate
    } = this.props;
    
    const backDisabled = min ? min.isSame(selectedDate, "day") : false;
    const forwardDisabled = max ? max.isSame(selectedDate, "day") : false;
    
    return (
      <div className="date-switcher">
        {picker && selectDate ? (
          <div className="col-sm-4">
            <DatePicker
              defaultValue={selectedDate.toDate()}
              onChange={e => selectDate(e)}
            />
          </div>
        ) : null}

        <div className="pagination">
          <div
            className={`btn btn-secondary pagination`}
            onClick={() => {
              // if (!backDisabled) {
              onDateBack();
              // }
            }}
          >
            <span className="fa fa-chevron-left" />
          </div>
        </div>

        <h1 onClick={() => this.setState({ picker: !this.state.picker })}>
          {selectedDate.format(dateFormat)}
        </h1>

        <div className="pagination">
          <div
            className={`btn btn-secondary pagination`}
            onClick={() => {
              // if (!forwardDisabled) {
              onDateForward();
              // }
            }}
          >
            <span className="fa fa-chevron-right" />
          </div>
        </div>
      </div>
    );
  }
}

DateSwitcher.defaultProps = {
  dateFormat: "dddd, MMMM D, YYYY"
};

export default DateSwitcher;
