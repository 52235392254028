import React, { Component } from "react";
import { DropdownList } from "react-widgets";
import { ZoneBadge } from "../../../";
import moment from "moment";
import Slot from "./Slot";
import ScheduledSlot from "./ScheduledSlot";
import { DropTarget } from "react-dnd";
import { connect } from "react-redux";
import {
  setAppointment,
  resetAppointment
} from "../../../../actions/admin/scheduler.actions";
import PetCardCondensed from "../PetCardCondensed";

const squareTarget = {
  drop(props, monitor, component) {
    const item = monitor.getItem();
    const app = item.appointment;

    app.scheduled_start_time = component.props.time.toString();
    app.sitter = props.sitter;
    component.dispatchProps.setAppointment(app);
    if (props.appointment && props.appointment._id !== item.appointment._id) {
      component.dispatchProps.resetAppointment(props.appointment);
    }
    return props;
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };
}

function getDiff(a, b) {
  let timeA = moment(a);
  let timeB = moment(b);
  let diff = timeA.diff(timeB, "minutes");
  return diff;
}

class DropZone extends Component {
  state = {
    appointment: null
  };
  renderAppointments = () => {
    return (
      <ScheduledSlot appointment={this.props.appointment}>
        <PetCardCondensed
          appointment={this.props.appointment}
          resetAppointment={this.props.resetAppointment}
          onClickAppointmentDetail={this.props.onClickAppointmentDetail}
        />
      </ScheduledSlot>
    );
  };

  renderTime = () => {
    return (
      <div className="time-slot">{moment(this.props.time).format("hh:mm")}</div>
    );
  };

  render() {
    const { x, y, connectDropTarget, isOver, canDrop, children } = this.props;
    return connectDropTarget(
      <div className="staff-block">
        {this.props.appointment ? this.renderAppointments() : this.renderTime()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = dispatch => ({
  setAppointment: appointment => dispatch(setAppointment(appointment)),
  resetAppointment: appointment => dispatch(resetAppointment(appointment))
});

export default DropTarget("card", squareTarget, collect)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DropZone)
);
