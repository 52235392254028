import { APIService } from "../../services";
import { dispatchFetchFailure } from "../helpers";
import queryString from "query-string";

export function getDashboardStats(filters = {}) {
  const qs = queryString.stringify(filters);
  const context = "getDashboardStats";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .getDashboardStats(qs)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch({ type: "ADMIN_GET_DASHBOARD_STATS", payload: response });
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function createLineItem(model) {
  const context = "createLineItem";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .createLineItem(model)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
        },
        error => error.message.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function reviewLineItems() {
  const context = "getLineItems";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .reviewLineItems()
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch({
            type: "ADMIN_GET_LINE_ITEMS_SUCCESS",
            payload: {
              lineItems: response
            }
          });
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export function getStripeCharges() {
  const context = "getStripeCharges";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .getStripeCharges()
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch({
            type: "ADMIN_GET_STRIPE_CHARGES_SUCCESS",
            payload: {
              stripeCharges: response
            }
          });
        },
        error => {
          return error;
        }
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}
