import React, { Component } from "react";
import moment from "moment";
import PetCardXtraCondensed from "../../../../components/admin/scheduler/PetCardXtraCondensed";

export default class CalendarWeek extends Component {

  renderDayAppointments = (apps, visits) => {
    let appts = [];
    let facility_visits = [];

    if (apps && apps.length) {
      appts = apps
        .filter(app => {
          if (!this.props.show_canceled) {
            return app.appointment_status && app.appointment_status.id !== 7;
          }
          return app;
        });
    }

    if (visits && visits.length) {
      facility_visits = visits
        .filter(visit => {
          if (!this.props.show_canceled) {
            return visit.status && visit.status.id !== 2;
          }
          return visit;
        });
    }

    let all_appts = [...appts, ...facility_visits].sort((a, b) => {
      let a_start = a.window_start_time || a.startDate;
      let b_start = b.window_start_time || b.startDate;
      
      return moment(a_start) - moment(b_start);
    });

    if (all_appts && all_appts.length) {
      return all_appts.map(appt => (
          <PetCardXtraCondensed
            key={`pet-card-xtra-condensed-appt-id-${appt._id || appt.id }`}
            appointment={appt}
          />
        ));
    }
    return null;
  };

  render() {
    const { firstDayOfWeek, month, appointments, visits } = this.props;
    let date = firstDayOfWeek.clone();

    const days = [];

    for (let i = 0; i < 7; i++) {
      const day = {
        name: date.format("dd").substring(0, 1),
        number: date.date(),
        isCurrentMonth: date.month() === month.month(),
        isToday: date.isSame(new Date(), "day"),
        isBefore: date.isBefore(moment().startOf("day")),
        date: date
      };

      const dayInstance = day.date.clone();

      let apps;
      let facility_visits;

      if ((appointments && appointments.length) || (visits && visits.length)) {
        apps = [...appointments];
        facility_visits = [ ...visits];

        apps = apps.filter(appt => {
          let startTime = moment(appt.window_start_time).add(30, "minutes");
          return startTime.isSame(day.date, "day");
        });

        facility_visits = facility_visits.filter(visit => {
          let startDate = moment(visit.startDate);
          return startDate.isSame(day.date, "day");
        });
      }

      days.push(
        <div
          key={day.date.toString()}
          className={`day-block${day.isSelected ? " selected-day" : ""}${
            day.isDisabled || day.isBefore ? " disabled-day" : ""
          } ${apps && apps.length ? "day-appointments" : ""} ${
            this.props.showMonth ? "" : "day-showmonth"
          }`}
        >
          <div className="date-row">
            <p
              className={`${day.isCurrentMonth ? "active" : "inactive"}${
                day.isToday ? " today" : ""
              }`}
            >
              {day.number}
            </p>
          </div>
          {this.renderDayAppointments(apps, facility_visits)}
        </div>
      );
      date.add(1, "d");
    }

    return <div className="calendar-row-month">{days}</div>;
  }
}
