import React, { Component } from "react";
import moment from "moment";
import { schedulerRows } from "../../../../config/scheduler";
import { PetCardCondensed } from "../../../";

export default class CalendarWeekView extends Component {

  componentWillMount = () => {
    const { calendarWeek } = this.props;
    this.getAppointments(calendarWeek);
  };

  getAppointments = week => {
    const { getAppointments } = this.props;
    const start = week
      .clone()
      .startOf("week")
      .toDate();
    const end = week
      .clone()
      .endOf("week")
      .toDate();
    getAppointments({ start, end });
  };

  previous = () => {
    const { calendarWeek, setCalendarWeek } = this.props;
    const week = calendarWeek.clone().subtract(1, "week");
    setCalendarWeek(week);
    this.getAppointments(week);
  };

  next = () => {
    const { calendarWeek, setCalendarWeek } = this.props;
    const week = calendarWeek.clone().add(1, "week");
    setCalendarWeek(week);
    this.getAppointments(week);
  };

  renderDayHeaders = () => {
    const { calendarWeek } = this.props;
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = calendarWeek.clone().day(i);
      dates.push(date);
    }

    return (
      <div className="calendar-header-week">
        <div className="spacer">
          <div className="pagination">
            <div
              className="btn btn-secondary pagination"
              onClick={this.previous}
            >
              <span className="fa fa-chevron-left" />
            </div>
          </div>
        </div>

        {dates.map(date => (
          <div
            key={`calendar-header-week-${date.format("ddd-M/D")}`}
            className={`day-wrapper${
              date.isSame(moment(), "day") ? " current-day" : ""
            }`}
          >
            <h4>{date.format("ddd M/D")}</h4>
          </div>
        ))}

        <div className="spacer">
          <div className="pagination">
            <div className="btn btn-secondary pagination" onClick={this.next}>
              <span className="fa fa-chevron-right" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderCalendarRows = () => {
    return schedulerRows.map((row, index) => (
      <div
        key={`scheduler-row-week-${row.label}-${index}`}
        className="calendar-row-week"
      >
        <div className="time-block">
          <p>{row.label}</p>
        </div>
        {this.renderWeekSlots(row.hours)}
        <div className="spacer"></div>
      </div>
    ));
  };

  renderWeekSlots = timeSlotHours => {
    const { calendarWeek } = this.props;
    let date = calendarWeek.clone().startOf("week");

    const daySlots = [];

    for (let i = 0; i < 7; i++) {
      const daySlot = {
        name: date.format("dd").substring(0, 1),
        number: date.date(),
        isToday: date.isSame(new Date(), "day"),
        date: date
      };

      daySlots.push(
        <div
          key={daySlot.date.toString()}
          className={`day-slot${daySlot.isToday ? " current-day" : ""}`}
        >
          {this.renderSlotAppointments(timeSlotHours, daySlot.date)}
        </div>
      );

      date.add(1, "day");
    }
    return daySlots;
  };

  renderSlotAppointments = (timeSlotHours, timeSlotDate) => {
    const { appointments, onClickAppointmentCard } = this.props;
    return appointments
      .filter(appt => {
        let startTime = moment(appt.window_start_time).add(30, "minutes");
        if (appt.scheduled_start_time) {
          startTime = moment(appt.scheduled_start_time);
        }
        const apptInSameHour = timeSlotHours.includes(startTime.hour());
        const apptOnSameDay = startTime.isSame(timeSlotDate, "day");
        return apptInSameHour && apptOnSameDay;
      })
      .map(appt => (
        <PetCardCondensed
          key={`pet-card-condensed-appt-id-${appt.id}`}
          appointment={appt}
          onClickAppointmentCard={onClickAppointmentCard}
        />
      ));
  };

  render() {
    return (
      <div>
        {this.renderDayHeaders()}
        {this.renderCalendarRows()}
      </div>
    );
  }
}
