export const schedulerRows = [
  { label: "...", hours: [0, 1, 2, 3, 4, 5, 6, 7, 8] },
  { label: "9AM", hours: [9] },
  { label: "10AM", hours: [10] },
  { label: "11AM", hours: [11] },
  { label: "Noon", hours: [12] },
  { label: "1PM", hours: [13] },
  { label: "2PM", hours: [14] },
  { label: "3PM", hours: [15] },
  { label: "4PM", hours: [16] },
  { label: "5PM", hours: [17] },
  { label: "...", hours: [18, 19, 20, 21, 22, 23] }
];

export const clientAppointmentTimeSlots = [
  "10:00am",
  "10:15am",
  "10:30am",
  "10:45am",
  "11:00am",
  "11:15am",
  "11:30am",
  "11:45am",
  "12:00pm",
  "12:15pm",
  "12:30pm",
  "12:45pm",
  "1:00pm",
  "1:15pm",
  "1:30pm",
  "1:45pm",
  "2:00pm",
  "2:15pm",
  "2:30pm",
  "2:45pm",
  "3:00pm",
  "3:15pm",
  "3:30pm",
  "3:45pm",
  "4:00pm",
  "4:15pm",
  "4:30pm",
  "4:45pm",
  "5:00pm"
];
