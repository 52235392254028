import React, { Component } from "react";
import { Link } from "react-router-dom";
import Tooltip from "rc-tooltip";

class ForecastRowItem extends Component {
  state = {
    expanded: false
  };

  getPrediction = p => {
    return p.toFixed(2);
  };

  renderSubtable = () => {
    return (
      <div
        className="sub-table"
        style={{ height: this.state.expanded ? "340px" : "0px" }}
      >
        <div className="child-header">
          <div>Hour</div>
          <div>Predicted</div>
          <div>10%</div>
          <div>25%</div>
          <div>50%</div>
          <div>75%</div>
          <div>90%</div>
          {this.props.day_rmse ? <div>Actual</div> : null}
        </div>
        <div className="table-body">
          {this.props.values
            .sort((a, b) => a["dt"] - b["dt"])
            .map(v => {
              return (
                <div className="child-row">
                  <div>{v.hour}</div>
                  <div>{this.getPrediction(v.mean)}</div>
                  <div>{this.getPrediction(v[10])}</div>
                  <div>{this.getPrediction(v[25])}</div>
                  <div>{this.getPrediction(v[50])}</div>
                  <div>{this.getPrediction(v[75])}</div>
                  <div>{this.getPrediction(v[90])}</div>
                  {this.props.day_rmse ? <div>{v.actual}</div> : null}
                </div>
              );
            })}
        </div>
      </div>
    );
  };
  render() {
    return (
      <div className="row-wrapper">
        <div className="parent-row">
          <div>{/* just here to trick grid :) */}</div>
          <div className="cells">
            <div>{this.props.date}</div>
            <div>{this.getPrediction(this.props.total_predicted)}</div>
            <div>{this.props.actual_visits}</div>
            <div>
              <div>{/* tricking flexbox ? */}</div>
              <div>
                {this.props.day_rmse ? this.props.day_rmse.toFixed(2) : 0}
              </div>
            </div>
          </div>
          <div class="actions">
            <i
              className={
                this.state.expanded
                  ? "fa fa-caret-square-o-up"
                  : "fa fa-caret-square-o-down"
              }
              onClick={() => this.setState({ expanded: !this.state.expanded })}
            />
          </div>
        </div>
        {this.state.expanded ? this.renderSubtable() : null}
      </div>
    );
  }
}

export default ForecastRowItem;
