import React, { Component } from "react";
import moment from "moment";
import { schedulerRows } from "../../../../config/scheduler";
import { DateSwitcher, PetCard } from "../../../";

export default class CalendarDayView extends Component {

  componentWillMount = () => {
    const { calendarDay } = this.props;
    this.getAppointments(calendarDay);
  };

  getAppointments = day => {
    const { getAppointments } = this.props;
    const start = day
      .clone()
      .startOf("day")
      .toDate();
    const end = day
      .clone()
      .endOf("day")
      .toDate();
    getAppointments({ start, end });
  };

  previous = () => {
    const { calendarDay, setCalendarDay } = this.props;
    const day = calendarDay.clone().subtract(1, "day");
    setCalendarDay(day);
    this.getAppointments(day);
  };

  next = () => {
    const { calendarDay, setCalendarDay } = this.props;
    const day = calendarDay.clone().add(1, "day");
    setCalendarDay(day);
    this.getAppointments(day);
  };

  renderCalendarRows = () => {
    return schedulerRows.map((row, index) => (
      <div
        key={`scheduler-row-day-${row.label}-${index}`}
        className="calendar-row-day"
      >
        <div className="time-block">
          <p>{row.label}</p>
        </div>
        <div className="time-slot">
          {this.renderSlotAppointments(row.hours)}
        </div>
      </div>
    ));
  };

  renderSlotAppointments = timeSlotHours => {
    const { appointments, calendarDay, onClickAppointmentDetail } = this.props;
    return appointments
      .filter(appt => {
        let startTime = moment(appt.window_start_time).add(30, "minutes");
        if (appt.scheduled_start_time) {
          startTime = moment(appt.scheduled_start_time);
        }
        const apptInSameHour = timeSlotHours.includes(startTime.hour());
        const apptOnSameDay = startTime.isSame(calendarDay, "day");
        return apptInSameHour && apptOnSameDay;
      })
      .map(appt => (
        <PetCard
          key={`pet-card-appt-id-${appt.id}`}
          appointment={appt}
          onClickAppointmentDetail={onClickAppointmentDetail}
        />
      ));
  };

  render() {
    const { calendarDay } = this.props;
    return (
      <div>
        <div className="calendar-header-day">
          <DateSwitcher
            onDateBack={this.previous}
            onDateForward={this.next}
            selectedDate={calendarDay}
          />
        </div>
        {this.renderCalendarRows()}
      </div>
    );
  }
}
