const baseURL = process.env.REACT_APP_API_URI;
import axios from "./network";

const handleErrors = response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  // if (response.status === 401) logout();
  const error = new Error(`${response.status} ${response.statusText}`);
  error.response = response;
  throw error;
};

// JSON
export const getJSON = endpoint =>
  axios
    .get(`${baseURL}${endpoint}`)
    .then(handleErrors)
    .then(res => res.data);

export const postJSON = (endpoint, body) =>
  axios
    .post(`${baseURL}${endpoint}`, body)
    .then(handleErrors)
    .then(res => res.data);

export const putJSON = (endpoint, body) =>
  axios
    .put(`${baseURL}${endpoint}`, body)
    .then(handleErrors)
    .then(res => res.data);

export const deleteJSON = (endpoint, body) =>
  axios
    .delete(`${baseURL}${endpoint}`, body)
    .then(handleErrors)
    .then(res => res.data);

// FORM DATA
export const postFormData = (endpoint, body) =>
  axios(`${baseURL}${endpoint}`, {
    method: "POST",
    config: { headers: { "Content-Type": "multipart/form-data" } },
    data: body
  })
    .then(handleErrors)
    .then(res => res.data);

export const putFormData = (endpoint, body) =>
  axios(`${baseURL}${endpoint}`, {
    method: "PUT",
    config: { headers: { "Content-Type": "multipart/form-data" } },
    data: body
  })
    .then(handleErrors)
    .then(res => res.data);
