import React, { Component } from "react";

export default class FormControlWrapper extends Component {
  static defaultProps = {
    isError: false
  };

  handleClick = e => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  render() {
    const { children, isError } = this.props;

    return (
      <div
        className={`${
          isError ? "error-border " : ""
        }input-wrapper--form-control-wrapper-component`}
        onClick={this.handleClick}
      >
        {children}
      </div>
    );
  }
}
