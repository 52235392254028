import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

export default class PetCardXtraCondensed extends Component {

  static defaultProps = {
    handleSelectAppointment: () => {}
  };

  render() {
    const { appointment, onClickAppointmentCard } = this.props;
    const {
      pets,
      appointment_status,
      status,
      _id,
      window_start_time,
      window_end_time,
      startDate,
      endDate,
      appointment_type,
      type,
      sitter
    } = appointment;

    let appt_status = appointment_status || status; 
    appt_status = appt_status.name.toLowerCase().replace(" ", "-");

    let startTime = window_start_time || startDate;
    let endTime = window_end_time || endDate;
    
    startTime = moment(startTime);
    endTime = moment(endTime);

    let card;

    card = sitter ? 
      <Link to={`/appointments/${_id}`}>
        <div className={`pet-card-xtra-condensed ${appt_status}`}>
          {appointment_type ? (
            <p>
              <span className="cal-label">Service: </span>
              {appointment_type.name}
            </p>
          ) : null}
          <p>
            <span className="cal-label">Window: </span>
            {`${startTime.format("h:mma")} - ${endTime.format("h:mma")}`}
          </p>
          {sitter && sitter.full_name ? (
            <p>
              <span className="cal-label">Sitter: </span>
              {`${sitter.full_name}`}
            </p>
          ) : null}
        </div>
      </Link>
      :
      <div className={`pet-card-xtra-condensed ${appt_status}`}>
        {type ? (
          <p>
            <span className="cal-label">Service: </span>
            {type ? type.toUpperCase() : null} - PETCARE
          </p>
        ) : null}
        <p>
          <span className="cal-label">Dates: </span>
          {`${startTime.format("MM/DD/YYYY")} - ${endTime.format("MM/DD/YYYY")}`}
        </p>
      </div>;

      return (card);
  }
}
