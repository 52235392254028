import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import admin from "./admin";
import general from "./general";

const rootReducer = combineReducers({
  admin,
  form: formReducer,
  general
});

export default rootReducer;
export { getIsFetching } from "./general";
