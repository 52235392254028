import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { FormSection } from "./formComponents";
import { formatNumsToDollars } from "../../helpers";

const WalkCredits = ({ credits }) => {
  return (
    <FormSection>
      <div className="row">
        <div className="message-charges">
          {credits.map(item => (
            <div
              className="message-charge-item"
              key={`transaction-${item._id}`}
            >
              <div className="col-sm-4">
                Created: {moment(item.createdAt).format("MMM DD YY")} <br />
                Updated: {moment(item.updatedAt).format("MMM DD YY HH:mm a")}
              </div>
              <div className="message-charge-item-description col-sm-6">
                {item.type === 11 ? "Playcare" : `${item.type} Minute`}
                Walk Credit used on{" "}
                {moment(item.date_used).format("dddd, MMMM DD, YYYY")}
              </div>
              <div className="message-charge-item-amount col-sm-2">-1</div>
            </div>
          ))}
        </div>
      </div>
    </FormSection>
  );
};

export default WalkCredits;
