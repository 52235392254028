import React, { Component } from "react";
import { Link } from "react-router-dom";

class PartnerRowItem extends Component {
  render() {
    return (
      <Link
        to={
          this.props.type === "Other"
            ? `/editpartner/${this.props.id}`
            : `/editapartment/${this.props.apartment_id}`
        }
      >
        <div className="row table-row">
          <div className="col-xs-3 cell">{this.props.name}</div>
          <div className="col-xs-3 cell">{this.props.type}</div>
          <div className="col-xs-3 cell">{this.props.promos.length}</div>
          <div className="col-xs-3 cell">{this.props.referrals.length}</div>
        </div>
      </Link>
    );
  }
}

export default PartnerRowItem;
