import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getIsFetching } from "../../reducers";
import { adminAppointmentService } from "../../services";
import { catchFormServerErrors } from "../../helpers";

// Actions
import { createAppointment as createAppointmentAction } from "../../actions/admin/appointments.actions";
import { getClient as getClientAction } from "../../actions/admin/clients.actions";
import { getAllStaff } from "../../actions/admin/staff.actions";
import {
  pushModal,
  popModal,
  showFormErrorMessage,
  showSuccessMessage
} from "../../actions/general.actions";

// Child components
import { FormSubHeader } from "../../components/shared/formComponents";
import {
  AdminScheduleAppointmentForm,
  PageTitleComponent,
  ZoneBadge
} from "../../components";
import axios from "../../services/network.js";

class AdminScheduleAppointmentPage extends Component {
  state = {
    addOnServices: [],
    vacation_days: []
  };


  componentWillReceiveProps(nextProps) {
    let { getStaffList } = this.props;
    if (
      !this.props.clientInfo &&
      nextProps.clientInfo &&
      nextProps.clientInfo.market_id
    )
      getStaffList({ city: nextProps.clientInfo.market_id });
  }

  componentWillMount = async () => {
    console.log('here maybe...')
    try {
      const { clientInfo, getClientInfo, getStaffList, match } = this.props;

      console.log('inof..', clientInfo)
      // Only get the client info if you don't already have the right client info in the store
      // if (
      //   !clientInfo ||
      //   clientInfo._id.toString() !== match.params.clientId.toString()
      // ) {
        console.log('getting info')
        getClientInfo(match.params.clientId);
        console.log('yay')
      // }
  
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URI}/admin/vacation`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken")
          }
        }
      );
      this.setState({ vacation_days: data });

      console.log('made it past all this')

    } catch(e) {
      console.log('e....', e)
    }
  };

  handleCreateAppointment = values => {
    const {
      createAppointment,
      match,
      clientInfo,
      displayFormError
    } = this.props;

    const model = adminAppointmentService.getAdminCreateAppointmentModel(
      values,
      match.params.clientId,
      clientInfo
    );
    model.market_id = clientInfo.market_id;
    return (
      createAppointment(model)
        // Return server errors to form to display field-level errors and prevent success message
        .then(res => catchFormServerErrors(res))
    );
  };

  onSubmitSuccess = () => {
    // If form detects successful submission, display success message
    this.props.displayFormSuccess("The appointment has been scheduled.");
  };

  render() {
    const {
      clientInfo,
      displayFormError,
      formConstants,
      isFetching,
      modalActions,
      staffList
    } = this.props;
    // render spinner if we're fetching client data

    console.log(isFetching, clientInfo)

    if (isFetching || !clientInfo) {
      return <div />;
    }

    return (
      <div className="card schedule-appointment-page">
        <PageTitleComponent title="Schedule an Appointment" />
        <div className="container form-container">
          <div className="row">
            <h3 className="col-sm-12 title">Client Information</h3>
            <div className="col-sm-6 col-md-6">
              <FormSubHeader>Address/Zone Information</FormSubHeader>
              <ul className="no-decoration">
                <li>
                  <Link to={`/client/${clientInfo._id}`}>
                    {clientInfo.contact.full_name}
                  </Link>
                </li>
                <li>
                  {clientInfo.home.address.address_line_1}{" "}
                  <div className="inline-zone-badge">
                    {clientInfo.zones.map(zone => (
                      <ZoneBadge
                        size="tiny"
                        zone={zone}
                        key={`${clientInfo._id}${zone.id}`}
                      />
                    ))}
                  </div>
                </li>
                {clientInfo.home.address.address_line_2 ? (
                  <li>{clientInfo.home.address.address_line_2}</li>
                ) : null}
                <li>{`${clientInfo.home.address.city}, ${clientInfo.home.address.state.name} ${clientInfo.home.address.zip_code}`}</li>
                {!clientInfo.home.address.enforce_device_within_region ? (
                  <li>
                    <span className="fa fa-location-arrow" /> Geolocation not
                    enforced
                  </li>
                ) : null}
              </ul>
            </div>

            <div className="col-sm-6 col-md-6">
              <FormSubHeader>Admin/Home Notes</FormSubHeader>
              <ul className="no-decoration">
                <li>{clientInfo.notes}</li>
                {clientInfo.home &&
                clientInfo.home.home_class &&
                clientInfo.home.home_class.name ? (
                  <li>
                    <span
                      className={`fa fa-home ${
                        clientInfo &&
                        clientInfo.home &&
                        clientInfo.home.home_class &&
                        clientInfo.home.home_class.name
                          ? clientInfo.home.home_class.name.toLowerCase()
                          : ""
                      }`}
                    >
                      {" "}
                      {clientInfo.home.home_class_notes
                        ? clientInfo.home.home_class_notes
                        : "No Notes"}
                    </span>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
        </div>

        <AdminScheduleAppointmentForm
          clientInfo={clientInfo}
          formConstants={formConstants}
          handleAddService={this.handleAddService}
          initialValues={{
            appointment_status_id: 1,
            pet_ids: clientInfo.pets
              .filter(pet => pet.active)
              .map(pet => pet._id)
          }}
          modalActions={modalActions}
          onSubmit={this.handleCreateAppointment}
          onSubmitFail={errors => displayFormError(errors)}
          onSubmitSuccess={this.onSubmitSuccess}
          staffList={staffList}
          vacation_days={this.state.vacation_days}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  clientInfo: state.admin.clients.client,
  formConstants: state.general.formConstants,
  isFetching: getIsFetching(state, "getClient"),
  // If staffList is not null, return array with only fullName and sitter id.
  staffList:
    state.admin.staff.staffList &&
    state.admin.staff.staffList.map(sitter => ({
      fullName: `${sitter.contact.full_name}`,
      _id: sitter._id
    }))
});

const mapDispatchToProps = dispatch => ({
  displayFormError: errors =>
    dispatch(showFormErrorMessage(errors, "adminAppointmentDetails")),
  displayFormSuccess: message =>
    dispatch(showSuccessMessage(message, "adminAppointmentDetails")),
  getClientInfo: clientId => dispatch(getClientAction(clientId)),
  getStaffList: qs => dispatch(getAllStaff(qs)),
  modalActions: {
    push: component => dispatch(pushModal(component)),
    pop: () => dispatch(popModal())
  },
  createAppointment: (clientId, model) =>
    dispatch(createAppointmentAction(clientId, model))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminScheduleAppointmentPage);
