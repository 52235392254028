import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { ColorField, InputField } from "../shared/formComponents";

class ZoneSettingsEditRow extends Component {

  handleKeyDown = (e, cb) => {
    if (e.key === "Enter" && e.shiftKey === false) {
      e.preventDefault();
      cb();
    }
  };

  render() {
    const { handleDelete, handleSubmit, zone } = this.props;
    return (
      <form
        onSubmit={handleSubmit}
        onKeyDown={e => this.handleKeyDown(e, handleSubmit)}
      >
        <div className="zone-row-details-header">Edit Zone Information</div>
        <div className="zone-form">
          <div className="zone-inputs">
            <div className="zone-input">
              <Field
                name="zone_name"
                component={InputField}
                placeholder="Zone Name"
                type="text"
                required
              />
            </div>

            <div className="zone-input color-fields">
              <Field
                name="background_color"
                component={ColorField}
                placeholder="Circle Color"
              />

              <Field
                name="text_color"
                component={ColorField}
                placeholder="Text Color"
              />
            </div>
          </div>

          <div className="zone-buttons">
            {zone ? (
              <button
                className="btn btn-block btn-secondary"
                onClick={e => handleDelete(e, zone.id)}
              >
                Delete
              </button>
            ) : null}
            <input
              type="submit"
              className="btn btn-block btn-primary"
              value="Save"
            />
          </div>
        </div>
      </form>
    );
  }
}

const validate = values => {
  const errors = {};
  // if (!values.scheduledTime) errors.scheduledTime = true;
  // if (!values.assignedSitter) errors.assignedSitter = true;
  return errors;
};

export default reduxForm({ validate })(ZoneSettingsEditRow);
