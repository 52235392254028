import React, { Component } from "react";
import DropdownList from "react-widgets/DropdownList";
import "react-widgets/styles.css";

export default class DropDownListField extends Component {

  state = {
    open: false
  };

  handleBlur = () => {
    // Only run dropdown's onBlur if what we end up clicking on is not the wrapper div.
    // This prevents the dropdown from triggering both the handleBlur() and toggleDropdown().
    if (document.activeElement !== this.wrapper) {
      this.setState({ open: false });
    }
  };

  toggleDropdown = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  render = () => {
    const { open } = this.state;
    // eslint-disable-next-line
    const {
      input,
      hidePlaceholderOnTop,
      meta,
      options,
      placeholder,
      required,
      ...rest
    } = this.props;
    const { touched, error } = meta;

    // TODO: Set up error styles.
    const wrapperClass = touched && error ? " error-text error-border" : "";
    const focusClass = open ? " field-focus" : "";
    const errorClass = touched && error ? "error-border" : null;
    const hasValue = input.value !== "";

    // Build class name string for placholder
    let placeholderClass = "noselect form-label";
    
    placeholderClass += " form-label-not-empty";
    
    if (touched && error) {
      placeholderClass += " error-text";
    }

    return (
      <div
        className={`input-wrapper--form-control-wrapper-component focus-fix${wrapperClass}`}
        tabIndex="-1"
        onClick={() => this.toggleDropdown()}
        ref={element => (this.wrapper = element)}
      >
        <div className={`field-focus-wrapper${focusClass}`} />
        {hidePlaceholderOnTop ? null : (
          <label htmlFor={input.name} className={placeholderClass}>
            {placeholder}
          </label>
        )}

        <DropdownList
          {...input}
          value={input.value}
          name={input.name}
          className={errorClass}
          onBlur={this.handleBlur}
          onToggle={() => {}}
          open={open}
          placeholder={`${placeholder} ${required ? "*" : ""}`}
          disabled={this.props.disabled}
          {...rest}
        />
      </div>
    );
  };
}
