import React, { Component } from "react";
import axios from "../../services/network.js";
import { connect } from "react-redux";
import _ from "lodash";
import { CardComponent, PriceForm } from "../../components";

class EditPrices extends Component {
  state = {
    prices: null,
    editing: false,
    previous_prices: null
  };

  componentWillMount = () => {
    this.fetchData(this.props.market._id);
  };

  componentWillReceiveProps = nextProps => {
    if (
      this.props.market &&
      this.props.market._id &&
      nextProps.market._id !== this.props.market._id
    ) {
      this.fetchData(nextProps.market._id);
    }
  };

  fetchData = async market_id => {
    const { fetchRequest, fetchSuccess } = this.props;
    fetchRequest();
    if (market_id) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URI}/admin/prices/${market_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );
      if (data) {
        this.setState({ prices: data, previous_prices: null, editing: false });
      } else {
        this.setState({
          prices: {
            market_id: market_id,
            20: {
              1: 0,
              5: 0,
              15: 0
            },
            40: {
              1: 0,
              5: 0,
              15: 0
            },
            60: {
              1: 0,
              5: 0,
              15: 0
            },
            11: {
              1: 0,
              5: 0,
              15: 0
            }
          }
        });
      }
    }
    fetchSuccess();
  };

  updatePrice = (duration, quantity, price) => {
    let clone = _.cloneDeep(this.state.prices);
    clone[duration][quantity] = price;

    this.setState({ prices: clone });
  };

  savePrices = async () => {
    const {
      fetchRequest,
      fetchSuccess,
      showMessage,
      removeMessage
    } = this.props;
    fetchRequest();
    if (this.state.prices) {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URI}/admin/prices/${this.props.market._id}`,
        this.state.prices,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );
      if (res && res.status === 200) {
        showMessage({
          message: "Successfully Updated Prices",
          messageType: "success"
        });
        setTimeout(removeMessage, 5000);
      } else {
        showMessage({
          message: "Unable to Update Prices",
          messageType: "error"
        });
        setTimeout(removeMessage, 5000);
      }
    } else {
      showMessage({
        message: "No Price Data Found",
        messageType: "error"
      });
      setTimeout(removeMessage, 5000);
    }
    this.fetchData(this.props.market._id);
    fetchSuccess();
  };

  toggleEditing = () => {
    if (this.state.editing) {
      this.setState({
        prices: this.state.previous_prices,
        previous_prices: null,
        editing: !this.state.editing
      });
    } else {
      const copy = _.cloneDeep(this.state.prices);
      this.setState({
        previous_prices: copy,
        editing: !this.state.editing
      });
    }
    this.setState({ editing: !this.state.editing });
  };

  render() {
    const { market } = this.props;
    const lengths = [20, 40, 60, 11];
    if (market._id === "ALL") {
      return (
        <CardComponent title="Error">
          <div className="row">
            <div className="col-md-12 text-center">
              <i
                className="fa fa-exclamation-triangle fa-5x"
                aria-hidden="true"
              ></i>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              You must select a specific market to view and update prices...
            </div>
          </div>
        </CardComponent>
      );
    } else {
      return (
        <CardComponent title={`${market ? market.city : null} Pricing`}>
          <div className="pricing-wrapper">
            <div className="row">
              {lengths.map(l => {
                if (this.state.prices) {
                  return (
                    <div className="col-md-3">
                      <PriceForm
                        updatePrice={this.updatePrice}
                        data={this.state.prices[l.toString()]}
                        duration={l}
                        editing={this.state.editing}
                      />
                    </div>
                  );
                }
                return null;
              })}
            </div>
            <div className="row actions">
              {this.state.editing ? (
                <div>
                  <div className="col-md-1">
                    <button
                      className="btn btn-secondary"
                      onClick={this.toggleEditing}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-md-1">
                    <button
                      className="btn btn-primary"
                      onClick={this.savePrices}
                    >
                      Save
                    </button>
                  </div>
                </div>
              ) : (
                <div className="col-md-6">
                  <button
                    className="btn btn-primary"
                    onClick={this.toggleEditing}
                  >
                    Edit Prices
                  </button>
                </div>
              )}
            </div>
          </div>
        </CardComponent>
      );
    }
  }
}
// const mapDispatchToProps = (dispatch) => {
//
// }

const mapStateToProps = state => ({
  market: state.admin.general.location
});

const mapDispatchToProps = dispatch => ({
  showMessage: message => dispatch({ type: "ADD_MESSAGE", payload: message }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" }),
  fetchRequest: () => dispatch({ type: "FETCH_REQUEST" }),
  fetchSuccess: () => dispatch({ type: "FETCH_SUCCESS" })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPrices);
