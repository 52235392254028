import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { DatePicker } from "react-widgets";
import {
  CardComponent,
  MetricTable,
  StaffTable,
  ExportForm,
  ForecastTable
} from "../../components";
import moment from "moment";
import axios from "../../services/network.js";

class StaffData extends Component {
  state = {
    startDate: moment()
      .startOf("month")
      .toDate(),
    endDate: moment()
      .endOf("day")
      .toDate(),
    tab: "metrics",
    fetch: false,
    disabled: false,
    forecastDate: moment()
      .startOf("month")
      .toDate()
  };

  getQueryString = (start, end, client) => {
    const query = {
      startDate: start,
      endDate: end
    };
    if (client) {
      query.client = client;
    }
    return queryString.stringify(query);
  };

  getWalkCredits = async (start, end, market_id) => {
    const { showSpinner, stopSpinner } = this.props;
    showSpinner();
    if (!market_id) {
      market_id = this.props.market._id;
    }
    const { data } = await axios.get(
      `${
        process.env.REACT_APP_API_URI
      }/admin/market/${market_id}/credits?${this.getQueryString(start, end)}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      }
    );
    stopSpinner();
    return data;
  };

  getLineItems = async (start, end, market_id) => {
    const { showSpinner, stopSpinner } = this.props;
    showSpinner();
    if (!market_id) {
      market_id = this.props.market._id;
    }
    const { data } = await axios.get(
      `${
        process.env.REACT_APP_API_URI
      }/admin/market/${market_id}/lineItems?${this.getQueryString(start, end)}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      }
    );
    stopSpinner();
    return data;
  };

  getAppointments = async (start, end, market_id, client = false) => {
    const { showSpinner, stopSpinner } = this.props;
    showSpinner();
    if (!market_id) {
      market_id = this.props.market._id;
    }
    const { data } = await axios.get(
      `${
        process.env.REACT_APP_API_URI
      }/admin/market/${market_id}/appointments?${this.getQueryString(
        start,
        end,
        client
      )}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      }
    );
    stopSpinner();
    return data;
  };

  //Needed to get appointments by visit date
  getAppointmentsByVisitDate = async (start, end, market_id) => {
    const { showSpinner, stopSpinner } = this.props;
    showSpinner();
    if (!market_id) {
      market_id = this.props.market_id;
    }
    const query = {
      city: market_id,
      startDate: start,
      endDate: end
    };
    const { data } = await axios.get(
      `${
        process.env.REACT_APP_API_URI
      }/admin/appointments?${queryString.stringify(query)}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      }
    );
    stopSpinner();
    return data;
  };

  //Need for ExportForm
  getAppointmentsWithPrice = async (start, end, market_id) => {
    const appointments = await this.getAppointments(
      start,
      end,
      market_id,
      true
    );
    const ids = [...new Set(appointments.map(a => a._id))];
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URI}/admin/appointments/getPayments`,
      { ids: ids },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      }
    );

    return new Promise((yea, no) => {
      const appts = appointments.map(a => {
        const item = data.line_items.find(l => l.appointment_id == a._id);
        const credit = data.walk_credits.find(w => w.visit_used_on == a._id);
        const pc_credit = data.playcare_credits.find(c =>
          c.visit_ids.includes(a._id.toString())
        );

        let derived_price;
        if (credit) {
          derived_price = credit.price;
        } else if (item) {
          derived_price = item.amount;
        } else if (pc_credit) {
          let temp = pc_credit.visits_used_on.find(v => v.visit_id === a._id);
          derived_price = temp.amount_used * pc_credit.price;
        } else {
          derived_price = a.price;
        }
        return {
          ...a,
          price: derived_price
        };
      });
      yea(appts);
    });
  };
  getStartTime = () => {
    return moment()
      .startOf("month")
      .toDate();
  };

  getEndTime = () => {
    return moment()
      .endOf("day")
      .toDate();
  };

  setStartTime = time => {
    if (moment(this.state.endDate).diff(moment(time), "months") > 2) {
      this.setState({ disabled: true, startDate: time });
    } else {
      this.setState({ disabled: false, startDate: time });
    }
  };

  setEndTime = time => {
    if (moment(time).diff(moment(this.state.startDate), "months") > 2) {
      this.setState({ disabled: true, endDate: time });
    } else {
      this.setState({ disabled: false, endDate: time });
    }
  };

  exportAppointmentProps = [
    { value: "_id", text: "Appointment ID" },
    { value: "market_id", text: "Market ID" },
    { value: "actual_end_time", text: "End Time" },
    { value: "actual_start_time", text: "Start Time" },
    { value: "appointment_status", text: "Appointment Status" },
    { value: "appointment_type", text: "Appointment Type" },
    { value: "apartment_complex", text: "Apartment Complex" },
    { value: "building_type", text: "Building Type" },
    { value: "confirmation_email_sent", text: "Confirmation Email Sent" },
    { value: "address", text: "Address" },
    { value: "phone_number", text: "Phone Number" },
    { value: "client_created_at", text: "Client Created At" },
    { value: "email", text: "Client Email" },
    { value: "building_type", text: "Building Type" },
    { value: "client_name", text: "Client Name" },
    { value: "valid_payment", text: "Valid Payment" },
    { value: "home_class", text: "Home Class" },
    { value: "legal_agreement", text: "Legal Agreement" },
    { value: "referral_specifics", text: "Referral Info" },
    { value: "secondary_contact", text: "Secondary Contact" },
    { value: "secondary_phone", text: "Secondary Phone" },
    { value: "secondary_email", text: "Secondary Email" },
    { value: "signup_date", text: "Signup Date" },
    { value: "stripe_id", text: "Stripe ID" },
    { value: "zones", text: "Zones" },
    { value: "building_type", text: "Building Type" },
    { value: "credit_used", text: "Walk Credit Used" },
    { value: "sitter", text: "Sitter" },
    { value: "building_type", text: "Building Type" },
    { value: "building_type", text: "Building Type" },
    { value: "household_id", text: "Client (Household) ID" },
    { value: "price", text: "Price" },
    { value: "sitter_notes_to_admin", text: "Admin Notes" },
    { value: "sitter_notes_to_client", text: "Client Notes" },
    { value: "minutes_late", text: "Minutes Late" },
    { value: "is_recurring", text: "Recurring" },
    { value: "visit_date", text: "Visit Date" }
  ];

  flattenAppointments = appointments => {
    const new_appts = appointments.map(a => {
      let late;
      if (
        a.appointment_type.id !== 8 &&
        moment(a.actual_start_time).isAfter(moment(a.window_end_time))
      ) {
        let lateAbs = moment(a.actual_start_time).diff(
          moment(a.window_end_time),
          "minutes"
        );
        late = Math.abs(lateAbs);
      } else {
        late = 0;
      }
      let model = {
        ...a,
        //Credit
        credit_used: a.credit ? "Yes" : "No",
        //Item
        line_item: a.item ? "Yes" : "No",
        //Sitter`
        sitter: a.sitter ? a.sitter.full_name : "",
        //Format Appointment Items
        actual_end_time: moment(a.actual_end_time).format("HH:mm"),
        actual_start_time: moment(a.actual_start_time).format("HH:mm"),
        appointment_status: a.appointment_status
          ? a.appointment_status.name
          : "",
        //Stringify appointment type
        appointment_type: a.appointment_type ? a.appointment_type.name : "",
        minutes_late: late,
        is_recurring: a.recurrence_id ? "Yes" : "No"
      };
      if (a.client) {
        model = {
          ...model,
          apartment_complex: a.client.apartment_complex,
          building_type: a.client.building_type,
          confirmation_email_sent: a.client.confirmation_email_sent,
          client_created_at: moment(a.client.createdAt).format("MM/DD/YYYY"),
          email: a.client.email,
          client_name: a.client.first_name + " " + a.client.last_name,
          valid_payment: a.client.has_valid_payment_method,
          legal_agreement: a.client.legal_agreement,
          referral_specifics: a.client.referral_specifics,
          signup_date: moment(a.client.signup_date).format("MM/DD/YYYY"),
          stripe_id: a.client.stripe_id,
          zones: a.client.zones.reduce((prev, next) => {
            return prev ? prev + "/" + next.zone_name : next.zone_name;
          }, ""),
          home_class: a.client.home_class ? a.client.home_class.name : ""
        };
        if (a.client.contact) {
          model = {
            ...model,
            phone_number: a.client.contact.phone_number
          };
          if (a.client.contact.address) {
            const { address } = a.client.contact;
            model = {
              ...model,
              address: `${address.address_line_1} ${address.address_line_2 ||
                ""} ${address.city} ${address.zip}`
            };
          }
        }
        if (a.client.secondary_contact) {
          model = {
            ...model,
            secondary_contact: `${
              a.client.secondary_contact
                ? a.client.secondary_contact.first_name + " "
                : ""
            }${
              a.client.secondary_contact.last_name
                ? a.client.secondary_contact.last_name
                : ""
            }`,
            secondary_phone: a.client.secondary_contact
              ? a.client.secondary_contact.phone_number
              : "",
            secondary_email: a.client.secondary_contact
              ? a.client.secondary_contact.email
              : ""
          };
        }
      }
      return model;
    });
    return new Promise((res, rej) => {
      res(new_appts);
    });
  };

  exportCreditProps = [
    { value: "_id", text: "ID" },
    { value: "market_id", text: "Market ID" },
    { value: "bundle_size", text: "Bundle Size" },
    { value: "cancellation", text: "Cancellation" },
    { value: "household_id", text: "Client ID (Household)" },
    { value: "client_name", text: "Client Name" },
    { value: "createdAt", text: "Created At" },
    { value: "playcare", text: "Playcare" },
    { value: "price", text: "Price" },
    { value: "type", text: "Type" },
    { value: "used", text: "Used" },
    { value: "value", text: "Value" }
  ];

  exportLineItemProps = [
    { value: "_id", text: "ID" },
    { value: "market_id", text: "Market ID" },
    { value: "type", text: "Type" },
    { value: "amount", text: "Amount" },
    { value: "household_id", text: "Client ID (Household)" },
    { value: "client_name", text: "Client Name" },
    { value: "createdAt", text: "Created At" },
    { value: "description", text: "Description" },
    { value: "updatedAt", text: "Updated At" }
  ];

  getHeaders = arr => {
    const headers = arr.map(a => {
      return a.text;
    });
    return headers;
  };

  getFields = arr => {
    const fields = arr.map(a => {
      return a.value;
    });
    return fields;
  };

  render() {
    const { startDate, endDate } = this.state;
    const market_id = this.props.market._id;
    return (
      <div>
        <CardComponent
          title={`Data - ${this.state.tab.charAt(0).toUpperCase() +
            this.state.tab.substr(1)}`}
        >
          <div className="data-wrapper">
            <ul className="nav nav-tabs payment-tabs">
              <li>
                <a
                  onClick={() => {
                    this.setState({ tab: "metrics" });
                  }}
                >
                  Metrics
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    this.setState({ tab: "export" });
                  }}
                >
                  Export
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    this.setState({ tab: "staff" });
                  }}
                >
                  Staff
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    this.setState({ tab: "forecast" });
                  }}
                >
                  Forecast
                </a>
              </li>
            </ul>
            <div className="date-filters">
              {this.state.tab !== "forecast" ? (
                <div className="row">
                  <div className="col-sm-4">
                    <label>Start Date</label>
                    <DatePicker
                      includeTime={true}
                      defaultValue={this.getStartTime()}
                      onChange={e => this.setStartTime(e)}
                    />
                  </div>
                  <div className="col-sm-4">
                    <label>End Date</label>
                    <DatePicker
                      includeTime={true}
                      defaultValue={this.getEndTime()}
                      onChange={e => this.setEndTime(e)}
                    />
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-sm-4">
                    <label>Date</label>
                    <DatePicker
                      includeTime={true}
                      onChange={e => {
                        this.setState({ forecastDate: e });
                      }}
                      defaultValue={this.getStartTime()}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </CardComponent>
        {this.state.tab === "metrics" ? (
          <MetricTable
            start_date={this.state.startDate}
            end_date={this.state.endDate}
            market_id={this.props.market._id}
            getAppointments={this.getAppointments}
            getLineItems={this.getLineItems}
            getWalkCredits={this.getWalkCredits}
            showSpinner={this.props.showSpinner}
            stopSpinner={this.props.stopSpinner}
            disabled={this.state.disabled}
          />
        ) : null}
        {this.state.tab === "export" ? (
          <div>
            <div>
              <ExportForm
                title="Appointments"
                flatten={this.flattenAppointments}
                exportFields={this.exportAppointmentProps}
                headers={this.getHeaders(this.exportAppointmentProps)}
                fields={this.getFields(this.exportAppointmentProps)}
                getData={() => {
                  return this.getAppointmentsWithPrice(
                    startDate,
                    endDate,
                    market_id
                  );
                }}
                disabled={this.state.disabled}
              />
            </div>
            <div>
              <ExportForm
                title="Walk Credits"
                exportFields={this.exportCreditProps}
                headers={this.getHeaders(this.exportCreditProps)}
                fields={this.getFields(this.exportCreditProps)}
                getData={() => {
                  return this.getWalkCredits(
                    startDate,
                    endDate,
                    market_id,
                    true
                  );
                }}
                disabled={this.state.disabled}
              />
            </div>
            <div>
              <ExportForm
                title="Line Item Credit"
                exportFields={this.exportLineItemProps}
                headers={this.getHeaders(this.exportLineItemProps)}
                fields={this.getFields(this.exportLineItemProps)}
                getData={() => {
                  return this.getLineItems(startDate, endDate, market_id, true);
                }}
                disabled={this.state.disabled}
              />
            </div>
          </div>
        ) : null}
        {this.state.tab === "staff" ? (
          <StaffTable
            market_id={this.props.market._id}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            getAppointments={this.getAppointmentsByVisitDate}
            getStaff={this.getStaff}
            disabled={this.state.disabled}
          />
        ) : null}
        {this.state.tab === "forecast" ? (
          <ForecastTable
            forecastDate={this.state.forecastDate}
            marketId={this.props.market._id}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  market: state.admin.general.location
});

const mapDispatchToProps = dispatch => ({
  showSpinner: () => dispatch({ type: "FETCH_REQUEST" }),
  stopSpinner: () => dispatch({ type: "FETCH_SUCCESS" }),
  showMessage: msg => dispatch({ type: "ADD_MESSAGE", payload: msg }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StaffData);
