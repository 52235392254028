import React, { Component } from "react";
import { Multiselect, DropdownList } from "react-widgets";
const yesno = [{ text: "Yes", bool: true }, { text: "No", bool: false }];
const bundles = [
  { text: "Bundles", bool: true },
  { text: "One-Off", bool: false }
];

class Boarding extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    options: [
      {
        id: 0,
        name: "Sunday",
        abb: "Sun"
      },
      {
        id: 1,
        name: "Monday",
        abb: "Mon"
      },
      {
        id: 2,
        name: "Tuesday",
        abb: "Tue"
      },
      {
        id: 3,
        name: "Wednesday",
        abb: "Wed"
      },
      {
        id: 4,
        name: "Thursday",
        abb: "Thu"
      },
      {
        id: 5,
        name: "Friday",
        abb: "Fri"
      },
      {
        id: 6,
        name: "Saturday",
        abb: "Sat"
      }
    ]
  };

  render() {
    console.log(this.props.facility.services);
    return (
      <div className="row boarding">
        <div className="col-sm-12">
          <div className="col-sm-12">
            <h4>Boarding Settings</h4>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="col-sm-3">
            <h5>Available Service?</h5>
            <DropdownList
              data={yesno}
              value={this.props.facility.services.boarding.available}
              textField="text"
              valueField="bool"
              onChange={val => this.props.updateBoarding("available", val.bool)}
            />
          </div>
          {this.props.facility.services.boarding.available ? (
            <div className="col-sm-9 boardingDays">
              <h5>Days Of The Week</h5>
              <div className={`toggle-box-container isAdmin editBoarding`}>
                {this.state.options.map((option, index) => {
                  const checked = this.props.facility.services.boarding.days_of_week.includes(
                    option.name
                  );
                  return (
                    <div
                      className={`toggle-box weekdays-field editBoardingWeekday btn btn-tertiary${
                        checked ? " checked" : ""
                      }`}
                      key={`${option.name}-${index}`}
                      onClick={() => {
                        const days = [
                          ...this.props.facility.services.boarding.days_of_week
                        ];
                        if (days.includes(option.name)) {
                          days.splice(days.indexOf(option.name), 1);
                        } else {
                          days.push(option.name);
                        }
                        this.props.updateBoarding("days_of_week", days);
                      }}
                    >
                      <label>{option.abb}</label>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="col-sm-9 boardingDays"></div>
          )}
          <div className="col-sm-6">
            <h5>Drop Off Begins...</h5>
            <DropdownList
              value={this.props.facility.services.boarding.dropoff_begins}
              data={this.props.times}
              onChange={val => this.props.updateBoarding("dropoff_begins", val)}
            />
          </div>
          <div className="col-sm-6">
            <h5>Pickup Ends...</h5>
            <DropdownList
              value={this.props.facility.services.boarding.pickup_deadline}
              data={this.props.times}
              onChange={val =>
                this.props.updateBoarding("pickup_deadline", val)
              }
            />
          </div>
          <div className="col-sm-6">
            <h5>Cancellation Deadline (hours)</h5>
            <input
              type="text"
              value={
                this.props.facility.services.boarding.cancellation_deadline
              }
              onChange={e =>
                this.props.updateBoarding(
                  "cancellation_deadline",
                  e.target.value
                )
              }
            />
          </div>
        </div>
        <div className="col-sm-6">
          {/*<div className="col-sm-12">
            <h5>Pricing Category</h5>
            <DropdownList
              textField="text"
              valueField="bool"
              value={this.props.facility.services.boarding.bundles_enabled}
              data={bundles}
              onChange={val =>
                this.props.updateBoarding("bundles_enabled", val.bool)
              }
            />
          </div>*/}
          <div className="col-sm-6">
            <h5>Price Per Night</h5>
            <input
              type="text"
              value={(
                this.props.facility.services.boarding.price_per_night / 100
              ).toFixed(2)}
              onChange={e => this.props.updateBoardingPrices(e.target.value)}
            />
          </div>

          <div className="col-sm-6">
            <h5>Late Pickup Fee</h5>
            <input
              type="text"
              value={(
                this.props.facility.services.boarding.late_pickup_fee / 100
              ).toFixed(2)}
              onChange={e =>
                this.props.updateBoardingCharges(
                  "late_pickup_fee",
                  e.target.value
                )
              }
            />
          </div>
          <div className="col-sm-6">
            <h5>Holiday Surcharge</h5>
            <input
              type="text"
              value={(
                this.props.facility.services.boarding.holiday_surcharge / 100
              ).toFixed(2)}
              onChange={e =>
                this.props.updateBoardingCharges(
                  "holiday_surcharge",
                  e.target.value
                )
              }
            />
          </div>
          <div className="col-sm-6">
            <h5>Late Cancellation Fee</h5>
            <input
              type="text"
              value={(
                this.props.facility.services.boarding.late_cancellation_fee /
                100
              ).toFixed(2)}
              onChange={e =>
                this.props.updateBoardingCharges(
                  "late_cancellation_fee",
                  e.target.value
                )
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Boarding;
