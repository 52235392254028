import React, { Component } from "react";
import moment from "moment";
import Tooltip from "rc-tooltip";
import PetCardCompact from "./scheduler/PetCardCompact";
import Timeblock from "./Timeblock";
import { DropdownList } from "react-widgets";
import _ from "lodash";
import history from "../../actions/history";

class ErrorModal extends Component {
  state = {
    selectedSitters: [],
    selectedAppts: []
  };

  getErrors = () => {
    if (this.props.unserved && this.props.unserved.length) {
      return this.props.unserved.map(u => {
        return (
          <div>
            {u.visit.client.full_name}: {u.reason}
          </div>
        );
      });
    } else if (this.props.error) {
      return <div>{this.props.error}</div>;
    } else {
      return <h3>Oh no! An error! Check your inputs and try again.</h3>;
    }
  };

  render() {
    let style = {
      width: "400px",
      height: "auto"
    };

    return (
      <div className="card row" style={style}>
        <div className="col-sm-12 scheduleHeader">{this.getErrors()}</div>
      </div>
    );
  }
}

export default ErrorModal;
