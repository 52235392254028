import React, { Component } from "react";
import { connect } from "react-redux";
import { createUserService } from "../../services";
import { catchFormServerErrors } from "../../helpers";

// Actions
import { createClient } from "../../actions/admin/clients.actions";
import { getAllStaff } from "../../actions/admin/staff.actions";
import {
  showFormErrorMessage,
  showSuccessMessage
} from "../../actions/general.actions";
import {
  authClient,
  resetPasswordClient
} from "../../services/firebaseHelpers";

//Child Components
import { ClientForm, PageTitleComponent } from "../../components";
import axios from "../../services/network.js";

class AddClientPage extends Component {
  state = {
    apartments: []
  };

  componentWillMount = async () => {
    const { getAllStaff, market } = this.props;
    getAllStaff({ city: market._id });
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URI}/admin/apartments?market=${this.props.market._id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      }
    );
    if (data) {
      data.unshift({ name: "None", apartment_id: null });
      this.setState({ apartments: data });
    }
  };

  componentWillReceiveProps = props => {
    const { getAllStaff, market } = this.props;
    if (market._id !== props.market._id) {
      getAllStaff({ city: props.market._id });
    }
  };

  constructor() {
    super();

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(values) {
    try {
      this.props.showFetch();
      const model = await createUserService.getAddClientModel(values);
      await this.props.onSubmit(model);
      this.props.removeFetch();
      this.props.displayFormSuccess("New client has been created.");
    } catch (e) {
      this.props.removeFetch();
    }
  }

  render() {
    const {
      displayFormError,
      displayFormSuccess,
      formConstants,
      markets
    } = this.props;

    return (
      <div className="card">
        <PageTitleComponent title="Add New Client" />
        <ClientForm
          onSubmit={this.handleSubmit}
          onSubmitFail={errors => displayFormError(errors)}
          formConstants={formConstants}
          team_members={this.props.team_members}
          markets={markets}
          apartments={this.state.apartments}
          initialValues={{
            state: 33,
            enforce_device_within_region: true,
            secured_yard: false
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  formConstants: state.general.formConstants,
  market: state.admin.general.location,
  markets: state.admin.general.markets,
  team_members: state.admin.staff.staffList
});

const mapDispatchToProps = dispatch => ({
  showFetch: () => dispatch({ type: "FETCH_REQUEST", context: "addUser" }),
  removeFetch: () => dispatch({ type: "FETCH_SUCCESS", context: "addUser" }),
  displayFormError: errors =>
    dispatch(showFormErrorMessage(errors, "addClientForm")),
  displayFormSuccess: message =>
    dispatch(showSuccessMessage(message, "addClientForm")),
  onSubmit: model => dispatch(createClient(model)),
  getAllStaff: qs => dispatch(getAllStaff(qs))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddClientPage);
