import { combineReducers } from "redux";

import formConstants from "./constants.reducer";
import messages from "./messages.reducer";
import specialMessages from "./special_messages.reducer";
import modals from "./modals.reducer";
import fetching, { isFetching } from "./fetching.reducer";
import user from "./user.reducer.js";

const general = combineReducers({
  formConstants,
  messages,
  specialMessages,
  modals,
  fetching,
  user
});

export default general;

export const getIsFetching = (state, contexts) => isFetching(state, contexts);
