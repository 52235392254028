import React, { Component } from "react";
import { DropdownList, Multiselect, DatePicker } from "react-widgets";
import { formatNumsToDollars, onlyNumbers } from "../../../../helpers";
let yesno = [{ text: "Yes", value: true }, { text: "No", value: false }];
import _ from "lodash";
import moment from "moment";

class Holidays extends Component {
  state = {
    adding: false,
    holiday: {
      name: ""
    }
  };

  updateHoliday = (key, val) => {
    this.setState({
      ...this.state,
      holiday: {
        ...this.state.holiday,
        [key]: val
      }
    });
  };

  getNumbers = () => {
    return _.range(0, 15, 1);
  };

  save = () => {
    this.props.save(this.state.holiday);
    this.setState({ adding: false, holiday: { name: "" } });
  };

  renderAddForm = () => {
    return (
      <div className="col-sm-12 boarding">
        <div className="col-sm-2">
          <h4>Name</h4>
          <input
            type="text"
            value={this.state.holiday.name}
            placeholder="NAME"
            onChange={e => this.updateHoliday("name", e.target.value)}
          />
        </div>
        <div className="col-sm-2">
          <h4>Start Date</h4>
          <DatePicker
            includeTime={true}
            defaultValue={moment().toDate()}
            format={"MMM Do"}
            onChange={e => this.updateHoliday("startDate", moment(e).toDate())}
    />
        </div>
        <div className="col-sm-2">
          <h4>End Date</h4>
          <DatePicker
            includeTime={true}
            defaultValue={moment().toDate()}
            format={"MMM Do"}
            onChange={e => this.updateHoliday("endDate", moment(e).toDate())}
  />
        </div>
        <div className="col-sm-2">
          <h4>Surcharge</h4>
          <DropdownList
            data={yesno}
            textField="text"
            valueField="value"
            onChange={val => this.updateHoliday("surcharge", val.value)}
          />
        </div>
        <div className="col-sm-2">
          <h4>Min Nights</h4>
          <DropdownList
            data={this.getNumbers()}
            onChange={val => this.updateHoliday("minimum_stay", val)}
          />
        </div>
        <div className="col-sm-2">
          <h4>Daycare Offered?</h4>
          <DropdownList
            data={yesno}
            textField="text"
            valueField="value"
            onChange={val => this.updateHoliday("daycare_allowed", val.value)}
          />
        </div>
        <div className="col-sm-12">
          <button className="btn btn-primary" onClick={() => this.save()}>
            Save
          </button>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="col-sm-2 areaTitle">Holiday Name</div>
          <div className="col-sm-2 areaTitle">Start Date</div>
          <div className="col-sm-2 areaTitle">End Date</div>
          <div className="col-sm-2 areaTitle">Surcharge?</div>
          <div className="col-sm-2 areaTitle">Minimum Stay</div>
          <div className="col-sm-2 areaTitle">Daycare?</div>
        </div>
        {this.props.facility.holidays.map(day => {
          return (
            <div className="col-sm-12">
              <div className="col-sm-2 areaTitle">{day.name}</div>
              <div className="col-sm-2 areaTitle">
                {moment(day.startDate).format("MMM Do")}
              </div>
              <div className="col-sm-2 areaTitle">
                {moment(day.endDate).format("MMM Do")}
              </div>
              <div className="col-sm-2 areaTitle">
                {day.surcharge ? "Yes" : "No"}
              </div>
              <div className="col-sm-2 areaTitle">
                {day.minimum_stay ? `${day.minimum_stay} Nights` : "N/A"}
              </div>
              <div className="col-sm-2 areaTitle">
                {day.daycare_allowed ? "Yes" : "No"}
              </div>
            </div>
          );
        })}
        {this.state.adding ? null : (
          <div className="row addOnButtons">
            <div className="col-sm-4">
              <button
                className="btn btn-primary"
                onClick={() => this.setState({ adding: true })}
              >
                + Add Holiday
              </button>
            </div>
          </div>
        )}
        {this.state.adding ? this.renderAddForm() : null}
      </div>
    );
  }
}

export default Holidays;
