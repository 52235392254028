import React, { Component } from "react";
import moment from "moment-timezone";
import Tooltip from "rc-tooltip";

const combineDateTime = (date, time) => {
  let newTime = moment(`${date} ${time}`);
  if (!newTime.isValid()) {
    return null;
  }
  return newTime.format("ddd MMM D YYYY HH:mm");
};

function convertTime12to24(time12h) {
  let time = time12h.toLowerCase();

  let index;
  let modifier;
  let newStr;
  if (time.indexOf("pm") > -1) {
    modifier = "pm";
    newStr = time.replace("pm", "");
  } else {
    modifier = "am";
    newStr = time.replace("am", "");
  }

  time.trim();

  let [hours, minutes] = newStr.split(":");

  if (hours === "12") {
    hours = "00";
  }

  hours = parseInt(hours, 10);
  let minutesTest = parseInt(minutes, 10);

  if (hours < 0 || hours > 12) return null;
  if (minutesTest > 59) return null;

  if (modifier === "pm") {
    hours += 12;
  }

  let finalTime = hours + ":" + minutes;
  return finalTime;
}

class MapAppointmentRowUnstacked extends Component {
  state = {
    editTime: false,
    valid: true,
    open: false
  };

  showTime = e => {
    e.stopPropagation();

    this.setState({ editTime: !this.state.editTime });
  };

  changeTime = (e, a) => {
    if (this.state.valid) {
      let converted = convertTime12to24(e.target.value);
      this.props.changeTime(converted, a);
    }
  };

  validateTime = e => {
    let a = this.props.appointment;

    let timeMod = convertTime12to24(e.target.value);
    if (!timeMod) {
      this.setState({ valid: false });
      this.props.dontSubmit();
      return;
    }

    let newTime = combineDateTime(
      moment(a.scheduled_start_time).format("ddd MMM D YYYY"),
      timeMod
    );

    if (!newTime) {
      this.setState({ valid: false });
      this.props.dontSubmit();
      return;
    }

    let hour = moment(newTime).hour();

    if (hour >= 9 && hour <= 19) {
      this.setState({ valid: true });
      this.props.allowSubmit();
    } else {
      this.setState({ valid: false });
      this.props.dontSubmit();
    }
  };

  getStatus = () => {
    const a = this.props.appointment;

    if (a.appointment_status.id === 5) {
      return <i className="fa fa-check" />;
    } else if (a.appointment_status.id === 4) {
      return <i className="fa fa-spinner fa-spin fa-stack-1x stackers" />;
    } else if (a.en_route) {
      return <i className="fa fa-car specialCar" />;
    } else {
      return null;
    }
  };

  getDuration = () => {
    let { appointment } = this.props;
    if (appointment.appointment_type.id === 12) return "Concierge";
    return `${appointment.appointment_type.duration_in_minutes} min`;
  };

  getTimeOrInput = () => {
    let inputStyle = {
      border: this.state.valid ? "none" : "1px solid red"
    };

    const a = this.props.appointment;

    if (a.appointment_status.id === 5) {
      return (
        <span>
          {moment(a.actual_start_time).format("hh:mm")} -{" "}
          {moment(a.actual_end_time).format("hh:mm")}
        </span>
      );
    } else if (a.appointment_status.id === 4) {
      return (
        <span>Started at {moment(a.actual_start_time).format("hh:mm")}</span>
      );
    } else {
      return (
        <Tooltip
          placement="top"
          overlay={<div>Projected: {this.getProjectedStart()}</div>}
          mouseEnterDelay={0.1}
          mouseLeaveDelay={0.1}
          overlayClassName="annotation-tooltip"
        >
          <input
            style={inputStyle}
            defaultValue={moment(
              a.scheduled_start_time
                ? a.scheduled_start_time
                : a.window_start_time
            ).format("hh:mm A")}
            onBlur={e => this.changeTime(e, a)}
            onChange={e => this.validateTime(e)}
          />
        </Tooltip>
      );
    }
  };

  toggleOpen = e => {
    e.stopPropagation();
    this.props.toggleOpen(this.props.appointment);
  };

  editEnRoute = e => {
    e.stopPropagation();
    this.props.editEnRoute(this.props.appointment);
  };

  getProjectedStart = () => {
    const a = this.props.appointment;
    let lateness = this.props.lateness ? this.props.lateness : 0;
    let entry = a.home && a.home.entry_time ? a.home.entry_time : 5;
    return moment(a.scheduled_start_time)
      .add(lateness, "minutes")
      .add(entry, "minutes")
      .format("hh:mm a");
  };

  render() {
    if (this.props.appointment.break) return null;
    if (!this.props.timeZone) return null;
    const a = this.props.appointment;

    let diffFromStartOfWindow = moment(a.window_start_time).diff(
      moment(a.scheduled_start_time),
      "minutes"
    );

    let isSelected =
      this.props.selected && a._id === this.props.selected._id ? true : false;
    let style = {
      width: "550px",
      height: a.open ? "200px" : `30px`,
      transition: ".25s all ease",
      zIndex: a.open ? 190 : 180
    };

    if (a.open) style.opacity = 0.99;

    let window = moment(a.window_start_time).diff(
      moment(a.window_end_time),
      "minutes"
    );
    let diffFromStart = moment(a.window_start_time).diff(
      moment(a.window_start_time)
        .hour(9)
        .minute(0),
      "minutes"
    );

    let windowStyle = {
      height: `30px`,
      width: "5%",
      left: 0,
      overflow: "visible",
      zIndex: "auto",
      margin: "10px"
    };

    let statusClass;

    if (a.en_route) statusClass = "en_route_row";
    if (a.appointment_status.id === 5) statusClass = "completed_row";
    if (a.appointment_status.id === 4) statusClass = "in_progress_row";
    if (a.special) statusClass = "special";

    let projStart = a.projected_start_time
      ? a.projected_start_time
      : a.scheduled_start_time;

    if (a.appointment_status.id === 2) {
      let idle = a.idle_time && a.idle_time > 0 ? a.idle_time : 0;
      if (
        moment(projStart)
          .add(this.props.lateness, "minutes")
          .subtract(idle, "minutes")
          .isAfter(moment(a.window_end_time))
      )
        statusClass = "late_visit";
    }

    let hiddenRowStyle = {
      height: a.open ? "100px" : 0,
      transition: ".5s height ease",
      overflow: "hidden"
    };

    return (
      <div style={windowStyle} className="apptWindow">
        <div
          style={style}
          className={`row ${
            isSelected ? "selectedAppt" : ""
          } mapAppointmentRow ${statusClass ? statusClass : ""}`}
          onMouseEnter={() => this.props.highlightAppointmentOnMap(a._id)}
          onClick={() => this.props.showPreview(a)}
          onMouseLeave={() => this.props.highlightAppointmentOnMap()}
        >
          <div className="col-sm-3">{a.contact.full_name}</div>
          <div className="col-sm-3">
            {moment(a.window_start_time).format("hh:mm")} -{" "}
            {moment(a.window_end_time).format("hh:mm")}
          </div>
          <div
            className={`col-sm-3 specialInput ${
              this.state.valid ? "" : "badInput"
            }`}
            key={`specialInputDiv${a._id}`}
          >
            {this.getTimeOrInput()}
          </div>
          <div className="col-sm-2">
            {this.getDuration()} {this.getStatus()}
          </div>
          <div className="col-sm-1 specialToggle">
            <i
              onClick={e => this.toggleOpen(e)}
              className={`fa fa-chevron-down ${
                a.open ? "toggleDown" : "toggleUp"
              }`}
            />
          </div>
          <div
            className="col-sm-12 hiddenRow"
            style={hiddenRowStyle}
            onMouseEnter={e => e.stopPropagation()}
          >
            <label>En Route</label>
            <input
              type="checkbox"
              checked={a.en_route ? true : false}
              onClick={e => this.editEnRoute(e)}
            />
            <p>Projected Start: {this.getProjectedStart()}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default MapAppointmentRowUnstacked;
