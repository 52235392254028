import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ZoneBadge } from "../../";

export default class StaffTableRow extends Component {

  render() {
    const { staff } = this.props;
    return (
      <Link to={`/staff/${staff._id}`}>
        <div className="user-list-item">
          <div className="user-list-item-content username-container col-lg-3 col-md-3 col-sm-3 col-xs-6">
            <div className="user-list-name">
              {staff.contact.full_name ? staff.contact.full_name : "No Name"}
            </div>
            <div className="user-list-staff-type">{staff.staff_type}</div>
          </div>
          <div className="user-list-item-content col-lg-3 col-md-3 col-sm-3 col-xs-6">
            {staff.zones && staff.zones.length
              ? staff.zones.map(zone => (
                  <ZoneBadge
                    key={`staff-${staff._id}-zone-${zone.id}`}
                    size="medium"
                    zone={zone}
                  />
                ))
              : null}
          </div>
          <div className="user-list-item-content col-lg-1 col-md-3 col-sm-3 col-xs-6">
            {staff.home && staff.home.address
              ? staff.home.address.zip_code
              : null}
          </div>
          <div className="user-list-item-content col-lg-3 col-md-4 col-sm-4 col-xs-12">
            <div className="user-list-notes">
              {staff.trainings_completed
                ? staff.trainings_completed.map(s => s)
                : staff.notes}
            </div>
          </div>
          <div className="user-list-item-content col-lg-2 col-md-4 col-sm-4 col-xs-12">
            <div className="user-list-notes">{staff.notes}</div>
          </div>
        </div>
      </Link>
    );
  }
}
