import * as ActionTypes from "../../actions";

const initialState = {
  facilities: null,
};

function facilities(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ActionTypes.GET_ALL_FACILITIES_SUCCESS:
      return { ...state, facilities: payload.facilities };
    default:
      return state;
  }
}

export default facilities;
