import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createUserService } from "../../services";
import {
  authClient,
  resetPasswordClient
} from "../../services/firebaseHelpers";
import { getAllStaff } from "../../actions/admin/staff.actions";
import { catchFormServerErrors } from "../../helpers";
import { Link } from "react-router-dom";
import moment from "moment";

// Pages
import { AddPetPage, EditPetPage } from "../../pages";
import ClientAppointments from "./ClientAppointments";
import axios from "../../services/network.js";

// Components
import { FormSection } from "../../components/shared/formComponents";
import {
  AddLineItemForm,
  ClientBalanceCard,
  ClientForm,
  ClientActivationConfirmationModal,
  DeactivatePetConfirmationModal,
  LineItems,
  OverlayModal,
  PageTitleComponent,
  PasswordResetConfirmationModal,
  PetDetailsRow,
  StripeCard,
  ClientAddVisitsCard,
  CardComponent,
  ClientAppointmentCalendar,
  ClientPromoCard,
  PunchCard
} from "../../components";

// Actions
import { createLineItem as createLineItemAction } from "../../actions/admin/general.actions";
import {
  pushModal,
  popModal,
  showFormErrorMessage,
  showSuccessMessage
} from "../../actions/general.actions";
import {
  getClient as getClientAction,
  processDelinquentClientBalance as processDelinquentClientBalanceAction,
  updateClient as updateClientAction
} from "../../actions/admin/clients.actions";
import { getAllFacilities as getAllFacilitiesAction } from "../../actions/admin/facilities.actions";

class EditClientPage extends Component {
  constructor() {
    super();

    this.sendConfirmEmail = this.sendConfirmEmail.bind(this);
  }

  state = {
    showAppts: false,
    showNotes: false,
    editCard: false,
    apartments: [],
    showActivationConfirm: false,
    calendarMonth: moment().month(),
    calendarDay: moment().day(),
    calendarYear: moment().year(),
    show_canceled: false
  };

  componentWillMount = async () => {
    const { getClient, getFacilities, params, match, getAllStaff, market } = this.props;
    getClient(match.params.clientId);
    getFacilities();
    getAllStaff({ city: market._id });
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URI}/admin/apartments?market=${this.props.market._id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      }
    );
    if (data) {
      data.unshift({ name: "None", apartment_id: null });
      this.setState({ apartments: data });
    }
  };

  componentWillUnmount = () => {
    this.props.modalActions.pop();
    this.props.resetUser();
  };

  handleSubmit = values => {
    const {
      onSubmit,
      match,
      showMessage,
      removeMessage,
      displayFormSuccess
    } = this.props;
    const clientId = match.params.clientId;
    const model = createUserService.getAddClientModel(values);
    return onSubmit(clientId, model).catch(err => {
      showMessage({
        message: err,
        messageType: "error"
      });
      setTimeout(removeMessage, 3000);
    });
  };

  handleClientActivation = (e, activate) => {
    e.preventDefault();
    const { client, modalActions } = this.props;
    const modal = (
      <ClientActivationConfirmationModal
        activateClient={activate}
        clientId={client._id}
      />
    );
    modalActions.push(modal);
  };

  handleAddLineItem = values => {
    const model = {
      ...values,
      amount:
        values.charge_or_credit === "Credit"
          ? values.amount * -1
          : values.amount,
      market_id: this.props.client.market_id
    };
    return (
      this.props
        .createLineItem(model)
        // Return server errors to form to display field-level errors and prevent success message
        .then(res => catchFormServerErrors(res))
    );
  };

  handleEditPet = (e, pet) => {
    e.stopPropagation();
    const { getClient, modalActions, match } = this.props;

    const onEditPetSuccess = () => {
      modalActions.pop();
      getClient(match.params.clientId);
    };

    const modalComponent = (
      <OverlayModal fullScreen onDismiss={modalActions.pop} textAlign="left">
        <EditPetPage
          onCancel={modalActions.pop}
          onEditPetSuccess={onEditPetSuccess}
          pet={pet}
        />
      </OverlayModal>
    );

    modalActions.push(modalComponent);
  };

  handleDeactivatePet = (e, petId) => {
    e.stopPropagation();
    const { modalActions, match } = this.props;
    const modal = (
      <DeactivatePetConfirmationModal
        clientId={match.params.clientId}
        petId={petId}
      />
    );
    modalActions.push(modal);
  };

  handleRetryOutstandingBalance = () => {
    const { client, retryOutstandingBalance } = this.props;
    retryOutstandingBalance(client._id);
  };

  handlePasswordReset = e => {
    e.preventDefault();
    const { client, modalActions } = this.props;
    const modal = (
      <PasswordResetConfirmationModal
        userType="client"
        email={client.contact.email}
      />
    );
    modalActions.push(modal);
  };

  renderPets = () => {
    const { client } = this.props;
    let petRows = (
      <div className="col-sm-12">
        <h4>
          No pets associated with this client. Click a button below to add one.
        </h4>
      </div>
    );

    if (client.pets && client.pets.length) {
      petRows = client.pets.map(pet => (
        <PetDetailsRow
          key={`pet2-${pet._id}`}
          editPet={e => this.handleEditPet(e, pet)}
          allowRemoval
          allowEdit
          pet={pet}
          removePet={e => this.handleDeactivatePet(e, pet._id)}
        />
      ));
    }

    return (
      <FormSection title="Edit Client Pets">
        <div className="row">{petRows}</div>
      </FormSection>
    );
  };

  renderPetButtons = () => {
    const { getClient, modalActions, match } = this.props;

    const onAddPetSuccess = () => {
      modalActions.pop();
      getClient(match.params.clientId);
    };

    const modalComponent = petType => (
      <OverlayModal fullScreen onDismiss={modalActions.pop} textAlign="left">
        <AddPetPage
          allowAddMultiple={false}
          clientId={match.params.clientId}
          onAddPetSuccess={onAddPetSuccess}
          onCancel={modalActions.pop}
          petType={petType}
        />
      </OverlayModal>
    );

    return (
      <FormSection>
        <div className="row">
          <div className="col-md-offset-3 col-md-2 col-sm-4 col-xs-4 button-column">
            <button
              className="btn btn-block btn-secondary"
              onClick={() => modalActions.push(modalComponent("Dog"))}
            >
              Add Dog
            </button>
          </div>
          <div className="col-md-2 col-sm-4 col-xs-4 button-column">
            <button
              className="btn btn-block btn-secondary"
              onClick={() => modalActions.push(modalComponent("Cat"))}
            >
              Add Cat
            </button>
          </div>
          <div className="col-md-2 col-sm-4 col-xs-4 button-column">
            <button
              className="btn btn-block btn-secondary"
              onClick={() => modalActions.push(modalComponent("Other"))}
            >
              Add Other
            </button>
          </div>
        </div>
      </FormSection>
    );
  };

  toggleAddCard = open => {
    this.setState({ editCard: open });
  };

  async sendConfirmEmail() {
    const { onSubmit, match, client, getClient } = this.props;
    const clientId = match.params.clientId;
    const user = await axios.get(
      `${process.env.REACT_APP_API_URI}/admin/activateClient/${clientId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      }
    );
    getClient(match.params.clientId);
  }

  render() {
    const {
      client,
      facilities,
      displayFormError,
      displayFormSuccess,
      formConstants,
      getClient,
      markets
    } = this.props;
    const { editCard } = this.state;
    let errorText = {
      color: "red",
      textAlign: "center"
    };

    if (client) {
      return (
        <span>
          <div className="card appts-title">
            <h4
              onClick={() =>
                this.setState({ showAppts: !this.state.showAppts })
              }
            >
              {this.state.showAppts
                ? "Hide Appointments"
                : "Click to see appointments"}
            </h4>
            {this.state.showAppts ? (
              <div>
                <ClientAppointmentCalendar
                  appointments={this.props.client.appointments}
                  visits={ this.props.client.facility_visits }
                />
              </div>
            ) : null}
          </div>

          <div className="card appts-title">
            <h4
              onClick={() =>
                this.setState({ showNotes: !this.state.showNotes })
              }
            >
              {this.state.showNotes ? "Hide Notes" : "Click to see notes"}
            </h4>
            {this.state.showNotes
              ? this.props.client.notes_list
                  .sort((a, b) => moment(a.date) - moment(b.date))
                  .map(note => {
                    return (
                      <div className="row">
                        <div className="col-sm-2">
                          {moment(note.date).format("MM/DD/YY")}
                        </div>
                        <div className="col-sm-8">
                          {note.sitter_notes_to_admin}
                        </div>
                        <div className="col-sm-2">
                          <Link to={`/appointments/${note._id}`} />
                        </div>
                      </div>
                    );
                  })
              : null}
          </div>

          {client && !client.confirmation_email_sent ? (
            <div className="card activateClient">
              <h4>Send Client Activation Email?</h4>
              <span>
                Once sent, the client will be able to set their password and log
                into the client app.
              </span>
              <br />
              <button
                className={`btn btn-${
                  this.state.showActivationConfirm ? "danger" : "primary"
                }`}
                onClick={() =>
                  this.setState({
                    showActivationConfirm: !this.state.showActivationConfirm
                  })
                }
              >
                {this.state.showActivationConfirm
                  ? "Cancel"
                  : "Send Activation Email"}
              </button>
              {this.state.showActivationConfirm ? (
                <button
                  className="btn btn-primary"
                  onClick={() => this.sendConfirmEmail()}
                >
                  Confirm Activation
                </button>
              ) : null}
            </div>
          ) : null}

          {client &&
          (!client.home.address.latitude || !client.home.address.longitude) ? (
            <div className="card" style={errorText}>
              Warning! This client is missing latitude or longitude in their
              profile. This will prevent them from being scheduled
              automatically.
            </div>
          ) : null}

          <div className="card">
            <PageTitleComponent title="Client" />

            {client ? (
              <ClientForm
                client={client}
                editExisting
                enableReinitialize
                markets={markets}
                formConstants={formConstants}
                team_members={this.props.team_members}
                handleActivation={this.handleClientActivation}
                handlePasswordReset={this.handlePasswordReset}
                apartments={this.state.apartments}
                initialValues={createUserService.getInitialFormValuesForClient(
                  client
                )}
                onSubmit={this.handleSubmit}
                onSubmitFail={errors => displayFormError(errors)}
              />
            ) : null}
          </div>

          <div className="card">
            <PageTitleComponent title="Client Pets" />
            {this.renderPets()}
            <br />
            {this.renderPetButtons()}
          </div>

          <CardComponent title="Client Credits">
            <ClientAddVisitsCard
              client={client}
              facilities={facilities}
              fetchSuccess={this.props.fetchSuccess}
              fetchRequest={this.props.fetchRequest}
              showMessage={this.props.showMessage}
              removeMessage={this.props.removeMessage}
            />
          </CardComponent>

          <CardComponent title="Client Punch Card & Promo">
            <div className="row">
              <div className="col-sm-6">
                <PunchCard client={client} />
              </div>
              <div className="col-sm-6">
                <ClientPromoCard
                  client={client}
                  fetchSuccess={this.props.fetchSuccess}
                  fetchRequest={this.props.fetchRequest}
                  modalActions={this.props.modalActions}
                  showMessage={this.props.showMessage}
                  removeMessage={this.props.removeMessage}
                />
              </div>
            </div>
          </CardComponent>

          <ClientBalanceCard
            balances={client.balances}
            retryOutstandingBalance={this.handleRetryOutstandingBalance}
          />

          <div className="card">
            <PageTitleComponent title="Credit Card" />

            <div className="container form-container">
              {editCard ? (
                <StripeCard
                  cards={client.cards}
                  clientId={client._id}
                  onDismiss={() => this.toggleAddCard(false)}
                />
              ) : null}

              {client.cards[0] ? (
                <div className="row">
                  <h3 className="col-sm-12 title">Current Card on File</h3>
                  <div className="cc-current-wrapper">
                    <div className="col-md-6 col-sm-3 col-xs-12 cc-field">
                      {client.cards[0].brand}
                    </div>
                    <div className="col-md-2 col-sm-3 col-xs-4 cc-field-date">
                      {client.cards[0].exp_month} / {client.cards[0].exp_year}
                    </div>
                    <div className="col-md-2 col-sm-3 col-xs-4 cc-field-last4">
                      {client.cards[0].last4}
                    </div>
                    <div className="col-md-2 col-sm-3 col-xs-4 button-column">
                      <button onClick={() => this.toggleAddCard(true)}>
                        {editCard ? "" : "Edit Card"}
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <StripeCard
                  cards={client.cards}
                  clientId={client._id}
                  onDismiss={() => this.toggleAddCard(false)}
                />
              )}
            </div>
          </div>

          <div className="card appointment-detail-page">
            <PageTitleComponent title="Charges/Credits" />

            <LineItems
              lineItems={client.line_items}
              modalActions={this.props.modalActions}
              showSpinner={this.props.fetchRequest}
              removeSpinner={this.props.fetchSuccess}
              showMessage={this.props.showMessage}
              removeMessage={this.props.removeMessage}
              callback={getClient}
              id={this.props.match.params.clientId}
            />

            <AddLineItemForm
              initialValues={{
                client_id: client._id,
                charge_or_credit: "Charge"
              }}
              hideClientField
              onSubmit={this.handleAddLineItem}
              onSubmitFail={errors => displayFormError(errors)}
              onSubmitSuccess={() => {
                displayFormSuccess("Line item successfully added.");
                getClient(client._id);
              }}
            />
          </div>
        </span>
      );
    }
    return null;
  }
}

const mapStateToProps = state => ({
  client: state.admin.clients.client,
  formConstants: state.general.formConstants,
  markets: state.admin.general.markets,
  team_members: state.admin.staff.staffList,
  market: state.admin.general.location,
  facilities: state.admin.facilities.facilities
});

const mapDispatchToProps = dispatch => ({
  fetchRequest: () => dispatch({ type: "FETCH_REQUEST" }),
  fetchSuccess: () => dispatch({ type: "FETCH_SUCCESS" }),
  resetUser: () => dispatch({ type: "RESET_USER", payload: null }),
  getAllStaff: qs => dispatch(getAllStaff(qs)),
  showMessage: message => dispatch({ type: "ADD_MESSAGE", payload: message }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" }),
  createLineItem: model => dispatch(createLineItemAction(model)),
  displayFormError: errors =>
    dispatch(showFormErrorMessage(errors, "addClientForm")),
  displayFormSuccess: message =>
    dispatch(showSuccessMessage(message, "addClientForm")),
  getClient: clientId => dispatch(getClientAction(clientId)),
  getFacilities: () => dispatch(getAllFacilitiesAction()),
  retryOutstandingBalance: clientId =>
    dispatch(processDelinquentClientBalanceAction({ client_id: clientId })),
  modalActions: {
    push: component => dispatch(pushModal(component)),
    pop: () => dispatch(popModal())
  },
  onSubmit: (clientId, model) => dispatch(updateClientAction(clientId, model))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditClientPage)
);
