import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import { pushModal, popModal } from "../../../actions/general.actions";
import { deactivatePet as deactivatePetAction } from "../../../actions/admin/clients.actions";

// Components
import { DefaultModal } from "../../";

class DeactivatePetConfirmationModal extends Component {

  confirmDeactivate = e => {
    e.preventDefault();
    const { clientId, deactivatePet, modalActions, petId } = this.props;
    deactivatePet(petId, clientId); // Here, success message gets triggered in action
    modalActions.pop();
  };

  render() {
    const { modalActions } = this.props;

    return (
      <DefaultModal
        title="Deactivate Pet"
        onDismiss={modalActions.pop}
        options={
          <div>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={modalActions.pop}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.confirmDeactivate}
            >
              Deactivate
            </button>
          </div>
        }
      >
        <p>Are you sure you want to deactivate this pet?</p>
        <p>
          This action will cancel any pending, approved, or change-requested
          appointments on which there are no other pets. Additionally, this pet
          will be removed from any pending, approved, or change-requested
          appointments on which there <i>are</i> other pets.
        </p>
      </DefaultModal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  deactivatePet: (petId, clientId) =>
    dispatch(deactivatePetAction(petId, clientId)),
  modalActions: {
    push: component => dispatch(pushModal(component)),
    pop: () => dispatch(popModal())
  }
});

export default connect(
  null,
  mapDispatchToProps
)(DeactivatePetConfirmationModal);
