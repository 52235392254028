import history from "../history";
import queryString from "query-string";
import { APIService } from "../../services";
import * as ActionTypes from "./actionTypes";
import { dispatchFetchFailure } from "../helpers";
import { showSuccessMessage } from "../general.actions";
import { getDashboardStats } from "./general.actions";
import moment from "moment";
import { getAllStaffWithAppointments } from "./staff.actions";
import { getAppointments, getActivities } from "./appointments.actions";

export const setSchedulerCalendarMonth = month => ({
  type: "SET_SCHEDULER_CALENDAR_MONTH",
  payload: month
});

export const setSchedulerCalendarWeek = week => ({
  type: "SET_SCHEDULER_CALENDAR_WEEK",
  payload: week
});

export const setSchedulerCalendarDay = day => ({
  type: "SET_SCHEDULER_CALENDAR_DAY",
  payload: day
});

export const addSitter = day => ({
  type: "WHEE",
  payload: day
});

export const setSchedulerSelectedDate = (date, city) => {
  return function(dispatch) {
    dispatch({
      type: "SET_SCHEDULER_SELECTED_DATE",
      payload: date
    });

    dispatch(
      getAppointments({
        startDate: moment(date)
          .startOf("day")
          .toDate(),
        endDate: moment(date)
          .endOf("day")
          .toDate(),
        city: city
      })
    );
    dispatch(
      getAllStaffWithAppointments({
        startDate: moment(date)
          .startOf("day")
          .toDate(),
        endDate: moment(date)
          .endOf("day")
          .toDate(),
        city: city
      })
    );
    dispatch(
      getActivities({
        startDate: moment(date)
          .startOf("day")
          .toDate(),
        endDate: moment(date)
          .endOf("day")
          .toDate(),
        city: city
      })
    );
  };
};

export const setSchedulerSelectedSitter = sitterId => ({
  type: "SET_SCHEDULER_SELECTED_SITTER",
  payload: {
    [`sitter`]: sitterId
  }
});

export const setSchedulerFilters = (filters, context) => ({
  type: "SET_SCHEDULER_FILTERS",
  payload: { filters, context }
});

export const setAppointment = appointment => {
  return {
    type: "SET_APPOINTMENT",
    payload: {
      appointment: appointment
    }
  };
};

export const setMapAppointment = appointment => {
  return {
    type: "SET_MAP_APPOINTMENT",
    payload: {
      appointment: appointment
    }
  };
};

export const resetAppointment = appointment => {
  return {
    type: "RESET_APPOINTMENT",
    payload: appointment
  };
};

export const getAppointmentsForSitter = sitter => {
  const context = "setSitterAppointments";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .getAppointmentsBySitter(sitter.id)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context }); // do not return
          dispatch({
            type: "SET_SITTER_APPOINTMENTS",
            payload: {
              appointments: response,
              sitter: sitter.id
            }
          });
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
};
