import React, { Component } from "react";
import Tooltip from "rc-tooltip";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import { ZoneBadge } from "../";

class PlayCareVisitRow extends Component {

  goToAppointmentDetails = appointmentId => {
    this.props.history.push(`/appointments/${appointmentId}`);
  };

  getLateness = () => {
    let time = moment().diff(
      moment(this.props.appointment.window_end_time),
      "minutes"
    );
    if (time < 0) return `${Math.abs(time)} minutes left`;
    return `Late by ${Math.abs(time)} minutes`;
  };

  getPetNames = appointment => {
    if (!appointment.pets || !appointment.pets.length) return null;
    if (appointment.pets.length === 1) return appointment.pets[0].name;
    return appointment.pets.map(p => p.name).join(", ");
  };

  render() {
    const { appointment, tab } = this.props;

    return (
      <div
        className="user-list-item admin-dash"
        key={`appointment-li-${appointment._id}`}
        onClick={() => this.goToAppointmentDetails(appointment._id)}
      >
        <div className="user-list-item-content username-container col-lg-2 col-md-2 col-sm-2 col-xs-8">
          <div className="user-list-name">{appointment.contact.full_name}</div>
          {appointment.appointment_status.name}
        </div>

        <div className="user-list-item-content time-requested col-lg-3 col-md-3 col-sm-3 col-xs-4">
          <div className="per-item">
            {moment(appointment.window_start_time).format(
              "ddd. M/D [at] h:mm A"
            )}
          </div>
          <div className="per-item">
            for {appointment.appointment_type.name}
            {appointment.recurrence && appointment.recurrence.is_recurring ? (
              <Tooltip
                placement="top"
                overlay={<div>Recurring Appointment</div>}
                mouseEnterDelay={0.2}
                mouseLeaveDelay={0.1}
                overlayClassName="annotation-tooltip"
              >
                <div className="btn-recurring">R</div>
              </Tooltip>
            ) : null}
          </div>
        </div>

        <div className="user-list-item-content col-lg-2 col-md-2 col-sm-2 hidden-xs">
          {appointment.sitter && appointment.sitter.full_name
            ? appointment.sitter.full_name
            : "Unassigned"}
        </div>

        <div className="user-list-item-content col-lg-1 col-md-1 col-sm-1 hidden-xs">
          {this.getPetNames(appointment)}
        </div>

        <div className="user-list-item-content notes col-lg-1 col-md-1 col-sm-1 hidden-xs">
          <div className="extra-notes">
            {appointment.client_notes && appointment.client_notes.length > 0 ? (
              <Tooltip
                placement="top"
                overlay={<div>{appointment.client_notes}</div>}
                mouseEnterDelay={0.2}
                mouseLeaveDelay={0.1}
                overlayClassName="annotation-tooltip"
              >
                <div className="extra-notes-item client">C</div>
              </Tooltip>
            ) : null}

            {appointment.admin_notes_to_sitter &&
            appointment.admin_notes_to_sitter.length > 0 ? (
              <Tooltip
                placement="top"
                overlay={<div>{appointment.admin_notes_to_sitter}</div>}
                mouseEnterDelay={0.2}
                mouseLeaveDelay={0.1}
                overlayClassName="annotation-tooltip"
              >
                <div className="extra-notes-item client">A</div>
              </Tooltip>
            ) : null}
          </div>
        </div>
        <div className="user-list-item-content notes col-lg-3 col-md-3 col-sm-3 hidden-xs"></div>
      </div>
    );
  }
}

export default withRouter(PlayCareVisitRow);
