import React, { Component } from "react";

export default class SelectField extends Component {

  render = () => {
    const { input, meta, options, placeholder } = this.props;
    const { touched, error } = meta;

    const errorClass = touched && error ? " error-text error-border" : "";

    return (
      <div>
        <select
          {...input}
          value={input.value}
          className={`form-control form-height select${errorClass}`}
        >
          <option value="-1">{placeholder}</option>
          {options.map((option, index) => (
            <option key={`option-${option.id}-${index}`} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
        <i className="fa fa-chevron-down chevron"></i>
      </div>
    );
  };
}
