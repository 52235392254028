import React, { Component } from "react";

export default class CheckboxField extends Component {

  static defaultProps = {
    disabled: false
  };

  render = () => {
    const { label, input } = this.props;

    // We can specify a separate uniqueId prop for PetCards, since several of these are often
    // rendered simultaneously. We still want a static name for redux form submission.
    const uniqueId = this.props.uniqueId || input.name;

    return (
      <div className="simple-checkbox-field">
        <div className="simple-checkbox-input">
          <input type="checkbox" id={uniqueId} {...input} />
          <label htmlFor={uniqueId} />
        </div>
        <span
          className="simple-checkbox-label"
          onClick={() => input.onChange(!input.checked)}
        >
          {label}
        </span>
      </div>
    );
  };
}
