import React, { Component } from "react";
import { DatePicker } from "react-widgets";
import { connect } from "react-redux";
import axios from "../../services/network.js";
import moment from "moment";
import { CardComponent, DateRow } from "../../components";

class Vacation extends Component {
  state = {
    days: [],
    date: moment().toDate()
  };

  componentWillMount = () => {
    this.getVacationDays();
  };

  getVacationDays = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URI}/admin/vacation`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
        }
      }
    );

    this.setState({ days: data });
  };

  createVacationDay = async () => {
    const { showSpinner, stopSpinner, showMessage, removeMessage } = this.props;
    showSpinner();
    try {
      const body = { date: moment(this.state.date).format("YYYY-MM-DD") };
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/vacation`,
        body,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken")
          }
        }
      );
      if (data.success) {
        this.getVacationDays();
        showMessage({
          message: "Sucessfully created new Vacation Date",
          messageType: "success"
        });
        setTimeout(removeMessage, 3000);
      }
    } catch (e) {
      console.log("the errrrrr", e);
      showMessage({
        message: "Unable to create vacation date",
        messageType: "error"
      });
      setTimeout(this.removeMessage, 3000);
    }
    stopSpinner();
  };

  handleUpdate = val => {
    this.setState({ date: val });
  };

  renderVacationDates = () => {
    return this.state.days.map(d => {
      return (
        <DateRow model={d} key={d._id} getVacationDays={this.getVacationDays} />
      );
    });
  };

  render() {
    return (
      <CardComponent title="Vacation">
        <div className="vacation-container">
          <div className="vacation-date-row row">
            <div className="col-md-3">
              <DatePicker
                defaultValue={moment().toDate()}
                value={this.state.current_date}
                onChange={e => {
                  this.handleUpdate(e);
                }}
                time={false}
              />
            </div>
            <button
              className="btn btn-primary"
              onClick={this.createVacationDay}
            >
              Add
            </button>
          </div>
          {this.renderVacationDates()}
        </div>
      </CardComponent>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showMessage: message => dispatch({ type: "ADD_MESSAGE", payload: message }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" }),
  showSpinner: () => dispatch({ type: "FETCH_REQUEST" }),
  stopSpinner: () => dispatch({ type: "FETCH_SUCCESS" })
});
export default connect(
  null,
  mapDispatchToProps
)(Vacation);
