import React, { Component } from "react";
import { OverlayModal } from "../../";

export default class AddPetOverlay extends Component {

  state = {
    selections: []
  };

  handleClickPet = id => {
    const { selections } = this.state;
    const petIsSelected = selections.includes(id);
    if (petIsSelected) {
      const newState = selections.filter(petId => petId !== id);
      this.setState({ selections: newState });
    } else {
      this.setState({ selections: [...selections, id] });
    }
  };

  handleSubmit = () => {
    const { selections } = this.state;
    const { onAddPets, onDismiss } = this.props;
    onAddPets(selections);
    onDismiss();
  };

  render() {
    const { selections } = this.state;
    const { onDismiss, clientPets, selectedPets } = this.props;
    return (
      <OverlayModal onDismiss={onDismiss}>
        <div className="row row-modal">
          <h1>Select Pet(s) to Add to This Appointment</h1>
        </div>

        <div className="row row-modal">
          <div className="add-pet-card-wrapper">
            {clientPets
              .filter(pet => !selectedPets.includes(pet._id))
              .map(pet => {
                const petIsSelected = selections.includes(pet._id);
                const selectedClass = petIsSelected ? " selected" : "";
                return (
                  <div
                    className={`add-pet-card${selectedClass}`}
                    key={`add-pet-${pet._id}`}
                    onClick={() => this.handleClickPet(pet._id)}
                  >
                    <div
                      className="pet-img"
                      style={{ backgroundImage: `url(${pet.image_url})` }}
                    ></div>
                    <p>{pet.name}</p>
                  </div>
                );
              })}
          </div>
        </div>

        {selections.length ? (
          <div className="row row-modal m-t-sm">
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleSubmit}
            >
              Add Pets
            </button>
          </div>
        ) : null}

        <div className="row m-t-sm">
          <h5 className="user-overlay-cancel" onClick={onDismiss}>
            Nevermind, I don't want to add any pets.
          </h5>
        </div>
      </OverlayModal>
    );
  }
}
