import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { formatNumsToDollars } from "../../helpers";

export default class PaymentStripeSubListItem extends React.Component {

  render() {
    const { listItem, userType } = this.props;

    let PSSLI__DetailsClass;
    if (userType === "client")
      PSSLI__DetailsClass =
        "user-list-item-content col-lg-5 col-md-3 col-sm-3 hidden-xs";
    if (userType === "admin")
      PSSLI__DetailsClass =
        "user-list-item-content col-lg-1 col-md-5 col-sm-3 hidden-xs";

    return (
      <div className="user-list-item stripecharge-sub-item">
        {userType === "admin" ? (
          <div className="user-list-item-content col-lg-1 col-md-1 col-sm-2 hidden-xs">
            {moment(listItem.date).format("MM/DD/YY")}
          </div>
        ) : null}

        {userType === "client" ? (
          <div className="user-list-item-content col-lg-6 col-md-5 col-sm-7 col-xs-9">
            {moment(listItem.date).format("MM/DD/YY")} - {listItem.description}
          </div>
        ) : (
          <div className="user-list-item-content col-lg-4 col-md-4 col-sm-4 hidden-xs">
            {listItem.description}
          </div>
        )}

        <div
          className={
            userType === "client"
              ? "user-list-item-content row-amount col-lg-1 col-md-2 col-sm-2 col-xs-3"
              : "user-list-item-content row-amount col-lg-1 col-md-3 col-sm-3 hidden-xs"
          }
        >
          {listItem.amount >= 0 ? (
            <span className="color-black">
              {formatNumsToDollars(listItem.amount * -1)}
            </span>
          ) : (
            <span>{formatNumsToDollars(listItem.amount * -1)}</span>
          )}
        </div>

        <div className={PSSLI__DetailsClass}>
          {listItem.appointment_id && (
            <Link
              to={`/${userType === "admin" ? "admin" : "client"}/appointments/${
                listItem.appointment_id
              }`}
            >
              Appointment <span className="fa fa-long-arrow-right" />
            </Link>
          )}
        </div>
      </div>
    );
  }
}
