import axios from "axios";
import firebase from "firebase";
const BASE_URL = process.env.REACT_APP_API_URI;

const network = axios.create({
  baseURL: BASE_URL,
  timeout: 30 * 1000,
  withCredentials: false
  // credentials: true
});

network.interceptors.response.use(
  response => {
    return response;
  },
  async function(error) {
    // if a 401 response is returned, refresh the id token and retry one time
    if (
      error.response &&
      error.response.status === 401 &&
      !error.config.__isRetryRequest
    ) {
      error.config.__isRetryRequest = true;
      let user = firebase.auth().currentUser;
      if (user) {
        const token = await firebase.auth().currentUser.getIdToken(true);
        error.config.headers["Authorization"] = `Bearer ${token}`;
        return network.request(error.config);
      } else {
        return Promise.reject(error.response ? error.response : error);
      }
    }

    return Promise.reject(error.response ? error.response : error);
  }
);

export default network;
