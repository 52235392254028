import React, { Component } from "react";
import { DatePicker } from "react-widgets";
import { connect } from "react-redux";
import moment from "moment";
import axios from "../../../services/network.js";

class DateRow extends Component {
  state = {
    editing: false,
    current_date: null,
    prev_date: null
  };

  componentWillMount = () => {
    this.setState({
      current_date: moment(this.props.model.date, "MM/DD/YYYY").toDate()
    });
  };

  toggleEdit = cancel => {
    this.setState({
      editing: !this.state.editing,
      prev_date: !this.state.editing ? this.state.current_date : null,
      current_date: cancel ? this.state.prev_date : this.state.current_date
    });
  };

  handleUpdate = val => {
    this.setState({ current_date: val });
  };

  updateVacation = async () => {
    const body = {
      date: moment(this.state.current_date).format("YYYY-MM-DD")
    };
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URI}/admin/vacation/${this.props.model._id}`,
        body,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken")
          }
        }
      );
      this.setState({
        current_date: moment(data.date).toDate(),
        editing: false
      });
    } catch (e) {
      console.log(e);
    }
  };

  deleteVacation = async id => {
    if (id) {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URI}/admin/vacation/${id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken")
          }
        }
      );
      if (data.success) this.props.getVacationDays();
    }
  };

  renderRow = () => {
    const { model } = this.props;
    return (
      <div>
        <div className="col-md-3">
          {moment(this.state.current_date, "YYYY-MM-DD").format("MM/DD/YYYY")}
        </div>
        <div className="actions">
          <button
            className="btn btn-primary"
            onClick={() => {
              this.toggleEdit(false);
            }}
          >
            Edit
          </button>
          <button
            className="btn btn-danger normal-size-btn"
            onClick={() => {
              this.deleteVacation(model._id);
            }}
          >
            Delete
          </button>
        </div>
      </div>
    );
  };

  renderEditRow = () => {
    const { model } = this.props;
    return (
      <div>
        <div className="col-md-3">
          <DatePicker
            defaultValue={moment(model.date, "MM/DD/YYYY").toDate()}
            value={this.state.current_date}
            onChange={e => {
              this.handleUpdate(e);
            }}
            time={false}
          />
        </div>
        <div className="actions">
          <button className="btn btn-primary" onClick={this.updateVacation}>
            Save
          </button>
          <button
            className="btn btn-danger normal-size-btn"
            onClick={() => {
              this.toggleEdit(true);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="vacation-date-row">
        {this.state.editing ? this.renderEditRow() : this.renderRow()}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showMessage: message => dispatch({ type: "ADD_MESSAGE", payload: message }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" }),
  showSpinner: () => dispatch({ type: "FETCH_REQUEST" }),
  stopSpinner: () => dispatch({ type: "FETCH_SUCCESS" })
});
export default connect(
  null,
  mapDispatchToProps
)(DateRow);
