import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class PageTitleComponent extends Component {

  handleBackButton = e => {
    e.preventDefault();
    this.props.history.push("/");
  };

  render() {
    const { back } = this.props;

    return (
      <div className="text-center page-title">
        {back ? (
          <button className="btn btn-back" onClick={this.handleBackButton}>
            <span className="fa fa-chevron-left" /> Back
          </button>
        ) : null}
        <h1>{this.props.title}</h1>
        <hr className="hr" />
      </div>
    );
  }
}

export default withRouter(PageTitleComponent);
