import React, { Component } from "react";
import { connect } from "react-redux";
import history from "../../actions/history";
import axios from "../../services/network.js";

import { CardComponent, PromoForm, ReferralTable } from "../../components";

class EditPromo extends Component {
  state = {
    apartments: [],
    promo: null
  };

  componentWillMount = async () => {
    const { showSpinner, stopSpinner, removeMessage } = this.props;
    removeMessage();
    showSpinner();
    const part = this.getPartners(this.props.market._id);
    const p = this.getPromo(
      this.props.match.params.promoId,
      this.props.market._id
    );
    const [partners, promo] = await Promise.all([part, p]);

    this.setState({ partners: partners, promo: promo });
    stopSpinner();
  };

  getPartners = async id => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URI}/admin/partners?market_id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      }
    );
    return data;
  };

  getPromo = async (id, market_id) => {
    const { showMessage, removeMessage } = this.props;
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URI}/admin/promo/${id}?marketId=${market_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );
      data.expiration_date = new Date(data.expiration_date);
      return new Promise((yea, no) => {
        yea(data);
      });
    } catch (e) {
      console.log(e.data);
      if (e.data && e.data.message) {
        showMessage({
          message: e.data.message,
          messageType: "error"
        });
      } else {
        showMessage({
          message: "Error fetching promo",
          messageType: "error"
        });
      }
      setTimeout(removeMessage, 3000);
    }
  };

  updatePromo = async (promo, valid) => {
    const { showSpinner, stopSpinner, showMessage, removeMessage } = this.props;

    showSpinner();
    if (valid) {
      try {
        const { data } = await axios.put(
          `${process.env.REACT_APP_API_URI}/admin/promo/${promo._id}?marketId=${this.props.market._id}`,
          promo,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            }
          }
        );
        showMessage({
          message: "Promo code successfully updated",
          messageType: "success"
        });
        setTimeout(removeMessage, 3000);
        promo = await this.getPromo(promo._id, this.props.market._id);
        this.setState({ promo: promo });
      } catch (e) {
        if (e.data && e.data.message) {
          showMessage({
            message: e.data.message,
            messageType: "error"
          });
        } else {
          showMessage({
            message: "Error updating promo",
            messageType: "error"
          });
        }
        setTimeout(removeMessage, 3000);
      }
    } else {
      showMessage({
        message: "Invalid data, check your form for errors",
        messageType: "error"
      });
      setTimeout(removeMessage, 3000);
    }
    stopSpinner();
  };

  render() {
    const valid_types = ["client", "staff", "partner", "general"];
    const valid_kickback_types = ["money", "credit"];
    const valid_threshold_types = ["walks"];
    return (
      <div>
        <CardComponent title="Edit Promo">
          <PromoForm
            partners={this.state.partners}
            markets={this.props.markets}
            promo={this.state.promo}
            savePromo={this.updatePromo}
          />
        </CardComponent>
        <CardComponent title="Referrals">
          {this.state.promo && this.state.promo.referrals.length ? (
            <ReferralTable referrals={this.state.promo.referrals} />
          ) : (
            <div>No referrals for this promo code</div>
          )}
        </CardComponent>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  market: state.admin.general.location,
  markets: state.admin.general.markets
});

const mapDispatchToProps = dispatch => ({
  showSpinner: () => dispatch({ type: "FETCH_REQUEST" }),
  stopSpinner: () => dispatch({ type: "FETCH_SUCCESS" }),
  showMessage: msg => dispatch({ type: "ADD_MESSAGE", payload: msg }),
  removeMessage: () => dispatch({ type: "REMOVE_MESSAGE" })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPromo);
