/*eslint-disable*/
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

// Pages
import { App } from "./pages";

// src/App.js

import React, { Component } from "react";

export default {
  render(store) {
    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      document.getElementById("root")
    );
  }
};
