import React, { Component } from "react";
import { reduxForm, Field, Fields, FieldArray } from "redux-form";
import validate from "./formValidator.js";
import {
  DateTimeField,
  DropdownListField,
  FieldColumnWrapper,
  FormSection,
  FormSubHeader,
  InputField,
  ProfileImageField,
  TextAreaField
} from "../../../shared/formComponents";
import {
  dogColors,
  catColors,
  dogBreeds,
  catBreeds,
  otherBreeds
} from "../../../../services/constants";
import MedicineForm from "./MedicineForm.js";

class PetForm extends Component {

  cancelForm = e => {
    e.preventDefault();
    this.props.onCancel();
  };

  renderPetClassFields = fields => {
    const { formConstants } = this.props;
    const petClass = fields.petclass.input.value;
    return (
      <div>
        <FieldColumnWrapper column="4">
          <Field
            component={DropdownListField}
            name="petclass"
            data={formConstants.classifications} // DropdownList: Array of strings or objects or other primitives to populate
            valueField="id" // DropdownList: If data is obj, determines which property is the id
            textField="name" // DropdownList: If data is obj, determines which property gets displayed
            parse={(val, name) => val.id} // reduxForm: parse decides what goes into the state in the form store
            placeholder="Pet Class"
            required
          />
        </FieldColumnWrapper>

        {(petClass === 2 || petClass === 3) && (
          <FieldColumnWrapper column="4">
            <Field
              component={TextAreaField}
              name="petnotes"
              type="text"
              placeholder="Notes"
              required
            />
          </FieldColumnWrapper>
        )}
      </div>
    );
  };

  // TODO: Look into a better way to handle redux-form submission.
  submitAndAdd = values => {
    return this.props.onSubmit(values, true); // values, addAnother = true
  };

  submitAndFinish = values => {
    return this.props.onSubmit(values, false); // values, addAnother = false
  };

  renderBreeds = () => {
    const { petType } = this.props;
    if (petType === "Dog") {
      return (
        <Field
          component={DropdownListField}
          allowCreate
          data={dogBreeds}
          name="breed"
          type="text"
          placeholder="Breed"
        />
      );
    } else if (petType === "Cat") {
      return (
        <Field
          component={DropdownListField}
          allowCreate
          data={catBreeds}
          name="breed"
          type="text"
          placeholder="Breed"
        />
      );
    } else {
      return (
        <Field
          component={DropdownListField}
          allowCreate
          data={otherBreeds}
          name="breed"
          type="text"
          placeholder="Breed"
        />
      );
    }
  };

  renderColors = () => {
    const { petType } = this.props;
    if (petType === "Dog") {
      return (
        <Field
          component={DropdownListField}
          allowCreate
          data={dogColors}
          name="color"
          type="text"
          placeholder="Color"
        />
      );
    } else if (petType === "Cat") {
      return (
        <Field
          component={DropdownListField}
          allowCreate
          data={catColors.concat(dogColors).sort()}
          name="color"
          type="text"
          placeholder="Color"
        />
      );
    } else {
      return (
        <Field
          component={DropdownListField}
          allowCreate
          data={dogColors}
          name="color"
          type="text"
          placeholder="Color"
        />
      );
    }
  };

  renderPetTypeField = () => {
    const { petType } = this.props;
    if (petType === "Dog") {
      return (
        <Field
          component={DropdownListField}
          name="age"
          data={["Puppy (< 1 Year)", "Adult", "Senior (> 7 Years)"]} // DropdownList: Array of strings or objects or other primitives to populate
          placeholder="Age"
        />
      );
    } else if (petType === "Cat") {
      return (
        <Field
          component={DropdownListField}
          name="age"
          data={["Kitten (< 1 Year)", "Adult", "Senior (>10 Years)"]} // DropdownList: Array of strings or objects or other primitives to populate
          placeholder="Age"
        />
      );
    } else {
      return (
        <Field
          component={DropdownListField}
          name="age"
          data={["Young (< 1 Year)", "Adult", "Senior"]} // DropdownList: Array of strings or objects or other primitives to populate
          placeholder="Age"
        />
      );
    }
  };

  render() {
    const {
      allowAddMultiple,
      displayFormError,
      editExisting,
      formConstants,
      handleSubmit,
      petType
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        {/* General Information */}
        <FormSection title="General Information">
          <div className="row">
            <FieldColumnWrapper column="4">
              <Field
                component={ProfileImageField}
                label="Add Pet Photo"
                name="profile_image"
                onDropRejected={e =>
                  displayFormError({
                    customMessageText:
                      "Oops, that file didn't stick. Make sure it's an image under 2MB."
                  })
                }
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={InputField}
                name="name"
                type="text"
                placeholder="Pet Name"
                required
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                name="sex"
                data={[
                  "Intact Male",
                  "Neutered Male",
                  "Intact Female",
                  "Spayed Female",
                  "Unknown"
                ]} // DropdownList: Array of strings or objects or other primitives to populate // DropdownList: If data is obj, determines which property is the id
                placeholder="Sex"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              {this.renderBreeds()}
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              {this.renderPetTypeField()}
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              {this.renderColors()}
            </FieldColumnWrapper>

            {petType !== "Other" && petType !== "Cat" && (
              <FieldColumnWrapper column="4">
                <Field
                  component={DropdownListField}
                  name="size"
                  type="text"
                  data={[
                    "Small (<20 lbs)",
                    "Medium (20 - 50 lbs)",
                    "Large (50 - 80 lbs)",
                    "Extra Large (>80 lbs)"
                  ]}
                  placeholder="Size"
                />
              </FieldColumnWrapper>
            )}

            {petType !== "Other" && (
              <FieldColumnWrapper column="4">
                <Field
                  component={InputField}
                  name="microchip"
                  type="text"
                  placeholder="Microchip ID"
                />
              </FieldColumnWrapper>
            )}

            {petType === "Cat" && (
              <FieldColumnWrapper column="4">
                <Field
                  component={DropdownListField}
                  name="indoor"
                  data={[
                    { id: "Indoor", name: "Indoor" },
                    { id: "Outdoor", name: "Outdoor" },
                    { id: "Indoor/Outdoor", name: "Indoor/Outdoor" }
                  ]}
                  valueField="id" // DropdownList: If data is obj, determines which property is the id
                  textField="name" // DropdownList: If data is obj, determines which property gets displayed
                  parse={(val, name) => val.id} // reduxForm: parse decides what goes into the state in the form store
                  placeholder="Indoor/Outdoor?"
                />
              </FieldColumnWrapper>
            )}

            <FieldColumnWrapper column="4">
              <Field
                component={TextAreaField}
                name="personality"
                type="text"
                placeholder="Personality"
              />
            </FieldColumnWrapper>

            <Fields
              names={["petclass", "petnotes"]}
              component={this.renderPetClassFields}
            />

            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                name="birthday_month"
                data={[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December"
                ]}
                placeholder="Birthday Month"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={InputField}
                name="origin"
                placeholder="Pet Origin"
              />
            </FieldColumnWrapper>
            {petType === "Dog" ? (
              <FieldColumnWrapper column="4">
                <Field
                  component={DropdownListField}
                  name="playcare_approved"
                  data={[
                    { text: "Yes", value: true },
                    { text: "No", value: false }
                  ]}
                  valueField={"value"}
                  textField={"text"}
                  parse={val => val.value}
                  placeholder="Playcare Approved?"
                />
              </FieldColumnWrapper>
            ) : null}
            {petType === "Dog" ? (
              <FieldColumnWrapper column="4">
                <Field
                  component={DropdownListField}
                  name="group_preference"
                  data={[
                    { text: "None", value: null },
                    { text: "Large", value: "large" },
                    { text: "Small", value: "small" }
                  ]}
                  textField={"text"}
                  valueField={"value"}
                  parse={val => val.value}
                  placeholder="Group Size Preference"
                />
              </FieldColumnWrapper>
            ) : null}
            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                name="temperament_test_status"
                placeholder="Temperament Test Status"
                data={[
                  { text: "Unscheduled", value: "Unscheduled" },
                  { text: "Scheduled", value: "Scheduled" },
                  { text: "Completed", value: "Completed" }
                ]}
                valueField={"value"}
                textField={"text"}
                parse={val => val.value}
              />
            </FieldColumnWrapper>
          </div>
        </FormSection>

        {/* Visit Details */}
        <FormSection title="Visit Details">
          <div className="row">
            {petType !== "Cat" && (
              <FieldColumnWrapper column="4">
                <Field
                  component={TextAreaField}
                  name="pet_location"
                  type="text"
                  placeholder="Pet Location"
                />
              </FieldColumnWrapper>
            )}

            {petType === "Dog" && (
              <FieldColumnWrapper column="4">
                <Field
                  component={TextAreaField}
                  name="leash_collar"
                  type="text"
                  placeholder="Leash / Collar Information"
                />
              </FieldColumnWrapper>
            )}

            {petType === "Dog" && (
              <FieldColumnWrapper column="4">
                <Field
                  component={TextAreaField}
                  name="harness"
                  type="text"
                  placeholder="Harness Information"
                />
              </FieldColumnWrapper>
            )}

            {petType === "Cat" && (
              <FieldColumnWrapper column="4">
                <Field
                  component={TextAreaField}
                  name="litterbox"
                  type="text"
                  placeholder="Litter Box Information"
                />
              </FieldColumnWrapper>
            )}

            <FieldColumnWrapper column="4">
              <Field
                component={TextAreaField}
                name="toy_info"
                type="text"
                placeholder="Toy Information"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={TextAreaField}
                name="bowl_location"
                type="text"
                placeholder="Bowl Location"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={TextAreaField}
                name="food_location"
                type="text"
                placeholder="Food Location"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={TextAreaField}
                name="brand_of_food"
                type="text"
                placeholder="Food Brand"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={TextAreaField}
                name="treat_info"
                type="text"
                placeholder="Treat Information"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={TextAreaField}
                name="food_instructions"
                type="text"
                placeholder="Food Instructions"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={TextAreaField}
                name="water_instructions"
                type="text"
                placeholder="Water Instructions"
              />
            </FieldColumnWrapper>
          </div>
        </FormSection>

        {/* Health */}
        <FormSection title="Health">
          <div className="row">
            <FieldColumnWrapper column="8">
              <Field
                component={InputField}
                name="allergies"
                type="text"
                placeholder="Allergies"
              />
            </FieldColumnWrapper>
            <FieldColumnWrapper column="4">
              <Field
                component={DateTimeField}
                name="rabies_vaccination_date"
                time={false}
                dateFormat="MMM. D, Y"
                placeholder="Rabies Expiration"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={InputField}
                name="vet_clinic"
                type="text"
                placeholder="Vet Clinic"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={InputField}
                name="vet_phone"
                type="text"
                placeholder="Vet Phone Number"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={InputField}
                name="vet_address"
                type="text"
                placeholder="Vet Address"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={InputField}
                name="vet_city"
                type="text"
                placeholder="Vet City"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                name="vet_state"
                data={formConstants.states} // DropdownList: Array of strings or objects or other primitives to populate
                valueField="id" // DropdownList: If data is obj, determines which property is the id
                textField="name" // DropdownList: If data is obj, determines which property gets displayed
                filter="contains" // DropdownList: If you want to filter, include this with string indicating filter type
                parse={(val, name) => val.id} // reduxForm: parse decides what goes into the state in the form store
                placeholder="Vet State"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={InputField}
                name="vet_zip_code"
                type="text"
                placeholder="Vet Zip Code"
              />
            </FieldColumnWrapper>
          </div>
        </FormSection>

        <br />

        {/* Buttons */}
        <FormSection>
          <div className="row">
            <div className="col-sm-12" style={{ padding: "25px 0" }}>
              <div className="hidden-xs hidden-sm col-md-3">
                <a
                  className="btn btn-block btn-tertiary"
                  onClick={this.cancelForm}
                >
                  Cancel
                </a>
              </div>
              <div className="hidden-xs hidden-sm col-md-3 pull-right">
                <a
                  className="btn btn-block btn-primary"
                  onClick={handleSubmit(this.submitAndFinish)}
                >
                  Submit
                </a>
              </div>
              {!editExisting && allowAddMultiple ? (
                <div className="hidden-xs hidden-sm col-md-3 pull-right">
                  <a
                    className="btn btn-block btn-secondary"
                    onClick={handleSubmit(this.submitAndAdd)}
                  >
                    Add Another Pet
                  </a>
                </div>
              ) : null}
              <div className="visible-xs visible-sm col-sm-12 p-b-sm">
                <a
                  className="btn btn-block btn-primary"
                  onClick={handleSubmit(this.submitAndFinish)}
                >
                  Submit
                </a>
              </div>
              {!editExisting && allowAddMultiple ? (
                <div className="visible-xs visible-sm col-sm-12 p-b-sm">
                  <a
                    className="btn btn-block btn-secondary"
                    onClick={handleSubmit(this.submitAndAdd)}
                  >
                    Add Another Pet
                  </a>
                </div>
              ) : null}
              <div className="visible-xs visible-sm col-sm-12">
                <a
                  className="btn btn-block btn-tertiary"
                  onClick={this.cancelForm}
                >
                  Cancel
                </a>
              </div>
            </div>
          </div>
        </FormSection>
      </form>
    );
  }
}

export default reduxForm({ form: "addPet", validate })(PetForm);
