import React from "react";
import GoogleApiComponent from "../../../helpers/GoogleApiComponent";
import MapComponent from "./MapComponent";

class KMLMap extends React.Component {

  render() {
    const { google, kmlUrl, marker } = this.props;

    return <MapComponent google={google} kmlUrl={kmlUrl} marker={marker} />;
  }
}

export default GoogleApiComponent({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY
})(KMLMap);
