import React, { Component } from "react";
import { connect } from "react-redux";
import { getAllStaff } from "../../actions/admin/staff.actions";
import { Link } from "react-router-dom";
import { StaffTableRow } from "../../components";
import axios from "../../services/network.js";
import {
  showSuccessMessage,
  showFailMessage
} from "../../actions/general.actions";

class NewPets extends Component {
  constructor() {
    super();

    this.getPets = this.getPets.bind(this);
    this.approvePet = this.approvePet.bind(this);
  }

  state = {
    pets: []
  };

  componentWillMount = () => {
    this.getPets();
  };

  async getPets() {
    try {
      const newPets = await axios.get(
        `${process.env.REACT_APP_API_URI}/admin/newPets`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken")
          }
        }
      );
      this.setState({ pets: newPets.data });
    } catch (e) {
      this.props.showFailMessage("Error getting pets.");
    }
  }

  async approvePet(petId) {
    try {
      this.props.fetchRequest();
      await axios.put(
        `${process.env.REACT_APP_API_URI}/admin/newPets/${petId}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken")
          }
        }
      );
      this.props.showSuccessMessage("Pet Approved!");
      await this.getPets();
      this.props.fetchSuccess();
    } catch (e) {
      this.props.showFailMessage("Error approving pet.");
      this.props.fetchSuccess();
    }
  }

  render() {
    return (
      <div className="card p-t-none">
        <h3>New Pets</h3>
        {this.state.pets.length
          ? this.state.pets.map(pet => {
              return (
                <div className="pet row">
                  <div className="col-sm-3">
                    {pet.name} ({pet.client.full_name})
                  </div>
                  <div className="col-sm-3">
                    <Link to={`/client/${pet.client._id}`} key={pet._id}>
                      View Client & Pets
                    </Link>
                  </div>
                  <div className="col-sm-3">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        this.approvePet(pet._id);
                      }}
                    >
                      Approve Pet
                    </button>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.general.user
});

const mapDispatchToProps = dispatch => ({
  fetchRequest: () => dispatch({ type: "FETCH_REQUEST", context: null }),
  fetchSuccess: () => dispatch({ type: "FETCH_SUCCESS", context: null }),
  showSuccessMessage: message => dispatch(showSuccessMessage(message)),
  showFailMessage: message => dispatch(showFailMessage(message))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPets);
