import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import auth from '../../services/auth';
import { logout } from "../../services/firebaseHelpers";
import history from "../../actions/history";

class UserDropdownMenu extends Component {

  state = {
    userDropdownOpen: false
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.hideDropdown);
  }

  showDropdown = () => {
    this.setState({ userDropdownOpen: true });
    document.addEventListener("click", this.hideDropdown);
  };

  hideDropdown = () => {
    this.setState({ userDropdownOpen: false });
    document.removeEventListener("click", this.hideDropdown);
  };

  logout = () => {
    logout();
    history.push("/login");
  };

  render() {
    const user = this.props.user;
    if (user) {
      const { userDropdownOpen } = this.state;

      return (
        <li className={`dropdown${userDropdownOpen ? " open" : ""}`}>
          <a className="dropdown-toggle" onClick={this.showDropdown}>
            <span className="fa fa-user user-icon" />
            {user.firstName}
            <span className="chevron fa fa-chevron-down" />
          </a>

          <div className="dropdown-menu nav-client">
            <ul>
              {user.roles && user.roles.indexOf("admin") ? (
                <Link to="/systemsettings">
                  <li>
                    <span className="fa fa-cog dropdown-icon" />
                    System Settings
                  </li>
                </Link>
              ) : null}

              <li onClick={() => this.logout()}>
                <span className="fa fa-power-off dropdown-icon" />
                Sign Out
              </li>
            </ul>
          </div>
        </li>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => ({
  formConstants: state.general.formConstants,
  user: state.general.user
});

export default connect(mapStateToProps)(UserDropdownMenu);
