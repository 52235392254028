import React, { Component } from "react";
import { DefaultModal } from "../../";

export default class CancelRecurringAppointment extends Component {

  render() {
    const { onConfirm, onDismiss } = this.props;

    return (
      <DefaultModal
        title="Appointment Cancellation"
        onDismiss={onDismiss}
        options={
          <div>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onDismiss}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => onConfirm(true)}
            >
              All Appointments
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => onConfirm(false)}
            >
              Only This One
            </button>
          </div>
        }
      >
        <p>
          This appointment is part of a recurring series. Would you like to
          cancel only this appointment, or all non-completed appointments in
          this series?
        </p>

        <p>
          Please note that any same-day cancellations after 8am will be subject
          to a 50% cancellation charge. Cancellations within one hour of the
          requested time will be charged at 100%.
        </p>
      </DefaultModal>
    );
  }
}
