import React, { Component } from "react";
import { Link } from "react-router-dom";
import { OverlayModal } from "../../";

export default class AddUserOverlay extends Component {


  render() {
    const { onDismiss } = this.props;
    return (
      <OverlayModal onDismiss={onDismiss}>
        <div className="row">
          <h2 className="staff-overlay-title">
            What type of user would you like to add?
          </h2>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6 text-right">
            <Link to="/addstaff" onClick={onDismiss}>
              <button className="btn btn-secondary btn-lg overlay-btn">
                Staff
              </button>
            </Link>
          </div>

          <div className="col-sm-12 col-md-6 text-left">
            <Link to="/addclient" onClick={onDismiss}>
              <button className="btn btn-secondary btn-lg overlay-btn">
                Client
              </button>
            </Link>
          </div>
        </div>
        <div className="row">
          <h5 className="user-overlay-cancel" onClick={onDismiss}>
            Nevermind, I don't want to add a new user.
          </h5>
        </div>
      </OverlayModal>
    );
  }
}
