/*eslint-disable*/
import React, { Component } from "react";
import { CSSTransitionGroup } from 'react-transition-group'
import { connect } from "react-redux";
import moment from "moment-timezone";
import firebase from 'firebase'
import { getIsFetching } from "../reducers";
import {
  popModal,
  pushModal,
  loadConstants,
  setUser,
  displayMessage
} from "../actions/general.actions";
import { FlashMessage, SpecialFlashMessage } from "../components";
import LoginPage from "../pages/common/Login";
import oval from "../images/oval.svg";
import { Router, Route, Switch, withRouter, Redirect } from "react-router-dom";
import history from "../actions/history";
import jwt from "jwt-decode";
import axios from "../services/network";

// Components
import { AddUserOverlay, AdminNavBar } from "../components";

//Pages
import AddClientPage from "./admin/AddClient";
import AddStaffPage from "./admin/AddStaff";
import AdminAppointmentDetailPage from "./admin/AdminAppointmentDetail";
import AdminPaymentPage from "./admin/AdminPayments";
import AdminDashboardPage from "./admin/AdminDashboard";
import AdminScheduleAppointment from "./admin/AdminScheduleAppointment";
import AdminScheduler from "./admin/AdminScheduler";
import AppVersions from "./admin/AppVersions";
import EditStaffPage from "./admin/EditStaff";
import StaffListPage from "./admin/StaffList";
import ClientListPage from "./admin/ClientList";
import EditClientPage from "./admin/EditClient";
import SystemSettingsPage from "./admin/SystemSettings";
import MapComponent from "./admin/Map";
import SetPassword from "./common/SetPassword";
import EditFacility from "./admin/EditFacility";
import FacilityList from "./admin/FacilityList";
import Login from "./common/Login";
import NewPets from "./admin/NewPets";
import StaffData from "./admin/StaffData";
import Markets from "./admin/Markets";
import PlayCare from "./admin/PlayCare";
import Faq from "./admin/Faq";
import Apartments from "./admin/Apartments";
import AddApartment from "./admin/AddApartment";
import EditApartment from "./admin/EditApartment";
import EditMarket from "./admin/EditMarket";
import AddMarket from "./admin/AddMarket";
import EditPrices from "./admin/EditPrices";
import Vacation from "./admin/Vacation";
import Promos from "./admin/Promos";
import AddPromo from "./admin/AddPromo";
import EditPromo from "./admin/EditPromo";
import Partners from "./admin/Partners";
import AddPartner from "./admin/AddPartner";
import EditPartner from "./admin/EditPartner";
import VetList from "./admin/VetList";
import Vet from "./admin/Vet";
// import DownloadApp from './admin/DownloadApp'

import { firebaseAuth } from "../services/firebaseAuth";
import { getUser } from "../services/firebaseHelpers";

const roleOptions = ['Back Office User', 'Support User', 'PCA Supervisor User']

const Matrix = () => {
    

  return (
    <section className="content-container admin-container">
        Whoa there, pardner. You got no right to be here. 
    </section>
  )
}

function PrivateRoute({ component: Component, authed, loading, adminRequired, isAdmin, ...rest }) {

  if (!loading) {
    return (
      <Route
        {...rest}
        render={props => {

          if (authed !== true) return <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />

          if (adminRequired && !isAdmin) return (
            <div>
              <Matrix />
            </div>
          )

          return <Component {...props} />

        }}

      />
    );
  }
  return null;
}

function PublicRoute({ component: Component, authed, errors, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => <Component {...props} />
      }
    />
  );
}

class App extends Component {
  constructor() {
    super();
  }

  state = {
    authed: false,
    loading: true,
    error: null
  };

  componentDidMount() {
    const stateUser = this.props.user;
    this.removeListener = firebase.auth().onIdTokenChanged(user => {

      if (user) {
        getUser()
          .getIdToken(true)
          .catch(e => console.log("HUUUUUUGE E: ", e))
          .then(token => {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            localStorage.setItem("accessToken", token);
            const decoded = jwt(token);

            let roles =
              decoded && decoded.roles ? decoded.roles.map(role => role) : [];
            let ourUser = {
              roles: roles,
              email: decoded.email
            };
            this.props.setUser(ourUser);
            this.setState({
              authed: true,
              loading: false,
              error: null
            });
          });
      } else {
        this.setState({
          authed: false,
          loading: false
        });


        if (window.location.pathname !== "/faq" && window.location.pathname !== '/createAccount') {
          // console.log('fire....', firebaseAuth())
          // console.log('we are not logged in')
          history.push('/login')
        };
      }
    });
  }

  componentWillUnmount() {
    this.removeListener();
  }


  handleAddNewUser = e => {
    const { modalActions } = this.props;
    const modalComponent = (
      <AddUserOverlay fullScreen onDismiss={modalActions.pop} />
    );
    modalActions.push(modalComponent);
  };

  async componentWillMount() {
    await this.props.loadFormConstants();
  }

  componentWillReceiveProps = nextProps => {
    // If we're fetching, prevent the body from scrolling by adding class to body.
    // We don't use 'modal-open' here because modals could cause fetching too and we don't want
    // to get those wires crossed.
    if (nextProps.isFetching) {
      document.body.classList.add("spinner-active");
    } else {
      document.body.classList.remove("spinner-active");
    }
  };

  getAdmin = () => {
    const { user } = this.props;

    const roles = user.user.roles

    let mainRole

    if (roles.includes('Support User') || roles.includes('PCA Supervisor User')) mainRole = 'support'
    if (roles.includes('Back Office User')) mainRole = 'admin'
    // legacy permissions...
    if (roles.includes('oa') || roles.includes('admin')) mainRole = 'admin'

    const isAdmin = mainRole === 'admin'

    if (mainRole) {
      return (
        <section className="content-container admin-container">
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            exact
            path="/"
            component={AdminDashboardPage}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            path="/appointments/:appointmentId"
            component={AdminAppointmentDetailPage}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            path="/scheduler"
            component={AdminScheduler}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            path="/addstaff"
            component={AddStaffPage}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            adminRequired={true}
            isAdmin={isAdmin ? true : false}
            path="/staff/:staffId"
            component={EditStaffPage}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            path="/stafflist"
            component={StaffListPage}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            path="/clientlist"
            component={ClientListPage}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            path="/addclient"
            component={AddClientPage}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            exact
            path="/client/:clientId"
            component={EditClientPage}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            path="/client/:clientId/schedule"
            component={AdminScheduleAppointment}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            path="/systemsettings"
            component={SystemSettingsPage}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            path="/payments"
            component={AdminPaymentPage}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            path="/map"
            component={MapComponent}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            path="/staffData"
            component={StaffData}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            admin
            adminRequired={true}
            isAdmin={isAdmin ? true : false}
            path="/markets"
            component={Markets}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            path="/playCare"
            adminRequired={true}
            isAdmin={isAdmin ? true : false}
            component={PlayCare}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            path="/apartments"
            adminRequired={true}
            isAdmin={isAdmin ? true : false}
            component={Apartments}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            adminRequired={true}
            isAdmin={isAdmin ? true : false}
            path="/addapartment"
            component={AddApartment}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            adminRequired={true}
            isAdmin={isAdmin ? true : false}
            path="/editapartment/:apartmentId"
            component={EditApartment}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            adminRequired={true}
            isAdmin={isAdmin ? true : false}
            path="/market/:marketId"
            component={EditMarket}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            adminRequired={true}
            isAdmin={isAdmin ? true : false}
            path="/addmarket"
            component={AddMarket}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            adminRequired={true}
            isAdmin={isAdmin ? true : false}
            path="/editprices"
            component={EditPrices}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            adminRequired={true}
            isAdmin={isAdmin ? true : false}
            path="/vacation"
            component={Vacation}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            adminRequired={true}
            isAdmin={isAdmin ? true : false}
            path="/promos"
            component={Promos}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            adminRequired={true}
            isAdmin={isAdmin ? true : false}
            path="/addpromo"
            component={AddPromo}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            adminRequired={true}
            isAdmin={isAdmin ? true : false}
            path="/promo/:promoId"
            component={EditPromo}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            adminRequired={true}
            isAdmin={isAdmin ? true : false}
            path="/partners"
            component={Partners}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            adminRequired={true}
            isAdmin={isAdmin ? true : false}
            path="/addpartner"
            component={AddPartner}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            adminRequired={true}
            isAdmin={isAdmin ? true : false}
            path="/editpartner/:partnerId"
            component={EditPartner}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            exact
            adminRequired={true}
            isAdmin={isAdmin ? true : false}
            path="/facilities"
            component={FacilityList}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            adminRequired={true}
            isAdmin={isAdmin ? true : false}
            path="/facilities/:facilityId"
            component={EditFacility}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            adminRequired={true}
            isAdmin={isAdmin ? true : false}
            path="/vets"
            component={VetList}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            adminRequired={true}
            isAdmin={isAdmin ? true : false}
            path="/vet/:vetId"
            component={Vet}
          />
          <PrivateRoute
            authed={this.state.authed}
            loading={this.state.loading}
            adminRequired={true}
            isAdmin={isAdmin ? true : false}
            path="/apps"
            component={AppVersions}
          />
          {/*<PrivateRoute authed={this.state.authed} loading={this.state.loading} path='/newPets' component={NewPets} />*/}
        </section>
      );
    } else {
      return (
        <section className="content-container admin-container">
          Dang, looks like you don't have access to this app.
        </section>
      );
    }
  };
  render() {
    const {
      isFetching,
      messages,
      modalActions,
      modals,
      formConstants,
      user,
      markets,
      specialMessages
    } = this.props;
    if (!markets || !markets.length) return null;
    return (
      <div>
        <SpecialFlashMessage messages={specialMessages} />
        <Router history={history}>
          <div className="layout-container">
            {/* Modals */}
            <CSSTransitionGroup
              transitionName="modal-background"
              transitionEnterTimeout={150}
              transitionLeaveTimeout={150}
            >
              {modals.length && !modals[modals.length - 1].props.fullScreen ? (
                <div className="modal-background" onClick={modalActions.pop} />
              ) : null}
            </CSSTransitionGroup>
            <CSSTransitionGroup
              transitionName="modal"
              transitionEnterTimeout={200}
              transitionLeaveTimeout={200}
            >
              {modals.map((modal, index) => {
                if (modal.props.schedule) {
                  return (
                    <div
                      key={`modal-${index}`}
                      className="modal-container-schedule"
                    >
                      {modal}
                    </div>
                  );
                }
                if (!modal.props.fullScreen) {
                  return (
                    <div key={`modal-${index}`} className="modal-container">
                      {modal}
                    </div>
                  );
                }
                return <div key={`modal-${index}`}>{modal}</div>;
              })}
            </CSSTransitionGroup>
            <Switch>
              <PublicRoute
                authed={this.state.authed}
                path="/createAccount"
                component={SetPassword}
              />
              <Route authed={this.state.authed} path="/faq" component={Faq} />
              <PublicRoute
                authed={this.state.authed}
                errors={this.state.error}
                path="/login"
                component={Login}
              />
              <Route
                authed={this.state.authed}
                errors={this.state.error}
                path="/faq"
                component={Faq}
              />
              <div className="flex-column">
                <section>
                  <AdminNavBar onAddNewUserClick={this.handleAddNewUser} />
                </section>
                {user && user.user && user.user.roles ? this.getAdmin() : null}
              </div>
            </Switch>
            {/* Flash Messages */}
            <FlashMessage messages={messages} />
            {/* Loading Spinner */}
            {isFetching ? (
              <div>
                <div className="loading-spinner-bg"></div>
                <img
                  className="loading-spinner-img"
                  src={oval}
                  alt="Spinner"
                  height={72}
                  width={72}
                />
              </div>
            ) : null}
          </div>
        </Router>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  formConstants: state.general.formConstants,
  isFetching: getIsFetching(state),
  messages: state.general.messages,
  specialMessages: state.general.specialMessages,
  modals: state.general.modals,
  markets: state.admin.general.markets,
  user: state.general.user
});
const mapDispatchToProps = dispatch => ({
  modalActions: {
    pop: () => dispatch(popModal()),
    push: component => dispatch(pushModal(component))
  },
  loadFormConstants: () => dispatch(loadConstants()),
  setUser: user => dispatch(setUser(user))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
