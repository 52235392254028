import { APIService } from "../services";
import { dispatchFetchFailure } from "./helpers";

export const GET_CONSTANTS_SUCCESS = "GET_CONSTANTS_SUCCESS";

export const setUser = user => {
  return dispatch => {
    dispatch({
      type: "SET_USER",
      payload: { user }
    });
  };
};

export const displayMessage = (message, messageContext, messageType) => ({
  type: "ADD_MESSAGE",
  payload: { message, messageContext, messageType }
});

export const pushModal = modalComponent => {
  document.body.classList.add("modal-open");
  return {
    type: "PUSH_MODAL",
    payload: modalComponent
  };
};

export const popModal = () => {
  return (dispatch, getState) => {
    const { modals } = getState().general;
    if (modals.length === 1) {
      document.body.classList.remove("modal-open");
    }
    dispatch({ type: "POP_MODAL" });
  };
};

export const destroyModals = () => {
  document.body.classList.remove("modal-open");
  return { type: "DESTROY_MODALS" };
};

export function loadConstants() {
  const context = "getConstants";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .requestFormConstants()
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch({
            type: GET_CONSTANTS_SUCCESS,
            payload: {
              formConstants: response.constants
            }
          });
          dispatch({
            type: "GET_MARKETS",
            payload: response.markets
          });
          const market_id = localStorage.getItem("market_id");
          const market = response.markets.find(m => m._id === market_id);
          dispatch({
            type: "CHANGE_CITY",
            payload: market ? market : response.markets[0]
          });
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}

export const showFormErrorMessage = (errors, context) => {
  // If this was a server error (not a local form validation error),

  if (!errors || errors.serverError) {
    return { type: "IGNORE_DUPLICATE_ERROR" }; // does nothing
  }

  // Display the error!
  const message = "Oops! Please check your form for errors and try again.";
  // TODO: should this be hard-coded to "rejectForm"?
  const messageContext = `${context} (${Object.keys(errors).join(", ")})`;
  return dispatch => {
    dispatch(
      displayMessage(
        errors.customMessageText || message,
        messageContext,
        "error"
      )
    );
    setTimeout(() => dispatch({ type: "REMOVE_MESSAGE" }), 5000);
  };
};

export const showSuccessMessage = (message, messageContext) => {
  return dispatch => {
    dispatch(displayMessage(message, messageContext, "success"));
    setTimeout(() => dispatch({ type: "REMOVE_MESSAGE" }), 5000);
  };
};

export const showFailMessage = (message, messageContext) => {
  return dispatch => {
    dispatch(displayMessage(message, messageContext, "error"));
    setTimeout(() => dispatch({ type: "REMOVE_MESSAGE" }), 5000);
  };
};

export function sendPasswordResetEmail(isAdmin, model) {
  const context = "sendPasswordResetEmail";
  let successMessage =
    "Please check your email for instructions for resetting your password.";
  if (isAdmin) {
    successMessage =
      "An email with password reset instructions has been sent to the client.";
  }

  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.shared
      .sendPasswordResetEmail(model)
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch(showSuccessMessage(successMessage, context));
        },
        error => error
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}
