import React, { Component } from "react";
import { connect } from "react-redux";
import { SelectList, DropdownList, DatePicker as DateTimePicker } from "react-widgets";
import history from "../../../../actions/history";

class MarketForm extends Component {
  state = {
    city: "",
    state: "",
    latitude: 0,
    longitude: 0,
    start_date: null,
    time_zone: "",
    phone_number: ""
  };
  componentWillMount() {
    this.setState({
      city: this.props.city || "",
      state: this.props.state || {},
      latitude: this.props.latitude || 0,
      longitude: this.props.longitude || 0,
      start_date: this.props.start_date,
      time_zone: this.props.time_zone || "",
      phone_number: this.props.phone_number || ""
    });
  }
  submitForm = () => {
    const market = {
      city: this.state.city,
      state: this.state.state,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      start_date: this.state.start_date,
      time_zone: this.state.time_zone,
      phone_number: this.state.phone_number
    };
    this.props.saveMarket(market);
  };

  validatePhone = num => {
    this.setState({
      phone_number: `${num.replace(/\D/g, "")}`.substr(0, 10)
    });
  };
  render() {
    return (
      <div className="market-form-container">
        <div className="row">
          <div className="col-sm-3">
            <div className="form-component-wrapper">
              <input
                value={this.state.city}
                className={`form-control form-height`}
                placeholder="City"
                onChange={e => this.setState({ city: e.target.value })}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-component-wrapper">
              <DropdownList
                data={this.props.formConstants.states}
                placeholder="State"
                valueField={"abbreviation"}
                textField={"name"}
                onChange={item => {
                  this.setState({ state: item.abbreviation });
                }}
                defaultValue={this.props.state}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-component-wrapper">
              <label>Latitude</label>
              <input
                value={this.state.latitude}
                className={`form-control form-height`}
                onChange={e => this.setState({ latitude: e.target.value })}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-component-wrapper">
              <label>Longitude</label>
              <input
                value={this.state.longitude}
                className={`form-control form-height`}
                onChange={e => this.setState({ longitude: e.target.value })}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3">
            <div className="form-component-wrapper">
              <label>Start Date</label>
              <DateTimePicker
                includeTime={true}
                value={this.state.start_date}
                time={false}
                onChange={value => this.setState({ start_date: value })}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-component-wrapper">
              <DropdownList
                data={this.props.formConstants.timezones}
                valueField={"value"}
                textField={"name"}
                placeholder="Time Zone"
                onChange={item => {
                  this.setState({ time_zone: item.value });
                }}
                defaultValue={this.props.time_zone}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-component-wrapper">
              <label>Phone Number</label>
              <input
                type="text"
                value={this.state.phone_number}
                onChange={e => {
                  this.validatePhone(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3">
            <button
              className="btn btn-danger normal-size-btn"
              onClick={() => history.push("/markets")}
            >
              Back
            </button>
          </div>
          <div className="col-sm-1 pull-right">
            <button
              className="btn btn-primary"
              onClick={() => {
                this.submitForm();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  formConstants: state.general.formConstants
});

export default connect(
  mapStateToProps,
  null
)(MarketForm);
