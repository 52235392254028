import React, { Component } from "react";
import { connect } from "react-redux";
import { submit } from "redux-form";
import { catchFormServerErrors } from "../../../helpers";
import { adminAppointmentService } from "../../../services";

// Actions
import {
  pushModal,
  popModal,
  showFormErrorMessage,
  showSuccessMessage
} from "../../../actions/general.actions";
import {
  getAppointmentDetail as getAppointmentDetailAction,
  scheduleAppointment
} from "../../../actions/admin/appointments.actions";

// Components
import { ApproveAppointmentForm, DefaultModal } from "../../";

class ApproveAppointmentModal extends Component {

  handleApprove = values => {
    const { appointment, onSubmitApprove } = this.props;
    const model = adminAppointmentService.getAdminApproveOrScheduleAppointmentModel(
      values
    );
    model.approve = true;
    return onSubmitApprove(appointment.id, model).then(res =>
      catchFormServerErrors(res)
    );
  };

  render() {
    const {
      appointment,
      displayFormError,
      displayFormSuccess,
      getAppointmentDetail,
      modalActions,
      staffList,
      submitApproveForm
    } = this.props;

    return (
      <DefaultModal
        title="Approve Appointment"
        onDismiss={modalActions.pop}
        options={
          <div>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={modalActions.pop}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={submitApproveForm}
            >
              Approve
            </button>
          </div>
        }
      >
        <span>
          <ApproveAppointmentForm
            appointment={appointment}
            onSubmit={this.handleApprove}
            onSubmitFail={errors => displayFormError(errors)}
            onSubmitSuccess={() => {
              displayFormSuccess(
                "Appointment successfully assigned and approved."
              );
              getAppointmentDetail(appointment.id);
              modalActions.pop();
            }}
            requestedStartDate={appointment.window_start_time}
            staffList={staffList}
            initialValues={adminAppointmentService.getInitialFormValuesForApproval(
              appointment
            )}
          />
        </span>
      </DefaultModal>
    );
  }
}

const mapStateToProps = state => ({
  appointment: state.admin.appointments.appointmentDetail,
  staffList:
    state.admin.staff.staffList &&
    state.admin.staff.staffList.map(sitter => ({
      fullName: `${sitter.contact.full_name}`,
      id: sitter.id
    }))
});

const mapDispatchToProps = dispatch => ({
  displayFormError: errors =>
    dispatch(showFormErrorMessage(errors, "adminAppointmentDetailsApprove")),
  displayFormSuccess: message =>
    dispatch(showSuccessMessage(message, "adminAppointmentDetailsApprove")),
  getAppointmentDetail: appointmentId =>
    dispatch(getAppointmentDetailAction(appointmentId)),
  modalActions: {
    push: component => dispatch(pushModal(component)),
    pop: () => dispatch(popModal())
  },
  submitApproveForm: () => dispatch(submit("approveAppointment")),
  onSubmitApprove: (appointmentId, model) =>
    dispatch(scheduleAppointment(appointmentId, model))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApproveAppointmentModal);
