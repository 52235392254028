import React, { Component } from "react";
import { connect } from "react-redux";
import { createPetService } from "../../services";
import { PetForm, PageTitleComponent } from "../../components";
import { catchFormServerErrors } from "../../helpers";

// Actions
import { updatePet as updatePetAction } from "../../actions/admin/clients.actions";
import {
  showFormErrorMessage,
  showSuccessMessage
} from "../../actions/general.actions";
import MedicineForm from "../../components/admin/forms/Pet/MedicineForm";

class EditPetPage extends Component {
  state = {
    tab: 1
  };

  handleSubmit = values => {
    const { pet } = this.props;
    const model = createPetService.getAddPetModel(values);

    // construct the FormData object
    const formData = new FormData();
    Object.keys(model).forEach(key => {
      if (model[key]) {
        formData.append(key, model[key]);
      }
    });

    return this.props
      .onSubmit(pet._id, formData)
      .then(res => catchFormServerErrors(res));
  };

  render() {
    const {
      displayFormError,
      displayFormSuccess,
      formConstants,
      onCancel,
      onEditPetSuccess,
      pet
    } = this.props;
    return (
      <div className="card">
        {pet ? (
          <div>
            <PageTitleComponent title={`Add New ${pet.animal_type}`} />
            <div
              onClick={() => this.setState({ tab: 1 })}
              className={`apartmentTab${
                this.state.tab === 1 ? " selectedTab" : ""
              }`}
            >
              Basic Info
            </div>
            <div
              onClick={() => this.setState({ tab: 2 })}
              className={`apartmentTab${
                this.state.tab === 2 ? " selectedTab" : ""
              }`}
            >
              Medication
            </div>
            {this.state.tab === 1 ? (
              <PetForm
                petId={pet._id}
                displayFormError={displayFormError}
                enableReinitialize
                formConstants={formConstants}
                initialValues={createPetService.getInitialFormValuesForUpdate(
                  pet
                )}
                onCancel={onCancel}
                onSubmit={this.handleSubmit}
                onSubmitFail={errors => displayFormError(errors)}
                onSubmitSuccess={() => {
                  displayFormSuccess("Pet successfully edited.");
                  onEditPetSuccess();
                }}
                petType={pet.animal_type}
                editExisting
              />
            ) : null}
            {this.state.tab === 2 ? <MedicineForm petId={pet._id} /> : null}
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  formConstants: state.general.formConstants
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  displayFormError: errors =>
    dispatch(showFormErrorMessage(errors, "addPetForm")),
  displayFormSuccess: message =>
    dispatch(showSuccessMessage(message, "addPetForm")),
  onSubmit: (petId, formData) => dispatch(updatePetAction(petId, formData))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPetPage);
