import React, { Component } from "react";
import moment from "moment";
import { schedulerRows } from "../../../../config/scheduler";
import { PetCard, PetCardCompact } from "../../../";

// helper to identify onRender which appointments belong in the given cell
const filterAppointments = (
  appointments,
  cellHours,
  rowSitterId,
  transitionalAppointment,
  selectedDate
) =>
  appointments
    .filter(appt => {
      // filter out transitional appts, as we'll add those separately
      if (transitionalAppointment) {
        return appt.id !== transitionalAppointment.id;
      }
      return appt;
    })
    .filter(appt => {
      const apptStartTime = moment(appt.scheduled_start_time);
      // const apptStartTime = moment(appt.scheduled_start_time || appt.window_start_time);
      const apptOnSameDay = apptStartTime.isSame(selectedDate, "day");
      const apptInSameHour = cellHours.includes(apptStartTime.hour());
      const apptForSitter = appt.sitter && appt.sitter.id === rowSitterId;
      return apptOnSameDay && apptInSameHour && apptForSitter;
    });

export default class SchedulerRow extends Component {

  // Determine the default value for the Pet Card drawer's "Scheduled Time" field.
  getScheduledTimeFieldValue = appointment => {
    const { transitionalHourSelected, selectedDate } = this.props;
    const firstHourOfLastSlot =
      schedulerRows[schedulerRows.length - 1].hours[0];

    // If the midnight to 8AM block is selected, use the appointment's scheduled_start_time (or the
    // window_start_time, if it's not yet scheduled for a specific time).
    if (
      transitionalHourSelected === 0 ||
      transitionalHourSelected >= firstHourOfLastSlot
    ) {
      return moment(
        appointment.scheduled_time || appointment.window_start_time
      ).toDate();

      // If a slot has been selected, the appointment has a scheduled_start_time, and the scheduled
      // time is within the selected hour block, then fill the field with the scheduled time.
    } else if (
      transitionalHourSelected &&
      appointment.scheduled_start_time &&
      transitionalHourSelected ===
        moment(appointment.scheduled_start_time).hour()
    ) {
      return moment(appointment.scheduled_start_time).toDate();

      // Otherwise, if a slot has been selected (but the appointment doesn't have a scheduled time),
      // default the field value to the beginning of the hour of the selected time block.
    } else if (transitionalHourSelected) {
      return moment(selectedDate)
        .hour(transitionalHourSelected)
        .startOf("hour")
        .toDate();
    }

    // Finally, if no slot has been selected (user if viewing drawer before moving pet card), fill
    // the field with the scheduled_start_time.
    return moment(appointment.scheduled_start_time).toDate();
  };

  renderPetCards = sitterId => {
    const {
      appointments,
      handleScheduleAppointment,
      handleSelectAppointment,
      hours,
      onClickAppointmentDetail,
      onScheduleFail,
      onScheduleSuccess,
      selectedAppointment,
      selectedDate,
      transitionalAppointment,
      transitionalHourSelected,
      transitionalSitterId
    } = this.props;

    // Render assigned appointments
    const cellAppointments = filterAppointments(
      appointments,
      hours,
      sitterId,
      transitionalAppointment,
      selectedDate
    );
    const scheduledAppointments = cellAppointments.map(appt => (
      <PetCardCompact
        key={`assigned-appt-id-${appt.id}`}
        appointment={appt}
        drawerOpen={selectedAppointment && appt.id === selectedAppointment.id}
        handleScheduleAppointment={handleScheduleAppointment}
        handleSelectAppointment={handleSelectAppointment}
        onClickAppointmentDetail={onClickAppointmentDetail}
        onScheduleFail={onScheduleFail}
        onScheduleSuccess={onScheduleSuccess}
        selected={selectedAppointment && appt.id === selectedAppointment.id}
        selectedDate={selectedDate}
        scheduledTimeFieldValue={this.getScheduledTimeFieldValue(appt)}
        sitterId={sitterId}
      />
    ));

    // Render any transitional appointment, if one exists
    let transitionalCellAppointment;
    if (transitionalAppointment) {
      if (
        transitionalSitterId === sitterId &&
        hours.includes(transitionalHourSelected)
      ) {
        transitionalCellAppointment = (
          <PetCard
            key={`transitional-appt-id-${transitionalAppointment.id}`}
            appointment={transitionalAppointment}
            drawerOpen
            handleScheduleAppointment={handleScheduleAppointment}
            handleSelectAppointment={handleSelectAppointment}
            isTransitional
            onClickAppointmentDetail={onClickAppointmentDetail}
            onScheduleFail={onScheduleFail}
            onScheduleSuccess={onScheduleSuccess}
            selected
            selectedDate={selectedDate}
            scheduledTimeFieldValue={this.getScheduledTimeFieldValue(
              transitionalAppointment
            )}
            sitterId={transitionalSitterId}
          />
        );
      }
    }

    // Return the rendered pet cards
    return (
      <div>
        Hello there
        {scheduledAppointments}
        {transitionalCellAppointment}
      </div>
    );
  };

  renderHotSpot = sitterId => {
    const {
      appointments,
      handleTransitionAppointment,
      hours,
      selectedAppointment,
      selectedDate
    } = this.props;

    // Do not attempt to render a hot spot if we don't have a selected appointment or sitter!
    if (!selectedAppointment || !sitterId) {
      return null;
    }

    // Do not attempt to render a hot spot if the selectedAppointment is already in the given cell.
    const cellAppointments = filterAppointments(
      appointments,
      hours,
      sitterId,
      null,
      selectedDate
    );

    if (cellAppointments.find(appt => appt.id === selectedAppointment.id)) {
      return null;
    }

    // Turn our start and end times into moments
    const windowStartTime = moment(selectedAppointment.window_start_time);
    const windowEndTime = moment(selectedAppointment.window_end_time);
    // Get the hour of those times
    const windowStartHour = windowStartTime.hour();
    let windowEndHour = windowEndTime.hour();
    // If the end time is on the hour, don't count that hour
    if (windowEndTime.isSame(windowEndTime.clone().startOf("hour"))) {
      windowEndHour -= 1;
    }

    // Loop over the hours represented by the given cell to identify if they're in bounds of selected appt
    let withinBounds = false;
    for (const hour of hours) {
      if (hour >= windowStartHour && hour <= windowEndHour) {
        withinBounds = true;
        break;
      }
    }

    // If they're in bounds, render the hot spot!
    if (withinBounds) {
      return (
        <div
          className="hot-spot"
          onClick={() => handleTransitionAppointment(hours[0], sitterId)}
        >
          Slot within appointment window
        </div>
      );
    }
    return null;
  };

  render = () => {
    const { label, selectedSitters } = this.props;
    const selectedSittersArray = Object.values(selectedSitters);

    return (
      <div className="scheduler-row">
        <div className="time-block">
          <p>{label}</p>
        </div>
        {selectedSittersArray.map((sitterId, index) => (
          <div className="time-slot" key={`time-slot-7-${index}`}>
            {this.renderPetCards(sitterId)}
            {this.renderHotSpot(sitterId)}
          </div>
        ))}
      </div>
    );
  };
}
