import React, { Component } from "react";

export default class DefaultModal extends Component {

  _handleEscKey = e => {
    const { onDismiss } = this.props;
    if (e.keyCode === 27) {
      onDismiss();
    }
  };

  componentWillMount = () => {
    window.addEventListener("keydown", this._handleEscKey);
  };

  componentWillUnmount = () => {
    window.removeEventListener("keydown", this._handleEscKey);
  };

  render() {
    const { children, onDismiss, options, title } = this.props;
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={onDismiss}
            >
              <span aria-hidden="true" className="fa fa-times"></span>
            </button>
            {title ? <h4 className="modal-title">{title}</h4> : null}
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer">{options}</div>
        </div>
      </div>
    );
  }
}
