import React, { Component } from "react";
import moment from "moment";

export default class Month extends Component {

  render() {
    const {
      calendarDayContents,
      firstDayOfWeek,
      isDayDisabled,
      isDaySelected,
      month,
      onClickDay
    } = this.props;
    let date = firstDayOfWeek.clone();
    const days = [];

    for (let i = 0; i < 7; i++) {
      const day = {
        name: date.format("dd").substring(0, 1),
        number: date.date(),
        isCurrentMonth: date.month() === month.month(),
        isSelected: isDaySelected && isDaySelected(date),
        isDisabled: isDayDisabled && isDayDisabled(date),
        isToday: date.isSame(new Date(), "day"),
        date: date
      };

      const dayInstance = day.date.clone();

      days.push(
        <div
          key={day.date.toString()}
          className={`day-block${day.isSelected ? " selected-day" : ""}${
            day.isDisabled ? " disabled-day" : ""
          }`}
          onClick={() => onClickDay(dayInstance, day.isDisabled)}
        >
          <div className="date-row">
            <p
              className={`${day.isCurrentMonth ? "active" : "inactive"}${
                day.isToday ? " today" : ""
              }`}
            >
              {day.number}
            </p>
          </div>
          {calendarDayContents(day)}
        </div>
      );
      date.add(1, "d");
    }

    return <div className="calendar-row-month">{days}</div>;
  }
}
