import React, { Component } from "react";
import { AddPetOverlay, PetDetailsRow } from "../../../components";
import { Multiselect } from "react-widgets";

export default class PetService extends Component {

  changeService = (val, pet) => {
    const { input } = this.props;
    const newValue = [...input.value];
    const findIt = newValue.findIndex(
      v => v.id === val.id && v.pet_id == pet._id
    );
    if (findIt > -1) {
      newValue.splice(findIt, 1);
    } else {
      let newOp = {
        id: val.id,
        name: val.name,
        pet_id: pet._id
      };
      newValue.push(newOp);
    }
    return input.onChange(newValue);
  };

  renderPets = () => {
    const { input, options, clientPets, pets, ...props } = this.props;

    const allowRemoval = input.value.length > 1;
    return pets
      ? clientPets
          .filter(pet => pets.includes(pet._id))
          .map(pet => {
            return (
              <div className="pet-services row" key={`petService${pet._id}`}>
                <div className="col-sm-4 servicePetName">
                  <h4>{pet.name}</h4>
                </div>
                <div className="toggle-box-container col-sm-8">
                  {options.map((option, index) => {
                    const checked =
                      input.value && input.value.length
                        ? input.value.find(
                            v => v.id === option.id && v.pet_id == pet._id
                          )
                        : null;
                    // const completed = input.value && input.value.length ? input.value.find(v => v.id === option.id && v.pet_id == pet._id && v.completed) : null
                    return (
                      <div
                        className={`toggle-box btn btn-tertiary${
                          checked ? " checked" : ""
                        }`}
                        key={`petService${input.name}-${index}`}
                        onClick={() => this.changeService(option, pet)}
                      >
                        <label>{option.name}</label>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })
      : null;
  };

  render = () => <div className="row petservicesrow">{this.renderPets()}</div>;
}
