import { APIService } from "../../services";
import * as ActionTypes from "./actionTypes";
import { dispatchFetchFailure } from "../helpers";

export function getAllFacilities() {
  const context = "getAllFacilities";
  return function(dispatch) {
    dispatch({ type: "FETCH_REQUEST", context });
    return APIService.admin
      .getAllFacilities()
      .then(
        response => {
          dispatch({ type: "FETCH_SUCCESS", context });
          dispatch({
            type: ActionTypes.GET_ALL_FACILITIES_SUCCESS,
            payload: {
              facilities: response
            }
          });
        },
        error => error.response.json()
      )
      .then(res => dispatchFetchFailure(res, context, dispatch))
      .catch(error =>
        dispatchFetchFailure("network", context, dispatch, error)
      );
  };
}