import moment from "moment-timezone";
import { combineDateTime } from "../helpers";

export default {
  getAdminApproveAppointmentModel: values => ({
    scheduled_time: values.scheduledTime,
    sitter_id: values.assignedSitter
  }),

  getAdminApproveOrScheduleAppointmentModel: values => ({
    scheduled_time: values.scheduled_time,
    sitter_id: values.sitter_id,
    apply_to_recurring: values.apply_to_recurring
  }),

  getAdminCreateAppointmentModel: (values, clientId, clientInfo) => {
    const services = values.add_on_services || [];
    const petServices = values.pet_addons || [];
    if ([11, 12].includes(values.appointment_type_id)) {
      let day;
      let windowStart;
      let windowEnd;
      if (values.appointment_type_id === 12) {
        day = moment(values.requested_date).format("ddd MMM D YYYY");
        windowStart = clientInfo.apartment.concierge.window_start_time;
        windowEnd = clientInfo.apartment.concierge.window_end_time;
      }
      if (values.appointment_type_id === 11) {
        day = moment(
          values.requested_date || values.recurrence_start_date
        ).format("ddd MMM D YYYY");
        windowStart = clientInfo.apartment.playcare.window_start_time;
        windowEnd = clientInfo.apartment.playcare.window_end_time;
        values.pet_ids = values.pet_ids.filter(i => {
          const find = clientInfo.pets.find(p => {
            return (
              p._id === i && p.playcare_approved && p.animal_type === "Dog"
            );
          });
          return !!find;
        });
        values.scheduled_time = `${day} ${windowStart}`;
      }
      values.window_start_time = `${day} ${windowStart}`;
      values.window_end_time = `${day} ${windowEnd}`;
    }

    return {
      add_on_services: services.concat(petServices),
      appointment_type_id: values.appointment_type_id,
      appointment_status_id: values.appointment_status_id,
      household_id: clientId,
      client_notes: values.client_notes,
      admin_notes_to_sitter: values.admin_notes_to_sitter,
      is_recurring: values.recurring,
      pet_ids: values.pet_ids,
      price_overridden: values.price_overridden,
      price: values.price,
      recurrence_end_date: values.recurrence_end_date,
      recurrence_start_date: moment(values.recurrence_start_date)
        .startOf("day")
        .toDate(),
      recurrence_weekdays: values.recurrence_weekdays,
      recurrence_type: values.recurrence_type
        ? values.recurrence_type.toLowerCase()
        : null,
      scheduled_time: values.scheduled_time ? values.scheduled_time : null,
      window_start_time: combineDateTime(
        values.requested_date || values.recurrence_start_date,
        values.window_start_time
      ),
      window_end_time: combineDateTime(
        values.requested_date || values.recurrence_start_date,
        values.window_end_time
      ),
      visit_date: moment(values.requested_date)
        .hour(12)
        .toDate()
    };
  },

  getAdminUpdateAppointmentModel: (values, clientInfo) => {
    const services = values.add_on_services || [];
    const petServices = values.pet_addons || [];
    if ([11, 12].includes(values.appointment_type.id)) {
      values.apartment_id = clientInfo.apartment._id;
      if (values.appointment_type.id === 11) {
        let day = moment(
          values.requested_date || values.recurrence_start_date
        ).format("ddd MMM D YYYY");
        let windowStart = clientInfo.apartment.playcare.window_start_time;
        let windowEnd = clientInfo.apartment.playcare.window_end_time;
        values.pet_ids = values.pet_ids.filter(i => {
          const find = clientInfo.pets.find(p => {
            return (
              p._id === i && p.playcare_approved && p.animal_type === "Dog"
            );
          });
          return !!find;
        });
        values.window_start_time = `${day} ${windowStart}`;
        values.window_end_time = `${day} ${windowEnd}`;
        values.scheduled_time = `${day} ${windowStart}`;
      }
    }
    let model = {
      _id: values._id,
      add_on_services: services.concat(petServices),
      appointment_status: values.appointment_status,
      appointment_type: values.appointment_type,
      appointment_images: values.appointment_images,
      household_id: values.household_id,
      client_notes: values.client_notes,
      completed_addons: values.checklist_status,
      pet_ids: values.pet_ids,
      price_overridden: values.price_overridden,
      rejection_reason: values.rejection_reason,
      sitter_notes_to_client: values.sitter_notes_to_client,
      sitter_notes_to_admin: values.sitter_notes_to_admin,
      admin_notes_to_sitter: values.admin_notes_to_sitter,
      visit_date: moment(values.requested_date)
        .hour(12)
        .toDate(),
      window_start_time: combineDateTime(
        values.requested_date,
        values.window_start_time
      ),
      window_end_time: combineDateTime(
        values.requested_date,
        values.window_end_time
      ),
      images_for_deletion: values.images_for_deletion,
      sitter: values.sitter,
      exclude_from_scheduler: values.exclude_from_scheduler ? true : false,
      update_all_sitters: values.update_all_sitters ? true : false,
      update_all_scheduled_times: values.update_all_scheduled_times
        ? true
        : false,
      window_end_date: values.recurrence_end_date,
      recurrence_weekdays: values.recurrence_weekdays,
      apartment_id: values.apartment_id
    };

    if (values.scheduled_time) {
      model.scheduled_time = combineDateTime(
        values.requested_date,
        values.scheduled_time
      );
    }

    if (values.actual_start_time) {
      model.actual_start_time = moment(
        combineDateTime(values.requested_date, values.actual_start_time)
      ).toDate();
    }

    if (values.actual_end_time) {
      model.actual_end_time = moment(
        combineDateTime(values.requested_date, values.actual_end_time)
      ).toDate();
    }

    model.price = values.price;
    return model;
  },

  getInitialFormValuesForUpdate: appt => {
    if (moment(appt.window_start_time).isValid()) {
      appt.window_start_time = moment(appt.window_start_time).toDate();
    }
    if (moment(appt.window_end_time).isValid()) {
      appt.window_end_time = moment(appt.window_end_time).toDate();
    }
    return {
      _id: appt._id,
      appointment_status: appt.appointment_status,
      appointment_type: appt.appointment_type,
      household_id: appt.household_id,
      pet_ids: appt.appointment_pet_ids,
      add_on_services: appt.add_on_services.filter(a => !a.pet_id),
      pet_addons: appt.add_on_services.filter(a => a.pet_id),
      requested_date: moment(appt.visit_date)
        .hour(12)
        .toDate(),
      window_start_time: appt.window_start_time,
      window_end_time: appt.window_end_time,
      recurrence_end_date: appt.window_end_date
        ? moment(appt.window_end_date).toDate()
        : null,
      completed_date: appt.actual_end_time
        ? moment(appt.actual_end_time).toDate()
        : null,
      actual_start_time: appt.actual_start_time
        ? moment.tz(appt.actual_start_time, appt.time_zone).toDate()
        : null,
      actual_end_time: appt.actual_end_time
        ? moment.tz(appt.actual_end_time, appt.time_zone).toDate()
        : null,
      scheduled_date: new Date(appt.window_start_time),
      scheduled_time: appt.scheduled_start_time
        ? moment(appt.scheduled_start_time).toDate()
        : null,
      sitter: appt.sitter && appt.sitter._id ? appt.sitter : null,
      client_notes: appt.client_notes,
      sitter_notes_to_client: appt.sitter_notes_to_client,
      sitter_notes_to_admin: appt.sitter_notes_to_admin,
      admin_notes_to_sitter: appt.admin_notes_to_sitter,
      checklist_status: appt.add_on_services
        .filter(addon => addon.completed)
        .map(addon => addon.id),
      rejection_reason: appt.rejection_reason,
      price_overridden: appt.price_overridden,
      price: appt.price_overridden ? appt.price : null,
      exclude_from_scheduler: appt.exclude_from_scheduler,
      recurrence_weekdays: appt.recurrence ? appt.recurrence.weekdays : null
    };
  },

  getInitialFormValuesForApproval: appointment => {
    const calculateScheduledTime = () => {
      if (appointment.scheduled_start_time) {
        return new Date(appointment.scheduled_start_time);
      }
      return new Date(appointment.window_start_time);
    };

    return {
      scheduled_time: calculateScheduledTime(),
      sitter_id: appointment.sitter_id
    };
  }
};
