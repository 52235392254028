import React, { Component } from "react";
import { Link } from "react-router-dom";
import Tooltip from "rc-tooltip";

class ReferralRowItem extends Component {
  render() {
    return (
      <Link to={`/client/${this.props.client._id}`}>
        <div className="row table-row">
          <div className="col-xs-2 cell">
            {this.props.client.full_name || "error"}
          </div>
          <div className="col-xs-2 cell">{this.props.created_at}</div>
          <div className="col-xs-2 cell">
            {this.props.client.consultation_status}
          </div>
          <Tooltip
            placement="top"
            overlay={
              <div>
                This is the number of visits completed towards the referral
                kickback
              </div>
            }
            mouseEnterDelay={0.2}
            mouseLeaveDelay={0.1}
            overlayClassName="annotation-tooltip"
          >
            <div className="col-xs-2 cell">{this.props.num_visits}</div>
          </Tooltip>
          <div className="col-xs-2 cell">
            {this.props.referral_completed ? (
              <i className="fa fa-check"></i>
            ) : null}
          </div>
          <div className="col-xs-2 cell">
            {this.props.referrer_completed ? (
              <i className="fa fa-check"></i>
            ) : null}
          </div>
        </div>
      </Link>
    );
  }
}

export default ReferralRowItem;
