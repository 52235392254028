import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import { pushModal, popModal } from "../../../actions/general.actions";
import { staffActivation as staffActivationAction } from "../../../actions/admin/staff.actions";

// Components
import { DefaultModal } from "../../";

class StaffActivationConfirmationModal extends Component {

  onConfirm = e => {
    e.preventDefault();
    const {
      activateStaff,
      staffId,
      staffActivation,
      modalActions
    } = this.props;
    staffActivation(staffId, activateStaff); // Here, success message gets triggered in action
    modalActions.pop();
  };

  render() {
    const { activateStaff, modalActions } = this.props;

    return (
      <DefaultModal
        title={
          activateStaff ? "Activate Team Member" : "Deactivate Team Member"
        }
        onDismiss={modalActions.pop}
        options={
          <div>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={modalActions.pop}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.onConfirm}
            >
              {activateStaff ? "Activate" : "Deactivate"}
            </button>
          </div>
        }
      >
        <p>
          Are you sure you want to {activateStaff ? "activate" : "deactivate"}{" "}
          this Team Member?
        </p>
        {activateStaff ? (
          <p>The Team Member will be able to log in again after activation.</p>
        ) : (
          <p>
            This action will remove the Team Member from all pending, approved,
            or change-requested appointments, change the status of those
            appointments to Pending, and lock the Team Member out of the system.
          </p>
        )}
      </DefaultModal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  staffActivation: (petId, staffId) =>
    dispatch(staffActivationAction(petId, staffId)),
  modalActions: {
    push: component => dispatch(pushModal(component)),
    pop: () => dispatch(popModal())
  }
});

export default connect(
  null,
  mapDispatchToProps
)(StaffActivationConfirmationModal);
