import React from "react";
import { PageTitleComponent } from "../../components";
import { formatNumsToDollars } from "../../helpers";

export default class ClientBalanceCard extends React.Component {

  render() {
    const retryOutstandingBalance = () => {};
    const { balances } = this.props;

    return (
      <div className="card card-client-balance" id="outstanding">
        <PageTitleComponent title="Client Balance" />

        {balances.account === balances.pending ? null : (
          <div className="card-container">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 card-container-item card-text">
              Current Week Balance
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 card-container-item card-result-text">
              {formatNumsToDollars(balances.pending)}
            </div>
          </div>
        )}

        {balances.outstanding > 0 ? (
          <div className="card-container outstanding">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 card-container-item card-text">
              Outstanding Balance
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 card-container-item card-result-text">
              {formatNumsToDollars(balances.outstanding)}
              <div
                className="btn btn-secondary"
                onClick={this.props.retryOutstandingBalance}
              >
                Retry Charge
              </div>
            </div>
          </div>
        ) : null}

        <div className="card-container">
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 card-container-item card-text">
            Account
            {balances.account < 0 ? (
              <div className="credit">&nbsp;credit</div>
            ) : (
              <span>&nbsp;Balance</span>
            )}
            {/* <div className="note">Any account balance over $1.00 will be charged to your card weekly, on Saturdays.</div> */}
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 card-container-item card-result-text">
            {formatNumsToDollars(Math.abs(balances.account))}
          </div>
        </div>
      </div>
    );
  }
}
