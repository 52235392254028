import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import {
  TextAreaField,
  FieldColumnWrapper
} from "../../../shared/formComponents";

class RejectAppointmentForm extends Component {

  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="row">
          <FieldColumnWrapper column="12">
            <Field
              component={TextAreaField}
              name="rejectionReason"
              placeholder="Rejection reason. This will be shared with the client."
            />
          </FieldColumnWrapper>
        </div>
      </form>
    );
  }
}

const validate = values => {
  const errors = {};
  if (!values.rejectionReason) errors.rejectionReason = true;
  return errors;
};

export default reduxForm({ form: "rejectAppointment", validate })(
  RejectAppointmentForm
);
