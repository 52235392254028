import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { formatNumsToDollars } from "../../helpers";
import Tooltip from "rc-tooltip";
import { PaymentTableItem } from "../../components";

export default class PaymentListItem extends React.Component {
  getLineItems = () => {
    return this.props.listItem.line_items
      .filter(this.props.filterLineItem)
      .map(item => {
        return (
          <PaymentTableItem
            item={item}
            selectPayment={this.props.selectPayment}
            selected={this.props.payments.includes(item)}
          />
        );
      });
  };

  state = {
    viewing: false
  };

  selectClient = e => {
    e.stopPropagation();

    this.props.selectClient(this.props.listItem);
  };

  render() {
    const { listItem, userType } = this.props;

    let height = listItem.line_items.length * 50;

    let paymentStyle = {
      height: this.state.viewing ? `${height}px` : "0px",
      overflow: "hidden",
      transition: ".5s all ease"
    };

    return listItem ? (
      <div
        className="specialPaymentRow row"
        style={this.state.viewing ? { display: "block" } : null}
      >
        <div className="col-sm-4">
          <div className="">
            {listItem.client ? (
              <Link to={`/client/${listItem.client._id}`}>
                {listItem.client.full_name}
              </Link>
            ) : (
              "No Client Found"
            )}
          </div>
        </div>
        <div className="col-sm-4">
          <span className="color-black">
            {formatNumsToDollars(listItem.total)}
          </span>
        </div>

        <div className="col-sm-2">
          {listItem.line_items.filter(this.props.filterLineItem).length}
        </div>
        <div className="col-sm-1">
          <input
            type="checkbox"
            checked={listItem.selected}
            onClick={e => this.selectClient(e)}
          />
        </div>
        <div className="col-sm-1">
          <i
            className={
              this.state.viewing
                ? "fa fa-caret-square-o-up"
                : "fa fa-caret-square-o-down"
            }
            onClick={() => this.setState({ viewing: !this.state.viewing })}
          />
        </div>
        <div className="paymentThing" style={paymentStyle}>
          {this.state.viewing ? this.getLineItems() : null}
        </div>
      </div>
    ) : null;
  }
}
