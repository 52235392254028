import React, { Component } from "react";
import moment from "moment";
import Week from "./monthView/Week";
import { DateSwitcher } from "../../";

export default class CalendarMonthView extends Component {

  static defaultProps = {
    onClickDay: () => {}
  };

  componentWillMount = () => {
    const { calendarMonth, getAppointmentsByMonth } = this.props;
    getAppointmentsByMonth(calendarMonth);
  };

  previous = () => {
    const {
      calendarMonth,
      getAppointmentsByMonth,
      setCalendarMonth
    } = this.props;
    const month = calendarMonth.clone().subtract(1, "month");
    setCalendarMonth(month);
    getAppointmentsByMonth(month);
  };

  next = () => {
    const {
      calendarMonth,
      getAppointmentsByMonth,
      setCalendarMonth
    } = this.props;
    const month = calendarMonth.clone().add(1, "month");
    setCalendarMonth(month);
    getAppointmentsByMonth(month);
  };

  renderWeeks = () => {
    const {
      calendarMonth,
      children,
      isDayDisabled,
      isDaySelected,
      onClickDay,
      appointments
    } = this.props;
    const weeks = [];
    let done = false;
    let date = calendarMonth
      .clone()
      .startOf("month")
      .day("Sunday");
    let monthIndex = date.month();
    let count = 0;

    while (!done) {
      weeks.push(
        <Week
          key={date.toString()}
          calendarDayContents={children}
          firstDayOfWeek={date.clone()}
          isDayDisabled={isDayDisabled}
          isDaySelected={isDaySelected}
          month={calendarMonth}
          onClickDay={onClickDay}
          appointments={appointments}
        />
      );
      date.add(1, "week");
      done = count++ > 2 && monthIndex !== date.month();
      monthIndex = date.month();
    }
    return weeks;
  };

  renderWeekDayHeaders = () => (
    <div className="calendar-row-month">
      <div className="day-wrapper">
        <h4>Sun</h4>
      </div>
      <div className="day-wrapper">
        <h4>Mon</h4>
      </div>
      <div className="day-wrapper">
        <h4>Tue</h4>
      </div>
      <div className="day-wrapper">
        <h4>Wed</h4>
      </div>
      <div className="day-wrapper">
        <h4>Thu</h4>
      </div>
      <div className="day-wrapper">
        <h4>Fri</h4>
      </div>
      <div className="day-wrapper">
        <h4>Sat</h4>
      </div>
    </div>
  );

  render() {
    const { calendarMonth } = this.props;
    return (
      <div className="card-calendar">
        <div className="calendar-header-month">
          <DateSwitcher
            dateFormat="MMMM YYYY"
            onDateBack={this.previous}
            onDateForward={this.next}
            selectedDate={calendarMonth}
          />
        </div>
        <div className="calendar-month-wrapper">
          {this.renderWeekDayHeaders()}
          {this.renderWeeks()}
        </div>
      </div>
    );
  }
}
