import React, { Component } from "react";
import { DefaultModal } from "../../../components";

class AddZipCodeModal extends Component {
  state = {
    zip_code: "",
    zip_error: false
  };
  addZipCode = () => {
    if (!this.state.zip_code.length) {
      this.setState({ zip_error: true });
      return;
    }
    if (!this.state.zip_error) {
      this.props.addZipCode(this.state.zip_code);
    }
  };

  validateZipCode = e => {
    const zip = e.target.value;
    this.setState({
      zip_error: isNaN(zip),
      zip_code: zip
    });
  };

  render() {
    return (
      <DefaultModal
        title="Add Zip Code"
        onDismiss={this.props.onDismiss}
        options={
          <div>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={this.props.onDismiss}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.addZipCode}
            >
              Add
            </button>
          </div>
        }
      >
        <div className="add-zip-modal">
          <div className="row">
            <div className="col-md-3">
              <label>Enter a Zip Code:</label>
            </div>
            <div className="col-md-4">
              <input
                type="text"
                onChange={e => {
                  this.validateZipCode(e);
                }}
                className={!this.state.zip_error || "has-error"}
              />
            </div>
            <div className="col-md-5">
              {this.state.zip_error ? (
                <span className="error-message">Zip Code must be a number</span>
              ) : null}
            </div>
          </div>
        </div>
      </DefaultModal>
    );
  }
}

export default AddZipCodeModal;
