import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "../../../../services/network.js";
import { DropdownList, Multiselect } from "react-widgets";
import { CardComponent } from "../../../../components";
import { formatNumsToDollars } from "../../../../helpers";
import { connect } from "react-redux";

class ApartmentForm extends Component {
  state = {
    name: "",
    valid_name: true,
    pet_perks: false,
    percentage: 0,
    tab: 1,
    start_date: moment().toDate(),
    concierge: {
      enabled: false,
      days: [],
      daily_visits: 0,
      window_start_time: "10:00 AM",
      window_end_time: "02:00 PM",
      week_limit: 0,
      staff: [],
      price: 0
    },
    playcare: {
      enabled: false,
      days: [],
      max_pets: 0,
      window_start_time: null,
      window_end_time: null,
      staff: [],
      price: {
        1: 0,
        5: 0,
        15: 0
      },
      duration_in_minutes: 0
    },
    days_changed: false,
    options: [
      {
        id: 0,
        name: "SUN"
      },
      {
        id: 1,
        name: "MON"
      },
      {
        id: 2,
        name: "TUE"
      },
      {
        id: 3,
        name: "WED"
      },
      {
        id: 4,
        name: "THU"
      },
      {
        id: 5,
        name: "FRI"
      },
      {
        id: 6,
        name: "SAT"
      }
    ],
    staff_list: [],
    contact: {}
  };

  componentDidMount = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URI}/admin/staff-list?city=${this.props.market_id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      }
    );
    const list = data
      .map(s => {
        return {
          text: s.full_name,
          value: s._id
        };
      })
      .sort((a, b) => {
        const aName = a.text.toLowerCase();
        const bName = b.text.toLowerCase();
        if (aName < bName) return -1;
        if (aName > bName) return 1;
        return 0;
      });
    this.setState({ staff_list: list });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.name) {
      this.setState({
        name: nextProps.name,
        playcare: nextProps.playcare,
        concierge: nextProps.concierge,
        pet_perks: nextProps.pet_perks,
        percentage: nextProps.percentage,
        start_date: moment(nextProps.start_date).toDate(),
        contact: nextProps.contact
      });
    }
  }

  validateName = e => {
    const value = e.target.value;
    if (value) {
      this.setState({ name: value, valid_name: true });
    } else {
      this.setState({ name: null, valid_name: false });
    }
  };

  validateNum = e => {
    let value = e.target.value;
    let num = Number(value);
    if (!num && num !== 0) {
      this.setState({ numberError: true });
    } else {
      this.setState({
        ...this.state,
        numberError: false,
        concierge: {
          ...this.state.concierge,
          daily_visits: num
        }
      });
    }
  };

  getStartTimes = () => {
    let times = [];
    for (
      let m = moment()
        .hour(9)
        .minute(0);
      m.hour() < 19;
      m.add(30, "minutes")
    ) {
      times.push({
        text: m.format("hh:mm a"),
        value: m.format("HH:mm")
      });
    }
    return times;
  };

  renderConcierge = () => {
    return (
      <div className="col-md-9 selectWeekdays">
        <div className="col-md-12">
          <div className="col-md-9">
            <div className={`toggle-box-container isAdmin`}>
              <div className="toggle-box-container-title">Select Weekdays</div>
              {this.state.options.map((option, index) => {
                const checked = this.state.concierge.days.includes(option.id);
                return (
                  <div
                    className={`toggle-box weekdays-field btn btn-tertiary${
                      checked ? " checked" : ""
                    }`}
                    key={`${option.name}-${index}`}
                    onClick={() => {
                      const days = [...this.state.concierge.days];
                      if (days.includes(option.id)) {
                        days.splice(days.indexOf(option.id), 1);
                      } else {
                        days.push(option.id);
                      }
                      this.setState({
                        ...this.state,
                        concierge: { ...this.state.concierge, days: days }
                      });
                    }}
                  >
                    <label>{option.name}</label>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-sm-3">
            {this.props.id && this.state.concierge.enabled ? (
              <button
                className="btn btn-danger normal-size-btn pull-right"
                style={{ marginLeft: "10px" }}
                onClick={this.props.showConciergeModal}
              >
                Cancel Concierge
              </button>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  renderPlaycare = () => {
    return (
      <div className="col-md-6 selectWeekdays">
        <div className={`toggle-box-container isAdmin`}>
          <div className="toggle-box-container-title">Select Weekdays</div>

          {this.state.options.map((option, index) => {
            const checked = this.state.playcare.days.includes(option.id);
            return (
              <div
                className={`toggle-box weekdays-field btn btn-tertiary${
                  checked ? " checked" : ""
                }`}
                key={`${option.name}-${index}`}
                onClick={() => {
                  const days = [...this.state.playcare.days];
                  let remove = false;
                  if (days.includes(option.id)) {
                    remove = true;
                    days.splice(days.indexOf(option.id), 1);
                  } else {
                    days.push(option.id);
                  }
                  this.setState({
                    ...this.state,
                    days_changed: remove ? true : false,
                    playcare: { ...this.state.playcare, days: days }
                  });
                }}
              >
                <label>{option.name}</label>
              </div>
            );
          })}
        </div>
        {this.state.days_changed ? (
          <div className="col-sm-12">
            Note: removing a day from this list will cancel all future (after
            today) playcare visits that occur on that day
          </div>
        ) : null}
      </div>
    );
  };

  renderPlaycareOptions = () => {
    return (
      <div>
        <div className="row">
          <div className="col-md-4">
            <div className="col-md-12">
              <h4 style={{ textAlign: "center" }}>Windows</h4>
            </div>
            <div className="col-md-6">
              <div className="form-component-wrapper">
                <DropdownList
                  data={this.getStartTimes()}
                  valueField={"value"}
                  textField={"text"}
                  value={this.state.playcare.window_start_time}
                  onChange={item => {
                    this.setState({
                      ...this.state,
                      playcare: {
                        ...this.state.playcare,
                        window_start_time: item.value
                      }
                    });
                  }}
                />
                <label>Window Start Time</label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-component-wrapper">
                <DropdownList
                  data={this.getStartTimes()}
                  valueField={"value"}
                  textField={"text"}
                  value={this.state.playcare.window_end_time}
                  onChange={item => {
                    this.setState({
                      ...this.state,
                      playcare: {
                        ...this.state.playcare,
                        window_end_time: item.value
                      }
                    });
                  }}
                />
                <label>Window End Time</label>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-md-12">
              <h4 style={{ textAlign: "center" }}>Limits</h4>
            </div>
            <div className="col-md-6">
              <div className={`form-component-wrapper`}>
                <input
                  type="text"
                  placeholder="Duration (Minutes)"
                  onChange={e => this.updateDuration(e)}
                  value={this.state.playcare.duration_in_minutes}
                />
                <label>Duration (Minutes)</label>
              </div>
            </div>
            <div className="col-md-6">
              <div className={`form-component-wrapper`}>
                <input
                  type="text"
                  placeholder="Max Pets"
                  onChange={e => this.updateMaxPets(e)}
                  value={this.state.playcare.max_pets}
                />
                <label>Max Pets</label>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="col-md-12">
              <h4 style={{ textAlign: "center" }}>Prices</h4>
            </div>
            <div className="col-md-4">
              <div className="form-component-wrapper">
                <input
                  type="text"
                  value={formatNumsToDollars(this.state.playcare.price[1])}
                  onChange={e => {
                    this.updatePrice(e, "playcare", 1);
                  }}
                />
                <label>Single Visit</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-component-wrapper">
                <input
                  type="text"
                  value={formatNumsToDollars(this.state.playcare.price[5])}
                  onChange={e => {
                    this.updatePrice(e, "playcare", 5);
                  }}
                />
                <label>5 Pack</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-component-wrapper">
                <input
                  type="text"
                  value={formatNumsToDollars(this.state.playcare.price[15])}
                  onChange={e => {
                    this.updatePrice(e, "playcare", 15);
                  }}
                />
                <label>15 Pack</label>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="col-md-12">
              <h4 style={{ textAlign: "center" }}>Staff</h4>
            </div>
            <div className="col-md-12">
              <div className="form-component-wrapper">
                <Multiselect
                  data={this.state.staff_list}
                  value={this.state.playcare.staff}
                  textField="text"
                  onChange={items => {
                    this.setState({
                      ...this.state,
                      playcare: { ...this.state.playcare, staff: items }
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderConciergeOptions = () => {
    return (
      <div className="col-sm-12">
        <div className="col-md-2">
          <div
            className={`form-component-wrapper${
              this.state.numberError ? " has-error" : ""
            }`}
          >
            <input
              type="text"
              placeholder="Number of Daily Visits"
              onChange={e => this.validateNum(e)}
              value={this.state.concierge.daily_visits}
            />
            <label>Daily Visits</label>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-component-wrapper">
            <DropdownList
              data={this.getStartTimes()}
              valueField={"value"}
              textField={"text"}
              value={this.state.concierge.window_start_time}
              onChange={item => {
                this.setState({
                  ...this.state,
                  concierge: {
                    ...this.state.concierge,
                    window_start_time: item.value
                  }
                });
              }}
            />
            <label>Windo Start Time</label>
          </div>
        </div>

        <div className="col-md-2">
          <div className="form-component-wrapper">
            <DropdownList
              data={this.getStartTimes()}
              valueField={"value"}
              textField={"text"}
              value={this.state.concierge.window_end_time}
              onChange={item => {
                this.setState({
                  ...this.state,
                  concierge: {
                    ...this.state.concierge,
                    window_end_time: item.value
                  }
                });
              }}
            />
            <label>Window End Time</label>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-component-wrapper">
            <input
              type="text"
              onChange={e =>
                this.setState({
                  ...this.state,
                  concierge: {
                    ...this.state.concierge,
                    week_limit: e.target.value
                  }
                })
              }
              value={this.state.concierge.week_limit}
            />
            <label>Weekly Visit Limit (per client)</label>
          </div>
        </div>
        <div className="col-md-9">
          <div className="form-component-wrapper">
            <Multiselect
              data={this.state.staff_list}
              value={this.state.concierge.staff}
              textField="text"
              valueField="value"
              onChange={items => {
                this.setState({
                  ...this.state,
                  concierge: {
                    ...this.state.concierge,
                    staff: items.map(i => i.value)
                  }
                });
              }}
            />
            <label>Staff</label>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-component-wrapper">
            <input
              type="text"
              value={formatNumsToDollars(this.state.concierge.price)}
              onChange={e => {
                this.updatePrice(e, "concierge");
              }}
            />
            <label>Concierge Price</label>
          </div>
        </div>
      </div>
    );
  };

  validatePercentage = e => {
    let num = parseInt(e.target.value, 10);
    if (isNaN(num)) {
      this.setState({ percentage: 0 });
    } else {
      this.setState({ percentage: num });
    }
  };

  updatePrice = (e, service, quantity) => {
    const value = e.target.value;
    if (!value || !service) return;
    const price = parseFloat(value.replace(/\D/g, ""));
    if (!quantity) {
      this.setState({
        ...this.state,
        [service]: { ...this.state[service], price }
      });
    } else {
      this.setState({
        ...this.state,
        [service]: {
          ...this.state[service],
          price: {
            ...this.state[service].price,
            [quantity]: price
          }
        }
      });
    }
  };

  getStates = () => {
    return this.props.states.map(s => ({ name: s.name, id: s.id.toString() }));
  };

  updateDuration = e => {
    const { value } = e.target;
    let minutes = parseFloat(value.replace(/\D/g, ""));
    if (!minutes || isNaN(minutes)) minutes = 0;
    this.setState({
      ...this.state,
      playcare: {
        ...this.state.playcare,
        duration_in_minutes: minutes
      }
    });
  };

  updateMaxPets = e => {
    const { value } = e.target;
    let pets = parseFloat(value.replace(/\D/g, ""));
    if (!pets || isNaN(pets)) pets = 0;
    this.setState({
      ...this.state,
      playcare: {
        ...this.state.playcare,
        max_pets: pets
      }
    });
  };

  renderInfoBlock = () => {
    return (
      <div>
        <div className="row">
          <div className="col-sm-12">
            <div className="col-md-4">
              <div
                className={`form-component-wrapper${
                  this.state.valid_name ? "" : " has-error"
                }`}
              >
                <input
                  type="text"
                  placeholder="Apartment Name *"
                  onChange={e => this.validateName(e)}
                  value={this.state.name}
                />
                <label>Name</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-component-wrapper">
                <input
                  type="text"
                  placeholder="Address"
                  onChange={e => {
                    this.setState({
                      ...this.state,
                      contact: {
                        ...this.state.contact,
                        address_1: e.target.value
                      }
                    });
                  }}
                  value={this.state.contact.address_1}
                />
                <label>Address</label>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-component-wrapper">
                <input
                  type="text"
                  placeholder="Address 2"
                  onChange={e => {
                    this.setState({
                      ...this.state,
                      contact: {
                        ...this.state.contact,
                        address_2: e.target.value
                      }
                    });
                  }}
                  value={this.state.contact.address_2}
                />
                <label>Address 2</label>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="col-md-4">
              <div className="form-component-wrapper">
                <input
                  type="text"
                  placeholder="City"
                  onChange={e => {
                    this.setState({
                      ...this.state,
                      contact: { ...this.state.contact, city: e.target.value }
                    });
                  }}
                  value={this.state.contact.city}
                />
                <label>City</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-component-wrapper">
                <DropdownList
                  data={this.getStates()}
                  valueField={"id"}
                  textField={"name"}
                  value={this.state.contact.state}
                  onChange={item => {
                    this.setState({
                      ...this.state,
                      contact: {
                        ...this.state.contact,
                        state: item.id
                      }
                    });
                  }}
                />
                <label>State</label>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-component-wrapper">
                <input
                  type="text"
                  placeholder="Zip"
                  value={this.state.contact.zip}
                  onChange={e => {
                    this.setState({
                      ...this.state,
                      contact: { ...this.state.contact, zip: e.target.value }
                    });
                  }}
                />
                <label>Zip</label>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="col-md-4">
              <div className="form-component-wrapper">
                <input
                  type="text"
                  placeholder="latitude"
                  value={this.state.contact.latitude}
                  onChange={e => {
                    this.setState({
                      ...this.state,
                      contact: {
                        ...this.state.contact,
                        latitude: e.target.value
                      }
                    });
                  }}
                />
                <label>Latitude</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-component-wrapper">
                <input
                  type="text"
                  placeholder="longitude"
                  value={this.state.contact.longitude}
                  onChange={e => {
                    this.setState({
                      ...this.state,
                      contact: {
                        ...this.state.contact,
                        longitude: e.target.value
                      }
                    });
                  }}
                />
                <label>Longitude</label>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-component-wrapper">
                <DropdownList
                  data={[{ id: true, name: "Yes" }, { id: false, name: "No" }]}
                  valueField={"id"}
                  textField={"name"}
                  value={this.state.contact.override_geocode}
                  onChange={item => {
                    this.setState({
                      ...this.state,
                      contact: {
                        ...this.state.contact,
                        override_geocode: item.id
                      }
                    });
                  }}
                />
                <label>Override Geocode</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderPlaycareBlock = () => {
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="col-md-3">
            <div className="form-component-wrapper">
              <DropdownList
                data={[
                  { text: "Yes", value: true },
                  { text: "No", value: false }
                ]}
                valueField={"value"}
                textField={"text"}
                value={this.state.playcare.enabled}
                onChange={item => {
                  this.setState({
                    ...this.state,
                    playcare: {
                      ...this.state.playcare,
                      enabled: item.value,
                      days: []
                    }
                  });
                }}
              />
              <label>Offers Playcare?</label>
            </div>
          </div>
          {this.state.playcare.enabled ? this.renderPlaycare() : null}
          {this.state.playcare.enabled && this.state.playcare.days.length
            ? this.renderPlaycareOptions()
            : null}
        </div>
      </div>
    );
  };

  renderConciergeBlock = () => {
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className={`col-md-3`}>
            <div className="form-component-wrapper">
              <DropdownList
                data={[
                  { text: "Yes", value: true },
                  { text: "No", value: false }
                ]}
                valueField={"value"}
                textField={"text"}
                value={this.state.concierge.enabled}
                onChange={item => {
                  this.setState({
                    ...this.state,
                    concierge: {
                      ...this.state.concierge,
                      enabled: item.value,
                      days: []
                    }
                  });
                }}
              />
              <label>Concierge?</label>
            </div>
          </div>
          {this.state.concierge.enabled ? this.renderConcierge() : null}
        </div>

        {this.state.concierge.enabled ? this.renderConciergeOptions() : null}
      </div>
    );
  };

  renderPetPerksBlock = () => {
    return null;
  };

  render() {
    let { tab } = this.state;
    return (
      <div className="row">
        <div className="col-sm-12" style={{ padding: "10px 0 20px 0" }}>
          <Link
            to={{ pathname: "/apartments" }}
            className="btn btn-danger normal-size-btn pull-left"
          >
            Back
          </Link>
        </div>

        <div className="apartmentTabs">
          <div
            onClick={() => this.setState({ tab: 1 })}
            className={`apartmentTab${tab === 1 ? " selectedTab" : ""}`}
          >
            Name
          </div>
          <div
            onClick={() => this.setState({ tab: 2 })}
            className={`apartmentTab${tab === 2 ? " selectedTab" : ""}`}
          >
            PlayCare
          </div>
          <div
            onClick={() => this.setState({ tab: 3 })}
            className={`apartmentTab${tab === 3 ? " selectedTab" : ""}`}
          >
            Concierge
          </div>
          <div
            onClick={() => this.setState({ tab: 4 })}
            className={`apartmentTab${tab === 4 ? " selectedTab" : ""}`}
          >
            Pet Perks
          </div>
        </div>
        <div className="selectedRes">
          {tab === 1 ? this.renderInfoBlock() : null}
          {tab === 2 ? this.renderPlaycareBlock() : null}
          {tab === 3 ? this.renderConciergeBlock() : null}
          {tab === 4 ? this.renderPetPerksBlock() : null}
        </div>
        <div className="col-sm-12">
          <a
            className="btn btn-primary pull-right"
            onClick={() => {
              this.props.saveApartment({
                name: this.state.name,
                playcare: this.state.playcare,
                concierge: this.state.concierge,
                pet_perks: this.state.pet_perks,
                start_date: this.state.start_date,
                percentage: this.state.percentage,
                days_changed: this.state.days_changed,
                contact: this.state.contact
              });
            }}
          >
            Save
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  states: state.general.formConstants.states
});

export default connect(
  mapStateToProps,
  null
)(ApartmentForm);
