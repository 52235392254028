import React, { Component } from "react";
import { Link } from "react-router-dom";
import { reduxForm, Field, Fields } from "redux-form";
import validate from "./formValidator.js";
import { apartments } from "../../../../services/constants";
import {
  DateTimeField,
  DropdownListField,
  FieldColumnWrapper,
  FormSection,
  InputField,
  TextAreaField,
  FormSubHeader,
  MultiselectField,
  CheckboxField
} from "../../../shared/formComponents";
import { entryTimes } from "../../../../services/constants";

function sortFunc(a, b) {
  if (a.full_name > b.full_name) return 1;
  if (a.full_name < b.full_name) return -1;
  return 0;
}

class ClientForm extends Component {

  state = {
    authorizedPickups: [],
    clientAuthorizedPickups: []
  };

  componentWillReceiveProps = nextProps => {
    let client = nextProps.client;

    if (
      !this.state.authorized_pickups ||
      !this.state.authorized_pickups.length ||
      !this.state.clientAuthorizedPickups ||
      !this.state.clientAuthorizedPickups.length
    ) {
      this.setState({
        authorizedPickups: client ? client.authorized_pickups : [],
        clientAuthorizedPickups: client ? client.authorized_pickups : []
      });
    }
  };

  cancelForm = e => {
    // e.preventDefault();
    // browserHistory.push("/admin");
  };

  renderHomeClassFields = fields => {
    const { formConstants } = this.props;
    const homeClass = fields.homeclass.input.value;
    return (
      <div>
        <FieldColumnWrapper column="6">
          <Field
            component={DropdownListField}
            name="homeclass"
            busy={false} // DropdownList: Display spinner
            data={formConstants.classifications} // DropdownList: Array of strings or objects or other primitives to populate
            valueField="name" // DropdownList: If data is obj, determines which property is the id
            textField="name" // DropdownList: If data is obj, determines which property gets displayed
            parse={(val, name) => val} // reduxForm: parse decides what goes into the state in the form store
            placeholder="Home Class"
            required
          />
        </FieldColumnWrapper>

        {homeClass && homeClass.name !== "Green" && (
          <FieldColumnWrapper column="6">
            <Field
              component={InputField}
              name="housenotes"
              type="text"
              placeholder="Home Class Notes"
              required
            />
          </FieldColumnWrapper>
        )}
      </div>
    );
  };

  renderSecondaryContact = fields => {
    const firstName = fields.secondaryfirstname.input.value;
    const lastName = fields.secondarylastname.input.value;
    const phone = fields.secondaryphone.input.value;
    const email = fields.secondaryemail.input.value;
    const fieldValues = [firstName, lastName, phone, email];
    const groupHasValue = !!fieldValues.find(item => item.length > 0);

    return (
      <div>
        <div className="col-sm-12">
          <FormSubHeader>Secondary Contact</FormSubHeader>
        </div>

        <FieldColumnWrapper column="4">
          <Field
            component={InputField}
            name="secondaryfirstname"
            type="text"
            placeholder="First Name"
            required={groupHasValue}
          />
        </FieldColumnWrapper>

        <FieldColumnWrapper column="4">
          <Field
            component={InputField}
            name="secondarylastname"
            type="text"
            placeholder="Last Name"
            required={groupHasValue}
          />
        </FieldColumnWrapper>

        <FieldColumnWrapper column="4">
          <Field
            component={InputField}
            name="secondaryphone"
            type="phone"
            placeholder="Phone Number"
            required={groupHasValue}
          />
        </FieldColumnWrapper>

        <FieldColumnWrapper column="4">
          <Field
            component={InputField}
            name="secondaryemail"
            type="email"
            placeholder="Email Address"
            required={groupHasValue}
          />
        </FieldColumnWrapper>
      </div>
    );
  };

  renderEmergencyContact = fields => {
    const firstName = fields.emergencycontactfirstname.input.value;
    const lastName = fields.emergencycontactlastname.input.value;
    const phone = fields.emergencycontactphone.input.value;
    const fieldValues = [firstName, lastName, phone];
    const groupHasValue = !!fieldValues.find(item => item.length > 0);

    return (
      <div>
        <div className="col-sm-12">
          <FormSubHeader>Emergency Contact</FormSubHeader>
        </div>

        <FieldColumnWrapper column="4">
          <Field
            component={InputField}
            name="emergencycontactfirstname"
            type="text"
            placeholder="First Name"
            required={groupHasValue}
          />
        </FieldColumnWrapper>

        <FieldColumnWrapper column="4">
          <Field
            component={InputField}
            name="emergencycontactlastname"
            type="text"
            placeholder="Last Name"
            required={groupHasValue}
          />
        </FieldColumnWrapper>

        <FieldColumnWrapper column="4">
          <Field
            component={InputField}
            name="emergencycontactphone"
            type="phone"
            placeholder="Phone Number"
            required={groupHasValue}
          />
        </FieldColumnWrapper>
      </div>
    );
  };

  render() {
    const {
      client,
      editExisting,
      formConstants,
      handleActivation,
      handlePasswordReset,
      pristine
    } = this.props;
    // console.log("client .here...", client);
    return (
      <form onSubmit={this.props.handleSubmit}>
        {editExisting && !client.active ? (
          <FormSection title="Client Activation">
            <div className="row">
              <div className="col-sm-4">
                <p className="red-text">Client Account Deactivated</p>
                <button
                  className="btn btn-block btn-primary"
                  onClick={e => handleActivation(e, true)}
                >
                  Reactivate Client
                </button>
              </div>
            </div>
          </FormSection>
        ) : null}

        <FormSection title="Personal Information">
          <div className="row">
            <FieldColumnWrapper column="4">
              <Field
                component={InputField}
                name="firstName"
                type="text"
                placeholder="First Name"
                required
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={InputField}
                name="lastName"
                type="text"
                placeholder="Last Name"
                required
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={InputField}
                name="email"
                type="email"
                placeholder="Email Address"
                required
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={InputField}
                name="mobile"
                type="text"
                placeholder="Phone Number"
                required
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={InputField}
                name="instagram"
                type="text"
                placeholder="Instagram"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                name="market_id"
                data={this.props.markets.filter(m => m !== "ALL")}
                valueField="_id" // DropdownList: If data is obj, determines which property is the id
                textField="city" // DropdownList: If data is obj, determines which property gets displayed
                parse={val => val._id} // reduxForm: parse decides what goes into the state in the form store
                placeholder="Market"
                required
              />
            </FieldColumnWrapper>

            {editExisting && client.active ? (
              <FieldColumnWrapper column="4">
                <Link to={`/client/${client._id}/schedule`}>
                  <button className="btn btn-block btn-primary">
                    Schedule Service
                  </button>
                </Link>
              </FieldColumnWrapper>
            ) : null}
          </div>
        </FormSection>

        <FormSection title="Home Information">
          <FormSubHeader>Address Information</FormSubHeader>
          <div className="row">
            <FieldColumnWrapper column="4">
              <Field
                component={InputField}
                name="address"
                type="text"
                placeholder="Address"
                required
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={InputField}
                name="address2"
                type="text"
                placeholder="Address 2"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={InputField}
                name="city"
                type="text"
                placeholder="City"
                required
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                name="state"
                busy={false} // DropdownList: Display spinner
                data={formConstants.states} // DropdownList: Array of strings or objects or other primitives to populate
                valueField="id" // DropdownList: If data is obj, determines which property is the id
                textField="name" // DropdownList: If data is obj, determines which property gets displayed
                filter="contains" // DropdownList: If you want to filter, include this with string indicating filter type
                parse={(val, name) => val.id} // reduxForm: parse decides what goes into the state in the form store
                placeholder="State"
                required
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={InputField}
                name="zip"
                type="text"
                placeholder="Zip Code"
                required
              />
            </FieldColumnWrapper>
            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                name="enforce_device_within_region"
                placeholder="Enforce Device Within Region?"
                data={[
                  { text: "Yes", value: true },
                  { text: "No", value: false }
                ]}
                valueField={"value"}
                textField={"text"}
                parse={val => val.value}
              />
            </FieldColumnWrapper>
          </div>

          <FormSubHeader>Home Detail</FormSubHeader>
          <div className="row">
            <FieldColumnWrapper column="4">
              <Field
                component={InputField}
                name="latitude"
                type="text"
                placeholder="Latitude"
              />
            </FieldColumnWrapper>
            <FieldColumnWrapper column="4">
              <Field
                component={InputField}
                name="longitude"
                type="text"
                placeholder="Longitude"
              />
            </FieldColumnWrapper>
            <FieldColumnWrapper column="4">
              <Field
                component={CheckboxField}
                name="override_geocode"
                label="Override Geocode"
                type="checkbox"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={MultiselectField}
                name="zones"
                data={formConstants.zones} // Multiselect: Array of strings or objects or other primitives to populate
                valueField="id" // Multiselect: If data is obj, determines which property is the id
                textField="zone_name" // Multiselect: If data is obj, determines which property gets displayed
                parse={(val, name) => val.map(v => v)} // reduxForm: parse decides what goes into the state in the form store
                placeholder="Zones"
                required
              />
            </FieldColumnWrapper>
            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                name="buildingType"
                busy={false} // DropdownList: Display spinner
                data={formConstants.buildingTypes} // DropdownList: Array of strings or objects or other primitives to populate
                valueField="name" // DropdownList: If data is obj, determines which property is the id
                textField="name" // DropdownList: If data is obj, determines which property gets displayed
                // filter="contains" // DropdownList: If you want to filter, include this with string indicating filter type
                parse={(val, name) => val.name} // reduxForm: parse decides what goes into the state in the form store
                placeholder="Building Type"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                name="apartment_id"
                busy={false} // DropdownList: Display spinner
                textField="name"
                valueField="apartment_id"
                parse={(v, n) => v.apartment_id}
                data={this.props.apartments.sort((a, b) => {
                  if (a.name < b.name) return -1;
                  if (a.name > b.name) return 1;
                  return 0;
                })} // DropdownList: Array of strings or objects or other primitives to populate
                // reduxForm: parse decides what goes into the state in the form store
                placeholder="Apartment Complex"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                name="secured_yard"
                placeholder="Secured Yard?"
                data={[
                  { text: "Yes", value: true },
                  { text: "No", value: false }
                ]}
                valueField={"value"}
                textField={"text"}
                defaultValue={{ text: "No", value: false }}
                parse={val => val.value}
              />
            </FieldColumnWrapper>
          </div>

          <FormSubHeader>Access Information</FormSubHeader>
          <div className="row">
            <FieldColumnWrapper column="6">
              <Field
                component={TextAreaField}
                name="homeaccessnotes"
                type="text"
                placeholder="Home Access Notes"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="6">
              <Field
                component={TextAreaField}
                name="parking"
                type="text"
                placeholder="Parking Information"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="6">
              <Field
                component={TextAreaField}
                name="securityalarmcode"
                type="text"
                placeholder="Alarm Information"
              />
            </FieldColumnWrapper>

            <Fields
              names={["homeclass", "housenotes"]}
              component={this.renderHomeClassFields}
            />

            <FieldColumnWrapper column="6">
              <Field
                component={InputField}
                name="waste_disposal"
                type="text"
                placeholder="Waste Disposal Notes"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="6">
              <Field
                component={InputField}
                name="wificode"
                type="text"
                placeholder="Wifi Information"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={MultiselectField}
                name="access_method"
                placeholder="Access Method"
                data={[
                  "Keyless",
                  "Garage Door",
                  "Lockbox",
                  "Hidden Key",
                  "Leasing Office",
                  "Call Box"
                ]}
              />
            </FieldColumnWrapper>
            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                name="entry_time"
                placeholder="Entry Time"
                data={entryTimes}
                textField={"name"}
                parse={val => val}
              />
            </FieldColumnWrapper>
            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                name="exit_time"
                placeholder="Exit Time"
                data={entryTimes}
                textField={"name"}
                parse={val => val}
              />
            </FieldColumnWrapper>
          </div>
        </FormSection>

        <FormSection title="Visit Information">
          <div className="row">
            <FieldColumnWrapper column="8">
              <Field
                component={TextAreaField}
                name="visit_routine"
                type="text"
                placeholder="Visit Routine"
                required
              />
            </FieldColumnWrapper>
            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                data={["None", "No Males", "No Females"]}
                name="gender_preference"
                placeholder="Gender Preference"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="12">
              <Field
                component={MultiselectField}
                name="team_members"
                placeholder="Only Use These Team Members:"
                data={this.props.team_members.sort(sortFunc)}
                textField="full_name"
                valueField="_id"
                parse={(val, name) => val.map(v => v._id)}
              />
            </FieldColumnWrapper>
            <FieldColumnWrapper column="12">
              <Field
                component={MultiselectField}
                name="avoid_tms"
                placeholder="Never Use These Team Members:"
                data={this.props.team_members.sort(sortFunc)}
                textField="full_name"
                valueField="_id"
                parse={(val, name) => val.map(v => v._id)}
              />
            </FieldColumnWrapper>
          </div>
        </FormSection>

        <FormSection title="Contacts">
          <div className="row">
            <Fields
              names={[
                "secondaryfirstname",
                "secondarylastname",
                "secondaryphone",
                "secondaryemail"
              ]}
              component={this.renderSecondaryContact}
            />

            <Fields
              names={[
                "emergencycontactfirstname",
                "emergencycontactlastname",
                "emergencycontactphone"
              ]}
              component={this.renderEmergencyContact}
            />
          </div>
        </FormSection>

        <FormSection title="Client Information">
          <div className="row">
            <FieldColumnWrapper column="4">
              <Field
                component={DateTimeField}
                name="signUpDate"
                time={false}
                dateFormat="MMM. D, Y"
                placeholder="Sign Up Date"
                required
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={InputField}
                name="referralsource"
                type="text"
                placeholder="Client Source"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={InputField}
                name="referral_specifics"
                type="text"
                placeholder="Referral Specifics"
              />
            </FieldColumnWrapper>

            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                name="consultation_status"
                placeholder="Consultation Status"
                data={[
                  { text: "Unscheduled", value: "unscheduled" },
                  { text: "Scheduled", value: "scheduled" },
                  { text: "Completed", value: "completed" }
                ]}
                valueField={"value"}
                textField={"text"}
                parse={val => val.value}
              />
            </FieldColumnWrapper>
            <FieldColumnWrapper column="4">
              <Field
                component={DropdownListField}
                name="dog_walking"
                placeholder="Dog Walking"
                data={[
                  { text: "In Area", value: "in_area" },
                  { text: "Wait List", value: "wait_list" },
                  { text: "Out of Area", value: "out_of_area" }
                ]}
                valueField={"value"}
                textField={"text"}
                parse={val => val.value}
              />
            </FieldColumnWrapper>
            <FieldColumnWrapper column="4">
              <Field
                component={MultiselectField}
                name="authorized_pickups"
                placeholder="Select who can pick up the pets"
                data={this.state.authorizedPickups}
                value={this.state.clientAuthorizedPickups}
                allowCreate
                onCreate={e =>
                  this.setState({
                    authorizedPickups: [...this.state.authorizedPickups, e]
                  })
                }
                onChange={(e, value) =>
                  this.setState({ clientAuthorizedPickups: value })
                }
              />
            </FieldColumnWrapper>
          </div>
        </FormSection>

        <FormSection title="Client Notes">
          <div className="row">
            <FieldColumnWrapper column="6">
              <Field
                component={TextAreaField}
                name="notes"
                placeholder="Notes to Team"
              />
            </FieldColumnWrapper>
            <FieldColumnWrapper column="6">
              <Field
                component={TextAreaField}
                name="private_admin_notes"
                placeholder="Private Admin Notes"
              />
            </FieldColumnWrapper>
          </div>
        </FormSection>

        <br />

        <FormSection>
          <div className="row">
            {editExisting && client.active ? (
              <div className="col-md-2 col-sm-4 col-xs-4 button-column">
                <button
                  className="btn btn-block btn-secondary btn-deactivate"
                  onClick={e => handleActivation(e, false)}
                >
                  Deactivate
                </button>
              </div>
            ) : null}

            {editExisting && client.active ? (
              <div className="col-md-2 col-sm-4 col-xs-4 button-column">
                <button
                  className="btn btn-block btn-secondary"
                  onClick={handlePasswordReset}
                >
                  Reset Password
                </button>
              </div>
            ) : null}

            <div className="col-md-2 col-sm-4 col-xs-4 button-column">
              <button
                className="btn btn-block btn-secondary"
                onClick={this.cancelForm}
              >
                Cancel
              </button>
            </div>

            <div className="col-md-2 col-sm-4 col-xs-4 button-column">
              <input
                disabled={pristine}
                type="submit"
                className="btn btn-block btn-primary"
                value={editExisting ? "Save" : "Continue"}
              />
            </div>
          </div>
        </FormSection>
      </form>
    );
  }
}

export default reduxForm({ form: "addClient", validate })(ClientForm);
