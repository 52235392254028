import React, { Component } from "react";
// import PropTypes from 'prop-types';
import { DragSource } from "react-dnd";

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
}

const knightSource = {
  beginDrag(props, monitor, component) {
    return {
      appointment: props.appointment
    };
  },
  endDrag(props, monitor) {
    if (!monitor.didDrop()) {
      return;
    }
  }
};

class Slot extends Component {
  render() {
    const { connectDragSource, isDragging } = this.props;
    const opacity = isDragging ? 0.4 : 1;

    return connectDragSource(
      <div style={{ opacity }}>{this.props.children}</div>
    );
  }
}

export default DragSource("card", knightSource, collect)(Slot);
