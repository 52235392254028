import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { FormSection } from "./formComponents";
import { formatNumsToDollars } from "../../helpers";
import Tooltip from "rc-tooltip";
import { DefaultModal } from "../";
import axios from "axios";

class LineItems extends React.Component {

  state = {
    showDeleted: false
  };

  toggleDeleteFilter = () => {
    this.setState({ showDeleted: !this.state.showDeleted });
  };

  filterLineItems = item => {
    if (!this.state.showDeleted) return !item.deleted;
    return item;
  };

  showDeleteWarning = item => {
    const { push, pop } = this.props.modalActions;
    let modal = (
      <DefaultModal
        options={[
          <button
            type="button"
            className="btn btn-danger normal-size-btn"
            onClick={pop}
          >
            Cancel
          </button>,
          <button
            type="button"
            className="btn btn-primary normal-size-btn"
            onClick={() => {
              this.toggleDelete(item);
            }}
          >
            Confirm
          </button>
        ]}
        onDismiss={pop}
        title="Warning"
      >
        {item.deleted
          ? `By confirming, this will remove the deleted status from this line item which means it will be 
            charged next time payments are run.  Do you wish to continue?`
          : `By confirming, this will prevent this line item from being charged when payments are run.  Do you
            wish to continue?`}
      </DefaultModal>
    );
    push(modal);
  };

  toggleDelete = async item => {
    const {
      showSpinner,
      removeSpinner,
      showMessage,
      removeMessage,
      modalActions,
      callback,
      id
    } = this.props;
    showSpinner();
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/toggleLineItem`,
        item,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken")
          }
        }
      );
      showMessage({
        message: "Successfully updated line item",
        messageType: "success"
      });
      callback(id);
    } catch (e) {
      if (e.data && e.data.message) {
        showMessage({
          message: e.data.message,
          messageType: "error"
        });
      } else {
        showMessage({
          message: "Something went wrong updating the line item status",
          messageType: "error"
        });
      }
    }
    modalActions.pop();
    setTimeout(removeMessage, 3000);
    removeSpinner();
  };

  render() {
    return (
      <FormSection>
        <div className="row">
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.toggleDeleteFilter}
          >
            {this.state.showDeleted ? "Hide Deleted" : "Show Deleted"}
          </button>
        </div>
        <div className="row">
          <div className="message-charges">
            {this.props.lineItems.filter(this.filterLineItems).map(item => (
              <div
                className="message-charge-item"
                key={`transaction-${item._id}`}
              >
                <div className="col-sm-2">
                  Created: {moment(item.createdAt).format("MMM DD YY")} <br />
                  Updated: {moment(item.updatedAt).format("MMM DD YY HH:mm a")}
                </div>
                <div className="message-charge-item-description col-sm-3">
                  {item.appointment_id ? (
                    <Link to={`/appointments/${item.appointment_id}`}>
                      ID {item.appointment_id}:
                    </Link>
                  ) : (
                    ""
                  )}{" "}
                  {item.description}
                </div>
                <div
                  className={
                    item.amount > 0
                      ? "message-charge-item-amount col-sm-2"
                      : "message-charge-item-amount col-sm-2 refund"
                  }
                >
                  {formatNumsToDollars(item.amount * -1)}
                </div>
                <div className="message-charge-item-amount col-sm-1">
                  {item.type}
                </div>
                <div className="message-charge-item-status col-sm-3">
                  <div className="message-charge-item-status-content">
                    {item.stripe_charge_status
                      ? item.stripe_charge_status
                      : "Pending Charge"}
                  </div>
                  <div className="message-charge-item-receipt">
                    {item.stripe_charge_id ? (
                      <div className="dateCreated">
                        <strong>
                          <a
                            href={`https://dashboard.stripe.com/payments/${item.stripe_charge_id}`}
                          >
                            View On Stripe
                          </a>
                        </strong>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-1">
                  {!item.stripe_charge_id ? (
                    <Tooltip
                      placement="top"
                      overlay={
                        <div>{item.deleted ? null : "Delete Line Item"}</div>
                      }
                      mouseEnterDelay={0.2}
                      mouseLeaveDelay={0.1}
                      overlayClassName="annotation-tooltip"
                    >
                      <span
                        className="btn btn-admin-details"
                        onClick={() => {
                          this.showDeleteWarning(item);
                        }}
                      >
                        {item.deleted ? "Undo Delete" : "X"}
                      </span>
                    </Tooltip>
                  ) : null}
                </div>
                {/* TODO: Receipt number can link directly to Stripe transaction */}
              </div>
            ))}
          </div>
        </div>
      </FormSection>
    );
  }
}

export default LineItems;
