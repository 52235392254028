import React, { Component } from "react";
import { Link } from "react-router-dom";
import Tooltip from "rc-tooltip";

class PromoCodeRowItem extends Component {
  toggleActive = e => {
    e.preventDefault();

    this.props.updatePromo(this.props.id, !this.props.active);
  };

  getActionCell = () => {
    if (this.props.active) {
      return (
        <Tooltip
          placement="top"
          overlay={<div>Deactivate</div>}
          mouseEnterDelay={0.2}
          mouseLeaveDelay={0.1}
          overlayClassName="annotation-tooltip"
        >
          <i
            className="fa fa-ban"
            onClick={e => {
              this.toggleActive(e);
            }}
          ></i>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip
          placement="top"
          overlay={<div>Activate</div>}
          mouseEnterDelay={0.2}
          mouseLeaveDelay={0.1}
          overlayClassName="annotation-tooltip"
        >
          <i
            className="fa fa-bolt"
            onClick={e => {
              this.toggleActive(e);
            }}
          ></i>
        </Tooltip>
      );
    }
  };

  render() {
    return (
      <Link to={`/promo/${this.props.id}`}>
        <div className="row table-row">
          <div className="col-xs-2 cell">
            <Tooltip
              placement="top"
              overlay={<div>{this.props.id}</div>}
              mouseEnterDelay={0.2}
              mouseLeaveDelay={0.1}
              overlayClassName="annotation-tooltip"
            >
              <div>{this.props.code_string}</div>
            </Tooltip>
          </div>
          <div className="col-xs-1 cell">{this.props.type}</div>
          <div className="col-xs-3 cell">{this.props.owner}</div>
          <div className="col-xs-1 cell">{this.props.num_used}</div>
          <div className="col-xs-2 cell">{this.props.created_at}</div>
          <div className="col-xs-2 cell">{this.props.expiration_date}</div>
          <div className="col-xs-1 cell">{this.getActionCell()}</div>
        </div>
      </Link>
    );
  }
}

export default PromoCodeRowItem;
