import React from "react";

const SegmentedControl = ({ onChange, options, selectedKey }) => (
  <div className="btn-group m-b-sm" role="group">
    {options.map(option => (
      <button
        key={`segmented-option-${option.key}`}
        className={`btn btn-tertiary${
          option.key === selectedKey ? " selected" : ""
        }`}
        type="button"
        onClick={() => onChange(option.key)}
      >
        {option.name}
      </button>
    ))}
  </div>
);

export default SegmentedControl;
