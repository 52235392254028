const modals = (state = [], action) => {
  switch (action.type) {
    case "PUSH_MODAL":
      return [...state, action.payload];

    case "POP_MODAL":
      return state.slice(0, -1);

    case "DESTROY_MODALS":
      return [];

    default:
      return state;
  }
};

export default modals;
