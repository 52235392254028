import React from "react";

const LegendBar = () => (
  <div className="legend-bar">
    <div className="legend-item">
      <div className="color completed"></div>
      <p>Completed</p>
    </div>
    <div className="legend-item">
      <div className="color in-progress"></div>
      <p>In-Progress</p>
    </div>
    <div className="legend-item">
      <div className="color approved"></div>
      <p>Approved</p>
    </div>
    <div className="legend-item">
      <div className="color pending"></div>
      <p>Pending</p>
    </div>
    <div className="legend-item">
      <div className="color change-requested"></div>
      <p>Change Requested</p>
    </div>
  </div>
);

export default LegendBar;
